import { GridLayout, Stack } from '@telefonica/mistica';

import CardNewContainer from './CardNew';

import { News } from '../../entities/news/news.model';

interface GridLoadMore { 
    groupCardsNews:  News[][],
    startIndex: number
}

const GridLoadMoreContainer: React.FC<GridLoadMore> = (prop) => {
    
  return (
    <Stack space={40}>
        {prop.groupCardsNews.slice(prop.startIndex).map((group, index) => (
          <GridLayout
            key={`group-${index}`}
            template="8+4"
            verticalSpace={32}
            left={
              <GridLayout
                template="6+6"
                left={<CardNewContainer news={group[0]} type='Media'/>}
                right={<CardNewContainer news={group[1]} type='Media'/>}
              />
            }
            right={<CardNewContainer news={group[2]} type='Media'/>}
          />
        ))}
    </Stack>
  );
};

export default GridLoadMoreContainer;    