import { useIonRouter } from '@ionic/react';
import { Box, LoadingBar, ResponsiveLayout, SkeletonRectangle, skinVars, Tabs, Text5, useScreenSize, useWindowSize } from '@telefonica/mistica';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import Documentation from '../../../../../components/product-data/Documentation';
import GeneralInformation from '../../../../../components/product-data/GeneralInformation';
import HistoricalProfitability from '../../../../../components/product-data/HistoricalProfitability';
import NetAssetValueEvolution from '../../../../../components/product-data/NetAssetValueEvolution';
import PortfolioComposition from '../../../../../components/product-data/PortfolioComposition';
import ProfitabilityValues from '../../../../../components/product-data/ProfitabilityValues';
import Risk from '../../../../../components/product-data/Risk';
import { Product } from '../../../../../entities/products/product';
import { getInfoProduct, trackStadistics } from '../../../../../utils/apiclient';
import { getCurrentProduct } from '../../../../../utils/storage';
import AnnualEvolutionProfitability from '../../../../../components/product-data/AnnualEvolutionProfitability';
import { EmploymentPlansCustomerPage, IndividualPlansCustomerPage, SimplifiedEmploymentPlansCustomerPage } from './PensionPlansCustomerPage';
import { getUrlNavigation } from '../../../../../utils/navigation';

export interface InfoPensionPlanCustomerPageProps {
  type: string
}

const InfoPensionPlanCustomerPage: React.FC<InfoPensionPlanCustomerPageProps> = (prop) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const screenSize = useScreenSize();
  const size = useWindowSize();
  const router = useIonRouter();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [product, setProduct] = useState(null as null|Product);
  const currentProduct = getCurrentProduct();
  const [parentTitle, setParentTitle] = useState('');
  const parentPage = (prop.type===process.env.REACT_APP_PLAN_PENSIONES_EMPRESA ? EmploymentPlansCustomerPage : (prop.type===process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL ? IndividualPlansCustomerPage : SimplifiedEmploymentPlansCustomerPage));
  const [options, setOptions] = useState([] as string[]);

  useLayoutEffect(()=>{
    switch(prop.type){
      case process.env.REACT_APP_PLAN_PENSIONES_EMPRESA:
        setParentTitle(intl.formatMessage({id:'page_customer_pension_plans_employment_plans_title'}));
        break;
      case process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL:
        setParentTitle(intl.formatMessage({id:'page_customer_pension_plans_individual_plans_title'}));
        break;
      case process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS:
        setParentTitle(intl.formatMessage({id:'page_customer_pension_plans_simplified_employment_plans_title'}));
        break;
    }
  }, []);

  useEffect(()=>{

    if(!router.canGoBack() || !currentProduct){
        router.push(getUrlNavigation(parentPage));
        return;
    }
    getInfoProduct({cdCompany:currentProduct?.cdCompania ?? -1, cdPlan: currentProduct?.cdPlan ?? '', cdFund: currentProduct?.cdFondo?? '', cdSubplan: currentProduct?.cdSubplan?? ''}).then((result)=>{
      if(result){
        let opts = [intl.formatMessage({id:'page_customer_pension_plans_list_option_1'}), intl.formatMessage({id:'page_customer_pension_plans_list_option_2'}), intl.formatMessage({id:'page_customer_pension_plans_list_option_3'})];
        if(result.documentacion && result.documentacion.filter(item=>(item.esEI??false)).length>0){
          opts.push(intl.formatMessage({id:'page_customer_pension_plans_list_option_5'}))
        }
        if(result.documentacion && result.documentacion.filter(item=>(item.esSO??false)).length>0){
          opts.push(intl.formatMessage({id:'page_customer_pension_plans_list_option_4'}))
        }
        setOptions(opts);
        setProduct(result);
      }
    }).finally(()=>{
        setLoading(false);
    });
  }, []);

  useEffect(()=>{
    switch (selectedIndex){
      case 1:
        trackStadistics('PAGE', '_RENTABILIDADES' );
        break;
      case 2:
        trackStadistics('PAGE', '_DOCUMENTACION' );
        break;
      case 3:
        trackStadistics('PAGE', '_INFORMACION_SOSTENIBILIDAD' );
        break;
      default:
        trackStadistics('PAGE', '_DATOS_GENERALES' );
        break;
    }
  }, [selectedIndex]);

  return (
    <LayoutPrivateArea
      seo={{metaTitle: currentProduct?.descripcion}}
      title={currentProduct?.descripcion}
      parents={[{item:parentPage, title: parentTitle}]}
      pageInfo={{option: process.env.REACT_APP_MENU_PLANES_PENSIONES, role:'client'}}
      showBack>

      <LoadingBar visible={loading}></LoadingBar>
      <ResponsiveLayout className='inner first'>
          <Box paddingBottom={screenSize.isDesktopOrBigger ? 32 : 0}>
              {screenSize.isDesktopOrBigger &&
                <Text5>{currentProduct?.descripcion}</Text5>
              }
          </Box>
      </ResponsiveLayout>
      <ResponsiveLayout className={!screenSize.isDesktopOrBigger ? 'fullScreen tabs--fixed' : 'inner'} backgroundColor={!screenSize.isDesktopOrBigger ? skinVars.colors.backgroundAlternative : 'transparent'}>
        <Box paddingTop={screenSize.isDesktopOrBigger ? 16 : 0}>
          {loading ?
            <SkeletonRectangle height={56} />
          :
            <Tabs
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
              tabs={options.map((text) => ({
                text,
            }))}/>
          }
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner '+(selectedIndex===0 ? 'd-block' : 'd-none')+(!screenSize.isDesktopOrBigger ? ' with-tabs--fixed min' : '')}>

        <ProfitabilityValues loading={loading} product={product} />

        <Risk loading={loading} product={product} />

        <PortfolioComposition loading={loading} product={product} />
        <GeneralInformation loading={loading} product={product} />

      </ResponsiveLayout>

      <ResponsiveLayout className={'inner first '+(selectedIndex===1 ? 'd-block' : 'd-none')}>

        <HistoricalProfitability loading={loading}  product={product} />

        <AnnualEvolutionProfitability loading={loading}  product={product} />

        <NetAssetValueEvolution loading={loading} product={product} />

      </ResponsiveLayout>

      <ResponsiveLayout className={'inner first '+(options[selectedIndex]===intl.formatMessage({id:'page_customer_pension_plans_list_option_3'}) ? 'd-block' : 'd-none')}>
        <Documentation prefixSlug={'perfil-plan-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_documentacion-'}  loading={loading} product={product} legal={true} />
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner first '+(options[selectedIndex]===intl.formatMessage({id:'page_customer_pension_plans_list_option_5'}) ? 'd-block' : 'd-none')}>
        <Documentation prefixSlug={'perfil-plan-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_impreso-'}  loading={loading} product={product} printed={true} />
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner first '+(options[selectedIndex]===intl.formatMessage({id:'page_customer_pension_plans_list_option_4'}) ? 'd-block' : 'd-none')}>
        <Documentation prefixSlug={'perfil-plan-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_sostenibilidad-'}  loading={loading} product={product} sustainability={true} />
      </ResponsiveLayout>


    </LayoutPrivateArea>
  );
};

export default InfoPensionPlanCustomerPage;

export const InfoIndividualPlansCustomerPage: React.FC = () => {
  return (<InfoPensionPlanCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL ?? "I"} />);
};

export const InfoSimplifiedEmploymentPlansCustomerPage: React.FC = () => {
  return (<InfoPensionPlanCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS ?? "S"} />);
};

export const InfoEmploymentPlansCustomerPage: React.FC = () => {
  return (<InfoPensionPlanCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_EMPRESA ?? "E"}  />);
};
