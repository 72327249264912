

import { useIonRouter } from '@ionic/react';
import { Box, Boxed, BoxedRow, ButtonPrimary, ButtonSecondary, Checkbox, DataCard, DecimalField, DisplayDataCard, EmailField, Form, Grid, IconChildRegular, IconInformationUserRegular, IconLightningRegular, IconLikeRegular, Inline, IntegerField, RadioButton, RadioGroup, ResponsiveLayout, skinVars, Stack, Tag, Text, Text2, Text3, Text4, Text5, Text6, TextField, TextLink, Tooltip, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import { useAlert } from '../../../../components/alerts/Alert';
import EmptyStateCustom from '../../../../components/empty-state/EmptyStateCustom';
import HeroCalculator from '../../../../components/hero/HeroCalculator';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import { useProductActions } from '../../../../components/operate/ProductActions';
import { ResultCalculatorDecideYourInvestment } from '../../../../entities/contents/result-calculator-decide-your-investment.model';
import { Product } from '../../../../entities/products/product';
import { calculateDecideYourInvestment, trackStadistics } from '../../../../utils/apiclient';
import { getUrlNavigation } from '../../../../utils/navigation';
import { formatAmount, formatProduct, scrollToTop, trackEvent } from '../../../../utils/utils';
import ContactPage from '../../contact/ContactPage';
import ForInvestorPage from '../ForInvestorPage';
import './Calculators.scss';

const CustomizeInvestmentFundPage: React.FC = () => {
	const intl = useIntl()
	const screenSize = useScreenSize();
	const [step, setStep] = useState(1);
    const { showAlert } = useAlert()
	const router = useIonRouter();
	const [loading, setLoading] = useState(false);
	const [results, setResults] = useState(null as null|ResultCalculatorDecideYourInvestment[]);
	const [currentOption, setCurrentOption] = useState(null as null|string);
	const [currentResult, setCurrentResult] = useState(null as null|ResultCalculatorDecideYourInvestment);
	const [showProducts, setShowProducts] = useState(false);
	const [currentProduct, setCurrentProduct] = useState(null as null|string);
	const { contract, moreInfo } = useProductActions()

  	//Form
	const initFormData = {
		age: '',
		annualIncome: '',
		saving: '',

		answer2:'',
		answer3:'',
		answer4:'',
		answer5:'',

		email: '',

		privacyPolicy: false
	}
	const [formData, setFormData] = useState(initFormData);
	const [formDataSaving, setFormDataSaving] = useState(initFormData);
	const [formErrors, setFormErrors] = useState([] as any);


	const handleError=(name:string) =>{
		if(formErrors && formErrors[name]){
			const error = formErrors[name];
			if(error){
				return intl.formatMessage({id: 'page_decide_your_investment_calculator_'+error.toLowerCase().replaceAll(' ', '_')});
			}
		}
		return null;
	}

	const handleChangeAnyField= (name:string, value:any) => {
		const newValues = {
			...formData,
			[name]: value,
		};
		setFormData(newValues);

		if(handleError(name)){
			delete formErrors[name];
		}
		validateField(name, value);
	};


	const validateField = (name:string, value:any)=>{
		return true;
	}

	const isSubmitDisabled = () => {
		switch(step){
			case 1:
				return formData.age.length===0 || formData.annualIncome.length===0 || formData.saving.length===0;
			case 2:
				return formData.answer2.length===0;
			case 3:
				return formData.answer3.length===0;
			case 4:
				return formData.answer4.length===0;
			case 5:
				return formData.answer5.length===0;
			case 6:
				return formData.email.length===0 || !formData.privacyPolicy;
		}
		return false;
	};

	const handleSubmit  = (data: any): Promise<void> =>
		new Promise((resolve) => {
			setLoading(true);

			trackEvent('para_inversor-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_choose_your_nvestment'})).replaceAll('-', '_')+'-calcular');

			const formSaving = {
				...formData
			}
			setFormDataSaving(formSaving);

			const params={
				age: parseInt(formData.age),
				annualIncome: parseFloat(formData.annualIncome.replace(',', '.')),
				saving: parseFloat(formData.saving.replace(',', '.')),
				email: formData.email,
				answers:{
					answer2: formData.answer2,
					answer3: formData.answer3,
					answer4: formData.answer4,
					answer5: formData.answer5,
				}
			};
			setCurrentOption(null);
			setCurrentResult(null);
			setResults(null);
			setShowProducts(false);
			calculateDecideYourInvestment(params).then((result)=>{
				if(result){
					const current = result.find(item=>item.selected);
					setCurrentResult(current ?? null);
					setCurrentOption(current?.name ?? null);
					setResults(result);
					const resetFormData = {
						...initFormData,
						privacyPolicy: false
					}
					trackStadistics('CALCULATOR', undefined, undefined, 'Calculadora decide tu inversión.' );
					setFormData(resetFormData);
					setStep(1);
					scrollToTop('results');
				}

			}).finally(()=>{
				resolve();
				setLoading(false);
			})

		});


	const handleChangeOption= (name:string)=>{
		if(results && results.length>0){
			let current : ResultCalculatorDecideYourInvestment|null = null;
			let resultOptions = results.map((item, index)=>{
				if(item.name===name){
					current = item;
					item.selected=true;
				}else{
					item.selected=false;
				}
				return item;
			});
			setCurrentResult(current);
			setCurrentOption(name);
			setResults(resultOptions);
			scrollToTop('results');
			setShowProducts(false);
		}
	}

	const showProductsOption=()=>{
		scrollToTop('products');
		setShowProducts(true);
	}

	const handleReset=()=>{
		scrollToTop();
		setCurrentOption(null);
		setCurrentResult(null);
		setResults(null);
		setShowProducts(false);
	}

	const handleMoreInformation = (product:Product): Promise<void> =>
	  new Promise((resolve) => {
		moreInfo(product, true).finally(()=>{
		  resolve();
		});
	});

	const handleContract = (product:Product): Promise<void> =>
	  new Promise((resolve) => {
		  setCurrentProduct(formatProduct(product));
		  contract(product,
		  ()=>{
			setCurrentProduct(null);
			resolve()
		  }).finally(()=>{
			setCurrentProduct(null)
			  resolve()
		  });
	});

	return (
		<LayoutPublic
			seo={{metaTitle: intl.formatMessage({id:'page_decide_your_investment_calculator_seo_title'}), metaDescription:intl.formatMessage({id:'page_decide_your_investment_calculator_seo_description'})}}
			header={{title:intl.formatMessage({id:'page_decide_your_investment_calculator_title'}), parents:[{title:intl.formatMessage({id:'page_for_investor_page_title'}), item:ForInvestorPage}]}}
		>
			<HeroCalculator
				title={intl.formatMessage({id:'page_decide_your_investment_calculator_title'})}
				description={intl.formatMessage({id:'page_decide_your_investment_calculator_description'})}
				children={
					<Boxed className='calculatorBox'>
						<ResponsiveLayout fullWidth backgroundColor={skinVars.colors.background}>
							<Box paddingX={24} paddingY={8}>
								<Inline space={'between'} fullWidth>
									<Box paddingTop={24} paddingBottom={8}>
										{step===6 ?
											<Tag type="active">{intl.formatMessage({id:'page_decide_your_investment_calculator_email'})}</Tag>
										:
											<Tag type="active">{intl.formatMessage({id:'page_decide_your_investment_calculator_question'}, {current: step.toString(), total: '5'})}</Tag>
										}
									</Box>
									<Box paddingY={8}>
										<Tooltip
											targetLabel=''
											target={<IconInformationUserRegular size={20} color={skinVars.colors.brand}></IconInformationUserRegular>}
											description=""
											position="left"
											extra={
												<Stack space={8}>
													<Text size={14} lineHeight={20} color={skinVars.colors.brand} weight='medium'>{intl.formatMessage({id:'page_decide_your_investment_calculator_tooltip_title'})}</Text>
													<Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_decide_your_investment_calculator_tooltip_'+step})}</Text2>
												</Stack>
											}
										/>
									</Box>
								</Inline>

								<Form onSubmit={handleSubmit}>
									<Box paddingBottom={32}>
										{step===1 &&
											<Box paddingTop={32}>
												<Stack space={16}>
													<IntegerField
														fullWidth
														name="age"
														value={formData.age}
														onChangeValue={(val=>{handleChangeAnyField('age', val)})}
														label={intl.formatMessage({id:'page_decide_your_investment_calculator_label_years_old'})}
													/>

													<DecimalField
														fullWidth
														name="annualIncome"
														value={formData.annualIncome}
														onChangeValue={(val=>{handleChangeAnyField('annualIncome', val)})}
														label={intl.formatMessage({id:'page_decide_your_investment_calculator_label_annual_income'})}
													/>

													<DecimalField
														fullWidth
														name="saving"
														value={formData.saving}
														onChangeValue={(val=>{handleChangeAnyField('saving', val)})}
														label={intl.formatMessage({id:'page_decide_your_investment_calculator_label_annual_savings'})}
													/>
												</Stack>
											</Box>
										}

										{step===2 &&
											<Stack space={24}>
												<Box paddingBottom={8}>
													<Text4 medium>{intl.formatMessage({id:'page_decide_your_investment_calculator_title_q2'})}</Text4>
												</Box>

												<RadioGroup
													name="answer2"
													value={formData.answer2}
													onChange={(val=>{handleChangeAnyField('answer2', val)})}
													aria-labelledby="label"
												>
													<Stack space={16}>
														<RadioButton value="a">{intl.formatMessage({id:'page_decide_your_investment_calculator_q2_option1'})}</RadioButton>
														<RadioButton value="b">{intl.formatMessage({id:'page_decide_your_investment_calculator_q2_option2'})}</RadioButton>
														<RadioButton value="c">{intl.formatMessage({id:'page_decide_your_investment_calculator_q2_option3'})}</RadioButton>
													</Stack>
												</RadioGroup>
											</Stack>
										}

										{step===3 &&
											<Stack space={24}>
												<Box paddingBottom={8}>
													<Text4 medium>{intl.formatMessage({id:'page_decide_your_investment_calculator_title_q3'})}</Text4>
												</Box>

												<RadioGroup
													name="answer3"
													value={formData.answer3}
													onChange={(val=>{handleChangeAnyField('answer3', val)})}
													aria-labelledby="label"
												>
													<Stack space={16}>
														<RadioButton value="a">{intl.formatMessage({id:'page_decide_your_investment_calculator_q3_option1'})}</RadioButton>
														<RadioButton value="b">{intl.formatMessage({id:'page_decide_your_investment_calculator_q3_option2'})}</RadioButton>
														<RadioButton value="c">{intl.formatMessage({id:'page_decide_your_investment_calculator_q3_option3'})}</RadioButton>
														<RadioButton value="d">{intl.formatMessage({id:'page_decide_your_investment_calculator_q3_option4'})}</RadioButton>
													</Stack>
												</RadioGroup>
											</Stack>
										}

										{step===4 &&
											<Stack space={24}>
												<Box paddingBottom={8}>
													<Text4 medium>{intl.formatMessage({id:'page_decide_your_investment_calculator_title_q4'})}</Text4>
												</Box>

												<RadioGroup
													name="answer4"
													value={formData.answer4}
													onChange={(val=>{handleChangeAnyField('answer4', val)})}
													aria-labelledby="label"
												>
													<Stack space={16}>
														<RadioButton value="a">{intl.formatMessage({id:'page_decide_your_investment_calculator_q4_option1'})}</RadioButton>
														<RadioButton value="b">{intl.formatMessage({id:'page_decide_your_investment_calculator_q4_option2'})}</RadioButton>
														<RadioButton value="c">{intl.formatMessage({id:'page_decide_your_investment_calculator_q4_option3'})}</RadioButton>
														<RadioButton value="d">{intl.formatMessage({id:'page_decide_your_investment_calculator_q4_option4'})}</RadioButton>
													</Stack>
												</RadioGroup>
											</Stack>
										}

										{step===5 &&
											<Stack space={24}>
												<Box paddingBottom={8}>
													<Text4 medium>{intl.formatMessage({id:'page_decide_your_investment_calculator_title_q5'})}</Text4>
												</Box>

												<RadioGroup
													name="answer5"
													value={formData.answer5}
													onChange={(val=>{handleChangeAnyField('answer5', val)})}
													aria-labelledby="label"
												>
													<Stack space={16}>
														<RadioButton value="a">{intl.formatMessage({id:'page_decide_your_investment_calculator_q5_option1'})}</RadioButton>
														<RadioButton value="b">{intl.formatMessage({id:'page_decide_your_investment_calculator_q5_option2'})}</RadioButton>
														<RadioButton value="c">{intl.formatMessage({id:'page_decide_your_investment_calculator_q5_option3'})}</RadioButton>
													</Stack>
												</RadioGroup>
											</Stack>
										}

										{step===6 &&
											<Stack space={24}>
												<Box paddingBottom={8}>
													<Text4 medium>{intl.formatMessage({id:'page_decide_your_investment_calculator_title_q6'})}</Text4>
												</Box>

												<Stack space={16}>
													<EmailField
														name="email"
														value={formData.email}
														onChangeValue={(val=>{handleChangeAnyField('email', val)})}
														label={intl.formatMessage({id:'page_decide_your_investment_calculator_label_email'})}
													/>

													<Checkbox name="privacyPolicy" checked={formData.privacyPolicy} onChange={(val=>{handleChangeAnyField('privacyPolicy', val)})} >
														{intl.formatMessage({id:'page_decide_your_investment_calculator_legal'},{privacy_policy: <TextLink onPress={()=>{showAlert('_privacy_policy_')}}>{intl.formatMessage({id:'page_decide_your_investment_calculator_privacy_policy_link_text'})}</TextLink>})}</Checkbox>
												</Stack>
											</Stack>
										}
									</Box>

									<Box paddingTop={16} paddingBottom={24}>
										{step===6 ?
											<Inline space={16}>
												<ButtonPrimary disabled={isSubmitDisabled()} submit>
													{intl.formatMessage({id:'page_decide_your_investment_calculator_button_send'})}
												</ButtonPrimary>
											</Inline>
										:
											<Inline space={16}>
												{step!==1 &&
													<ButtonSecondary onPress={() => {setStep(step-1)}}>
														{intl.formatMessage({id:'page_decide_your_investment_calculator_button_prev'})}
													</ButtonSecondary>
												}

												<ButtonPrimary disabled={isSubmitDisabled()} onPress={() => {setStep(step+1)}}>
													{intl.formatMessage({id:'page_decide_your_investment_calculator_button_next'})}
												</ButtonPrimary>
											</Inline>
										}
									</Box>
								</Form>
							</Box>
						</ResponsiveLayout>
					</Boxed>
				}
			></HeroCalculator>

			<div id="results">
				{results &&
					<>
						{/* calculation results */}
						<style>{
							'.calculatorPage--content .result--card .verticalDivider{background:'+skinVars.colors.background+';}'
						}</style>
						<ResponsiveLayout className='content' fullWidth={true}>
							<ResponsiveLayout className='calculatorPage--content'>

								<Box paddingY={screenSize.isTabletOrBigger ? 64 : 32}>
									<Stack space={16}>
										<Text5>{intl.formatMessage({id:'page_decide_your_investment_calculator_result_title'})}</Text5>

										<Text4 light>{intl.formatMessage({id:'page_decide_your_investment_calculator_result_description'})}</Text4>
									</Stack>
								</Box>

								{/* ** RESULT ** */}
								<Box paddingX={screenSize.isDesktopOrBigger ? 80 : (screenSize.isTabletOrBigger ? 40 : 0)} paddingY={screenSize.isTabletOrBigger ? 48 : 32} className='result--card'>
									<Stack space={24}>
										<DisplayDataCard
											isInverse
											icon={
												<>
													{ currentOption==='agresivo' && <IconLightningRegular size={40} /> }
													{ currentOption==='moderado' && <IconChildRegular size={40} /> }
													{ currentOption==='conservador' && <IconLikeRegular size={40} /> }
												</>
											}
											title={
													currentOption==='agresivo' ? intl.formatMessage({id:'page_decide_your_investment_calculator_aggressive'})
													: (currentOption==='moderado' ? intl.formatMessage({id:'page_decide_your_investment_calculator_moderate'})
													: (currentOption==='conservador' ? intl.formatMessage({id:'page_decide_your_investment_calculator_conservative'}) : ''))
											}
											extra={
												<Box paddingTop={24} paddingBottom={16}>
													<Stack space={24}>
														<Stack space={8}>
															{!screenSize.isTabletOrBigger &&
																<Stack space={8}>
																	<Text3 regular>{intl.formatMessage({id:'page_decide_your_investment_calculator_result_years'})}</Text3>

																	<Text4 medium>{}</Text4>
																</Stack>
															}

															<Inline space={screenSize.isTabletOrBigger ? 64 : 32}>
																{screenSize.isTabletOrBigger &&
																	<Stack space={8}>
																		<Text3 regular>{intl.formatMessage({id:'page_decide_your_investment_calculator_result_years'})}</Text3>

																		<Text4 medium>{formDataSaving.age}</Text4>
																	</Stack>
																}

																{screenSize.isTabletOrBigger &&
																	<Box className='verticalDivider'></Box>
																}

																<Stack space={8}>
																	<Text3 regular>{intl.formatMessage({id:'page_decide_your_investment_calculator_text_annual_savings'})}</Text3>

																	<Text4 medium>{formatAmount(parseFloat(formDataSaving.saving.replace(',', '.')), true)+' €'}</Text4>
																</Stack>

																<Box className='verticalDivider'></Box>

																<Stack space={8}>
																	<Text3 regular>{intl.formatMessage({id:'page_decide_your_investment_calculator_text_annual_income'})}</Text3>

																	<Text4 medium>{formatAmount(parseFloat(formDataSaving.annualIncome.replace(',', '.')), true)+' €'}</Text4>
																</Stack>
															</Inline>
														</Stack>

														{currentOption==='agresivo' &&
															<Stack space={8}>
																<Text4 light>{intl.formatMessage({id:'page_decide_your_investment_calculator_aggressive_text1'})}</Text4>

																<Text2 regular>{intl.formatMessage({id:'page_decide_your_investment_calculator_aggressive_text2'})}</Text2>
															</Stack>
														}


														{currentOption==='moderado' &&
															<Stack space={8}>
																<Text4 light>{intl.formatMessage({id:'page_decide_your_investment_calculator_moderate_text1'})}</Text4>

																<Text2 regular>{intl.formatMessage({id:'page_decide_your_investment_calculator_moderate_text2'})}</Text2>
															</Stack>
														}


														{currentOption==='conservador' &&
															<Stack space={8}>
																<Text4 light>{intl.formatMessage({id:'page_decide_your_investment_calculator_conservative_text1'})}</Text4>

																<Text2 regular>{intl.formatMessage({id:'page_decide_your_investment_calculator_conservative_text2'})}</Text2>
															</Stack>
														}
													</Stack>
												</Box>
											}
											button={
												<Inline space={16}>
													<ButtonPrimary onPress={()=>{showProductsOption()}}>
														{intl.formatMessage({id:'page_decide_your_investment_calculator_see_products'})}
													</ButtonPrimary>

													<ButtonSecondary onPress={()=>{handleReset()}}>
														{intl.formatMessage({id:'page_decide_your_investment_calculator_repeat'})}
													</ButtonSecondary>
												</Inline>
											}
										/>

										{/* Ver productos:: */}
										<div id="products">
											{showProducts &&
												<Box paddingTop={8}>
													<Stack space={16}>
														<Text3 light>{intl.formatMessage({id:'page_decide_your_investment_calculator_products_title'})}</Text3>


														{currentResult && (currentResult.plans.length==0 || currentResult.funds.length==0) ?
															<Grid columns={1}>
																<DataCard
																	title=''
																	extra={
																		<Stack space={16}>
																			<Box paddingTop={16}>
																				{currentResult.plans.length>0 &&
																					<Stack space={8}>
																						<Text6>{intl.formatMessage({id:'page_decide_your_investment_calculator_products_pension_plans'})}</Text6>

																						<Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_decide_your_investment_calculator_products_pension_plans_description'})}</Text2>
																					</Stack>
																				}

																				{currentResult.funds.length>0 &&
																					<Stack space={8}>
																						<Text6>{intl.formatMessage({id:'page_decide_your_investment_calculator_products_investment_funds'})}</Text6>

																						<Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_decide_your_investment_calculator_products_investment_funds_description'})}</Text2>
																					</Stack>
																				}
																			</Box>

																			<Stack space={12}>
																				{currentResult && currentResult.plans.map((item, index)=>
																					<BoxedRow  key={'plans-'+index}
																						isInverse
																						title={(item?.porcentaje ?? 0)+'%'}
																						description={item.descripcion}
																						onPress={() => {handleMoreInformation(item)}} />
																				)}
																				{currentResult && currentResult.funds.map((item, index)=>
																					<BoxedRow key={'funds-'+index}
																						isInverse
																						title={(item?.porcentaje ?? 0)+'%'}
																						description={item.descripcion}
																						onPress={() => {handleMoreInformation(item)}} />
																				)}
																			</Stack>
																		</Stack>
																	}
																	button={
																		<ButtonSecondary showSpinner={currentProduct === formatProduct(currentResult.plans.length>0 ? currentResult.plans[0] : currentResult.funds[0])} onPress={()=>{handleContract(currentResult.plans.length>0 ? currentResult.plans[0] : currentResult.funds[0])}}>
																			{intl.formatMessage({id:'page_decide_your_investment_calculator_hire'})}
																		</ButtonSecondary>
																	}
																/>
															</Grid>
														:

															<Grid columns={screenSize.isTabletOrBigger ? 2 : 1} gap={screenSize.isTabletOrBigger ? 24 : 16}>
																<DataCard
																	title=''
																	extra={
																		<Stack space={16}>
																			<Box paddingTop={16}>
																				<Stack space={8}>
																					<Text6>{intl.formatMessage({id:'page_decide_your_investment_calculator_products_pension_plans'})}</Text6>

																					<Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_decide_your_investment_calculator_products_pension_plans_description'})}</Text2>
																				</Stack>
																			</Box>

																			<Stack space={12}>
																				{currentResult && currentResult.plans.map((item, index)=>
																					<BoxedRow key={'plans-'+index}
																						isInverse
																						title={(item?.porcentaje ?? 0)+'%'}
																						description={item.descripcion}
																						onPress={() => {handleMoreInformation(item)}} />
																				)}
																			</Stack>
																		</Stack>
																	}
																	button={
																		<ButtonSecondary showSpinner={currentResult ? currentProduct === formatProduct(currentResult.plans[0]) : false} onPress={()=>{currentResult && handleContract(currentResult.plans[0])}}>
																			{intl.formatMessage({id:'page_decide_your_investment_calculator_hire'})}
																		</ButtonSecondary>
																	}
																/>

																<DataCard
																	title=''
																	extra={
																		<Stack space={16}>
																			<Box paddingTop={16}>
																				<Stack space={8}>
																					<Text6>{intl.formatMessage({id:'page_decide_your_investment_calculator_products_investment_funds'})}</Text6>

																					<Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_decide_your_investment_calculator_products_investment_funds_description'})}</Text2>
																				</Stack>
																			</Box>

																			<Stack space={12}>
																				{currentResult && currentResult.funds.map((item, index)=>
																					<BoxedRow key={'funds-'+index}
																						isInverse
																						title={(item?.porcentaje ?? 0)+'%'}
																						description={item.descripcion}
																						onPress={() => {handleMoreInformation(item)}} />
																				)}
																			</Stack>
																		</Stack>
																	}
																	button={
																		<ButtonSecondary showSpinner={currentResult ? currentProduct === formatProduct(currentResult.funds[0]) : false} onPress={()=>{currentResult && handleContract(currentResult.funds[0])}}>
																			{intl.formatMessage({id:'page_decide_your_investment_calculator_hire'})}
																		</ButtonSecondary>
																	}
																/>
															</Grid>
														}
													</Stack>
												</Box>
											}
										</div>

										<Grid columns={screenSize.isTabletOrBigger ? 2 : 1} gap={screenSize.isTabletOrBigger ? 24 : 16}>
											{currentOption!=='conservador' &&
												<BoxedRow
													asset={<IconLikeRegular color={skinVars.colors.brand} />}
													title={intl.formatMessage({id:'page_decide_your_investment_calculator_products_conservative_profile'})}
													onPress={() => {handleChangeOption('conservador')}} />
											}

											{currentOption!=='moderado' &&
												<BoxedRow
													asset={<IconChildRegular color={skinVars.colors.brand} />}
													title={intl.formatMessage({id:'page_decide_your_investment_calculator_products_moderate_profile'})}
													onPress={() => {handleChangeOption('moderado')}} />
											}

											{currentOption!=='agresivo' &&
												<BoxedRow
													asset={<IconLightningRegular color={skinVars.colors.brand} />}
													title={intl.formatMessage({id:'page_decide_your_investment_calculator_products_aggressive_profile'})}
													onPress={() => {handleChangeOption('agresivo')}} />
											}
										</Grid>
									</Stack>
								</Box>
							</ResponsiveLayout>
						</ResponsiveLayout>
					</>
				}
			</div>


			<ResponsiveLayout className='content' fullWidth={true}>
					<ResponsiveLayout>

						<EmptyStateCustom
							largeImageUrl="../assets/img/necesitas-ayuda.jpg"
							title={intl.formatMessage({id:'page_for_investor_empty_state_title'})}
							description={intl.formatMessage({id:'page_for_investor_empty_state_description'})}
							button={
								<ButtonGroup
										primaryButton={
											<ButtonPrimary onPress={() => {router.push(getUrlNavigation(ContactPage)); trackEvent('para_inversor-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_choose_your_nvestment'})).replaceAll('-', '_')+'-contacto');}}> <img src={'../assets/img/contactar.svg'}/>
												{intl.formatMessage({id:'page_for_investor_empty_state_action_primary'})}
											</ButtonPrimary>
										}
								></ButtonGroup>
							}
						/>

					</ResponsiveLayout>
			</ResponsiveLayout>
		</LayoutPublic>
	);
};

export default CustomizeInvestmentFundPage;