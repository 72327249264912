import React from 'react';
import { useIntl } from 'react-intl';
import { useIonRouter } from '@ionic/react';

import LayoutPublic from '../../../components/layout/LayoutPublic';
import OurAppContainer from '../../../components/staticPages/ourApp/OurApp';

import { Box, GridLayout, ResponsiveLayout, skinVars, Stack, Text3, Text7, useScreenSize } from '@telefonica/mistica';
import TechnicalClaimsForm from '../../../components/technical-claims/TechnicalClaimsForm';
import './TechnicalClaims.scss';

const TechnicalClaimsPage: React.FC = () => {
  const intl = useIntl();
  const router = useIonRouter();
  const size = useScreenSize();

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_technical_claimst_seo_title'}), metaDescription:intl.formatMessage({id:'page_technical_claimst_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_technical_claimst_title'})}}
    >
      <ResponsiveLayout className='container' fullWidth={true} backgroundColor={"transparent"}>
        <ResponsiveLayout className='content' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
          <ResponsiveLayout className='content' backgroundColor={"transparent"}>
            <Box paddingY={40}>
              <GridLayout verticalSpace={24} template='8+4'
                left={
                  <Box paddingRight={80}>
                    <Stack space={16}>
                      <Box>
                        <Text7>{intl.formatMessage({id:'page_technical_claimst_title'})}</Text7>
                      </Box>
                      <Box>
                        <div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_technical_claimst_description'})}}></div>
                      </Box>
                    </Stack>
                  </Box>
                }
                right={
                  <></>
                }
              />
            </Box>
          </ResponsiveLayout>
        </ResponsiveLayout>
        <ResponsiveLayout className='content' fullWidth={true} backgroundColor={"transparent"}>
          <ResponsiveLayout className='content' backgroundColor={"transparent"}>
            <Box paddingY={40}>
              <Stack space={40}>
                <TechnicalClaimsForm></TechnicalClaimsForm>
              </Stack>
            </Box>
          </ResponsiveLayout>

          <ResponsiveLayout className='content' fullWidth={true}>
            <OurAppContainer/>
          </ResponsiveLayout>
        </ResponsiveLayout>
      </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default TechnicalClaimsPage;
