import { useIntl } from 'react-intl';


import { ButtonPrimary, ButtonSecondary, ResponsiveLayout, Image } from '@telefonica/mistica';

import ButtonGroup from '@telefonica/mistica/dist/button-group';
import EmptyStateCustom from '../../empty-state/EmptyStateCustom';
import { trackEvent } from '../../../utils/utils';

const OurAppContainer: React.FC = () => {

const intl = useIntl()

  return (
    <ResponsiveLayout>
        <EmptyStateCustom largeImageUrl="../assets/img/nuestra-app.jpg"
            title={intl.formatMessage({id:'page_about_us_content_our_app_title'})}
            description={intl.formatMessage({id:'page_about_us_content_our_app_description'})}
            button={
                <ButtonGroup
                primaryButton={
                    <ButtonPrimary onPress={()=>{ window.open(intl.formatMessage({id:'page_about_us_content_our_app_url_applestore'}), '_blank'); trackEvent('descargar_app-ios');} }> <Image noBorderRadius={true} width={17} height={18} src={'../assets/img/logo-apple.svg'}/>
                        {intl.formatMessage({id:'page_about_us_content_our_app_button_applestore'})}
                    </ButtonPrimary>
                }
                secondaryButton={
                    <ButtonSecondary onPress={()=>{ window.open(intl.formatMessage({id:'page_about_us_content_our_app_url_playstore'}), '_blank'); trackEvent('descargar_app-android');} }> <Image noBorderRadius={true} width={17} height={18} src={'../assets/img/logo-android.svg'}/>
                        {intl.formatMessage({id:'page_about_us_content_our_app_button_playstore'})}
                    </ButtonSecondary>
                }></ButtonGroup>
            } />
    </ResponsiveLayout>
  );
};

export default OurAppContainer;