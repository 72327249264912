import { Box, Boxed, Chip, LoadingBar, skinVars, Text } from '@telefonica/mistica';
import React, { useCallback, useEffect, useState } from 'react'
import {useDropzone} from 'react-dropzone'
import './DropZone.scss';
interface DropZoneProps {
    title: string,
    reset: boolean,
    onUploaded:(file:any) => void,
}

const DropZone: React.FC<DropZoneProps> = (prop) => {
    const [loading, setLoading]= useState(false);
    const [uploadedFile, setUploadedFile]= useState(false);
    const [filename, setFilename]= useState(null as null|string);
    const [myFiles, setMyFiles] = useState([] as any[]);


    useEffect(()=>{
        if(prop.reset){
          removeFile();
        }
    },[prop.reset]);

    const onDrop = useCallback((acceptedFiles:any) => {

      setMyFiles([acceptedFiles])

      acceptedFiles.forEach((file:any) => {
        const reader = new FileReader()

        //reader.onabort = () => console.log('file reading was aborted')
        //reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
        // Do whatever you want with the file contents
          const binaryStr = reader.result
          setUploadedFile(true)
          setFilename(file.name);
          file.binaryStr =binaryStr;
          prop.onUploaded(file);
          setLoading(false);
        }

        setUploadedFile(false);
        reader.readAsDataURL(file)
      })


      setLoading(true);

    }, [myFiles])
    const {getRootProps, getInputProps, isFocused} = useDropzone({onDrop, multiple: false, maxFiles: 1, accept:
      {
      'text/csv': ['.csv'] ,
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/plain': ['.txt']
    }})

    const removeFile= () => {
        setUploadedFile(false);
        setFilename(null);
        prop.onUploaded(null);
        setMyFiles([]);
    }

    return (
        <>
        <style>{
          '.dropzone--content.uploaded .boxed-dashed{border-color:'+skinVars.colors.brand+';}'
        }</style>
        <LoadingBar visible={loading} />
        <Box className={'dropzone--content '+(uploadedFile || isFocused ? 'uploaded' : '')}>
            <div {...getRootProps()}>
                <Boxed className='boxed-dashed'>
                    <Box className='text-center' padding={56}>
                        <input {...getInputProps()} />
                        {uploadedFile ?
                            <Chip onClose={() => {removeFile()}}>
                                {filename?.toString() ?? ''}
                            </Chip>
                        :
                            <Text size={14}  textAlign={'center'} color={skinVars.colors.textSecondary}>{prop.title}</Text>
                        }
                    </Box>
                </Boxed>
        </div>
      </Box>
      </>
    );
};

export default DropZone;