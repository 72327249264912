import { Box, Boxed, ButtonFixedFooterLayout, alert, ButtonLayout, ButtonPrimary, ButtonSecondary, DecimalField, Form, Grid, Inline, LoadingBar, RadioButton, RadioGroup, ResponsiveLayout, Select, skinVars, Stack, Text1, Text2, Text4, useScreenSize, useWindowSize } from '@telefonica/mistica';
import React, { useEffect, useState } from "react";
import { useIntl } from 'react-intl';

import { useIonRouter } from '@ionic/react';
import { BankAccount } from '../../entities/contract/bank-account.model';
import { Contract } from '../../entities/contract/contract';
import { OperationRefund } from '../../entities/contract/operations/operation-refund';
import { addRefund } from '../../utils/apiclient';
import { getCurrentUserContract, removeOperationRefund, setOperationRefund } from '../../utils/storage';
import { closeDialog, formatAccountNumber, formatAmount, formatNetAssetValue, maskAccountNumber } from '../../utils/utils';

interface RefundInvestmentFundsProps {
    contract: Contract | null,
    loading?: boolean,
    onCancel?:() => void,
    onConfirm:() => void
}

const RefundInvestmentFunds: React.FC<RefundInvestmentFundsProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const router = useIonRouter();
    const [contract, setContract] = useState(null as null|Contract);
    const [bankAccounts, setBankAccounts] = useState([] as BankAccount[]);


    const [loading, setLoading] = React.useState(true);

    //Loading form
    const [loadingForm, setLoadingForm] = React.useState(false);

    useEffect(()=>{
        removeOperationRefund();
        if(prop.contract){
            setContract(prop.contract);

            const uc = getCurrentUserContract();
            if(uc){
                setContract(uc);
                if(uc.cuentasReembolso && uc.cuentasReembolso.length>0){
                    setBankAccounts(uc.cuentasReembolso);
                }
            }
            setLoading(false);

        }
    },[prop.contract]);

    //Form
    const initFormData = {
        refundType: (process.env.REACT_APP_REEMBOLSO_TIPO_TOTAL??'T'),
        refundMode: (process.env.REACT_APP_REEMBOLSO_MODO_PARTICIPACIONES??'P'),
        iban: '',
        amount: '',
        amountType: process.env.REACT_APP_REEMBOLSO_MODALIDAD_BRUTO??'B',
    }
    const [formData, setFormData] = useState(initFormData);
    const [formErrors, setFormErrors] = useState([] as any);

    //Handle fields
    const handleError=(name:string) =>{
            if(formErrors && formErrors[name]){
            const error = formErrors[name];
            if(error){
                return intl.formatMessage({id: 'page_operate_refund_'+error.toLowerCase().replaceAll(' ', '_')});
            }
        }
        return null;
    }

    const validateField = (name:string, value:any)=>{
          return true;
    }

    const handleChangeAnyField= (name:string, value:any) => {
        const newValues = {
            ...formData,
            [name]: value,
        };
        setFormData(newValues);

        if(handleError(name)){
            delete formErrors[name];
        }
        validateField(name, value);
    };

    const handleSubmit = (data: any): Promise<void> =>
        new Promise((resolve) => {
            setLoadingForm(true);

            let operation = getOperation();

            addRefund(operation).then((result)=>{
                if(result.result){
                    operation.summary = getSummary();
                    setOperationRefund(operation);
                    prop.onConfirm();
                }else if(result.errors){
                    const errors = result.errors as any;
                    setFormErrors(result.errors);

                    if(errors['alert']){
                        let message = '';
                        const errorKey = 'page_operate_refund_'+errors['alert'].toLowerCase().replaceAll(' ', '_');

                        message = intl.formatMessage({id: errorKey});

                        if(message.length>0 && message!=errorKey){
                            closeDialog().then(()=>{
                                alert({
                                    title: intl.formatMessage({id:'alert_info_title'}),
                                    message: message??''
                                });
                            });
                        }
                    }

                }
            }).finally(()=>{
                setLoadingForm(false);
                resolve();
            })
        });

    const getOperation = ()=>{
        let operation: OperationRefund = {
            refundType: formData.refundType,
            iban: formData.iban,
            refundMode: formData.refundType===process.env.REACT_APP_REEMBOLSO_TIPO_PARCIAL ? formData.refundMode : undefined,
            amount: formData.refundType===process.env.REACT_APP_REEMBOLSO_TIPO_PARCIAL ? parseFloat(formData.amount.replace(',', '.')) : undefined,
            amountType: formData.refundType===process.env.REACT_APP_REEMBOLSO_TIPO_PARCIAL && formData.refundMode===process.env.REACT_APP_REEMBOLSO_MODO_IMPORTE ? formData.amountType : undefined,
            confirm: false
        };
        return operation;
    }

    const getSummary= () => {
        let summary: Array<{title:string, value:string}> = [];
        if(formData.refundType===process.env.REACT_APP_REEMBOLSO_TIPO_TOTAL){
            summary.push({title:intl.formatMessage({id:'page_operate_refund_refund_type'}), value:'Total'});
        }else{


            if(formData.refundMode===process.env.REACT_APP_REEMBOLSO_MODO_PARTICIPACIONES){
                summary.push({title:intl.formatMessage({id:'page_operate_refund_refund_type'}), value:'Parcial por participaciones'});
                summary.push({title:intl.formatMessage({id:'page_operate_refund_participations'}), value: formatNetAssetValue(parseFloat(formData.amount.replace(',', '.')))});
            }

            if(formData.refundMode===process.env.REACT_APP_REEMBOLSO_MODO_IMPORTE){
                summary.push({title:intl.formatMessage({id:'page_operate_refund_refund_type'}), value:'Parcial por importe'});
                summary.push({title:intl.formatMessage({id:'page_operate_refund_amount'}), value: formatAmount(parseFloat(formData.amount.replace(',', '.')))+'€'});
                summary.push({title:intl.formatMessage({id:'page_operate_refund_type'}), value: formData.amountType===process.env.REACT_APP_REEMBOLSO_MODALIDAD_BRUTO ? 'Bruto' : 'Neto'});
            }
        }

        summary.push({title:intl.formatMessage({id:'page_operate_refund_destiny'}), value: maskAccountNumber(formData.iban)});
        return summary;
    };


    const isSubmitDisabled = () => {
        return (formData.refundType===process.env.REACT_APP_REEMBOLSO_TIPO_PARCIAL &&
               ((formData.amount.length===0) || (formData.refundMode===process.env.REACT_APP_REEMBOLSO_MODO_IMPORTE && formData.amountType.length===0))) ||
               (formData.iban.length===0);
    };

    return (
        <>{prop.loading ?
            <></>
        :
            <>
                <LoadingBar  visible={loading || loadingForm} />
                <Form onSubmit={handleSubmit} initialValues={initFormData} className={loadingForm ? 'loading' : ''}>
                <ResponsiveLayout className="inner">
                    <ButtonFixedFooterLayout
                        button={
                            <ButtonLayout>
                                <ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_operate_refund_continue'})}</ButtonPrimary>
                            </ButtonLayout>
                        }
                        secondaryButton={screenSize.isDesktopOrBigger ? <ButtonLayout><ButtonSecondary onPress={() => {prop.onCancel ? prop.onCancel() : router.goBack()}}>{intl.formatMessage({id:'page_operate_refund_return_operation'})}</ButtonSecondary></ButtonLayout> : undefined}
                    >

                        <Box paddingBottom={screenSize.isDesktopOrBigger ? 56 : 16}>
                            <Stack space={32}>
                                <Stack space={16}>
                                    <Text4 medium>{intl.formatMessage({id:'page_operate_refund_refund_type'})}</Text4>

                                    <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                        <Stack space={screenSize.isDesktopOrBigger ? 24 : 16}>
                                            <RadioGroup name="refundType" onChange={(val:any)=> handleChangeAnyField('refundType', val)}>
                                                <Grid columns={2} gap={screenSize.isDesktopOrBigger ? 24 : 16}>
                                                    <RadioButton
                                                        value={process.env.REACT_APP_REEMBOLSO_TIPO_TOTAL??'T'}
                                                        render={({ controlElement, checked, labelId }:any) => (
                                                            <Boxed className={'rbx-option '+ (checked ? 'active' : '')}>
                                                                <Box padding={16}>
                                                                    <Inline space={8} alignItems='center'>
                                                                        {controlElement}
                                                                        <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_refund_full_refund'})}</Text2>
                                                                    </Inline>
                                                                </Box>
                                                            </Boxed>
                                                        )}
                                                    />

                                                    <RadioButton
                                                        value={process.env.REACT_APP_REEMBOLSO_TIPO_PARCIAL??'P'}
                                                        render={({ controlElement, checked, labelId }:any) => (
                                                            <Boxed className={'rbx-option '+ (checked ? 'active' : '')}>
                                                                <Box padding={16}>
                                                                    <Inline space={8} alignItems='center'>
                                                                        {controlElement}
                                                                        <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_refund_partial_refund'})}</Text2>
                                                                    </Inline>
                                                                </Box>
                                                            </Boxed>
                                                        )}
                                                    />
                                                </Grid>
                                            </RadioGroup>
                                        </Stack>
                                    </Grid>
                                </Stack>

                                {/* Rbx reembolso parcial */}
                                {formData.refundType===(process.env.REACT_APP_REEMBOLSO_TIPO_PARCIAL??'P') &&
                                    <Stack space={16}>
                                        <Text4 medium>{intl.formatMessage({id:'page_operate_refund_partial_refund'})}</Text4>

                                        <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                            <Stack space={16}>
                                                <RadioGroup name="refundMode" onChange={(val:any)=> handleChangeAnyField('refundMode', val)}>
                                                    <Inline space={16}>
                                                        <RadioButton
                                                            value={process.env.REACT_APP_REEMBOLSO_MODO_PARTICIPACIONES??'P'}
                                                            render={({ controlElement, checked, labelId }:any) => (
                                                                <Inline space={8} alignItems='center'>
                                                                    {controlElement}
                                                                    <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_refund_partial_refund_by_shares'})}</Text2>
                                                                </Inline>
                                                            )}
                                                        />

                                                        <RadioButton
                                                            value={process.env.REACT_APP_REEMBOLSO_MODO_IMPORTE??'I'}
                                                            render={({ controlElement, checked, labelId }:any) => (
                                                                <Inline space={8} alignItems='center'>
                                                                    {controlElement}
                                                                    <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_refund_partial_refund_by_import'})}</Text2>
                                                                </Inline>
                                                            )}
                                                        />
                                                    </Inline>
                                                </RadioGroup>

                                                <DecimalField maxDecimals={2} fullWidth error={handleError('amount')!==null} helperText={handleError('amount')??undefined} name="amount" label={'Cantidad'} onChangeValue={(val:any)=> handleChangeAnyField('amount', val)}  />

                                                {/* Rbx por importe */}
                                                {formData.refundMode===(process.env.REACT_APP_REEMBOLSO_MODO_IMPORTE??'I') &&
                                                    <Select
                                                        fullWidth
                                                        label={intl.formatMessage({id:'page_operate_refund_refund_type'})}
                                                        name="amountType"
                                                        value={formData.amountType}
                                                        error={handleError('amountType')!==null} helperText={handleError('amountType')??undefined}
                                                        onChangeValue={(val:any)=> handleChangeAnyField('amountType', val)}
                                                        options={[{text:'Bruto', value: process.env.REACT_APP_REEMBOLSO_MODALIDAD_BRUTO??'B'},
                                                                  {text:'Neto', value: process.env.REACT_APP_REEMBOLSO_MODALIDAD_NETO??'N' }]}
                                                    />
                                                }
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                }

                                <Stack space={24}>
                                    <Stack space={8}>
                                        <Text4 medium>{intl.formatMessage({id:'page_operate_refund_destiny'})}</Text4>

                                        <>
                                            {!screenSize.isDesktopOrBigger &&
                                                <Text2 regular>{intl.formatMessage({id:'page_operate_refund_holder_detail'})}</Text2>
                                            }
                                        </>
                                    </Stack>

                                    <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={[40, 4]}>
                                        <Select
                                            fullWidth
                                            label={intl.formatMessage({id:'page_operate_refund_choose_account_bank'})}
                                            name="iban"
                                            value={formData.iban}
                                            error={handleError('iban')!==null} helperText={handleError('iban')??undefined}
                                            onChangeValue={(val:any)=> handleChangeAnyField('iban', val)}
                                            options={bankAccounts.map(item => ({text:formatAccountNumber(item.IBAN??''), value:item.IBAN??''}))}
                                        />

                                        {screenSize.isDesktopOrBigger &&
                                            <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_refund_holder_detail2'})}</Text1>
                                        }
                                    </Grid>
                                </Stack>

                            </Stack>
                        </Box>

                    </ButtonFixedFooterLayout>
                </ResponsiveLayout>

                </Form>
            </>
        }</>
    );
};

export default RefundInvestmentFunds;