import { GoogleMap, InfoBox, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Box, Boxed, Image, ResponsiveLayout, skinVars, Text2, useScreenSize } from '@telefonica/mistica';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import './Map.scss';


const Map: React.FC = () => {

    const size = useScreenSize();
    const intl = useIntl();
    const containerStyle = {
        width: '100%',
        height: size.isDesktopOrBigger ? '80%' : '300px'
    };

    const center = {
        lat: 40.51427979202002,
        lng: -3.6633809137915394
    };

    const marginLeft = ('-160px');
    const maxWidth = ('320px');

    const options = {
        styles : [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]}]
    }
    const [map, setMap] = useState(null as any);
    const [openMap, setOpenMap] = useState(false);
    const [isGoogleMap, setIsGoogleMap] = useState(false);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_API_KEY_GOOGLE_MAPS ?? ''
    })

    const onLoad = React.useCallback(function callback(map:any) {
        setTimeout(function(){
            //const bounds = new window.google.maps.LatLngBounds(center);
            //map.fitBounds(bounds);

            setOpenMap(true);

        }, 200);

      }, [])

    const onUnmount = React.useCallback(function callback(map:any) {
        setMap(null)
    }, [])

  return (isLoaded && isGoogleMap) ? (
      <GoogleMap
        id="google-maps"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
        options={options as any}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <style>{`.infoBox{margin-left:`+marginLeft+`;margin-top:10px}.infoBox >img{display:none} .infoBox >div{max-width:`+maxWidth+`}`}</style>
         <Marker icon={window.location.origin+'/assets/img/marker-icon.png'} onClick={()=>{setOpenMap(!openMap)}} clickable={true}  title={'Fonditel'} position={{ lat: 40.51513459626665, lng: -3.6631393340525267 }}
            >
                {openMap &&
                    <InfoBox onCloseClick={()=>{setOpenMap(!openMap)}}>
                        <Boxed isInverse>
                            <Box padding={8}>
                                <Text2 regular color={skinVars.colors.background}>Ronda de la Comunicación S/N - Oficina en 'La Ronda'</Text2>
                            </Box>
                        </Boxed>
                    </InfoBox>
                }
        </Marker>
      </GoogleMap>
  ) : <ResponsiveLayout fullWidth>
        <Box className='contact-map'>
            <Image src='/assets/img/contact-map.jpg'></Image>
        </Box>
        <Box className="contact-info" paddingX={32} paddingY={12}>
            <Box paddingY={12}>
                <Text2 regular>{intl.formatMessage({id:'page_map_direction'})}</Text2><br/>
                <Text2 regular>{intl.formatMessage({id:'page_map_direction2'})}</Text2><br/>
                <Text2 regular>{intl.formatMessage({id:'page_map_direction3'})}</Text2>
            </Box>
        </Box>
      </ResponsiveLayout>
}

export default React.memo(Map)