import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, GridLayout, Inline, Row, RowList, SkeletonLine, skinVars, Text, Text3, useScreenSize } from '@telefonica/mistica';
import Accordion from '../accordion/Accordion';
import './faqs.scss';
import { trackEvent } from '../../utils/utils';
import slugify from 'react-slugify';

interface FaqsProp {
    loading?: boolean,
    values: Array<{title:string, icon:React.ReactNode, items: Array<{title:string, value:any}> }>,
    prefixSlug: string,
}

const Faqs: React.FC<FaqsProp> = (prop) => {

    const [values, setValues] = useState([] as Array<{title:string, icon:React.ReactNode, active?:boolean, items: Array<{title:string, value:any}> }>);

  useEffect(()=>{
    handleActiveItem(0);
  }, []);

  const handleActiveItem = (value: number) => {
    let items = prop.values as Array<{title:string, icon:React.ReactNode, active?:boolean, items: Array<{title:string, value:any}> }>;

    const itemsActive = items.map((item, index)=>{
        if(index==value){
            item.active=true;
        }else{
            item.active=false;
        }
        return item;
    });

    trackEvent(prop.prefixSlug+'-'+itemsActive[value].title);
    setValues(itemsActive);
  };

const intl = useIntl();
const screenSize = useScreenSize();
const size = useScreenSize();

    return (
        <>
            <style>{
                '.faqs--container [data-component-name="RowList"]:before{background:'+(skinVars.colors.divider)+';}'+
                '.faqs--container .pane-menu:before{background:'+(skinVars.colors.divider)+';}'+
                '.faqs--container .html-content {color:'+(skinVars.colors.textSecondary)+'!important;}'+
                '.faqs--container .html-content *{color:'+(skinVars.colors.textSecondary)+'!important;}'+
                '.faqs--container .html-content a{color:'+(skinVars.colors.brand)+'!important;}'
            }</style>
            <Box className='faqs--container'>
                <GridLayout
                    template='3+9'
                    left={
                        <Box>
                            {prop.loading ?
                                <RowList dataAttributes={{"list-menu":true}}>
                                    <Row title=''
                                        extra={
                                            <SkeletonLine />
                                        }
                                    />
                                    <Row title=''
                                        extra={
                                            <SkeletonLine   />
                                        }
                                    />
                                </RowList>
                            :
                                <RowList dataAttributes={{"list-menu":true}}>
                                    {values.map((item, index)=>(

                                        <Row title=''
                                            onPress={() => {handleActiveItem(index)}}
                                            right={null}
                                            key={'faqs-items'+index}
                                            extra={
                                                <Inline space={'between'} alignItems='center'>
                                                    <Text color={(item.active ?? false) ? skinVars.colors.brand : skinVars.colors.textPrimary }>
                                                        <Inline space={16} alignItems='center'>
                                                            {item.icon}
                                                            <Text3 regular color={'currentColor'}>{item.title}</Text3>
                                                        </Inline>
                                                    </Text>
                                                    {item.active &&
                                                        <div className='hr--vertical' style={{backgroundColor:skinVars.colors.brand}}></div>
                                                    }
                                                </Inline>
                                            }
                                        />
                                    ))}
                                </RowList>
                            }
                        </Box>
                    }
                    right={
                        <Box paddingLeft={screenSize.isDesktopOrBigger ? 80 : 0} paddingBottom={screenSize.isDesktopOrBigger ? 80 : 64} className='pane-menu'>

                            {prop.loading ?
                                <RowList dataAttributes={{"list-menu":true}}>
                                    <Row title=''
                                        extra={
                                            <SkeletonLine   />
                                        }
                                    />
                                    <Row title=''
                                        extra={
                                            <SkeletonLine   />
                                        }
                                    />
                                    <Row title=''
                                        extra={
                                            <SkeletonLine   />
                                        }
                                    />
                                    <Row title=''
                                        extra={
                                            <SkeletonLine   />
                                        }
                                    />
                                    <Row title=''
                                        extra={
                                            <SkeletonLine   />
                                        }
                                    />
                                    <Row title=''
                                        extra={
                                            <SkeletonLine   />
                                        }
                                    />
                                </RowList>
                            :
                            values.map((itemParent, indexParent)=>(
                                itemParent.active===true &&
                                itemParent.items.map((item, index)=>(

                                    <Accordion prefixSlug={prop.prefixSlug+'-'+slugify(item.title).replaceAll('-', '_')} key={'faqs-'+indexParent+'-'+index} title={item.title}>
                                        <div className='html-content' dangerouslySetInnerHTML={{__html: item.value}}></div>
                                    </Accordion>
                                ))
                            ))}
                        </Box>
                    }
                />
            </Box>
        </>
    );
};

export default Faqs;
