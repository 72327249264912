import { Box, Grid, Image, Inline, ResponsiveLayout, Stack, Text6, skinVars, useScreenSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import './MembersOf.scss';

const MembersOf: React.FC = () => {

const intl = useIntl()
const screenSize = useScreenSize();

  return (
    <ResponsiveLayout backgroundColor={skinVars.colors.backgroundContainerAlternative} className='membersOf--content'>
        <Box paddingY={40}>
            <Stack space={40}>
                <Box className='boxAlignCenter'>
                    <Text6>{intl.formatMessage({id:'page_member_of_title'})}</Text6>
                </Box>

                <Box className='limitBox'>
                    <Grid columns={screenSize.isDesktopOrBigger ? 3 : 1} gap={screenSize.isDesktopOrBigger ? 8 : 40}>
                        <Inline space={'evenly'} alignItems={'center'}>
                            <Image src={"/assets/img/pri.png"} height={46} aspectRatio={0} alt='' noBorderRadius={true} />
                        </Inline>

                        <Inline space={'evenly'} alignItems={'center'}>
                            <Image src={"/assets/img/iigcc.png"} height={52} aspectRatio={0} alt='' noBorderRadius={true} />
                        </Inline>

                        <Inline space={'evenly'} alignItems={'center'}>
                            <Image src={"/assets/img/spain_sif.png"} height={48} aspectRatio={0} alt='' noBorderRadius={true} />
                        </Inline>
                    </Grid>
                </Box>
            </Stack>
        </Box>
    </ResponsiveLayout>
  );
};

export default MembersOf;