import { IonFooter, useIonRouter } from '@ionic/react';
import { Box, Divider, Inline, ResponsiveLayout, skinVars, Stack, Text, TextLink, useScreenSize } from '@telefonica/mistica';
import React, { useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';
import { NavOption } from '../../entities/commons/nav-option.model';
import HomePage from '../../pages/public/home/HomePage';
import { getFooterPrivateNavigation, getUrlNavigation } from '../../utils/navigation';
import { getUser } from '../../utils/storage';
import { vibration } from '../../utils/utils';
import './FooterPrivateArea.scss';

interface FooterPrivateAreaProps { }

const FooterPrivateArea: React.FC<FooterPrivateAreaProps> = () => {
const screenSize = useScreenSize();
const router = useIonRouter();
const intl = useIntl();
const user = getUser();

const [privateNavigation, setPrivateNavigation] = React.useState([] as NavOption[]);
const [optionMenuSelected, setOptionMenuSelected] = React.useState(-1);
useLayoutEffect(()=>{
    const options = getFooterPrivateNavigation(user.selectedRole);

    setPrivateNavigation(options);
    let optionSelected = options.findIndex(item=>router.routeInfo.pathname.indexOf(getUrlNavigation(item.page))!==-1);
    setOptionMenuSelected(optionSelected);
}, []);

const handleClickNavMainOption = (index:number) =>{

    vibration();

    if(privateNavigation[index].page!==undefined){
        if(privateNavigation[index]!==undefined && privateNavigation[index].page!==undefined){
            router.push(getUrlNavigation(privateNavigation[index]?.page??HomePage));
        }
    }
}


  return (
    <ResponsiveLayout className="footer-private-area" fullWidth={true} backgroundColor={skinVars.colors.background}>
        <Divider></Divider>
        <IonFooter >
            <ResponsiveLayout backgroundColor={'transparent'} isInverse>
                <Inline space={'between'} alignItems='center'>
                    {privateNavigation && Array.isArray(privateNavigation) && privateNavigation.map((item, i) => {
                        return (<TextLink key={'footer-private-nav-content'+i} onPress={() => handleClickNavMainOption(i)}>
                                    <Box padding={8}>
                                        <Stack space={2}>
                                            <Text color={i===optionMenuSelected ? skinVars.colors.brand : skinVars.colors.neutralMedium}>{item.iconSelected && i===optionMenuSelected ? item.iconSelected : item.icon}</Text>
                                            <Text size={10} color={i===optionMenuSelected ? skinVars.colors.brand : skinVars.colors.neutralMedium}>{intl.formatMessage({id:item.title})}</Text>
                                        </Stack>
                                    </Box>
                                </TextLink>)})
                    }
                </Inline>
            </ResponsiveLayout>
        </IonFooter>
    </ResponsiveLayout>
  );
};

export default FooterPrivateArea;
