import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import EmptyCard from '../../../../../components/empty-card/EmptyCard';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import ProductCard from '../../../../../components/product/ProductCard';

import { GlobalPosition } from '../../../../../entities/globalPosition/globalPosition';
import { getGlobalPosition, setReleaseState, updateStadisticsBanner } from '../../../../../utils/apiclient';

import { Box, ButtonLayout, ButtonPrimary, ButtonSecondary, Callout, Carousel, DisplayDataCard, IconBellRegular, IconEcoRegular, IconFlowerRegular, IconSearchFileRegular, IconUmbrellaRegular, LoadingBar, PosterCard, ResponsiveLayout, SkeletonRectangle, SkeletonText, skinVars, Stack, Text3, Text4, Text5, Text6, TextLink, useScreenSize, useWindowSize } from '@telefonica/mistica';

import { useIonRouter } from '@ionic/react';
import { UserProduct } from '../../../../../entities/products/userProduct';
import { getUrlNavigation } from '../../../../../utils/navigation';
import { getUser, removeBanners, removeCurrentUserProduct, updatePostIts } from '../../../../../utils/storage';
import { formatAnualProfit, formatBalance, formatDate, formatProduct, isExternalUrl, isNativeApp, trackEvent, validHttpUrl } from '../../../../../utils/utils';
import ContractCustomerPage from '../contract/ContractCustomerPage';
// import './GlobalPositionCustomerPage.scss';
import GlobalCard from '../../../../../components/multi-data-card/GlobalCard';
import { useProductActions } from '../../../../../components/operate/ProductActions';
import { Banner } from '../../../../../entities/clients/banner';
import { PostIt } from '../../../../../entities/clients/postit';
import { Product } from '../../../../../entities/products/product';
import slugify from 'react-slugify';

const GlobalPositionCustomerPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
  const size = useWindowSize();
  const [loading, setLoading] = useState(true);
  const router = useIonRouter();

  const [globalPosition, setGlobalPosition] = useState({} as GlobalPosition);
  const [numberCardGlobalPosition, setNumberCardGlobalPosition] = useState(2);
  const [banner, setBanner] = useState({} as Banner);
  const [bannerVisible, setBannerVisible] = useState(true);
  const [postits, setPostIts] = useState([] as PostIt[]);

  const { moreInfo, operate } = useProductActions()

  useEffect(()=>{
    removeCurrentUserProduct();
    setLoading(true);

    const user = getUser();

    if(user){
      if(user.postIts && Object.values(user.postIts).length>0){
        setPostIts(Object.values(user.postIts) as PostIt[]);
      }

      if(user.banner != null){
        setBanner(user.banner);
      }
    }

    getGlobalPosition().then(globalPosition=>{
      setLoading(false);
      if(globalPosition)
        if(globalPosition.planes && globalPosition.planes.length > 0 && globalPosition.fondos && globalPosition.fondos.length > 0 && globalPosition.otros && globalPosition.otros.length > 0){
          setNumberCardGlobalPosition(3)
        }

        if(globalPosition.planes && globalPosition.planes.length === 0 && globalPosition.fondos && globalPosition.fondos.length === 0 && globalPosition.otros && globalPosition.otros.length === 0){
          setNumberCardGlobalPosition(0);
        }
        setGlobalPosition(globalPosition);
    });
  }, []);

  function getCarouselRights(globalPosition: GlobalPosition): JSX.Element[] {
    let dataDesktop : JSX.Element[] =[];
    let valuesMobile : Array<{title:string, value:string, small?:string}> =[]
    let dataMobile : JSX.Element[] =[];

    if (globalPosition.planes && globalPosition.planes.length) {
      const saldos = globalPosition.planes.map((planes) => parseFloat(planes.saldo ? (planes.saldo.toString()) : ('0,00')  || '0,00'));
      const saldoTotal = saldos.reduce((total, saldo) => total + saldo, 0);
      const saldoFormateado = formatBalance(saldoTotal);
      valuesMobile.push({title:intl.formatMessage({ id: 'page_customer_global_position_pension_plans'}), value: saldoFormateado+' €', small: intl.formatMessage({ id: 'page_customer_global_position_total_rights' })});
      dataDesktop.push (
        <DisplayDataCard
          isInverse
          icon={<IconUmbrellaRegular size={48} />}
          title={intl.formatMessage({ id: 'page_customer_global_position_my_pension_plans' })}
          extra={
            <Stack space={8}>
              <Text6>{saldoFormateado} €</Text6>
              <Text3 regular>{intl.formatMessage({ id: 'page_customer_global_position_total_rights' })}</Text3>
            </Stack>
          }
        />
      );
    }

    if (globalPosition.fondos && globalPosition.fondos.length) {
      const saldos = globalPosition.fondos.map((fondos) => parseFloat(fondos.saldo ? (fondos.saldo.toString()) : ('0,00') || '0,00'));
      const saldoTotal = saldos.reduce((total, saldo) => total + saldo, 0);
      const saldoFormateado = formatBalance(saldoTotal);
      valuesMobile.push({title:intl.formatMessage({ id: 'page_customer_global_position_investment_funds'}), value: saldoFormateado+' €', small: intl.formatMessage({ id: 'page_customer_global_position_total_balance' })});
      dataDesktop.push (
        <DisplayDataCard
          isInverse
          icon={<IconEcoRegular size={48} />}
          title={intl.formatMessage({ id: 'page_customer_global_position_my_investment_funds' })}
          extra={
            <Stack space={8}>
              <Text6>{saldoFormateado} €</Text6>
              <Text3 regular>{intl.formatMessage({ id: 'page_customer_global_position_total_balance' })}</Text3>
            </Stack>
          }
        />
      );
    }

    if (globalPosition.otros && globalPosition.otros.length) {
      const saldos = globalPosition.otros.map((otros) => parseFloat(otros.saldo ? (otros.saldo.toString()) : ('0,00') || '0,00'));
      const saldoTotal = saldos.reduce((total, saldo) => total + saldo, 0);
      const saldoFormateado = formatBalance(saldoTotal);
      valuesMobile.push({title:intl.formatMessage({ id: 'page_customer_global_position_other_products'}), value: saldoFormateado+' €', small: intl.formatMessage({ id: 'page_customer_global_position_total_balance' })});
      dataDesktop.push (
        <DisplayDataCard
          isInverse
          icon={<IconFlowerRegular size={48} />}
          title={intl.formatMessage({ id: 'page_customer_global_position_other_products' })}
          extra={
            <Stack space={8}>
              <Text6>{saldoFormateado} €</Text6>
              <Text3 regular>{intl.formatMessage({ id: 'page_customer_global_position_total_balance' })}</Text3>
            </Stack>
          }
        />
      );
    }

    if(valuesMobile.length==3){
      dataMobile.push(<GlobalCard values={valuesMobile.slice(0, 2)} />)
      dataMobile.push(<GlobalCard values={valuesMobile.slice(2)} />)
    }else{
      dataMobile.push(<GlobalCard values={valuesMobile} />)
    }

    return screenSize.isDesktopOrBigger ? dataDesktop : dataMobile;
  }

  const handleMoreInformation = (product:UserProduct): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('perfil-'+formatProduct(product.product)+'-mas_informacion');
      if(product.product){
        moreInfo(product.product).finally(()=>{
          resolve();
        });
      }
  });

  const handleOperate = (product:UserProduct): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('perfil-'+formatProduct(product.product).replaceAll('-', '_')+'-operar');
      operate(product).finally(()=>{
        resolve();
      });
  });

  const handleClosePostIt = (cdInfo:number) =>  {
    trackEvent('perfil-avisos-ocultar');
    let currentPostits = postits.filter(item=>item.cdInfo!=cdInfo);
    setPostIts(currentPostits);
    updatePostIts(currentPostits);
    setReleaseState(cdInfo, 'Eliminado');

  };

  const handleCloseBanner = (cdBanner:number) =>  {
    setBannerVisible(false);
    trackEvent('perfil-banner-ocultar');
    updateStadisticsBanner({cdBanner: cdBanner, view: false, click: true, close: false}).then(response=>{
      removeBanners();

    });
  };

  const handleDontShow = (cdBanner:number) =>  {
    setBannerVisible(false);
    trackEvent('perfil-banner-no_volver_mostrar');
    updateStadisticsBanner({cdBanner: cdBanner, view: false, click: true, close: true}).then(response=>{
      removeBanners();
    });
  };

  const handleClickBanner = (cdBanner:number, vinculo:string, mismaVentana:boolean) => {
    setLoading(true);
    trackEvent('perfil-banner-click');
    updateStadisticsBanner({cdBanner: cdBanner, view: true, click: true, close: false}).then(response=>{
      setLoading(false);
      if(validHttpUrl(vinculo)){
        if(isExternalUrl(vinculo)){
          if(!isNativeApp){
            window.open(vinculo, '_blank');
          }
        }else{
          if(mismaVentana){
            router.push(vinculo);
          }else{
            if(!isNativeApp){
              window.open(vinculo, '_blank');
            }
          }
        }
      }
    });
  };

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_customer_global_position_title'})}}
      pageInfo={{option: process.env.REACT_APP_MENU_POSICION_GLOBAL, role:'client'}}
      showMenu
      welcome>

      <ResponsiveLayout className='inner first' fullWidth>
        <LoadingBar visible={loading}/>

        {banner.cdBanner && bannerVisible && !isNativeApp &&
            <Box paddingY={32} >
              <PosterCard
                onPress={()=>{handleClickBanner(banner.cdBanner, banner.vinculo ? banner.vinculo: '', banner.mismaVentana ? banner.mismaVentana : false)}}
                aspectRatio={'auto'}
                onClose={()=>{handleCloseBanner(banner.cdBanner)}}
                width="auto"
                height= {screenSize.isDesktopOrBigger ? "300px" : "400px" }
                backgroundImage= {
                  screenSize.isDesktopOrBigger ?
                  (banner.nbArchivoDesktop ? banner.nbArchivoDesktop : '') :
                  (banner.nbArchivoMobile ? banner.nbArchivoMobile : (banner.nbArchivoDesktop ? banner.nbArchivoDesktop : ''))
                }
                title=''
                description=''
                />
              <TextLink className='global-position-dontshow-link' style={{ color:skinVars.colors.background}}  onPress={()=>{handleDontShow(banner.cdBanner)}}>{intl.formatMessage({id:'page_customer_global_position_dont_show'})}</TextLink>
            </Box>
        }

        {postits &&  postits.length>0 &&
          <Box paddingBottom={32}>
            <Stack space={16}>
              {postits.map((postit) => (
                <Callout
                  key={'post-it-'+postit.cdInfo}
                  icon={<IconBellRegular />}
                  onClose={() => {handleClosePostIt(postit.cdInfo)}}
                  title={postit.notificadoPor ? postit.notificadoPor : postit.titulo }
                  description={postit.descripcion ? postit.descripcion.replace(/<[^>]+>/g, '') : ''}
                />
              ))}
            </Stack>
          </Box>
        }

        {screenSize.isDesktopOrBigger &&
          <Box paddingBottom={32}>
            <Text5>{intl.formatMessage({id:'page_customer_global_position_title'})}</Text5>
          </Box>
        }

        {loading ?
          <Box paddingBottom={40}>
            <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1300 ? numberCardGlobalPosition : 2)}
              items={[
                <SkeletonRectangle height={screenSize.isMobile ? 110 : 242} />,
                <SkeletonRectangle height={screenSize.isMobile ? 110 : 242} />
              ]} />
          </Box>
        :
          (numberCardGlobalPosition>0 &&
            (<Box paddingBottom={40}>
              {screenSize.isDesktopOrBigger ?
                <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1300 ? numberCardGlobalPosition : 2)}
                  items={getCarouselRights(globalPosition)}
                />
              :

              <Carousel initialActiveItem={0}  itemsPerPage={1}
                  items={getCarouselRights(globalPosition)}
                />
              }
            </Box>))
        }
      </ResponsiveLayout>

      <ResponsiveLayout className={ loading || (globalPosition.planes && globalPosition.planes.length>0)  ? 'full' : 'inner'} fullWidth>
        <Box paddingBottom={24}>
          {loading ?
            <SkeletonText ariaLabel="loading" />
          :
            <Text4 medium>{intl.formatMessage({id:'page_customer_global_position_my_pension_plans'})}</Text4>
          }
        </Box>

        <Box paddingBottom={40}>
          {loading ?
            <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1700 ? 3 : 2)}
            items={[
              <SkeletonRectangle height={180} />,
              <SkeletonRectangle height={180} />,
              <SkeletonRectangle height={180} />
            ]} />
          :
            <>
              {
                globalPosition.planes && globalPosition.planes.length > 0 ? (
                  <Carousel initialActiveItem={0}
                    itemsPerPage={
                      screenSize.isMobile ? 1 : (size.width > 1700 ? 3 : 2)
                    }
                    items={
                      globalPosition.planes
                        ? globalPosition.planes.map((plan) => (
                            <ProductCard
                              title={plan.nbPlan ? plan.nbPlan : ''}
                              category={plan.tipoFondo ? plan.tipoFondo : ''}
                              ownership={plan.nbTitular ? plan.nbTitular : ''}
                              rights={plan.saldo ? formatBalance(plan.saldo) : '0,00'}
                              annual_profitability={plan.rentabilidadAnual ? formatAnualProfit(plan.rentabilidadAnual) : '0,00'}
                              date_balance={plan.fechaSaldo ? formatDate(plan.fechaSaldo) : '0,00'}
                              button={
                                <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                                  <><ButtonPrimary small onPress={() => {handleOperate(plan)}}>
                                    {intl.formatMessage({id:'page_product_customer_operate_button'})}
                                  </ButtonPrimary></>

                                  <ButtonSecondary small onPress={()=>{plan.product && handleMoreInformation(plan) }}>
                                    {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                                  </ButtonSecondary>
                                </ButtonLayout>
                              }
                            />
                          ))
                        : []
                    }
                  />
                ) : (
                  <EmptyCard
                    icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                    title={intl.formatMessage({id:'page_customer_global_position_empty_card_plans_title'})}
                    subtitle={intl.formatMessage({id:'page_customer_global_position_empty_card_plans_description'})}
                    button ={<ButtonSecondary onPress={() => {router.push(getUrlNavigation(ContractCustomerPage))}}>{intl.formatMessage({id:'page_customer_global_position_contract_button'})}</ButtonSecondary>}
                  />
                )
              }
            </>
          }
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={ loading || (globalPosition.fondos && globalPosition.fondos.length > 0)  ? 'full' : 'inner'} fullWidth>
        <Box paddingBottom={24}>
          {loading ?
            <SkeletonText ariaLabel="loading" />
          :
            <Text4 medium>{intl.formatMessage({id:'page_customer_global_position_my_investment_funds'})}</Text4>
          }
        </Box>

        <Box paddingBottom={40}>
          {loading ?
            <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1700 ? 3 : 2)}
            items={[
              <SkeletonRectangle height={180} />,
              <SkeletonRectangle height={180} />,
              <SkeletonRectangle height={180} />
            ]} />
          :
            <>
              {
                globalPosition.fondos && globalPosition.fondos.length > 0 ? (
                  <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1700 ? 3 : 2)}
                    items={globalPosition.fondos
                      ? globalPosition.fondos.map((fondo) => (
                          <ProductCard
                            title={fondo.product?.descripcion ? fondo.product.descripcion : ''}
                            category={fondo.tipoFondo ? fondo.tipoFondo : ''}
                            ownership={fondo.nbTitular ? fondo.nbTitular : ''}
                            balance={fondo.saldo ? formatBalance(fondo.saldo) : '0,00'}
                            annual_profitability={fondo.rentabilidadAnual ? formatAnualProfit(fondo.rentabilidadAnual) : '0,00'}
                            date_balance={fondo.fechaSaldo ? formatDate(fondo.fechaSaldo) : '0,00'}
                            button={
                              <ButtonLayout  align={screenSize.isDesktopOrBigger ? 'left': 'center'}>

                              <><ButtonPrimary small onPress={() => {handleOperate(fondo)}}>
                                {intl.formatMessage({id:'page_product_customer_operate_button'})}
                              </ButtonPrimary></>

                               <ButtonSecondary small onPress={()=>{fondo.product && handleMoreInformation(fondo)}}>
                                  {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                                </ButtonSecondary>
                              </ButtonLayout>
                            }
                          />
                        ))
                      : []
                    }
                  />
                ) : (
                  <EmptyCard
                    icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                    title={intl.formatMessage({id:'page_customer_global_position_empty_card_funds_title'})}
                    subtitle={intl.formatMessage({id:'page_customer_global_position_empty_card_funds_description'})}
                    button ={<ButtonSecondary onPress={() => {router.push(getUrlNavigation(ContractCustomerPage))}}>{intl.formatMessage({id:'page_customer_global_position_contract_button'})}</ButtonSecondary>}
                  />
                )
              }
            </>
          }
        </Box>
      </ResponsiveLayout>

      {globalPosition.otros && globalPosition.otros.length > 0 ? (
        <ResponsiveLayout className='full' fullWidth>
          <Box paddingBottom={24}>
            {loading ?
              <SkeletonText ariaLabel="loading" />
            :
              <Text4 medium>{intl.formatMessage({id:'page_customer_global_position_other_products'})}</Text4>
            }
          </Box>

          <Box paddingBottom={40}>
            {loading ?
              <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1700 ? 3 : 2)}
              items={[
                <SkeletonRectangle height={180} />,
                <SkeletonRectangle height={180} />,
                <SkeletonRectangle height={180} />
              ]} />
            :

              globalPosition.otros && globalPosition.otros.length > 0 ? (
                <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1700 ? 3 : 2)}
                  items={globalPosition.otros
                    ? globalPosition.otros.map((otroProducto) => (
                        <ProductCard
                          title={otroProducto.product?.descripcion ? otroProducto.product.descripcion : ''}
                          category={otroProducto.tipoFondo ? otroProducto.tipoFondo : ''}
                          ownership={otroProducto.nbTitular ? otroProducto.nbTitular : ''}
                          balance={otroProducto.saldo ? formatBalance(otroProducto.saldo) : '0,00'}
                          annual_profitability={otroProducto.rentabilidadAnual ? formatAnualProfit(otroProducto.rentabilidadAnual) : '0,00'}
                          date_balance={otroProducto.fechaSaldo ? formatDate(otroProducto.fechaSaldo)  : '0,00'}
                          button={
                            <ButtonSecondary small onPress={() => {handleOperate(otroProducto)}}>
                              {intl.formatMessage({
                                id: 'page_product_customer_more_information_button',
                              })}
                            </ButtonSecondary>
                          }
                        />
                      ))
                    : []
                  }
                />
              ): (<></>)
            }
          </Box>
        </ResponsiveLayout>
      ) : (
        <></>
      )}

    </LayoutPrivateArea>
  );
};

export default GlobalPositionCustomerPage;
