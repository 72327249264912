

import { useIonRouter } from '@ionic/react';
import { Box, ButtonSecondary, Circle, DataCard, Grid, Hero, IconComputerUserRegular, IconEmailRegular, IconLikeRegular, IconPercentRegular, IconSearchMoneyRegular, IconSmileyHappyRegular, Image, ResponsiveLayout, skinVars, Stack, Text6, useScreenSize } from '@telefonica/mistica';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ButtonTertiary from '../../../components/buttons/ButtonTertiary';
import ChipTabs from '../../../components/chip-tabs/ChipTabs';
import HeroImage from '../../../components/hero/HeroImage';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import { useProductActions } from '../../../components/operate/ProductActions';
import AnnualEvolutionProfitability from '../../../components/product-data/AnnualEvolutionProfitability';
import Documentation from '../../../components/product-data/Documentation';
import GeneralInformation from '../../../components/product-data/GeneralInformation';
import HistoricalProfitability from '../../../components/product-data/HistoricalProfitability';
import NetAssetValueEvolution from '../../../components/product-data/NetAssetValueEvolution';
import PortfolioComposition from '../../../components/product-data/PortfolioComposition';
import ProfitabilityValues from '../../../components/product-data/ProfitabilityValues';
import Risk from '../../../components/product-data/Risk';
import OurAppContainer from '../../../components/staticPages/ourApp/OurApp';
import { Product } from '../../../entities/products/product';
import { getContent, getEmploymentPlan } from '../../../utils/apiclient';
import { getUrlNavigation } from '../../../utils/navigation';
import { isBot } from '../../../utils/utils';
import ContactPage from '../contact/ContactPage';
import EmploymentSystemPensionPlansPage from './EmploymentSystemPensionPlansPage';
import './SolutionPage.scss';
import { Content } from '../../../entities/contents/content.model';

const SolutionsForFreelancersPage: React.FC = () => {
  const intl = useIntl()
  const size = useScreenSize();
  const screenSize = useScreenSize();
  const router = useIonRouter();
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [product, setProduct] = useState(null as Product|null);
  const [options, setOptions] = useState([] as string[]);
  const { contract } = useProductActions();
  const [content, setContent] = useState(undefined as Content|undefined);

  useLayoutEffect(()=>{
    getContent('ppes').then((result)=>{
      setContent(result);
    });
  }, []);

  useEffect(()=>{
    getEmploymentPlan().then((result)=>{
        if(result){
            let opts = [intl.formatMessage({id:'page_solutions_for_freelancers_chip_tab1_name'}), intl.formatMessage({id:'page_solutions_for_freelancers_chip_tab2_name'}), intl.formatMessage({id:'page_solutions_for_freelancers_chip_tab3_name'})];
            if(result.documentacion && result.documentacion.filter(item=>(item.esEI??false)).length>0){
                opts.push(intl.formatMessage({id:'page_pension_plans_detail_chip_tab5_name'}))
            }
            if(result.documentacion && result.documentacion.filter(item=>(item.esSO??false)).length>0){
              opts.push(intl.formatMessage({id:'page_solutions_for_freelancers_chip_tab4_name'}))
            }
            setOptions(opts);
            setProduct(result);
        }
    }).finally(()=>{
        setLoading(false);
    });
  }, []);


  const handleContract = (): Promise<void> =>
    new Promise((resolve) => {
        if(product){
            contract(product).finally(()=>{
                resolve()
            });
        }else{
            resolve()
        }
    });

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_solutions_for_freelancers_seo_title'}), metaDescription:intl.formatMessage({id:'page_solutions_for_freelancers_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_solutions_for_freelancers_page_title'}), parents:[{title:intl.formatMessage({id:'page_employment_system_pension_plans_page_title'}), item:EmploymentSystemPensionPlansPage}]}}
    >
        <HeroImage
            image={content?.imageBloque2 ?? '../assets/img/soluciones_para_autonomos.jpg'}
            title={intl.formatMessage({id:'page_solutions_for_freelancers_hero_title'})}
            description={intl.formatMessage({id:'page_solutions_for_freelancers_hero_description'})}
            button={product?.contratable && <ButtonTertiary onPress={handleContract}>{intl.formatMessage({id:'page_solutions_for_freelancers_hero_action'})}</ButtonTertiary>}
        ></HeroImage>

        <ResponsiveLayout className='content'>
            <Box paddingTop={64}>
                <Stack space={56}>
                    <Box className='boxAlignCenter'>
                        <Text6>{intl.formatMessage({id:'page_solutions_for_freelancers_responsive_layout_title'})}</Text6>
                    </Box>

                    <Grid columns={screenSize.isDesktopOrBigger ? 4 : (screenSize.isTabletOrBigger ? 2 : 1)} gap={screenSize.isTabletOrBigger ? 24 : 16}>
                        <DataCard
                            icon={
                                <Circle size={40} backgroundColor={skinVars.colors.brandLow}>
                                    <IconPercentRegular color={skinVars.colors.brand} />
                                </Circle>
                            }
                            title={intl.formatMessage({id:'page_solutions_for_freelancers_data_card1_title'})}/>

                        <DataCard
                            icon={
                                <Circle size={40} backgroundColor={skinVars.colors.brandLow}>
                                    <IconLikeRegular color={skinVars.colors.brand} />
                                </Circle>
                            }
                            title={intl.formatMessage({id:'page_solutions_for_freelancers_data_card2_title'})}/>

                        <DataCard
                            icon={
                                <Circle size={40} backgroundColor={skinVars.colors.brandLow}>
                                    <IconComputerUserRegular color={skinVars.colors.brand} />
                                </Circle>
                            }
                            title={intl.formatMessage({id:'page_solutions_for_freelancers_data_card3_title'})}/>

                        <DataCard
                            icon={
                                <Circle size={40} backgroundColor={skinVars.colors.brandLow}>
                                    <IconSearchMoneyRegular color={skinVars.colors.brand} />
                                </Circle>
                            }
                            title={intl.formatMessage({id:'page_solutions_for_freelancers_data_card4_title'})}/>
                    </Grid>
                </Stack>
            </Box>
        </ResponsiveLayout>

        <Box paddingY={screenSize.isTabletOrBigger ? 56 : 40}>
            <ChipTabs
                noPadding={true}
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
                tabs={options.map((text) => ({ text}))}
            />
        </Box>

        <ResponsiveLayout className={(options[selectedIndex]===intl.formatMessage({id:'page_solutions_for_freelancers_chip_tab1_name'}) ? 'd-block' : 'd-none')}>
            <ProfitabilityValues loading={loading} product={product} />
            <Risk loading={loading} product={product} />
            <PortfolioComposition loading={loading} product={product} />
            <GeneralInformation loading={loading} product={product} />
        </ResponsiveLayout>

        <ResponsiveLayout className={(options[selectedIndex]===intl.formatMessage({id:'page_solutions_for_freelancers_chip_tab2_name'}) ? 'd-block' : 'd-none')}>
            <HistoricalProfitability loading={loading}  product={product} />
            <AnnualEvolutionProfitability loading={loading}  product={product} />
            <NetAssetValueEvolution loading={loading} product={product} />
        </ResponsiveLayout>

        <ResponsiveLayout className={(options[selectedIndex]===intl.formatMessage({id:'page_solutions_for_freelancers_chip_tab3_name'}) ? 'd-block' : 'd-none')}>
            <Documentation prefixSlug={'perfil-plan-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_documentacion-'} full loading={loading} product={product} legal={true} />
        </ResponsiveLayout>

        <ResponsiveLayout className={(options[selectedIndex]===intl.formatMessage({id:'page_pension_plans_detail_chip_tab5_name'}) ? 'd-block' : 'd-none')}>
            <Documentation prefixSlug={'perfil-plan-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_documentacion-'} full loading={loading} product={product} printed={true}  />
        </ResponsiveLayout>

        <ResponsiveLayout className={(options[selectedIndex]===intl.formatMessage({id:'page_solutions_for_freelancers_chip_tab4_name'}) ? 'd-block' : 'd-none')}>
            <Documentation prefixSlug={'perfil-plan-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_sostenibilidad-'} full loading={loading} product={product} sustainability={true} />
        </ResponsiveLayout>

        <ResponsiveLayout className='content' fullWidth={true}>

            <Hero
                background='brand'
                media={<Image src="../assets/img/mejor_plan.jpg" width="100%" height='337px'/>}
                title={intl.formatMessage({id:'page_solutions_for_freelancers_blue_hero_title'})}
                description={intl.formatMessage({id:'page_solutions_for_freelancers_blue_hero_description'})}
                desktopMediaPosition='left'
                button={isBot ?
                    <ButtonSecondary href={getUrlNavigation(ContactPage)}><IconEmailRegular color={"currentColor"} />{intl.formatMessage({id:'page_solutions_for_freelancers_blue_hero_action'})}</ButtonSecondary>
                :
                    <ButtonSecondary onPress={()=>router.push(getUrlNavigation(ContactPage))}><IconEmailRegular color={"currentColor"} />{intl.formatMessage({id:'page_solutions_for_freelancers_blue_hero_action'})}</ButtonSecondary>
                }
            />

        </ResponsiveLayout>

        <ResponsiveLayout className='content' fullWidth={true}>

            <OurAppContainer/>

        </ResponsiveLayout>

    </LayoutPublic>
  );
};

export default SolutionsForFreelancersPage;
