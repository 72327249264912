import { Box, Divider, Stack, Text3, Text4, Text5, skinVars, SkeletonRectangle } from '@telefonica/mistica';

import './GeneralInformation.scss';

import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { Product } from '../../entities/products/product';
import Documentation from '../product-data/Documentation';
import HistoricalProfitability from '../product-data/HistoricalProfitability';
import NetAssetValueEvolution from '../product-data/NetAssetValueEvolution';
import PortfolioComposition from '../product-data/PortfolioComposition';
import ProfitabilityValues from '../product-data/ProfitabilityValues';
import Risk from '../product-data/Risk';
import GeneralInformation from '../product-data/GeneralInformation';
import Accordion from '../accordion/Accordion';
import AnnualEvolutionProfitability from './AnnualEvolutionProfitability';
import slugify from 'react-slugify';
import { formatProduct } from '../../utils/utils';

interface ProductInformationProps {
    product: Product | null
    loading?: boolean
}

const ProductInformation: React.FC<ProductInformationProps> = (prop) => {
    const intl = useIntl()

    return (<>{prop.loading ?
                <Box paddingY={12}>
                    <Stack space={12}>
                        <SkeletonRectangle height={65} />
                        <Divider />
                        <SkeletonRectangle height={65} />
                        <Divider />
                        <SkeletonRectangle height={65} />
                        <Divider />
                    </Stack>
                </Box>
            :
            prop.product ?
                <>
                    <Box paddingY={32}>
                        <Text4 medium>{intl.formatMessage({id:'page_investment_funds_customer_general_product_information_information_the'})} {prop.product.descripcion}</Text4>
                    </Box>

                    <Box paddingBottom={32}>
                        {prop.product?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_OTROSPRODUCTOS ?
                            <>
                                <Accordion title={intl.formatMessage({id:'page_investment_funds_customer_general_product_information_general_information'})}>
                                    <GeneralInformation loading={prop.loading} product={prop.product}  />
                                </Accordion>
                                <Accordion title={intl.formatMessage({id:'page_investment_funds_customer_general_product_information_documentation'})}>
                                    <Documentation prefixSlug={'perfil-otros-'+formatProduct(prop.product).replaceAll('-', '_')+'-descarga_documentacion-'} loading={prop.loading} product={prop.product}  />
                                </Accordion>
                            </>
                        :
                        <>
                                <Accordion title={intl.formatMessage({id:'page_investment_funds_customer_general_product_information_general_data'})}>
                                    <ProfitabilityValues loading={prop.loading} product={prop.product} />
                                    <Risk loading={prop.loading}  product={prop.product} />
                                    <PortfolioComposition loading={prop.loading} product={prop.product}  />
                                    <GeneralInformation loading={prop.loading} product={prop.product}  />
                                </Accordion>
                                <Accordion title={intl.formatMessage({id:'page_investment_funds_customer_general_product_information_rentability'})}>
                                    <HistoricalProfitability loading={prop.loading} product={prop.product}  />
                                    <AnnualEvolutionProfitability loading={prop.loading} product={prop.product}  />
                                    <NetAssetValueEvolution loading={prop.loading} product={prop.product} />
                                </Accordion>
                                <Accordion title={intl.formatMessage({id:'page_investment_funds_customer_general_product_information_documentation'})}>
                                    <Documentation prefixSlug={'perfil-'+(prop.product?.cdCompania?.toString()===process.env.COMPANIA_FONDITEL_FONDOS ? 'fondo' : 'plan')+'-'+(formatProduct(prop.product).replaceAll('-', '_'))+'-descarga_documentacion-'} loading={prop.loading} product={prop.product} sustainability={prop.product?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ? undefined : false} legal={prop.product?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ? true : undefined}  />
                                </Accordion>
                                {prop.product?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES &&  prop.product.documentacion && prop.product.documentacion.filter(item=>(item.esEI??false)).length>0 &&
                                    <Accordion title={intl.formatMessage({id:'page_investment_funds_customer_general_product_information_printed'})}>
                                        <Documentation prefixSlug={'perfil-'+(prop.product?.cdCompania?.toString()===process.env.COMPANIA_FONDITEL_FONDOS ? 'fondo' : 'plan')+'-'+(formatProduct(prop.product).replaceAll('-', '_') ?? '')+'-descarga_impreso-'} loading={prop.loading} product={prop.product} printed={true}  />
                                    </Accordion>
                                }
                                {prop.product.documentacion && prop.product.documentacion.filter(item=>(item.esSO??false)).length>0 &&
                                    <Accordion title={intl.formatMessage({id:'page_investment_funds_customer_general_product_information_sustainability_information'})}>
                                        <Documentation prefixSlug={'perfil-'+(prop.product?.cdCompania?.toString()===process.env.COMPANIA_FONDITEL_FONDOS ? 'fondo' : 'plan')+'-'+(formatProduct(prop.product).replaceAll('-', '_') ?? '')+'-descarga_sostenibilidad-'} loading={prop.loading} product={prop.product} sustainability={true}  />
                                    </Accordion>
                                }
                            </>
                        }
                    </Box>
                </>
            :
            <></>
        }</>);
};

export default ProductInformation;
