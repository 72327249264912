import { useIonRouter } from '@ionic/react';
import React, { useLayoutEffect } from 'react';

import { Box, DisplayDataCard, GridLayout, IconComputerUserRegular, IconLoudspeakerRegular, IconPeopleNetworkRegular, ResponsiveLayout, skinVars, Stack, Text2, Text4, Text5, useScreenSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../components/layout/LayoutPrivateArea';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import { getUrlNavigation } from '../../../../utils/navigation';
import { getUser, removeCurrentUserProduct, selectRoleUser } from '../../../../utils/storage';
import { getCapitalizeText, getUrlDefaultUser, haveRol, trackEvent } from '../../../../utils/utils';
import GlobalPositionControlCommisionPage from '../control-commision/global-position/GlobalPositionControlCommisionPage';
import GlobalPositionCustomerPage from '../customer/global-position/GlobalPositionCustomerPage';
import GlobalPositionPromoterPage from '../promoter/global-position/GlobalPositionPromoterPage';
import './SelectProfile.scss';

const SelectProfile: React.FC = () => {

const intl = useIntl();
const router = useIonRouter();

const screenSize = useScreenSize();
const user = getUser();

useLayoutEffect(()=>{
  removeCurrentUserProduct();
  if(user && user.roles && user.roles.length<2){
    router.push(getUrlDefaultUser());
    return;
  }
}, []);



const handleSelectRole = (role: string): Promise<void> =>
  new Promise((resolve) => {
    selectRoleUser(role);
    switch(role){
      case 'client':
        trackEvent('acceso-perfil-cliente');
        router.push(getUrlNavigation(GlobalPositionCustomerPage))
        break;
      case 'promoter':
        trackEvent('acceso-perfil-promotor');
        router.push(getUrlNavigation(GlobalPositionPromoterPage))
        break;
      case 'control-commision':
        trackEvent('acceso-perfil-comision');
        router.push(getUrlNavigation(GlobalPositionControlCommisionPage))
        break;
    }
    resolve();
});

const dataCardClient = <DisplayDataCard
                            onPress={()=>{handleSelectRole('client')}}
                            icon={<IconComputerUserRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconComputerUserRegular>}
                            title={intl.formatMessage({id: 'page_select_profile_customer'})}
                            extra={<Box paddingTop={16}><Text2 medium color={skinVars.colors.brand} >{intl.formatMessage({id: 'page_select_profile_actions_access'})}</Text2></Box>}></DisplayDataCard>


const dataCardPromoter = <DisplayDataCard
                            onPress={()=>{handleSelectRole('promoter')}}
                            icon={<IconLoudspeakerRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconLoudspeakerRegular>}
                            title={intl.formatMessage({id: 'page_select_profile_promoter'})}
                            extra={<Box paddingTop={16}><Text2 medium color={skinVars.colors.brand} >{intl.formatMessage({id: 'page_select_profile_actions_access'})}</Text2></Box>}></DisplayDataCard>

const dataCardControlComission = <DisplayDataCard
                                    onPress={()=>{handleSelectRole('control-commision')}}
                                    icon={<IconPeopleNetworkRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconPeopleNetworkRegular>}
                                    title={intl.formatMessage({id: 'page_select_profile_control_commision'})}
                                    extra={<Box paddingTop={16}><Text2 medium color={skinVars.colors.brand} >{intl.formatMessage({id: 'page_select_profile_actions_access'})}</Text2></Box>}></DisplayDataCard>



const infoSelectProfile = <Box className='form-selector-profile' paddingY={screenSize.isDesktopOrBigger ? 80 : 0}>
<Stack space={32}>
    <ResponsiveLayout className='form'>
    <Stack space={16}>
        <Text5>{intl.formatMessage({id: 'page_select_profile_welcome'})} {getCapitalizeText(user?.nombre)},</Text5>
        <Text4 regular>{intl.formatMessage({id: 'page_select_profile_subtitle'})}</Text4>
        </Stack>
    </ResponsiveLayout>
    {user && user.roles && user.roles.length===3 ?
        <GridLayout verticalSpace={16} template='8+4'
            left={<GridLayout template='6+6' verticalSpace={16}
            left={dataCardClient}
            right={dataCardPromoter}></GridLayout>
            }
            right={dataCardControlComission}></GridLayout>
      :
        <GridLayout template='6+6' verticalSpace={16}
          left={haveRol('client') ? dataCardClient : (haveRol('promoter') ? dataCardPromoter : (haveRol('control-commision') ? dataCardControlComission : <></>))}
          right={haveRol('client') && haveRol('promoter') ? dataCardPromoter : (haveRol('control-commision') ? dataCardControlComission : <></>) }></GridLayout>
    }
    </Stack>
</Box>;

  return (
    screenSize.isDesktopOrBigger ?
      <LayoutPublic
        seo={{metaTitle: intl.formatMessage({id: 'page_select_profile_title'})}}
        header={{small:true}}
        hideFooter={true}
        requiresLoggedIn={true}
      >
        <ResponsiveLayout className='full-height header-small' backgroundColor={skinVars.colors.backgroundAlternative}>
          <ResponsiveLayout fullWidth>
            {infoSelectProfile}
          </ResponsiveLayout>
        </ResponsiveLayout>
      </LayoutPublic>
    :
    <LayoutPrivateArea
      title={intl.formatMessage({id: 'page_select_profile_title'})}
      seo={{metaTitle: intl.formatMessage({id: 'page_select_profile_title'})}}
      showBack
    >
        <ResponsiveLayout className={'fullscreen full-height header-small'} backgroundColor={skinVars.colors.backgroundAlternative}>
          {infoSelectProfile}
        </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default SelectProfile;
