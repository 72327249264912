import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useIonRouter } from '@ionic/react';

import LayoutPublic from '../../../components/layout/LayoutPublic';
import GlobalPositionCustomerPage from '../users/customer/global-position/GlobalPositionCustomerPage';

import { getUrlNavigation } from '../../../utils/navigation';
import { downloadAttachmentSignedDocument, downloadSignedDocument, formalizeContractSignaturit, setStateSignaturit } from '../../../utils/apiclient';

import { Box, ButtonLink, DisplayDataCard, GridLayout, IconDocumentsRegular, IconPenRegular, IconUserAccountRegular, LoadingBar, ResponsiveLayout, Stack, Text4, skinVars } from '@telefonica/mistica';

import './FormalizeContractSignaturit.scss';
import { getUser } from '../../../utils/storage';

const FormalizeContractSignaturit: React.FC = () => {
    const intl = useIntl()
    const router = useIonRouter();

    const [step, setStep] = React.useState(0);
    const [urlSignature, setUrlSignature]= React.useState("" as string|"");
    const [signatureId, setSignatureId]= React.useState(null as string|null);
    const [documentId, setDocumentId]= React.useState(null as string|null);
    const [isState, setIsState]= React.useState(false);

    const [loading, setLoading] = React.useState(false);

    useEffect(()=>{
        const user = getUser();
        if(user){
                setLoading(true);
                formalizeContractSignaturit().then((contract)=>{
                    setUrlSignature(contract.urlSignature);
                }).finally(()=>{
                    setLoading(false);
            })
        }

        window.addEventListener("message", (event) => {
            if(event?.data?.event === 'completed'){
                setSignatureId(event?.data?.signatureId);
                setDocumentId(event?.data?.documentId);
                setStateSignaturit({document_id: event?.data?.documentId, signature_id: event?.data?.signatureId, state: 'FIRMADO'});
                setStep(1);
            }else if(event?.data?.event === 'declined'){
                router.push(getUrlNavigation(GlobalPositionCustomerPage));
            }
        });


    }, []);

    const getAttachmentSignedDocument= () => {
        setLoading(true);
        downloadAttachmentSignedDocument(intl.formatMessage({id: 'page_register_attachment_signed_document'}), signatureId, documentId).then(()=>{
            setLoading(false);
        });
    }

    const getSignedDocument= () => {
        setLoading(true);
        downloadSignedDocument(intl.formatMessage({id: 'page_register_signed_document'}), signatureId, documentId).then(()=>{
            setLoading(false);
        });
    }

    useEffect(() => {
        if (
          signatureId &&
          documentId &&
          !isState
        ) {
          setIsState(true);
          setStateSignaturit({document_id: documentId, signature_id: signatureId, state: 'FIRMADO'});
        }
      }, [signatureId, documentId]);

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_register_seo_title'})}}
      header={{small:true, title: intl.formatMessage({id:'page_register_title'})}}
      hideFooter={true}
      requiresLoggedIn={true}
    >
        <LoadingBar  visible={loading} />
        {step===0 &&
        <ResponsiveLayout fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
            <Box paddingY={48} paddingX={80}>
                <iframe id='iframeSignaturitFormalizeContract'
                    src={urlSignature}
                    style={{ width: '1px', minWidth: '100%', scrollBehavior:'auto'}}
                />
            </Box>
        </ResponsiveLayout>
        }

        {step===1 &&
         <ResponsiveLayout className='content-formalize-contract' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
            <Box paddingY={80} className='form extra-large'>
                <Stack space={32}>
                    <ResponsiveLayout className='form'>
                        <Stack space={16}>
                            <Text4 medium>{intl.formatMessage({id:'page_register_finished_title'})}</Text4>
                            <Text4 medium>{intl.formatMessage({id:'page_register_finished_description'})}</Text4>
                        </Stack>
                    </ResponsiveLayout>

                        <GridLayout verticalSpace={16} template='8+4'
                            left={<GridLayout template='6+6' verticalSpace={16}
                                left={
                                <DisplayDataCard
                                    icon={<IconPenRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconPenRegular>}
                                    title={intl.formatMessage({id:'page_register_finished_signed_document_title'})}
                                    buttonLink={<ButtonLink onPress={()=>{getSignedDocument()}}>{intl.formatMessage({id:'page_register_finished_signed_document_action'})}</ButtonLink>}></DisplayDataCard>
                                }
                                right={
                                <DisplayDataCard
                                    icon={<IconDocumentsRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconDocumentsRegular>}
                                    title={intl.formatMessage({id:'page_register_finished_attachments_title'})}
                                    buttonLink={<ButtonLink onPress={()=>{getAttachmentSignedDocument()}}>{intl.formatMessage({id:'page_register_finished_attachments_action'})}</ButtonLink>}></DisplayDataCard>
                                }></GridLayout>
                            }
                            right={
                                <DisplayDataCard
                                    icon={<IconUserAccountRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconUserAccountRegular>}
                                    title={intl.formatMessage({id:'page_register_finished_private_area_title'})}
                                    buttonLink={<ButtonLink onPress={()=>{router.push(getUrlNavigation(GlobalPositionCustomerPage)) }}>{intl.formatMessage({id:'page_register_finished_private_area_action'})}</ButtonLink>}></DisplayDataCard>
                                }
                        />
                </Stack>
            </Box>
        </ResponsiveLayout>
        }
    </LayoutPublic>
  );
};

export default FormalizeContractSignaturit;

