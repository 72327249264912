import './AboutUsPage.scss';

import {
  Box,
  ButtonLink,
  ButtonSecondary,
  Carousel,
  DataCard,
  GridLayout,
  Hero,
  IconArrowLineRightRegular,
  IconEcoRegular,
  IconEmailRegular,
  IconSmileyHappyRegular,
  IconTrendUpRegular,
  Image,
  ResponsiveLayout,
  skinVars,
  Stack,
  Text3,
  Text4,
  Text5,
  Text8,
  useScreenSize
} from '@telefonica/mistica';
import { useIntl } from 'react-intl';

import LayoutPublic from '../../../components/layout/LayoutPublic';
import CarouselHistoryContainer from '../../../components/staticPages/carouselHistory/CarouselHistory';
import OurAppContainer from '../../../components/staticPages/ourApp/OurApp';
import { getUrlNavigation } from '../../../utils/navigation';
import { isBot } from '../../../utils/utils';
import ContactPage from '../contact/ContactPage';
import { useIonRouter } from '@ionic/react';
import IsrInFonditelPage from '../landings/sustainability/IsrInFonditelPage';

const AboutUsPage: React.FC = () => {

  const intl = useIntl()
  const size = useScreenSize();
  const router = useIonRouter();

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_about_us_seo_title'}), metaDescription:intl.formatMessage({id:'page_about_us_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_about_us_title'}), inverse: true}}
    >
        <ResponsiveLayout className='container' fullWidth={true}  backgroundColor={skinVars.colors.backgroundBrand}>
          <ResponsiveLayout className='content' fullWidth={!size.isDesktopOrBigger} backgroundColor={"transparent"}>
            <Hero
              media={<Image src="../assets/img/nosotros.jpg" aspectRatio='4:3'/>}
              extra={<Text8><div dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_about_us_header_title'})}}></div></Text8>}
              background='brand'
              desktopMediaPosition='right'/>
          </ResponsiveLayout>
        </ResponsiveLayout>
        <ResponsiveLayout className='container' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
          <ResponsiveLayout className='content' backgroundColor={"transparent"}>
            <Box paddingY={80}>
              <Stack space={80}>
                <GridLayout template='8+4'
                  left={
                    <Stack space={64}>
                      <Stack space={24}>
                        <Box>
                          <Text5 color={skinVars.colors.brand}>{intl.formatMessage({id:'page_about_us_content_area_title2'})}</Text5>
                        </Box>
                        <Box>
                          <Text4 light>{intl.formatMessage({id:'page_about_us_content_area_description2'})}</Text4>
                        </Box>
                      </Stack>
                      <Stack space={24}>
                        <Box>
                          <Text5 color={skinVars.colors.brand}>{intl.formatMessage({id:'page_about_us_content_area_title1'})}</Text5>
                        </Box>
                        <Box>
                          <Text4 light>{intl.formatMessage({id:'page_about_us_content_area_description1'})}</Text4>
                        </Box>
                      </Stack>
                      <Stack space={24}>
                        <Box>
                          <Text5 color={skinVars.colors.brand}>{intl.formatMessage({id:'page_about_us_content_area_title3'})}</Text5>
                        </Box>
                        <Box>
                          <Text4 light>{intl.formatMessage({id:'page_about_us_content_area_description3'})}</Text4>
                        </Box>
                      </Stack>
                      <ButtonSecondary small onPress={()=>{router.push(getUrlNavigation(IsrInFonditelPage))}}>{intl.formatMessage({id:'page_about_us_content_area_read_more'})}</ButtonSecondary>
                    </Stack>
                  }
                  right={
                    <></>
                  }
                />
                <Carousel initialActiveItem={0} itemsPerPage={size.isMobile ? 1 : (size.isTablet ? 3 : 4)}
                  items={[
                    <DataCard
                        icon={<img src={'../assets/img/telefonica-logo.svg'} width="40px" height="40px" />}
                        title={intl.formatMessage({id:'page_about_us_content_area_card1_title'})}
                        description={intl.formatMessage({id:'page_about_us_content_area_card1_content'})}/>,
                    <DataCard
                        icon={<IconTrendUpRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconTrendUpRegular>}
                        title={intl.formatMessage({id:'page_about_us_content_area_card2_title'})}
                        description={intl.formatMessage({id:'page_about_us_content_area_card2_content'})}/>,
                    <DataCard
                        icon={<IconSmileyHappyRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconSmileyHappyRegular>}
                        title={intl.formatMessage({id:'page_about_us_content_area_card3_title'})}
                        description={intl.formatMessage({id:'page_about_us_content_area_card3_content'})}/>,
                    <DataCard
                        icon={<IconEcoRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconEcoRegular>}
                        title={intl.formatMessage({id:'page_about_us_content_area_card4_title'})}
                        description={intl.formatMessage({id:'page_about_us_content_area_card4_content'})}
                        button={<ButtonLink onPress={() => {router.push(getUrlNavigation(IsrInFonditelPage))}}>{intl.formatMessage({id:'page_about_us_content_area_card4_button'})}<IconArrowLineRightRegular color='currentColor'/></ButtonLink>}/>
                  ]}
                />
              </Stack>
            </Box>
          </ResponsiveLayout>
        </ResponsiveLayout>
        <ResponsiveLayout className='container' fullWidth={true} backgroundColor={"transparent"}>
          <ResponsiveLayout className='content' backgroundColor={"transparent"}>
            <Box paddingY={80}>
              <Stack space={48}>
                <GridLayout template='6+6'
                  left={
                    <Stack space={32}>
                      <Box>
                        <Text4 medium>{intl.formatMessage({id:'page_about_us_content_area_history_title'})}</Text4>
                      </Box>
                      <Box>
                        <Text3 regular>{intl.formatMessage({id:'page_about_us_content_area_history_description'})}</Text3>
                      </Box>
                    </Stack>
                  }
                  right={
                    <></>
                  }
                />
                <CarouselHistoryContainer/>
              </Stack>
            </Box>
          </ResponsiveLayout>
        </ResponsiveLayout>
        <ResponsiveLayout className='container' fullWidth={true} isInverse backgroundColor={skinVars.colors.backgroundBrand}>
          <Hero
              media={<Image src="../assets/img/te-ayudamos-elegir-plan.jpg" aspectRatio='16:9'/>}
              extra={
                <Stack space={24}>
                  <Text8><div dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_about_us_content_area_contact_title'})}}></div></Text8>
                  <Text3 regular><div dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_about_us_content_area_contact_description'})}}></div></Text3>

                  {isBot ?
                      <ButtonSecondary href={getUrlNavigation(ContactPage)}><IconEmailRegular color={"currentColor"} />{intl.formatMessage({id:'page_about_us_content_area_contact_button'})}</ButtonSecondary>
                  :
                      <ButtonSecondary onPress={()=>router.push(getUrlNavigation(ContactPage))}><IconEmailRegular color={"currentColor"} />{intl.formatMessage({id:'page_about_us_content_area_contact_button'})}</ButtonSecondary>
                  }
                </Stack>
              }
              background='brand'
              desktopMediaPosition='left'/>
        </ResponsiveLayout>
        <ResponsiveLayout className='content' fullWidth={true}>

          <OurAppContainer/>

        </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default AboutUsPage;

