import { ResponsiveLayout } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../components/layout/LayoutPrivateArea';


const UserCopyrightPage: React.FC = () => {
  const intl = useIntl()

  return (
    <LayoutPrivateArea
      title={intl.formatMessage({id:'page_copyright_title'})}
      showBack
    >
      <ResponsiveLayout className='inner first'>
        <div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_copyright_text'})}}></div>
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default UserCopyrightPage;
