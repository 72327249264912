import { Divider, Grid, skinVars, Stack, Text2, Text3, Text4, useScreenSize } from '@telefonica/mistica';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ListOption } from '../../entities/commons/list-option.model';
import { OperationInProgress } from '../../entities/operationsInProgress/operation-in-progress.model';
import { getMasterDatas } from '../../utils/apiclient';


interface OperationInProgressDetailContractProps {
    operation: OperationInProgress,
    items: Array<{title:string, items:Array<{title:string, value:string}>}>
}

const OperationInProgressDetailContract: React.FC<OperationInProgressDetailContractProps> = (prop) => {
    const intl = useIntl();
    const screenSize = useScreenSize();
    const [genderOptions, setGenderOptions] = React.useState([] as ListOption[])
	const [countryOptions, setCountryOptions] = React.useState([] as ListOption[])
	const [provinceOptions, setProvinceOptions] = React.useState([] as ListOption[])
	const [viaOptions, setViaOptions] = React.useState([] as ListOption[])
    const [personalDataItems, setPersonalDataItems] = React.useState([] as Array<{title:string, value:string}>);

    useEffect(()=>{
        if(prop.operation){
            const op = prop.operation;
            getMasterDatas('genres').then(options=>{
                setGenderOptions(options);
            });
            getMasterDatas('countries').then(options=>{
                setCountryOptions(options);
            });
            getMasterDatas('provinces').then(options=>{
                setProvinceOptions(options);
            });
            getMasterDatas('addressTypes').then(options=>{
                setViaOptions(options);
            });

            /*const personalData : Array<{title:string, value:string}> = [];
            if(op.contrato.cliente.nombre){
                personalData.push({title: intl.formatMessage({ id: 'page_operations_in_progress_personal_data_name' }), value: op.contrato.cliente.nombre});
            }

            if(personalData.length>0){
                setPersonalDataItems(personalData);
            }*/
        }



    },[]);



return (screenSize.isDesktopOrBigger ?
            <Stack space={32}>
                <Stack space={16}>
                    <Text3 regular>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data' })}</Text3>
                    <Grid columns={4} gap={12}>
                        {prop.operation.contrato.cliente.nombre &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_name' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.nombre}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.apellidos &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_lastname' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.apellidos}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.genero &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_gender' })}</Text2>
                                <Text2 medium>{genderOptions.find(item=>item.valor===prop.operation.contrato.cliente.genero)?.descripcion}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.fechaNacimiento &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_birthdate' })}</Text2>
                                <Text2 medium>{moment(prop.operation.contrato.cliente.fechaNacimiento??'').format('DD/MM/YYYY')}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.telefono &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_phone' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.telefono}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.movil &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_mobilphone' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.movil}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.email &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_email' })}</Text2>
                                <Text2 medium truncate={1}>{prop.operation.contrato.cliente.email.toLowerCase()}</Text2>
                            </Stack>
                        }
                    </Grid>
                    <Divider />
                </Stack>


                <Stack space={16}>
                    <Text3 regular>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address' })}</Text3>
                    <Grid columns={prop.operation.contrato.cliente.mismaDireccionFiscalYPostal ? 1 : 4} gap={12}>

                        {(prop.operation.contrato.cliente.mismaDireccionFiscalYPostal || !prop.operation.contrato.cliente.datosPostales?.domicilioPais) &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_same_fiscal_address' })}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioPais &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_country' })}</Text2>
                                <Text2 medium>{countryOptions.find(item=>item.valor===prop.operation.contrato.cliente.datosPostales?.domicilioPais?.toString() ?? '')?.descripcion}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioCP &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_cp' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.datosPostales?.domicilioCP}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioProvincia &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_province' })}</Text2>
                                <Text2 medium>{provinceOptions.find(item=>item.valor===prop.operation.contrato.cliente.datosPostales?.domicilioProvincia?.toString() ?? '')?.descripcion}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioPoblacion &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_population' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.datosPostales?.domicilioPoblacion}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioTipoVia &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_via' })}</Text2>
                                <Text2 medium>{viaOptions.find(item=>item.valor===prop.operation.contrato.cliente.datosPostales?.domicilioTipoVia?.toString() ?? '')?.descripcion}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioVia &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_via_name' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.datosPostales?.domicilioVia}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioNumero &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_number' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.datosPostales?.domicilioNumero}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioPiso &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_floor' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.datosPostales?.domicilioPiso}</Text2>
                            </Stack>
                        }
                    </Grid>
                    <Divider />
                </Stack>
                {prop.operation.compania.cdCompania === parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS??'3') &&
                    <Stack space={16}>
                        <Text3 regular>{prop.operation.contrato.esMancomunado ? intl.formatMessage({ id: 'page_operations_in_progress_customer_pooled_ownership' }) : intl.formatMessage({ id: 'page_operations_in_progress_customer_solidarity_ownership' })}</Text3>
                        <Grid columns={(prop.operation.detalle.intervinientes.length>4 ? 4 : prop.operation.detalle.intervinientes.length) as any} gap={12}>

                            {prop.operation.detalle.intervinientes.map((item, index) => (
                                <Stack key={'cont-hold-'+index} space={4}>
                                    <Stack space={4}>
                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_nif' })}</Text2>
                                        <Text2 medium>{item.NIF}</Text2>
                                    </Stack>
                                    <Stack space={4}>
                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_name' })}</Text2>
                                        <Text2 medium>{item.nombre}</Text2>
                                    </Stack>
                                    <Stack space={4}>
                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_lastname' })}</Text2>
                                        <Text2 medium>{item.apellidos}</Text2>
                                    </Stack>
                                </Stack>
                            ))}
                        </Grid>
                        <Divider />
                    </Stack>
                }

                <Grid columns={2} gap={12}>
                    {prop.items.map((item, index) => (
                        <Stack key={'oipd-'+index} space={16}>
                            <Text3 regular>{item.title}</Text3>
                            {item.items.map((itemD, indexD) => (
                                <Stack key={'oipdd-'+indexD} space={4}>
                                    <Stack space={4}>
                                        <Text2 regular color={skinVars.colors.textSecondary}>{itemD.title}</Text2>
                                        <Text2 medium>{itemD.value}</Text2>
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    ))}
                </Grid>
            </Stack>
        :
            <Stack space={16}>
                <Stack space={16}>
                    <Text3 regular>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data' })}</Text3>
                    <Grid columns={screenSize.isMobile ? 1 : 2} gap={12}>
                        {prop.operation.contrato.cliente.nombre &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_name' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.nombre}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.apellidos &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_lastname' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.apellidos}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.genero &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_gender' })}</Text2>
                                <Text2 medium>{genderOptions.find(item=>item.valor===prop.operation.contrato.cliente.genero)?.descripcion}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.fechaNacimiento &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_birthdate' })}</Text2>
                                <Text2 medium>{moment(prop.operation.contrato.cliente.fechaNacimiento??'').format('DD/MM/YYYY')}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.telefono &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_phone' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.telefono}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.movil &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_mobilphone' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.movil}</Text2>
                            </Stack>
                        }
                        {prop.operation.contrato.cliente.email &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_email' })}</Text2>
                                <Text2 medium truncate={1}>{prop.operation.contrato.cliente.email.toLowerCase()}</Text2>
                            </Stack>
                        }
                    </Grid>
                    <Divider />
                </Stack>
                <Stack space={16}>
                    <Text3 regular>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address' })}</Text3>
                    <Grid columns={prop.operation.contrato.cliente.mismaDireccionFiscalYPostal ? 1 : (screenSize.isMobile ? 1 : 2)} gap={12}>

                        {(prop.operation.contrato.cliente.mismaDireccionFiscalYPostal || !prop.operation.contrato.cliente.datosPostales?.domicilioPais) &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_same_fiscal_address' })}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioPais &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_country' })}</Text2>
                                <Text2 medium>{countryOptions.find(item=>item.valor===prop.operation.contrato.cliente.datosPostales?.domicilioPais?.toString() ?? '')?.descripcion}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioCP &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_cp' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.datosPostales?.domicilioCP}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioProvincia &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_province' })}</Text2>
                                <Text2 medium>{provinceOptions.find(item=>item.valor===prop.operation.contrato.cliente.datosPostales?.domicilioProvincia?.toString() ?? '')?.descripcion}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioPoblacion &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_population' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.datosPostales?.domicilioPoblacion}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioTipoVia &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_via' })}</Text2>
                                <Text2 medium>{viaOptions.find(item=>item.valor===prop.operation.contrato.cliente.datosPostales?.domicilioTipoVia?.toString() ?? '')?.descripcion}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioVia &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_via_name' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.datosPostales?.domicilioVia}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioNumero &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_number' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.datosPostales?.domicilioNumero}</Text2>
                            </Stack>
                        }
                        {!prop.operation.contrato.cliente.mismaDireccionFiscalYPostal && prop.operation.contrato.cliente.datosPostales?.domicilioPiso &&
                            <Stack space={4}>
                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_postal_address_floor' })}</Text2>
                                <Text2 medium>{prop.operation.contrato.cliente.datosPostales?.domicilioPiso}</Text2>
                            </Stack>
                        }
                    </Grid>
                    <Divider />
                </Stack>
                {prop.operation.compania.cdCompania === parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS??'3') &&
                    <Stack space={16}>
                        <Text3 regular>{prop.operation.contrato.esMancomunado ? intl.formatMessage({ id: 'page_operations_in_progress_customer_pooled_ownership' }) : intl.formatMessage({ id: 'page_operations_in_progress_customer_solidarity_ownership' })}</Text3>
                        <Grid columns={screenSize.isMobile ? 1 : 2} gap={24}>

                            {prop.operation.detalle.intervinientes.map((item, index) => (
                                <Stack key={'cont-hold-'+index} space={4}>
                                    <Stack space={4}>
                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_nif' })}</Text2>
                                        <Text2 medium>{item.NIF}</Text2>
                                    </Stack>
                                    <Stack space={4}>
                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_name' })}</Text2>
                                        <Text2 medium>{item.nombre}</Text2>
                                    </Stack>
                                    <Stack space={4}>
                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_personal_data_lastname' })}</Text2>
                                        <Text2 medium>{item.apellidos}</Text2>
                                    </Stack>
                                </Stack>
                            ))}
                        </Grid>
                        <Divider />
                    </Stack>
                }
                <Grid columns={screenSize.isMobile ? 1 : 2} gap={12}>
                    {prop.items.map((item, index) => (
                        <Stack key={'oipd-'+index} space={16}>
                            <Text3 regular>{item.title}</Text3>
                            {item.items.map((itemD, indexD) => (
                                <Stack key={'oipdd-'+indexD} space={4}>
                                    <Stack space={4}>
                                        <Text2 regular color={skinVars.colors.textSecondary}>{itemD.title}</Text2>
                                        <Text2 medium>{itemD.value}</Text2>
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    ))}
                </Grid>
            </Stack>
        );
};

export default OperationInProgressDetailContract;
