import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Grid, ResponsiveLayout, Stack, Text4, Text8, skinVars, useScreenSize } from '@telefonica/mistica';
import './Hero.scss';

interface HeroNoImagProp {
    title: string,
    subtitle?: string,
    full_head?: boolean,
    children?: any
}

const HeroNoImage: React.FC<HeroNoImagProp> = (prop) => {

const intl = useIntl();
const screenSize = useScreenSize();
const size = useScreenSize();

    return (
        <>
            <style>{
                '.noImageHero:before{background-color:'+skinVars.colors.backgroundAlternative+';}'
            }</style>
            <ResponsiveLayout className='content' fullWidth={true}>
                <Box className={'noImageHero '+(!prop.children ? 'noImageHero--noChildren' : '')} paddingBottom={40}>

                    <ResponsiveLayout className='noImageHero__content'>

                        <Stack space={24}>
                            <Box paddingTop={screenSize.isDesktopOrBigger ? 56 : 8} paddingBottom={screenSize.isDesktopOrBigger ? 56 : 40}>
                                <Grid columns={screenSize.isDesktopOrBigger && !prop.full_head ? 2 : 1} gap={screenSize.isDesktopOrBigger ? 24 : 16}>
                                    <Stack space={8} className={prop.full_head ? 'noImageHero--fullHead': ''}>
                                        <Text8>{prop.title}</Text8>
                                    </Stack>
                                    {prop.subtitle &&
                                        <Text4 medium color={skinVars.colors.brand}>{prop.subtitle}</Text4>
                                    }
                                </Grid>
                            </Box>

                            {prop.children}
                        </Stack>

                    </ResponsiveLayout>
                
                </Box>
            </ResponsiveLayout>
        </>
    );
};

export default HeroNoImage;