import { useIonRouter } from '@ionic/react';
import React from 'react';

import { Box, Boxed, ButtonLayout, ButtonPrimary, ButtonSecondary, IconLockClosedRegular, IconSmileyHappyRegular, ResponsiveLayout, Stack, Text3, Text6, skinVars, useScreenSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import { getUrlNavigation } from '../../../utils/navigation';
import ContactPage from '../../public/contact/ContactPage';
import './AlreadyUserPage.scss';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import RegisterPage from '../register/RegisterPage';
import { trackEvent } from '../../../utils/utils';

const AlreadyUserPage: React.FC = () => {

const intl = useIntl();
const router = useIonRouter();

const size = useScreenSize();

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_login_already_client_title'})}}
      header={{small:true}}
      hideFooter={true}
    >
      <ResponsiveLayout className='full-height header-small' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
        <ResponsiveLayout className='content' backgroundColor={"transparent"}>
          <Box className='form-info-already-customer' paddingY={80}>
              <Boxed>
                <Box padding={40}>
                  <Stack space={32}>
                    <IconSmileyHappyRegular size={64} color={skinVars.colors.buttonPrimaryBackground}></IconSmileyHappyRegular>
                    <Text6>{intl.formatMessage({id:'page_login_already_client_title'})}</Text6>
                    <Text3 regular>{intl.formatMessage({id:'page_login_already_client_description'})}</Text3>
                    <ButtonGroup
                      primaryButton={<ButtonPrimary onPress={() => {router.push(getUrlNavigation(RegisterPage)); trackEvent('acceso-error-iniciar_registro');}}>{intl.formatMessage({id:'page_login_already_client_actions_signup'})}</ButtonPrimary>}
                      secondaryButton={<ButtonSecondary onPress={() => {router.push(getUrlNavigation(ContactPage)); trackEvent('acceso-error-contactar');}}>{intl.formatMessage({id:'page_login_already_client_actions_contact'})}</ButtonSecondary>}></ButtonGroup>
                  </Stack>
                </Box>
              </Boxed>
            </Box>
        </ResponsiveLayout>
      </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default AlreadyUserPage;
