import { useIonRouter } from '@ionic/react';
import { alert, Box, dialog, IconCheckedLight, IconFileErrorRegular, LoadingBar, ResponsiveLayout, skinVars, useScreenSize } from '@telefonica/mistica';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPublic from '../../../../../components/layout/LayoutPublic';

import { getUrlNavigation } from '../../../../../utils/navigation';
import {
    getContractUrl, removeContractUrl
} from '../../../../../utils/storage';
import { useToast } from '../../../../../utils/toast';
import { closeDialog } from '../../../../../utils/utils';
import OperationsInProgressCustomerPage from '../operations-in-progress/OperationsInProgressCustomerPage';
import { SimplifiedEmploymentPlansCustomerPage } from './PensionPlansCustomerPage';

import './SignContractSimplifiedEmploymentPlansCustomerPage.scss';


const SignContractSimplifiedEmploymentPlansCustomerPage: React.FC = () => {
    const { showToast } = useToast()
    const intl = useIntl();
    const [loading, setLoading] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);
    const router = useIonRouter();
    const [urlSignature, setUrlSignature]= React.useState('');
    const screenSize = useScreenSize();

    //Loading form
    const [loadingForm, setLoadingForm] = React.useState(false);

    useEffect(()=>{
        const contractUrl = getContractUrl();

        if(!router.canGoBack() || !contractUrl){
            router.push(getUrlNavigation(SimplifiedEmploymentPlansCustomerPage));
            return;
        }

        closeDialog().then(()=>{
            alert({
                title: intl.formatMessage({id:'page_sign_contract_simplified_employment_plans_alert_title'}),
                acceptText: intl.formatMessage({id:'page_sign_contract_simplified_employment_plans_alert_button'}),
                message: intl.formatMessage({id:'page_sign_contract_simplified_employment_plans_alert_description'}),
                onAccept:()=>{
                    setLoadingForm(true);
                    setUrlSignature(contractUrl);
                    removeContractUrl();
                }
            });
        });
        setLoading(false);

        window.addEventListener("message", (event) => {
            if(event?.data?.event === 'completed'){
                setLoading(true);
                closeDialog().then(()=>{
                    dialog({
                        icon:<IconCheckedLight color={skinVars.colors.brand} />,
                        title: intl.formatMessage({id:'page_sign_contract_simplified_employment_plans_dialog_title_ok'}),
                        acceptText: intl.formatMessage({id:'page_sign_contract_simplified_employment_plans_dialog_button_ok'}),
                        message: "",
                        onAccept: ()=>{ router.push(getUrlNavigation(OperationsInProgressCustomerPage)); }
                    });
                });
            }else if(event?.data?.event === 'declined'){
                setLoading(true);
                closeDialog().then(()=>{
                    dialog({
                        icon:<IconFileErrorRegular color={skinVars.colors.textLinkDanger} />,
                        title: intl.formatMessage({id:'page_sign_contract_simplified_employment_plans_dialog_title_ko'}),
                        acceptText: intl.formatMessage({id:'page_sign_contract_simplified_employment_plans_dialog_button_ko'}),
                        message: "",
                        onAccept: ()=>{ router.push(getUrlNavigation(OperationsInProgressCustomerPage)); }
                    });
                });
            }
        });

    }, []);


    const onLoadedIframeSignaturit = () => {
        setLoadingForm(false);
    }



    return (
        <LayoutPublic
          seo={{metaTitle: intl.formatMessage({id:'page_contract_pension_plans_title'})}}
          header={{small:true, title: intl.formatMessage({id:'page_contract_pension_plans_title'})}}
          hideFooter={true}
          requiresLoggedIn={true}
        >
            <LoadingBar visible={loading || loadingForm} />
            {urlSignature && urlSignature.length>0 &&
                <ResponsiveLayout fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
                    <Box paddingY={screenSize.isTabletOrSmaller ? 16 : 48} paddingX={screenSize.isTabletOrSmaller ? 16 : 80}>
                        <iframe id='iframeSignaturitSignContract'
                            onLoad={onLoadedIframeSignaturit}
                            src={urlSignature}
                            style={{ width: '1px', minWidth: '100%', scrollBehavior:'auto'}}
                        />
                    </Box>
                </ResponsiveLayout>
            }
        </LayoutPublic>
    );
};

export default SignContractSimplifiedEmploymentPlansCustomerPage;