import { useIonRouter } from '@ionic/react';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { IconChevronRightRegular, IconLogoutRegular, Inline, ResponsiveLayout, Row, RowList, skinVars, Text, useScreenSize, useWindowSize } from '@telefonica/mistica';
import LayoutPrivateArea from '../../../../components/layout/LayoutPrivateArea';

import { NativeBiometric } from 'capacitor-native-biometric';
import React from 'react';
import { NavOption } from '../../../../entities/commons/nav-option.model';
import { getUrlNavigation, getUserMenuNavigation } from '../../../../utils/navigation';
import { removeUser } from '../../../../utils/storage';
import { getUrlDefaultUser, isNativeApp, vibration } from '../../../../utils/utils';
import LoginPage from '../../login/LoginPage';
import './UserMenuPage.scss';

const UserMenuPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
  const size = useWindowSize();
  const router = useIonRouter();
  const [loading, setLoading] = useState(true);
  const [biometricIsAvailable, setBiometricIsAvailable] = useState(false);
  const [userNavigation, setUserNavigation] = React.useState([] as NavOption[]);

  useLayoutEffect(()=>{
    if(isNativeApp){
        NativeBiometric.isAvailable().then((result)=>{
          setBiometricIsAvailable(result.isAvailable);
        });
    }
  }, []);

  useEffect(()=>{
    const options = getUserMenuNavigation(biometricIsAvailable);
    setUserNavigation(options);
  }, [biometricIsAvailable]);

  function handleLogout() {
      removeUser(true);
      router.push(getUrlNavigation(LoginPage));
  }

  return (
    <LayoutPrivateArea showBack showBigAvatar welcome>
      <ResponsiveLayout className='user-menu-page' fullWidth>

        <RowList noLastDivider dataAttributes={{"remove-paddings":true}}>
            {userNavigation.map((item, i) => {
                return (<Row title=''
                    key={'ump'+i}
                    right={null}
                    onPress={() => {vibration();router.push(getUrlNavigation(item.page))}}
                    extra={
                        <Inline space={'between'} alignItems='center'>
                            <Text color={skinVars.colors.neutralHigh}>
                                <Inline space={16} alignItems='center'>
                                    <span>{item.icon}</span>
                                    <span>{intl.formatMessage({id:item.title})}</span>
                                </Inline>
                            </Text>
                            <IconChevronRightRegular color={skinVars.colors.neutralMediumInverse} size={16}></IconChevronRightRegular>
                        </Inline>
                    }
                />)
            })}

            <Row title=''
                    right={null}
                    onPress={() => handleLogout()}
                    extra={
                        <Inline space={'between'} alignItems='center'>
                            <Text color={skinVars.colors.neutralHigh}>
                                <Inline space={16} alignItems='center'>
                                    <span><IconLogoutRegular color="currentColor"></IconLogoutRegular></span>
                                    <span>{intl.formatMessage({ id: 'page_user_menu_sign_off' })}</span>
                                </Inline>
                            </Text>
                            <IconChevronRightRegular color={skinVars.colors.neutralMediumInverse} size={16}></IconChevronRightRegular>
                        </Inline>
                    }
                />

        </RowList>
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default UserMenuPage;

