import { useIonRouter } from '@ionic/react';
import { IconChevronRightRegular, Inline, ResponsiveLayout, Row, RowList, skinVars, Text, useScreenSize } from '@telefonica/mistica';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import { getUrlNavigation } from '../../../../../utils/navigation';
import TaxInformationPensionPlansCustomerPage from '../tax-information/TaxInformationPensionPlansCustomerPage';
import TaxInformationInvestmentFundsCustomerPage from './TaxInformationInvestmentFundsCustomerPage';
import { vibration } from '../../../../../utils/utils';

const TaxInformationCustomerPage: React.FC = () => {
  const intl = useIntl()
  const router = useIonRouter();
  const screenSize = useScreenSize();
  const [loading, setLoading] = useState(false);

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_tax_information_seo_title'})}}
      title={intl.formatMessage({id:'page_tax_information_title'})}
      showBack
    >
      <ResponsiveLayout className='fullscreen'>
        <RowList dataAttributes={{"remove-paddings":true}}>
        <Row title=''
              right={null}
              onPress={() => {vibration();router.push(getUrlNavigation(TaxInformationPensionPlansCustomerPage))}}
              extra={
                <Inline space={'between'} alignItems='center'>
                    <Text weight={'regular'} color={skinVars.colors.neutralHigh}>
                        <Inline space={16} alignItems='center'>
                            <span>{intl.formatMessage({id:'page_tax_information_pensions_plan'})}</span>
                        </Inline>
                    </Text>
                    <IconChevronRightRegular size={16} color={skinVars.colors.neutralMediumInverse}></IconChevronRightRegular>
                </Inline>
              }
          />
          <Row title=''
                right={null}
                onPress={() => {vibration();router.push(getUrlNavigation(TaxInformationInvestmentFundsCustomerPage))}}
                extra={
                  <Inline space={'between'} alignItems='center'>
                      <Text weight={'regular'} color={skinVars.colors.neutralHigh}>
                          <Inline space={16} alignItems='center'>
                              <span>{intl.formatMessage({id:'page_tax_information_inversment_fund'})}</span>
                          </Inline>
                      </Text>
                      <IconChevronRightRegular size={16} color={skinVars.colors.neutralMediumInverse}></IconChevronRightRegular>
                  </Inline>
                }
            />
        </RowList>
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default TaxInformationCustomerPage;
