import { useIonRouter } from '@ionic/react';
import React from 'react';

import { Box, Boxed, ButtonLayout, ButtonPrimary, IconLockClosedRegular, ResponsiveLayout, Stack, Text3, Text6, skinVars, useScreenSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import { getUrlNavigation } from '../../../utils/navigation';
import ContactPage from '../../public/contact/ContactPage';
import './UserBlockedPage.scss';
import { trackEvent } from '../../../utils/utils';

const UserBlockedPage: React.FC = () => {

const intl = useIntl();
const router = useIonRouter();

const size = useScreenSize();

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_login_exceeded_attempts_title'})}}
      header={{small:true}}
      hideFooter={true}
    >
      <ResponsiveLayout className='full-height header-small' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
        <ResponsiveLayout className='content' backgroundColor={"transparent"}>
          <Box className='form-info-user-blocked' paddingY={80}>
              <Boxed>
                <Box padding={40}>
                  <Stack space={32}>
                    <IconLockClosedRegular size={64} color={skinVars.colors.buttonPrimaryBackground}></IconLockClosedRegular>
                    <Text6>{intl.formatMessage({id:'page_login_exceeded_attempts_title'})}</Text6>
                    <Text3 regular>{intl.formatMessage({id:'page_login_exceeded_attempts_description'})}</Text3>
                    <ButtonLayout align='full-width'>
                      <ButtonPrimary onPress={() => {router.push(getUrlNavigation(ContactPage)); trackEvent('acceso-error-numero_intento');}}>{intl.formatMessage({id:'page_login_exceeded_attempts_actions_contact'})}</ButtonPrimary>
                    </ButtonLayout>
                  </Stack>
                </Box>
              </Boxed>
            </Box>
        </ResponsiveLayout>
      </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default UserBlockedPage;
