

import { Box, Hero, Image, ResponsiveLayout, skinVars, useScreenSize } from '@telefonica/mistica';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import { getStatus } from '../../../utils/apiclient';

const MaintenancePage: React.FC = () => {
  const intl = useIntl()
  const size = useScreenSize();
  const [retrieve, setRetrieve] = useState(false);

  useEffect(()=>{

  }, []);

  function retrieveStatus(){
    getStatus().then((data)=>{
      if(data && !data.maintenance){
        window.location.href='/';
      }
    })
    setRetrieve(!retrieve);
}

  useEffect( () => {
    setTimeout(retrieveStatus, 15000);

}, [retrieve])



  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_maintenance_seo_title'}), metaDescription:intl.formatMessage({id:'page_maintenance_seo_description'})}}
      header={{small:true}}
      hideFooter={true}
    >

      <ResponsiveLayout className='full-height header-small' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
        <Box paddingTop={size.isDesktopOrBigger ?  40 : 0} paddingBottom={size.isDesktopOrBigger ?  80 : 0}>
            <Hero media={<Image src="../assets/img/mantenimiento.jpg"
                width="100%"/>} background='alternative' title={intl.formatMessage({id:'page_maintenance_title'})} description={intl.formatMessage({id:'page_maintenance_description'})} desktopMediaPosition='right'/>
        </Box>
      </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default MaintenancePage;
