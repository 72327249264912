import { useIntl } from 'react-intl';

import { Carousel, useScreenSize, useWindowSize } from '@telefonica/mistica';

import TooltipCarouselHistory from './TooltipCarouselHistory';

import './CarouselHistory.scss';
import { useEffect, useState } from 'react';

const CarouselHistoryContainer: React.FC = () => {

// const size = useScreenSize();
const screenSize = useScreenSize();
const size = useWindowSize();

const [itemsNumber, setItemsNumber] = useState(12);

useEffect(()=>{
  if(size.width<340){
    setItemsNumber(2);
  }else if(size.width<490){
    setItemsNumber(3);
  }else if(size.width<590){
    setItemsNumber(4);
  }else if(size.width<690){
    setItemsNumber(5);
  }else if(size.width<790){
    setItemsNumber(6);
  }else if(size.width<890){
    setItemsNumber(7);
  }else if(size.width<992){
    setItemsNumber(8);
  }else if(size.width<1080){
    setItemsNumber(9);
  }else if(size.width<1180){
    setItemsNumber(10);
  }

}, [screenSize, size]);

const intl = useIntl();

  return (
    <>
      <Carousel initialActiveItem={0} itemClassName='carouselItem' itemsPerPage={itemsNumber}
        items={[
          <TooltipCarouselHistory numberItem={intl.formatMessage({id:'page_about_us_content_area_history_block1_year_title'})} textItem={intl.formatMessage({id:'page_about_us_content_area_history_block1_year_content'})}/>,
          <TooltipCarouselHistory numberItem={intl.formatMessage({id:'page_about_us_content_area_history_block2_year_title'})} textItem={intl.formatMessage({id:'page_about_us_content_area_history_block2_year_content'})}/>,
          <TooltipCarouselHistory numberItem={intl.formatMessage({id:'page_about_us_content_area_history_block3_year_title'})} textItem={intl.formatMessage({id:'page_about_us_content_area_history_block3_year_content'})}/>,
          <TooltipCarouselHistory numberItem={intl.formatMessage({id:'page_about_us_content_area_history_block4_year_title'})} textItem={intl.formatMessage({id:'page_about_us_content_area_history_block4_year_content'})}/>,
          <TooltipCarouselHistory numberItem={intl.formatMessage({id:'page_about_us_content_area_history_block5_year_title'})} textItem={intl.formatMessage({id:'page_about_us_content_area_history_block5_year_content'})}/>,
          <TooltipCarouselHistory numberItem={intl.formatMessage({id:'page_about_us_content_area_history_block6_year_title'})} textItem={intl.formatMessage({id:'page_about_us_content_area_history_block6_year_content'})}/>,
          <TooltipCarouselHistory numberItem={intl.formatMessage({id:'page_about_us_content_area_history_block7_year_title'})} textItem={intl.formatMessage({id:'page_about_us_content_area_history_block7_year_content'})}/>,
          <TooltipCarouselHistory numberItem={intl.formatMessage({id:'page_about_us_content_area_history_block8_year_title'})} textItem={intl.formatMessage({id:'page_about_us_content_area_history_block8_year_content'})}/>,
          <TooltipCarouselHistory numberItem={intl.formatMessage({id:'page_about_us_content_area_history_block9_year_title'})} textItem={intl.formatMessage({id:'page_about_us_content_area_history_block9_year_content'})}/>,
          <TooltipCarouselHistory numberItem={intl.formatMessage({id:'page_about_us_content_area_history_block10_year_title'})} textItem={intl.formatMessage({id:'page_about_us_content_area_history_block10_year_content'})}/>,
          <TooltipCarouselHistory numberItem={intl.formatMessage({id:'page_about_us_content_area_history_block11_year_title'})} textItem={intl.formatMessage({id:'page_about_us_content_area_history_block11_year_content'})}/>,
        ]}
      />
    </>
  );
};

export default CarouselHistoryContainer;