import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import './HistoricalProfitability.scss';
import { Box, ButtonLayout, ButtonSecondary, DateField, Divider, DoubleField, Form, GridLayout, IconInformationUserRegular, Inline, LoadingBar, Placeholder, Stack, Text2, Text3, Text5, Tooltip, skinVars, useScreenSize, useWindowSize, SkeletonRectangle } from '@telefonica/mistica';
import { Product } from '../../entities/products/product';
import { formatAnualProfit } from '../../utils/utils';
import moment from 'moment';
import { getProfitabilityPercentage } from '../../utils/apiclient';
import { Profitability } from '../../entities/products/profitability';

interface HistoricalProfitabilityProps {
    product: Product | null
    loading?: boolean
}

const HistoricalProfitability: React.FC<HistoricalProfitabilityProps> = (prop) => {
    const intl = useIntl()

    const screenSize = useScreenSize();
    const size = useWindowSize();
    const [calculatedProfitability, setCalculatedProfitability]= useState(null as null|string);
    const [loading, setLoading]= useState(false);
    const [dateFrom, setDateFrom]= useState(moment().subtract('1','years').format("YYYY-MM-DD"));
    const [dateTo, setDateTo]= useState(moment().format("YYYY-MM-DD"));


    useEffect(()=>{
        setDateFrom(moment(prop.product?.rentabilidad?.fechaValor ?? '').subtract('1','years').format("YYYY-MM-DD").toString());
        setDateTo(moment(prop.product?.rentabilidad?.fechaValor ?? '').format("YYYY-MM-DD").toString());
    },[prop.product]);

    const handleCalculate  = (data: any): Promise<void> =>
        new Promise((resolve) => {
            setLoading(true);
            if(prop.product && prop.product.cdCompania && prop.product.cdFondo && prop.product.cdPlan && prop.product.cdSubplan){
                getProfitabilityPercentage({cdCompany:prop.product.cdCompania, cdFund:prop.product.cdFondo, cdPlan:prop.product.cdPlan, cdSubplan:prop.product.cdSubplan, dateFrom: data.dateFrom, dateTo: data.dateTo }).then((result)=>{
                    const textProfitability = 'Rentabilidad';
                    if(result.result){
                        setCalculatedProfitability(textProfitability+' '+formatAnualProfit(result.result)+'%');
                    }else{
                        setCalculatedProfitability(' - ');
                    }
                }).finally(()=>{
                    setLoading(false);
                    resolve();
                });
            }
        });

    return (<>{prop.loading ?
                <Box paddingY={12}>
                    <SkeletonRectangle height={277} />
                </Box>
            :
            prop.product ?
            <>
                <style>{
                    '.historicalProfitability--content .boxWidth--color{background-color:'+skinVars.colors.brand+';}'+
                    '.historicalProfitability--content .boxWidth--lightColor{background-color:'+skinVars.colors.backgroundAlternative+';}'
                }</style>
                <LoadingBar visible={loading} />
                <Box className='historicalProfitability--content' paddingY={40}>
                    <Stack space={24}>
                        <Inline space={8} alignItems='center'>
                            <Text5>{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_title'})}</Text5>
                            {prop.product.rentabilidad?.fechaRentabilidadInverco &&
                                <Tooltip
                                    targetLabel="Información"
                                    target={<IconInformationUserRegular size={22} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                    description=""
                                    position="top"
                                    extra={
                                        <Inline space={'evenly'}>
                                            <Text2 regular textAlign={'center'}>{(prop.product.rentabilidad?.fechaRentabilidadInverco ? intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_inverco_data'})+moment(prop.product.rentabilidad?.fechaRentabilidadInverco).format('DD/MM/YYYY')+'. ' : '')+intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_past_returns'})}</Text2>
                                        </Inline>
                                    }
                                />
                            }
                        </Inline>


                        {screenSize.isDesktopOrBigger &&
                            <Stack space={16}>
                                <Inline fullWidth space={0} alignItems='flex-end' className='inlineItems--end'>
                                    <Inline space={12}>
                                        <Box className='boxWidth'>
                                            <Inline space={2}>
                                                <Text2 medium color={skinVars.colors.brand}>1</Text2>
                                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_year'})}</Text2>
                                            </Inline>
                                        </Box>

                                        <Box className='boxWidth'>
                                            <Inline space={2}>
                                                <Text2 medium color={skinVars.colors.brand}>3º</Text2>
                                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_year'})}</Text2>
                                            </Inline>
                                        </Box>

                                        <Box className='boxWidth'>
                                            <Inline space={2}>
                                                <Text2 medium color={skinVars.colors.brand}>5º</Text2>
                                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_year'})}</Text2>
                                            </Inline>
                                        </Box>

                                        <Box className='boxWidth'>
                                            <Inline space={2}>
                                                <Text2 medium color={skinVars.colors.brand}>10º</Text2>
                                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_year'})}</Text2>
                                            </Inline>
                                        </Box>
                                    </Inline>
                                </Inline>
                                <Divider />
                            </Stack>
                        }

                        <Inline space={screenSize.isDesktopOrBigger ? 'between' : 'around'} alignItems='center'>
                            {screenSize.isDesktopOrBigger &&
                                <Text3 light>{prop.product?.descripcion}</Text3>
                            }

                            <Inline space={screenSize.isDesktopOrBigger ? 12: 8}>
                                <Stack space={8}>
                                    {!screenSize.isDesktopOrBigger &&
                                        <Box className='boxWidth'>
                                            <Inline space={2}>
                                                <Text2 medium color={skinVars.colors.brand}>1º</Text2>
                                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_year'})}</Text2>
                                            </Inline>
                                        </Box>
                                    }

                                    <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                        <Text3 light color={skinVars.colors.background}>{prop.product?.rentabilidad?.planUltimo ? formatAnualProfit(prop.product?.rentabilidad?.planUltimo)+'%' : ' - '}</Text3>
                                    </Box>
                                </Stack>

                                <Stack space={8}>
                                    {!screenSize.isDesktopOrBigger &&
                                        <Box className='boxWidth'>
                                            <Inline space={2}>
                                                <Text2 medium color={skinVars.colors.brand}>3º</Text2>
                                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_year'})}</Text2>
                                            </Inline>
                                        </Box>
                                    }

                                    <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                        <Text3 light color={skinVars.colors.background}>{prop.product?.rentabilidad?.plan_3 ? formatAnualProfit(prop.product?.rentabilidad?.plan_3)+'%' : ' - '}</Text3>
                                    </Box>
                                </Stack>

                                <Stack space={8}>
                                    {!screenSize.isDesktopOrBigger &&
                                        <Box className='boxWidth'>
                                            <Inline space={2}>
                                                <Text2 medium color={skinVars.colors.brand}>5º</Text2>
                                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_year'})}</Text2>
                                            </Inline>
                                        </Box>
                                    }

                                    <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                        <Text3 light color={skinVars.colors.background}>{prop.product?.rentabilidad?.plan_5 ? formatAnualProfit(prop.product?.rentabilidad?.plan_5)+'%' : ' - '}</Text3>
                                    </Box>
                                </Stack>

                                <Stack space={8}>
                                    {!screenSize.isDesktopOrBigger &&
                                        <Box className='boxWidth'>
                                            <Inline space={2}>
                                                <Text2 medium color={skinVars.colors.brand}>10º</Text2>
                                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_year'})}</Text2>
                                            </Inline>
                                        </Box>
                                    }

                                    <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                        <Text3 light color={skinVars.colors.background}>{prop.product?.rentabilidad?.plan_8 ? formatAnualProfit(prop.product?.rentabilidad?.plan_8)+'%' : ' - '}</Text3>
                                    </Box>
                                </Stack>
                            </Inline>
                        </Inline>

                        <Divider />

                        <Stack space={16}>
                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_customer_online_correspondence_profitability_dates'})}</Text2>
                            <Form onSubmit={handleCalculate} className={loading ? 'loading': ''}>
                                <GridLayout template='6+6' verticalSpace={8}
                                    left={
                                    <DoubleField fullWidth layout='50/50'>
                                        <DateField
                                            fullWidth
                                            name="dateFrom"
                                            max={prop.product && prop.product?.rentabilidad && prop.product?.rentabilidad?.fechaValor ? moment(prop.product?.rentabilidad?.fechaValor).subtract('1','days').toDate() : undefined}
                                            value={dateFrom}
                                            label={intl.formatMessage({id:'page_customer_online_correspondence_field_from'})}
                                            onChangeValue={(value) => {setDateFrom(value)}}
                                        />

                                        <DateField
                                            fullWidth
                                            name="dateTo"
                                            max={prop.product && prop.product?.rentabilidad && prop.product?.rentabilidad?.fechaValor ? moment(prop.product?.rentabilidad?.fechaValor).toDate() : undefined}
                                            value={dateTo}
                                            label={intl.formatMessage({id:'page_customer_online_correspondence_field_to'})}
                                            onChangeValue={(value) => {setDateTo(value)}}
                                        />
                                    </DoubleField>
                                    }
                                    right={
                                        <GridLayout
                                            template='6+6' verticalSpace={8}
                                            left={
                                                <Box paddingY={4}>
                                                    <ButtonLayout align='full-width'>
                                                        <ButtonSecondary submit >{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_consult'})}</ButtonSecondary>
                                                    </ButtonLayout>
                                                </Box>
                                            }
                                            right={
                                                calculatedProfitability!==null && calculatedProfitability.length>0 ?
                                                    <Inline space={'around'}>
                                                        <Box className='boxWidth boxWidth--lightColor' paddingX={screenSize.isDesktopOrBigger ? 16 : 24} paddingY={16}>
                                                            <Text3 regular color={skinVars.colors.textButtonPrimaryInverse}>{calculatedProfitability}</Text3>
                                                        </Box>
                                                    </Inline>
                                                : <></>
                                            }
                                        ></GridLayout>
                                    }/>
                            </Form>
                        </Stack>
                    </Stack>
                </Box>

                {prop.product.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS &&
                    <Box className='historicalProfitability--content values--extend' paddingY={40}>
                        <Stack space={24}>
                            <Inline space={8} alignItems='center'>
                                <Text5>{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_annual_rentability'})}</Text5>
                                <Tooltip
                                    targetLabel="Información"
                                    target={<IconInformationUserRegular size={22} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                    description=""
                                    position="top"
                                    extra={
                                        <Inline space={'evenly'}>
                                            <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_investment_funds_customer_general_historical_profitability_fountain_fonditel'})}</Text2>
                                        </Inline>
                                    }
                                />
                            </Inline>

                            {screenSize.isDesktopOrBigger &&
                                <Stack space={16}>
                                    <Inline fullWidth space={0} alignItems='flex-end' className='inlineItems--end'>
                                        <Inline space={12}>
                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                    <Text2 medium color={skinVars.colors.brand}>{moment().format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>

                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                    <Text2 medium color={skinVars.colors.brand}>{moment().subtract(1, 'years').format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>

                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                <Text2 medium color={skinVars.colors.brand}>{moment().subtract(2, 'years').format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>

                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                <Text2 medium color={skinVars.colors.brand}>{moment().subtract(3, 'years').format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>

                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                    <Text2 medium color={skinVars.colors.brand}>{moment().subtract(4, 'years').format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>

                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                    <Text2 medium color={skinVars.colors.brand}>{moment().subtract(5, 'years').format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>
                                        </Inline>
                                    </Inline>
                                    <Divider />
                                </Stack>
                            }

                            <Inline fullWidth space={0} alignItems='flex-end' className='inlineItems--endDesktop'>
                                <Inline space={screenSize.isDesktopOrBigger ? 12: 8} className='center--movile'>
                                    <Stack space={8}>
                                        {!screenSize.isDesktopOrBigger &&
                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                    <Text2 medium color={skinVars.colors.brand}>{moment().format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>
                                        }

                                        <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                            <Text3 light color={skinVars.colors.background}>{prop.product?.rentabilidad?.rentabilidadActualAnio ? formatAnualProfit(prop.product?.rentabilidad?.rentabilidadActualAnio)+'%' : ' - '}</Text3>
                                        </Box>
                                    </Stack>

                                    <Stack space={8}>
                                        {!screenSize.isDesktopOrBigger &&
                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                    <Text2 medium color={skinVars.colors.brand}>{moment().subtract(1, 'years').format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>
                                        }

                                        <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                            <Text3 light color={skinVars.colors.background}>{prop.product?.rentabilidad?.rentabilidadAnio1 ? formatAnualProfit(prop.product?.rentabilidad?.rentabilidadAnio1)+'%' : ' - '}</Text3>
                                        </Box>
                                    </Stack>

                                    <Stack space={8}>
                                        {!screenSize.isDesktopOrBigger &&
                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                    <Text2 medium color={skinVars.colors.brand}>{moment().subtract(2, 'years').format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>
                                        }

                                        <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                            <Text3 light color={skinVars.colors.background}>{prop.product?.rentabilidad?.rentabilidadAnio2 ? formatAnualProfit(prop.product?.rentabilidad?.rentabilidadAnio2)+'%' : ' - '}</Text3>
                                        </Box>
                                    </Stack>

                                    <Stack space={8}>
                                        {!screenSize.isDesktopOrBigger &&
                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                    <Text2 medium color={skinVars.colors.brand}>{moment().subtract(3, 'years').format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>
                                        }

                                        <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                            <Text3 light color={skinVars.colors.background}>{prop.product?.rentabilidad?.rentabilidadAnio3 ? formatAnualProfit(prop.product?.rentabilidad?.rentabilidadAnio3)+'%' : ' - '}</Text3>
                                        </Box>
                                    </Stack>

                                    <Stack space={8}>
                                        {!screenSize.isDesktopOrBigger &&
                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                    <Text2 medium color={skinVars.colors.brand}>{moment().subtract(4, 'years').format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>
                                        }

                                        <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                            <Text3 light color={skinVars.colors.background}>{prop.product?.rentabilidad?.rentabilidadAnio4 ? formatAnualProfit(prop.product?.rentabilidad?.rentabilidadAnio4)+'%' : ' - '}</Text3>
                                        </Box>
                                    </Stack>

                                    <Stack space={8}>
                                        {!screenSize.isDesktopOrBigger &&
                                            <Box className='boxWidth'>
                                                <Inline space={2}>
                                                    <Text2 medium color={skinVars.colors.brand}>{moment().subtract(5, 'years').format('YYYY')}</Text2>
                                                </Inline>
                                            </Box>
                                        }

                                        <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                            <Text3 light color={skinVars.colors.background}>{prop.product?.rentabilidad?.rentabilidadAnio5 ? formatAnualProfit(prop.product?.rentabilidad?.rentabilidadAnio5)+'%' : ' - '}</Text3>
                                        </Box>
                                    </Stack>
                                </Inline>
                            </Inline>
                        </Stack>
                    </Box>
                }
            </>
            : <></>
        }</>);
};

export default HistoricalProfitability;
