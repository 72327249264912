import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { IonContent } from '@ionic/react';

import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';

import { Box, GridLayout, Hero, Image, ResponsiveLayout, Stack, Text3, Text7, skinVars, useScreenSize } from '@telefonica/mistica';

import './CertificatesPage.scss';
import LayoutPublic from '../../../components/layout/LayoutPublic';

const CertificatesPage: React.FC = () => {
  const intl = useIntl();
  const size = useScreenSize();

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_certificates_seo_title'}), metaDescription:intl.formatMessage({id:'page_certificates_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_certificates_title'})}}
    >
    <ResponsiveLayout className='container' fullWidth={true} backgroundColor={"transparent"}>
      <ResponsiveLayout className='container' fullWidth={true}  backgroundColor={skinVars.colors.backgroundAlternative}>
        <ResponsiveLayout className='content' backgroundColor={"transparent"}>
          <Box paddingY={40}>
            <GridLayout template='6+6'
              left={
                <Stack space={24}>
                  <Box>
                    <Text7>{intl.formatMessage({id:'page_certificates_title'})}</Text7>
                  </Box>
                </Stack>
              }
              right={
                <></>
              }
            />
          </Box>
        </ResponsiveLayout>
      </ResponsiveLayout>
      <ResponsiveLayout className='container' fullWidth={true} backgroundColor={"transparent"}>
        <ResponsiveLayout className='content' fullWidth={!size.isDesktopOrBigger} backgroundColor={"transparent"}>
          <Box paddingY={40}>
            <Stack space={80}>
            <Hero
              media={size.isDesktopOrBigger ? (<Image src="../assets/img/certificacion-espacio-covid-19-protegido.jpg" width="100%"/>) : size.isTablet ? (<Image src="../assets/img/certificacion-espacio-covid-19-protegido-responsive.jpg" height="450px"/>) : (<Image src="../assets/img/certificacion-espacio-covid-19-protegido-responsive.jpg" height="211px"/>)}
              title={intl.formatMessage({id:'page_certificates_title_block1'})}
              extra={
                <Box paddingY={24}>
                  <Text3 light><div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_certificates_description_block1'})}}></div></Text3>
                </Box>
              }
              desktopMediaPosition='right'/>
            <Hero
              media={size.isDesktopOrBigger ? (<Image src="../assets/img/certificado-iso-45001.jpg" width="100%"  height="650px"/>) : size.isTablet ? (<Image src="../assets/img/certificado-iso-45001-responsive.jpg"  height="450px"/>) : (<Image src="../assets/img/certificado-iso-45001-responsive.jpg"  height="211px"/>)}
              title={intl.formatMessage({id:'page_certificates_title_block2'})}
              extra={
                <Box paddingY={24}>
                  <Text3 light><div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_certificates_description_block2'})}}></div></Text3>
                </Box>
              }
              desktopMediaPosition='left'/>
            <Hero
              media={size.isDesktopOrBigger ? (<Image src="../assets/img/certificado-lgai-technological-center-sa-applus.jpg" width="100%" height="650px"/>) : size.isTablet ? (<Image src="../assets/img/certificado-lgai-technological-center-sa-applus-responsive.jpg" height="450px"/>) : (<Image src="../assets/img/certificado-lgai-technological-center-sa-applus-responsive.jpg" height="211px"/>)}
              title={intl.formatMessage({id:'page_certificates_title_block3'})}
              extra={
                <Box paddingY={24}>
                  <Text3 light><div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_certificates_description_block3'})}}></div></Text3>
                </Box>
              }
              desktopMediaPosition='right'/>
            </Stack>
          </Box>
        </ResponsiveLayout>
      </ResponsiveLayout>
    </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default CertificatesPage;
