

import { useIonRouter } from '@ionic/react';
import { Box, ButtonLink, ButtonPrimary, ButtonSecondary, Carousel, DataCard, Grid, Hero, IconArrowLineRightRegular, IconEcoRegular, IconFlagRegular, IconSmileyHappyRegular, IconTrendUpRegular, Image, ResponsiveLayout, Stack, Text, Text8, Video, skinVars, useScreenSize } from '@telefonica/mistica';
import { useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import LastNewsContainer from '../../../components/news/lastNews/LastNews';
import NumbersContainer from '../../../components/numbers/Numbers';
import OurAppContainer from '../../../components/staticPages/ourApp/OurApp';
import { Content } from '../../../entities/contents/content.model';
import { getContent } from '../../../utils/apiclient';
import { getUrlNavigation } from '../../../utils/navigation';
import { isExternalUrl, isMobile, validHttpUrl, isBot, trackEvent } from '../../../utils/utils';
import EmploymentSystemPensionPlansPage from '../employment-system-pension-plans/EmploymentSystemPensionPlansPage';
import InvestmentFundsPage from '../investment-funds/InvestmentFundsPage';
import PensionPlansPage from '../pension-plans/PensionPlansPage';
import HeroShadow from '../../../components/hero/HeroShadow';

const HomePage: React.FC = () => {
  const router = useIonRouter();
  const intl = useIntl()
  const size = useScreenSize();
  const screenSize = useScreenSize();
  const [content, setContent] = useState(undefined as Content|undefined);

  useLayoutEffect(()=>{
    getContent('home').then((result)=>{
      setContent(result);
    });
  }, []);

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_home_title'}), metaDescription: intl.formatMessage({id:'page_home_seo_description'})}}
    >

      <HeroShadow
          video={content?.video ?? undefined}
          image={content?.image ?? '../assets/img/nosotros.jpg'}
          title={<Text8>{intl.formatMessage({id:'page_home_black_title'})} <Text color={skinVars.colors.backgroundBrand}>{intl.formatMessage({id:'page_home_blue_title'})}</Text> <Text>{intl.formatMessage({id:'page_home_black_title2'})}</Text></Text8>}
          button={
            validHttpUrl(intl.formatMessage({id:'page_home_button_link'})) &&
              (isBot || isExternalUrl(intl.formatMessage({id:'page_home_button_link'})) ?
                <ButtonPrimary newTab href={intl.formatMessage({id:'page_home_button_link'})}>{intl.formatMessage({id:'page_home_button'})}</ButtonPrimary>
              :
                <ButtonPrimary onPress={()=> {router.push(intl.formatMessage({id:'page_home_button_link'})); trackEvent('home-banner-clic');}}>{intl.formatMessage({id:'page_home_button'})}</ButtonPrimary>
              )
          }
          children={
            <Carousel initialActiveItem={0} itemsPerPage={size.isMobile ? 1 : (size.isTablet ? 3 : 4)}
                items={[
                  <DataCard
                    icon={<img src={'../assets/img/telefonica-logo.svg'} width="40px" height="40px" />}
                    title={intl.formatMessage({id:'page_home_card1_title'})}
                    description={intl.formatMessage({id:'page_home_card1_description'})}
                    buttonLink=
                    {
                      validHttpUrl(intl.formatMessage({id:'page_home_card1_link'})) ?
                        (isBot || isExternalUrl(intl.formatMessage({id:'page_home_card1_link'})) ?
                          <ButtonLink withChevron={false} newTab  href={intl.formatMessage({id:'page_home_card1_link'})}>{intl.formatMessage({id:'page_home_hero_data_card_action'})} <IconArrowLineRightRegular color='currentColor'></IconArrowLineRightRegular></ButtonLink>
                        :
                          <ButtonLink onPress={()=> {router.push(intl.formatMessage({id:'page_home_card1_link'})); trackEvent('home-card_1-leer_mas'); }}>{intl.formatMessage({id:'page_home_hero_data_card_action'})} <IconArrowLineRightRegular color='currentColor'></IconArrowLineRightRegular></ButtonLink>
                        )
                      : undefined
                    }/>,
                  <DataCard
                    icon={<IconTrendUpRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconTrendUpRegular>}
                    title={intl.formatMessage({id:'page_home_card2_title'})}
                    description={intl.formatMessage({id:'page_home_card2_description'})}
                    buttonLink=
                    {
                      validHttpUrl(intl.formatMessage({id:'page_home_card2_link'})) ?
                        (isBot || isExternalUrl(intl.formatMessage({id:'page_home_card2_link'})) ?
                          <ButtonLink withChevron={false} newTab  href={intl.formatMessage({id:'page_home_card2_link'})}>{intl.formatMessage({id:'page_home_hero_data_card_action'})} <IconArrowLineRightRegular color='currentColor'></IconArrowLineRightRegular></ButtonLink>
                        :
                          <ButtonLink onPress={()=> {router.push(intl.formatMessage({id:'page_home_card2_link'})); trackEvent('home-card_2-leer_mas'); }}>{intl.formatMessage({id:'page_home_hero_data_card_action'})} <IconArrowLineRightRegular color='currentColor'></IconArrowLineRightRegular></ButtonLink>
                        )
                      : undefined
                    }/>,
                  <DataCard
                    icon={<IconSmileyHappyRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconSmileyHappyRegular>}
                    title={intl.formatMessage({id:'page_home_card3_title'})}
                    description={intl.formatMessage({id:'page_home_card3_description'})}
                    buttonLink=
                    {
                      validHttpUrl(intl.formatMessage({id:'page_home_card3_link'})) ?
                        (isBot || isExternalUrl(intl.formatMessage({id:'page_home_card3_link'})) ?
                          <ButtonLink withChevron={false} newTab  href={intl.formatMessage({id:'page_home_card3_link'})}>{intl.formatMessage({id:'page_home_hero_data_card_action'})} <IconArrowLineRightRegular color='currentColor'></IconArrowLineRightRegular></ButtonLink>
                        :
                          <ButtonLink onPress={()=> {router.push(intl.formatMessage({id:'page_home_card3_link'})); trackEvent('home-card_3-leer_mas'); }}>{intl.formatMessage({id:'page_home_hero_data_card_action'})} <IconArrowLineRightRegular color='currentColor'></IconArrowLineRightRegular></ButtonLink>
                        )
                      : undefined
                    }/>,
                  <DataCard
                    icon={<IconEcoRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconEcoRegular>}
                    title={intl.formatMessage({id:'page_home_card4_title'})}
                    description={intl.formatMessage({id:'page_home_card4_description'})}
                    buttonLink=
                    {
                      validHttpUrl(intl.formatMessage({id:'page_home_card4_link'})) ?
                        (isBot || isExternalUrl(intl.formatMessage({id:'page_home_card4_link'})) ?
                          <ButtonLink withChevron={false} newTab  href={intl.formatMessage({id:'page_home_card4_link'})}>{intl.formatMessage({id:'page_home_hero_data_card_action'})} <IconArrowLineRightRegular color='currentColor'></IconArrowLineRightRegular></ButtonLink>
                        :
                          <ButtonLink onPress={()=> {router.push(intl.formatMessage({id:'page_home_card4_link'})); trackEvent('home-card_4-leer_mas'); }}>{intl.formatMessage({id:'page_home_hero_data_card_action'})} <IconArrowLineRightRegular color='currentColor'></IconArrowLineRightRegular></ButtonLink>
                        )
                      : undefined
                    }/>
                ]}
              />
          }
      ></HeroShadow>

      <ResponsiveLayout className='content' fullWidth={true}>

        <Hero
          media={<Image src={content?.imageBloque1 ?? '../assets/img/home-planes-pensiones.jpg'} width="100%" height='337px'/>}
          title={intl.formatMessage({id:'page_home_hero1_title'})}
          description={intl.formatMessage({id:'page_home_hero1_description'})}
          desktopMediaPosition='left'
          button={isBot ?
                    <ButtonSecondary href={getUrlNavigation(EmploymentSystemPensionPlansPage)}>{intl.formatMessage({id:'page_home_hero_action'})}</ButtonSecondary>
                  :
                    <ButtonSecondary onPress={()=>{router.push(getUrlNavigation(EmploymentSystemPensionPlansPage)); trackEvent('home-planes_empleo-click'); }}>{intl.formatMessage({id:'page_home_hero_action'})}</ButtonSecondary>
          }/>
        <Hero
          media={<Image src={content?.imageBloque2 ?? '../assets/img/home-fondos-inversion.jpg'} width="100%" height='337px'/>}
          title={intl.formatMessage({id:'page_home_hero2_title'})}
          description={intl.formatMessage({id:'page_home_hero2_description'})}
          desktopMediaPosition='right'
          button={isBot ?
                    <ButtonSecondary href={getUrlNavigation(InvestmentFundsPage)}>{intl.formatMessage({id:'page_home_hero_action'})}</ButtonSecondary>
                  :
                    <ButtonSecondary onPress={()=>{router.push(getUrlNavigation(InvestmentFundsPage)); trackEvent('home-fondos-click');}}>{intl.formatMessage({id:'page_home_hero_action'})}</ButtonSecondary>
          }/>
        <Hero
          media={<Image src={content?.imageBloque3 ?? '../assets/img/home-planes-pensiones-empleo.jpg'} width="100%" height='337px'/>}
          title={intl.formatMessage({id:'page_home_hero3_title'})}
          description={intl.formatMessage({id:'page_home_hero3_description'})}
          desktopMediaPosition='left'
          button={isBot ?
                    <ButtonSecondary href={getUrlNavigation(PensionPlansPage)}>{intl.formatMessage({id:'page_home_hero_action'})}</ButtonSecondary>
                  :
                    <ButtonSecondary onPress={()=>{router.push(getUrlNavigation(PensionPlansPage)); trackEvent('home-planes-click');}}>{intl.formatMessage({id:'page_home_hero_action'})}</ButtonSecondary>
          }/>

      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
        <ResponsiveLayout>

          <LastNewsContainer />

        </ResponsiveLayout>
      </ResponsiveLayout>

      <ResponsiveLayout className='content numbers-container' fullWidth={true} isInverse>
        <ResponsiveLayout fullWidth={false}>

          <NumbersContainer />

        </ResponsiveLayout>
      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth={true}>

        <OurAppContainer/>

      </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default HomePage;
