import React from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import CardNewContainer from '../CardNew';

import { News } from '../../../entities/news/news.model';

import { GridLayout, Stack, Text3 } from '@telefonica/mistica';

import './ReleatedPosts.scss';

interface ReleatedPosts {
    news: News[],
}

const ReleatedPostsContainer: React.FC<ReleatedPosts> = (prop) => {

const intl = useIntl();

const [news, setNews] = React.useState([] as News[])

useEffect(()=>{
    setNews(prop.news);
  }, [prop]);

  return (
    <>
        <Stack space={12}>
            <Text3 medium>{intl.formatMessage({id:'page_news_related_content'})}</Text3>
            <GridLayout template="8+4" verticalSpace={32}
                left={
                    <GridLayout template="6+6"
                        left={
                            <CardNewContainer news = {news[0]} type='Media'/>
                        }
                        right={
                            <CardNewContainer news = {news[1]} type='Media'/>
                        }
                    />
                }
                right={
                    <CardNewContainer news = {news[2]} type='Media'/>
                }
            />
        </Stack>
    </>
  );
};

export default ReleatedPostsContainer;