import { useIonRouter } from '@ionic/react';
import { Box, ButtonPrimary, Inline, ResponsiveLayout, Stack, Text6, Video, skinVars, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import ChipTabs from '../../../../components/chip-tabs/ChipTabs';
import EmptyStateCustom from '../../../../components/empty-state/EmptyStateCustom';
import HeroImage from '../../../../components/hero/HeroImage';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import MembersOf from '../../../../components/staticPages/membersOf/MembersOf';
import { getUrlNavigation } from '../../../../utils/navigation';
import { trackEvent } from '../../../../utils/utils';
import FinalCapitalCalculatorPage from '../../for-investor/calculators/FinalCapitalCalculatorPage';
import '../LandingPage.scss';

const FiscalExerciseIndividualPensionPlansPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
	const router = useIonRouter();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [options, setOptions] = useState(['Aportaciones', 'Aportaciones especiales', 'Autónomos', '¿Cómo tributan las prestaciones?']);

  return (
    <LayoutPublic
      seo={{metaTitle: 'Fonditel IRPF 2022', metaDescription:'Fonditel IRPF 2022'}}
    >
      <style>{'.landingPage--content .html-content ul li:before{background: '+skinVars.colors.textPrimary+';}'}</style>
      <HeroImage
        image=''
        video={
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/1Mb1CvcJZlQ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        }
        title='Planes de Pensiones Individuales'
        imageNotRound={true}
      ></HeroImage>

      <ChipTabs
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
        tabs={options.map((text) => ({
          text,
        }))}
      />

      <ResponsiveLayout className={(selectedIndex===0 ? 'landingPage--content d-block' : 'd-none')}>
        <div className='html-content' dangerouslySetInnerHTML={{__html:
          `<p>Todas las aportaciones realizadas a planes de pensiones permiten el diferimiento fiscal y generan un beneficio directo: desgravan en el IRPF. Con ello se consigue una disminución de los impuestos y poder diferir el pago en el IRPF hasta el momento del rescate del plan, seguramente en la jubilación donde los rendimientos del trabajo son menores que cuando se está en activo.</p>
           <p>El 1 de enero de 2022, entraba en vigor la reforma en materia de planes de pensiones para residentes fiscales en España, por la cual, las aportaciones a planes de pensiones individuales se reducen de 2.000 euros anuales a 1.500 euros anuales. El máximo del 30% de los rendimientos netos del trabajo y de actividades económicas, se mantiene igual que en 2021, por lo que el máximo anual a aportar será ese 30% o los 1.500 euros (la menor cifra de las dos).</p>
           <p>En caso de contar con un plan de pensiones de empleo, en dicho importe se incluyen las aportaciones que el promotor del plan le haya imputado. De cara al ejercicio fiscal 2022 también han sufrido algunas variaciones, por lo que te invitamos a visitar la pestaña de Planes de Pensiones de Empleo.</p>
           <p>Sin embargo, para la Declaración de la Renta del ejercicio fiscal 2021, los partícipes de planes de pensiones individuales podrán continuar desgravándose hasta 2.000€ de sus aportaciones al plan</p>`
        }}></div>
      </ResponsiveLayout>

      <ResponsiveLayout className={(selectedIndex===1 ? 'landingPage--content d-block' : 'd-none')}>
        <div className='html-content' dangerouslySetInnerHTML={{__html:
          `<ul>
            <li><strong>Cónyuge:</strong> La cantidad máxima de deducción por aportaciones a planes de pensiones a favor del cónyuge, se mantiene en 2022 en 1.000 euros, sin perjuicio de las aportaciones que puedan realizar a su propio plan de pensiones.</li>
            <li><strong>Personas con discapacidad:</strong> Las aportaciones anuales máximas realizadas por las personas con discapacidad no podrán rebasar la cantidad de 24.250 euros. Las aportaciones anuales máximas realizadas a favor de persona con discapacidad no podrán rebasar la cantidad de 10.000 euros, sin perjuicio de las aportaciones que pueda realizar a su propio plan de pensiones. Para ello se requiere un vínculo de parentesco.</li>
          </ul>`
        }}></div>
      </ResponsiveLayout>

      <ResponsiveLayout className={(selectedIndex===2 ? 'landingPage--content d-block' : 'd-none')}>
        <div className='html-content' dangerouslySetInnerHTML={{__html:
          `<p>En 2022 el trabajador autónomo sólo podía aportar un máximo de 1.500 euros a los planes de pensiones individuales.</p>
           <p>La entrada en vigor de la Ley 12/2022, faculta a los autónomos acceder en 2023 a una nueva figura como son los nuevos planes de pensiones de empleo simplificados, que serán promovidos desde las diferentes asociaciones de autónomos.</p>
           <p>Esta nueva legislación va a permitir al trabajador autónomo, a partir de 2023, ampliar la aportación máxima desde 1.500 euros, hasta los 5.750 euros anuales siempre que se aporten a un plan de pensiones de empleo simplificado promovido por una asociación de autónomos.</p>`
        }}></div>
      </ResponsiveLayout>

      <ResponsiveLayout className={(selectedIndex===3 ? 'landingPage--content d-block' : 'd-none')}>
        <div className='html-content' dangerouslySetInnerHTML={{__html:
          `<p>Los rescates de los planes de pensiones tributan como rendimientos del trabajo, al tipo que corresponda según tu nivel de ingresos. Esto es muy importante, especialmente si te planteas cobrar el plan de pensiones el mismo año que te jubilas, ya que tus rendimientos del trabajo serán superiores a tu futura pensión pública, por lo que la factura con Hacienda también será más alta que si esperas al año siguiente.</p>
           <p>Las prestaciones recibidas por los beneficiarios del plan tributan en su integridad como rendimientos del trabajo, igual que si percibieras un salario. Hay que tener en cuenta que a efectos fiscales la entidad que abona las prestaciones tiene la consideración de pagador.</p>
           <p><strong>Tributación</strong></p>
           <ul>
              <li>Si la prestación de los planes de pensiones es en forma de renta, la cantidad total percibida se integrará en la base imponible del IRPF como rendimiento del trabajo.</li>
              <li>Si la prestación de los planes de pensiones es en forma de capital, también se integrará en la base imponible del IRPF como rendimiento del trabajo.</li>
              <li>Dependiendo de la fecha de la contingencia, existen unos plazos para aplicar una reducción del 40% a las cantidades aportadas antes del 31 de diciembre de 2006.</li>
           </ul>`
        }}></div>
      </ResponsiveLayout>

      <ResponsiveLayout className={(selectedIndex===4 ? 'landingPage--content d-block' : 'd-none')}>
        <div className='html-content' dangerouslySetInnerHTML={{__html:
          `<p>Las aportaciones a un plan de pensiones tienen ventajas fiscales al reducir la base imponible general del IRPF. Las prestaciones que se cobren durante la jubilación normalmente tributarán a un tipo inferior al que se tributó durante la etapa laboral.</p>
           <p>Si el ahorro fiscal generado año a año en la declaración de la renta se reinvierte en el plan de pensiones, el efecto multiplicador hace aumentar el importe acumulado de manera considerable.</p>`
        }}></div>
      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth>

        <Box paddingTop={screenSize.isDesktopOrBigger ? 80 : 32} paddingBottom={screenSize.isDesktopOrBigger ? 32 : 16}>
          <Inline space={'evenly'}>
            <iframe width={screenSize.isTabletOrBigger ? 675 : 320} height={screenSize.isTabletOrBigger ? 380 : 175} src="https://www.youtube.com/embed/fV9c1kx2T-Q" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
          </Inline>
        </Box>

      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth>

        <EmptyStateCustom
          largeImageUrl="../assets/img/ejercicio-fiscal.jpg"
          title='Accede a nuestra web y usa nuestra calculadora de Capital Final y Ahorro Fiscal'
          button={
            <ButtonGroup
              primaryButton={
                <ButtonPrimary onPress={() => {router.push(getUrlNavigation(FinalCapitalCalculatorPage)); trackEvent('landing_irpf-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_final_capital_calculator_title'})).replaceAll('-', '_')); }}>
                    Calculadoras
                </ButtonPrimary>
              }
            ></ButtonGroup>
          }
        />

      </ResponsiveLayout>

      <ResponsiveLayout className='content landingPage--content' fullWidth>
        <ResponsiveLayout>

          <Box className='blueText--block' paddingY={screenSize.isDesktopOrBigger ? 80 : 24}>
            <Stack space={16} className='boxAlignCenter'>
              <Text6 color={skinVars.colors.brand}>“Tener un plan de pensiones supone un ahorro, no solo de cara a la jubilación, también cada año cuando llega el momento de realizar la declaración de la renta.”</Text6>
            </Stack>
          </Box>

        </ResponsiveLayout>
      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth>
        <MembersOf></MembersOf>
      </ResponsiveLayout>

    </LayoutPublic>
  );
};

export default FiscalExerciseIndividualPensionPlansPage;
