import { IonContent, useIonRouter } from '@ionic/react';
import { Box, ButtonLink, Carousel, Circle, Inline, PageBullets, Placeholder, ResponsiveLayout, Slideshow, Stack, Text3, Text4, Text7, skinVars, useWindowSize } from '@telefonica/mistica';
import React, { useEffect, useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';
import './OnBoardingInitAppPage.scss';
import { getUrlNavigation } from '../../../utils/navigation';
import LoginPage from '../../private/login/LoginPage';
import { getFirstLoginApp, getFirstTimeApp, setFirstTimeApp } from '../../../utils/storage';

const OnBoardingInitAppPage: React.FC = () => {

  const intl = useIntl()
  const router = useIonRouter();
  const [sizeCircle, setSizeCircle] = React.useState(320);
  const size = useWindowSize();


  const [pageInfo, setPageInfo] = React.useState<{
            pageIndex: number;
            shownItemIndexes: Array<number>;
        } | null>({pageIndex:0, shownItemIndexes:[]});


  useLayoutEffect(()=>{
    if(getFirstTimeApp()){
      setFirstTimeApp();
    }else{
      router.push(getUrlNavigation(LoginPage));
      return;
    }
  }, []);

  useEffect(()=>{
    if(size.height<590 && size.height>520){
      setSizeCircle(230);
    }else if(size.height<520){
        setSizeCircle(180);
    }else{
      setSizeCircle(320);
    }
  }, [size]);

  const handleSubmitOnBoarding = (): Promise<void> =>
    new Promise((resolve) => {
      router.push(getUrlNavigation(LoginPage));
      resolve();
  });

  return (
    <IonContent>
      <ResponsiveLayout className={'initApp'} fullWidth>
        <Stack space={'between'}>

          <Stack space={0}>

            <Box paddingTop={32} paddingBottom={40} className={'initApp__header '+ 'step-'+pageInfo?.pageIndex??0}>
              <div className="image">
                <Circle size={sizeCircle} backgroundImage={'../../assets/img/onboarding-init-app-01.jpg'}></Circle>
              </div>

              <div className="image-step-1">
                <Circle size={sizeCircle} backgroundImage={'../../assets/img/onboarding-init-app-02.jpg'}></Circle>
              </div>

              <div className="image-step-2">
                <Circle size={sizeCircle} backgroundImage={'../../assets/img/onboarding-init-app-03.jpg'}></Circle>
              </div>
            </Box>

            <Box className='initApp__slider'>
              <Carousel initialActiveItem={pageInfo?.pageIndex??0}
                items={[
                  <Box padding={16} paddingRight={32} className='step-1'>
                    <Stack space={16}>
                      <Text7 >{intl.formatMessage({id:'page_splash_screen_step1_title'})}</Text7>
                      <Text4 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_splash_screen_step1_subtitle'})}</Text4>
                    </Stack>
                  </Box>,

                  <Box padding={16} paddingRight={32} className='step-2'>
                    <Stack space={16}>
                      <Text7 >{intl.formatMessage({id:'page_splash_screen_step2_title'})}</Text7>
                      <Text4 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_splash_screen_step2_subtitle'})}</Text4>
                    </Stack>
                  </Box>,

                  <Box padding={16} paddingRight={32} className='step-3'>
                    <Stack space={16}>
                      <Text7 >{intl.formatMessage({id:'page_splash_screen_step3_title'})}</Text7>
                      <Text4 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_splash_screen_step3_subtitle'})}</Text4>
                    </Stack>
                  </Box>,
                ]}
              />
            </Box>

          </Stack>

          <Box paddingY={8} paddingX={8}>

            <Inline space={'between'} alignItems='center'>

              <ButtonLink onPress={handleSubmitOnBoarding}>
                <Text3 regular color='currentColor'>{intl.formatMessage({id:'page_splash_screen_button_end'})}</Text3>
              </ButtonLink>

              <PageBullets numPages={3} currentIndex={pageInfo?.pageIndex??0}></PageBullets>

              {pageInfo?.pageIndex === 2 ?
                <ButtonLink onPress={handleSubmitOnBoarding}>
                  <Text3 regular color='currentColor'>{intl.formatMessage({id:'page_splash_screen_button_start'})}</Text3>
                </ButtonLink>
              :
                <ButtonLink onPress={() => {setPageInfo({pageIndex:(pageInfo?.pageIndex??0)+1, shownItemIndexes: []})}}>
                  <Text3 regular color='currentColor'>{intl.formatMessage({id:'page_splash_screen_button_next'})}</Text3>
                </ButtonLink>
              }

            </Inline>

          </Box>
        </Stack>
      </ResponsiveLayout>
    </IonContent>
  );
};

export default OnBoardingInitAppPage;
