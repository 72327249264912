import { Box, Boxed, ButtonFixedFooterLayout, ButtonLayout, ButtonPrimary, ButtonSecondary, DecimalField, Form, Grid, Inline, LoadingBar, RadioButton, RadioGroup, ResponsiveLayout, Select, skinVars, Stack, Text1, Text2, Text3, Text4, Text5, useScreenSize } from '@telefonica/mistica';
import React, { useEffect, useState } from "react";
import { useIntl } from 'react-intl';

import { useIonRouter } from '@ionic/react';
import { Option } from '../../entities/commons/option.model';
import { Contract } from '../../entities/contract/contract';
import { OperationMobilization } from '../../entities/contract/operations/operation-mobilization';
import { addMobilization, getExternalPlans, getPlanManagers } from '../../utils/apiclient';
import { getCurrentUserContract, removeOperationMobilization, setOperationMobilization } from '../../utils/storage';
import { formatAmount, trackError } from '../../utils/utils';

interface MobilizePensionPlanProps {
    contract: Contract | null,
    loading?: boolean,
    onCancel?:() => void,
    onConfirm:() => void
}

const MobilizePensionPlan: React.FC<MobilizePensionPlanProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const router = useIonRouter();
    const [contract, setContract] = useState(null as null|Contract);
    const [planManagersOptions, setPlanManagersOptions] = React.useState([] as Option[]);
    const [externalPlansOptions, setExternalPlansOptions] = React.useState([] as Option[]);
    const [contractsUser, setContractsUser] = React.useState([] as Contract[]);

    const [loading, setLoading] = React.useState(true);

    //Loading form
    const [loadingForm, setLoadingForm] = React.useState(false);

    useEffect(()=>{
        removeOperationMobilization();
        if(prop.contract){
            setContract(prop.contract);

            const uc = getCurrentUserContract();
            if(uc){
                setContract(uc)

                if(uc.planesContratados && uc.planesContratados.length>0){
                    setContractsUser(uc.planesContratados);
                }
            }

            getPlanManagers(prop.contract.plan?.cdCompania??-1).then(options=>{
                setPlanManagersOptions(options);
            }).finally(()=>{
                setLoading(false);
            })

        }
    },[prop.contract]);

    //Form
    const initFormData = {
        transferTypeParent: (process.env.REACT_APP_TRASLADO_TOTAL??'0'),
        transferType: '',
        originType: (process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO??'E'),
        cdExternalPromoter: '',
        cdExternalPlan: '',
        cdFonditelContract: '',
        partialsTransfers: '',
        amount: '',
    }
    const [formData, setFormData] = useState(initFormData);
    const [formErrors, setFormErrors] = useState([] as any);

    //Handle fields
    const handleError=(name:string) =>{
            if(formErrors && formErrors[name]){
            const error = formErrors[name];
            if(error){
                return intl.formatMessage({id: 'page_operate_mobilize_'+error.toLowerCase().replaceAll(' ', '_')});
            }
        }
        return null;
    }

    const validateField = (name:string, value:any)=>{
        switch(name){
            case 'amount':
                if(value && value.length>0 && formData.transferType===process.env.REACT_APP_TRASLADO_PARCIAL_PORCENTAJE && (parseFloat(value.replace(',', '.')) < 1 || parseFloat(value.replace(',', '.')) >= 100)){
                    formErrors[name] = 'percentage between 0 and 100';
                    return false;
                }
                break;
            case 'transferType':
                if(value && value.length>0 && value===process.env.REACT_APP_TRASLADO_PARCIAL_PORCENTAJE && (parseFloat(formData.amount.replace(',', '.')) < 1 || parseFloat(formData.amount.replace(',', '.')) >= 100)){
                    formErrors['amount'] = 'percentage between 0 and 100';
                    return false;
                }
                break;
          }
          return true;
    }

    const handleChangeAnyField= (name:string, value:any) => {
        const newValues = {
            ...formData,
            [name]: value,
        };
        setFormData(newValues);

        switch(name){
            case 'cdExternalPromoter':
                setExternalPlansOptions([]);
                try{
                  if(value && value.toString().trim().length>0){
                    setLoadingForm(true);
                    getExternalPlans(value.toString().trim()).then(options=>{
                      if(options){
                          setExternalPlansOptions(options);
                      }
                    }).finally(()=>{
                      setLoadingForm(false);
                    });
                  }
                }catch(err){
                    if(err){
                        trackError(JSON.stringify(err));
                    }
                }
                break;
        }

        if(handleError(name)){
            delete formErrors[name];
        }
        validateField(name, value);
    };

    const handleSubmit = (data: any): Promise<void> =>
        new Promise((resolve) => {
            setLoadingForm(true);

            let operation = getOperation();

            addMobilization(operation).then((result)=>{
                if(result.result){
                    operation.summary = getSummary();
                    setOperationMobilization(operation);
                    prop.onConfirm();
                }else if(result.errors){
                    const errors = result.errors as any;
                    setFormErrors(result.errors);
                }
            }).finally(()=>{
                setLoadingForm(false);
                resolve();
            })
        });

    const getOperation = ()=>{
        let operation: OperationMobilization = {
            transferType: formData.transferTypeParent===process.env.REACT_APP_TRASLADO_TOTAL ? parseInt(formData.transferTypeParent) : parseInt(formData.transferType),
            originType: formData.originType,
            cdExternalPromoter: formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO ? formData.cdExternalPromoter : '',
            cdExternalPlan: formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO ? formData.cdExternalPlan : '',
            cdFonditelContract: formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_FONDITEL ? formData.cdFonditelContract : '',
            partialsTransfers: formData.transferTypeParent!==process.env.REACT_APP_TRASLADO_TOTAL ? formData.partialsTransfers : '',
            amount: formData.transferTypeParent!==process.env.REACT_APP_TRASLADO_TOTAL ? parseFloat(formData.amount.replace(',', '.')) : undefined,
            confirm: false
        };
        return operation;
    }

    const getSummary= () => {
        let summary: Array<{title:string, value:string}> = [];

        if(formData.transferTypeParent===process.env.REACT_APP_TRASLADO_TOTAL){
            summary.push({title:intl.formatMessage({id:'page_operate_mobilize_type_of_transfer_title'}), value:'Todo el contenido del plan'});

        }else{
            summary.push({title:intl.formatMessage({id:'page_operate_mobilize_type_of_transfer_title'}), value:'Parcial'});

            switch(formData.transferType){
                case process.env.REACT_APP_TRASLADO_PARCIAL_IMPORTE:
                    summary.push({title:intl.formatMessage({id:'page_operate_mobilize_amount_title'}), value:formatAmount(parseFloat(formData.amount.replace(',', '.')))+'€'});
                    break;
                case process.env.REACT_APP_TRASLADO_PARCIAL_UNIDADES:
                    summary.push({title:intl.formatMessage({id:'page_operate_mobilize_units_title'}), value:formatAmount(parseFloat(formData.amount.replace(',', '.')))});
                    break;
                case process.env.REACT_APP_TRASLADO_PARCIAL_PORCENTAJE:
                    summary.push({title:intl.formatMessage({id:'page_operate_mobilize_percentage_title'}), value:formatAmount(parseFloat(formData.amount.replace(',', '.')))+'%'});
                    break;
            }
        }

        switch(formData.originType){
            case process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO:
                summary.push({title:intl.formatMessage({id:'page_operate_mobilize_origin_manager_title'}), value: planManagersOptions.find(item=>item.id===formData.cdExternalPromoter)?.nombre ?? ''});
                summary.push({title:intl.formatMessage({id:'page_operate_mobilize_source_plan_title'}),  value: externalPlansOptions.find(item=>item.id===formData.cdExternalPlan)?.nombre ?? ''});
                break;
            case process.env.REACT_APP_TRASLADO_ORIGEN_FONDITEL:
                summary.push({title:intl.formatMessage({id:'page_operate_mobilize_source_plan_fonditel_title'}), value: contractsUser.find(item=>item.id===formData.cdFonditelContract)?.plan?.descripcion ?? ''});
                break;
        }

        return summary;
    };


    const isSubmitDisabled = () => {
        return (formData.transferTypeParent!==process.env.REACT_APP_TRASLADO_TOTAL &&
                (formData.transferType.length===0 || formData.amount.length===0 || handleError('amount')!==null)) ||

                (formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO &&
                (formData.cdExternalPromoter.length===0 || formData.cdExternalPlan.length===0)) ||

                (formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_FONDITEL &&
                    (formData.cdFonditelContract.length===0));
    };

    return (
        <>{prop.loading ?
            <></>
        :
            <>
                <LoadingBar  visible={loading || loadingForm} />
                <Form onSubmit={handleSubmit} initialValues={initFormData} className={loadingForm ? 'loading' : ''}>
                    <ResponsiveLayout className="inner">
                        <ButtonFixedFooterLayout
                            secondaryButton={
                                <ButtonLayout><ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_operate_mobilize_continue'})}</ButtonPrimary></ButtonLayout>
                            }
                            button={screenSize.isDesktopOrBigger ? <ButtonLayout><ButtonSecondary onPress={() => {prop.onCancel ? prop.onCancel() : router.goBack()}}>{intl.formatMessage({id:'page_operate_mobilize_back_to_operational'})}</ButtonSecondary></ButtonLayout> : undefined}
                        >

                            <Box paddingBottom={screenSize.isDesktopOrBigger ? 56 : 16}>
                                <Stack space={32}>
                                    <Stack space={16}>
                                        <Text5 color={skinVars.colors.brand}>{intl.formatMessage({id:'page_operate_mobilize_plan_title'})}</Text5>

                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_mobilize_plan_description'})}</Text2>
                                    </Stack>

                                    <Stack space={16}>
                                        <Text4 medium>{intl.formatMessage({id:'page_operate_mobilize_title_type_transfer'})}</Text4>

                                        <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                            <Stack space={screenSize.isDesktopOrBigger ? 24 : 16}>
                                                <RadioGroup name="transferTypeParent" onChange={(val:any)=> handleChangeAnyField('transferTypeParent', val)}>
                                                    <Grid columns={2} gap={screenSize.isDesktopOrBigger ? 24 : 16}>
                                                        <RadioButton
                                                            value={process.env.REACT_APP_TRASLADO_TOTAL??'0'}
                                                            render={({ controlElement, checked, labelId }:any) => (
                                                                <Boxed className={'rbx-option '+ (checked ? 'active' : '')}>
                                                                    <Box padding={16}>
                                                                        <Inline space={8} alignItems='center'>
                                                                            {controlElement}
                                                                            <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_mobilize_full_transfer'})}</Text2>
                                                                        </Inline>
                                                                    </Box>
                                                                </Boxed>
                                                            )}
                                                        />

                                                        <RadioButton
                                                            value={process.env.REACT_APP_TRASLADO_PARCIAL??'-1'}
                                                            render={({ controlElement, checked, labelId }:any) => (
                                                                <Boxed className={'rbx-option '+ (checked ? 'active' : '')}>
                                                                    <Box padding={16}>
                                                                        <Inline space={8} alignItems='center'>
                                                                            {controlElement}
                                                                            <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_mobilize_partial_transfer'})}</Text2>
                                                                        </Inline>
                                                                    </Box>
                                                                </Boxed>
                                                            )}
                                                        />
                                                    </Grid>
                                                </RadioGroup>


                                            </Stack>
                                        </Grid>

                                        {/* Rbx Traslado parcial */}
                                        {formData.transferTypeParent===(process.env.REACT_APP_TRASLADO_PARCIAL??'-1') &&
                                            <Stack space={16}>
                                                <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                                    <Stack space={16}>
                                                        <Select
                                                            fullWidth
                                                            label={intl.formatMessage({id:'page_operate_mobilize_type_of_partial_transfer'})}
                                                            name="transferType"
                                                            value={formData.transferType}
                                                            error={handleError('transferType')!==null} helperText={handleError('transferType')??undefined}
                                                            onChangeValue={(val:any)=> handleChangeAnyField('transferType', val)}
                                                            options={[
                                                                { value: (process.env.REACT_APP_TRASLADO_PARCIAL_IMPORTE??'1'), text: intl.formatMessage({id:'page_operate_mobilize_type_of_partial_transfer_amount'}) },
                                                                { value: (process.env.REACT_APP_TRASLADO_PARCIAL_PORCENTAJE??'3'), text: intl.formatMessage({id:'page_operate_mobilize_type_of_partial_transfer_percentage'})  },
                                                                { value: (process.env.REACT_APP_TRASLADO_PARCIAL_UNIDADES??'2'), text: intl.formatMessage({id:'page_operate_mobilize_type_of_partial_transfer_units'})  }
                                                            ]}
                                                        />


                                                        {formData.transferType===(process.env.REACT_APP_TRASLADO_PARCIAL_IMPORTE) &&

                                                            <DecimalField maxDecimals={2} fullWidth error={handleError('amount')!==null} helperText={handleError('amount')??undefined} name="amount" label={'Importe'} onChangeValue={(val:any)=> handleChangeAnyField('amount', val)}  />
                                                        }


                                                        {formData.transferType===(process.env.REACT_APP_TRASLADO_PARCIAL_PORCENTAJE) &&

                                                            <DecimalField maxDecimals={2} fullWidth error={handleError('amount')!==null} helperText={handleError('amount')??undefined} name="amount" label={'Porcentaje'} onChangeValue={(val:any)=> handleChangeAnyField('amount', val)}  />
                                                        }

                                                        {formData.transferType===(process.env.REACT_APP_TRASLADO_PARCIAL_UNIDADES) &&

                                                            <DecimalField maxDecimals={5} fullWidth error={handleError('amount')!==null} helperText={handleError('amount')??undefined} name="amount" label={'Unidades'} onChangeValue={(val:any)=> handleChangeAnyField('amount', val)}  />
                                                        }


                                                    </Stack>
                                                </Grid>

                                                <Stack space={24}>
                                                    <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                                        <Text3 medium>{intl.formatMessage({id:'page_operate_mobilize_type_of_partial_transfer_description'})}</Text3>

                                                        {!screenSize.isDesktopOrBigger &&
                                                            <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_mobilize_type_of_partial_transfer_description2'})}</Text1>
                                                        }
                                                    </Grid>

                                                    <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                                        <RadioGroup name="partialsTransfers" value={formData.partialsTransfers} onChange={(val:any)=> handleChangeAnyField('partialsTransfers', val)}>
                                                            <Stack space={16}>
                                                                <RadioButton
                                                                    value={process.env.REACT_APP_TRASLADO_PARCIALES_ANTERIOR??'A'}
                                                                    render={({ controlElement, checked, labelId }:any) => (
                                                                        <Inline space={8} alignItems='center'>
                                                                            {controlElement}
                                                                            <Text2 regular color={checked ? skinVars.colors.brand : 'currentColor'}>{intl.formatMessage({id:'page_operate_mobilize_type_of_partial_transfer_previus'})}</Text2>
                                                                        </Inline>
                                                                    )}
                                                                />

                                                                <RadioButton
                                                                    value={process.env.REACT_APP_TRASLADO_PARCIALES_POSTERIOR??'C'}
                                                                    render={({ controlElement, checked, labelId }:any) => (
                                                                        <Inline space={8} alignItems='center'>
                                                                            {controlElement}
                                                                            <Text2 regular color={checked ? skinVars.colors.brand : 'currentColor'}>{intl.formatMessage({id:'page_operate_mobilize_type_of_partial_transfer_post'})}</Text2>
                                                                        </Inline>
                                                                    )}
                                                                />
                                                            </Stack>
                                                        </RadioGroup>

                                                        {screenSize.isDesktopOrBigger &&
                                                            <Box paddingX={8}>
                                                                <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_mobilize_type_of_partial_transfer_description3'})}</Text1>
                                                            </Box>
                                                        }
                                                    </Grid>
                                                </Stack>
                                            </Stack>
                                        }
                                    </Stack>

                                    <Stack space={16}>
                                        <Text4 medium>{intl.formatMessage({id:'page_operate_mobilize_origin_plan'})}</Text4>

                                        <RadioGroup name="originType" value={formData.originType} onChange={(val:any)=> handleChangeAnyField('originType', val)}>
                                            <Inline space={16}>
                                                <RadioButton
                                                    value={process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO??'E'}
                                                    render={({ controlElement, checked, labelId }:any) => (
                                                        <Inline space={8} alignItems='center'>
                                                            {controlElement}
                                                            <Text2 regular color={checked ? skinVars.colors.brand : 'currentColor'}>{intl.formatMessage({id:'page_operate_mobilize_origin_plan_another_entity'})}</Text2>
                                                        </Inline>
                                                    )}
                                                />

                                                {contractsUser && contractsUser.length>0 &&
                                                    <RadioButton
                                                        value={process.env.REACT_APP_TRASLADO_ORIGEN_FONDITEL??'F'}
                                                        render={({ controlElement, checked, labelId }:any) => (
                                                            <Inline space={8} alignItems='center'>
                                                                {controlElement}
                                                                <Text2 regular color={checked ? skinVars.colors.brand : 'currentColor'}>{intl.formatMessage({id:'page_operate_mobilize_origin_plan_fonditel'})}</Text2>
                                                            </Inline>
                                                        )}
                                                    />
                                                }
                                            </Inline>
                                        </RadioGroup>

                                        {/* Rbx Otra entidad */}
                                        {formData.originType===(process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO??'E') &&
                                            <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                                <Stack space={16}>
                                                    <Stack space={4}>
                                                        <Select
                                                            fullWidth
                                                            label={intl.formatMessage({id:'page_operate_mobilize_origin_plan_another_entity_manager'})}
                                                            name="cdExternalPromoter"
                                                            value={formData.cdExternalPromoter}
                                                            error={handleError('cdExternalPromoter')!==null} helperText={handleError('cdExternalPromoter')??undefined}
                                                            onChangeValue={(val:any)=> handleChangeAnyField('cdExternalPromoter', val)}
                                                            options={planManagersOptions.map(item => ({text:item.nombre, value:item.id}))}
                                                        />

                                                        <>
                                                            {!screenSize.isDesktopOrBigger &&
                                                                <Box paddingX={8}>
                                                                    <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_mobilize_origin_plan_another_entity_description'})}</Text1>
                                                                </Box>
                                                            }
                                                        </>
                                                    </Stack>

                                                    <Select
                                                        fullWidth
                                                        label={intl.formatMessage({id:'page_operate_mobilize_origin_plan_another_entity_origin_plan'})}
                                                        name="cdExternalPlan"
                                                        value={formData.cdExternalPlan}
                                                        disabled={!externalPlansOptions || externalPlansOptions.length===0}
                                                        error={handleError('cdExternalPlan')!==null} helperText={handleError('cdExternalPlan')??undefined}
                                                        onChangeValue={(val:any)=> handleChangeAnyField('cdExternalPlan', val)}
                                                        options={externalPlansOptions.map(item => ({text:item.nombre, value:item.id}))}
                                                    />
                                                </Stack>

                                                <>
                                                    {screenSize.isDesktopOrBigger &&
                                                        <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_mobilize_origin_plan_another_entity_description2'})}</Text1>
                                                    }
                                                </>
                                            </Grid>
                                        }

                                        {/* Rbx Plan de pensiones */}
                                        {formData.originType===(process.env.REACT_APP_TRASLADO_ORIGEN_FONDITEL??'F') && contractsUser.length>0 &&
                                            <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                                <Stack space={16}>
                                                    <Select
                                                        fullWidth
                                                        label={intl.formatMessage({id:'page_operate_mobilize_origin_plan_another_entity_pension_plan'})}
                                                        name="cdFonditelContract"
                                                        value={formData.cdFonditelContract}
                                                        error={handleError('cdFonditelContract')!==null} helperText={handleError('cdFonditelContract')??undefined}
                                                        onChangeValue={(val:any)=> handleChangeAnyField('cdFonditelContract', val)}
                                                        options={contractsUser.map(item => ({text:item.plan?.descripcion ?? '', value:item.id??''}))}
                                                    />
                                                </Stack>
                                            </Grid>
                                        }
                                    </Stack>
                                </Stack>
                            </Box>

                        </ButtonFixedFooterLayout>
                    </ResponsiveLayout>
                </Form>
            </>
        }</>
    );
};

export default MobilizePensionPlan;