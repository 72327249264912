import { useIonRouter } from "@ionic/react";
import { useIntl } from "react-intl";
import { Product } from "../../entities/products/product";
import { UserProduct } from "../../entities/products/userProduct";
import LoginPage from "../../pages/private/login/LoginPage";
import ContractInvestmentFundsCustomerPage from "../../pages/private/users/customer/investment-funds/ContractInvestmentFundsCustomerPage";
import InfoInvestmentFundsCustomerPage from "../../pages/private/users/customer/investment-funds/InfoInvestmentFundsCustomerPage";
import OperateInvestmentFundsCustomerPage from "../../pages/private/users/customer/investment-funds/OperateInvestmentFundsCustomerPage";
import OperateOtherProductsCustomerPage from "../../pages/private/users/customer/other-products/OperateOtherProductsCustomerPage";
import { ContractEmploymentPlansCustomerPage, ContractIndividualPlansCustomerPage, ContractSimplifiedEmploymentPlansCustomerPage } from "../../pages/private/users/customer/pension-plans/ContractPensionPlansCustomerPage";
import { InfoEmploymentPlansCustomerPage, InfoIndividualPlansCustomerPage, InfoSimplifiedEmploymentPlansCustomerPage } from "../../pages/private/users/customer/pension-plans/InfoPensionPlanCustomerPage";
import { OperateEmploymentPlansCustomerPage, OperateIndividualPlansCustomerPage, OperateSimplifiedEmploymentPlansCustomerPage } from "../../pages/private/users/customer/pension-plans/OperatePensionPlanCustomerPage";
import InvestmentFundsPage from "../../pages/public/investment-funds/InvestmentFundsPage";
import PensionPlansPage from "../../pages/public/pension-plans/PensionPlansPage";
import { getUrlNavigation } from "../../utils/navigation";
import { getContinueToContract, getCurrentProductToContract, getUser, removeContinueToContract, removeCurrentUserContract, removeCurrentUserData, removeCurrentUserProduct, setCurrentProduct, setCurrentProductToContract, setCurrentUserProduct, setProductToContract } from "../../utils/storage";
import { useAlert } from "../alerts/Alert";
import { useValidOperate } from "./ValidOperate";
import { vibration } from "../../utils/utils";



export const useProductActions = () => {

    const intl = useIntl();
    const { showAlert } = useAlert()
    const router = useIonRouter();
    const { validOperate } = useValidOperate()

    const operate  = (userProduct:UserProduct): Promise<void> =>

        new Promise((resolve) => {

        vibration();

        if(userProduct.product){
            setCurrentUserProduct(userProduct);
            switch(userProduct.product.cdCompania){
                case parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES??'1'):
                    switch(userProduct.product.individual){
                        case process.env.REACT_APP_PLAN_PENSIONES_EMPRESA:
                            router.push(getUrlNavigation(OperateEmploymentPlansCustomerPage));
                            break;
                        case process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL:
                            router.push(getUrlNavigation(OperateIndividualPlansCustomerPage));
                            break;
                        case process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS:
                            router.push(getUrlNavigation(OperateSimplifiedEmploymentPlansCustomerPage));
                            break;
                    }
                    break;

                case parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS??'3'):
                    router.push(getUrlNavigation(OperateInvestmentFundsCustomerPage));
                    break;

                case parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_OTROSPRODUCTOS??'5'):
                    router.push(getUrlNavigation(OperateOtherProductsCustomerPage));
                    break;
            }
        }
        resolve();
    });

    const moreInfo = (product:Product, publicArea:boolean=false): Promise<void> =>
        new Promise((resolve) => {
            vibration();
            if(!publicArea){
                setCurrentProduct(product);
            }
            switch(product.cdCompania){
                case parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES??'1'):
                    switch(product.individual){
                        case process.env.REACT_APP_PLAN_PENSIONES_EMPRESA:
                            router.push(getUrlNavigation(InfoEmploymentPlansCustomerPage));
                            break;
                        case process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL:
                            if(publicArea && product.slug){
                                router.push(getUrlNavigation(PensionPlansPage)+'/'+product.slug);
                            }else{
                                router.push(getUrlNavigation(InfoIndividualPlansCustomerPage));
                            }
                            break;
                        case process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS:
                            router.push(getUrlNavigation(InfoSimplifiedEmploymentPlansCustomerPage));
                            break;
                    }
                break;
                case parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS??'3'):
                    if(publicArea && product.slug){
                        router.push(getUrlNavigation(InvestmentFundsPage)+'/'+product.slug);
                    }else{
                        router.push(getUrlNavigation(InfoInvestmentFundsCustomerPage));
                    }
                    break;
            }
            resolve();
        });


  const contract = (product:Product, onError?:() => void): Promise<void> =>
    new Promise((resolve) => {
        vibration();
        const productInfo: Product = {
            cdCompania: product.cdCompania,
            cdPlan: product.cdPlan,
            cdFondo: product.cdFondo,
            cdSubplan: product.cdSubplan,
            individual: product.individual
        };
        const userProduct : UserProduct={
            product:productInfo
        };


        if(getUser()){

            setCurrentUserProduct(userProduct);
            validOperate(getContinueToContract() ? parseInt(process.env.REACT_APP_MENU_NUEVO_CONTRATO_TRAS_REGISTRO ?? '99') : parseInt(process.env.REACT_APP_MENU_NUEVO_CONTRATO ?? '4'),
            //onConfirm
            ()=>{
                const productDetail = getCurrentProductToContract();
                if(productDetail){
                    switch(productDetail.cdCompania){
                        case parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES??'1'):
                            switch(productDetail.individual){
                                case process.env.REACT_APP_PLAN_PENSIONES_EMPRESA:
                                    router.push(getUrlNavigation(ContractEmploymentPlansCustomerPage));
                                    break;
                                case process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL:
                                    router.push(getUrlNavigation(ContractIndividualPlansCustomerPage));
                                    break;
                                case process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS:
                                    router.push(getUrlNavigation(ContractSimplifiedEmploymentPlansCustomerPage));
                                    break;
                            }
                            break;
                        case parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS??'3'):
                            router.push(getUrlNavigation(ContractInvestmentFundsCustomerPage));
                            break;
                        }
                }
            },

            //onError
            ()=>{
                removeContinueToContract();
                removeCurrentUserProduct();
                removeCurrentUserData();
                removeCurrentUserContract();
                if(onError){
                    onError();
                }
            }).finally(()=>{
                resolve()
            });
        }else{
            removeContinueToContract();
            setProductToContract(productInfo);
            router.push(getUrlNavigation(LoginPage));
            resolve()
        }
    });

	return { operate, moreInfo, contract };
};