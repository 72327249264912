import { useIonRouter } from '@ionic/react';
import { useIntl } from 'react-intl';

import ContactPage from '../contact/ContactPage';
import HomePage from '../home/HomePage';
import NewsPage from '../news/NewsPage';

import { getUrlNavigation } from '../../../utils/navigation';

import { Box, ButtonLink, DisplayDataCard, GridLayout, ResponsiveLayout, Stack, Text3, Text8, skinVars } from '@telefonica/mistica';

import LayoutPublic from '../../../components/layout/LayoutPublic';
import { isBot, trackError } from '../../../utils/utils';
import { useEffect } from 'react';

const Error404Page: React.FC = () => {

  const intl = useIntl()
  const router = useIonRouter();

  useEffect(()=>{
    if(router.routeInfo.lastPathname){
      trackError('Error 404 page: '+(router.routeInfo.lastPathname));
    }
  }, []);

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_404_seo_title'}), metaDescription:intl.formatMessage({id:'page_404_seo_description'})}}
      header={{small:true}}
      hideFooter={true}
    >
      <ResponsiveLayout className='full-height header-small' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
        <ResponsiveLayout className='content' backgroundColor={"transparent"}>
          <Box paddingY={80}>
            <Stack space={64}>
              <Text8><div  dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_404_title'})}}></div></Text8>
              <GridLayout template='6+6'
                left={
                  <Text3 regular><div dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_404_description'})}}></div></Text3>
                }
                right={
                  <></>
                }
              />
              <GridLayout template='8+4' verticalSpace={24}
                left={
                  <GridLayout template='6+6'
                    left={
                      <DisplayDataCard
                        title={intl.formatMessage({id:'page_404_card_inicio_title'})}
                        description={intl.formatMessage({id:'page_404_card_inicio_description'})}
                        buttonLink={isBot ? <ButtonLink href={getUrlNavigation(HomePage)} withChevron={false}>{intl.formatMessage({id:'page_404_card_inicio_button'})}</ButtonLink> : <ButtonLink onPress={()=>{router.push(getUrlNavigation(HomePage))}}>{intl.formatMessage({id:'page_404_card_inicio_button'})}</ButtonLink>}
                      />
                    }
                    right={
                      <DisplayDataCard
                        title={intl.formatMessage({id:'page_404_card_noticias_title'})}
                        description={intl.formatMessage({id:'page_404_card_noticias_description'})}
                        buttonLink={isBot ? <ButtonLink href={getUrlNavigation(NewsPage)} withChevron={false}>{intl.formatMessage({id:'page_404_card_noticias_button'})}</ButtonLink> : <ButtonLink onPress={()=>{router.push(getUrlNavigation(NewsPage))}}>{intl.formatMessage({id:'page_404_card_noticias_button'})}</ButtonLink>}
                      />
                    }
                  />
                }
                right={
                  <DisplayDataCard
                    title={intl.formatMessage({id:'page_404_card_contacto_title'})}
                    description={intl.formatMessage({id:'page_404_card_contacto_description'})}
                    buttonLink={isBot ? <ButtonLink href={getUrlNavigation(ContactPage)} withChevron={false}>{intl.formatMessage({id:'page_404_card_contacto_button'})}</ButtonLink> : <ButtonLink onPress={()=>{router.push(getUrlNavigation(ContactPage))}}>{intl.formatMessage({id:'page_404_card_contacto_button'})}</ButtonLink>}
                  />
                }
              />
            </Stack>
          </Box>
        </ResponsiveLayout>
      </ResponsiveLayout>
  </LayoutPublic>
  );
};

export default Error404Page;
