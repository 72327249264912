import React, { useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';

import CardNewContainer from '../CardNew';

import { News } from '../../../entities/news/news.model';
import { getNewsMostRead } from '../../../utils/apiclient';

import { Box, GridLayout, SkeletonRectangle, Stack, Text3 } from '@telefonica/mistica';

import { NewsParameters } from '../../../entities/news/news-parameters.model';
import './MostRead.scss';

interface MostRead {
    side?: boolean,
    news?: News[],
}

const MostReadContainer: React.FC<MostRead> = (prop) => {

const intl = useIntl();

const [news, setNews] = React.useState([] as News[])
const initNewParameters: NewsParameters = new NewsParameters();

useLayoutEffect(()=>{
  if(prop.news){
    setNews(prop.news);
  }else{
    getNewsMostRead(initNewParameters).then(news=>{
      if(news)
        setNews(news);
    });
  }
}, [, prop]);

  return (
    <>
        <Text3 medium>{intl.formatMessage({id:'page_news_most_read'})}</Text3>

        <Box paddingY={32}>
          {prop.side ?
            (

                news.length === 0 ? (
                  <Stack space={16}>
                    <SkeletonRectangle width="100%" height={100}/>
                    <SkeletonRectangle width="100%" height={100}/>
                    <SkeletonRectangle width="100%" height={100}/>
                  </Stack>
                ) : (
                  <Stack space={16}>
                    <CardNewContainer news = {news[0]} type='Highlighted'/>
                    <CardNewContainer news = {news[1]} type='Highlighted'/>
                    <CardNewContainer news = {news[2]} type='Highlighted'/>
                  </Stack>
                )

            ) : (
              news.length === 0 ? (
                <GridLayout template="6+6" verticalSpace={32}
                  left={
                    <SkeletonRectangle width="100%" height={300}/>
                  }
                  right={
                    <SkeletonRectangle width="100%" height={300}/>
                  }
                />
              ) : (
                <GridLayout template="6+6" verticalSpace={32}
                  left={
                    <CardNewContainer prefixSlug='noticias-masLeido-' news = {news[0]} type='Media'/>
                  }
                  right={
                    <CardNewContainer prefixSlug='noticias-masLeido-' news = {news[1]} type='Media'/>
                  }
                />
              )
            )
          }
        </Box>
    </>
  );
};

export default MostReadContainer;