import { Box, Boxed, DoubleField, Stack, Text2, Text3, Text4, skinVars, useScreenSize } from '@telefonica/mistica';
import React from 'react';

import { useIntl } from 'react-intl';
import './ProductCard.scss';

interface ProductCardProps {
    title: string,
    category?: string,
    ownership?: string,
    balance?: string,
    rights?: string,
    date_balance?: string,
    net_asset_value?: string,
    annual_profitability?: string,
    historical_profitability?: string,
    button?: React.ReactNode
}

const ProductCard: React.FC<ProductCardProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();


return (<>
            <style>{'.item-product{height:100%} .item-product .product-title{background-color:'+skinVars.colors.backgroundAlternative+'}'}</style>
            <Boxed minHeight={screenSize.isDesktopOrBigger ? 324 : 294} className='item-product'>
                <Box  padding={24} className='product-title'>
                    <Text4 color={skinVars.colors.textButtonPrimaryInverse} medium>{prop.title}</Text4>
                </Box>
                <Box padding={24}>
                    <Stack space={16}>
                        {prop.category &&
                            <Text3 color={skinVars.colors.textButtonPrimaryInverse} regular>{prop.category}</Text3>
                        }
                        <Stack space={16}>
                        {prop.ownership && (prop.balance || prop.rights) &&
                            <DoubleField layout="50/50">
                                <Stack space={4}>
                                    <Text2 regular>{intl.formatMessage({id:'page_customer_global_position_product_card_ownership'})}</Text2>
                                    <Text4 medium>{prop.ownership}</Text4>
                                </Stack>
                                <>
                                    {prop.balance &&
                                        <Stack space={4}>
                                            <Text2 regular>{intl.formatMessage({id:'page_customer_global_position_product_card_balance'})}</Text2>
                                            <Text4 medium>{prop.balance}€</Text4>
                                        </Stack>
                                    }
                                    {prop.rights &&
                                        <Stack space={4}>
                                            <Text2 regular>{intl.formatMessage({id:'page_customer_global_position_product_card_rights'})}</Text2>
                                            <Text4 medium>{prop.rights}€</Text4>
                                        </Stack>
                                    }
                                </>
                            </DoubleField>
                        }

                        {prop.annual_profitability && prop.date_balance  &&
                            <DoubleField layout="50/50">
                                <Stack space={4}>
                                    <Text2 regular>{intl.formatMessage({id:'page_customer_global_position_product_card_annual_profitability'})}</Text2>
                                    <Text4 medium>{prop.annual_profitability}%</Text4>
                                </Stack>
                                <Stack space={4}>
                                    <Text2 regular>{intl.formatMessage({id:'page_customer_global_position_product_card_date_balance'})}</Text2>
                                    <Text4 medium>{prop.date_balance}</Text4>
                                </Stack>
                            </DoubleField>
                        }

                        {prop.net_asset_value &&
                            <DoubleField layout="50/50">
                                <Stack space={4}>
                                    <Text2 regular>{intl.formatMessage({id:'page_customer_global_position_product_card_net_asset_value'})}</Text2>
                                    <Text4 medium>{prop.net_asset_value}</Text4>
                                </Stack>
                                <></>
                            </DoubleField>
                        }

                        {prop.annual_profitability && prop.historical_profitability  &&
                            <DoubleField layout="50/50">
                                <Stack space={4}>
                                    <Text2 regular>{intl.formatMessage({id:'page_customer_global_position_product_card_annual_profitability'})}</Text2>
                                    <Text4 medium>{prop.annual_profitability}%</Text4>
                                </Stack>
                                <Stack space={4}>
                                    <Text2 regular>{intl.formatMessage({id:'page_customer_global_position_product_card_historical_profitability'})}</Text2>
                                    <Text4 medium>{prop.historical_profitability}</Text4>
                                </Stack>
                            </DoubleField>
                        }
                        </Stack>
                        {prop.button}
                    </Stack>
                </Box>
            </Boxed>
        </>);
};

export default ProductCard;
