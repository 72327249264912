import { Box, Boxed, ButtonLayout, ButtonPrimary, DateField, Divider, DoubleField, Grid, GridLayout, Inline, Placeholder, SkeletonRectangle, Stack, Text, Text1, Text2, Text3, Text4, skinVars, useScreenSize, ButtonLink, Row, SkeletonLine, Form } from '@telefonica/mistica';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Contract } from '../../entities/contract/contract';
import { Contribution } from '../../entities/contract/contribution';
import { getContributionCapital } from '../../utils/apiclient';
import { formatBalance } from '../../utils/utils';

// import './ContributedCapital.scss';

interface ContributedCapitalProps {
    contract: Contract | null
    loading?: boolean
}

const ContributedCapital: React.FC<ContributedCapitalProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState(null as null|Contract);
    const [dateFrom, setDateFrom]= useState(moment().subtract('4', 'years').format("YYYY-MM-DD").toString());
    const [dateTo, setDateTo]= useState(moment().format("YYYY-MM-DD").toString());
    const [contributionsInit, setContributionsInit]= useState([] as Array<Contribution>);
    const [contributions, setContributions]= useState([] as Array<Contribution>);
    const [textContributionDates, setTextContributionDates]= useState('');

    useEffect(()=>{
        if(prop.contract && prop.contract.aportaciones){
            setContract(prop.contract);
            setContributions(Object.values(prop.contract.aportaciones));
            setContributionsInit(Object.values(prop.contract.aportaciones));
            setDateFrom(moment().subtract('4', 'years').startOf('year').format("YYYY-MM-DD").toString());
            setDateTo(moment().format("YYYY-MM-DD").toString());
        }
    },[prop.contract]);

    const handleAllData = (): Promise<void> =>
        new Promise((resolve) => {
            setContributions(contributionsInit);
            setDateFrom(moment().subtract('4', 'years').startOf('year').format("YYYY-MM-DD").toString());
            setDateTo(moment().format("YYYY-MM-DD").toString());
            resolve();
        });

    const handleSubmit  = (): Promise<void> =>
        new Promise((resolve) => {
            setLoading(true);
            if(contract && contract.plan && contract.plan.cdCompania && contract.plan.cdFondo && contract.plan.cdPlan && contract.plan.cdSubplan && contract?.numContrato){
                getContributionCapital({cdCompany:contract.plan.cdCompania, cdFund:contract.plan.cdFondo, cdPlan:contract.plan.cdPlan, cdSubplan:contract.plan.cdSubplan, numContract:contract.numContrato, dateFrom: dateFrom??'', dateTo: dateTo??''}).then((result)=>{
                    setTextContributionDates(intl.formatMessage({id:'page_contract_data_contributed_capital_contributions_dates'},{dateFrom:moment(dateFrom).format('DD/MM/YYYY'), dateTo: moment(dateTo).format('DD/MM/YYYY')}));
                    setContributions(Object.values(result));
                }).finally(()=>{
                    setLoading(false);
                    resolve();
                });
            }
        });

    return (<>{prop.loading ?
                <Box paddingY={screenSize.isDesktopOrBigger ? 32 : 16} className={'movements--content '}>
                    <Stack space={screenSize.isDesktopOrBigger ? 40 : 12}>
                        <Stack space={16}>
                            {screenSize.isDesktopOrBigger &&
                                <SkeletonLine />
                            }

                            <Box className='blockFilters'>
                                <Box className='filterGroup'>
                                    <Box className='group--date'>
                                        <DoubleField layout='50/50' fullWidth>
                                            <SkeletonRectangle height={60} />

                                            <SkeletonRectangle height={60} />
                                        </DoubleField>
                                    </Box>
                                    <Box className='group--button'>
                                        <ButtonLayout>
                                            <SkeletonRectangle height={60} />
                                        </ButtonLayout>
                                    </Box>
                                </Box>

                                <Box className='filterGroup filterGroup--options'>
                                    <SkeletonRectangle height={31} />
                                    <SkeletonRectangle height={31} />
                                </Box>
                            </Box>
                        </Stack>
                        {screenSize.isDesktopOrBigger ?

                            <Stack space={8}>
                                <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                                <Divider />
                                <SkeletonRectangle ariaLabel="loading" height={70}></SkeletonRectangle>
                                <Divider />
                                <SkeletonRectangle ariaLabel="loading" height={70}></SkeletonRectangle>
                                <Divider />
                                <SkeletonRectangle ariaLabel="loading" height={70}></SkeletonRectangle>
                                <Divider />
                                <SkeletonRectangle ariaLabel="loading" height={70}></SkeletonRectangle>
                                <Divider />
                            </Stack>
                        :
                            <Stack space={12}>
                                <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                                <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                            </Stack>
                        }
                    </Stack>
                </Box>
            :
        <>
            <Box paddingY={screenSize.isDesktopOrBigger ? 32 : 16} className='contributedCapital--content'>
                <Stack space={screenSize.isDesktopOrBigger ? 40 : 4}>
                    <Form onSubmit={handleSubmit}>
                        <Stack space={16}>
                            {screenSize.isDesktopOrBigger &&
                                <Box>
                                    <Text4 medium>{intl.formatMessage({id:'page_contract_data_contributed_capital_aported'})}</Text4>
                                </Box>
                            }

                            <GridLayout
                                template="6+6"
                                left={
                                    <DoubleField layout='50/50' fullWidth>
                                        <DateField  value={dateFrom} name="dateFrom" onChangeValue={(value) => {setDateFrom(value)}} label={intl.formatMessage({id:'page_contract_data_movements_from'})} />

                                        <DateField  value={dateTo} name="dateTo" onChangeValue={(value) => {setDateTo(value)}} label={intl.formatMessage({id:'page_contract_data_movements_until'})} />
                                    </DoubleField>
                                }
                                right={
                                    <Box paddingY={8}>
                                        <ButtonLayout>
                                            <ButtonPrimary submit>{intl.formatMessage({id:'page_contract_data_contributed_calculated'})}</ButtonPrimary>
                                        </ButtonLayout>
                                    </Box>
                                }
                            />
                        </Stack>
                    </Form>

                    {screenSize.isDesktopOrBigger ?

                        <Stack space={8} className={'table--contributedCapital loadingBox '+(loading ? 'loading' : '')}>
                            <Inline space={'between'}>
                                <Box className='col-1' paddingLeft={16}>
                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_contributed_period'})}</Text>
                                </Box>
                                <Box className='col-2' paddingX={16}>
                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_contributed_participant_contributions'})}</Text>
                                </Box>
                                <Box className='col-3' paddingRight={16}>
                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_contributed_promotor_contributions'})}</Text>
                                </Box>
                            </Inline>
                            <Divider/>
                            <Box paddingY={12}>
                                <Stack space={24}>
                                    {contributions && contributions.map((contribution, index) => (
                                        <Stack key={'table-contributions-'+index} space={24}>
                                            <Inline space='between' alignItems='center' className='row'>
                                                <Box className='col-1' paddingLeft={16}>
                                                    <Text2 regular>{contributions.length===1 ? textContributionDates : contribution.anio}</Text2>
                                                </Box>
                                                <Box className='col-2' paddingX={16}>
                                                    <Text2 regular>{formatBalance(contribution.AportacionParticipe ?? '')}€</Text2>
                                                </Box>
                                                <Box className='col-3' paddingRight={16}>
                                                    <Text2 regular>{formatBalance(contribution.AportacionPromotor ?? '')}€</Text2>
                                                </Box>
                                            </Inline>
                                            <Divider/>
                                        </Stack>
                                    ))}
                                </Stack>
                            </Box>
                            {contributions && contributions.length===1 &&
                                <ButtonLayout align='right'>
                                    <ButtonLink onPress={handleAllData}>{intl.formatMessage({id:'page_contract_data_contributed_handle_all_data'})}</ButtonLink>
                                </ButtonLayout>
                            }
                        </Stack>
                    :
                        <>
                            <Stack space={12} className={'loadingBox '+(loading ? 'loading' : '')}>

                                {contributions && contributions.map((contribution, index) => (
                                    <Boxed key={'boxed-contributions-'+index}>
                                        <Box paddingX={16} paddingY={24}>
                                            <Stack space={12}>
                                                <Text4 medium>{contributions.length===1 ? textContributionDates : contribution.anio}</Text4>

                                                <Grid columns={2} gap={16}>
                                                    <Stack space={2}>
                                                        <Text1 regular>{intl.formatMessage({id:'page_contract_data_contributed_participant_contributions2'})}</Text1>
                                                        <Text3 medium>{formatBalance(contribution.AportacionParticipe ?? '')}€</Text3>
                                                    </Stack>

                                                    <Stack space={2}>
                                                        <Text1 regular>{intl.formatMessage({id:'page_contract_data_contributed_promotor_contributions2'})}</Text1>
                                                        <Text3 medium>{formatBalance(contribution.AportacionPromotor ?? '')}€</Text3>
                                                    </Stack>
                                                </Grid>
                                            </Stack>
                                        </Box>
                                    </Boxed>
                                ))}
                            </Stack>
                            {contributions && contributions.length===1 &&
                                <ButtonLayout withMargins>
                                    <ButtonLink onPress={handleAllData}>{intl.formatMessage({id:'page_contract_data_contributed_handle_all_data'})}</ButtonLink>
                                </ButtonLayout>
                            }
                        </>
                    }
                </Stack>
            </Box>
        </>
    }</>);
};

export default ContributedCapital;