import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, ButtonPrimary, Checkbox, DataCard, EmailField, Form, GridLayout, IconAlertLight, IconInformationUserLight, Stack, TextField, dialog, skinVars } from '@telefonica/mistica';

import { NewsletterSubscription } from '../../../entities/news/newsletter-subscription';
import { addNewsletterSubscription } from '../../../utils/apiclient';
import './Newsletter.scss';
import { trackEvent } from '../../../utils/utils';

interface Newsletter {
    side?: boolean,
}

const NewsletterContainer: React.FC<Newsletter> = (prop) => {

const intl = useIntl();

const initNewsletter = {name: '', email: '', checkConditions: false} as NewsletterSubscription;
const [formNewsletter, setFormNewsletter] = useState(initNewsletter);

const [checkConditions, setCheckConditions] = useState(false);

const handleChangeField= (evt:any) => {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
      ...formNewsletter,
      [name]: value,
    };
    setFormNewsletter(newValues);
  };

  const handleSubmit = (data: any): Promise<void> =>
  new Promise((resolve) => {
    if(data){
        if(prop.side){
            trackEvent('noticias-detalle-suscripcion');
        }else{
            trackEvent('noticias-suscripcion');
        }
        addNewsletterSubscription(data).then(response=>{
        if(response && response.result === 'Created'){
            dialog({
                title: intl.formatMessage({id:'page_news_newsletter_title_dialog_ok'}),
                message: intl.formatMessage({id:'page_news_newsletter_description_dialog_ok'}),
                acceptText: intl.formatMessage({id:'page_news_newsletter_button_dialog'}),
                forceWeb: true,
                showCancel: false,
                icon: <IconInformationUserLight color={skinVars.colors.brand} />,
            });
          setFormNewsletter(initNewsletter);
          setCheckConditions(false);
        }else if(response && response.result === 'Exists'){
            dialog({
                title: intl.formatMessage({id:'page_news_newsletter_title_dialog_ko'}),
                message: intl.formatMessage({id:'page_news_newsletter_description_dialog_ko2'}),
                acceptText: intl.formatMessage({id:'page_news_newsletter_button_dialog'}),
                forceWeb: true,
                showCancel: false,
                icon: <IconAlertLight color={skinVars.colors.buttonDangerBackground} />,
            });
        }else{
            dialog({
                title: intl.formatMessage({id:'page_news_newsletter_title_dialog_ko'}),
                message: response && response.result === 'Existe' ? (intl.formatMessage({id:'page_news_newsletter_description_dialog_ko2'})) : (intl.formatMessage({id:'page_news_newsletter_description_dialog_ko'})),
                acceptText: intl.formatMessage({id:'page_news_newsletter_button_dialog'}),
                forceWeb: true,
                showCancel: false,
                icon: <IconAlertLight color={skinVars.colors.buttonDangerBackground} />,
            });
        }
        resolve();
      });
    }
  });

  return (
    <div>
        <DataCard
            title={intl.formatMessage({id:'page_news_newsletter_title'})}
            extra={
            <>
                <Form onSubmit={handleSubmit}>
                    <Box paddingY={16}>
                        {prop.side ?
                            (
                                <Stack space={32}>
                                    <TextField
                                        name="name"
                                        value={formNewsletter.name}
                                        onChange={handleChangeField}
                                        label={intl.formatMessage({id:'page_news_newsletter_name'})}
                                        fullWidth
                                    />
                                    <EmailField
                                        name="email"
                                        value={formNewsletter.email}
                                        onChange={handleChangeField}
                                        label={intl.formatMessage({id:'page_news_newsletter_email'})}
                                        fullWidth
                                    />
                                </Stack>
                            ) : (
                                <GridLayout verticalSpace={16}>
                                    <TextField
                                        name="name"
                                        value={formNewsletter.name}
                                        onChange={handleChangeField}
                                        label={intl.formatMessage({id:'page_news_newsletter_name'})}
                                    />
                                    <EmailField
                                        name="email"
                                        value={formNewsletter.email}
                                        onChange={handleChangeField}
                                        label={intl.formatMessage({id:'page_news_newsletter_email'})}
                                    />
                                </GridLayout>
                            )
                        }
                    </Box>
                    <Checkbox name="checkConditions" checked={checkConditions} onChange={setCheckConditions}>
                        {intl.formatMessage({id:'page_news_newsletter_checkbox'})}
                    </Checkbox>
                    <Box paddingTop={32}>
                        <ButtonPrimary disabled={!checkConditions} submit>{intl.formatMessage({id:'page_news_newsletter_button_subscribe'})}</ButtonPrimary>
                    </Box>
                </Form>
            </>
            }
            aria-label="Data card label"
        />
    </div>
  );
};

export default NewsletterContainer;