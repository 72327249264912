import { Box, Boxed, ButtonLayout, ButtonPrimary, DateField, Form, Grid, LoadingBar, SkeletonLine, SkeletonRectangle, skinVars, Stack, Text2, Text3, Text4, useScreenSize, useWindowSize } from '@telefonica/mistica';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Contract } from '../../entities/contract/contract';
import { Rights } from '../../entities/contract/rights';

import { getRightBalance } from '../../utils/apiclient';
import { formatBalance, formatNetAssetValue } from '../../utils/utils';
import './BalanceRights.scss';

interface BalanceRightsProps {
    contract: Contract | null,
    loading?: boolean
}

const BalanceRights: React.FC<BalanceRightsProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const size = useWindowSize();
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState(null as null|Contract);
    const [rightsBalance, setRightsBalance]= useState(null as null|Rights);
    const [date, setDate]= useState(moment().format("YYYY-MM-DD") as string);


    useEffect(()=>{
        if(prop.contract && prop.contract.derechos){
            setContract(prop.contract);
            setRightsBalance(prop.contract.derechos);
            setDate(moment(prop.contract.derechos?.valorLiquidativo?.fechaValor??'').format("YYYY-MM-DD").toString());
        }
    },[prop.contract]);

    const handleSubmit  = (): Promise<void> =>
        new Promise((resolve) => {
            setLoading(true);
            if(contract && contract.plan && contract.plan.cdCompania && contract.plan.cdFondo && contract.plan.cdPlan && contract.plan.cdSubplan && contract?.numContrato){
                getRightBalance({cdCompany:contract.plan.cdCompania, cdFund:contract.plan.cdFondo, cdPlan:contract.plan.cdPlan, cdSubplan:contract.plan.cdSubplan, numContract:contract.numContrato, date: date??''}).then((result)=>{
                    setRightsBalance(result);
                }).finally(()=>{
                    setLoading(false);
                    resolve();
                });
            }
        });

    return (
        <>{prop.loading ?
                <Box paddingY={screenSize.isDesktopOrBigger ? 32 : 16} className='contractData--content'>
                    <Stack space={40}>
                        <Stack space={24}>
                            <Stack space={8}>
                                {screenSize.isDesktopOrBigger &&
                                    <SkeletonLine></SkeletonLine>
                                }

                                <SkeletonLine></SkeletonLine>
                            </Stack>

                            <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1}>
                                <SkeletonRectangle height={60}></SkeletonRectangle>
                            </Grid>

                            <ButtonLayout>
                                <SkeletonRectangle height={45}></SkeletonRectangle>
                            </ButtonLayout>
                        </Stack>

                        <Boxed className={'boxResults box--auto'}>
                            <SkeletonRectangle width={800} height={116}></SkeletonRectangle>
                        </Boxed>
                    </Stack>
                </Box>
            :
            <>
                <LoadingBar visible={loading}/>
                <style>{
                    '.contractData--content .boxResults {background: '+skinVars.colors.backgroundAlternative+';}'+
                    '.contractData--content .boxValue:before {background: '+skinVars.colors.borderHigh+';}'
                }</style>

                <Box paddingY={screenSize.isDesktopOrBigger ? 32 : 16} className='contractData--content'>
                    <Stack space={40}>
                        <Form initialValues={{date: date}} onSubmit={handleSubmit}>
                            <Stack space={24}>
                                
                                {contract?.plan?.cdCompania === parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ?? '3')? (
                                    <Stack space={8}>
                                        {screenSize.isDesktopOrBigger && (
                                            <Text4 medium>{intl.formatMessage({ id: 'page_contract_data_balace_specific_date' })}</Text4>
                                        )}

                                        <Text2 regular color={skinVars.colors.textSecondary}>
                                            {intl.formatMessage({ id: 'page_contract_data_balace_select_date' })}
                                        </Text2>
                                    </Stack>
                                ) : (
                                    <Stack space={8}>
                                        {screenSize.isDesktopOrBigger && (
                                            <Text4 medium>{intl.formatMessage({ id: 'page_contract_data_balace_rights_specific_date' })}</Text4>
                                        )}

                                        <Text2 regular color={skinVars.colors.textSecondary}>
                                            {intl.formatMessage({ id: 'page_contract_data_balace_rights_select_date' })}
                                        </Text2>
                                    </Stack>
                                )}

                                <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1}>
                                    <DateField max={moment(contract?.derechos?.valorLiquidativo?.fechaValor??'').toDate()} value={date} onChangeValue={(value) => {setDate(value)}} fullWidth name="date" label="Fecha" />
                                </Grid>

                                <ButtonLayout>
                                        <ButtonPrimary submit>{intl.formatMessage({id:'page_contract_data_balace_rights_calculate'})}</ButtonPrimary>
                                </ButtonLayout>
                            </Stack>
                        </Form>

                        <Boxed className={'boxResults box--auto loadingBox '+(loading ? 'loading' : '')}>
                            <Box padding={screenSize.isDesktopOrBigger ? 32 : 16}>
                                <Stack space={16}>
                                    <Grid columns={size.width>480 ? 3 : 2} gap={16}>
                                        <Box className='boxValue box--first'>
                                            <Stack space={4}>
                                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_balace_rights_units'})}</Text2>
                                                <Text3 medium>{rightsBalance?.unidades!==undefined ? formatBalance(rightsBalance?.unidades ?? '') : intl.formatMessage({id:'page_product_customer_not_available_short'}) }</Text3>
                                            </Stack>
                                        </Box>

                                        <Box className='boxValue'>
                                            <Stack space={4}>
                                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_balace_rights_liquidation_value'})}</Text2>
                                                <Text3 medium>{rightsBalance?.valorLiquidativo?.valor!==undefined ? formatNetAssetValue(rightsBalance?.valorLiquidativo?.valor ?? '') : intl.formatMessage({id:'page_product_customer_not_available_short'}) }</Text3>
                                            </Stack>
                                        </Box>

                                        {contract?.plan?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?
                                            <Box className='boxValue'>
                                                <Stack space={4}>
                                                    <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_balace_rights_rights'})}</Text2>
                                                    <Text3 medium>{rightsBalance?.derechos!==undefined ? formatBalance(rightsBalance?.derechos ?? '') : intl.formatMessage({id:'page_product_customer_not_available_short'}) }€</Text3>
                                                </Stack>
                                            </Box>
                                            :
                                            <Box className='boxValue'>
                                                <Stack space={4}>
                                                    <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_balace_rights_balance'})}</Text2>
                                                    <Text3 medium>{rightsBalance?.derechos!==undefined ? formatBalance(rightsBalance?.derechos ?? '') : intl.formatMessage({id:'page_product_customer_not_available_short'}) }€</Text3>
                                                </Stack>
                                            </Box>
                                        }
                                    </Grid>
                                </Stack>
                            </Box>
                        </Boxed>
                    </Stack>
                </Box>
            </>
        }
        </>
    );
};

export default BalanceRights;