import { NativeMarket } from "@capacitor-community/native-market";
import { App as AppCapacitor } from '@capacitor/app';
import { IonApp, IonRouterOutlet, setupIonicReact, useIonRouter } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ActionsSheet, getMovistarSkin, getTelefonicaSkin, LoadingBar, skinVars, ThemeContextProvider } from '@telefonica/mistica';
import { NativeBiometric } from "capacitor-native-biometric";
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { IntlProvider } from 'react-intl';
import CustomThemeContext from './context/ThemeContext';
import { getProvisionalTexts } from "./utils/apiclient";
import { getCurrentLanguage } from './utils/locate';
import {  Pages } from './utils/navigation';
import { getBiometricLogin, getModeMaintenance, getTextsCache, getUser, removeUser, setModeCallCenter } from './utils/storage';
import { isAndroid, isNativeApp, trackError } from './utils/utils';

interface AppProps {
  messages: any,
  loaded: boolean,
  lockapp: boolean,
  newVersion: boolean,
  message?: string
}
const App: React.FC<AppProps> = (prop) => {

  const router = useIonRouter();
  const currentLanguage = getCurrentLanguage();
  const [theme, setTheme] = useState("light")
  const [i18n, setLocale] = useState({locale: currentLanguage.locale, phoneNumberFormattingRegionCode: currentLanguage.phoneNumberFormattingRegionCode})
  const value : any= { theme, setTheme, i18n, setLocale }
  const [appPause, setAppPause] = useState(false)
  const [appLoaded, setAppLoaded] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [appNewVersionLoaded, setAppNewVersionLoaded] = useState(false)

  let customSkin = getTelefonicaSkin();
  customSkin.borderRadii = getMovistarSkin().borderRadii;
  //customSkin.colors = getTelefonicaSkin().colors;
  //customSkin.colors.backgroundAlternative = '#FAF8F2';

  const loadOneTrust=()=>{

    const head = document.querySelector("head");
    if(window.location.host==='localhost:8100' || window.location.host==='fonditel-na.demosdesarrollo.com'){

        const script = document.createElement("script");
        script.setAttribute("src", 'https://cdn-ukwest.onetrust.com/consent/6b32db08-2791-4bad-a97b-47bb5d63127b-test/OtAutoBlock.js');
        if(head)
            head.appendChild(script);

        const script2 = document.createElement("script");
        script2.setAttribute("src", 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js');
        script2.setAttribute("type", 'text/javascript');
        script2.setAttribute("charset", 'UTF-8');
        script2.setAttribute("data-domain-script", "6b32db08-2791-4bad-a97b-47bb5d63127b-test");
        if(head)
            head.appendChild(script2);
    }else if(window.location.host==='pre.fonditel.es'){
        const script = document.createElement("script");
        script.setAttribute("src", 'https://cdn-ukwest.onetrust.com/consent/a47e4161-de88-490a-a1bf-d318e3676f91-test/OtAutoBlock.js');
        if(head)
            head.appendChild(script);

        const script2 = document.createElement("script");
        script2.setAttribute("src", 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js');
        script2.setAttribute("type", 'text/javascript');
        script2.setAttribute("charset", 'UTF-8');
        script2.setAttribute("data-domain-script", "a47e4161-de88-490a-a1bf-d318e3676f91-test");
        if(head)
            head.appendChild(script2);

    }else if(window.location.host==='www.fonditel.es'){
      const script = document.createElement("script");
      script.setAttribute("src", 'https://cdn-ukwest.onetrust.com/consent/d86cb948-856c-47fb-bcdc-dd335cead45f/OtAutoBlock.js');
      if(head)
          head.appendChild(script);

      const script2 = document.createElement("script");
      script2.setAttribute("src", 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js');
      script2.setAttribute("type", 'text/javascript');
      script2.setAttribute("charset", 'UTF-8');
      script2.setAttribute("data-domain-script", "d86cb948-856c-47fb-bcdc-dd335cead45f");
      if(head)
          head.appendChild(script2);
    }
  }

  const openMarket=()=>{
    let appId = "id1437174520";
    if(isAndroid){
      appId = "com.fonditel.app";
    }
    NativeMarket.openStoreListing({
      appId: appId,
    });
    AppCapacitor.exitApp();
  }

  const cancelUpdate=()=>{
    if(updating){
      setUpdating(false);
      setAppNewVersionLoaded(true);
      NativeBiometric.isAvailable().then((result)=>{
        if(result.isAvailable && getBiometricLogin()){
          setAppPause(true);
        }
        setAppLoaded(true);
      }).catch((err)=>{
        setAppLoaded(true);
        if(err){
          trackError(JSON.stringify(err));
        }
      })
    }

  }

  useEffect(()=>{
      if(prop.loaded){
        if(isNativeApp && getModeMaintenance()){
          setAppLoaded(true);
          setAppNewVersionLoaded(true);
          setUpdating(false);
          setAppPause(false);
        }
        else if(isNativeApp && !getModeMaintenance()){
          if(prop.newVersion && prop.message){
            setUpdating(true);
            setAppNewVersionLoaded(false);
            setAppLoaded(false);
          }else{
            NativeBiometric.isAvailable().then((result)=>{
              if(result.isAvailable && getBiometricLogin()){
                setAppPause(true);
              }
              setAppLoaded(true);
            }).catch((err)=>{
              setAppLoaded(true);
              if(err){
                trackError(JSON.stringify(err));
              }
            })
          }
        }else{
          if(window.location.search.length>0){
            const params = Object.fromEntries((new URLSearchParams(window.location.search)));
            if(params.callcenter && params.callcenter.length>0 && atob(params.callcenter) && atob(params.callcenter).split('@').length===3){
              const token = atob(params.callcenter).split('@');
              const cdSesion = token[0];
              const nif = token[1];
              const cdSesionCallCenter = token[2];

              if(cdSesion && nif && cdSesionCallCenter){
                const modeCallCenter : any ={
                  cdSesion,
                  nif,
                  cdSesionCallCenter
                }
                setModeCallCenter(modeCallCenter);
              }
            }
          }

          setAppLoaded(true);
          loadOneTrust()
        }
    }else{
      setAppLoaded(false);
    }
  }, [prop]);

  const messages = prop?.messages?.[currentLanguage.value] ?? (getTextsCache()?.messages?.[currentLanguage.value] ?? getProvisionalTexts());

  setupIonicReact({
    mode: 'ios'
  });


  if(isNativeApp){
    AppCapacitor.removeAllListeners().then(()=>{

      AppCapacitor.addListener('appStateChange', ({ isActive }) => {
        if(appPause!=(!isActive)){
          setAppPause(!isActive);

          if(isActive){
            setTimeout(function(){
              setAppPause(false);
            }, 200);
          }

          /*if(isActive && !isValidToken() && getUser()){
            setTimeout(function(){
              removeUser();
              window.location.href='/';
            }, 300);
            //return;
          }*/
        }
      });
    })
  }


  return (
     <CustomThemeContext.Provider value={value}>
      <IntlProvider locale={currentLanguage.value} messages={messages as Record<string, string>} fallbackOnEmptyString={false} onError={()=>{}}>
        <ThemeContextProvider
                theme={{
                    skin: customSkin,
                    colorScheme: value.theme,
                    i18n: value.i18n,
                }}
            >
          <style>{'.html-content{color:'+(skinVars.colors.textPrimary)+'!important} .html-content a{color:'+(skinVars.colors.brand)+'!important}'}</style>
          <style>{'.rbx-option.active{border-color:'+skinVars.colors.brand+'}'}</style>
          <IonApp className={(isNativeApp ? 'app' : '')+' '+(appPause ? 'app-pause' : '')+' '+(appLoaded ? 'app-loaded' : 'app-loading')}>
            {appLoaded && <IonReactRouter>
              <IonRouterOutlet>
                {Pages()}
              </IonRouterOutlet>
            </IonReactRouter>
            }
            <LoadingBar visible={!appLoaded} />
            <Toaster
                  position="bottom-center"
                  reverseOrder={false}
                />

            {prop.message && prop.newVersion &&
              <ActionsSheet
                title=''
                description={prop.message}
                onClose={()=>{prop.lockapp ? openMarket() : cancelUpdate()}}
                onPressButton={(val)=>{val==='PRIMARY' ? openMarket() : cancelUpdate()}}
                secondaryButton={prop.lockapp ? undefined : {text: messages['app_cancel_update']}}
                button={{ text: messages['app_download_update'] }} />
            }
          </IonApp>
          </ThemeContextProvider>
        </IntlProvider>
      </CustomThemeContext.Provider>
    )
};

export default App;
