import { Box, Callout, IconCancelRegular, IconCheckedRegular, skinVars } from "@telefonica/mistica";
import toast from 'react-hot-toast';


export const useToast = () => {

    const showToast = (prop:{type:string, title: string, description?: string}) => {

        toast((t) => (
            <Box className='toastCallout'>
                  <Callout
                      icon={prop.type==='ok' ? <IconCheckedRegular color={skinVars.colors.brand}></IconCheckedRegular> : <IconCancelRegular color={skinVars.colors.buttonDangerBackground}></IconCancelRegular>}
                      onClose={() => {toast.dismiss(t.id)}}
                      title={prop.title}
                      description={prop?.description??""}
                  />
              </Box>
          ), {duration:5000, style:{background:'none', borderRadius:'0', padding:'0', width:'100%', maxWidth:'inherit', boxShadow:'none'}});

    };
	return { showToast };
}