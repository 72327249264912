

import { useIntl } from 'react-intl';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import './EmploymentSystemPensionPlansPage.scss';
import HeroNoImage from '../../../components/hero/HeroNoImage';
import { ButtonSecondary, Carousel, DataCard, Grid, Hero, IconComputerUserRegular, IconFlagRegular, IconPodiumRegular, Image, ResponsiveLayout, skinVars, useScreenSize } from '@telefonica/mistica';
import OurAppContainer from '../../../components/staticPages/ourApp/OurApp';
import { useIonRouter } from '@ionic/react';
import { getUrlNavigation } from '../../../utils/navigation';
import SolutionForCompaniesPage from './SolutionsForCompaniesPage';
import SolutionsForFreelancersPage from './SolutionsForFreelancersPage';
import { isBot } from '../../../utils/utils';
import { useLayoutEffect, useState } from 'react';
import { getContent } from '../../../utils/apiclient';
import { Content } from '../../../entities/contents/content.model';

const EmploymentSystemPensionPlansPage: React.FC = () => {
  const intl = useIntl()
  const size = useScreenSize();
  const screenSize = useScreenSize();
  const router = useIonRouter();
  const [content, setContent] = useState(undefined as Content|undefined);

  useLayoutEffect(()=>{
    getContent('ppes').then((result)=>{
      setContent(result);
    });
  }, []);

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_employment_system_pension_plans_seo_title'}), metaDescription:intl.formatMessage({id:'page_employment_system_pension_plans_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_employment_system_pension_plans_page_title'})}}
    >
      <HeroNoImage
        title={intl.formatMessage({id:'page_simplified_pension_plans_titulo1'})}
        full_head={true}
      >
        <Carousel initialActiveItem={0} itemsPerPage={size.isMobile ? 1 : (size.isTablet ? 3 : 4)}
          items={[
            <DataCard
              icon={<IconFlagRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconFlagRegular>}
              title={intl.formatMessage({id:'page_simplified_pension_plans_card1_title'})}
              description={intl.formatMessage({id:'page_simplified_pension_plans_card1_description'})}/>,
            <DataCard
              icon={<img src={'../assets/img/telefonica-logo.svg'} color={skinVars.colors.buttonPrimaryBackground} width="40px" height="40px" />}
              title={intl.formatMessage({id:'page_simplified_pension_plans_card2_title'})}
              description={intl.formatMessage({id:'page_simplified_pension_plans_card2_description'})}/>,
            <DataCard
              icon={<IconComputerUserRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconComputerUserRegular>}
              title={intl.formatMessage({id:'page_simplified_pension_plans_card3_title'})}
              description={intl.formatMessage({id:'page_simplified_pension_plans_card3_description'})}/>,
            <DataCard
              icon={<IconPodiumRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconPodiumRegular>}
              title={intl.formatMessage({id:'page_simplified_pension_plans_card4_title'})}
              description={intl.formatMessage({id:'page_simplified_pension_plans_card4_description'})}/>
            ]}
          />
      </HeroNoImage>

      <ResponsiveLayout className='content' fullWidth={true}>
        <Hero
          media={<Image src={content?.imageBloque1 ?? '../assets/img/soluciones_para_empresas.jpg'} width="100%" height='337px'/>}
          title={intl.formatMessage({id:'page_employment_system_pension_plans_hero1_title'})}
          description={intl.formatMessage({id:'page_employment_system_pension_plans_hero1_subtitle'})}
          desktopMediaPosition='right'
          button={isBot ?
                    <ButtonSecondary href={getUrlNavigation(SolutionForCompaniesPage)}>{intl.formatMessage({id:'page_employment_system_pension_plans_heros_action'})}</ButtonSecondary>
                  :
                    <ButtonSecondary onPress={()=> {router.push(getUrlNavigation(SolutionForCompaniesPage))}}>{intl.formatMessage({id:'page_employment_system_pension_plans_heros_action'})}</ButtonSecondary>

                  }
        />
        <Hero
          media={<Image src={content?.imageBloque2 ?? '../assets/img/soluciones_para_autonomos.jpg'} width="100%" height='337px'/>}
          title={intl.formatMessage({id:'page_employment_system_pension_plans_hero2_title'})}
          description={intl.formatMessage({id:'page_employment_system_pension_plans_hero2_subtitle'})}
          desktopMediaPosition='left'
          button={isBot ?
                      <ButtonSecondary href={getUrlNavigation(SolutionsForFreelancersPage)}>{intl.formatMessage({id:'page_employment_system_pension_plans_heros_action'})}</ButtonSecondary>
                    :
                      <ButtonSecondary onPress={()=> {router.push(getUrlNavigation(SolutionsForFreelancersPage))}}>{intl.formatMessage({id:'page_employment_system_pension_plans_heros_action'})}</ButtonSecondary>
                  }
        />
      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth={true}>

        <OurAppContainer/>

      </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default EmploymentSystemPensionPlansPage;
