import { IonFooter, useIonRouter } from '@ionic/react';
import { Box, ButtonPrimary, GridLayout, IconButton, IconChevronDownLight, IconChevronUpLight, Image, Inline, ResponsiveLayout, Row, Stack, Text1, TextLink, Touchable, skinVars, useScreenSize } from '@telefonica/mistica';
import isbot from 'isbot';
import React, { useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import { NavOption } from '../../entities/commons/nav-option.model';
import ContactPage from '../../pages/public/contact/ContactPage';
import HomePage from '../../pages/public/home/HomePage';
import { getFooterNavigation, getUrlNavigation } from '../../utils/navigation';
import { isBot, trackEvent } from '../../utils/utils';
import './Footer.scss';

interface FooterProps {

    landing?: boolean
}

const Footer: React.FC<FooterProps> = (prop) => {
const screenSize = useScreenSize();
const router = useIonRouter();
const intl = useIntl();


const [footerNavigation, setFooterNavigation] = React.useState([] as NavOption[])
useLayoutEffect(()=>{
    const options = getFooterNavigation();
    setFooterNavigation(options);
}, []);

const handleClickNavMainOption = (index:number) =>{

    if(screenSize.isDesktopOrBigger){
        if(footerNavigation[index]!==undefined && footerNavigation[index].page!==undefined){
            router.push(getUrlNavigation(footerNavigation[index]?.page??HomePage));
        }
    }else{
        const newValues = {
            ...footerNavigation
        };
        newValues[index].showOptions = newValues[index].showOptions && newValues[index].showOptions===true ? false : true;
        setFooterNavigation(newValues);
    }
}

const renderOptionFooterNavigation = (index:number) => {

    if(footerNavigation[index]===undefined) return <></>

    const options = footerNavigation[index].options!==undefined ? footerNavigation[index].options : [];
        return <div key={'footer-nav-'+index}>
            <TextLink style={{fontWeight:'500'}} onPress={()=> handleClickNavMainOption(index) }>
                <Inline space={'between'} alignItems={'center'}>
                    <>{intl.formatMessage({id:footerNavigation[index].title})}</>
                    {!screenSize.isDesktopOrBigger &&
                        (footerNavigation[index].showOptions ? <IconChevronUpLight></IconChevronUpLight> : <IconChevronDownLight></IconChevronDownLight>)
                    }
                </Inline>
            </TextLink>
            {(screenSize.isDesktopOrBigger || footerNavigation[index].showOptions) && options &&
                <Box paddingY={16}>
                    <Stack space={8}>
                        { options.map((option, i) => {
                            return (
                                option.href ? <TextLink key={'footer-nav-'+index+'-option-'+i} href={option.href} newTab>{intl.formatMessage({id:option.title})}</TextLink>
                                            : (isBot ?
                                                    <TextLink key={'footer-nav-'+index+'-option-'+i} href={getUrlNavigation(option.page??HomePage)+(option.anchor ? '#'+slugify(intl.formatMessage({id:option.title})) : '')}>{intl.formatMessage({id:option.title})}</TextLink>
                                                :
                                                    <TextLink key={'footer-nav-'+index+'-option-'+i} onPress={()=>router.push(getUrlNavigation(option.page??HomePage)+(option.anchor ? '#'+slugify(intl.formatMessage({id:option.title})) : ''))}>{intl.formatMessage({id:option.title})}</TextLink>)
                                            )
                        })}
                    </Stack>
                </Box>
            }
        </div>

  }

  return (
    <ResponsiveLayout fullWidth={true} backgroundColor={skinVars.colors.backgroundBrand}>
        <IonFooter id="footer">
            <ResponsiveLayout backgroundColor={'transparent'} isInverse>
                <Box paddingTop={56} paddingBottom={56}>
                    <Stack space={32}>
                        <Inline space={'between'} alignItems={'center'}>
                            <Touchable href='/'>
                                <Image src={'/assets/img/logo-inverse.svg'} width={110} aspectRatio={0} alt={intl.formatMessage({id:'site_name'})} noBorderRadius={true} ></Image>
                            </Touchable>


                            {!prop.landing &&
                                <Inline space={24} alignItems={'center'}>
                                    <IconButton onPress={()=>{ window.open('https://twitter.com/fonditel', '_blank'); trackEvent('footer-redes-twitter');} }  icon="/assets/img/twitter-logo.svg" aria-label="Twitter" />
                                    <IconButton onPress={()=>{ window.open('https://www.linkedin.com/company/fonditel/', '_blank'); trackEvent('footer-redes-linkedin');} } icon="/assets/img/linkedin-logo.svg" aria-label="Linkedin" />
                                    {isBot ?
                                        <ButtonPrimary href={getUrlNavigation(ContactPage)} small>{intl.formatMessage({id:'menu_footer_contact'})}</ButtonPrimary>
                                    :
                                        <ButtonPrimary onPress={()=>{router.push(getUrlNavigation(ContactPage)); trackEvent('footer-contacto');}} small>{intl.formatMessage({id:'menu_footer_contact'})}</ButtonPrimary>
                                    }
                                </Inline>
                            }
                        </Inline>
                    </Stack>

                    <ResponsiveLayout fullWidth={true} isInverse={true} backgroundColor={'transparent'}>

                        {!prop.landing &&
                            <Box paddingTop={80} paddingBottom={32}>
                                <GridLayout template='6+6' verticalSpace={16}
                                left={
                                    <GridLayout template='8+4'
                                        left={
                                            <GridLayout template='6+6'
                                                left={renderOptionFooterNavigation(0)}
                                                right={renderOptionFooterNavigation(1)}></GridLayout>
                                        }
                                        right={renderOptionFooterNavigation(2)}></GridLayout>
                                }
                                right={
                                    <GridLayout template='8+4'
                                        left={
                                            <GridLayout template='6+6'
                                                left={renderOptionFooterNavigation(3)}
                                                right={renderOptionFooterNavigation(4)}></GridLayout>
                                        }
                                        right={<></>}
                                    ></GridLayout>
                                }
                                ></GridLayout>
                            </Box>
                        }
                        <Box paddingTop={24}>
                            <Stack space={16}>
                                {!screenSize.isDesktopOrBigger &&
                                    <Inline space={'between'} alignItems={'center'}>
                                        <IconButton href='https://telefonica.es' style={{width:"195px",height:"41px"}} newTab={true} icon={'/assets/img/endoso-telefonica.svg'}></IconButton>
                                    </Inline>
                                }
                                <Inline space={'between'} alignItems={'center'}>
                                    <Text1 regular>{intl.formatMessage({id:'menu_footer_trademark'})}</Text1>
                                    {screenSize.isDesktopOrBigger &&
                                        <IconButton href='https://telefonica.es' style={{width:"195px",height:"41px"}} newTab={true} icon={'/assets/img/endoso-telefonica.svg'}></IconButton>
                                    }
                                </Inline>
                            </Stack>
                        </Box>
                    </ResponsiveLayout>
                </Box>
            </ResponsiveLayout>
        </IonFooter>
    </ResponsiveLayout>
  );
};

export default Footer;
