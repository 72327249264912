import { Box, Inline, ResponsiveLayout, Text5, useScreenSize } from '@telefonica/mistica';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import Documentation from '../../../../../components/product-data/Documentation';
import { Product } from '../../../../../entities/products/product';
import { getDocumentsControlCommision } from '../../../../../utils/apiclient';
import { getSelectedPlanComisionControl, getUser } from '../../../../../utils/storage';

const LegalDocumentationControlCommisionPage: React.FC = () => {
  const intl = useIntl();
  const screenSize = useScreenSize();
  const [loading, setLoading] = useState(true);

  const [product, setProduct] = useState(null as null|Product);
  let planComisionControlSelected = '';

  useEffect(()=>{
    getDocuments();
  }, []);

  const getDocuments = ()=>{
    const planCC = getSelectedPlanComisionControl();

    if(planCC){

      const newComisionControlPlanSelected = getUser()?.selectedPlanComisionControl??'';
      if(newComisionControlPlanSelected && newComisionControlPlanSelected.length>0 && newComisionControlPlanSelected!=planComisionControlSelected){
        planComisionControlSelected = newComisionControlPlanSelected;
        setLoading(true);
        setProduct(null);
        getDocumentsControlCommision('dl').then(documents=>{
          if(documents){
            setProduct({ cdCompania: planCC.cdCompania, cdFondo: planCC.cdFondo, cdPlan: planCC.cdPlan, cdSubplan: planCC.cdSubplan,  documentacion: documents});
          }
        }).finally(()=>{
          setLoading(false);
        });
      }
    }
  };

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_control_commision_legal_documentation_title'})}}
      pageInfo={{option: process.env.REACT_APP_AREA_COMISION_CONTROL_DOCUMENTACION_LEGAL, role:'control-commision'}}
      title={intl.formatMessage({id:'page_control_commision_legal_documentation_title'})}
      onChangeUser={()=>{getDocuments()}}
      showMenu>
      <ResponsiveLayout className='inner first'>
        {screenSize.isDesktopOrBigger &&
          <Box paddingBottom={32}>
            <Inline space={'between'} alignItems='center'>
              <Text5>{intl.formatMessage({id:'page_control_commision_legal_documentation_title'})}</Text5>
            </Inline>
          </Box>
        }

        <Documentation prefixSlug='comision-documentacion-descarga_' loading={loading} product={product}  />
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default LegalDocumentationControlCommisionPage;