

import { Box, Boxed, ButtonPrimary, Divider, Form, GridLayout, IconEuroSymbolCircleRegular, IconPercentRegular, Inline, RadioButton, RadioGroup, ResponsiveLayout, Select, Stack, Text2, Text3, Text4, Text5, TextField, skinVars, useScreenSize, DecimalField, LoadingBar } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import EmptyStateCustom from '../../../../components/empty-state/EmptyStateCustom';
import HeroCalculator from '../../../../components/hero/HeroCalculator';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import './Calculators.scss';

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS, Filler, Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip as TooltipChart
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ForInvestorPage from '../ForInvestorPage';
import { formatAmount, formatBalance, scrollToTop, trackEvent } from '../../../../utils/utils';
import { calculateCommissionsSavingsContribution, calculateCommissionsSavingsTransfer, trackStadistics } from '../../../../utils/apiclient';
import { ResultCalculatorCommisionSavingsContribution } from '../../../../entities/contents/result-calculator-commision-savings-contribution.model';
import { ResultCalculatorCommisionSavingsTransfer } from '../../../../entities/contents/result-calculator-commision-savings-transfer.model';
import { useAlert } from '../../../../components/alerts/Alert';
import slugify from 'react-slugify';
import { getUrlNavigation } from '../../../../utils/navigation';
import ContactPage from '../../contact/ContactPage';
import { useIonRouter } from '@ionic/react';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  LineController,
  BarController,
  TooltipChart,
  Filler
);

const CommissionSavingsCalculatorPage: React.FC = () => {
  const intl = useIntl()
  const { showAlert } = useAlert();
	const router = useIonRouter();
  const screenSize = useScreenSize();
  const [loading, setLoading] = useState(false);
  const [resultContribution, setResultContribution] = useState(null as ResultCalculatorCommisionSavingsContribution|null);
  const [resultTransfer, setResultTransfer] = useState(null as ResultCalculatorCommisionSavingsTransfer|null);

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  const dataGraphicContribution = {
    labels: [intl.formatMessage({id:'page_commission_savings_calculator_10_years'}), intl.formatMessage({id:'page_commission_savings_calculator_20_years'}), intl.formatMessage({id:'page_commission_savings_calculator_30_years'})],
    datasets: [{
      label: intl.formatMessage({id:'page_commission_savings_calculator_with_fonditel'}),
      data: [resultContribution?.ImpFonditel10, resultContribution?.ImpFonditel20, resultContribution?.ImpFonditel30],
      backgroundColor: ['#06F'],
    },{
      label: intl.formatMessage({id:'page_commission_savings_calculator_equity_with_another_entity'}),
      data: [resultContribution?.ImpOtra10, resultContribution?.ImpOtra20, resultContribution?.ImpOtra30],
      backgroundColor: ['#F28D15']
    },
    {
      label: intl.formatMessage({id:'page_commission_savings_calculator_savings_commissions'}),
      data: [resultContribution?.commissionsSavings10, resultContribution?.commissionsSavings20, resultContribution?.commissionsSavings30],
      backgroundColor: ['#E63780']
    }]
  };

  const dataGraphicTransfer = {
    labels: [intl.formatMessage({id:'page_commission_savings_calculator_5_years'}), resultTransfer?.permanenceCommitment+' años'],
    datasets: [{
      label: intl.formatMessage({id:'page_commission_savings_calculator_with_fonditel'}),
      data: [resultTransfer?.ImpFonditel5, resultTransfer?.ImpFonditel1],
      backgroundColor: ['#06F'],
    },{
      label: intl.formatMessage({id:'page_commission_savings_calculator_equity_with_another_entity'}),
      data: [resultTransfer?.ImpOtra5, resultTransfer?.ImpOtra1],
      backgroundColor: ['#F28D15']
    }]
  };

  //Form
  const initFormData = {
    operationType: ('A'),

    amountContributedAnnually: '',
    estimatedProfitability : formatBalance(process.env.REACT_APP_FONDITEL_RENTABILIDAD_ANUAL ?? '3.0'),
    commissionsOfTheOtherEntity: '',

    transferAmountAnotherEntity: '',
    bonusPercentage: '',
    amountCalculated: '',
    newEntityCommissions: '',
    permanenceCommitment: '',

  }
  const [formData, setFormData] = useState(initFormData);
  const [formErrors, setFormErrors] = useState([] as any);


  const handleError=(name:string) =>{
    if(formErrors && formErrors[name]){
        const error = formErrors[name];
        if(error){
            return intl.formatMessage({id: 'page_commission_savings_calculator_'+error.toLowerCase().replaceAll(' ', '_')});
        }
    }
    return null;
  }

  const handleChangeAnyField= (name:string, value:any) => {
    const newValues = {
        ...formData,
        [name]: value,
    };
    setFormData(newValues);

    switch(name){
      case 'transferAmountAnotherEntity':
      case 'bonusPercentage':
        let amountCalculated='';
        if((name==='bonusPercentage' && value && value.length>0 && formData.transferAmountAnotherEntity && formData.transferAmountAnotherEntity.length>0) ||
            (name==='transferAmountAnotherEntity' && value && value.length>0 && formData.bonusPercentage && formData.bonusPercentage.length>0)){

            const bonus = name==='bonusPercentage' ? parseFloat(value.replace(',', '.')) : parseFloat(formData.bonusPercentage.replace(',', '.'));
            const transferAmount = name==='transferAmountAnotherEntity' ? parseFloat(value.replace(',', '.')) : parseFloat(formData.transferAmountAnotherEntity.replace(',', '.'));

            amountCalculated='0€';
            if(bonus>0){
              amountCalculated= formatAmount(bonus/100 * transferAmount)+'€';
            }
        }
        const newValuesCalculated = {
          ...newValues,
          ['amountCalculated']: amountCalculated,
        };
        setFormData(newValuesCalculated);
        break;
    }

    if(handleError(name)){
        delete formErrors[name];
    }
    validateField(name, value);
  };


  const validateField = (name:string, value:any)=>{
    switch(name){
      case 'commissionsOfTheOtherEntity':
        if(value && value.length>0){
          const commissions = parseFloat(value.replace(',', '.'));
          if(commissions<0 || commissions > (1.5)){
            formErrors[name] = 'minimum amount of contributions';
            return false;
          }
        }
        break;
      }
      return true;
  }

  const handleCalculateCommissions = (data: any): Promise<void> =>
    new Promise((resolve) => {
      setLoading(true);
      trackEvent('para_inversor-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_commission_savings_calculator_title'})).replaceAll('-', '_')+'-calcular');
      switch(formData.operationType){
        case 'A':
          const paramsContribution: any = {
            amountContributedAnnually: parseFloat(formData.amountContributedAnnually.replace(',', '.')),
            estimatedProfitability: parseFloat(formData.estimatedProfitability.replace(',', '.')),
            commissionsOfTheOtherEntity: parseFloat(formData.commissionsOfTheOtherEntity.replace(',', '.'))
          };
          setResultContribution(null);
          calculateCommissionsSavingsContribution(paramsContribution).then((result)=>{
            if(result){
              trackStadistics('CALCULATOR', undefined, undefined, 'Calculadora de Ahorro por comisiones.' );
              setResultContribution(result);
              scrollToTop('result-contribution');
            }
          }).finally(()=>{
            showAlert('_comimission_savings_calculator_form_', 'Calculadora de ahorro A');
            setLoading(false);
            resolve();
          });
          break;
        case 'T':
          const paramsTransfer: any = {
            transferAmountAnotherEntity: parseFloat(formData.transferAmountAnotherEntity.replace(',', '.')),
            bonusPercentage: parseFloat(formData.bonusPercentage.replace(',', '.')),
            amountCalculated: parseFloat(formData.amountCalculated.replace(',', '.').replace('€', '')),
            newEntityCommissions: parseFloat(formData.newEntityCommissions.replace(',', '.')),
            permanenceCommitment: parseInt(formData.permanenceCommitment.replace(',', '.')),
          }
          setResultTransfer(null);
          calculateCommissionsSavingsTransfer(paramsTransfer).then((result)=>{
            if(result){
              setResultTransfer(result);
              scrollToTop('result-transfer');
            }
          }).finally(()=>{
            showAlert('_comimission_savings_calculator_form_', 'Calculadora de ahorro T');
            setLoading(false);
            resolve();
          });
          break;
      }
    });


    const isSubmitDisabled = () => {
        return (formData.operationType==='A' &&
               (formData.amountContributedAnnually.length===0 || handleError('amountContributedAnnually')!==null ||
                formData.commissionsOfTheOtherEntity.length===0 || handleError('commissionsOfTheOtherEntity')!==null)) ||

                (formData.operationType==='T' &&
                (formData.transferAmountAnotherEntity.length===0 || handleError('transferAmountAnotherEntity')!==null ||
                 formData.bonusPercentage.length===0 || handleError('bonusPercentage')!==null ||
                 formData.newEntityCommissions.length===0 || handleError('newEntityCommissions')!==null ||
                 formData.permanenceCommitment.length===0 || handleError('permanenceCommitment')!==null));
    };



  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_commission_savings_calculator_seo_title'}), metaDescription:intl.formatMessage({id:'page_commission_savings_calculator_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_commission_savings_calculator_title'}), parents:[{title:intl.formatMessage({id:'page_for_investor_page_title'}), item:ForInvestorPage}]}}
    >
      <LoadingBar visible={loading} />
      <HeroCalculator
        title={intl.formatMessage({id:'page_calculator_commission_savings_title'})}
        description={intl.formatMessage({id:'page_calculator_commission_savings_description'})}
        children={
          <Boxed className='calculatorBox'>
              <ResponsiveLayout backgroundColor={skinVars.colors.background}>
                  <Box paddingX={24} paddingY={32}>
                      <Form initialValues={formData} onSubmit={handleCalculateCommissions}>
                          <Stack space={24}>
                              <RadioGroup
                                  name="operationType"
                                  onChange={(val=>{handleChangeAnyField('operationType', val)})}
                                  aria-labelledby="label"
                              >
                                  <Inline space={16}>
                                      <RadioButton value="A">{intl.formatMessage({id:'page_calculator_label_contribution'})}</RadioButton>
                                      <RadioButton value="T">{intl.formatMessage({id:'page_calculator_label_transfer'})}</RadioButton>
                                  </Inline>
                              </RadioGroup>

                              <Stack space={16}>
                                {formData.operationType==='A' &&
                                  <Stack space={16}>
                                    <DecimalField
                                      fullWidth
                                      value={formData.amountContributedAnnually}
                                      name="amountContributedAnnually"
                                      onChangeValue={(val=>{handleChangeAnyField('amountContributedAnnually', val)})}
                                      label={intl.formatMessage({id:'page_calculator_label_annual_amount'})}
                                    />

                                    <DecimalField
                                      fullWidth
                                      disabled
                                      name="estimatedProfitability"
                                      label={intl.formatMessage({id:'page_calculator_label_estimated_profitability'})}
                                    />

                                    <DecimalField
                                      fullWidth
                                      name="commissionsOfTheOtherEntity"
                                      onChangeValue={(val=>{handleChangeAnyField('commissionsOfTheOtherEntity', val)})}
                                      label={intl.formatMessage({id:'page_calculator_label_commissions_other_entity'})}
                                    />
                                  </Stack>
                                }

                                {formData.operationType==='T' &&
                                  <Stack space={16}>
                                    <DecimalField
                                      fullWidth
                                      name="transferAmountAnotherEntity"
                                      onChangeValue={(val=>{handleChangeAnyField('transferAmountAnotherEntity', val)})}
                                      label={intl.formatMessage({id:'page_calculator_label_amount_to_transfer'})}
                                    />

                                      <GridLayout
                                        template='8+4'
                                        verticalSpace={16}
                                        left={
                                          <DecimalField
                                            fullWidth
                                            name="bonusPercentage"
                                            onChangeValue={(val=>{handleChangeAnyField('bonusPercentage', val)})}
                                            label={intl.formatMessage({id:'page_calculator_label_bonus_percentage'})}
                                          />
                                        }
                                        right={
                                          <TextField
                                            fullWidth
                                            disabled
                                            name="amountCalculated"
                                            value={formData.amountCalculated}
                                            label=""
                                          />
                                        }
                                      />

                                    <DecimalField
                                      fullWidth
                                      name="newEntityCommissions"
                                      onChangeValue={(val=>{handleChangeAnyField('newEntityCommissions', val)})}
                                      label={intl.formatMessage({id:'page_calculator_label_commissions_new_entity'})}
                                    />

                                    <Select
                                      fullWidth
                                      name="permanenceCommitment"
                                      onChangeValue={(val=>{handleChangeAnyField('permanenceCommitment', val)})}
                                      label={intl.formatMessage({id:'page_calculator_label_compromise_permanence'})}
                                      options={[
                                        { value: "0", text: intl.formatMessage({id:'page_commission_savings_calculator_0_years'}) },
                                        { value: "1", text: intl.formatMessage({id:'page_commission_savings_calculator_1_years'}) },
                                        { value: "2", text: intl.formatMessage({id:'page_commission_savings_calculator_2_years'}) },
                                        { value: "3", text: intl.formatMessage({id:'page_commission_savings_calculator_3_years'}) },
                                        { value: "4", text: intl.formatMessage({id:'page_commission_savings_calculator_4_years'}) },
                                        { value: "5", text: intl.formatMessage({id:'page_commission_savings_calculator_5_years'}) },
                                        { value: "6", text: intl.formatMessage({id:'page_commission_savings_calculator_6_years'}) },
                                        { value: "7", text: intl.formatMessage({id:'page_commission_savings_calculator_7_years'}) },
                                        { value: "8", text: intl.formatMessage({id:'page_commission_savings_calculator_8_years'}) },
                                        { value: "9", text: intl.formatMessage({id:'page_commission_savings_calculator_9_years'}) },
                                        { value: "10", text: intl.formatMessage({id:'page_commission_savings_calculator_10_years'}) }
                                      ]}
                                    />
                                  </Stack>
                                }

                                <ButtonPrimary disabled={isSubmitDisabled()} submit>
                                  {intl.formatMessage({id:'page_calculator_button_calculate'})}
                                </ButtonPrimary>
                              </Stack>
                          </Stack>
                      </Form>
                  </Box>
              </ResponsiveLayout>
          </Boxed>
        }
      ></HeroCalculator>

      {/* calculation results */}
      <style>{
          '.calculatorPage--content .boxWidth--color{background-color:'+skinVars.colors.backgroundAlternative+';}'
      }</style>
      <ResponsiveLayout className='content' fullWidth={true}>
        <ResponsiveLayout className='calculatorPage--content'>

            <div id="result-contribution">

              {resultContribution && formData.operationType==='A' &&
              <>
              {/* ** RESULT:: CONTRIBUTION ** */}
              <Box paddingY={screenSize.isTabletOrBigger ? 64 : 32}>
                <Stack space={16}>
                  <Text5>{intl.formatMessage({id:'page_calculator_result_title'})}</Text5>

                  <Text4 light>{intl.formatMessage({id:'page_calculator_result_description_contribution'})}</Text4>
                </Stack>
              </Box>

              {/* historical data */}
              <Box paddingX={screenSize.isDesktopOrBigger ? 80 : (screenSize.isTabletOrBigger ? 40 : 0)} paddingY={screenSize.isTabletOrBigger ? 56 : 16} className='result--box'>
                {screenSize.isTabletOrBigger &&
                  <Stack space={16}>
                    <Inline fullWidth space={0} alignItems='flex-end' className='inlineItems--end'>
                      <Inline space={8}>
                        <Box className='boxWidth'>
                          <Inline space={2}>
                            <Text2 medium color={skinVars.colors.brand}>10</Text2>
                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                          </Inline>
                        </Box>

                        <Box className='boxWidth'>
                          <Inline space={2}>
                            <Text2 medium color={skinVars.colors.brand}>20</Text2>
                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                          </Inline>
                        </Box>

                        <Box className='boxWidth'>
                          <Inline space={2}>
                            <Text2 medium color={skinVars.colors.brand}>30</Text2>
                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                          </Inline>
                        </Box>
                      </Inline>
                    </Inline>
                    <Divider />
                  </Stack>
                }

                <Box paddingTop={24}>
                  <Stack space={screenSize.isTabletOrBigger ? 12 : 16}>
                    <Stack space={8}>
                      {!screenSize.isTabletOrBigger &&
                        <Box className='boxAlignCenter'>
                          <Text3 light>{intl.formatMessage({id:'page_calculator_data_money_with_another_entity'})}</Text3>
                        </Box>
                      }

                      <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                        {screenSize.isTabletOrBigger &&
                          <Text3 regular>{intl.formatMessage({id:'page_calculator_data_money_with_another_entity'})}</Text3>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 12: 8}>
                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>10</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultContribution?.ImpOtra10 ? formatAmount(resultContribution?.ImpOtra10, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>

                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>20</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultContribution?.ImpOtra20 ? formatAmount(resultContribution?.ImpOtra20, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>

                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>30</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultContribution?.ImpOtra30 ? formatAmount(resultContribution?.ImpOtra30, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>
                        </Inline>
                      </Inline>
                    </Stack>

                    {!screenSize.isTabletOrBigger &&
                      <Divider />
                    }

                    <Stack space={8}>
                      {!screenSize.isTabletOrBigger &&
                        <Box className='boxAlignCenter'>
                          <Text3 light>{intl.formatMessage({id:'page_calculator_data_money_with_fonditel'})}</Text3>
                        </Box>
                      }

                      <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                        {screenSize.isTabletOrBigger &&
                          <Text3 regular>{intl.formatMessage({id:'page_calculator_data_money_with_fonditel'})}</Text3>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 12: 8}>
                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>10</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultContribution?.ImpFonditel10 ? formatAmount(resultContribution?.ImpFonditel10, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>

                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>20</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultContribution?.ImpFonditel20 ? formatAmount(resultContribution?.ImpFonditel20, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>

                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>30</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultContribution?.ImpFonditel30 ? formatAmount(resultContribution?.ImpFonditel30, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>
                        </Inline>
                      </Inline>
                    </Stack>

                    {!screenSize.isTabletOrBigger &&
                      <Divider />
                    }

                    <Stack space={8}>
                      {!screenSize.isTabletOrBigger &&
                        <Box className='boxAlignCenter'>
                          <Text3 light>{intl.formatMessage({id:'page_calculator_data_commission_savings'})}</Text3>
                        </Box>
                      }

                      <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                        {screenSize.isTabletOrBigger &&
                          <Text3 regular>{intl.formatMessage({id:'page_calculator_data_commission_savings'})}</Text3>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 12: 8}>
                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>10</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultContribution?.commissionsSavings10 ? formatAmount(resultContribution?.commissionsSavings10, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>

                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>20</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultContribution?.commissionsSavings20 ? formatAmount(resultContribution?.commissionsSavings20, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>

                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>30</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultContribution?.commissionsSavings30 ? formatAmount(resultContribution?.commissionsSavings30, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>
                        </Inline>
                      </Inline>
                    </Stack>
                  </Stack>
                </Box>
              </Box>

              {/* Chart data */}
              <Boxed>
                <Box paddingX={screenSize.isTabletOrBigger ? 80 : 16} paddingY={screenSize.isTabletOrBigger ? 64 : 16}>
                  <Bar options={options} data={dataGraphicContribution} />
                </Box>
              </Boxed>

              </>
              }
            </div>

            <div id="result-transfer">

              {resultTransfer && formData.operationType==='T' &&
              <>
              {/* ** RESULT:: TRANSFER ** */}
              <Box paddingY={screenSize.isTabletOrBigger ? 64 : 32}>
                <Stack space={16}>
                  <Text5>{intl.formatMessage({id:'page_calculator_result_title'})}</Text5>

                  <Text4 light>{intl.formatMessage({id:'page_calculator_result_description_transfer'})}</Text4>
                </Stack>
              </Box>

              {/* historical data */}
              <Box paddingX={screenSize.isDesktopOrBigger ? 80 : (screenSize.isTabletOrBigger ? 40 : 0)} paddingY={screenSize.isTabletOrBigger ? 56 : 16} className='result--box'>
                {screenSize.isTabletOrBigger &&
                  <Stack space={16}>
                    <Inline fullWidth space={0} alignItems='flex-end' className='inlineItems--end'>
                      <Inline space={8}>
                        <Box className='boxWidth'>
                          <Inline space={2}>
                            <Text2 medium color={skinVars.colors.brand}>5</Text2>
                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                          </Inline>
                        </Box>

                        <Box className='boxWidth'>
                          <Inline space={2}>
                            <Text2 medium color={skinVars.colors.brand}>{resultTransfer?.permanenceCommitment}</Text2>
                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                          </Inline>
                        </Box>
                      </Inline>
                    </Inline>
                    <Divider />
                  </Stack>
                }

                <Box paddingTop={24}>
                  <Stack space={screenSize.isTabletOrBigger ? 12 : 16}>
                    <Stack space={8}>
                      {!screenSize.isTabletOrBigger &&
                        <Box className='boxAlignCenter'>
                          <Text3 light>{intl.formatMessage({id:'page_calculator_data_amount_with_another_entity'})}</Text3>
                        </Box>
                      }

                      <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                        {screenSize.isTabletOrBigger &&
                          <Text3 regular>{intl.formatMessage({id:'page_calculator_data_amount_with_another_entity'})}</Text3>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 12: 8}>
                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>5</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultTransfer?.ImpOtra5 ? formatAmount(resultTransfer?.ImpOtra5, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>

                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>{resultTransfer?.permanenceCommitment}</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultTransfer?.ImpOtra1 ? formatAmount(resultTransfer?.ImpOtra1, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>
                        </Inline>
                      </Inline>
                    </Stack>

                    {!screenSize.isTabletOrBigger &&
                      <Divider />
                    }

                    <Stack space={8}>
                      {!screenSize.isTabletOrBigger &&
                        <Box className='boxAlignCenter'>
                          <Text3 light>{intl.formatMessage({id:'page_calculator_data_money_with_fonditel'})}</Text3>
                        </Box>
                      }

                      <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                        {screenSize.isTabletOrBigger &&
                          <Text3 regular>{intl.formatMessage({id:'page_calculator_data_money_with_fonditel'})}</Text3>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 12: 8}>
                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>5</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultTransfer?.ImpFonditel5 ? formatAmount(resultTransfer?.ImpFonditel5, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>

                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>10</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultTransfer?.ImpFonditel1 ? formatAmount(resultTransfer?.ImpFonditel1, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>
                        </Inline>
                      </Inline>
                    </Stack>

                    {!screenSize.isTabletOrBigger &&
                      <Divider />
                    }

                    <Stack space={8}>
                      {!screenSize.isTabletOrBigger &&
                        <Box className='boxAlignCenter'>
                          <Text3 light>{intl.formatMessage({id:'page_calculator_data_commission_savings_with_fonditel'})}</Text3>
                        </Box>
                      }

                      <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                        {screenSize.isTabletOrBigger &&
                          <Text3 regular>{intl.formatMessage({id:'page_calculator_data_commission_savings_with_fonditel'})}</Text3>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 12: 8}>
                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>5</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultTransfer?.commissionsSavings5 ? formatAmount(resultTransfer?.commissionsSavings5, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>

                          <Stack space={8}>
                            {!screenSize.isTabletOrBigger &&
                              <Box className='boxWidth'>
                                <Inline space={2}>
                                  <Text2 medium color={skinVars.colors.brand}>10</Text2>
                                  <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_calculator_result_years'})}</Text2>
                                </Inline>
                              </Box>
                            }

                            <Box className='boxWidth boxWidth--color' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                              <Text3 regular>{resultTransfer?.commissionsSavings1 ? formatAmount(resultTransfer?.commissionsSavings1, true)+'€' : '-'}</Text3>
                            </Box>
                          </Stack>
                        </Inline>
                      </Inline>
                    </Stack>
                  </Stack>
                </Box>
              </Box>


              {/* Chart data */}
              <Boxed>
                <Box paddingX={screenSize.isTabletOrBigger ? 80 : 16} paddingY={screenSize.isTabletOrBigger ? 64 : 16}>
                  <Bar options={options} data={dataGraphicTransfer} />
                </Box>
              </Boxed>
              </>
              }
            </div>

        </ResponsiveLayout>
      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth={true}>
          <ResponsiveLayout>

            <EmptyStateCustom
              largeImageUrl="../assets/img/necesitas-ayuda.jpg"
              title={intl.formatMessage({id:'page_for_investor_empty_state_title'})}
              description={intl.formatMessage({id:'page_for_investor_empty_state_description'})}
              button={
                <ButtonGroup
                    primaryButton={
                      <ButtonPrimary onPress={() => {router.push(getUrlNavigation(ContactPage)); trackEvent('para_inversor-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_commission_savings_calculator_title'})).replaceAll('-', '_')+'-contacto');}}> <img src={'../assets/img/contactar.svg'}/>
                        {intl.formatMessage({id:'page_for_investor_empty_state_action_primary'})}
                      </ButtonPrimary>
                    }
                ></ButtonGroup>
              }
            />

          </ResponsiveLayout>
      </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default CommissionSavingsCalculatorPage;