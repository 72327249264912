import { Box, GridLayout, Hero, Image, Inline, ResponsiveLayout, SkeletonLine, SkeletonRectangle, Stack, Text3, Text4, Text5, skinVars } from '@telefonica/mistica';
import { useLayoutEffect, useState } from 'react';
import { Redirect } from 'react-router';
import '../LandingPage.scss';
import { getPlans } from '../../../../utils/apiclient';
import { getUser } from '../../../../utils/storage';
import { scrollToTop } from '../../../../utils/utils';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import ChipTabs from '../../../../components/chip-tabs/ChipTabs';
import EmptyStateCustom from '../../../../components/empty-state/EmptyStateCustom';
import { UserProduct } from '../../../../entities/products/userProduct';

const RecordEmploymentRegulationPage: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [options, setOptions] = useState(['¿Qué hago con mi plan?', '¿Cuándo puedo cobrar?', 'Ventajas de mantener mi plan', '¿Y si decido cobrar?'] as string[]);
  const [optionsHash, setOptionsHash] = useState(['Home', 'CuandoCobrar', 'Ventajas', 'Cobrar' ] as string[]);
  const [products, setProducts] = useState<UserProduct[]>([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useLayoutEffect(()=>{
    getPlans().then((result) => {
      setProducts(result);
    })
    .finally(() => {

    });
  }, []);

  useLayoutEffect(()=>{
    const fetchData = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setLoading(false);
    };

    if(getUser()){
      products.map((product) => {
        if((product.product && product.product.cdFondo && product.product.cdFondo == '100') || (product.product && product.product.cdPlan && product.product.cdPlan == '210') || (product.product && product.product.cdPlan && product.product.cdPlan == '289')){
          setVisible(true);
        }
      });
    }
    
    fetchData();
  }, [products]);

  useLayoutEffect(()=>{
    if(!visible && !loading){
      setShouldRedirect(true);
    }
  }, [visible, loading]);
 
  useLayoutEffect(()=>{
    if(window.location.hash.length>0 && optionsHash.length>0){
      
      let index = optionsHash.findIndex(item=>item===window.location.hash.replace('#', ''));
      if(index!=-1){
        setSelectedIndex(index);

        setTimeout(function(){
          scrollToTop('content-chips');
        }, 400);
      }
    }

  }, [window.location.hash, options]);

  if (shouldRedirect) {
    return <Redirect to='/404' />;
  }

  return (
      <LayoutPublic
        landing={true}
        header={{small:true}}
        seo={{metaTitle: 'Expediente de Regulación de Empleo', metaDescription:'Expediente de Regulación de Empleo'}}
      >
        { !loading ?
          <>
            <ResponsiveLayout className='content landingPage--content' fullWidth backgroundColor={skinVars.colors.backgroundAlternative}>
              <Box paddingBottom={40}>
                    <Hero
                      background="alternative"
                      title="¿Qué hago con mi plan de pensiones de empleo?"
                      media={<Image src="../assets/img/la_tranquilidad_de_quedarte_en_casa2.jpg" width="100%" height='337px'/>}
                      desktopMediaPosition="right"
                      extra={
                      <div className='html-content landingPageERE--subtitle' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                        `<p style='font-size: 25px;'>La tranquilidad de quedarte en casa.</p>
                        <p>En Fonditel continuamos hoy, como los últimos 30 años, siempre a tu lado</p>
                        `
                      }}></div>
                      }
                    />
              </Box>
            </ResponsiveLayout>

            <ResponsiveLayout className='content' fullWidth backgroundColor={skinVars.colors.background}>
              <div id='content-chips'>
                <ChipTabs
                    selectedIndex={selectedIndex}
                    onChange={setSelectedIndex}
                    tabs={options.map((text) => ({
                      text,
                    }))}
                />
              </div>
              <ResponsiveLayout className={(options.length>0 && options[selectedIndex]==='¿Qué hago con mi plan?' ? 'd-block' : 'd-none')}>
                <GridLayout template='6+6' 
                  left={
                    <div className='html-content' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                        `
                        <p>Si te encuentras entre los empleados adheridos al ERE de Telefónica de España, Telefónica Móviles, o Telefónica Soluciones este 2024, resulta clave comprender que el plan de pensiones de empleo es un instrumento cuya titularidad reside en el empleado. Es decir, tú continúas manteniendo la titularidad de los derechos consolidados de tu plan de pensiones de empleo.</p>
                        <p>Tu plan seguirá siendo gestionado por Fonditel con un seguimiento permanente de la Comisión de Control del plan y continuará generando retornos, de la misma forma que si continuaras como un trabajador en activo.</p>
                        <p>Tú <strong>podrías seguir aportando y seguir desgravando en tu IRPF</strong> si lo consideras conveniente.</p>
                        <p>Dependiendo de tu situación personal, tienes diversas opciones para tu plan de pensiones. Lo fundamental es <strong>no tomar una decisión precipitada</strong> con el mismo, dado que resulta más importante entender las ventajas de cada alternativa y evaluar la línea temporal a corto y largo plazo de tus decisiones.</p>
                        `
                      }}>
                    </div>
                  } 
                  right={
                    <Image src="../assets/img/la_tranquilidad_de_quedarte_en_casa.jpg" width="100%" height='490px'/>
                  }
                />
                <div className='html-content' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                    `
                    <ul>
                      <li>Puedes <strong>mantener tu plan de pensiones de empleo como cualquier trabajador en activo</strong> y seguir obteniendo los retornos correspondientes, beneficiándote de una gestión profesional e independiente y con una cartera de activos muy diversificada. Además, recuerda que éste plan de pensiones tiene una comisión de gestión de 3 a 4 veces inferior a un plan de pensiones individual. Cuentas con la facilidad en el seguimiento de la evolución de tu plan de pensiones a través de la página web <a href="https://www.fonditel.es" target="_blank">www.fonditel.es</a> y en el caso de Empleados de Telefónica de España también a través de <a href="https://www.pensetel.es" target="_blank">www.pensetel.com</a>.</li>
                      <li><p>Además, puedes <strong>realizar aportaciones voluntarias</strong> al plan de pensiones dentro de los límites de aportación anual establecidos en cada momento.</p><p>Puedes seguir aportando hasta 1.500 euros al año. En 2024 además, podrás equiparar las aportaciones del promotor de los meses en los que hayas trabajado.</p></li>
                      <li>Otra opción es movilizar o <strong>traspasar parte (o la totalidad) de lo ahorrado en tu plan de pensiones a otro instrumento de ahorro para la jubilación</strong> (como un plan de pensiones individual de Fonditel u otra entidad), siempre teniendo en cuenta la importancia de mantener y velar por los ahorros para la jubilación que has conseguido a lo largo de tus años en activo.</li>
                      <li>Y una última opción es cobrar del plan de pensiones, bajo determinados supuestos.</li>
                    </ul>
                    <p>Aunque este momento te resulte abrumador por las múltiples opciones que ves y la incertidumbre sobre tu futuro financiero, te invitamos a evaluar con calma cada alternativa y tomar decisiones informadas, que se ajusten lo mejor posible a tus necesidades.</p>
                    <p>Intentaremos ayudarte con esta breve guía y preguntas frecuentes. Además, te recordamos que <strong>estamos aquí para ti</strong>, puedes llamarnos al <strong>91 704 04 01</strong>, escribirnos a contacto@fonditel.es o visitarnos en nuestra oficina en la Ronda de Distrito Telefónica en Madrid (cita previa).</p>
                    <p><em>En Fonditel #SomosTelefónica y tenemos #30añosAtuLado. Hoy como siempre, seguimos siendo tu casa</em></p>
                    `
                  }}>
                </div>

                <EmptyStateCustom largeImageUrl="../assets/img/contactUs.jpg"
                  title='Contactanos'
                  description='Puedes ponerte en contacto con nosotros y concertar una cita para ver tu situación de forma personalizada y conocer las diferentes opciones por las que puedes cobrar a través del 917040401 o de contacto@fonditel.es'
                  />
              </ResponsiveLayout>
              <ResponsiveLayout className={(options.length>0 && options[selectedIndex]==='¿Cuándo puedo cobrar?' ? 'd-block' : 'd-none')}>
                <Stack space={16}>
                  <GridLayout template='6+6' 
                    left={
                        <Image src="../assets/img/Fiscalidad.jpg" width="100%" height='380px'/>
                    } 
                    right={
                      <Stack space={16}>
                        <Text4 regular color={skinVars.colors.textLink}>Si has decidido cobrar, puedes ponerte en contacto con nosotros y concertar una cita para ver tu situación de forma personalizada y conocer las diferentes opciones por las que puedes cobrar a través del 917040401 o de contacto@fonditel.es</Text4>
                        <div className='html-content' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                          `
                          <p>Con respecto a la fiscalidad en el cobro del plan de pensiones, es importante tener en cuenta que los ingresos derivados del plan de pensiones se considerarán como rendimientos del trabajo en tu IRPF, ya sea que el cobro se realice en forma de renta, capital o de manera mixta.</p>
                          <p>Durante los primeros dos años, el importe solicitado se sumaría a la prestación de desempleo percibida, aumentando la base imponible y tributando al tipo medio del IRPF aplicable a esta nueva base.  Por lo tanto, es probable que no sea hasta después de este periodo cuando el cobro del plan comience a ser eficiente desde una perspectiva fiscal.</p>
                          `
                          }}>
                      </div>
                      </Stack>
                    }
                  />
                  
                  <Text3 regular color={skinVars.colors.textLink}>¿Puedo movilizar mis derechos consolidados?</Text3>
                  <div className='html-content' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                        `
                        <p>Una vez extinguida la relación laboral es posible efectuar la movilización de los derechos consolidados, tanto parcial como totalmente. Esa movilización puede hacerse en cualquier momento hasta el momento de la jubilación.</p>
                        <p>Es muy importante estudiar en tu caso particular el impacto fiscal de una movilización, especialmente las que se hagan de forma total.</p>
                        <p><strong>El equipo de Fonditel podrá ayudarte a evaluar y analizar de forma personalizada las posibilidades de cobro buscando una mejor eficiencia y optimización fiscal.</strong></p>
                        <p>Recuerda que, con las opciones planteadas, resulta más conveniente:</p>
                        <ul>
                          <li>Analizar las características del producto en el que voy a estar invertido en cuanto a comisiones, nivel de riesgo, patrimonio, seguimiento de las inversiones y organismos de control de las mismas.</li>
                          <li>Considerar siempre el posible impacto fiscal a corto y medio plazo.</li>
                          <li>Conocer el nivel de servicio y atención personalizada que voy a recibir, estudio del impacto fiscal y contar con una oficina de atención al partícipe propia.</li>
                          <li>Valorar las ofertas posibles en todas sus condiciones o términos (incentivos, comisiones de los productos, obligaciones de permanencia y su posible impacto fiscal)</li>
                        </ul>
                        <p><strong>En caso de estar interesado(a) en un estudio más personalizado, no dudes en contactarnos y podríamos ayudarte a analizar tu caso de manera más detallada.</strong></p>
                        `
                      }}>
                  </div>
                </Stack>
              </ResponsiveLayout>
              <ResponsiveLayout className={(options.length>0 && options[selectedIndex]==='Ventajas de mantener mi plan' ? 'd-block' : 'd-none')}>
                <Stack space={16}>
                  <GridLayout template='6+6' 
                    left={
                      <div className='html-content' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                            `
                            <p>Dependiendo de tu situación personal y tu planificación financiera a corto y largo plazo, una de las opciones que debes evaluar si te has adherido al ERE, consiste en mantener tu plan de pensiones de empleo como hasta ahora e ir analizando su evolución.</p>
                            <p>Recuerda que el objetivo principal de un plan de pensiones es mantener a buen resguardo tu ahorro finalista y poder complementar tu pensión pública llegado este momento. Debes valorar los beneficios y las desventajas de cobrar tu plan de pensiones de empleo ahora (en el corto) y/o en el largo plazo, con vistas a mantener tu calidad de vida en los años por venir.</p>
                            <p>Entre las ventajas clave de mantener tu plan de pensiones de empleo destacan las bajas comisiones de gestión, que además son notablemente inferiores a la media de los planes individuales; la buena rentabilidad y el control del riesgo, supervisado por la Comisión de Control.</p>
                            `
                          }}>
                      </div>
                    } 
                    right={
                      <Image src="../assets/img/Ventajas_con_texto.jpg" width="100%" height='380px'/>
                    }
                  />

                  <Text5 color={skinVars.colors.textLink}>a) Gobernanza del plan de pensiones</Text5>
                  <div className='html-content' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                        `
                        <ul>
                          <li>Tu Plan seguirá siendo gestionado por Fonditel con un <strong>seguimiento continuo de la Comisión de Control</strong> y continuará generando retornos como si estuvieses en activo.</li>
                          <li>Control de riesgo. Si tus ahorros para la jubilación continúan bajo gestión en tu actual plan de pensiones, como partícipe tendrás mayor control del riesgo de tu inversión, que en un plan individual, normalmente con un perfil de riesgo más asimétrico.</li>
                          <li>Garantía de que todo está en manos de expertos y de que tus ahorros seguirán siendo gestionados con la misma profesionalidad y cercanía que nos ha caracterizado en las últimas tres décadas a tu lado. <strong>Contáctanos si tienes dudas o necesitas más información.</strong></li>
                        </ul>
                        `
                      }}>
                  </div>
                  <Text5 color={skinVars.colors.textLink}>b)	Comisiones y condiciones ventajosas</Text5>
                  <div className='html-content' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                        `
                        <p>Como participe de un plan de pensiones de empleo gestionado por Fonditel, seguirás obteniendo los retornos correspondientes, beneficiándote de una gestión profesional e independiente, con una cartera de activos muy diversificada y un patrimonio cercano a los 3.000 millones de euros. Todo gestionado por un equipo gestor con una dilatada experiencia en los mercados financieros, así como en la gestión de activos alternativos y en la inversión sostenible y responsable.</p>
                        <p>Fonditel ha demostrado la consistencia de sus retornos ajustados a riesgo en diferentes períodos de tiempo. Su filosofía de inversión se basa en la gestión activa y en la eficiencia de la gestión de carteras con un enfoque clave en la asignación de activos, diversificación, control de costes y de riesgos. Todo ello con comisiones muy ventajosas.</p>
                        <p>Cuentas además con un servicio de atención personalizada en Fonditel y en la OAP (Oficina de Atención al Partícipe).</p>
                        `
                      }}>
                  </div>

                  <Text5 color={skinVars.colors.textLink}>c)	Atención personalizada</Text5>
                  <div className='html-content' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                        `
                        <p>Recuerda que en Fonditel llevamos 30 años a tu lado gestionando tu plan de pensiones. Somos parte del Grupo Telefónica y por lo tanto, somos independientes de cualquier grupo bancario. Nuestra razón de ser y misión radica en ser expertos en gestionar planes de pensiones como el tuyo.</p>
                        <p>Si te encuentras adherido al ERE, ponemos a tu disposición todos nuestros canales de contacto para resolver tus dudas y atenderte de forma cercana y personalizada. Recuerda que ésta es tu casa y hoy como siempre, seguimos a tu lado.</p>
                        `
                      }}>
                  </div>
                </Stack>
              </ResponsiveLayout>
              <ResponsiveLayout className={(options.length>0 && options[selectedIndex]==='¿Y si decido cobrar?' ? 'd-block' : 'd-none')}>
                <Stack space={16}>
                  <div className='html-content' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                        `
                        <p>Si tras valorar todas tus opciones, decides que la mejor opción según tus necesidades a corto y largo plazo es cobrar de tu plan de pensiones de empleo, debes saber que es posible cobrar en determinados supuestos. En tu caso, analiza el impacto fiscal y cuál es el mejor momento para solicitar el pago.</p>
                        <p>Recuerda que lo puedes movilizar en cualquier momento parcialmente, si sólo quieres cobrar un parte y que es posible que solicitar el pago en un primer momento es probable que no sea eficiente fiscalmente.</p>
                        `
                      }}>
                  </div>
                  <Text5 color={skinVars.colors.textLink}>¿Y si prefiero no cobrar, pero movilizarlo a otro plan de pensiones?</Text5>
                  <GridLayout template='6+6' 
                    left={
                      <div className='html-content' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                          `
                          <p>Ante todo, te recomendamos no tomar decisiones precipitadas, ten en cuenta que la posibilidad de movilización a otro plan, tanto parcial como totalmente, va a ser posible en cualquier momento, hasta el momento de la jubilación.</p>
                          <p><strong>Ten en consideración las posibles exigencias de permanencia durante unos años, así como las comisiones de gestión de los planes individuales, significativamente superiores a las del plan de pensiones de empleo.</strong></p>
                          <p>Por todo ello, antes de tomar la decisión de movilizar tu plan de pensiones de empleo a un plan de pensiones individual de otra entidad, evalúa todos los factores que afectarán a corto y largo plazo el importe final de tus ahorros.</p>
                          `
                        }}>
                      </div>
                    } 
                    right={
                      <Image src="../assets/img/Rescate.jpg" width="80%" height='280px'/>
                    }
                  />
                </Stack>
              </ResponsiveLayout>
              {/* 
              <ResponsiveLayout className={(options.length>0 && options[selectedIndex]==='Q&A ETE' ? 'd-block' : 'd-none')}>
                <Box paddingLeft={screenSize.isDesktopOrBigger ? 80 : 0} paddingBottom={screenSize.isDesktopOrBigger ? 80 : 64} className='pane-menu'>
                  <Accordion title='¿Qué ocurre con mi plan de empleo tras el ERE?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Continuarás manteniendo la titularidad de los derechos consolidados de tu plan de pensiones. El plan seguirá siendo gestionado y generando retornos igual que si estuvieras en activo.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Qué ocurre con las aportaciones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'La empresa dejará de aportar, aunque tú podrías seguir aportando y seguir desgravando si lo consideras conveniente. Puedes aportar hasta 1.500 euros al año. En 2024 además, podrás equiparar las aportaciones del promotor de los meses en los que hayas trabajado de forma efectiva e incrementarlo en 1.500 euros, siempre que no superen en total los 10.000 euros al año.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Qué puedo hacer con mi plan tras el ERE?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>Dependiendo de tu situación personal existen diversas opciones. Lo fundamental es no tomar una decisión precipitada con el mismo, dado que no hay ninguna prisa y puedes reflexionar. A continuación, te indicamos las diferentes opciones que puedes hacer con tu plan de pensiones de empleo:</p> <ul><li>Mantener tu plan de pensiones de empleo como cualquier trabajador en activo y seguir obteniendo los retornos correspondientes, beneficiándote de una gestión profesional e independiente y con una cartera de activos muy diversificada. Además, este plan de pensiones tiene una comisión de gestión de 3 a 4 veces inferior a un plan de pensiones individual.</li><li>Realizar aportaciones voluntarias al plan dentro de los límites de aportación anual establecidos en cada momento.</li><li>Realizar una movilización parcial o total a otro plan de pensiones individual de Fonditel o de otra entidad.</li></ul>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Puedo mantener mi plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Efectivamente, una de las opciones es mantener tu plan de pensiones de empleo como cualquier trabajador en activo y seguir obteniendo los retornos correspondientes.'}}></div>
                  </Accordion>

                  <Accordion  title='¿Cómo puedo conocer la situación de mi plan de pensiones una vez desvinculado?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Dándote de alta en la página web www.fonditel.es,o a través del servicio de atención al cliente 91 704 0401 o contacto@fonditel.es. Y en la Oficina de Atención al participe (OAP) para los empleados de Telefónica de España, a través del teléfono 900 210 666 o el email info@pensetel.com.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Por qué mantener mi plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>Muy bajas comisiones de gestión y notablemente inferiores a la media de los planes individuales, buena rentabilidad, control del riesgo, supervisado por la Comisión de Control. Como participe del plan de pensiones de empleo de Telefónica de España como cualquier trabajador en activo, seguirás obteniendo los retornos correspondientes, beneficiándote de una gestión profesional e independiente, con una cartera de activos muy diversificada y un patrimonio cercano a los 3.000 millones de euros. Además, cuentas con un servicio de atención personalizada. Cuentas con un equipo gestor con una dilatada experiencia en los mercados financieros, así como en la gestión de activos alternativos y en la inversión sostenible y responsable.</p><p>Fonditel ha demostrado la consistencia de sus retornos ajustados a riesgo en diferentes períodos de tiempo. Su filosofía de inversión se basa en la gestión activa y en la eficiencia de la gestión de carteras con un enfoque clave en la asignación de activos, diversificación, control de costes y de riesgos. Así la rentabilidad del plan de pensiones de 2023 ha sido del 6,35% y la rentabilidad anualizada desde su lanzamiento hace más de 30 años es casi del 4%.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='Para empleados de Telefónica de España'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>El plan de pensiones de Empleados de Telefónica de España tiene una comisión de gestión del 0.329%, que es entre 3 y 4 veces inferior a la media de los planes de pensiones individuales. Los planes de pensiones individuales de la industria pueden tener una comisión de gestión del 0,8% al 1,5% dependiendo de la categoría, siendo la media de las comisiones de gestión de los planes de pensiones individuales del 1,2%.</p><p>Si un participe tiene 100.000 euros en su plan de pensiones de Empleados de Telefónica de España, vamos a comparar el ahorro en comisiones a 5, 10 y 15 años al mantener su plan de pensiones de Empleados de Telefónica de España versus a tenerlo en un plan de pensiones individual.</p><p>La diferencia de comisión de gestión es de 0,831% con la media del sector. Si es con la máxima del 1,5% la diferencia sería del 1,17%.</p><p>Recuerda que esto es un ejemplo a efectos ilustrativos.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Puedo cobrar del plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Sí, es posible cobrar del plan de pensiones en determinados supuestos, pero tienes que analizar el impacto fiscal y cuál es el mejor momento para solicitar el pago. Hacerlo en un primer momento es probable que no sea eficiente fiscalmente y debes recordar que también lo puedes movilizar parcialmente en cualquier momento si se quisieras cobrar una parte. En el caso de Empleados de Telefónica de España se puede cobrar a partir de los 60 años.'}}></div>
                  </Accordion>

                  <Accordion  title='¿Cuándo puedo rescatar los derechos consolidados de mi plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>Cuando accedas a una pensión de la Seguridad Social, bien sea por jubilación anticipada u ordinaria, o bien por incapacidad. </p><p>Existe la posibilidad de rescate a partir de los 60 años en caso de no mantener una actividad laboral, sin perjuicio de estar en situación asimilada al alta en la Seguridad Social (alta en el Convenio Especial). Asimismo, en los casos de que te encuentres afectado por una enfermedad grave, o tu cónyuge, alguno de tus ascendientes o descendientes en primer grado o persona que, en régimen de tutela o acogimiento, conviva contigo o de ti dependa.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Si tengo 58 años?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Lo razonable es esperar al segundo año que es fiscalmente el más favorable. Ya habrás cumplido los 60 y podrás solicitar el cobro.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Si tengo menos de 58 años?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Podrías traspasar el importe a cobrar a un plan individual de Fonditel y solicitar el pago, al haberse extinguido la relación laboral por ERE.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Como puedo cobrar si no tengo 60 años o no me he jubilado?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Podrías traspasar el importe que quieras cobrar a un plan individual de Fonditel y desde ahí, solicitar el pago, al haberse extinguido la relación laboral por ERE. Por lo tanto, el impacto de la fiscalidad puede ser muy relevante en función del momento y de la forma de cobro (renta, capital o mixta) y merece una plantificación para que te resulte eficiente'}}></div>
                  </Accordion>

                  <Accordion  title='¿Puedo rescatar el plan de pensiones por desempleo de larga duración?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'El Reglamento del plan de pensiones de Empleados de Telefónica de España impide el rescate por este motivo, al percibir prestaciones por desempleo en su nivel contributivo, o rentas pactadas con la empresa superiores al duplo del Indicador Público de Renta de Efectos Múltiples (IPREM).'}}></div>
                  </Accordion>
                  <Accordion  title='¿Puedo disponer del importe de mi plan de pensiones por enfermedad grave?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Sí, tendrás la facultad de hacer efectivos tus derechos consolidados o parte de éstos, en el caso de que te veas afectado por una enfermedad grave, o bien tu cónyuge o alguno de tus ascendientes o descendientes en primer grado. Siempre cumpliendo con los requisitos establecidos por la normativa. En tanto no den lugar a la percepción de una prestación por incapacidad permanente en cualquiera de sus grados (conforme al régimen de la Seguridad Social) y siempre que supongan para ti una disminución de tu renta disponible por aumento de gastos o reducción de tus ingresos.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Podré disponer de los importes del plan de pensiones correspondientes a las aportaciones que tengan al menos 10 años de antigüedad?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>No, el plan de pensiones de empleo de Telefónica no contempla la posibilidad de efectuar rescates de las aportaciones que tengan 10 o más años de antigüedad. </p><p>Siempre podrías estudiar la posible movilización de una parte a un plan de pensiones individual de Fonditel o de otra entidad para poder cobrar una parte por esta contingencia (10 años de antigüedad), analizando previamente el posible impacto fiscal de ese cobro.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Qué fiscalidad tiene el rescate de mi plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Los ingresos procedentes del plan de pensiones tendrán consideración de rendimientos del trabajo, tanto si el rescate se produce en forma de renta, en forma de capital o mixta.'}}></div>
                  </Accordion>

                  <Accordion  title='¿Fiscalmente cuál es el mejor momento para cobrar de mi plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>A partir del segundo año, una vez se extinga la prestación por desempleo. En ese momento estarás cobrando sólo la indemnización de Telefonica.</p><p>En el ámbito de un ERE la indemnización percibida está exenta del IRPF dentro de ciertos límites: la cantidad menor entre la indemnización calculada según el Estatuto de los Trabajadores o 180.000 euros.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Y si necesito o quiero cobrar antes del segundo año?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'El importe que solicites se sumará a la prestación por desempleo que estés cobrando. El montante total tributará en el IRPF al tipo medio que resulte en función de la base imponible generada.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Puedo movilizar los derechos consolidados?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>Sí, una vez extinguida la relación laboral es posible efectuar la movilización de los derechos consolidados, tanto parcial como totalmente. Esa movilización puedes hacerla, en cualquier momento, hasta el momento de la jubilación.</p><p>Es muy importante estudiar en tu caso el impacto fiscal de una movilización, especialmente si decides hacerla de forma total. El equipo de Fonditel podrá ayudarte a evaluar y analizar de forma personalizada las posibilidades de cobro, buscando una mejor eficiencia y optimización fiscal.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Qué es lo que me conviene hacer?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<ul><li>Considerar siempre el posible impacto fiscal a corto y medio plazo.</li><li>Analizar las características del producto en el que vas a estar invertido en cuanto a comisiones, nivel de riesgo, patrimonio, seguimiento de las inversiones y organismos de control de las mismas.</li><li>Conocer el nivel de servicio y atención personalizada que vas a recibir, con estudio del impacto fiscal y con una oficina de atención al participe propia.</li><li>Valorar las ofertas posibles en todas sus condiciones o términos (incentivos, comisiones de los productos, obligaciones de permanencia y su posible impacto fiscal).</li></ul>'}}></div>
                  </Accordion>

                  <Accordion  title='¿Cuál es el impacto fiscal del rescate?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>¿Cuál es el impacto fiscal del rescate? Telefónica a va a abonar como indemnización un porcentaje del sueldo hasta los 65 años. Adicionalmente, el trabajado recibirá una prestación por desempleo durante 24 meses que puede tributar.</p><p>En el ámbito de un ERE la indemnización percibida está exenta del IRPF dentro de ciertos límites: la cantidad menor entre la indemnización calculada según el Estatuto de los Trabajadores o 180.000 euros. Superados esos límites de indemnización, se empieza a tributar en el IRPF, aunque las cantidades percibidas pueden tener una reducción del 30%.</p><p>A partir del segundo año sólo recibirás indemnización de Telefónica. Entonces es a partir del segundo año, cuando resulta más eficiente rescatar parte de tu plan de pensiones, hasta el límite anteriormente mencionado. Superada esta cifra, la indemnización pendiente de recibir tributa, por lo que hay que hacer números en caso de rescate de tu plan de pensiones</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Movilizarlo a otro plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>Ante todo, no tomes decisiones precipitadas. Recuerda que la opción de movilizar a otro plan va a ser posible, en cualquier momento, hasta el momento de tu jubilación.</p><p>Es muy posible que recibas suculentas ofertas de entidades bancarias para el traspaso del plan de pensiones de empleo a un plan individual. Esas ofertas van a seguir ahí en los próximos años, dado que el mercado de planes de pensiones individuales apenas crece por el límite de aportación a 1.500 euros por año. Las ofertas normalmente están condicionadas a la permanencia durante unos años.</p><p>Si como vemos, fiscalmente es razonable cobrar del plan a partir del año 2, es probable que se pierdan las bonificaciones. Además, los planes de destino de estas ofertas suelen tener comisiones de gestión que triplican la de tu plan de pensiones de empleo.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Tiene alguna ventaja permanecer en Fonditel?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Sí, Telefónica te realizará una aportación extraordinaria de 1.000 euros a tu plan de pensiones de si te has adherido al plan de bajas y tienes 63 años, siempre y cuando no hayas movilizado ni rescatado cantidad alguna de tu plan de pensiones de empleo.'}}></div>
                  </Accordion>
                  <Accordion  title='¿A quién se destinarían los derechos económicos en caso de mi fallecimiento?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'El plan de pensiones no forma parte de la masa hereditaria; por lo tanto, los derechos económicos corresponderían a tus beneficiarios designados en el impreso correspondiente o a falta de designación expresa, conforme a lo establecido en el Reglamento del plan de pensiones correspondiente.'}}></div>
                  </Accordion>
                </Box>
              </ResponsiveLayout>
              <ResponsiveLayout className={(options.length>0 && options[selectedIndex]==='Q&A Móviles y Soluciones' ? 'd-block' : 'd-none')}>
                <Box paddingLeft={screenSize.isDesktopOrBigger ? 80 : 0} paddingBottom={screenSize.isDesktopOrBigger ? 80 : 64} className='pane-menu'>
                  <Accordion title='¿Qué ocurre con mi plan de empleo tras el ERE?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Continuarás manteniendo la titularidad de los derechos consolidados de tu plan de pensiones. El plan seguirá siendo gestionado y generando retornos igual que si estuvieras en activo.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Qué ocurre con las aportaciones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'La empresa dejará de aportar, aunque tú podrías seguir aportando y seguir desgravando si lo consideras conveniente. Puedes aportar hasta 1.500 euros al año. En 2024 además, podrás equiparar las aportaciones del promotor de los meses en los que hayas trabajado de forma efectiva e incrementarlo en 1.500 euros, siempre que no superen en total los 10.000 euros al año.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Qué puedo hacer con mi plan tras el ERE?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>Dependiendo de tu situación personal existen diversas opciones. Lo fundamental es no tomar una decisión precipitada con el mismo, dado que no hay ninguna prisa y puedes reflexionar. A continuación, te indicamos las diferentes opciones que puedes hacer con tu plan de pensiones de empleo:</p> <ul><li>Mantener tu plan de pensiones de empleo como cualquier trabajador en activo y seguir obteniendo los retornos correspondientes, beneficiándote de una gestión profesional e independiente y con una cartera de activos muy diversificada. Además, este plan de pensiones tiene una comisión de gestión de 3 a 4 veces inferior a un plan de pensiones individual.</li><li>Realizar aportaciones voluntarias al plan dentro de los límites de aportación anual establecidos en cada momento.</li><li>Realizar una movilización parcial o total a otro plan de pensiones individual de Fonditel o de otra entidad.</li></ul>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Puedo mantener mi plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Efectivamente, una de las opciones es mantener tu plan de pensiones de empleo como cualquier trabajador en activo y seguir obteniendo los retornos correspondientes.'}}></div>
                  </Accordion>

                  <Accordion  title='¿Cómo puedo conocer la situación de mi plan de pensiones una vez desvinculado?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Dándote de alta en la página web www.fonditel.es,o a través del servicio de atención al cliente 91 704 0401 o contacto@fonditel.es.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Por qué mantener mi plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>Muy bajas comisiones de gestión y notablemente inferiores a la media de los planes individuales, buena rentabilidad, control del riesgo, supervisado por la Comisión de Control.</p><p>Como participe del plan de pensiones de empleo de Telefónica Móviles o Telefónica Soluciones como cualquier trabajador en activo, seguirás obteniendo los retornos correspondientes, beneficiándote de una gestión profesional e independiente, con una cartera de activos muy diversificada. </p><p>Además, cuentas con un servicio de atención personalizada. Cuentas con un equipo gestor con una dilatada experiencia en los mercados financieros, así como en la gestión de activos alternativos y en la inversión sostenible y responsable.</p><p>Fonditel ha demostrado la consistencia de sus retornos ajustados a riesgo en diferentes períodos de tiempo. Su filosofía de inversión se basa en la gestión activa y en la eficiencia de la gestión de carteras con un enfoque clave en la asignación de activos, diversificación, control de costes y de riesgos. </p>'}}></div>
                  </Accordion>
                  <Accordion  title='Para empleados de Telefónica Móviles'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>El plan de pensiones de Empleados de Telefónica Móviles tiene una comisión de gestión del 0.329%, que es entre 3 y 4 veces inferior a la media de los planes de pensiones individuales. </p><p>Los planes de pensiones individuales de la industria pueden tener una comisión de gestión del 0,8% al 1,5% dependiendo de la categoría, siendo la media de las comisiones de gestión de los planes de pensiones individuales del 1,2%.</p><p>Si un participe tiene 100.000 euros en su plan de pensiones de Empleados de Telefónica Móviles, vamos a comparar el ahorro en comisiones a 5, 10 y 15 años al mantener su plan de pensiones de Empleados de Telefónica Móviles versus a tenerlo en un plan de pensiones individual.</p><p>La diferencia de comisión de gestión es de 0,831% con la media del sector. Si es con la máxima del 1,5% la diferencia sería del 1,17%.</p><p>Recuerda que esto es un ejemplo a efectos ilustrativos.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='Para empleados de Telefónica Soluciones'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>El plan de pensiones de Telefónica Soluciones tiene una comisión de gestión del 0,5%, que es 3 veces inferior a la comisión de gestión máxima de los planes de pensiones individuales (1,5%). </p><p>Los planes de pensiones individuales de la industria pueden tener una comisión de gestión del 0,8% al 1,5% dependiendo de la categoría, siendo la media de las comisiones de gestión de los planes de pensiones individuales del 1,2%.</p><p>Si un participe tiene 100.000 euros en su plan de pensiones de Telefónica Soluciones, vamos a comparar el ahorro en comisiones a 5, 10 y 15 años al mantener su plan de pensiones de Telefónica Soluciones versus a tenerlo en un plan de pensiones individual.</p><p>La diferencia de comisión de gestión es de 0,66% con la media del sector. Si es con la máxima del 1,5% la diferencia sería del 1%.</p><p>Recuerda que esto es un ejemplo a efectos ilustrativos.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Puedo cobrar del plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Sí, es posible cobrar del plan de pensiones en determinados supuestos, pero tienes que analizar el impacto fiscal y cuál es el mejor momento para solicitar el pago. Hacerlo en un primer momento es probable que no sea eficiente fiscalmente y debes recordar que también lo puedes movilizar parcialmente en cualquier momento si se quisieras cobrar una parte.'}}></div>
                  </Accordion>

                  <Accordion  title='¿Cuándo puedo rescatar los derechos consolidados de mi plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>Cuando accedas a una pensión de la Seguridad Social, bien sea por jubilación anticipada u ordinaria, o bien por incapacidad. </p><p>Existe la posibilidad de rescate a partir de los 60 años en caso de no mantener una actividad laboral, sin perjuicio de estar en situación asimilada al alta en la Seguridad Social (alta en el Convenio Especial). Para los partícipes de Telefónica Soluciones es posible el rescate, cualquiera que sea la edad, cuando se extinga la relación laboral y se pase a situación legal de desempleo por el ERE.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Puedo rescatar el plan de pensiones por desempleo de larga duración?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Se podría rescatar en el PPE Telefónica Móviles España y <strong>PPE Telefónica Soluciones</strong>, siempre que se cumpla con los requisitos de hallarse en situación legal de desempleo, no tener derecho a las prestaciones por desempleo a nivel contributivo y estar inscrito en el momento de la solicitud como demandante de empleo.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Puedo disponer del importe de mi plan de pensiones por enfermedad grave?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Sí, tendrás la facultad de hacer efectivos tus derechos consolidados o parte de éstos, en el caso de que te veas afectado por una enfermedad grave, o bien tu cónyuge o alguno de tus ascendientes o descendientes en primer grado. Siempre cumpliendo con los requisitos establecidos por la normativa. En tanto no den lugar a la percepción de una prestación por incapacidad permanente en cualquiera de sus grados (conforme al régimen de la Seguridad Social) y siempre que supongan para ti una disminución de tu renta disponible por aumento de gastos o reducción de tus ingresos.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Podré disponer de los importes del plan de pensiones correspondientes a las aportaciones que tengan al menos 10 años de antigüedad?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'No, el plan de pensiones de empleo de Telefónica Móviles y Telefónica Soluciones no contemplan la posibilidad de efectuar rescates de las aportaciones que tengan 10 o más años de antigüedad. Siempre podrías estudiar la posible movilización de una parte a un plan de pensiones individual de Fonditel o de otra entidad para poder cobrar una parte por esta contingencia (10 años de antigüedad), analizando previamente el posible impacto fiscal de ese cobro.'}}></div>
                  </Accordion>

                  <Accordion  title='¿Qué fiscalidad tiene el rescate de mi plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Los ingresos procedentes del plan de pensiones tendrán consideración de rendimientos del trabajo, tanto si el rescate se produce en forma de renta, en forma de capital o mixta.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Fiscalmente cuál es el mejor momento para cobrar de mi plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>A partir del segundo año, una vez se extinga la prestación por desempleo. En ese momento estarás cobrando sólo la indemnización de Telefonica.</p><p>En el ámbito de un ERE la indemnización percibida está exenta del IRPF dentro de ciertos límites: la cantidad menor entre la indemnización calculada según el Estatuto de los Trabajadores o 180.000 euros.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Y si necesito o quiero cobrar antes del segundo año?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'El importe que solicites se sumará a la prestación por desempleo que estés cobrando. El montante total tributará en el IRPF al tipo medio que resulte en función de la base imponible generada.'}}></div>
                  </Accordion>
                  <Accordion  title='¿Puedo movilizar los derechos consolidados?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>Sí, una vez extinguida la relación laboral es posible efectuar la movilización de los derechos consolidados, tanto parcial como totalmente. Esa movilización puedes hacerla, en cualquier momento, hasta el momento de la jubilación.</p><p>Es muy importante estudiar en tu caso el impacto fiscal de una movilización, especialmente si decides hacerla de forma total. El equipo de Fonditel podrá ayudarte a evaluar y analizar de forma personalizada las posibilidades de cobro, buscando una mejor eficiencia y optimización fiscal.</p>'}}></div>
                  </Accordion>

                  <Accordion  title='¿Qué es lo que me conviene hacer?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<ul><li>Considerar siempre el posible impacto fiscal a corto y medio plazo</li><li>Analizar las características del producto en el que vas a estar invertido en cuanto a comisiones, nivel de riesgo, patrimonio, seguimiento de las inversiones y organismos de control de las mismas. </li><li>Conocer el nivel de servicio y atención personalizada que vas a recibir, con estudio del impacto fiscal y con una oficina de atención al participe propia.</li><li>Valorar las ofertas posibles en todas sus condiciones o términos (incentivos, comisiones de los productos, obligaciones de permanencia y su posible impacto fiscal).</li></ul>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Cuál es el impacto fiscal del rescate?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>Telefónica a va a abonar como indemnización un porcentaje del sueldo hasta los 65 años. Adicionalmente, el trabajado recibirá una prestación por desempleo durante 24 meses que puede tributar. </p><p>En el ámbito de un ERE la indemnización percibida está exenta del IRPF dentro de ciertos límites: la cantidad menor entre la indemnización calculada según el Estatuto de los Trabajadores o 180.000 euros. Superados esos límites de indemnización, se empieza a tributar en el IRPF, aunque las cantidades percibidas pueden tener una reducción del 30%.</p><p>A partir del segundo año sólo recibirás indemnización de Telefónica. Entonces es a partir del segundo año, cuando resulta más eficiente rescatar parte de tu plan de pensiones, hasta el límite anteriormente mencionado. Superada esta cifra, la indemnización pendiente de recibir tributa, por lo que hay que hacer números en caso de rescate de tu plan de pensiones.</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Movilizarlo a otro plan de pensiones?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: '<p>Ante todo, no tomes decisiones precipitadas. Recuerda que la opción de movilizar a otro plan va a ser posible, en cualquier momento, hasta el momento de tu jubilación.</p><p>Es muy posible que recibas suculentas ofertas de entidades bancarias para el traspaso del plan de pensiones de empleo a un plan individual. Esas ofertas van a seguir ahí en los próximos años, dado que el mercado de planes de pensiones individuales apenas crece por el límite de aportación a 1.500 euros por año. Las ofertas normalmente están condicionadas a la permanencia durante unos años.</p><p>Si como vemos, fiscalmente es razonable cobrar del plan a partir del año 2, es probable que se pierdan las bonificaciones. Además, los planes de destino de estas ofertas suelen tener comisiones de gestión que triplican la de tu plan de pensiones de empleo</p>'}}></div>
                  </Accordion>
                  <Accordion  title='¿Tiene alguna ventaja permanecer en Fonditel?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'Sí, Telefónica te realizará una aportación extraordinaria de 1.000 euros a tu plan de pensiones de si te has adherido al plan de bajas y tienes 63 años, siempre y cuando no hayas movilizado ni rescatado cantidad alguna de tu plan de pensiones de empleo.'}}></div>
                  </Accordion>
                  <Accordion  title='¿A quién se destinarían los derechos económicos en caso de mi fallecimiento?'>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: 'El plan de pensiones no forma parte de la masa hereditaria; por lo tanto, los derechos económicos corresponderían a tus beneficiarios designados en el impreso correspondiente o a falta de designación expresa, conforme a lo establecido en el Reglamento del plan de pensiones correspondiente.'}}></div>
                  </Accordion>
                </Box>
              </ResponsiveLayout>
              */}
            </ResponsiveLayout>
          </>
          :
          <>
            <ResponsiveLayout className='content landingPage--content' fullWidth backgroundColor={skinVars.colors.backgroundAlternative}>
              <Box paddingTop={40}>
                <GridLayout template='6+6' 
                left={
                  <Box padding={80}>
                    <Stack space={24}>
                      <SkeletonRectangle height={100} width={600}></SkeletonRectangle>
                      <SkeletonLine width={600}></SkeletonLine> 
                      <SkeletonLine width={600}></SkeletonLine> 
                      <SkeletonLine width={600}></SkeletonLine>
                    </Stack>
                  </Box>
                } right={
                  <SkeletonRectangle height={300} width={600}></SkeletonRectangle>
                }/>
              </Box>
            </ResponsiveLayout>
            <ResponsiveLayout className='content landingPage--content' fullWidth backgroundColor={skinVars.colors.background}>
              <Box padding={80}>
                <div className='landingPageERE--chipSkeleton'>
                  <Inline space={16}>
                    <SkeletonRectangle height={25} width={150}></SkeletonRectangle>
                    <SkeletonRectangle height={25} width={150}></SkeletonRectangle>
                    <SkeletonRectangle height={25} width={150}></SkeletonRectangle>
                    <SkeletonRectangle height={25} width={150}></SkeletonRectangle>
                  </Inline>
                </div>
              </Box>
            </ResponsiveLayout>
          </>
        }
      </LayoutPublic> 
  );
};

export default RecordEmploymentRegulationPage;