import { useIonRouter } from '@ionic/react';

import { TagNews } from '../../../entities/news/tag.model';
import { Tag, Touchable } from '@telefonica/mistica';
import { getUrlNavigation } from '../../../utils/navigation';

import NewsPage from '../../../pages/public/news/NewsPage';

import './TagNew.scss';
import { isBot, trackEvent } from '../../../utils/utils';

interface TagNewInterface {
    tag: TagNews,
}

const TagNewContainer: React.FC<TagNewInterface> = (prop) => {

const router = useIonRouter();

  return (
      isBot ?
        <Touchable href={getUrlNavigation(NewsPage)+'/'+prop.tag.slug}>
          <Tag type="active">
            {prop.tag.name}
          </Tag>
        </Touchable>
      :
        <Touchable onPress={()=>{router.push(getUrlNavigation(NewsPage)+'/'+prop.tag.slug); trackEvent('noticias-tag-'+prop.tag.name);}}>
          <Tag type="active">
            {prop.tag.name}
          </Tag>
        </Touchable>
  );
};

export default TagNewContainer;