import { Circle, IconArrowLineUpRegular, IconHeadphonesLight, Inline, ResponsiveLayout, Row, TextLink, Touchable, skinVars } from '@telefonica/mistica';
import React from 'react';

import './ScrollToTop.scss';
import { isNativeApp } from '../../utils/utils';
import { getUrlNavigation } from '../../utils/navigation';
import ContactPage from '../../pages/public/contact/ContactPage';

interface ScrollToTopProps {
    visible: boolean,
    showFooterMenu?:boolean
}

const ScrollToTop: React.FC<ScrollToTopProps> = (prop) => {
    const handleClickScrollToTop = () =>{
        let element = document.getElementById("header") ? document.getElementById("header") : document.getElementById("header-private");
        element?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    }

return (<div className={'sticky-scroll-to-top '+(prop.visible && !isNativeApp ? 'visible' : '')+(prop.showFooterMenu ? ' with-footer-menu' : '')}>
            <div className='button-scroll-to-top'>
                <Inline space={24} >
                    <Touchable href={getUrlNavigation(ContactPage)}>
                        <Circle size={40} backgroundColor={skinVars.colors.buttonPrimaryBackground}>
                            <IconHeadphonesLight color={skinVars.colors.textButtonPrimary}></IconHeadphonesLight>
                        </Circle>
                    </Touchable>
                    <Touchable onPress={()=>handleClickScrollToTop()}>
                        <Circle size={40} backgroundColor={skinVars.colors.buttonPrimaryBackground}>
                            <IconArrowLineUpRegular color={skinVars.colors.textButtonPrimary}></IconArrowLineUpRegular>
                        </Circle>
                    </Touchable>
                </Inline>
            </div>
        </div>
    );
};

export default ScrollToTop;
