import { useIntl } from "react-intl";


export const useTranslate = () => {

    const intl = useIntl();

    const formatMonth = (month:number|string, short?:boolean) => {
        return intl.formatMessage({id:'months_'+(short ? 'short_' :'')+month.toString().padStart(2, '0')})
    };
	return { formatMonth };
}