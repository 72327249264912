import { useIntl } from 'react-intl';


import { Box, EmptyState, ResponsiveLayout } from '@telefonica/mistica';

import './EmptyStateCustom.scss';
interface EmptyStateCustomProps {
    largeImageUrl: string,
    title: string,
    description?:string,
    button?:any
}

const EmptyStateCustom: React.FC<EmptyStateCustomProps> = (prop) => {

const intl = useIntl()

  return (
    <ResponsiveLayout className='container' fullWidth={true} backgroundColor={"transparent"}>
        <ResponsiveLayout fullWidth className='content' backgroundColor={"transparent"}>
            <Box paddingY={48} className='empty-state-custom-box'>
                <EmptyState
                    largeImageUrl={prop.largeImageUrl}
                    title={prop.title}
                    description={prop.description}
                    button={prop.button}
                />
            </Box>
        </ResponsiveLayout>
    </ResponsiveLayout>
  );
};

export default EmptyStateCustom;