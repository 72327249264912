import { Box, Boxed, ButtonPrimary, dialog, Form, IconAlertLight, IconInformationUserLight, Inline, RadioButton, RadioGroup, ResponsiveLayout, skinVars, Stack, Text2, Text4, Text5, useScreenSize } from '@telefonica/mistica';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import { setOnlineCorrespondenceType } from '../../../../../utils/apiclient';
import { addUser, getUser, removeCurrentUserProduct } from '../../../../../utils/storage';
import { getPlatform, trackEvent } from '../../../../../utils/utils';
import OnlineCorrespondenceCustomerPage from './OnlineCorrespondenceCustomerPage';

const ConfigureOnlineCorrespondenceCustomerPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
  const user = getUser();
  const initFormData = {'optionCorrespondence':user.swSoloCorrespondenciaOnline ? 'online' : 'correo-postal'}
  const [formData, setFormData] = useState(initFormData);




  useEffect(()=>{
    removeCurrentUserProduct();
  }, []);

  const handleSubmit = (data: any): Promise<void> =>
  new Promise((resolve) => {
    let online = true;

    if(data.optionCorrespondence === "correo-postal"){
      online = false;
    }
    trackEvent('perfil-correspondencia-configurar'+(online ? 'mail' : 'postal'));

    setOnlineCorrespondenceType({soloOnline: online, source: getPlatform()}).then(response => {
      if(response && response.result === true){
        dialog({
          title: intl.formatMessage({id:'page_customer_online_correspondence_service_dialog_ok_title'}),
          message: intl.formatMessage({id:'page_customer_online_correspondence_service_dialog_ok_message'}),
          acceptText: intl.formatMessage({id:'page_customer_online_correspondence_service_dialog_button'}),
          forceWeb: true,
          showCancel: false,
          icon: <IconInformationUserLight color={skinVars.colors.brand} />,
        });
        const user = getUser();
        if(user){
            user.swSoloCorrespondenciaOnline = online;
            addUser(user);
        }
      }else{
        dialog({
          title: intl.formatMessage({id:'page_customer_online_correspondence_service_dialog_ko_title'}),
          message: intl.formatMessage({id:'page_customer_online_correspondence_service_dialog_ko_message'}),
          acceptText: intl.formatMessage({id:'page_customer_online_correspondence_service_dialog_button'}),
          forceWeb: true,
          showCancel: false,
          icon: <IconAlertLight color={skinVars.colors.buttonDangerBackground} />,
        });
      }
      resolve();
    });
  });

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_customer_online_correspondence_service_seo_title'})}}
      title= {intl.formatMessage({id:'page_customer_online_correspondence_service_title'})}
      parents={[{title: intl.formatMessage({id:'page_customer_online_correspondence_service_parents_title'}), item: OnlineCorrespondenceCustomerPage}]}
      pageInfo={{option: process.env.REACT_APP_MENU_CORRESPONDENCIA_ONLINE, role:'client'}}
      showBack
    >
      <ResponsiveLayout className='inner first'>
        {screenSize.isDesktopOrBigger &&
          <Box paddingBottom={32}>
            <Text5>{intl.formatMessage({id:'page_customer_online_correspondence_service_configure'})}</Text5>
          </Box>
        }
        <Box paddingBottom={16}>
          <Text4 medium>{intl.formatMessage({id:'page_customer_online_correspondence_service_configure_description'})}</Text4>
        </Box>


        <Box paddingBottom={24}>
          <Form onSubmit={handleSubmit} initialValues={formData}>
            <Stack space={24}>
              <RadioGroup name="optionCorrespondence">
                <Inline space={screenSize.isDesktopOrBigger ? 32 : 16} wrap>
                  <RadioButton
                    value="online"
                    render={({ controlElement, checked, labelId }:any) => (
                      <Boxed className={'rbx-option '+ (checked ? 'active' : '')}>
                        <Box width={screenSize.isDesktopOrBigger ? 184 : 160} padding={16}>
                          <Inline space={8} alignItems='center'>
                            {controlElement}
                            <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_customer_online_correspondence_service_online'})}</Text2>
                          </Inline>
                        </Box>
                      </Boxed>
                    )}
                  />

                  <RadioButton
                      value="correo-postal"
                      render={({ controlElement, checked, labelId }:any) => (
                        <Boxed className={'rbx-option '+ (checked ? 'active' : '')}>
                          <Box width={screenSize.isDesktopOrBigger ? 184 : 160} padding={16}>
                            <Inline space={8} alignItems='center'>
                              {controlElement}
                              <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_customer_online_correspondence_service_post_mail'})}</Text2>
                            </Inline>
                          </Box>
                        </Boxed>
                      )}
                    />
                </Inline>
              </RadioGroup>

              <ButtonPrimary submit>{intl.formatMessage({id:'page_customer_online_correspondence_service_save'})}</ButtonPrimary>
            </Stack>
          </Form>
        </Box>

        <div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_customer_online_correspondence_service_description'})}}></div>

      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default ConfigureOnlineCorrespondenceCustomerPage;
