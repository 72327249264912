import { Box, ResponsiveLayout, useScreenSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import ButtonTertiary from '../../../../../components/buttons/ButtonTertiary';
import HeroImage from '../../../../../components/hero/HeroImage';
import LayoutPublic from '../../../../../components/layout/LayoutPublic';
import { getHeaderEuroHorizonte } from '../../../../../utils/navigation';
import '../../LandingPage.scss';

const EuroHorizonSubscriptionPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();

  return (
    <LayoutPublic
    landing={true}
    header={{options:getHeaderEuroHorizonte()}}
      seo={{metaTitle: 'Fonditel Euro Horizonte 2026, FI', metaDescription:'Fonditel Euro Horizonte 2026, FI'}}
    >

      <HeroImage
        image='../assets/img/fonditel-euro-horizonte.jpg'
        title='Fonditel Euro Horizonte 2026, FI'
        button={<ButtonTertiary onPress={()=>{}}>Suscríbelo</ButtonTertiary>}
      ></HeroImage>

      <ResponsiveLayout className='content' fullWidth>
        <ResponsiveLayout>

          <Box paddingTop={screenSize.isDesktopOrBigger ? 64 : 40} paddingBottom={40}>
            <div className='html-content' dangerouslySetInnerHTML={{__html:
              `<p>Comisión de suscripción: 5% Importe suscrito a partir del 1 de mayo de 2023 inclusive o desde que el Fondo alcance un patrimonio total de 20 millones de euros, si esto sucediera antes.</p>
               <p>Comisión de reembolso: 5% Importe reembolsado a partir de del 1 de mayo de 2023, inclusive o desde que el Fondo alcance un patrimonio total de 20 millones de euros, si esto sucediera antes.</p>
               <p>“La comisión de reembolso no se aplicará los días 15 de cada mes, entre el 1 de mayo de 2023 y el 31 de diciembre de 2026, ambos incluidos o siguiente día hábil, siempre que se dé un preaviso de al menos 5 días hábiles. Dichos reembolsos se realizarán al valor liquidativo aplicable a dichas fechas.”</p>
               <p>Riesgo de Interés: LAS INVERSIONES EN RENTA FIJA REALIZADAS POR EL FONDO TENDRÍAN PÉRDIDAS SI LOS TIPOS DE INTERÉS SUBEN, POR LO QUE LOS REEMBOLSOS REALIZADOS ANTES DEL VENCIMIENTO DE SU HORIZONTE TEMPORAL PUEDEN SUPONER MINUSVALÍAS PARA EL INVERSOR.</p>`
            }}></div>
          </Box>

        </ResponsiveLayout>
      </ResponsiveLayout>

    </LayoutPublic>
  );
};

export default EuroHorizonSubscriptionPage;