import { useIonRouter } from '@ionic/react';
import { Box, ButtonLink, Image, Carousel, IconChevronLeftRegular, IconHandUpLight, Inline, PageBullets, ResponsiveLayout, skinVars, Stack, Text2, Text3, useWindowSize } from '@telefonica/mistica';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import GlobalPositionControlCommisionPage from '../../pages/private/users/control-commision/global-position/GlobalPositionControlCommisionPage';
import GlobalPositionCustomerPage from '../../pages/private/users/customer/global-position/GlobalPositionCustomerPage';
import { getOptionNavigationInverse, getUrlNavigationInverse } from '../../utils/navigation';
import { getFirstLoginApp, setFirstLoginApp } from '../../utils/storage';
import { isNativeApp } from '../../utils/utils';

import './OnBoardingFirstLogin.scss';

const OnBoardingFirstLogin: React.FC = () => {
  const intl = useIntl()
  const router = useIonRouter();
  const size = useWindowSize();
  const [visible, setVisible] = useState(false);

  const [pageInfo, setPageInfo] = React.useState<{
            pageIndex: number;
            shownItemIndexes: Array<number>;
        } | null>(null);

  const handleVisibleOnBoarding = (): Promise<void> =>
    new Promise((resolve) => {

        setVisible(false);
        resolve();
    });

    useLayoutEffect(()=>{
      const page = getOptionNavigationInverse(router.routeInfo.pathname);
      if(getFirstLoginApp() && isNativeApp && page && page.props.children.type.name===GlobalPositionCustomerPage.name){
        setVisible(true);
        setFirstLoginApp();
      }
    }, []);


return (<ResponsiveLayout className={'onboardingApp '+(!visible ? 'hide' : '')} fullWidth>
          <Stack space={'between'}>
            <Box className='onboardingApp__slider'>
              <Carousel initialActiveItem={pageInfo?.pageIndex??0}
                items={[
                  <Box padding={16} className='step step-1'>
                    <Stack space={0}>
                      <Box className='stepText step--textHome'>
                        <Text2 regular color={skinVars.colors.background}>{intl.formatMessage({id:'page_onboarding_text_home'})}</Text2>
                      </Box>

                      <Box className='stepText step--textProgress'>
                        <Text2 regular color={skinVars.colors.background}>{intl.formatMessage({id:'page_onboarding_text_in_progress'})}</Text2>
                      </Box>

                      <Box className='stepText step--textContract'>
                        <Text2 regular color={skinVars.colors.background}>{intl.formatMessage({id:'page_onboarding_text_contract'})}</Text2>
                      </Box>

                      <Image src={'../assets/img/onboarding_menu.svg'} width='100%' aspectRatio={0} />

                      <Box className='stepText step--textMail'>
                        <Text2 regular color={skinVars.colors.background}>{intl.formatMessage({id:'page_onboarding_text_mail'})}</Text2>
                      </Box>

                      <Box className='stepText step--textMenu'>
                        <Text2 regular color={skinVars.colors.background}>{intl.formatMessage({id:'page_onboarding_text_menu'})}</Text2>
                      </Box>
                    </Stack>
                  </Box>,

                  <Box padding={16} className='step step-2'>
                    <Stack space={0}>
                      <Box className='stepImage'>
                        <Image src='../assets/img/onboarding_user.svg' width='100%' aspectRatio={0} />
                      </Box>

                      <Box className='stepText step--textUser'>
                        <Text2 regular color={skinVars.colors.background}>{intl.formatMessage({id:'page_onboarding_text_user'})}</Text2>
                      </Box>
                    </Stack>
                  </Box>,

                  <Box padding={16} className='step step-3'>
                    <Stack space={0}>
                      <Box className='stepText step--textPlans'>
                        <Text2 regular color={skinVars.colors.background}>{intl.formatMessage({id:'page_onboarding_text_plans'})}</Text2>
                      </Box>

                      <Box className='stepImage'>
                        <Image src={'../assets/img/onboarding_plans.svg'} width='100%' aspectRatio={0} />
                      </Box>

                      <Box className='stepIcon'>
                        <IconHandUpLight color={skinVars.colors.background} size={46} className='iconHandUp'></IconHandUpLight>
                        <Box className='iconChevronLeft'>
                          <Inline space={-8}>
                            <IconChevronLeftRegular color={skinVars.colors.background} size={16} className='iconChevron'></IconChevronLeftRegular>
                            <IconChevronLeftRegular color={skinVars.colors.background} size={16} className='iconChevron last'></IconChevronLeftRegular>
                          </Inline>
                          <Text2 regular color={skinVars.colors.background}>{intl.formatMessage({id:'page_onboarding_text_carousel'})}</Text2>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>,
                ]}
              />
            </Box>

            <Box paddingY={8} paddingX={8} className='onboardingApp__footer'>

              <Inline space={'between'} alignItems='center'>

                <ButtonLink onPress={() => {handleVisibleOnBoarding()}}>
                  <Text3 regular color={skinVars.colors.background}>{intl.formatMessage({id:'page_onboarding_button_end'})}</Text3>
                </ButtonLink>

                <PageBullets numPages={3} currentIndex={pageInfo?.pageIndex??0}></PageBullets>

                {pageInfo?.pageIndex === 2 ?
                  <ButtonLink onPress={() => {handleVisibleOnBoarding()}}>
                    <Text3 regular color={skinVars.colors.background}>{intl.formatMessage({id:'page_onboarding_button_start'})}</Text3>
                  </ButtonLink>
                :
                  <ButtonLink onPress={() => {setPageInfo({pageIndex:(pageInfo?.pageIndex??0)+1, shownItemIndexes: []})}}>
                    <Text3 regular color={skinVars.colors.background}>{intl.formatMessage({id:'page_onboarding_button_next'})}</Text3>
                  </ButtonLink>
                }

              </Inline>

            </Box>
          </Stack>
          </ResponsiveLayout>);
};

export default OnBoardingFirstLogin;