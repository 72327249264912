import { Box, Text, Text3, Tooltip, skinVars } from '@telefonica/mistica';

import './CarouselHistory.scss';
import { useIntl } from 'react-intl';

interface PopoverItem {
    numberItem: string,
    textItem: string
}

const TooltipCarouselHistory: React.FC<PopoverItem> = (prop) => {

  const intl = useIntl();

  return (
    <>
      <style>
            {'[aria-label="tooltip-carousel-item-history"][aria-expanded="false"] .tooltip-carousel-history{background-color: ' + skinVars.colors.backgroundAlternative +';color:' + skinVars.colors.textPrimary + '}'}
            {'[aria-label="tooltip-carousel-item-history"][aria-expanded="true"] .tooltip-carousel-history{background-color: ' + skinVars.colors.backgroundBrand +';color:' + skinVars.colors.textPrimaryInverse + '}'}
      </style>

      <Tooltip
        targetLabel = {'tooltip-carousel-item-history'}
        target={
          <div className='tooltip-carousel-history'>
            <Text3 color='currentColor'regular>{prop.numberItem}</Text3>
          </div>}
        title=''
        delay={false}
        extra={<Box padding={24}>
          <Text>
            <div className='html-content' dangerouslySetInnerHTML={{__html: prop.textItem}}></div>
          </Text>
        </Box>}/>
    </>
  );
};


export default TooltipCarouselHistory;