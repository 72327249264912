import { useIntl } from "react-intl";
import { validateOperate } from "../../utils/apiclient";
import { useAlert } from "../alerts/Alert";
import { removeSignState, setSignState } from "../../utils/storage";



export const useValidOperate = () => {

    const intl = useIntl();
    const { showAlert } = useAlert()

	const validOperate = (operation:number, onConfirm?: () => void, onError?: () => void): Promise<boolean> =>
        new Promise((resolve) => {
        //Validar si es posible operar
        removeSignState();
        validateOperate({operation: operation}).then((result)=>{
            if(result.result){
                if(onConfirm && !result.error){
                    if(result.signState){
                        setSignState(result.signState);
                    }
                    onConfirm();
                }
                if(result.error){
                    /*if(onError){
                        onError();
                    }*/
                    if(onConfirm){
                        showAlert(result.error, result.extra, ()=>{ onConfirm() });
                    }else{
                        showAlert(result.error, result.extra);
                    }
                }
                resolve(true);
            }else{
                if(result.error){
                    if(onError){
                        onError();
                    }
                    showAlert(result.error, result.extra);
                }
                resolve(false);
            }
        })
        return (<></>);
	});
	return { validOperate };
};