
import { Haptics } from '@capacitor/haptics';
import { IonContent, useIonRouter } from '@ionic/react';
import { Box, ButtonLayout, ButtonPrimary, ButtonSecondary, dialog, IconFingerprintRegular, IconInformationUserLight, Inline, ResponsiveLayout, skinVars, Stack, Text3 } from '@telefonica/mistica';
import { NativeBiometric } from 'capacitor-native-biometric';
import React from 'react';
import { useIntl } from 'react-intl';
import { getBiometricLogin, getLoginSaved, removeBiometricLogin, removeFirstBiometricLogin, setBiometricLogin, setFirstBiometricLogin, setStatusBiometricLogin } from '../../../utils/storage';
import { getUrlDefaultUser, trackError } from '../../../utils/utils';
import './BiometricLoginPage.scss';

const BiometricLoginPage: React.FC = () => {

  const intl = useIntl()
  const router = useIonRouter();

  const handleBiometricLogin = (status:string): Promise<void> =>
    new Promise((resolve) => {

        switch(status){
          case 'activate':
            NativeBiometric.verifyIdentity({maxAttempts:2, useFallback: false}).then(()=>{
              try{
                Haptics.vibrate({duration:200}).then(()=>{});
              }catch(err){
                if(err){
                  trackError(JSON.stringify(err));
                }
              }
              setFirstBiometricLogin();
              const loginSaved = getLoginSaved();
              if(loginSaved)
                setBiometricLogin(loginSaved);
              continueDashboard(true);
              resolve();
              return;
            }).catch(()=>{

              dialog({
                icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                title: intl.formatMessage({id:'page_login_biometric_login_error_title'}),
                message: '',
                acceptText: intl.formatMessage({id:'page_login_biometric_login_error_button'})});

              continueDashboard(false);
            });
            break;
          case 'deactivate':
            NativeBiometric.verifyIdentity({maxAttempts:2, useFallback: false}).then(()=>{
              try{
                Haptics.vibrate({duration:200}).then(()=>{});
              }catch(err){
                if(err){
                  trackError(JSON.stringify(err));
                }
              }
              removeBiometricLogin();
              removeFirstBiometricLogin();
              continueDashboard(true);
              resolve();
              return;
            }).catch(()=>{

              dialog({
                icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                title: intl.formatMessage({id:'page_login_biometric_login_error_title'}),
                message: '',
                acceptText: intl.formatMessage({id:'page_login_biometric_login_error_button'})});

              continueDashboard(false);
            });
            break;
          case 'cancel':
            continueDashboard(false);
            resolve();
            break;
          case 'not_yet':
            removeBiometricLogin();
            continueDashboard(false);
            resolve();
            break;
        }
    });

  const continueDashboard = (status:boolean) =>{
    setStatusBiometricLogin(status);
    router.push(getUrlDefaultUser());
  }


  return (
    <IonContent>
      <ResponsiveLayout className={'fingerprintApp'} backgroundColor={skinVars.colors.brand} fullWidth isInverse>
        <Stack space={'between'}>
          <Box className='fingerprintApp__content'>
            <Stack space={'around'}>
              <Box paddingY={16} paddingX={40}>
                <Stack space={8}>
                  <Inline space={'around'}>
                    <IconFingerprintRegular size={149}></IconFingerprintRegular>
                  </Inline>

                  <Stack space={2}>
                    <Text3 regular>{intl.formatMessage({id:'page_login_biometric_login_title'})}</Text3>
                    <Text3 regular>{intl.formatMessage({id:'page_login_biometric_login_description'})}</Text3>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Box padding={16}>
            {(getBiometricLogin()) ?
              <ButtonLayout>
                <ButtonPrimary onPress={() => {handleBiometricLogin('deactivate')}}>{intl.formatMessage({id:'page_login_biometric_login_deactivate'})}</ButtonPrimary>
                <ButtonSecondary onPress={() => {handleBiometricLogin('cancel')}}>{intl.formatMessage({id:'page_login_biometric_login_cancel'})}</ButtonSecondary>
              </ButtonLayout>
            :
              <ButtonLayout>
                <ButtonPrimary onPress={() => {handleBiometricLogin('activate')}}>{intl.formatMessage({id:'page_login_biometric_login_active'})}</ButtonPrimary>
                <ButtonSecondary onPress={() => {handleBiometricLogin('not_yet')}}>{intl.formatMessage({id:'page_login_biometric_login_noactive'})}</ButtonSecondary>
              </ButtonLayout>
            }
          </Box>
        </Stack>
      </ResponsiveLayout>
    </IonContent>
  );
};

export default BiometricLoginPage;
