import { useIonRouter } from '@ionic/react';
import { Box, Boxed, ButtonLayout, ButtonLink, ButtonPrimary, ButtonSecondary, dialog, Form, IconFingerprintRegular, IconIdCardLight, IconInformationUserLight, LoadingBar, PasswordField, skinVars, Stack, Text3, Text4, TextField, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { NativeBiometric } from "capacitor-native-biometric";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ClientsLoginBody } from '../../entities/clients/clients-login-body';
import { UserInvalidException } from '../../entities/clients/user-invalid-exception';
import BiometricLoginPage from '../../pages/private/login/BiometricLoginPage';
import UserBlockedPage from '../../pages/private/login/UserBlockedPage';
import { login, recoveryPassword, verifyCodeRecoveryPassword } from '../../utils/apiclient';
import { getUrlNavigation } from '../../utils/navigation';
import { addUser, deleteModeCallCenter, getBiometricLogin, getDeviceId, getFirstBiometricLogin, getModeCallCenter, getProductToContract, removeProductToContract, selectRoleUser, setAlertExpiredIdCard, setBiometricLogin, setFirstBiometricLogin, setLoginSaved, setPendingAction } from '../../utils/storage';
import { closeDialog, formatPlanComisionControl, formatPlanPromotor, getPlatformDetail, getUrlDefaultUser, isNativeApp, trackError, trackEvent } from '../../utils/utils';
import { useProductActions } from '../operate/ProductActions';

import { Haptics } from '@capacitor/haptics';
import IncompleteRegistrationPage from '../../pages/private/incomplete-registration/IncompleteRegistrationPage';


const LoginCard: React.FC = () => {

const intl = useIntl();
const router = useIonRouter();
const size = useScreenSize();
const [biometricIsAvailable, setBiometricIsAvailable] = useState(false);
const biometricLogin = getBiometricLogin();
const firstBiometricLogin = getFirstBiometricLogin();
const [loading, setLoading] = useState(false);
const [nifRecoveryPassword, setNifRecoveryPassword] = useState('');
const { contract } = useProductActions()

useEffect(()=>{
  const modeCallCenter = getModeCallCenter();

  if(isNativeApp){
    NativeBiometric.isAvailable().then((result)=>{
      setBiometricIsAvailable(result.isAvailable);
      if(result.isAvailable && isNativeApp && biometricLogin && firstBiometricLogin){
        setFirstBiometricLogin();
        handleBiometricLogin();
      }
    });
  }else if(modeCallCenter!=null){
    doLogin({
      nif:modeCallCenter.nif,
      cdSesion: modeCallCenter.cdSesion,
      cdSesionCallCenter: modeCallCenter.cdSesionCallCenter,
      password: moment().unix().toString(),
      deviceId: getDeviceId(),
      platform: getPlatformDetail()
    }).finally(()=>{
      trackEvent('login', {method:'callcenter'});
      deleteModeCallCenter();
    });
  }
}, []);

  const doLogin = (clientsLoginBody: ClientsLoginBody): Promise<void> =>
    new Promise((resolve) => {
      setLoading(true);
      login(clientsLoginBody).then((client)=>{
        if(client){
          if(client.roles && client.roles.length===1){
              client.selectedRole = client.roles[0];
          }
          if(client.promotores && client.promotores.length>0){
            const promotor = client.promotores[0];
            client.selectedPromotor = promotor.idPromotor;

            if(promotor.planesPromotor && promotor.planesPromotor.length>0){
              client.selectedPlanPromotor = formatPlanPromotor(promotor.planesPromotor[0]);
            }
          }
          if(client.planesComisionControl && client.planesComisionControl.length>0){
            client.selectedPlanComisionControl = formatPlanComisionControl(client.planesComisionControl[0]);
          }

          addUser(client);

          setLoginSaved(clientsLoginBody);
          const product = getProductToContract();
          removeProductToContract();
          if(product && client.roles && client.roles.find(role=>role==='client')){
            selectRoleUser('client')
            contract(product,
              ()=>{
                if(client.roles && client.roles?.length>1){
                  selectRoleUser('')
                }
                router.push(getUrlDefaultUser())

              }).finally(()=>{
                resolve();
              })
            return;
          }


          if(client.accion){
            if(client.accion==='MostrarAvisoValidezDNI'){
              setAlertExpiredIdCard();
            }else{
              setPendingAction(client.accion);
              router.push(getUrlNavigation(IncompleteRegistrationPage));
              resolve();
              return;
            }
          }
          if(biometricIsAvailable){
            if(!biometricLogin){
              router.push(getUrlNavigation(BiometricLoginPage));
              resolve();
              return;
            }else{
              setBiometricLogin(clientsLoginBody);
            }
          }

          router.push(getUrlDefaultUser());
          resolve();
          return;
        }
        resolve();
      }).catch((reason)=>{
         setLoading(false);
          const userInvalid = reason.response && reason.response.data ? reason.response.data as UserInvalidException : null;
          if(userInvalid && userInvalid.message){
            switch(userInvalid.message){
              case 'Invalid credentials':
                const attemptMessage = userInvalid.retries && userInvalid.retries>0 ? (userInvalid.retries===1 ? intl.formatMessage({id:'page_login_invalid_credentials_last_attemp'}) : intl.formatMessage({id:'page_login_invalid_credentials_attemps'}, {attemps: userInvalid.retries})): '';
                dialog({
                  icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                  title: intl.formatMessage({id:'page_login_invalid_credentials'}),
                  message: attemptMessage,
                  acceptText: intl.formatMessage({id:'page_login_invalid_credentials_action_continue'})});
                  resolve();
                  break;

              case 'User is blocked':
                router.push(getUrlNavigation(UserBlockedPage));
                resolve();
                break;

              case 'NIF is not valid':
              case 'NIF is not register':
                dialog({
                  icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                  title: intl.formatMessage({id:'page_login_invalid_credentials'}),
                  message: '',
                  acceptText: intl.formatMessage({id:'page_login_invalid_credentials_action_continue'})});
                  resolve();
                  break;
            }
            resolve();
            return;
          }

          if(reason.code==='ERR_NETWORK'){
            if(clientsLoginBody.electronic){
              //No se han encontrado certificados válidos
              dialog({
                icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                title: intl.formatMessage({id:'page_login_invalid_certificates'}),
                message: '',
                acceptText: intl.formatMessage({id:'page_login_invalid_credentials_action_continue'})});
                resolve();
                return;
            }
          }

          dialog({
            icon: <IconInformationUserLight color={skinVars.colors.brand} />,
            title: intl.formatMessage({id:'page_login_invalid_credentials'}),
            message: '',
            acceptText: intl.formatMessage({id:'page_login_invalid_credentials_action_continue'})});

          resolve();
      }).finally(()=>{
        setLoading(false);
      });
    }
  );

  const doLoginDNIE = (): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('acceso-login-dnie');
      doLogin({
        nif:'dnie',
        password: moment().unix().toString(),
        deviceId: getDeviceId(),
        platform: getPlatformDetail(),
        electronic: true
      }).finally(()=>{
        trackEvent('login', {method:'dnie'});
        resolve();
      })
    });

  //Submit
  const handleBiometricLogin = (): Promise<void> =>
    new Promise((resolve) => {
      NativeBiometric.verifyIdentity({maxAttempts:2, useFallback: false}).then((result)=>{
        if(biometricLogin){
          try{
            Haptics.vibrate({duration:200}).then(()=>{});
          }catch(err){

            if(err){
              trackError(JSON.stringify(err));
            }
          }

          doLogin(biometricLogin).finally(()=>{
            trackEvent('login', {method:'biometric'});
            resolve();
          });
        }
      }).catch(()=>{
          //ERROR
          dialog({
            icon: <IconInformationUserLight color={skinVars.colors.brand} />,
            title: intl.formatMessage({id:'page_login_invalid_face_id'}),
            message: '',
            acceptText: intl.formatMessage({id:'page_login_invalid_credentials_action_continue'})});
          resolve();
      });
    }
  );

  //Submit
  const handleSubmit = (): Promise<void> =>
    new Promise((resolve) => {
        trackEvent('acceso-login');
        doLogin(formData).then(()=>{
          trackEvent('login', {method:'password'});
          resolve();
        });
    });

    const [formData, setFormData] = useState({
      nif: '',
      password: '',
      deviceId: getDeviceId(),
      platform: getPlatformDetail()
    } as ClientsLoginBody);

    const idCardResetPassword = React.createRef<HTMLInputElement>();
    const verificationCodeResetPassword = React.createRef<HTMLInputElement>();

    //Handle fields
    const handleChangeField= (evt:any) => {
      const { target } = evt;
      const { name, value } = target;
      const newValues = {
        ...formData,
        [name]: value,
      };
      setFormData(newValues);
    };

    //Submit disabled
    const isSubmitDisabled = () => {
      return !formData.nif || formData.nif.length===0 || !formData.password || formData.password.length===0;
    }

    const handleSubmitResetPassword = (data:any): Promise<void> =>
      new Promise((resolve) => {
          setLoading(true);
          trackEvent('acceso-recuperar_contraseña');
          setNifRecoveryPassword(data.idCard);
          recoveryPassword({nif:data.idCard}).then((response)=>{
            closeDialog().then(()=>{
              if(response.result.sent_sms){
                dialog({
                  title: intl.formatMessage({id:'page_login_recovery_password_sent_phone_title'}, {phone_numbers: (response.result.phone_numbers??'')}),
                  message: intl.formatMessage({id:'page_login_recovery_password_sent_phone_description'}),
                  extra: <Form onSubmit={handleSubmitVerificationCodeReset} className={loading ? 'loading' : ''}>
                            <Stack space={32}>
                              <div className='d-none'>
                                <TextField name="idCard" value={data.idCard} label=''></TextField>
                              </div>
                              <TextField ref={verificationCodeResetPassword} name="verificationCodeResetPassword" label={intl.formatMessage({id:'page_login_recover_password_verification_code'})}></TextField>
                              <ButtonLayout align='full-width'>
                                <ButtonPrimary submit disabled={verificationCodeResetPassword.current?.value.length===0}>
                                  {intl.formatMessage({id:'page_login_recover_password_accept_text'})}
                                </ButtonPrimary>
                              </ButtonLayout>
                            </Stack>
                          </Form>,
                  className:'dialog-without-buttons'
                });
              }else if(response.result.sent_email){
                dialog({
                  icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                  title: intl.formatMessage({id:'page_login_recover_password'}),
                  message:'',
                  extra: <Text3 regular color={skinVars.colors.neutralMediumInverse}><div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_login_recovery_password_sent_mail'})}}></div></Text3>,
                  acceptText: intl.formatMessage({id:'page_login_invalid_credentials_action_continue'})});

              }else{
                dialog({
                  icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                  title: intl.formatMessage({id:'page_login_recovery_password'}),
                  message: intl.formatMessage({id:'page_login_recovery_password_error'}),
                  acceptText: intl.formatMessage({id:'page_login_invalid_credentials_action_continue'})});
              }
            });
          }).finally(()=>{
            setLoading(false);
            resolve();
          });
    });

    const handleSubmitVerificationCodeReset= (data:any): Promise<void> =>
      new Promise((resolve) => {
          setLoading(true);
          verifyCodeRecoveryPassword({nif:data.idCard, verification_code: data.verificationCodeResetPassword}).then((response)=>{

            closeDialog().then(()=>{
              if(response.result.sent_email){

                setNifRecoveryPassword('');
                dialog({
                  icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                  title: intl.formatMessage({id:'page_login_recover_password'}),
                  message:'',
                  extra: <Text3 regular color={skinVars.colors.neutralMediumInverse}><div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_login_recovery_password_sent_mail'})}}></div></Text3>,
                  acceptText: intl.formatMessage({id:'page_login_invalid_credentials_action_continue'})});

              }else if(response.result.sent_sms){

                dialog({
                  title: intl.formatMessage({id:'page_login_recovery_password_sent_phone_title'}, {phone_numbers: (response.result.phone_numbers??'')}),
                  message: intl.formatMessage({id:'page_login_recovery_password_sent_phone_description'}),
                  extra: <Form onSubmit={handleSubmitVerificationCodeReset} className={loading ? 'loading' : ''}>
                            <Stack space={32}>
                              <div className='d-none'>
                                <TextField name="idCard" value={data.idCard} label=''></TextField>
                              </div>
                              <TextField ref={verificationCodeResetPassword} name="verificationCodeResetPassword" label={intl.formatMessage({id:'page_login_recover_password_verification_code'})}></TextField>
                              <ButtonLayout align='full-width'>
                                <ButtonPrimary submit disabled={verificationCodeResetPassword.current?.value.length===0}>
                                  {intl.formatMessage({id:'page_login_recover_password_accept_text'})}
                                </ButtonPrimary>
                              </ButtonLayout>
                            </Stack>
                          </Form>,
                  className:'dialog-without-buttons'
                });

              }else{
                dialog({
                  icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                  title: intl.formatMessage({id:'page_login_recovery_password'}),
                  message: intl.formatMessage({id:'page_login_recovery_password_error'}),
                  acceptText: intl.formatMessage({id:'page_login_invalid_credentials_action_continue'})});
              }
            });
          }).finally(()=>{
            setLoading(false);
            resolve();
          })
      });

  return (
    <>
      <LoadingBar visible={loading}/>
      <Boxed>
        <Box padding={40}>
          <Text4 medium>{intl.formatMessage({id:'page_login_title_login_card'})}</Text4>
          <Form autoJump={false}  onSubmit={handleSubmit} initialValues={formData}  className={loading ? 'loading' : ''}>
              <Box paddingTop={32}>
                <Stack space={32}>
                  <TextField name="nif" autoFocus={false} onChange={handleChangeField} label={intl.formatMessage({id:'page_login_dni_nif_passport'})} autoComplete="off" fullWidth />
                  <PasswordField
                    name="password"
                    label={intl.formatMessage({id:'page_login_label_password'})}
                    onChange={handleChangeField} autoFocus={false}
                    fullWidth />
                  <ButtonLink onPress={() =>
                                dialog({
                                    title: intl.formatMessage({id:'page_login_recover_password'}),
                                    message: intl.formatMessage({id:'page_login_recover_password_description'}),
                                    forceWeb:true,
                                    extra: <Form onSubmit={handleSubmitResetPassword} className={loading ? 'loading' : ''}>
                                              <Stack space={32}>
                                                <TextField ref={idCardResetPassword} onChangeValue={(val)=>{setNifRecoveryPassword(val)}} name="idCard" label={intl.formatMessage({id:'page_login_recover_password_dni'})}></TextField>
                                                <ButtonLayout align='full-width'>
                                                  <ButtonPrimary submit disabled={idCardResetPassword.current?.value.length===0}>
                                                    {intl.formatMessage({id:'page_login_recover_password_accept_text'})}
                                                  </ButtonPrimary>
                                                </ButtonLayout>
                                              </Stack>
                                            </Form>,
                                    className:'dialog-without-buttons'
                                  })
                            }>{intl.formatMessage({id:'page_login_forgot_password'})}</ButtonLink>

                  {size.isDesktopOrBigger ?
                    <ButtonGroup
                    primaryButton={
                      <ButtonLayout align='full-width'>
                        <ButtonPrimary submit disabled={isSubmitDisabled()}>
                          {intl.formatMessage({id:'page_login_get_into'})}
                        </ButtonPrimary>
                      </ButtonLayout>
                    }
                    secondaryButton={
                      <ButtonSecondary onPress={doLoginDNIE}> <IconIdCardLight color="currentColor" />
                        {intl.formatMessage({id:'page_login_get_into_dnie'})}
                      </ButtonSecondary>
                    }></ButtonGroup>
                  :
                  <ButtonLayout>
                      <ButtonPrimary showSpinner={loading} submit disabled={isSubmitDisabled()}>
                        {intl.formatMessage({id:'page_login_get_into'})}
                      </ButtonPrimary>
                      {!isNativeApp &&
                        <ButtonSecondary onPress={doLoginDNIE}> <IconIdCardLight color="currentColor" />
                        {intl.formatMessage({id:'page_login_get_into_dnie'})}
                       </ButtonSecondary>
                      }
                  </ButtonLayout>
                  }
                </Stack>
            </Box>
          </Form>
        </Box>
      </Boxed>

      {isNativeApp && biometricIsAvailable && biometricLogin &&
        <Box paddingTop={24}>
          <ButtonLayout>
            <ButtonLink onPress={()=>{handleBiometricLogin()}}><IconFingerprintRegular color='currentColor' />{intl.formatMessage({id:'page_login_get_into_with_face_id_or_fingerprint'})}</ButtonLink>
          </ButtonLayout>
        </Box>
      }
    </>
  );
};

export default LoginCard;