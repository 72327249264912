import { Box, Boxed, ButtonLayout, ButtonLink, ButtonPrimary, ButtonSecondary, DateField, dialog, Divider, DoubleField, Form, Grid, IconArrowLineRightRegular, IconButton, IconChevronDownRegular, IconChevronUpRegular, IconExportLight, IconInvoicePlanFileRegular, IconSearchFileRegular, Inline, LoadingBar, ResponsiveLayout, SkeletonLine, SkeletonRectangle, skinVars, Stack, Tag, Text, Text1, Text2, Text3, Text4, useScreenSize } from '@telefonica/mistica';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Contract } from '../../entities/contract/contract';
import { Movement } from '../../entities/contract/movement';
import { exportMovements, getMovements } from '../../utils/apiclient';
import { closeDialog, isNativeApp } from '../../utils/utils';
import './Movements.scss';
import EmptyCard from '../empty-card/EmptyCard';

interface MovementsProps {
    contract: Contract | null
    loading?: boolean
}

const Movements: React.FC<MovementsProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState(null as null|Contract);
    const [movements, setMovements]= useState([] as Array<Movement>);
    const [dateFrom, setDateFrom]= useState(moment().format("YYYY-MM-DD").toString());
    const [dateTo, setDateTo]= useState(moment().format("YYYY-MM-DD").toString());
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);


    useEffect(()=>{
        if(prop.contract && prop.contract.movimientos){
            setContract(prop.contract);
            setMovements(Object.values(prop.contract.movimientos));
            setDateFrom(moment(prop.contract.fechaUltimosMovimientos ?? '', "DD/MM/YYYY").format("YYYY-MM-DD").toString());
            setDateTo(moment().format("YYYY-MM-DD").toString());
        }
    },[prop.contract]);


    const handleSubmit  = (): Promise<void> =>
        new Promise((resolve) => {
            setLoading(true);
            if(contract && contract.plan && contract.plan.cdCompania && contract.plan.cdFondo && contract.plan.cdPlan && contract.plan.cdSubplan && contract?.numContrato){
                getMovements({cdCompany:contract.plan.cdCompania, cdFund:contract.plan.cdFondo, cdPlan:contract.plan.cdPlan, cdSubplan:contract.plan.cdSubplan, numContract:contract.numContrato, dateFrom: dateFrom??'', dateTo: dateTo??''}).then((result)=>{
                  setMovements(Object.values(result));
                }).finally(()=>{
                    setLoading(false);
                    resolve();
                });
            }
        });

    const handleExport  = (): Promise<void> =>
        new Promise((resolve) => {
            setLoadingExport(true);
            if(contract && contract.plan && contract.plan.cdCompania && contract.plan.cdFondo && contract.plan.cdPlan && contract.plan.cdSubplan && contract?.numContrato){
                exportMovements({cdCompany:contract.plan.cdCompania, cdFund:contract.plan.cdFondo, cdPlan:contract.plan.cdPlan, cdSubplan:contract.plan.cdSubplan, numContract:contract.numContrato, dateFrom: dateFrom??'', dateTo: dateTo??'', action:'export', title:'Movimientos.xlsx'}).then(()=>{}).finally(()=>{
                    setLoadingExport(false);
                    resolve();
                });
            }
        });

    const handlePrint  = (): Promise<void> =>
        new Promise((resolve) => {
            setLoadingPrint(true);
            if(contract && contract.plan && contract.plan.cdCompania && contract.plan.cdFondo && contract.plan.cdPlan && contract.plan.cdSubplan && contract?.numContrato){
                exportMovements({cdCompany:contract.plan.cdCompania, cdFund:contract.plan.cdFondo, cdPlan:contract.plan.cdPlan, cdSubplan:contract.plan.cdSubplan, numContract:contract.numContrato, dateFrom: dateFrom??'', dateTo: dateTo??'', action:'print', title:'Movimientos.pdf'}).then(()=>{}).finally(()=>{
                    setLoadingPrint(false);
                    resolve();
                });
            }
        });
    const toggleExpandedMovement=(index:number)=>{
        const toggleMovements = movements.map((movement, i)=>{
            if(i===index){
                movement.expanded = !movement.expanded;
            }
            return movement;
        })
        setMovements(toggleMovements);
    }

    const handleViewMorePlan = (movement:Movement): Promise<void> =>
        new Promise((resolve) => {
            dialog({
                title: intl.formatMessage({id:'page_contract_data_movements_detail'}),
                message: '',
                className:'dialog-without-buttons',
                extra:
                    <Stack space={24}>
                        <Grid columns={2} gap={24}>
                            <Stack space={4}>
                                <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_operation_date'})}</Text3>
                                <Text3 medium>{movement.detail?.fechaOperacion}</Text3>
                            </Stack>

                            <Stack space={4}>
                                <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_value_date'})}</Text3>
                                <Text3 medium>{movement.detail?.fechaValor}</Text3>
                            </Stack>

                            <Stack space={4}>
                                <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_import'})}</Text3>
                                <Text3 medium>{movement.detail?.importe}</Text3>
                            </Stack>

                            <Stack space={4}>
                                <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_units'})}</Text3>
                                <Text3 medium>{movement.detail?.unidades}</Text3>
                            </Stack>

                            <Stack space={4}>
                                <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_liquidation_value'})}</Text3>
                                <Text3 medium>{movement.detail?.valorLiquidativo}</Text3>
                            </Stack>
                        </Grid>

                        <Grid columns={2} gap={24}>
                            <Stack space={4}>
                                <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_liquidation_operation'})}</Text3>
                                <Text3 medium>{movement.detail?.operacion}</Text3>
                            </Stack>

                            <Stack space={4}>
                                <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_concept'})}</Text3>
                                <Text3 medium>{movement.detail?.concepto}</Text3>
                            </Stack>

                            <Stack space={4}>
                                <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_retention'})}</Text3>
                                <Text3 medium>{movement.detail?.retencionImporte}</Text3>
                            </Stack>

                            <Stack space={4}>
                                <Text3 regular>% {intl.formatMessage({id:'page_contract_data_movements_retention'})}</Text3>
                                <Text3 medium>{movement.detail?.retencionPorc}</Text3>
                            </Stack>

                            {movement.detail?.modalidad &&
                                <Stack space={4}>
                                    <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_modality'})}</Text3>
                                    <Text3 medium>{movement.detail?.modalidad}</Text3>
                                </Stack>
                            }
                        </Grid>
                    </Stack>
            });
            resolve();
        });

    const handleViewMoreFund = (movement:Movement): Promise<void> =>
        new Promise((resolve) => {
            closeDialog().then(()=>{
                dialog({
                    title: intl.formatMessage({id:'page_contract_data_movements_detail'}),
                    message: '',
                    className:'dialog-without-buttons',
                    extra:
                        <Stack space={24}>
                            <Grid columns={2} gap={24}>
                                <Stack space={4}>
                                    <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_operation_date'})}</Text3>
                                    <Text3 medium>{movement.detail?.fechaOperacion}</Text3>
                                </Stack>

                                <Stack space={4}>
                                    <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_value_date'})}</Text3>
                                    <Text3 medium>{movement.detail?.fechaValor}</Text3>
                                </Stack>

                                <Stack space={4}>
                                    <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_import'})}</Text3>
                                    <Text3 medium>{movement.detail?.importe}</Text3>
                                </Stack>

                                <Stack space={4}>
                                    <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_units'})}</Text3>
                                    <Text3 medium>{movement.detail?.unidades}</Text3>
                                </Stack>

                                <Stack space={4}>
                                    <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_liquidation_value'})}</Text3>
                                <Text3 medium>{movement.detail?.valorLiquidativo}</Text3>
                                </Stack>

                                <Stack space={4}>
                                    <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_liquidation_operation'})}</Text3>
                                <Text3 medium>{movement.detail?.operacion}</Text3>
                                </Stack>
                                {movement.detail?.fechaTraspaso &&
                                    <Stack space={4}>
                                        <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_transfer_date'})}</Text3>
                                        <Text3 medium>{movement.detail?.fechaTraspaso}</Text3>
                                    </Stack>
                                }

                                {movement.detail?.comisiones &&
                                    <Stack space={4}>
                                        <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_commissions'})}</Text3>
                                        <Text3 medium>{movement.detail?.comisiones}</Text3>
                                    </Stack>
                                }

                                {movement.detail?.retencionImporte &&
                                    <Stack space={4}>
                                        <Text3 regular>{intl.formatMessage({id:'page_contract_data_movements_retention'})}</Text3>
                                        <Text3 medium>{movement.detail?.retencionImporte}</Text3>
                                    </Stack>
                                }
                            </Grid>

                            {movement.detail_transfers &&
                                <ButtonLayout align='full-width'>
                                    <ButtonPrimary onPress={()=>handleViewDetailFund(movement)}>{intl.formatMessage({id:'page_contract_data_movements_transfer_details'})}</ButtonPrimary>
                                </ButtonLayout>
                            }
                        </Stack>
                });
            });
            resolve();
        });

    const handleViewDetailFund = (movement:Movement): Promise<void> =>
        new Promise((resolve) => {
            closeDialog().then(()=>{
                dialog({
                    title: intl.formatMessage({id:'page_contract_data_movements_transfer_details'}),
                    message: '',
                    className:'dialog-without-buttons',
                    onAccept: ()=>{!screenSize.isDesktopOrBigger && handleViewMoreFund(movement)},
                    extra:
                        <>
                            {screenSize.isDesktopOrBigger ?
                                <Box padding={8}>
                                    <Stack space={40}>
                                        <Divider />

                                        <Inline space={8} alignItems='flex-end'>
                                            <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_value_date'})}:</Text1>
                                            <Text3 regular>{movement.detail?.fechaOperacion}</Text3>
                                        </Inline>

                                        <Stack space={8} className='table--contributedCapital'>
                                            <Inline space={'between'}>
                                                <Box className='col-1' paddingLeft={16}>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_acquisition_date_mayus'})}</Text>
                                                </Box>
                                                <Box className='col-2' paddingX={16}>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_acquisition_amount_mayus'})}</Text>
                                                </Box>
                                                <Box className='col-3' paddingRight={16}>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_transfer_amount_mayus'})}</Text>
                                                </Box>
                                            </Inline>
                                            <Divider/>

                                            <Box paddingY={12}>
                                                <Stack space={12}>
                                                    {movement.detail_transfers && movement.detail_transfers.map((detailTransfer, index) => (
                                                        <Stack key={'detail-transfer-desktop-'+index} space={24}>
                                                            <Inline space='between' alignItems='center' className='row'>
                                                                <Box className='col-1' paddingLeft={16}>
                                                                    <Text2 regular>{detailTransfer.fechaOperacion}</Text2>
                                                                </Box>
                                                                <Box className='col-2' paddingX={16}>
                                                                    <Text2 regular>{detailTransfer.costeTraspaso}</Text2>
                                                                </Box>
                                                                <Box className='col-3' paddingRight={16}>
                                                                    <Text2 regular>{detailTransfer.importeRealizado}</Text2>
                                                                </Box>
                                                            </Inline>
                                                            <Divider/>
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Box>
                            :
                                <Box padding={4}>
                                    <Stack space={16}>
                                        <Divider />

                                        <Inline space={8} alignItems='flex-end'>
                                            <Text2 regular>{intl.formatMessage({id:'page_contract_data_movements_value_date'})}:</Text2>
                                            <Text3 regular>{movement.detail?.fechaOperacion}</Text3>
                                        </Inline>

                                        <Stack space={12}>
                                            {movement.detail_transfers && movement.detail_transfers.map((detailTransfer, index) => (
                                                <Boxed key={'detail-transfer-mobile-'+index}>
                                                    <Box paddingX={16} paddingY={24}>
                                                        <Grid columns={2} gap={16}>
                                                            <Stack space={2}>
                                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_acquisition_date'})}</Text1>
                                                                <Text3 medium>{detailTransfer.fechaOperacion}</Text3>
                                                            </Stack>
                                                            <Stack space={2}>
                                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_acquisition_amount'})}</Text1>
                                                                <Text3 medium>{detailTransfer.costeTraspaso}</Text3>
                                                            </Stack>
                                                            <Stack space={2}>
                                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_transfer_amount'})}</Text1>
                                                                <Text3 medium>{detailTransfer.importeRealizado}</Text3>
                                                            </Stack>
                                                        </Grid>
                                                    </Box>
                                                </Boxed>
                                            ))}
                                        </Stack>
                                    </Stack>
                                </Box>
                            }
                        </>
                });
            });
            resolve();
    });

    return ( <>{prop.loading ?
                <Box paddingY={screenSize.isDesktopOrBigger ? 32 : 16} className={'movements--content '}>
                    <Stack space={screenSize.isDesktopOrBigger ? 40 : 12}>
                        <Stack space={16}>
                            {screenSize.isDesktopOrBigger &&
                                <SkeletonLine />
                            }

                            <Box className='blockFilters'>
                                <Box className='filterGroup'>
                                    <Box className='group--date'>
                                        <DoubleField layout='50/50' fullWidth>
                                            <SkeletonRectangle height={60} />

                                            <SkeletonRectangle height={60} />
                                        </DoubleField>
                                    </Box>
                                    <Box className='group--button'>
                                        <ButtonLayout>
                                            <SkeletonRectangle height={60} />
                                        </ButtonLayout>
                                    </Box>
                                </Box>

                                <Box className='filterGroup filterGroup--options'>
                                    <SkeletonRectangle height={31} />
                                    <SkeletonRectangle height={31} />
                                </Box>
                            </Box>
                        </Stack>
                        {screenSize.isDesktopOrBigger ?

                            <Stack space={8}>
                                <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                                <Divider />
                                <SkeletonRectangle ariaLabel="loading" height={70}></SkeletonRectangle>
                                <Divider />
                                <SkeletonRectangle ariaLabel="loading" height={70}></SkeletonRectangle>
                                <Divider />
                                <SkeletonRectangle ariaLabel="loading" height={70}></SkeletonRectangle>
                                <Divider />
                                <SkeletonRectangle ariaLabel="loading" height={70}></SkeletonRectangle>
                                <Divider />
                            </Stack>
                        :
                            <Stack space={12}>
                                <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                                <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                            </Stack>
                        }
                    </Stack>
                </Box>
            :
                <>
                    <LoadingBar visible={loading || loadingDownload || loadingPrint || loadingExport} />
                    <Box paddingY={screenSize.isDesktopOrBigger ? 32 : 16} className={'movements--content loadingBox '+(loading ? 'loading' : '')}>
                        <Stack space={screenSize.isDesktopOrBigger ? 40 : 12}>
                            <Stack space={16}>
                                {screenSize.isDesktopOrBigger &&
                                    <Box>
                                        <Text4 medium>{intl.formatMessage({id:'page_contract_data_movements_movements'})}</Text4>
                                    </Box>
                                }

                                <Form onSubmit={handleSubmit}>
                                    <Box className='blockFilters'>
                                        <Box className='filterGroup'>
                                            <Box className='group--date'>
                                                <DoubleField layout='50/50' fullWidth>
                                                    <DateField value={dateFrom} name="dateFrom" onChangeValue={(value) => {setDateFrom(value)}} label={intl.formatMessage({id:'page_contract_data_movements_from'})} />

                                                    <DateField value={dateTo}  name="dateTo" onChangeValue={(value) => {setDateTo(value)}} label={intl.formatMessage({id:'page_contract_data_movements_until'})} />
                                                </DoubleField>
                                            </Box>
                                            <Box className='group--button'>
                                                <ButtonLayout>
                                                    <ButtonPrimary submit>{intl.formatMessage({id:'page_contract_data_movements_calculate'})}</ButtonPrimary>
                                                </ButtonLayout>
                                            </Box>
                                        </Box>

                                    <Box className={'filterGroup filterGroup--options '+(isNativeApp ? 'one-button': '')}>
                                        {!isNativeApp &&
                                            <ButtonSecondary disabled={movements.length===0} showSpinner={loadingExport} small onPress={() => {handleExport()}}>
                                                <IconExportLight color={skinVars.colors.brand}></IconExportLight>
                                                {intl.formatMessage({id:'page_contract_data_movements_export_excel'})}
                                            </ButtonSecondary>
                                        }
                                        <ButtonSecondary disabled={movements.length===0} showSpinner={loadingPrint} small onPress={() => {handlePrint()}}>
                                            <IconInvoicePlanFileRegular color={skinVars.colors.brand}></IconInvoicePlanFileRegular>
                                            {intl.formatMessage({id:'page_contract_data_movements_print'})}
                                        </ButtonSecondary>
                                    </Box>
                                </Box>
                                </Form>
                            </Stack>

                            {/* pension plan table */}
                            {contract?.plan?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES &&
                            <>
                                {screenSize.isDesktopOrBigger ?
                                <Stack space={8} className='table--movements'>
                                    {movements && movements.length>0 &&
                                        <Stack space={8}>
                                            <Inline space="between">
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_date_value'})}</Text>
                                                </Box>
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_operation_mayus'})}</Text>
                                                </Box>
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_import_mayus'})}</Text>
                                                </Box>
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_units_mayus'})}</Text>
                                                </Box>
                                                <Box className='col-tag'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_sign_mayus'})}</Text>
                                                </Box>
                                                <Box className='col-icon'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_detail_mayus'})}</Text>
                                                </Box>
                                            </Inline>
                                            <Divider/>
                                        </Stack>
                                    }
                                    <Box paddingY={12}>
                                        <Stack space={12}>

                                            {movements && movements.length===0 ?
                                                <Box paddingY={32}>
                                                    <EmptyCard
                                                    icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                                                    title={intl.formatMessage({id:'page_contract_data_movements_without_movements_title'})}
                                                    subtitle={intl.formatMessage({id:'page_contract_data_movements_without_movements_description'})}
                                                    />
                                                </Box>
                                            : movements && movements.map((movement, index) => (
                                                <Stack key={'table-movement-'+index} space={24}>
                                                    <Inline space='between' alignItems='center' className='row'>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.fechaValor}</Text1>
                                                        </Box>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.tipoOperacion}</Text1>
                                                        </Box>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.importe}</Text1>
                                                        </Box>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.unidades}</Text1>
                                                        </Box>
                                                        <Box className='col-tag'>
                                                            <Tag type={movement.info?.signo==='+' ? "success" : "error"}>{movement.info?.signo??''}</Tag>
                                                        </Box>
                                                        <Box className='col-icon'>
                                                            <IconButton onPress={()=>{toggleExpandedMovement(index)}}>
                                                                {movement.expanded ?
                                                                <IconChevronUpRegular color={skinVars.colors.buttonPrimaryBackground}></IconChevronUpRegular>
                                                                :
                                                                    <IconChevronDownRegular color={skinVars.colors.buttonPrimaryBackground}></IconChevronDownRegular>
                                                                }
                                                            </IconButton>
                                                        </Box>
                                                    </Inline>
                                                    <ResponsiveLayout className={'detail-movements '+(movement.expanded ? 'visible': '')} backgroundColor={skinVars.colors.backgroundAlternative}>
                                                        <Box padding={40}>
                                                            <Stack space={32}>
                                                                <Grid columns={{ minSize: 100 }} gap={40}>
                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_operation_date'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.fechaOperacion}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_value_date'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.fechaValor}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_import'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.importe}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_units'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.unidades}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_liquidation_value'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.valorLiquidativo}</Text2>
                                                                    </Stack>
                                                                </Grid>

                                                                <Grid columns={{ minSize: 100 }} gap={40}>
                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_liquidation_operation'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.operacion}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_concept'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.concepto}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_retention'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.retencionImporte}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>% {intl.formatMessage({id:'page_contract_data_movements_retention'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.retencionPorc}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_modality'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.modalidad}</Text2>
                                                                    </Stack>
                                                                </Grid>
                                                            </Stack>
                                                        </Box>
                                                    </ResponsiveLayout>
                                                    <Divider/>
                                                </Stack>
                                            ))}
                                        </Stack>
                                    </Box>
                                </Stack>
                                :
                                    <>
                                        <Stack space={12}>

                                            {movements && movements.length===0 ?
                                                <Box paddingY={32}>
                                                    <EmptyCard
                                                        icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                                                        title={intl.formatMessage({id:'page_contract_data_movements_without_movements_title'})}
                                                        subtitle={intl.formatMessage({id:'page_contract_data_movements_without_movements_description'})}
                                                    />
                                                </Box>
                                            : movements && movements.map((movement, index) => (
                                                <Boxed key={'mobile-movement-'+index}>
                                                    <Box padding={16}>
                                                        <Stack space={24}>
                                                            <Grid columns={2} gap={16}>
                                                                <Stack space={2}>
                                                                    <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_date'})}</Text1>
                                                                    <Text3 medium>{movement.detail?.fechaOperacion}</Text3>
                                                                </Stack>

                                                                <Stack space={2}>
                                                                    <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_liquidation_operation'})}</Text1>
                                                                    <Text3 medium>{movement.detail?.operacion}</Text3>
                                                                </Stack>

                                                                <Stack space={2}>
                                                                    <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_units'})}</Text1>
                                                                    <Text3 medium>{movement.detail?.unidades}</Text3>
                                                                </Stack>

                                                                <Stack space={2}>
                                                                    <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_import'})}</Text1>
                                                                    <Text3 medium>{movement.detail?.importe}</Text3>
                                                                </Stack>

                                                                <Stack space={8}>
                                                                    <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_sign'})}</Text1>
                                                                    <Tag type={movement.info?.signo==='+' ? "success" : "error"}>{movement.info?.signo??''}</Tag>
                                                                </Stack>
                                                            </Grid>

                                                            <ButtonLink onPress={() => {handleViewMorePlan(movement)}} aligned>
                                                                {intl.formatMessage({id:'page_contract_data_movements_show_more'})}
                                                                <IconArrowLineRightRegular color="currentColor" />
                                                            </ButtonLink>
                                                        </Stack>
                                                    </Box>
                                                </Boxed>
                                            ))}
                                        </Stack>
                                    </>
                                }
                            </>}

                            {/* investment fund table */}
                            {contract?.plan?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS &&
                                <>{
                                screenSize.isDesktopOrBigger ?
                                <Stack space={8} className='table--movements'>
                                    {movements && movements.length>0 &&
                                        <Stack space={8}>
                                            <Inline space="between">
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_date_value'})}</Text>
                                                </Box>
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_operation_mayus'})}</Text>
                                                </Box>
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_import_mayus'})}</Text>
                                                </Box>
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_units_mayus'})}</Text>
                                                </Box>
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_transfer_mayus'})}</Text>
                                                </Box>
                                                <Box className='col-icon'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_detail_mayus'})}</Text>
                                                </Box>
                                            </Inline>
                                            <Divider/>
                                        </Stack>
                                    }
                                    <Box paddingY={12}>
                                        <Stack space={12}>
                                            {movements && movements.length===0 ?
                                                <Box paddingY={32}>
                                                    <EmptyCard
                                                        icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                                                        title={intl.formatMessage({id:'page_contract_data_movements_without_movements_title'})}
                                                        subtitle={intl.formatMessage({id:'page_contract_data_movements_without_movements_description'})}
                                                    />
                                                </Box>
                                            : movements && movements.map((movement, index) => (
                                                <Stack key={'table-movement-'+index} space={24}>

                                                    <Inline space='between' alignItems='center' className='row'>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.fechaValor}</Text1>
                                                        </Box>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.tipoOperacion}</Text1>
                                                        </Box>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.importe}</Text1>
                                                        </Box>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.unidades}</Text1>
                                                        </Box>
                                                        <Box className='col'>
                                                            {movement.detail_transfers &&
                                                                <ButtonLink onPress={() => {handleViewDetailFund(movement)}}>{intl.formatMessage({id:'page_contract_data_movements_see_detail'})}</ButtonLink>
                                                            }
                                                        </Box>

                                                        <Box className='col-icon'>
                                                            <IconButton onPress={()=>{toggleExpandedMovement(index)}}>
                                                                {movement.expanded ?
                                                                <IconChevronUpRegular color={skinVars.colors.buttonPrimaryBackground}></IconChevronUpRegular>
                                                                :
                                                                    <IconChevronDownRegular color={skinVars.colors.buttonPrimaryBackground}></IconChevronDownRegular>
                                                                }
                                                            </IconButton>
                                                        </Box>
                                                    </Inline>
                                                    <ResponsiveLayout className={'detail-movements '+(movement.expanded ? 'visible': '')} backgroundColor={skinVars.colors.backgroundAlternative}>
                                                        <Box padding={40}>
                                                            <Stack space={32}>
                                                                <Grid columns={{ minSize: 100 }} gap={40}>
                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_operation_date'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.fechaOperacion}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_value_date'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.fechaValor}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_import'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.importe}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_units'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.unidades}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_liquidation_value'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.valorLiquidativo}</Text2>
                                                                    </Stack>
                                                                </Grid>

                                                                <Grid columns={{ minSize: 100 }} gap={40}>
                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_operation_type'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.operacion}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_transfer_date'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.fechaTraspaso}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_commissions'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.comisiones}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_retention'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.retencionImporte}</Text2>
                                                                    </Stack>
                                                                </Grid>
                                                            </Stack>
                                                        </Box>
                                                    </ResponsiveLayout>
                                                    <Divider />
                                                </Stack>
                                            ))}
                                        </Stack>
                                    </Box>
                                </Stack>
                            :
                                <>
                                    <Stack space={12}>
                                        {movements && movements.length===0 ?
                                            <Box paddingY={32}>
                                                <EmptyCard
                                                    icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                                                    title={intl.formatMessage({id:'page_contract_data_movements_without_movements_title'})}
                                                    subtitle={intl.formatMessage({id:'page_contract_data_movements_without_movements_description'})}
                                                />
                                            </Box>
                                        : movements && movements.map((movement, index) => (
                                            <Boxed key={'mobile-movement-'+index}>
                                                <Box padding={16}>
                                                    <Stack space={24}>
                                                        <Grid columns={2} gap={16}>
                                                            <Stack space={2}>
                                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_date'})}</Text1>
                                                                <Text3 medium>{movement.detail?.fechaOperacion}</Text3>
                                                            </Stack>

                                                            <Stack space={2}>
                                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_liquidation_operation'})}</Text1>
                                                                <Text3 medium>{movement.detail?.operacion}</Text3>
                                                            </Stack>

                                                            <Stack space={2}>
                                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_units'})}</Text1>
                                                                <Text3 medium>{movement.detail?.unidades}</Text3>
                                                            </Stack>

                                                            <Stack space={2}>
                                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_import'})}</Text1>
                                                                <Text3 medium>{movement.detail?.importe}</Text3>
                                                            </Stack>
                                                        </Grid>

                                                        <ButtonLink onPress={() => {handleViewMoreFund(movement)}} aligned>
                                                            {intl.formatMessage({id:'page_contract_data_movements_show_more'})}
                                                            <IconArrowLineRightRegular color="currentColor" />
                                                        </ButtonLink>
                                                    </Stack>
                                                </Box>
                                            </Boxed>
                                        ))}
                                    </Stack>
                                </>
                                }
                            </>}


                            {/* other products table */}
                            {contract?.plan?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_OTROSPRODUCTOS &&
                                <>{
                                screenSize.isDesktopOrBigger ?
                                <Stack space={8} className='table--movements'>
                                    {movements && movements.length>0 &&
                                        <Stack space={8}>
                                            <Inline space="between">
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_date_value'})}</Text>
                                                </Box>
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_operation_mayus'})}</Text>
                                                </Box>
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_import_mayus'})}</Text>
                                                </Box>
                                                <Box className='col'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_units_mayus'})}</Text>
                                                </Box>
                                                <Box className='col-icon'>
                                                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_detail_mayus'})}</Text>
                                                </Box>
                                            </Inline>
                                            <Divider/>
                                        </Stack>
                                    }

                                    <Box paddingY={12}>
                                        <Stack space={12}>
                                            {movements && movements.length===0 ?
                                                <Box paddingY={32}>
                                                    <EmptyCard
                                                        icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                                                        title={intl.formatMessage({id:'page_contract_data_movements_without_movements_title'})}
                                                        subtitle={intl.formatMessage({id:'page_contract_data_movements_without_movements_description'})}
                                                    />
                                                </Box>
                                            : movements && movements.map((movement, index) => (
                                                <Stack key={'table-movement-'+index} space={24}>

                                                    <Inline space='between' alignItems='center' className='row'>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.fechaValor}</Text1>
                                                        </Box>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.tipoOperacion}</Text1>
                                                        </Box>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.importe}</Text1>
                                                        </Box>
                                                        <Box className='col'>
                                                            <Text1 regular>{movement.info?.unidades}</Text1>
                                                        </Box>
                                                        <Box className='col-icon'>
                                                            <IconButton onPress={()=>{toggleExpandedMovement(index)}}>
                                                                {movement.expanded ?
                                                                <IconChevronUpRegular color={skinVars.colors.buttonPrimaryBackground}></IconChevronUpRegular>
                                                                :
                                                                    <IconChevronDownRegular color={skinVars.colors.buttonPrimaryBackground}></IconChevronDownRegular>
                                                                }
                                                            </IconButton>
                                                        </Box>
                                                    </Inline>
                                                    <ResponsiveLayout className={'detail-movements '+(movement.expanded ? 'visible': '')} backgroundColor={skinVars.colors.backgroundAlternative}>
                                                        <Box padding={40}>
                                                            <Stack space={32}>
                                                                <Grid columns={{ minSize: 100 }} gap={40}>
                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_operation_date'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.fechaOperacion}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_value_date'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.fechaValor}</Text2>
                                                                    </Stack>
                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_operation_type'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.operacion}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_import'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.importe}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_units'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.unidades}</Text2>
                                                                    </Stack>

                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contract_data_movements_liquidation_value'})}</Text2>
                                                                        <Text2 medium>{movement.detail?.valorLiquidativo}</Text2>
                                                                    </Stack>
                                                                </Grid>

                                                                <Grid columns={{ minSize: 100 }} gap={40}>
                                                                </Grid>
                                                            </Stack>
                                                        </Box>
                                                    </ResponsiveLayout>
                                                    <Divider />
                                                </Stack>
                                            ))}
                                        </Stack>
                                    </Box>
                                </Stack>
                            :
                                <>
                                    <Stack space={12}>
                                        {movements && movements.length===0 ?
                                            <Box paddingY={32}>
                                                <EmptyCard
                                                    icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                                                    title={intl.formatMessage({id:'page_contract_data_movements_without_movements_title'})}
                                                    subtitle={intl.formatMessage({id:'page_contract_data_movements_without_movements_description'})}
                                                />
                                            </Box>
                                        : movements && movements.map((movement, index) => (
                                            <Boxed key={'mobile-movement-'+index}>
                                                <Box padding={16}>
                                                    <Stack space={24}>
                                                        <Grid columns={2} gap={16}>
                                                            <Stack space={2}>
                                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_date'})}</Text1>
                                                                <Text3 medium>{movement.detail?.fechaOperacion}</Text3>
                                                            </Stack>

                                                            <Stack space={2}>
                                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_liquidation_operation'})}</Text1>
                                                                <Text3 medium>{movement.detail?.operacion}</Text3>
                                                            </Stack>

                                                            <Stack space={2}>
                                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_units'})}</Text1>
                                                                <Text3 medium>{movement.detail?.unidades}</Text3>
                                                            </Stack>

                                                            <Stack space={2}>
                                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_movements_import'})}</Text1>
                                                                <Text3 medium>{movement.detail?.importe}</Text3>
                                                            </Stack>
                                                        </Grid>

                                                        <ButtonLink onPress={() => {handleViewMoreFund(movement)}} aligned>
                                                        {intl.formatMessage({id:'page_contract_data_movements_show_more'})}
                                                            <IconArrowLineRightRegular color="currentColor" />
                                                        </ButtonLink>
                                                    </Stack>
                                                </Box>
                                            </Boxed>
                                        ))}
                                    </Stack>
                                </>
                                }
                            </>}
                        </Stack>
                    </Box>
                </>
        }</>
    );
};

export default Movements;