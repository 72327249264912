import { useEffect } from "react";
import { useIntl } from "react-intl";
import { validateCertificate, validateOperate } from "../../utils/apiclient";
import { setTokenSign } from "../../utils/storage";
import { trackError } from "../../utils/utils";
import { useAlert } from "../alerts/Alert";


export const useDigitalSign = () => {

    const intl = useIntl();
    const { showAlert } = useAlert()



	const digitalSign = (tokenOperation:string, onConfirm:() => void, onError: () => void): Promise<boolean> =>
        new Promise((resolve) => {
            const showSignResultCallback= (signatureB64:string, certificateB64:string, extraData:any)=>{
                //Validar certificado
                //console.log("Firma", signatureB64)
                //console.log("Certificado", certificateB64)

                validateCertificate({certificateSign: certificateB64}).then((result)=>{
                    if(result.result){
                        setTokenSign(signatureB64);
                        resolve(true);
                        onConfirm();
                        return;
                    }else{
                        showErrorCallback();
                    }
                }).catch((err)=>{
                    showErrorCallback();
                    if(err){
                        trackError(JSON.stringify(err));
                    }
                });
            }
            const showErrorCallback= ()=>{
                //alert
                onError();
                resolve(false);
            }

            if(window.AutoScript) {
                try{
                    window.AutoScript.cargarAppAfirma();
                    window.AutoScript.setServlets(window.location.origin + "/afirma-signature-storage/StorageService", window.location.origin + "/afirma-signature-retriever/RetrieveService");
                } catch(err) {

                    if(err){
                        trackError(JSON.stringify(err));
                      }
                }

                try {
                    window.AutoScript.sign(
                        tokenOperation,
                        'SHA512withRSA',
                        'XAdES',
                        'serverUrl=https://valide.redsara.es/firmaMovil/afirma-server-triphase-signer/SignatureService',
                        showSignResultCallback,
                        showErrorCallback);

                } catch(err) {
                    if(err){
                        trackError(JSON.stringify(err));
                    }
                }
            }

        return (<></>);
	});
	return { digitalSign };
};