import { Box, Boxed, Divider, DoubleField, IconDatabaseRegular, Inline, LoadingBar, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Tabs, Text, Text2, Text3, Text4, Text5, useScreenSize } from '@telefonica/mistica';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';

import EmptyCard from '../../../../../components/empty-card/EmptyCard';
import { TaxInformation } from '../../../../../entities/taxInformation/tax-information.model';
import { getPlanFiscalInformation } from '../../../../../utils/apiclient';
import { firstCapitalLetter, formatBalance } from '../../../../../utils/utils';
import './TaxInformationInvestmentCustomerPage.scss';

const TaxInformationPensionPlansCustomerPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [arrayYears, setArrayYears] = useState<string[]>([]);
  const [infoFiscal, setInfoFiscal] = useState({} as TaxInformation[]);

  useEffect(()=>{
    setLoading(true);
    getPlanFiscalInformation().then(infoFiscal=>{
      setLoading(false);
      setInfoFiscal(infoFiscal);
      setArrayYears(infoFiscal.map(info => intl.formatMessage({id:'page_tax_information_investment_plans_exercise'}) + info.year));
    });

  }, []);

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_tax_information_investment_plans_title'})}}
      title={intl.formatMessage({id:'page_tax_information_investment_plans_title'})}
      titleResponsive={intl.formatMessage({id:'page_tax_information_investment_plans_title_responsive'})}
      pageInfo={{option: process.env.REACT_APP_MENU_INFORMACION_FISCAL_PLANES_PENSIONES, role:'client'}}
      showBack
    >
      <ResponsiveLayout className='inner first'>
      <LoadingBar visible={loading}/>
        <Box paddingBottom={40}>
          <Stack space={16}>
            <Text5>{intl.formatMessage({id:'page_tax_information_investment_plans_title'})}</Text5>
            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_subtitle'})}</Text2>
          </Stack>
        </Box>
        {infoFiscal.length === 0 ? (
          <EmptyCard
            icon = {<IconDatabaseRegular size={52} color={skinVars.colors.brand} />}
            title={intl.formatMessage({id:'page_tax_information_investment_plans_empty_card_title'})}
            subtitle={intl.formatMessage({id:'page_tax_information_investment_plans_empty_card_subtitle'})}
          />
          ) : (
            <Box paddingBottom={40}>
              {arrayYears.length > 0 &&
                <Tabs
                  selectedIndex={selectedIndex}
                  onChange={setSelectedIndex}
                  tabs={arrayYears.map((text) => ({
                      text,
                  }))}
                />
              }
              {loading &&
                <SkeletonRectangle ariaLabel="loading" height={20}/>
              }
              <>
                {screenSize.isDesktopOrBigger ?
                  <>
                    <Box paddingTop={32} paddingBottom={32}>
                      {infoFiscal[selectedIndex] && selectedIndex !== 0 ? (
                          <Text4 medium>{intl.formatMessage({id:'page_tax_information_investment_plans_contributions'})} ({infoFiscal[selectedIndex].year})</Text4>
                        ) : (
                          <Text4 medium>{intl.formatMessage({id:'page_tax_information_investment_plans_contributions'})}</Text4>
                        )
                      }
                    </Box>

                    <Box paddingBottom={40}>
                      <Stack space={8} className='table--taxInformationPlans'>
                        <Inline space="between" alignItems='center'>
                          <Box className='col-1'>
                            <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_column_product'})}</Text>
                          </Box>
                          <Box className='col-2'>
                            <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_column_direct_participant'})}</Text>
                          </Box>
                          <Box className='col-3'>
                            <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_column_of_the_promoter'})}</Text>
                          </Box>
                          <Box className='col-4'>
                            <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_column_in_favor_of_spouse'})}</Text>
                          </Box>
                          <Box className='col-5'>
                            <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_column_in_favor_of_disabled'})}</Text>
                          </Box>
                        </Inline>
                        <Divider/>

                        {loading ?
                          <Stack space={8}>
                            <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                            <Divider />
                            <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                            <Divider />
                          </Stack>
                        : infoFiscal[selectedIndex] && infoFiscal[selectedIndex].informacion_fiscal.map((infoFiscal, index) => (
                              <Box key={`${infoFiscal.anioFiscal}-${index}`}>
                                <Inline space='between' alignItems='center' className='row'>
                                  <Box className='col-1'>
                                    <Text2 regular>{infoFiscal.contrato?.plan?.descripcion}</Text2>
                                  </Box>
                                  <Box className='col-2'>
                                    {infoFiscal.apoPar ? (
                                        <Text2 regular>{formatBalance(parseFloat(infoFiscal.apoPar))}€</Text2>
                                      ) : (
                                        <Text2 regular>0,00€</Text2>
                                      )
                                    }
                                  </Box>
                                  <Box className='col-3'>
                                    {infoFiscal.apoPro ? (
                                        <Text2 regular>{formatBalance(parseFloat(infoFiscal.apoPro))}€</Text2>
                                      ) : (
                                        <Text2 regular>0,00€</Text2>
                                      )
                                    }
                                  </Box>
                                  <Box className='col-4'>
                                    {infoFiscal.apoCon ? (
                                        <Text2 regular>{formatBalance(parseFloat(infoFiscal.apoCon))}€</Text2>
                                      ) : (
                                        <Text2 regular>0,00€</Text2>
                                      )
                                    }
                                  </Box>
                                  <Box className='col-5'>
                                    {infoFiscal.apoMin ? (
                                        <Text2 regular>{formatBalance(parseFloat(infoFiscal.apoMin))}€</Text2>
                                      ) : (
                                        <Text2 regular>0,00€</Text2>
                                      )
                                    }
                                  </Box>
                                </Inline>
                                <Divider/>
                              </Box>
                            )
                          )
                        }
                      </Stack>
                    </Box>

                    <Box paddingTop={32} paddingBottom={32}>
                      {infoFiscal[selectedIndex] && selectedIndex !== 0 ? (
                          <Text4 medium>{intl.formatMessage({id:'page_tax_information_investment_plans_withholdings'})} ({infoFiscal[selectedIndex].year})</Text4>
                        ) : (
                          <Text4 medium>{intl.formatMessage({id:'page_tax_information_investment_plans_withholdings'})}</Text4>
                        )
                      }
                    </Box>

                    <Box paddingBottom={40}>
                      <Stack space={8} className='table--taxInformationPlans'>
                        <Inline space="between" alignItems='center'>
                          <Box className='col-2'>
                            <Text transform="uppercase" size={10} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_column_product'})}</Text>
                          </Box>
                          <Box className='col-2'>
                            <Text transform="uppercase" size={10} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_column_capital_benefits_received'})}</Text>
                          </Box>
                          <Box className='col-3'>
                            <Text transform="uppercase" size={10} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_column_capital_benefits_received_income'})}</Text>
                          </Box>
                          <Box className='col-4'>
                            <Text transform="uppercase" size={10} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_column_capital_withholdings_received'})}</Text>
                          </Box>
                          <Box className='col-5'>
                            <Text transform="uppercase" size={10} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_column_capital_withholdings_received_income'})}</Text>
                          </Box>
                          <Box className='col-5'>
                            <Text transform="uppercase" size={10} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_plans_column_capital_amount_reductions'})}</Text>
                          </Box>
                        </Inline>
                        <Divider/>

                        {loading ?
                          <Stack space={8}>
                            <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                            <Divider />
                            <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                            <Divider />
                          </Stack>
                        : infoFiscal[selectedIndex] && infoFiscal[selectedIndex].informacion_fiscal.map((infoFiscal, index) => (
                              <Box key={`${infoFiscal.anioFiscal}-${index}`}>
                                <Inline space='between' alignItems='center' className='row'>
                                  <Box className='col-2'>
                                    <Text2 regular>{infoFiscal.contrato?.plan?.descripcion}</Text2>
                                  </Box>
                                  <Box className='col-2'>
                                    {infoFiscal.preCap ? (
                                        <Text2 regular>{formatBalance(parseFloat(infoFiscal.preCap))}€</Text2>
                                      ) : (
                                        <Text2 regular>0,00€</Text2>
                                      )
                                    }
                                  </Box>
                                  <Box className='col-3'>
                                    {infoFiscal.preRen ? (
                                        <Text2 regular>{formatBalance(parseFloat(infoFiscal.preRen))}€</Text2>
                                      ) : (
                                        <Text2 regular>0,00€</Text2>
                                      )
                                    }
                                  </Box>
                                  <Box className='col-4'>
                                    {infoFiscal.retCap ? (
                                        <Text2 regular>{formatBalance(parseFloat(infoFiscal.retCap))}€</Text2>
                                      ) : (
                                        <Text2 regular>0,00€</Text2>
                                      )
                                    }
                                  </Box>
                                  <Box className='col-5'>
                                    {infoFiscal.retRen ? (
                                        <Text2 regular>{formatBalance(parseFloat(infoFiscal.retRen))}€</Text2>
                                      ) : (
                                        <Text2 regular>0,00€</Text2>
                                      )
                                    }
                                  </Box>
                                  <Box className='col-5'>
                                    {infoFiscal.impRed ? (
                                        <Text2 regular>{formatBalance(parseFloat(infoFiscal.impRed))}€</Text2>
                                      ) : (
                                        <Text2 regular>0,00€</Text2>
                                      )
                                    }
                                  </Box>
                                </Inline>
                                <Divider/>
                              </Box>
                          ))
                        }
                      </Stack>
                    </Box>
                  </>
                :
                  <Box paddingY={12}>
                  <Stack space={12}>
                    {loading ?
                        <>
                          <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                          <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                        </>
                    : infoFiscal[selectedIndex] && infoFiscal[selectedIndex].informacion_fiscal.map((infoFiscal, index)  => (
                      <Stack key={index} space={12}>
                        <Boxed>
                          <Box paddingX={16} paddingY={24}>
                            <Stack space={24}>
                              <Text4 medium>{infoFiscal.contrato?.plan?.descripcion} ({intl.formatMessage({id:'page_tax_information_investment_plans_contributions'})})</Text4>

                              <DoubleField layout="50/50">
                                <Stack space={2}>
                                  <Text>{firstCapitalLetter(intl.formatMessage({id:'page_tax_information_investment_plans_column_direct_participant'}))}</Text>
                                    {infoFiscal.apoPar ? (
                                        <Text3 medium>{formatBalance(parseFloat(infoFiscal.apoPar))}€</Text3>
                                      ) : (
                                        <Text3 medium>0,00€</Text3>
                                      )
                                    }
                                </Stack>

                                <Stack space={2}>
                                  <Text>{firstCapitalLetter(intl.formatMessage({id:'page_tax_information_investment_plans_column_of_the_promoter'}))}</Text>
                                    {infoFiscal.apoPro ? (
                                        <Text3 medium>{formatBalance(parseFloat(infoFiscal.apoPro))}€</Text3>
                                      ) : (
                                        <Text3 medium>0,00€</Text3>
                                      )
                                    }
                                </Stack>
                              </DoubleField>

                              <DoubleField layout="50/50">
                                <Stack space={2}>
                                  <Text>{firstCapitalLetter(intl.formatMessage({id:'page_tax_information_investment_plans_column_in_favor_of_spouse'}))}</Text>
                                    {infoFiscal.apoCon ? (
                                        <Text3 medium>{formatBalance(parseFloat(infoFiscal.apoCon))}€</Text3>
                                      ) : (
                                        <Text3 medium>0,00€</Text3>
                                      )
                                    }
                                </Stack>

                                <Stack space={2}>
                                  <Text>{firstCapitalLetter(intl.formatMessage({id:'page_tax_information_investment_plans_column_in_favor_of_disabled'}))}</Text>
                                    {infoFiscal.apoMin ? (
                                        <Text3 medium>{formatBalance(parseFloat(infoFiscal.apoMin))}€</Text3>
                                      ) : (
                                        <Text3 medium>0,00€</Text3>
                                      )
                                    }
                                </Stack>
                              </DoubleField>
                            </Stack>
                          </Box>
                        </Boxed>
                        <Boxed>
                          <Box paddingX={16} paddingY={24}>
                            <Stack space={24}>
                              <Text4 medium>{infoFiscal.contrato?.plan?.descripcion} ({intl.formatMessage({id:'page_tax_information_investment_plans_benefits_withholdings'})})</Text4>

                              <DoubleField layout="50/50">
                                <Stack space={2}>
                                  <Text>{intl.formatMessage({id:'page_tax_information_investment_plans_column_capital_benefits_received'})}</Text>
                                    {infoFiscal.preCap ? (
                                        <Text3 medium>{formatBalance(parseFloat(infoFiscal.preCap))}€</Text3>
                                      ) : (
                                        <Text3 medium>0,00€</Text3>
                                      )
                                    }
                                </Stack>

                                <Stack space={2}>
                                  <Text>{intl.formatMessage({id:'page_tax_information_investment_plans_column_capital_benefits_received_income'})}</Text>
                                    {infoFiscal.preRen ? (
                                        <Text3 medium>{formatBalance(parseFloat(infoFiscal.preRen))}€</Text3>
                                      ) : (
                                        <Text3 medium>0,00€</Text3>
                                      )
                                    }
                                </Stack>
                              </DoubleField>

                              <DoubleField layout="50/50">
                                <Stack space={2}>
                                  <Text>{intl.formatMessage({id:'page_tax_information_investment_plans_column_capital_withholdings_received'})}</Text>
                                    {infoFiscal.retCap ? (
                                        <Text3 medium>{formatBalance(parseFloat(infoFiscal.retCap))}€</Text3>
                                      ) : (
                                        <Text3 medium>0,00€</Text3>
                                      )
                                    }
                                </Stack>

                                <Stack space={2}>
                                  <Text>{intl.formatMessage({id:'page_tax_information_investment_plans_column_capital_withholdings_received_income'})}</Text>
                                    {infoFiscal.retRen ? (
                                        <Text3 medium>{formatBalance(parseFloat(infoFiscal.retRen))}€</Text3>
                                      ) : (
                                        <Text3 medium>0,00€</Text3>
                                      )
                                    }
                                </Stack>
                              </DoubleField>

                              <DoubleField layout="50/50">
                                <Stack space={2}>
                                  <Text>{intl.formatMessage({id:'page_tax_information_investment_plans_column_capital_amount_reductions'})}</Text>
                                    {infoFiscal.impRed ? (
                                        <Text3 medium>{formatBalance(parseFloat(infoFiscal.impRed))}€</Text3>
                                      ) : (
                                        <Text3 medium>0,00€</Text3>
                                      )
                                    }
                                </Stack>
                              </DoubleField>
                            </Stack>
                          </Box>
                        </Boxed>
                      </Stack>
                      ))
                    }
                  </Stack>
                  </Box>
                }
              </>
            </Box>
          )
        }

      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default TaxInformationPensionPlansCustomerPage;
