import { Box, ButtonPrimary, Carousel, Hero, Image, Inline, ResponsiveLayout, Slideshow, Stack, Text2, Text3, skinVars, useScreenSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import '../LandingPage.scss';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import { useIonRouter } from '@ionic/react';

const OfficePage: React.FC = () => {
  const intl = useIntl()
  const size = useScreenSize();
	const router = useIonRouter();
  const screenSize = useScreenSize();

  return (
    <LayoutPublic
      landing={true}
      header={{small:true}}
      seo={{metaTitle: 'Oficina', metaDescription:'Oficina'}}
    >
      <ResponsiveLayout className='content' fullWidth backgroundColor={skinVars.colors.backgroundAlternative}>

        <Box paddingBottom={40}>
          <Slideshow
            withBullets
            inverseBullets={false}
            items={[
              <Hero
                background="alternative"
                media={ <Image src="../assets/img/oficinas.jpg" border height={450} aspectRatio={'16:9'}></Image> }
                title="¡Más cerca de ti!"
                description='Venir a conocer la nueva oficina de Fonditel en “La Ronda” de Distrito Telefónica ahora tiene regalo.'
                desktopMediaPosition="right"
              />,
              <Hero
                background="alternative"
                media={ <Image src="../assets/img/oficinas.jpg" border height={450} aspectRatio={'16:9'}></Image> }
                title="¡Más cerca de ti! (02)"
                description='Venir a conocer la nueva oficina de Fonditel en “La Ronda” de Distrito Telefónica ahora tiene regalo.'
                desktopMediaPosition="right"
              />,
              <Hero
                background="alternative"
                media={ <Image src="../assets/img/oficinas.jpg" border height={450} aspectRatio={'16:9'}></Image> }
                title="¡Más cerca de ti! (03)"
                description='Venir a conocer la nueva oficina de Fonditel en “La Ronda” de Distrito Telefónica ahora tiene regalo.'
                desktopMediaPosition="right"
              />
            ]}
          />
        </Box>

      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth backgroundColor={skinVars.colors.backgroundAlternative}>
          <ResponsiveLayout>

            <Box paddingTop={24} paddingBottom={56}>
              <Stack space={24}>
                <div className='html-content' dangerouslySetInnerHTML={{__html:
                  `<p>Hasta el 30 de junio si vienes a visitarnos y realizas una suscripción o un traspaso en favor de un fondo de inversión* de Fonditel, a través de la web/app o nuestra oficina en Distrito Telefónica; por importe igual o superior a 10.000 euros, te regalamos una Mochila Samsonite Guardit 2.0©.</p>
                   <p>O si el importe es igual o superior a 2.000 euros, te regalamos un cargador inalámbrico hecho en ABS con acabado de goma.</p>`
                }}></div>

                <Text2 medium>Promoción válida hasta fin de existencias.</Text2>

                <Inline space={'evenly'}>
                  <ButtonPrimary onPress={() => {}}>Consulta Bases Legales</ButtonPrimary>
                </Inline>
              </Stack>
            </Box>

          </ResponsiveLayout>
      </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default OfficePage;