import { IonHeader, useIonRouter } from '@ionic/react';
import { Avatar, Badge, Box, Boxed, Divider, IconAddMoreCircleRegular, IconBellRegular, IconChevronDownLight, IconChevronDownRegular, IconChevronLeftRegular, IconChevronRightRegular, IconChevronUpLight, IconChevronUpRegular, IconCloseRegular, IconHeadphonesLight, IconHeadphonesRegular, IconListDocumentLight, IconMenuRegular, IconTimeLight, Image, Inline, MainNavigationBar, ResponsiveLayout, Row, RowList, Select, Stack, Text, Text2, Text3, Text4, Text7, Touchable, dialog, skinVars, useScreenSize, useWindowSize } from '@telefonica/mistica';
import * as React from 'react';

import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import { NavOption } from '../../entities/commons/nav-option.model';
import BiometricLoginPage from '../../pages/private/login/BiometricLoginPage';
import LoginPage from '../../pages/private/login/LoginPage';
import ChangeOperationsKeyCustomerPage from '../../pages/private/users/customer/change-operations-key/ChangeOperationsKeyCustomerPage';
import ChangePasswordCustomerPage from '../../pages/private/users/customer/change-password/ChangePasswordCustomerPage';
import ContractCustomerPage from '../../pages/private/users/customer/contract/ContractCustomerPage';
import ContractsCustomerPage from '../../pages/private/users/customer/contracts/ContractsCustomerPage';
import NoticesCustomerPage from '../../pages/private/users/customer/notices/NoticesCustomerPage';
import OperationsInProgressCustomerPage from '../../pages/private/users/customer/operations-in-progress/OperationsInProgressCustomerPage';
import ProfileCustomerPage from '../../pages/private/users/customer/profile/ProfileCustomerPage';
import SelectProfile from '../../pages/private/users/select-profile/SelectProfile';
import UserMenuPage from '../../pages/private/users/user-menu/UserMenuPage';
import HomePage from '../../pages/public/home/HomePage';
import RecordEmploymentRegulationPage from '../../pages/public/landings/record-employment-regulation/RecordEmploymentRegulationPage';
import { getNumberAdvises, getNumberCourseOperations, getPlans } from '../../utils/apiclient';
import { getOptionNavigationInverse, getPrivateNavigation, getPrivateNavigationResponsive, getUrlNavigation } from '../../utils/navigation';
import { getNumberAdvisesCache, getNumberCourseOperationsCache, getUser, removeUser, selectPlanComisionControl, selectPlanPromotor, selectPromotor } from '../../utils/storage';
import { formatPlanComisionControl, formatPlanPromotor, getCapitalizeText, getInitials, havePermission, isClient, isControlCommision, isNativeApp, isPromoter, trackEvent, vibration } from '../../utils/utils';
import ButtonTertiary from '../buttons/ButtonTertiary';
import './HeaderPrivateArea.scss';

export interface HeaderPrivateAreaProps {
    title?: string,
    parent?: {title:string, item:React.FC},
    welcome?: boolean,
    showBigAvatar?:boolean,
    showBack?:boolean,
    showFooterMenu?:boolean,
    onChangeUser?: () => void,
}
const HeaderPrivateArea: React.FC<HeaderPrivateAreaProps> = (prop) => {
    const screenSize = useScreenSize();
    const windowSize = useWindowSize();
    const router = useIonRouter();
    const intl = useIntl();

    const [numberAdvises, setNumberAdvises] = useState(0);
    const [numberCourseOperations, setNumberCourseOperations] = useState(0);
    const [showBackButton, setShowBackButton] = useState(true);
    const [showMenuVertical, setShowMenuVertical] = useState(false);
    const [showMenuAside, setShowMenuAside] = useState(false);
    const [user, setUser] = useState(getUser());
    const [idPromotor, setIdPromotor] = useState(getUser()?.selectedPromotor??'');
    const [planPromotor, setPlanPromotor] = useState(getUser()?.selectedPlanPromotor??'');
    const [planComisionControl, setPlanComisionControl] = useState(getUser()?.selectedPlanComisionControl??'');
    const ref = React.useRef<HTMLIonHeaderElement>(null);

    let retrieveNumberAdvisesRef = useRef<number>();
    let retrieveNumberCourseOperationsRef = useRef<number>();


    const [privateNavigation, setPrivateNavigation] = React.useState([] as NavOption[])
    const [privateNavigationResponsive, setPrivateNavigationResponsive] = React.useState([] as NavOption[])
    useLayoutEffect(()=>{
        const currentPage = getOptionNavigationInverse(router.routeInfo.pathname);
        //Number advises
        if(!(currentPage && (currentPage?.props.children.type.name===NoticesCustomerPage.name))){
            const resultNumberAdvisesCache = getNumberAdvisesCache(false);
            if(resultNumberAdvisesCache){
                setNumberAdvises(resultNumberAdvisesCache.result);
            }
        }
        retrieveNumberAdvises();

        //Number operations
        const resultNumberCourseOperationsCache = getNumberCourseOperationsCache(false);
        if(resultNumberCourseOperationsCache){
            setNumberCourseOperations(resultNumberCourseOperationsCache.result);
        }
        retrieveNumberCourseOperations();

        if(router?.routeInfo?.lastPathname){
            const prevPage = getOptionNavigationInverse(router.routeInfo.lastPathname);

            if(prevPage && (prevPage?.props.children.type.name===LoginPage.name) || (prevPage?.props.children.type.name===BiometricLoginPage.name)){
                setShowBackButton(false);
            }else{
                setShowBackButton(true);
            }
        }

        const options = getPrivateNavigation(user.selectedRole);

        /* CUANDO SE ELIMINE LANDING DE ERE BORRAR */
        let acceso = false;

        const accesoERE = {
            title: "menu_private_customer_ere_access",
            page: RecordEmploymentRegulationPage, icon: <IconListDocumentLight color="currentColor"  />
          }; 

        if(getUser()){
            getPlans().then((plan)=>{
                plan.map((product) => {
                    if((product.product && product.product.cdFondo && product.product.cdFondo == '100') || (product.product && product.product.cdPlan && product.product.cdPlan == '210') || (product.product && product.product.cdPlan && product.product.cdPlan == '289')){
                        acceso = true;
                    }
                });
            }).finally(() => {
                if(acceso){
                    options.push(accesoERE);
                }
            });
        }
        /* ************************************** */

        let subOptionSelected = -1;
        let optionSelected = -1;
        options.map(item=> {
            if(item.options && subOptionSelected===-1){
                subOptionSelected = item.options.findIndex(subitem=>router.routeInfo.pathname.indexOf(getUrlNavigation(subitem.page))!==-1);

                if(subOptionSelected!=-1){
                    optionSelected = options.findIndex(option=>option===item);
                }
            }
        });

        if(optionSelected!=-1){
            options[optionSelected].showOptions = options[optionSelected].showOptions && options[optionSelected].showOptions===true ? false : true;
        }
        setPrivateNavigation(options);


        const optionsResponsive = getPrivateNavigationResponsive(user.selectedRole);
        setPrivateNavigationResponsive(optionsResponsive);
    }, []);


    const [optionMenuSelected, setOptionMenuSelected] = React.useState(-1)
    const [optionSubmenuSelected, setOptionSubmenuSelected] = React.useState(-1)
    useLayoutEffect(()=>{
        if(privateNavigation){
            let optionSelected = -1;
            let subOptionSelected = -1;
            privateNavigation.map(item=> {
                if(item.options && subOptionSelected===-1){
                    subOptionSelected = item.options.findIndex(subitem=>router.routeInfo.pathname===(subitem.page ? getUrlNavigation(subitem.page) : null));

                    if(subOptionSelected===-1){
                        const pathname = router.routeInfo.pathname.substring(0, router.routeInfo.pathname.lastIndexOf('/'));
                        subOptionSelected = item.options.findIndex(subitem=>pathname===(getUrlNavigation(subitem.page)));

                        if(subOptionSelected===-1){
                            subOptionSelected = item.options.findIndex(subitem=>router.routeInfo.pathname.indexOf(getUrlNavigation(subitem.page))!==-1);
                        }
                    }

                    if(subOptionSelected!=-1){
                        optionSelected = privateNavigation.findIndex(option=>option===item);
                        setOptionMenuSelected(optionSelected);
                    }
                }
            });
            setOptionSubmenuSelected(subOptionSelected);

            if(subOptionSelected===-1){
                optionSelected = privateNavigation.findIndex(item=>router.routeInfo.pathname.indexOf(getUrlNavigation(item.page))!==-1);
                setOptionMenuSelected(optionSelected);
            }
        }
    }, [privateNavigation]);


    const [optionMenuResponsiveSelected, setOptionMenuResponsiveSelected] = React.useState(-1)
    useLayoutEffect(()=>{
        if(privateNavigationResponsive){
            let optionSelected = privateNavigationResponsive.findIndex(item=>router.routeInfo.pathname.indexOf(getUrlNavigation(item.page))!==-1);
            setOptionMenuResponsiveSelected(optionSelected);
        }
    }, [privateNavigationResponsive]);

    function retrieveNumberAdvises(setValue=true){
        if(!retrieveNumberAdvisesRef || !retrieveNumberAdvisesRef.current){

            let interValMs = 15000;
            retrieveNumberAdvisesRef.current = setInterval(retrieveNumberAdvises, interValMs);
        }

        getNumberAdvises().then((result)=>{
            if(result){
                setNumberAdvises(result.result);
            }
        });
    }

    function retrieveNumberCourseOperations(setValue=true){
        if(!retrieveNumberCourseOperationsRef || !retrieveNumberCourseOperationsRef.current){
            let interValMs = 15000;
            const currentPage = getOptionNavigationInverse(router.routeInfo.pathname);
            if((currentPage && (currentPage?.props.children.type.name===OperationsInProgressCustomerPage.name))){
                interValMs=3000;
            }
            retrieveNumberCourseOperationsRef.current = setInterval(retrieveNumberCourseOperations, interValMs);
        }
        getNumberCourseOperations().then((result)=>{
            if(result){
                setNumberCourseOperations(result.result);
            }
        });
    }

    useEffect(()=>{
        handleChangePromotor(getUser()?.selectedPromotor??'');
        handleChangePlanPromotor(getUser()?.selectedPlanPromotor??'');
        handleChangePlanComisionControl(getUser()?.selectedPlanComisionControl??'');
    }, [screenSize])

    const handleClickNavMainOption = (index:number) =>{
        if(privateNavigation[index].page!==undefined){
            if(privateNavigation[index]!==undefined && privateNavigation[index].page!==undefined){
                trackEvent('perfil-menu-'+slugify(intl.formatMessage({id:privateNavigation[index].title ?? ''})).replaceAll('-', '_'));
                router.push(getUrlNavigation(privateNavigation[index]?.page??HomePage));
            }
        }else{
            const newValues = [
                ...privateNavigation
            ];
            newValues[index].showOptions = newValues[index].showOptions && newValues[index].showOptions===true ? false : true;
            setPrivateNavigation(newValues);
        }
    }
    const [showUserMenu, setShowUserMenu] = useState(false);


    function handleLogout() {
        removeUser(true);
        router.push(getUrlNavigation(LoginPage));
    }

    function handleMenuVertical() {
        setShowMenuVertical(!showMenuVertical);
    }

    function handleMenuAside(){
        setShowMenuAside(!showMenuAside);
    }

    const handleChangePromotor = (promotorId: string) =>{
        setIdPromotor(promotorId);
        const userUpdated = selectPromotor(promotorId);
        if(userUpdated){
            setUser(userUpdated);
            if(userUpdated.selectedPlanPromotor){
                setPlanPromotor(userUpdated.selectedPlanPromotor);
            }
        }
        if(prop.onChangeUser){
            prop.onChangeUser();
        }
    }

    const handleChangePlanPromotor = (planPromotorId: string) =>{
        setPlanPromotor(planPromotorId);
        selectPlanPromotor(planPromotorId);
        if(prop.onChangeUser){
            prop.onChangeUser();
        }
    }

    const handleChangePlanComisionControl = (planComisionControlId: string) =>{
        setPlanComisionControl(planComisionControlId);
        selectPlanComisionControl(planComisionControlId);
        if(prop.onChangeUser){
            prop.onChangeUser();
        }
    }




    React.useEffect(() => {
        return () => {
            try{
                clearInterval(retrieveNumberAdvisesRef.current);
            }catch(err){}
            try{
                clearInterval(retrieveNumberCourseOperationsRef.current);
            }catch(err){}
        };
      }, []);

    return (
        <>
            {user &&
                <ResponsiveLayout fullWidth={true} backgroundColor={skinVars.colors.background}>
                    <IonHeader ref={ref} id="header-private" translucent={true} className={"ion-no-border"+(prop.welcome ? 'big' : 'small')}>
                        {screenSize.isDesktopOrBigger &&
                            <ResponsiveLayout fullWidth>
                                <Stack space={0}>
                                    <Box className={"menu-nav fixed"}>
                                        <style>{'.menu-nav [data-component-name="MainNavigationBar"]{background:'+(skinVars.colors.background)+'!important}'}</style>
                                        <MainNavigationBar
                                            logo={<Touchable className='logo' onPress={()=>{handleClickNavMainOption(0)}}>
                                                    <Image src={"/assets/img/logo.svg"} width={112} aspectRatio={0} alt={intl.formatMessage({id:'site_name'})} noBorderRadius={true} ></Image>
                                                </Touchable>}
                                            topFixed={true} sections={[]}
                                            right={
                                                <Inline fullWidth space={64} alignItems='center'>
                                                    <Touchable onPress={()=>{
                                                    dialog({
                                                            title:intl.formatMessage({id:'menu_header_private_area_phone'}),
                                                            message:'',
                                                            extra:<Stack space={4}>
                                                                    <Text3 regular color={skinVars.colors.neutralMediumInverse}>{intl.formatMessage({id:'menu_header_private_area_schedule_title'})}</Text3>
                                                                    <Text3 regular color={skinVars.colors.neutralMediumInverse}>{intl.formatMessage({id:'menu_header_private_area_schedule_description'})}</Text3>
                                                                    <Text3 regular color={skinVars.colors.neutralMediumInverse}>{intl.formatMessage({id:'menu_header_private_area_schedule_face_to_face_attention'})}</Text3>
                                                                </Stack>,
                                                            acceptText: intl.formatMessage({id:'menu_header_private_area_call_now'}),
                                                            icon: <IconHeadphonesLight color={skinVars.colors.brand}></IconHeadphonesLight>,
                                                            onCancel: () => {},
                                                            onAccept: () => window.open( `tel:${intl.formatMessage({id:'menu_header_phone'}).replaceAll(' ', '')}`),
                                                            forceWeb: true,
                                                            showCancel: true
                                                    });
                                                    }}>
                                                        <Inline space={8} alignItems={'center'}>
                                                            <IconHeadphonesRegular color={"currentColor"} />
                                                            <Text size={16} color={"currentColor"}>{intl.formatMessage({id:'menu_header_private_area_need_help'})}</Text>
                                                        </Inline>
                                                    </Touchable>

                                                    <Inline space={32} alignItems='center'>

                                                        {isClient() && havePermission(process.env.REACT_APP_MENU_OPERACIONES_CURSO) &&
                                                            <Touchable onPress={()=> { router.push(getUrlNavigation(OperationsInProgressCustomerPage)); trackEvent('perfil-operaciones');  }}>
                                                                <Inline space={8} alignItems={'center'}>
                                                                    <Badge value={numberCourseOperations}>
                                                                        <IconTimeLight />
                                                                    </Badge>
                                                                    <Text size={16}>{intl.formatMessage({id:'menu_header_private_area_operations'})}</Text>
                                                                </Inline>
                                                            </Touchable>
                                                        }
                                                        {isClient() && havePermission(process.env.REACT_APP_MENU_POSICION_GLOBAL) &&
                                                            <Touchable onPress={()=> {router.push(getUrlNavigation(NoticesCustomerPage)); trackEvent('perfil-avisos');} }>
                                                                <Inline space={8} alignItems={'center'}>
                                                                    <Badge value={numberAdvises}>
                                                                        <IconBellRegular />
                                                                    </Badge>
                                                                    <Text size={16}>{intl.formatMessage({id:'menu_header_private_area_notices'})}</Text>
                                                                </Inline>
                                                            </Touchable>
                                                        }
                                                        <Touchable onPress={()=>{setShowUserMenu(!showUserMenu)}}>
                                                            <Inline space={8} alignItems={'center'}>
                                                                <Avatar size={32} initials={getInitials(user.nombre+' '+user.apellidos)} />
                                                                <Text>{getCapitalizeText(user.nombre+' '+user.apellidos)}</Text>
                                                                {showUserMenu ?
                                                                    <IconChevronUpRegular size={12}></IconChevronUpRegular>
                                                                    : <IconChevronDownRegular size={12}></IconChevronDownRegular>
                                                                }
                                                            </Inline>
                                                        </Touchable>
                                                    </Inline>
                                                </Inline>
                                            }
                                        />
                                    </Box>
                                </Stack>
                            </ResponsiveLayout>
                        }
                        <ResponsiveLayout className={"user-menu-fixed "+(showUserMenu ? "visible" : "")}>
                            <Inline space={'between'}>
                                <Row title=''></Row>
                                <Boxed>
                                    <Box padding={16}>
                                        <Stack space={24}>
                                            {isClient() && havePermission(process.env.REACT_APP_MENU_OPERATIVA_WEB_CAMBIAR_SITUACION_CONTRATOS) &&
                                                <Touchable onPress={()=> router.push(getUrlNavigation(ContractsCustomerPage))}>
                                                    <Text2 regular>{intl.formatMessage({id:'menu_header_private_area_download_contract'})}</Text2>
                                                </Touchable>
                                            }
                                            {isClient() && havePermission(process.env.REACT_APP_MENU_OPERATIVA_WEB_MIS_DATOS) &&
                                                <Touchable onPress={()=> router.push(getUrlNavigation(ProfileCustomerPage))}>
                                                    <Text2 regular>{intl.formatMessage({id:'menu_header_private_area_edit_profile_data'})}</Text2>
                                                </Touchable>
                                            }
                                            {isClient() && havePermission(process.env.REACT_APP_MENU_OPERATIVA_WEB_CAMBIAR_PASSWORD) &&
                                                <Touchable onPress={()=> router.push(getUrlNavigation(ChangePasswordCustomerPage))}>
                                                    <Text2 regular>{intl.formatMessage({id:'menu_header_private_area_change_password'})}</Text2>
                                                </Touchable>
                                            }
                                            {isClient() && havePermission(process.env.REACT_APP_MENU_OPERATIVA_WEB_CAMBIAR_CLAVE_OPERACIONES) &&
                                                <Touchable onPress={()=> router.push(getUrlNavigation(ChangeOperationsKeyCustomerPage))}>
                                                    <Text2 regular>{intl.formatMessage({id:'menu_header_private_area_change_operation_key'})}</Text2>
                                                </Touchable>
                                            }
                                            {(user.roles && user.roles.length>1)  &&
                                                <Touchable onPress={()=> router.push(getUrlNavigation(SelectProfile))}>
                                                    <Text2 regular>{intl.formatMessage({id:'menu_header_private_area_change_profile'})}</Text2>
                                                </Touchable>
                                            }
                                            <Touchable  onPress={() => handleLogout()}>
                                                <Text2 regular color={skinVars.colors.neutralMedium}>{intl.formatMessage({id:'menu_header_private_area_sign_off'})}</Text2>
                                            </Touchable>
                                        </Stack>
                                    </Box>
                                </Boxed>
                            </Inline>
                        </ResponsiveLayout>
                        {(screenSize.isDesktopOrBigger || prop.welcome) ?
                            <>
                                <ResponsiveLayout isInverse className={'profile-nav fixed'} backgroundColor={skinVars.colors.backgroundBrand}>
                                    <ResponsiveLayout fullWidth>
                                        <Box paddingTop={8}>
                                            <Stack space={0}>
                                                {!screenSize.isDesktopOrBigger &&
                                                    <Inline alignItems='center' space={'between'}>
                                                        {prop.showBack && prop.showBack===true && showBackButton &&
                                                            <Touchable onPress={()=>{vibration();router.goBack()}}>
                                                                <IconChevronLeftRegular />
                                                            </Touchable>
                                                        }
                                                        {prop.showFooterMenu && !isNativeApp ?
                                                            <Touchable onPress={() =>{handleMenuVertical()}}>
                                                                <IconMenuRegular></IconMenuRegular>
                                                            </Touchable>
                                                            : <span></span>
                                                        }
                                                        {!prop.showBigAvatar &&
                                                            <Inline space={16} alignItems='center'>
                                                                {isClient() && havePermission(process.env.REACT_APP_MENU_POSICION_GLOBAL) &&
                                                                    <Touchable onPress={()=> {vibration();router.push(getUrlNavigation(NoticesCustomerPage)); trackEvent('perfil-avisos');}}>
                                                                        <Inline space={8} alignItems={'center'}>
                                                                            <Badge value={numberAdvises}>
                                                                                <IconBellRegular />
                                                                            </Badge>
                                                                        </Inline>
                                                                    </Touchable>
                                                                }

                                                                <Touchable onPress={()=> {vibration();router.push(getUrlNavigation(UserMenuPage))}}>
                                                                    <Inline space={8} alignItems={'center'}>
                                                                        <Avatar size={32} initials={getInitials(user.nombre+' '+user.apellidos)} />
                                                                    </Inline>
                                                                </Touchable>
                                                            </Inline>
                                                        }
                                                    </Inline>
                                                }
                                                <Box paddingY={screenSize.isDesktopOrBigger ? 16 : 4}>
                                                    <Inline space={'between'} alignItems={'center'}>
                                                        {prop.showBigAvatar ?
                                                            <Stack space={0}>
                                                                <Inline space={16} alignItems={'center'}>
                                                                    <Avatar size={56} initials={getInitials(user.nombre+' '+user.apellidos)} />
                                                                    <Text4 truncate={1}  medium>{getCapitalizeText(user.nombre+' '+user.apellidos)}</Text4>
                                                                </Inline>
                                                            </Stack>
                                                        :
                                                            <Stack space={0}>
                                                                <Text3 light>{intl.formatMessage({id:'menu_header_private_area_hello'})}</Text3>
                                                                <Text7>{getCapitalizeText(user.nombre)}</Text7>
                                                            </Stack>
                                                        }
                                                        {isClient() && havePermission(process.env.REACT_APP_MENU_NUEVO_CONTRATO) && screenSize.isDesktopOrBigger &&
                                                            <ButtonTertiary onPress={()=>{router.push(getUrlNavigation(ContractCustomerPage)); trackEvent('perfil-contratar');}}><IconAddMoreCircleRegular color="currentColor" />{intl.formatMessage({id:'menu_header_private_area_hire'})}</ButtonTertiary>
                                                        }
                                                    </Inline>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </ResponsiveLayout>
                                </ResponsiveLayout>
                                <ResponsiveLayout className={"profile-nav space"}> </ResponsiveLayout>
                            </>
                        :
                            <>
                                <ResponsiveLayout isInverse className={'profile-nav fixed small'} backgroundColor={skinVars.colors.backgroundBrand}>
                                    <ResponsiveLayout>
                                        <Box paddingTop={8}>
                                            <Stack space={0}>
                                                {!screenSize.isDesktopOrBigger &&
                                                    <Inline alignItems='center' space={16}>
                                                        {prop.showBack && prop.showBack===true && showBackButton ?
                                                            <Touchable onPress={()=>{router.goBack()}}>
                                                                <IconChevronLeftRegular />
                                                            </Touchable>
                                                        : !isNativeApp &&
                                                            <Touchable onPress={() =>{handleMenuVertical()}}>
                                                                <IconMenuRegular></IconMenuRegular>
                                                            </Touchable>
                                                        }
                                                        <Inline space={'between'} alignItems={'center'}>
                                                            <Box paddingTop={2} className={'title-center '+((prop.showBack && prop.showBack===true && showBackButton) || (!isNativeApp) ? 'with-back' : '')}>
                                                                <Text4 regular textAlign='center'  truncate>{prop.title}</Text4>
                                                            </Box>
                                                        </Inline>
                                                    </Inline>
                                                }
                                            </Stack>
                                        </Box>
                                    </ResponsiveLayout>
                                </ResponsiveLayout>
                                <ResponsiveLayout className={"profile-nav space small"}> </ResponsiveLayout>
                            </>

                        }

                        {!screenSize.isDesktopOrBigger &&
                            <ResponsiveLayout isInverse={false} className={'menu-vertical '+(showMenuVertical ? 'visible' : '')} fullWidth>
                                <ResponsiveLayout fullWidth backgroundColor={skinVars.colors.backgroundAlternative}>
                                    <ResponsiveLayout>
                                        <Box paddingY={8}>
                                            <Stack space={0}>
                                                <Inline alignItems='center' space={'between'}>
                                                    <Touchable onPress={()=>handleMenuVertical()}>
                                                        <IconCloseRegular />
                                                    </Touchable>
                                                </Inline>
                                            </Stack>
                                        </Box>
                                    </ResponsiveLayout>
                                </ResponsiveLayout>
                                <RowList noLastDivider dataAttributes={{"remove-paddings":true}}>

                                    {privateNavigationResponsive && privateNavigationResponsive.length>0 &&
                                        privateNavigationResponsive.map((item, i) => {
                                            return (<div key={'private-nav-responsive-content'+i}>
                                                    <Row title=''
                                                    right={null}
                                                    onPress={() => {router.push(getUrlNavigation(item.page))}}
                                                    extra={
                                                        <Inline space={'between'} alignItems='center'>
                                                            <Text weight='medium' color={i===optionMenuResponsiveSelected ? skinVars.colors.backgroundBrand : skinVars.colors.neutralHigh}>{intl.formatMessage({id:item.title})}</Text>
                                                            {i===optionMenuResponsiveSelected &&
                                                                <div className='option-selected' style={{backgroundColor:skinVars.colors.backgroundBrand}}></div>
                                                            }
                                                        </Inline>
                                                    }
                                                />
                                            </div>)
                                        })
                                    }
                                </RowList>
                            </ResponsiveLayout>
                        }

                        {screenSize.isDesktopOrBigger &&
                            <ResponsiveLayout>
                                <style>{
                                    '.private-nav.fixed > div:first-child{background-color:'+skinVars.colors.background+';} .private-nav.fixed [data-component-name="RowList"]{border-right:1px solid '+(skinVars.colors.border)+'!important}'+
                                    '#header-private .private-nav.fixed:before{background:'+skinVars.colors.background+';}'
                                }</style>
                                <Box paddingY={0} className={'private-nav fixed '+(windowSize.width<1224 ? 'collapsed ': 'visible ')+(showMenuAside ? 'active' : '')}>
                                    <Stack space={32}>
                                        <Box paddingTop={32}>

                                                {isPromoter() &&
                                                    <Stack space={16}>
                                                        <Select fullWidth
                                                            name="promoter"
                                                            label={intl.formatMessage({id:'menu_header_private_area_choose_promotor'})}
                                                            value={idPromotor}
                                                            onChangeValue={(val:string)=>handleChangePromotor(val)}
                                                            native
                                                            options={user.promotores?.map(item => ({text:item.NombrePromotor ?? '', value:item.idPromotor})) ?? []}
                                                        />
                                                        <Select fullWidth
                                                            name="pensionPlans"
                                                            label={intl.formatMessage({id:'menu_header_private_area_choose_pension_plan'})}
                                                            value={planPromotor}
                                                            onChangeValue={(val:string)=>handleChangePlanPromotor(val)}
                                                            native
                                                            options={user.planesPromotor?.map(item => ({text:item.Nombre ?? '', value: formatPlanPromotor(item)})) ?? []}
                                                        />
                                                    </Stack>
                                                }
                                                {(isControlCommision()) &&
                                                    <Select fullWidth
                                                        name="pensionPlans"
                                                        label={intl.formatMessage({id:'menu_header_private_area_choose_pension_plan'})}
                                                        value={planComisionControl}
                                                        native
                                                        onChangeValue={(val:string)=>handleChangePlanComisionControl(val)}
                                                        options={user.planesComisionControl?.map(item => ({text:item.NombrePlan??'', value: formatPlanComisionControl(item)})) ?? []}
                                                    />
                                                }
                                        </Box>
                                        <>
                                            <Divider></Divider>
                                            <RowList dataAttributes={{"remove-paddings":true}}>
                                                {privateNavigation && Array.isArray(privateNavigation) && privateNavigation.map((item, i) => {
                                                    return (<div key={'private-nav-content'+i}>
                                                    <Row title=''
                                                        key={'private-nav'+i}
                                                        right={null}
                                                        onPress={() => handleClickNavMainOption(i)}

                                                        extra={
                                                            <Inline space={'between'} alignItems='center'>
                                                                <Text weight={i===optionMenuSelected && optionSubmenuSelected===-1 ? 'medium' : 'regular'} color={i===optionMenuSelected && optionSubmenuSelected===-1 ? skinVars.colors.backgroundBrand : skinVars.colors.neutralHigh}>
                                                                    <Inline space={16} alignItems='center'>
                                                                        <span>{item.icon}</span>
                                                                        <span>{intl.formatMessage({id:item.title})}</span>
                                                                    </Inline>
                                                                </Text>
                                                                {item.options && (item.showOptions ? <IconChevronUpLight size={12}></IconChevronUpLight> : <IconChevronDownLight size={12}></IconChevronDownLight>)}
                                                                {i===optionMenuSelected && optionSubmenuSelected===-1 &&
                                                                    <div className='option-selected' style={{backgroundColor:skinVars.colors.backgroundBrand}}></div>
                                                                }
                                                            </Inline>
                                                        }
                                                    />


                                                    {item.options && item.showOptions &&
                                                        <div key={'private-nav-content-sub'+i}>
                                                            <Divider></Divider>
                                                            <RowList noLastDivider>
                                                            {item.options.map((subitem, j) => {
                                                                return (<Row title=''
                                                                    key={'private-nav'+i+'-'+j}
                                                                    right={null}
                                                                    onPress={() => {router.push(getUrlNavigation(subitem?.page??HomePage)); trackEvent('perfil-menu-'+slugify(intl.formatMessage({id:subitem.title})).replaceAll('-', '_'));}}
                                                                    extra={
                                                                        <Inline space={'between'} alignItems='center'>
                                                                            <Text weight={i===optionMenuSelected && j===optionSubmenuSelected ? 'medium' : 'regular'} color={i===optionMenuSelected && j===optionSubmenuSelected ? skinVars.colors.backgroundBrand : skinVars.colors.neutralHigh}>
                                                                                <Box paddingLeft={40}>{intl.formatMessage({id:subitem.title})}</Box>
                                                                            </Text>
                                                                            {i===optionMenuSelected && j===optionSubmenuSelected &&
                                                                                <div className='option-selected' style={{backgroundColor:skinVars.colors.backgroundBrand}}></div>
                                                                            }
                                                                        </Inline>
                                                                    }></Row>)
                                                            })}
                                                            </RowList>
                                                        </div>
                                                    }
                                                    </div>
                                                    )
                                                })}
                                            </RowList>
                                        </>
                                    </Stack>

                                    {windowSize.width<1224 &&
                                        <>
                                            <style>{'.hideMenu{background-color:'+(skinVars.colors.backgroundAlternative)+'; border-color:'+(skinVars.colors.brand)+';}'+
                                                    '.hideMenu__text:after{color:'+(skinVars.colors.brand)+';}'}
                                            </style>
                                            <Box className={'private-nav-button '}>
                                                <Touchable onPress={()=>{handleMenuAside()}}>
                                                    <Boxed className='hideMenu'>
                                                        <Box className='hideMenu__text' dataAttributes={{"after-content":"Menu"}}>
                                                            {showMenuAside ?
                                                                <IconChevronLeftRegular size={20} color={skinVars.colors.brand}></IconChevronLeftRegular>
                                                            :
                                                                <IconChevronRightRegular size={20} color={skinVars.colors.brand}></IconChevronRightRegular>
                                                            }
                                                        </Box>
                                                    </Boxed>
                                                </Touchable>
                                            </Box>
                                        </>
                                    }
                                </Box>
                            </ResponsiveLayout>
                        }
                    </IonHeader>
                </ResponsiveLayout>
            }
        </>
    );
};

export default HeaderPrivateArea;