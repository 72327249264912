import { IonHeader, useIonRouter } from '@ionic/react';
import { Box, ButtonPrimary, Circle, Divider, Form, IconArrowLineRightRegular, IconButton, IconCallLandlineRegular, IconChevronDownRegular, IconChevronLeftRegular, IconChevronUpRegular, IconCloseRegular, IconEmailRegular, IconInternetRegular, IconMenuRegular, IconSearchRegular, IconUserAccountFilled, Image, Inline, MainNavigationBar, ResponsiveLayout, Row, RowList, SearchField, skinVars, Stack, Text, Text4, TextField, TextLink, Touchable, useIsInViewport, useScreenSize } from '@telefonica/mistica';
import * as React from 'react';

import { useContext, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import CustomThemeContext from '../../context/ThemeContext';
import LoginPage from '../../pages/private/login/LoginPage';
import HomePage from '../../pages/public/home/HomePage';
import { getCurrentLanguage, getLanguages } from '../../utils/locate';
import { getHeaderNavigation, getUrlNavigation, getUrlNavigationInverse } from '../../utils/navigation';
import { getModeMaintenance, getUser } from '../../utils/storage';
import { getUrlDefaultUser, isNativeApp, closeDialog, trackEvent } from '../../utils/utils';
import Breadcrumb from '../breacrumb/Breadcrumb';
import './Header.scss';
import Searcher from '../searcher/Searcher';
import { NavOption } from '../../entities/commons/nav-option.model';
export interface HeaderProps {
    inverse?: boolean,
    small?: boolean,
    title?: string,
    parents?: [{title:string, item:React.FC}],
    refHeader?: React.RefObject<HTMLIonHeaderElement>,
    hide?:boolean,
    showBack?:boolean,
    landing?: boolean,
    options?: NavOption[],
}
const Header: React.FC<HeaderProps> = (prop) => {
    const screenSize = useScreenSize();
    const router = useIonRouter();
    const intl = useIntl();
    const user = getUser();

    let ref = React.useRef<HTMLIonHeaderElement>(null);
    if(prop.refHeader){
        ref = prop.refHeader;
    }
    const inView = useIsInViewport(ref, false);

    const [loaded, setLoaded] = useState(false);
    const [showMenuFixed, setShowMenuFixed] = useState(false);
    useLayoutEffect(() => {
        if (inView) {
            setLoaded(true);
        }

        setShowMenuFixed((!inView && loaded) || !screenSize.isDesktopOrBigger);
    }, [inView, loaded, screenSize]);


    const headerNavigation = prop.options ?  prop.options : getHeaderNavigation();
    const sections = headerNavigation.map(item=>{ return intl.formatMessage({id:item.title})});


    let optionMenuSelected = headerNavigation.find(item=>router.routeInfo.pathname === getUrlNavigation(item.page))?.title ?? undefined;
    optionMenuSelected = optionMenuSelected ? optionMenuSelected : (headerNavigation.find(item=>router.routeInfo.pathname.substring(0, router.routeInfo.pathname.lastIndexOf('/')) === getUrlNavigation(item.page))?.title ?? undefined);
    optionMenuSelected = optionMenuSelected ? optionMenuSelected : (headerNavigation.find(item=>router.routeInfo.pathname.indexOf(getUrlNavigation(item.page))!==-1)?.title ?? undefined);

    const selectedIndex = optionMenuSelected ? (sections.findIndex(x => x ===intl.formatMessage({id:optionMenuSelected})) ?? undefined) : undefined;

    const [showSearch, setShowSearch] = useState(false);
    const [showSelLanguages, setShowSelLanguages] = useState(false);
    const [currentLanguage, setCurrentLanguage] =  React.useState(getCurrentLanguage())
    const [languages, setLanguages] =  React.useState(getLanguages())

    const [showMenuVertical, setShowMenuVertical] = useState(false);
    function handleMenuVertical() {
        setShowMenuVertical(!showMenuVertical);
    }

    function handleNavigation(selected: string) {
        const option = headerNavigation.find(item=>intl.formatMessage({id:item.title})===selected);
        if(option && option.page){
            router.push(getUrlNavigation(option.page));
        }
    }

    const { i18n, setLocale } = useContext(CustomThemeContext)
    const handleLanguage= (lang:string) => {
        const language = languages.find(item=>item.value===lang);

        if(language){
            router.push(getUrlNavigationInverse(router.routeInfo.pathname, language));
            setLocale({locale: language.locale, phoneNumberFormattingRegionCode: language.phoneNumberFormattingRegionCode});
        }

    };

    return (
        <>{isNativeApp ? (prop.hide ? <></> :
        <>
            <ResponsiveLayout fullWidth={true} backgroundColor={skinVars.colors.background}>
                <IonHeader ref={ref} id="header-private" translucent={true} className={"ion-no-border small"}>

                    <ResponsiveLayout isInverse className={'profile-nav fixed small'} backgroundColor={skinVars.colors.backgroundBrand}>
                        <ResponsiveLayout>
                            <Box paddingTop={8}>
                                <Stack space={0}>
                                    <Inline alignItems='center' space={16}>

                                        {prop.showBack && prop.showBack===true &&
                                            <Touchable onPress={()=>{router.goBack()}}>
                                                <IconChevronLeftRegular />
                                            </Touchable>
                                        }


                                        <Inline space={'between'} alignItems={'center'}>
                                            <Box paddingTop={2} className={'title-center '+((prop.showBack && prop.showBack===true) || (!isNativeApp) ? 'with-back' : '')}>
                                                <Text4 regular textAlign='center'  truncate>{prop.title}</Text4>
                                            </Box>
                                        </Inline>
                                    </Inline>
                                </Stack>
                            </Box>
                        </ResponsiveLayout>
                    </ResponsiveLayout>
                    <ResponsiveLayout className={"profile-nav space small"}> </ResponsiveLayout>
                </IonHeader>
            </ResponsiveLayout>
        </>) :
            <ResponsiveLayout isInverse={screenSize.isDesktopOrBigger && prop.inverse} fullWidth={true} backgroundColor={`${prop.inverse ? skinVars.colors.backgroundBrand : skinVars.colors.background}`}>
                <IonHeader ref={ref} id="header" translucent={true} className={`ion-no-border ${prop.inverse ? "inverse" : ""}`}>
                    <ResponsiveLayout fullWidth>
                        <Stack space={0}>
                            <>
                                <style>{
                                    '.menu-logo [data-component-name="MainNavigationBar"]{background:'+(prop.small ? skinVars.colors.background : (prop.inverse ? skinVars.colors.backgroundBrand : "transparent"))+'!important}'
                                }</style>
                                {screenSize.isDesktopOrBigger &&
                                    <Searcher onClose={()=>{setShowSearch(false)}} showSearch={showSearch} inverse={prop.inverse} small={prop.small} />
                                }
                            </>

                            {screenSize.isDesktopOrBigger &&
                                <>
                                    <Box className={'menu-logo '+(prop.small ? 'small ' : '')+ (prop.landing ? 'landing' : '')}>
                                        <MainNavigationBar
                                            logo={<TextLink onPress={()=>{router.push(getUrlNavigation(HomePage))}}>
                                                    <Image src={`/assets/img/${prop.inverse ? "logo-inverse.svg" : "logo.svg"}`} width={160} aspectRatio={0} alt={intl.formatMessage({id:'site_name'})} noBorderRadius={true} ></Image>
                                                </TextLink>}
                                            topFixed={prop.small===true}
                                            isInverse={prop.inverse}
                                            sections={[]}
                                            right={
                                                <Inline className='right' space={16} alignItems={'flex-end'}>
                                                    {(prop.small || prop.landing) && (
                                                        <Inline space={32} alignItems={'flex-end'}>
                                                            <TextLink onPress={()=>{window.location.href = `tel:${intl.formatMessage({id:'menu_header_phone'}).replaceAll(' ', '')}`;trackEvent('header-contacto-tel');}}>
                                                                <Inline space={8} alignItems={'center'}>
                                                                    <IconCallLandlineRegular color={`${prop.inverse ? skinVars.colors.inverse : "currentColor"}`} />
                                                                    <Text color={`${prop.inverse ? skinVars.colors.inverse : skinVars.colors.neutralHigh}`}>{intl.formatMessage({id:'menu_header_doubts_call_us'})}</Text>
                                                                </Inline>
                                                            </TextLink>
                                                        </Inline>
                                                    )}
                                                    {!(prop.small || prop.landing) && (
                                                        <Inline space={16} alignItems={'flex-end'}>
                                                            {!screenSize.isMobile && (
                                                                <Inline space={24} alignItems={'center'}>
                                                                    <TextLink onPress={()=>{window.location.href = `tel:${intl.formatMessage({id:'menu_header_phone'}).replaceAll(' ', '')}`;trackEvent('header-contacto-tel');}}>
                                                                        <Inline space={8} alignItems={'center'}>
                                                                            <IconCallLandlineRegular color={`${prop.inverse ? skinVars.colors.inverse : "currentColor"}`} />
                                                                            <Text color={`${prop.inverse ? skinVars.colors.inverse : skinVars.colors.neutralHigh}`}>{intl.formatMessage({id:'menu_header_phone'})}</Text>
                                                                        </Inline>
                                                                    </TextLink>
                                                                    <TextLink onPress={()=>{window.location.href = `mailto:${intl.formatMessage({id:'menu_header_email'}).replaceAll(' ', '')}`;trackEvent('header-contacto-mail');}}>
                                                                        <Inline space={8} alignItems={'center'}>
                                                                            <IconEmailRegular color={`${prop.inverse ? skinVars.colors.inverse : "currentColor"}`} />
                                                                            <Text color={`${prop.inverse ? skinVars.colors.inverse : skinVars.colors.neutralHigh}`}>{intl.formatMessage({id:'menu_header_email'})}</Text>
                                                                        </Inline>
                                                                    </TextLink>
                                                                    <Inline space={8} alignItems={'center'}>
                                                                        <IconInternetRegular color={`${prop.inverse ? skinVars.colors.inverse : skinVars.colors.buttonPrimaryBackground}`} />

                                                                        <Stack space={0}>

                                                                            <TextLink onPress={()=>setShowSelLanguages(!showSelLanguages)}>
                                                                                <Inline space={8} alignItems={'center'}>
                                                                                    <Text color={`${prop.inverse ? skinVars.colors.inverse : skinVars.colors.neutralHigh}`}>{currentLanguage.text}</Text>
                                                                                    {showSelLanguages ?
                                                                                        <IconChevronUpRegular  color={`${prop.inverse ? skinVars.colors.inverse : skinVars.colors.neutralHigh}`} size={12} />
                                                                                    : <IconChevronDownRegular  color={`${prop.inverse ? skinVars.colors.inverse : skinVars.colors.neutralHigh}`} size={12} />}
                                                                                </Inline>
                                                                            </TextLink>
                                                                            <div className={'language-selector '+(showSelLanguages ? 'visible' : '')}>
                                                                                <Stack space={8}>
                                                                                    {
                                                                                        languages.filter(item=>item.value!=currentLanguage.value).map((lang, i) => {
                                                                                            return (<TextLink key={lang.value} onPress={()=>handleLanguage(lang.value)}><Text color={`${prop.inverse ? skinVars.colors.inverse : skinVars.colors.neutralHigh}`}>{lang.text}</Text></TextLink>)
                                                                                        })
                                                                                    }
                                                                                </Stack>
                                                                            </div>
                                                                        </Stack>
                                                                    </Inline>
                                                                </Inline>
                                                            )}
                                                            <TextLink onPress={()=>{setShowSearch(true)}}>
                                                                <Inline space={8} alignItems={'center'}>
                                                                    <IconSearchRegular color={`${prop.inverse ? skinVars.colors.inverse : "currentColor"}`} />
                                                                </Inline>
                                                            </TextLink>
                                                            <ButtonPrimary onPress={()=>router.push(getUrlDefaultUser())}  small> <IconUserAccountFilled color="currentColor" /> {intl.formatMessage({id:'menu_header_users'})}</ButtonPrimary>

                                                        </Inline>
                                                    )}
                                                </Inline>
                                            } />
                                    </Box>
                                </>
                            }
                            {((!prop.small) || (prop.small && showMenuFixed && !screenSize.isDesktopOrBigger)) && (
                                <Box className={"menu-nav " + (showMenuFixed ? 'fixed ' : '')+ (showSearch ? 'searcher ' : '')+ (prop.landing ? 'landing' : '')}>
                                    <style>{'.menu-nav [data-component-name="MainNavigationBar"]{background:'+(prop.small ? skinVars.colors.background : (prop.inverse ? skinVars.colors.backgroundBrand : (showMenuFixed ? skinVars.colors.background  : "transparent")))+'!important}'}</style>
                                    {!screenSize.isDesktopOrBigger &&
                                        <Searcher onClose={()=>{setShowSearch(false)}} showSearch={showSearch} inverse={prop.inverse} small={prop.small} />
                                    }
                                    <MainNavigationBar
                                        logo={
                                            (showMenuFixed ? <TextLink className='logo' onPress={()=>{router.push(getUrlNavigation(HomePage))}}>
                                                                <Image src={`/assets/img/${prop.inverse ? "logo-inverse.svg" : "logo.svg"}`} width={112} aspectRatio={0} alt={intl.formatMessage({id:'site_name'})} noBorderRadius={true} ></Image>
                                                            </TextLink> : <></>)
                                        }
                                        topFixed={showMenuFixed} isInverse={prop.inverse} sections={sections.map((title, idx) => ({title, onPress: () => handleNavigation(title)}))} selectedIndex={selectedIndex}
                                        right={
                                            screenSize.isDesktopOrBigger ? <></> :
                                            <Inline space={16} alignItems='center'>
                                                <Stack space={0}>
                                                    <style>{'.circle > div{border: 1px solid '+skinVars.colors.textLinkSnackbar+'}'}</style>
                                                    <TextLink className='circle' onPress={()=>setShowSelLanguages(!showSelLanguages)}>
                                                        <Circle size={40} backgroundColor={`${prop.inverse ? skinVars.colors.backgroundBrand : skinVars.colors.background}`}>
                                                            <Text color={`${prop.inverse ? skinVars.colors.inverse : "currentColor"}`}>{currentLanguage.text}</Text>
                                                        </Circle>
                                                    </TextLink>
                                                    <div className={'language-selector '+(showSelLanguages ? 'visible' : '')}>
                                                        <Stack space={8}>
                                                            {
                                                                languages.filter(item=>item.value!=currentLanguage.value).map((lang, i) => {
                                                                    return (
                                                                        <TextLink key={lang.value} className='circle' onPress={()=>handleLanguage(lang.value)}>
                                                                            <Circle size={40} backgroundColor={`${prop.inverse ? skinVars.colors.backgroundBrand : skinVars.colors.background}`}>
                                                                                <Text color={`${prop.inverse ? skinVars.colors.inverse : "currentColor"}`}>{lang.text}</Text>
                                                                            </Circle>
                                                                        </TextLink>

                                                                    )
                                                                })
                                                            }
                                                        </Stack>
                                                    </div>
                                                </Stack>

                                                {!getModeMaintenance()  &&
                                                    <TextLink onPress={()=>{setShowMenuVertical(false);setShowSearch(true)}}>
                                                        <Inline space={8} alignItems={'center'}>
                                                            <IconSearchRegular color={`${prop.inverse ? skinVars.colors.inverse : "currentColor"}`} />
                                                        </Inline>
                                                    </TextLink>
                                                }

                                                {!getModeMaintenance()  &&
                                                    <TextLink onPress={()=>{setShowSearch(false);handleMenuVertical();}}>
                                                        {showMenuVertical ?
                                                            <IconCloseRegular color={`${prop.inverse ? skinVars.colors.inverse : "currentColor"}`} />
                                                            :
                                                            <IconMenuRegular color={`${prop.inverse ? skinVars.colors.inverse : "currentColor"}`} />
                                                        }
                                                    </TextLink>
                                                }

                                            </Inline>
                                        }
                                    />
                                </Box>
                            )}
                        </Stack>
                    </ResponsiveLayout>
                    {!prop.small && (
                        <Divider></Divider>
                    )}
                    {!screenSize.isDesktopOrBigger &&
                        <ResponsiveLayout isInverse={false} className={'menu-vertical '+(showMenuVertical ? 'visible' : '')} fullWidth>
                            <RowList noLastDivider dataAttributes={{"remove-paddings":true}}>
                                <Row title=''
                                    right={null}
                                    extra={
                                        <Box paddingX={16} paddingY={8}>
                                            <ButtonPrimary onPress={()=>router.push(getUrlNavigation(LoginPage))} > <IconUserAccountFilled color="currentColor" /> {intl.formatMessage({id:'menu_header_users'})}</ButtonPrimary>
                                        </Box>
                                    }
                                />
                                {headerNavigation.map((item, i) => {
                                    return (<Row title=''
                                        key={'nav'+i}
                                        right={null}
                                        onPress={() => handleNavigation(intl.formatMessage({id:item.title}))}
                                        extra={
                                            <Inline space={'between'} alignItems='center'>
                                                <Text color={i===selectedIndex ? skinVars.colors.backgroundBrand : skinVars.colors.neutralHigh}>{intl.formatMessage({id:item.title})}</Text>
                                                {i===selectedIndex &&
                                                    <div className='option-selected' style={{backgroundColor:skinVars.colors.backgroundBrand}}></div>
                                                }
                                            </Inline>
                                        }
                                    />)
                                })}
                                <Row title=''
                                    extra={
                                        <Inline space={'around'}>
                                            <TextLink onPress={()=>{window.location.href = `tel:${intl.formatMessage({id:'menu_header_phone'}).replaceAll(' ', '')}`;trackEvent('header-contacto-tel');}}>
                                                <Inline space={8} alignItems={'center'}>
                                                    <IconCallLandlineRegular color={"currentColor"} />
                                                    <Text size={14} color={skinVars.colors.neutralHigh}>{intl.formatMessage({id:'menu_header_phone'})}</Text>
                                                </Inline>
                                            </TextLink>
                                            <TextLink onPress={()=>{window.location.href = `mailto:${intl.formatMessage({id:'menu_header_email'}).replaceAll(' ', '')}`;trackEvent('header-contacto-mail');}}>
                                                <Inline space={8} alignItems={'center'}>
                                                    <IconEmailRegular color={"currentColor"} />
                                                    <Text size={14} color={skinVars.colors.neutralHigh}>{intl.formatMessage({id:'menu_header_email'})}</Text>
                                                </Inline>
                                            </TextLink>
                                        </Inline>
                                    }
                                ></Row>
                            </RowList>
                        </ResponsiveLayout>
                    }
                </IonHeader>

                {prop.title && prop.title.length>0 && !prop.small &&
                    <ResponsiveLayout fullWidth backgroundColor={prop.inverse ? skinVars.colors.backgroundBrand : skinVars.colors.backgroundAlternative}>

                        <ResponsiveLayout>
                            <Box paddingY={24}>
                                <Breadcrumb title={prop.title} parents={prop.parents} inverse={prop.inverse}></Breadcrumb>
                            </Box>
                        </ResponsiveLayout>
                    </ResponsiveLayout>
                }
            </ResponsiveLayout>
        }</>
    );
};

export default Header;