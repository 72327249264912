import { useScreenSize } from '@telefonica/mistica';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { OperationInProgress } from '../../entities/operationsInProgress/operation-in-progress.model';
import OperationInProgressDetail from './OperationInProgressDetail';
import { formatAmount, formatNetAssetValue } from '../../utils/utils';


interface OperationInProgressRefundDetailProps {
    operation: OperationInProgress
}

const OperationInProgressRefundDetail: React.FC<OperationInProgressRefundDetailProps> = (prop) => {

    const intl = useIntl();
    const screenSize = useScreenSize();
    const [title, setTitle] = useState('' as string);
    const [items, setItems] = useState([] as Array<{title:string, value:string}>);

    useEffect(()=>{
        if(prop.operation){
            const operation = prop.operation;

            setTitle(intl.formatMessage({ id: 'page_operations_in_progress_customer_refund_detail' }));

            let itemValues: Array<{title:string, value:string}> = [];
            if(!screenSize.isDesktopOrBigger){
                itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_product' }), value: operation.detalle.producto});
                itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_contract' }), value: operation.detalle.numContrato});
            }


            itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_iban' }), value: operation.cuentaBancaria.IBAN});
            let refundData='';
            switch(operation.tipoMovilizacionS){
                case process.env.REACT_APP_REEMBOLSO_TIPO_TOTAL:
                    refundData= intl.formatMessage({ id: 'page_operations_in_progress_customer_full_balance' })+ ' '+formatAmount(operation.saldoActual)+'€';
                    break;
                case process.env.REACT_APP_REEMBOLSO_MODO_IMPORTE:
                    refundData= formatAmount(operation.importeOperacion)+'€ ';
                    switch(operation.formaPago){
                        case process.env.REACT_APP_REEMBOLSO_MODALIDAD_BRUTO:
                            refundData+=intl.formatMessage({ id: 'page_operations_in_progress_customer_amount_gross' });
                            break;
                        case process.env.REACT_APP_REEMBOLSO_MODALIDAD_NETO:
                            refundData+=intl.formatMessage({ id: 'page_operations_in_progress_customer_amount_nets' });
                            break;
                    }
                    break;
                default:
                    refundData= formatNetAssetValue(operation.UnidadesONumParticip)+' '+intl.formatMessage({ id: 'page_operations_in_progress_customer_shares' });
                    break;
            }

            itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_column_refund' }), value: refundData});

            setItems(itemValues);
        }

    },[]);

    return (<OperationInProgressDetail title={title} items={items} numColumns={2} />);
};

export default OperationInProgressRefundDetail;
