import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Boxed, ButtonPrimary, Form, Grid, Inline, RadioButton, RadioGroup, ResponsiveLayout, Stack, Text, Text8, TextField, skinVars, useScreenSize } from '@telefonica/mistica';
import './Hero.scss';

interface HeroCalculatorProp {
    title?: string,
    description?: string,
    form?: any,
    children?: any
}

const HeroCalculator: React.FC<HeroCalculatorProp> = (prop) => {

const intl = useIntl();
const screenSize = useScreenSize();
const size = useScreenSize();

    return (
        <>
            <style>{
                '.calculatorHero:before{background-color:'+skinVars.colors.backgroundAlternative+';}'
            }</style>
            <ResponsiveLayout className='content' fullWidth={true}>
                <Box className={'calculatorHero'}>

                    <ResponsiveLayout className='calculatorHero__content'>

                        <Box paddingTop={screenSize.isDesktopOrBigger ? 32 : 8} paddingBottom={24}>
                            <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={screenSize.isDesktopOrBigger ? 24 : 56}>
                                <Box paddingTop={screenSize.isDesktopOrBigger ? 56: 0} paddingRight={screenSize.isDesktopOrBigger ? 24 : 0} className='calculatorHero__left'>
                                    <Stack space={16}>
                                        <Text8>{prop.title}</Text8>
                                        {prop.description &&
                                            <Text size={18} lineHeight={24} color={skinVars.colors.textSecondary}>
                                                <div className='html-content' dangerouslySetInnerHTML={{__html: prop.description}}></div>
                                            </Text>
                                        }
                                    </Stack>
                                </Box>

                                <Box className='calculatorHero__right'>
                                    {prop.children}
                                </Box>
                            </Grid>
                        </Box>

                    </ResponsiveLayout>

                </Box>
            </ResponsiveLayout>
        </>
    );
};

export default HeroCalculator;