import { useScreenSize } from '@telefonica/mistica';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { OperationInProgress } from '../../entities/operationsInProgress/operation-in-progress.model';
import OperationInProgressDetail from './OperationInProgressDetail';


interface OperationInProgressContributionDetailProps {
    operation: OperationInProgress
}

const OperationInProgressContributionDetail: React.FC<OperationInProgressContributionDetailProps> = (prop) => {

    const intl = useIntl();
    const screenSize = useScreenSize();
    const [title, setTitle] = useState('' as string);
    const [items, setItems] = useState([] as Array<{title:string, value:string}>);

    useEffect(()=>{
        if(prop.operation){
            const operation = prop.operation;

            setTitle(intl.formatMessage({ id: 'page_operations_in_progress_customer_contribution_detail' }));

            let itemValues: Array<{title:string, value:string}> = [];
            if(!screenSize.isDesktopOrBigger){
                itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_product' }), value: operation.detalle.producto});
                itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_contract' }), value: operation.detalle.numContrato});
            }


            itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_amount' }), value: operation.detalle.importeOperacion});
            itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_iban' }), value: operation.cuentaBancaria.IBAN});

            setItems(itemValues);
        }

    },[]);

    return (<OperationInProgressDetail title={title} items={items} numColumns={2} />);
};

export default OperationInProgressContributionDetail;
