import { Box, ButtonPrimary, GridLayout, Hero, Image, Inline, LoadingBar, ResponsiveLayout, skinVars, Stack, Text, Text3, Text5, Text6, Text8, TextLink, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import EmptyStateCustom from '../../../../components/empty-state/EmptyStateCustom';
import HeroShadow from '../../../../components/hero/HeroShadow';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import MembersOf from '../../../../components/staticPages/membersOf/MembersOf';
import { getPublicDocuments } from '../../../../utils/apiclient';
import '../LandingPage.scss';

const IsrInFonditelPage: React.FC = () => {
    const intl = useIntl()
    const size = useScreenSize();
    const screenSize = useScreenSize();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [options, setOptions] = useState(['Sobre Fonditel', 'ISR en Fonditel', 'Filosofia ISR', 'Inversión ISR', 'Divulgación (SFDR)']);

    const [loadingDownload, setLoadingDownload] = useState(false);

    const downloadDocument= (filename:string, name:string) => {
        setLoadingDownload(true);
        getPublicDocuments(filename, name).finally(()=>{
            setLoadingDownload(false);
        });
    };

    const data1 = {
        labels: ['Social', 'Gobernanza', 'Ambiental'],
        datasets: [{
            label: '',
            data: [51.5, 5.3, 43.2],
            backgroundColor: [
                '#0066FF',
                '#E7C2F8',
                '#F5E98A',
                '#E3A19A',
                '#67E0E5',
            ],
            hoverOffset: 4
        }],
    };

    const data2 = {
        labels: ['Renta Variable', 'Inversiones Líquidas', 'Renta fija'],
        datasets: [{
            label: '',
            data: [34.8, 12.4, 52.8],
            backgroundColor: [
                '#0066FF',
                '#E7C2F8',
                '#F5E98A',
                '#E3A19A',
                '#67E0E5',
            ],
            hoverOffset: 4
        }],
    };

    const optionsGraphics = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'  as any
            }
        },
        transitions: {
            active: {
                animation: {
                duration: 0
                }
            }
        }
    };

    return (
        <LayoutPublic
        seo={{metaTitle: intl.formatMessage({id:'page_sustainability_meta_title'}), metaDescription: intl.formatMessage({id:'page_sustainability_meta_description'})}}
        >
            <HeroShadow
                image='../assets/img/sostenibilidad.jpg'
                title={<Text8>{intl.formatMessage({id:'page_sustainability_title_black1'})} <Text color={skinVars.colors.backgroundBrand}>{intl.formatMessage({id:'page_sustainability_title_blue1'})}</Text> {intl.formatMessage({id:'page_sustainability_title_black2'})} <Text color={skinVars.colors.backgroundBrand}>{intl.formatMessage({id:'page_sustainability_title_blue2'})}</Text></Text8>}
            ></HeroShadow>

            {/* <ChipTabs
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
                tabs={options.map((text) => ({
                    text,
                }))}
            />*/}

            <style>{
                '.landingPage--content .html-content *{color:'+skinVars.colors.textSecondary+';}'+
                '.landingPage--content .isotopeGrid:before{background:'+skinVars.colors.backgroundContainerAlternative+';}'+
                '.landingPage--content .html-content.chipTabs--content *{color:'+skinVars.colors.textPrimary+';}'
            }</style>
            <LoadingBar visible={loadingDownload}></LoadingBar>
            <ResponsiveLayout className={(selectedIndex===0 ? 'landingPage--content d-block' : 'd-none')}>
                {/* <Hero
                    background="default"
                    media={
                        <Image src='../assets/img/sostenibilidad-hero1.jpg' aspectRatio='16:9' />
                    }
                    title="Más de 30 años de experiencia en la gestión de fondos de pensiones"
                    extra={
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:
                            `<p>Fonditel, gestora independiente perteneciente al Grupo Telefónica <br />
                            Ofrecemos soluciones de inversión, tanto en Planes de Pensiones como Fondos de Inversión, adaptándonos al perfil de riesgo de nuestros clientes. <br />
                            Ponemos a tu disposición nuestra amplia experiencia, alta capacidad de innovación e independencia en la toma de decisiones.</p>`
                            }}></div>
                    }
                    desktopMediaPosition="left"
                />
                */}

                <Hero
                    background="default"
                    media={
                        <Image src='../assets/img/sostenibilidad-hero2.jpg' aspectRatio={screenSize.isDesktopOrBigger ? '7:10' : '16:9'} />
                    }
                    title={intl.formatMessage({id:'page_sustainability_title_mainstreaming_sustainability'})}
                    extra={
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_mainstreaming_sustainability'})}}></div>
                    }
                    desktopMediaPosition="right"
                />

                <Hero
                    background="default"
                    media={
                        <Image src='../assets/img/sostenibilidad-hero4.jpg' aspectRatio='16:9' />
                    }
                    title={intl.formatMessage({id:'page_sustainability_title_isr_source_value'})}
                    extra={
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_isr_source_value'})}}></div>
                    }
                    desktopMediaPosition="left"
                />
                <div className='hero--reduceSize'>
                    <Hero
                        background="default"
                        media={
                            <div className='hero--hiddenImage html-content'><Image src='' aspectRatio='16:9' /></div>
                        }
                        title={intl.formatMessage({id:'page_sustainability_title_isr_philosophy'})}
                        extra={
                            <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_isr_philosophy'})}}></div>
                        }
                        desktopMediaPosition="right"
                    />
                </div>

                {/*<Hero
                    background="default"
                    media={
                        <Image src='../assets/img/sostenibilidad-hero5.jpg' aspectRatio='16:9' />
                    }
                    title="Transformación a todos los niveles"
                    extra={
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:
                            `<p>Este compromiso ha supuesto un reto importante de transformación para la compañía. Hemos reforzado nuestro proceso de integración de criterios ambientales, sociales y de gobernanza (ASG) en el proceso de inversión de forma transversal, abarcando la toma de decisiones desde el front to back. Para ello, hemos abordado nuevas inversiones, tanto en sistemas como en la formación continua de los equipos, asegurándonos de contar con los recursos necesarios para su implementación.</p>`
                        }}></div>
                    }
                    desktopMediaPosition="right"
                />*/}

                <Hero
                    background="default"
                    media={
                        <Image src='../assets/img/sostenibilidad-pri.jpg' aspectRatio='16:9' />
                    }
                    title={intl.formatMessage({id:'page_sustainability_title_principles_responsible_investment'})}
                    extra={
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_principles_responsible_investment'})}}></div>
                    }
                    desktopMediaPosition="left"
                />

                <Hero
                    background="default"
                    media={
                        <Image src='../assets/img/sostenibilidad-objetivos.jpg' aspectRatio='16:9' />
                    }
                    title={intl.formatMessage({id:'page_sustainability_title_sustainable_development_goals'})}
                    extra={
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_sustainable_development_goals'})}}></div>
                    }
                    desktopMediaPosition="right"
                />

                <ResponsiveLayout>

                    <EmptyStateCustom
                        largeImageUrl="../assets/img/informe-inverco-fondos-de-inversion.jpg"
                        title={intl.formatMessage({id:'page_sustainability_empty_state_title'})}
                        description={intl.formatMessage({id:'page_sustainability_empty_state_subtitle'})}
                        button={
                            <ButtonGroup
                                primaryButton={
                                    <ButtonPrimary onPress={() => {downloadDocument('Fonditel-Memoria-de-Sostenibilidad-2023.pdf', 'Fonditel Memoria de Sostenibilidad 2023')}}>{intl.formatMessage({id:'page_sustainability_empty_state_button'})}</ButtonPrimary>
                                }
                            ></ButtonGroup>
                        }
                    />

                </ResponsiveLayout>

                <Box className='isotopeGrid' paddingTop={56} paddingBottom={screenSize.isDesktopOrBigger ? 64 : 56}>
                    <ResponsiveLayout>
                        <Stack space={40} className='isotopeGrid__content'>
                            <Stack space={screenSize.isDesktopOrBigger ? 64 : 40}>
                                <Stack space={screenSize.isDesktopOrBigger ? 40 : 24}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_sustainability_title_sustainability_policies'})}</Text6>
                                    </Box>
                                    <Box className='boxAlignCenter'>
                                        <GridLayout verticalSpace={16} template='8+4'
                                            left={
                                                <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                        <Text5>{intl.formatMessage({id:'page_sustainability_fonditel_pensions_sustainability_policies'})}</Text5>
                                                    }
                                                    right={
                                                        <ButtonPrimary small onPress={() => {downloadDocument('Politica-ISR.pdf', 'Politica ISR')}}>{intl.formatMessage({id:'page_sustainability_fonditel_button_investment_policy'})}</ButtonPrimary>
                                                    }></GridLayout>
                                            }
                                            right={
                                                <ButtonPrimary small onPress={() => {downloadDocument('Politica-de-implicacion.pdf', 'Politica de implicacion')}}>{intl.formatMessage({id:'page_sustainability_fonditel_button_involvement_policy'})}</ButtonPrimary>
                                        }/>      
                                    </Box>
                                    <Box className='boxAlignCenter'>
                                        <GridLayout verticalSpace={16} template='8+4'
                                            left={
                                                <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                        <Text5>{intl.formatMessage({id:'page_sustainability_fonditel_management_sustainability_policies'})}</Text5>
                                                    }
                                                    right={
                                                        <ButtonPrimary small onPress={() => {downloadDocument('Politica-ISR-FI.pdf', 'Politica ISR FI')}}>{intl.formatMessage({id:'page_sustainability_fonditel_button_investment_policy'})}</ButtonPrimary>
                                                    }></GridLayout>
                                            }
                                            right={
                                                <ButtonPrimary small onPress={() => {downloadDocument('Política de implicación Fonditel Gestión.pdf', 'Política de implicación Fonditel Gestión')}}>{intl.formatMessage({id:'page_sustainability_fonditel_button_involvement_policy'})}</ButtonPrimary>
                                            }/>
                                    </Box>
                                </Stack>
                                {/*
                                    <Box className='boxAlignCenter'>
                                        <Text6>ISR en la práctica en Fonditel</Text6>
                                    </Box>
                                */}
                            </Stack>

                            {/*screenSize.isTabletOrBigger ?
                                <Box className='limit--block'>
                                    <Stack space={24}>
                                        <DoubleField
                                            fullWidth
                                            layout='60/40'
                                        >
                                            <DataCard
                                                icon={
                                                    <IconGroupRegular color={skinVars.colors.brand} size={40} />
                                                }
                                                title="Gestión"
                                                extra={
                                                    <div className='dataCard--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                                        `<ul>
                                                            <li>Integración de factores ASG en nuestros procesos de inversión</li>
                                                            <li>Contribuir al crecimiento económico sostenible a largo plazo</li>
                                                        </ul>`
                                                    }}></div>
                                                }
                                            />

                                            <DataCard
                                                icon={
                                                    <IconSniperScopeRegular color={skinVars.colors.brand} size={40} />
                                                }
                                                title="Filosofia"
                                                extra={
                                                    <div className='dataCard--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                                        `<ul>
                                                            <li>Pensamiento a largo plazo: buscando minimizar riesgos, maximizar oportunidades</li>
                                                            <li>Aunar sentido económico, responsabilidad y compromiso</li>
                                                        </ul>`
                                                    }}></div>
                                                }
                                            />
                                        </DoubleField>

                                        <DoubleField
                                            fullWidth
                                            layout='40/60'
                                        >
                                            <DataCard
                                                icon={
                                                    <IconWalletRegular color={skinVars.colors.brand} size={40} />
                                                }
                                                title="Cartera ISR"
                                                extra={
                                                    <div className='dataCard--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                                        `<ul>
                                                            <li>52,8% en renta fija</li>
                                                            <li>34,8% en renta variable</li>
                                                            <li>12,4% en inversiones ilíquidas</li>
                                                        </ul>`
                                                    }}></div>
                                                }
                                            />

                                            <DataCard
                                                icon={
                                                    <IconEcoRegular color={skinVars.colors.brand} size={40} />
                                                }
                                                title="Objetivos a medio plazo"
                                                extra={
                                                    <div className='dataCard--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                                        `<ul>
                                                            <li>Cumplimiento con las normativas europeas en cuanto a taxonomía ambiental y social</li>
                                                            <li>Política Net Zero de las carteras en línea con el Acuerdo de París</li>
                                                            <li>Medición del impacto social que nuestra cartera temática aporta a la sociedad</li>
                                                        </ul>`
                                                    }}></div>
                                                }
                                            />
                                        </DoubleField>
                                    </Stack>
                                </Box>
                            :
                                <Carousel
                                    itemsPerPage={size.isMobile ? 1 : 2}
                                    items={[
                                        <DataCard
                                            icon={
                                                <IconGroupRegular color={skinVars.colors.brand} size={40} />
                                            }
                                            title="Gestión"
                                            extra={
                                                <div className='dataCard--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                                    `<ul>
                                                        <li>Integración de factores ASG en nuestros procesos de inversión</li>
                                                        <li>Contribuir al crecimiento económico sostenible a largo plazo</li>
                                                    </ul>`
                                                }}></div>
                                            }
                                        />,
                                        <DataCard
                                            icon={
                                                <IconSniperScopeRegular color={skinVars.colors.brand} size={40} />
                                            }
                                            title="Filosofia"
                                            extra={
                                                <div className='dataCard--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                                    `<ul>
                                                        <li>Pensamiento a largo plazo: buscando minimizar riesgos, maximizar oportunidades</li>
                                                        <li>Aunar sentido económico, responsabilidad y compromiso</li>
                                                    </ul>`
                                                }}></div>
                                            }
                                        />,
                                        <DataCard
                                            icon={
                                                <IconWalletRegular color={skinVars.colors.brand} size={40} />
                                            }
                                            title="Cartera ISR"
                                            extra={
                                                <div className='dataCard--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                                    `<ul>
                                                        <li>52,8% en renta fija</li>
                                                        <li>34,8% en renta variable</li>
                                                        <li>12,4% en inversiones ilíquidas</li>
                                                    </ul>`
                                                }}></div>
                                            }
                                        />,
                                            <DataCard
                                                icon={
                                                    <IconEcoRegular color={skinVars.colors.brand} size={40} />
                                                }
                                                title="Objetivos a medio plazo"
                                                extra={
                                                    <div className='dataCard--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                                        `<ul>
                                                            <li>Cumplimiento con las normativas europeas en cuanto a taxonomía ambiental y social</li>
                                                            <li>Política Net Zero de las carteras en línea con el Acuerdo de París</li>
                                                            <li>Medición del impacto social que nuestra cartera temática aporta a la sociedad</li>
                                                        </ul>`
                                                    }}></div>
                                                }
                                            />
                                    ]}
                                />
                            */}
                        </Stack>
                    </ResponsiveLayout>
                </Box>                  

                <Box paddingY={24}>
                    <Stack space={16}>
                        <Text3 medium>{intl.formatMessage({id:'page_sustainability_title_policy_statement'})}</Text3>
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_policy_statement'})}}></div>
                        <Text3 medium>{intl.formatMessage({id:'page_sustainability_title_remuneration_policies'})}</Text3>
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_remuneration_policies'})}}></div>
                        <Text3 medium>
                            {intl.formatMessage({id:'page_sustainability_adherence_good_faith'})}
                        </Text3>
                        <div className='hero--extraContent html-content' ><p>El 23 de junio de 2023, Fonditel Pensiones EGFP, S.A. se adhirió al Código de buenas prácticas de inversores de la Comisión Nacional del Mercado de Valores, acogiéndose al periodo transitorio.</p></div>
                        <Inline space={12}>
                            <ButtonPrimary small onPress={() => {downloadDocument('Carta-Adhesion-Codigo-Buenas-Practicas-CNMV_signed.pdf', 'Políticas de remuneración')}}>Carta de Adhesión al Código de Buenas Prácticas</ButtonPrimary>
                            <ButtonPrimary small onPress={() => {downloadDocument('Plan-y-calendario-de-adaptacion-al-Codigo-de-Buenas-Practicas-para-Inversores-de-CNMV.pdf', 'Buenas prácticas')}}>Plan y calendario de adaptación al Código de Buenas Prácticas</ButtonPrimary>
                        </Inline>           
                        <Text3 medium> {intl.formatMessage({id:'page_sustainability_vote_report_title'})}
                        </Text3>
                        <div className='hero--extraContent html-content' ><p>{intl.formatMessage({id:'page_sustainability_vote_report_description'})}</p></div>
                        <Inline space={12}>
                            <ButtonPrimary small onPress={() => {downloadDocument('INFORME SOBRE PARTICIPACIÓN JGA 2023 - Fonditel Gestión SGIIC.pdf', 'INFORME SOBRE PARTICIPACIÓN JGA 2023 - Fonditel Gestión SGIIC')}}>{intl.formatMessage({id:'page_sustainability_vote_report_button'})}</ButtonPrimary>
                        </Inline>  
                    </Stack>
                </Box>
                {/*
                <Stack space={screenSize.isDesktopOrBigger ? 80 : 40}>
                    <Box className='boxAlignCenter'>
                        <Text6>Filosofía de inversión</Text6>
                    </Box>

                    <Stack space={0} className='limit--block'>
                        <Inline space={screenSize.isTabletOrBigger ? 'between' : 'evenly'} wrap>
                            <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                                <Stack space={16}>
                                    <Inline space={'evenly'}>
                                        <IconPeopleNetworkLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                    </Inline>

                                    <Stack space={8}>
                                        <Box className='boxAlignCenter'>
                                            <Text4 medium>Independencia</Text4>
                                        </Box>

                                        <Box className='boxAlignCenter'>
                                            <Text3 regular color={skinVars.colors.textSecondary}>Alineados completamente con los intereses de nuestros clientes</Text3>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Box>

                            <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                                <Stack space={16}>
                                    <Inline space={'evenly'}>
                                        <IconPresentationLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                    </Inline>

                                    <Stack space={8}>
                                        <Box className='boxAlignCenter'>
                                            <Text4 medium>Patrimonialistas y Gestión eficiente</Text4>
                                        </Box>

                                        <Box className='boxAlignCenter'>
                                            <Text3 regular color={skinVars.colors.textSecondary}>Gestión del riesgo como clave. Foco en Asignación de Activos (AA), gestión activa y costes</Text3>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Box>

                            <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                                <Stack space={16}>
                                    <Inline space={'evenly'}>
                                        <IconSmileyHappyLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                    </Inline>

                                    <Stack space={8}>
                                        <Box className='boxAlignCenter'>
                                            <Text4 medium>Cercania con el cliente</Text4>
                                        </Box>

                                        <Box className='boxAlignCenter'>
                                            <Text3 regular color={skinVars.colors.textSecondary}>Servicio ad hoc. Diálogo constante con las Comisiones de Control. El cliente como centro</Text3>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Inline>

                        <Inline space={'evenly'} wrap>
                            <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                                <Stack space={16}>
                                    <Inline space={'evenly'}>
                                        <IconIntranetLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                    </Inline>

                                    <Stack space={8}>
                                        <Box className='boxAlignCenter'>
                                            <Text4 medium>Innovación</Text4>
                                        </Box>

                                        <Box className='boxAlignCenter'>
                                            <Text3 regular color={skinVars.colors.textSecondary}>Desarrollo continuo como base de la mejora</Text3>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Box>

                            <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                                <Stack space={16}>
                                    <Inline space={'evenly'}>
                                        <IconPlantLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                    </Inline>

                                    <Stack space={8}>
                                        <Box className='boxAlignCenter'>
                                            <Text4 medium>Responsabilidad</Text4>
                                        </Box>

                                        <Box className='boxAlignCenter'>
                                            <Text3 regular color={skinVars.colors.textSecondary}>Con las inversiones, los clientes y la sociedad</Text3>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Inline>
                    </Stack>
                </Stack>
                */}
            </ResponsiveLayout>

            {/*

            <Box className={(selectedIndex===0 ? 'landingPage--content d-block' : 'd-none')}>
                <ResponsiveLayout>
                    <Hero
                        background="default"
                        media={
                            <Image src='../assets/img/sostenibilidad-hero3.jpg' aspectRatio='16:9' />
                        }
                        title="Comprometidos con la sostenibilidad"
                        extra={
                            <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                `<p>En un mundo complejo como el actual, cada vez nos enfrentamos a más retos como sociedad. Debemos abordar estos problemas para contribuir a un desarrollo económico, social y medioambiental sostenible, sin perjudicar a generaciones futuras. De ahí nuestro compromiso de incorporar inversiones sostenibles y responsables (ISR) en nuestra cartera.</p>
                                <p>La ISR persigue maximizar la rentabilidad a largo plazo para los inversores, beneficiando a la vez al planeta y la sociedad.</p>`
                            }}></div>
                        }
                        desktopMediaPosition="left"
                    />

                    <Hero
                        background="default"
                        media={
                            <Image src='../assets/img/sostenibilidad-hero6.jpg' aspectRatio='16:9' />
                        }
                        title="Innovación tecnológica al servicio de la sostenibilidad"
                        extra={
                            <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                `<p>La inversión sostenible requiere del análisis de muchos datos y de mucha información sobre temas muy diversos y buscar las fuentes más fiables y especializadas para cada uno de ellos. Conscientes de esto, en Fonditel seleccionamos una herramienta de inteligencia artificial como nuestro proveedor de datos.</p>
                                <p>Apalancándonos en la información de dicho proveedor, medimos el rating de cada uno de los tres factores ASG así como el rating global o ISR de la cartera, su intensidad de carbono y analizamos y monitorizamos las controversias, siguiendo las recomendaciones de UN PRI.</p>`
                            }}></div>
                        }
                        desktopMediaPosition="right"
                    />

                    <Hero
                        background="default"
                        media={
                            <Image src='../assets/img/sostenibilidad-hero7.jpg' aspectRatio='16:9' />
                        }
                        title="Más allá de la rentabilidad financiera"
                        extra={
                            <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                `<p>Nuestra Inversión ISR refleja las preocupaciones e inquietudes de nuestros grupos de interés.</p>
                                <p>Seleccionamos inversiones que aúnen sentido económico en el largo plazo y contribuyan a la consecución de los ODS, buscando ofrecer una rentabilidad financiera atractiva y contribuir positivamente a la sociedad y al medio ambiente.</p>`
                            }}></div>
                        }
                        desktopMediaPosition="left"
                    />

                    <Hero
                        background="default"
                        media={
                            <Image src='../assets/img/sostenibilidad-hero8.jpg' aspectRatio={screenSize.isDesktopOrBigger ? '7:10' : '16:9'} />
                        }
                        title="Contribuimos en la difusión de la ISR y al progreso de la sociedad"
                        extra={
                            <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                `<p>Estamos comprometidos con la difusión y promoción de la ISR a nivel global por lo que continuamente estamos abiertos a colaborar, informar y debatir avances regulatorios y de mercado en temas de sostenibilidad.</p>
                                <p>Desde 2009 somos miembros cofundadores de Spainsif, el foro español de ISR que promueve la inversión sostenible en España y participamos continuamente en eventos de la industria, en diferentes encuentros de educación financiera además de diálogos internos con nuestros grupos de interés.
                                <p>Como parte de nuestro compromiso con la sociedad y su progreso, colaboramos con la Fundación del Grupo Telefónica en actividades de voluntariado dirigidas especialmente a niños, personas con discapacidad y a nuestros mayores además de colaboraciones económicas en proyectos solidarios de la Fundación y propios.</p>`
                            }}></div>
                        }
                        desktopMediaPosition="right"
                    />

                    <Box paddingY={screenSize.isDesktopOrBigger ? 40 : 24}>
                        <HorizontalScroll>
                            <Image src='../assets/img/sostenibilidad-linea-tiempo.jpg' height={270} width={1224} />
                        </HorizontalScroll>
                    </Box>
                </ResponsiveLayout>
            </Box>

            <ResponsiveLayout className={(selectedIndex===0 ? 'landingPage--content d-block' : 'd-none')}>
                <Box paddingBottom={40}>
                    <Text6>En Fonditel basamos nuestra ISR en los Principios de Inversión Responsable, apoyados por Naciones Unidas (UN PRI) y los Objetivos de Desarrollo Sostenible (ODS).</Text6>
                </Box>

                <Stack space={screenSize.isDesktopOrBigger ? 80 : 40}>
                    <Box className='boxAlignCenter'>
                        <Text6>Principios UN PRI</Text6>
                    </Box>

                    <Stack space={0} className='limit--block'>
                        <Inline space={screenSize.isTabletOrBigger ? 'between' : 'evenly'} wrap>
                            <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                                <Stack space={16}>
                                    <Inline space={'evenly'}>
                                        <IconChangePlanRegular size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                    </Inline>

                                    <Stack space={8}>
                                        <Box className='boxAlignCenter'>
                                            <Text4 medium>1. Incorporamos</Text4>
                                        </Box>

                                        <div className='boxIcon--content html-content' dangerouslySetInnerHTML={{__html:
                                            `<p>Cuestiones ASG a nuestros procesos de análisis y toma de decisiones en inversiones</p>
                                            <ul>
                                                <li>Scoring</li>
                                                <li>Tecnología en la integración de datos</li>
                                            </ul>`
                                        }}></div>
                                    </Stack>
                                </Stack>
                            </Box>

                            <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                                <Stack space={16}>
                                    <Inline space={'evenly'}>
                                        <IconRouteRegular size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                    </Inline>

                                    <Stack space={8}>
                                        <Box className='boxAlignCenter'>
                                            <Text4 medium>2. Somos activos</Text4>
                                        </Box>

                                        <div className='boxIcon--content html-content' dangerouslySetInnerHTML={{__html:
                                            `<p>En incorporar cuestiones ASG a nuestras políticas y prácticas de propiedad.</p>
                                            <ul>
                                                <li>En 2021 ejercicio de voto en las 116 juntas de accionistas convocadas</li>
                                            </ul>`
                                        }}></div>
                                    </Stack>
                                </Stack>
                            </Box>

                            <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                                <Stack space={16}>
                                    <Inline space={'evenly'}>
                                        <IconLoudspeakerRegular size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                    </Inline>

                                    <Stack space={8}>
                                        <Box className='boxAlignCenter'>
                                            <Text4 medium>3. Procuramos</Text4>
                                        </Box>

                                        <div className='boxIcon--content html-content' dangerouslySetInnerHTML={{__html:
                                            `<p>En incorporar cuestiones ASG a nuestras políticas y prácticas de propiedad.</p>
                                            <ul>
                                                <li>En 2021 ejercicio de voto en las 116 juntas de accionistas convocadas</li>
                                            </ul>`
                                        }}></div>
                                    </Stack>
                                </Stack>
                            </Box>

                            <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                                <Stack space={16}>
                                    <Inline space={'evenly'}>
                                        <IconConferenceRegular size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                    </Inline>

                                    <Stack space={8}>
                                        <Box className='boxAlignCenter'>
                                            <Text4 medium>4. Promovemos</Text4>
                                        </Box>

                                        <div className='boxIcon--content html-content' dangerouslySetInnerHTML={{__html:
                                            `<p>Aceptación y aplicación de los principios UN PRI en el sector de las inversiones.</p>
                                            <p>Somos miembros de</p>`
                                        }}></div>

                                        <Image src="../assets/img/sostenibilidad-promovemos.png" width={256} height={55} />
                                    </Stack>
                                </Stack>
                            </Box>

                            <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                                <Stack space={16}>
                                    <Inline space={'evenly'}>
                                        <IconInternetRegular size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                    </Inline>

                                    <Stack space={8}>
                                        <Box className='boxAlignCenter'>
                                            <Text4 medium>5. Colaboramos</Text4>
                                        </Box>

                                        <div className='boxIcon--content html-content' dangerouslySetInnerHTML={{__html:
                                            `<p>Para mejorar la eficacia en la implementación de los PRI. Diálogo con nuestros grupos de interés.</p>`
                                        }}></div>

                                        <Image src="../assets/img/sostenibilidad-colaboramos.png" width={290} height={109} />
                                    </Stack>
                                </Stack>
                            </Box>

                            <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                                <Stack space={16}>
                                    <Inline space={'evenly'}>
                                        <IconPresentationRegular size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                    </Inline>

                                    <Stack space={8}>
                                        <Box className='boxAlignCenter'>
                                            <Text4 medium>6. Reportamos</Text4>
                                        </Box>

                                        <div className='boxIcon--content html-content' dangerouslySetInnerHTML={{__html:
                                            `<p>Nuestra actividad y progreso hacia la aplicación de los Principios.</p>
                                            <ul>
                                                <li>Página Web</li>
                                                <li>Comunicación a las Comisiones de Control</li>
                                                <li>Memoria de Sostenibilidad</li>
                                            </ul>`
                                        }}></div>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Inline>
                    </Stack>
                </Stack>
                */}
                {/*
                <Box className='fullImage--block'>
                    <Image src={screenSize.isTabletOrBigger ? '../assets/img/sostenibilidad-objetivos.jpg' : '../assets/img/sostenibilidad-objetivos-mobile.jpg'} />
                </Box>
                */}
            {/*
            </ResponsiveLayout>

            <Box className={(selectedIndex===0 ? 'landingPage--content d-block' : 'd-none')}>
                <ResponsiveLayout>
                    <Stack space={40}>
                        <Text6>La inversión sostenible ha experimentado un crecimiento significativo en los últimos años. En Fonditel estamos convencidos de que esta tendencia continuará en el futuro, impulsada por el creciente interés por parte de los inversores finales, que cada vez dan más importancia a que sus inversiones, además de ser rentables, reflejen sus inquietudes y generen un impacto positivo y respaldada por el continuo desarrollo del marco regulatorio.</Text6>

                        <Text6>La inversión sostenible ha experimentado un crecimiento significativo en los últimos años. En Fonditel estamos convencidos de que esta tendencia continuará en el futuro, impulsada por el creciente interés por parte de los inversores finales, que cada vez dan más importancia a que sus inversiones, además de ser rentables, reflejen sus inquietudes y generen un impacto positivo y respaldada por el continuo desarrollo del marco regulatorio.</Text6>

                        <Stack space={24}>
                            <Text5 color={skinVars.colors.brand}>Peso creciente de nuestra cartera temática</Text5>

                            <Text4 light>Fruto de nuestro compromiso con la ISR, el peso de nuestra cartera temática ha ido aumentando progresivamente sobre el total del patrimonio gestionado desde el 4,5% de los activos bajo gestión en 2017 hasta el 8,9% a finales del 2021.</Text4>
                        </Stack>

                        <Box className='fullImage--block'>
                            <Image src={screenSize.isTabletOrBigger ? '../assets/img/sostenibilidad-cartera.jpg' : '../assets/img/sostenibilidad-cartera-mobile.jpg'} />
                        </Box>
                    </Stack>

                    <Hero
                        background="default"
                        media={
                            <Image src='../assets/img/sostenibilidad-hero11.jpg' aspectRatio='16:9' />
                        }
                        title="Cartera temática ligada a los ODS"
                        extra={
                            <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                `<p>En los últimos años, hemos avanzado en la definición de las temáticas de inversión alineadas con los ODS identificadas como prioritarias para nuestros grupos de interés y la gestora. Con ese propósito, realizamos una encuesta entre nuestros principales grupos de interés para conocer sus preferencias de inversión en estos aspectos e identificamos, entre una lista de temáticas, aquellas que tenían mayor sentido económico y producto de inversión sostenible. De esta forma, de los 17 ODS, en Fonditel consideramos prioritario contribuir a los siguientes objetivos:</p>`
                            }}></div>
                        }
                        desktopMediaPosition="right"
                    />

                    <Stack space={56}>
                        <Box className='fullImage--block screenSize--same'>
                            <Image src='../assets/img/sostenibilidad-ods.jpg' />
                        </Box>

                        <Box paddingBottom={56} className='limit--block boxAlignCenter'>
                            <Text5>Nuestro objetivo es encontrar inversiones que aúnen sentido económico en el largo plazo y el compromiso con los ODS, buscando que nuestras inversiones, además de ofrecer una rentabilidad financiera atractiva, contribuyan positivamente al medio ambiente y a la sociedad de una forma que sea susceptible de medición.</Text5>
                        </Box>
                    </Stack>
                </ResponsiveLayout>

                <ResponsiveLayout backgroundColor={skinVars.colors.backgroundAlternative} className='bg--alternative'>
                    <Box className='boxAlignCenter' paddingTop={80}>
                        <Text6>Desglose cartera temática 2021 (% sobre activos bajo gestión)</Text6>
                    </Box>

                    <Hero
                        background="alternative"
                        media={
                            <Image src='../assets/img/sostenibilidad-hero12.jpg' aspectRatio='16:9' />
                        }
                        title="Cartera temática 2021"
                        extra={
                            <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                `<p>Nuestra cartera temática combina inversiones con sesgo ambiental, social y de gobernanza bajo el criterio de clasificación de dichas inversiones en función de cuál es el ámbito predominante de la inversión. Atendiendo a este criterio, el 43% responden al factor ambiental, el 52% al social y el 5% restante a gobernanza.</p>
                                <p>En resumen, nuestras áreas prioritarias son cambio climático, envejecimiento activo, infraestructuras, eficiencia energética, agua, gestión de residuos, digitalización y educación.</p>`
                            }}></div>
                        }
                        desktopMediaPosition="left"
                    />

                    <Hero
                        background="alternative"
                        media={
                            <Image src='../assets/img/sostenibilidad-hero13.jpg' aspectRatio='16:9' />
                        }
                        title="Cartera temática 2021Con esta cartera buscamos:"
                        extra={
                            <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:
                                `<ul>
                                    <li>Inversiones que aúnen el beneficio financiero, así como su impacto o generación de valor en la sociedad</li>
                                    <li>Orientación a medio y largo plazo</li>
                                    <li>Asunción de riesgo activo, exposición mundia</li>
                                </ul>
                                <p>Asimismo, nuestra cartera temática incluye una amplia variedad de tipos de activos a través de 31 inversiones diferentes de las cuales 7 corresponden a inversiones en activos ilíquidos (12,4% del total), 13 inversiones en renta variable (34,8% del total) y 11 vehículos de renta fija (52,8% del total).</p>`
                            }}></div>
                        }
                        desktopMediaPosition="right"
                    />
                </ResponsiveLayout>

                <Box className='limit--block' paddingY={40}>
                    <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={screenSize.isDesktopOrBigger ? 16 : 40}>
                        <Stack space={40}>
                            <Doughnut data={data1} options={optionsGraphics} />

                            <Box className='boxAlignCenter'>
                                <Text5>Por ámbito predominante de inversión</Text5>
                            </Box>
                        </Stack>

                        <Stack space={40}>
                            <Doughnut data={data2} options={optionsGraphics} />

                            <Box className='boxAlignCenter'>
                                <Text5>Por tipo de activo</Text5>
                            </Box>
                        </Stack>
                    </Grid>
                </Box>

                <ResponsiveLayout>
                    <Box paddingY={40}>
                        <Stack space={40}>
                            <Stack space={16}>
                                <Box className='boxAlignCenter'>
                                    <Text6>Conoce más sobre la Sostenibilidad en Fonditel</Text6>
                                </Box>

                                <Box className='boxAlignCenter'>
                                    <Text3 regular>Puedes descargarte nuestras memorias en los siguientes enlaces:</Text3>
                                </Box>
                            </Stack>

                            <Inline space={'evenly'} wrap>
                                <Box paddingBottom={32} paddingX={16}>
                                    <ButtonPrimary onPress={() => {downloadDocument('Fonditel-Memoria-de-Sostenibilidad-2022.pdf')}}>Memoria de Sostenibilidad 2022</ButtonPrimary>
                                </Box>

                                <Box paddingBottom={32} paddingX={16}>
                                    <ButtonPrimary onPress={() => {downloadDocument('Fonditel-Memoria-de-Sostenibilidad-2021.pdf')}}>Memoria de Sostenibilidad 2021</ButtonPrimary>
                                </Box>

                                <Box paddingBottom={32} paddingX={16}>
                                    <ButtonPrimary onPress={() => {downloadDocument('Fonditel-Memoria-de-Sostenibilidad-2018.pdf')}}>Memoria de Sostenibilidad 2018</ButtonPrimary>
                                </Box>

                                <Box paddingBottom={32} paddingX={16}>
                                    <ButtonPrimary onPress={() => {downloadDocument('Fonditel-Memoria-de-Sostenibilidad-2017.pdf')}}>Memoria de Sostenibilidad 2017</ButtonPrimary>
                                </Box>
                            </Inline>
                        </Stack>
                    </Box>
                </ResponsiveLayout>
            </Box>

            <ResponsiveLayout className={(selectedIndex===0 ? 'landingPage--content d-block' : 'd-none')}>
                <Box paddingBottom={40}>
                    <div className='chipTabs--content html-content' dangerouslySetInnerHTML={{__html:
                        `<h2>Información de Fonditel Pensiones, E.G.F.P., S.A. y Fonditel Gestión S.G.I.I.C. S.A.U</h2>
                        <ol>
                            <li>Información sobre la política de integración de los riesgos de sostenibilidad en el proceso de toma de decisiones.</li>
                            <li>El proceso de toma de decisiones de inversión tiene en cuenta los riesgos de sostenibilidad y está basado en análisis de terceros y propios.</li>
                            <li>Para ello la Gestora tomará como referencia la información disponible publicada por los emisores de los activos en los que invierte, podrá tener en cuenta los ratings ASG publicados por parte de las compañías de calificación crediticias y utilizará los datos facilitados por proveedores externos.</li>
                            <li>Declaración sobre la política en relación con las incidencias adversas de las decisiones de inversión sobre los factores de sostenibilidad.</li>
                            <li>La gestora no toma en consideración las incidencias adversas sobre los factores de sostenibilidad ya que no dispone actualmente de políticas de diligencia debida en relación con dichas incidencias adversas.</li>
                            <li>Políticas de remuneración en relación con la integración de los riegos de sostenibilidad.</li>
                            <li>La Política Remuneración de la entidad es una herramienta clave para las buenas prácticas de gobernanza y la gestión efectiva de los riesgos en la medida en que no alienta la asunción de riesgos excesivos, entre los que se incluyen los riesgos de sostenibilidad.</li>
                        </ol>
                        <h3>Definiciones del Reglamento (UE) 2019/2088 del Parlamento Europeo y del Consejo de 27 de noviembre de 2019 sobre la divulgación de información relativa a la sostenibilidad:</h3>
                        <h4>Factores de sostenibilidad</h4>
                        <p>Toda información relacionada con cuestiones medioambientales y sociales, así como relativas al personal, y con el respeto de los derechos humanos y la lucha contra la corrupción y el soborno.</p>
                        <h4>Incidencias adversas</h4>
                        <p>Aquellas incidencias de las decisiones de inversión que tengan efectos negativos sobre los factores de sostenibilidad (Considerando 20 Reglamento UE 2019/2088)Inversiones sosteniblesInversiones en una actividad económica que contribuyan a un objetivo medioambiental, medido, por ejemplo a través de indicadores clave de eficiencia de recursos relativos al uso de la energía, de la energía renovable, consumo de materias primas, agua y suelo, producción de residuos y emisiones de gases de efecto invernadero e impacto sobre la biodiversidad y la economía circular o las inversiones en una actividad económica que contribuyan a un objetivo social y, en particular, toda inversión que contribuya a luchar contra la desigualdad, toda inversión que refuerce la cohesión social, la integración social y las relaciones laborales, o toda inversión en capital humano o en comunidades económica o socialmente desfavorecidas; siempre y cuando las inversiones no perjudiquen significativamente a ninguno de dichos objetivos y las empresas beneficiarias sigan prácticas de buena gobernanza, en particular en lo que respecta a que sus estructuras de gestión, relaciones con los asalariados y remuneración del personal pertinente sean sanas y cumplan las obligaciones tributarias (art. 2 Reglamento (UE) 2019/2088)</p>
                        <h4>Riesgo de sostenibilidad</h4>
                        <p>Todo acontecimiento o estado medioambiental, social o de gobernanza que, de ocurrir, pudiera surtir un efecto material negativo real o posible sobre el valor de la inversión.</p>`
                    }}></div>
                </Box>
            </ResponsiveLayout>

            <ResponsiveLayout className={(selectedIndex===0 ? 'landingPage--content d-block' : 'd-none')}>
                <Box paddingBottom={24}>
                    <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                        <MediaCard
                            headline={<Tag type='active'>Prensa</Tag>}
                            pretitle='24/01/2023'
                            title='Te contamos cómo es el sistema de pensiones español con respecto a los europeos.'
                            media={<Image src='../assets/img/home-planes-pensiones.jpg' noBorderRadius aspectRatio='16:9' />}
                            button={<ButtonLink onPress={() => {}}>{intl.formatMessage({id:'page_news_card_button'})}<IconArrowLineRightRegular color='currentColor'/></ButtonLink>}
                        />

                        <MediaCard
                            headline={<Tag type='active'>Prensa</Tag>}
                            pretitle='24/01/2023'
                            title='¿Sabes cuáles son los límites de aportación para tu Plan de Pensiones?'
                            media={<Image src='../assets/img/home-planes-pensiones-empleo.jpg' noBorderRadius aspectRatio='16:9' />}
                            button={<ButtonLink onPress={() => {}}>{intl.formatMessage({id:'page_news_card_button'})}<IconArrowLineRightRegular color='currentColor'/></ButtonLink>}
                        />
                    </Grid>
                </Box>

                <Box paddingBottom={24}>
                    <Grid columns={screenSize.isDesktopOrBigger ? 3 : 1} gap={24}>
                        <MediaCard
                            headline={<Tag type='active'>Prensa</Tag>}
                            pretitle='24/01/2023'
                            title='Te contamos cómo es el sistema de pensiones español con respecto a los europeos.'
                            media={<Image src='../assets/img/home-planes-pensiones.jpg' noBorderRadius aspectRatio='16:9' />}
                            button={<ButtonLink onPress={() => {}}>{intl.formatMessage({id:'page_news_card_button'})}<IconArrowLineRightRegular color='currentColor'/></ButtonLink>}
                        />

                        <MediaCard
                            headline={<Tag type='active'>Prensa</Tag>}
                            pretitle='24/01/2023'
                            title='Te contamos cómo es el sistema de pensiones español con respecto a los europeos.'
                            media={<Image src='../assets/img/home-planes-pensiones-empleo.jpg' noBorderRadius aspectRatio='16:9' />}
                            button={<ButtonLink onPress={() => {}}>{intl.formatMessage({id:'page_news_card_button'})}<IconArrowLineRightRegular color='currentColor'/></ButtonLink>}
                        />

                        <MediaCard
                            headline={<Tag type='active'>Prensa</Tag>}
                            pretitle='24/01/2023'
                            title='¿En qué consiste la inversión en activos alternativos?'
                            media={<Image src='../assets/img/home-fondos-inversion.jpg' noBorderRadius aspectRatio='16:9' />}
                            button={<ButtonLink onPress={() => {}}>{intl.formatMessage({id:'page_news_card_button'})}<IconArrowLineRightRegular color='currentColor'/></ButtonLink>}
                        />
                    </Grid>
                </Box>

                <Box paddingBottom={64}>
                    <Inline space={'evenly'} wrap>
                        <ButtonPrimary onPress={() => {}}>Ver más noticias</ButtonPrimary>
                    </Inline>
                </Box>
            </ResponsiveLayout>
*/}
            <ResponsiveLayout className='content' fullWidth>
                <MembersOf></MembersOf>
            </ResponsiveLayout>
        </LayoutPublic>
    );
};

export default IsrInFonditelPage;