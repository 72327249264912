import { Box, ButtonLayout, ButtonPrimary, Checkbox, Form, Grid, GridLayout, IbanField, IconInformationUserRegular, Inline, IntegerField, LoadingBar, Select, SkeletonLine, SkeletonRectangle, skinVars, Stack, Switch, Text1, Text2, Text4, TextField, Tooltip, useScreenSize, useWindowSize } from '@telefonica/mistica';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DatosPostales } from '../../entities/clients/datos-postales';
import { ListOption } from '../../entities/commons/list-option.model';
import { BankAccount } from '../../entities/contract/bank-account.model';
import { Contract } from '../../entities/contract/contract';
import { ContractHolder } from '../../entities/contract/contractHolder';
import { Benefit } from '../../entities/products/benefit';
import { getMasterDatas, getProvinceCity, setContractData } from '../../utils/apiclient';
import { getCurrentLang } from '../../utils/locate';
import { getOperationKey, getTokenSign, getUser, removeOperationKey, setInfoContractCache } from '../../utils/storage';
import { useToast } from '../../utils/toast';
import { formatBalance, getPlatform, havePermission, trackError } from '../../utils/utils';
import { useDigitalSign } from '../digital-sign/DigitalSign';
import { useSignOperationsKey } from '../operations-key/SignOperationsKey';
import './ContractData.scss';

interface ContractDataProps {
    contract: Contract | null
    loading?: boolean
}

const ContractData: React.FC<ContractDataProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const size = useWindowSize();
    const user = getUser();

    const { digitalSign } = useDigitalSign();

    const [countryOptions, setCountryOptions] = React.useState([] as ListOption[]);
    const [provinceOptions, setProvinceOptions] = React.useState([] as ListOption[]);
    const [viaOptions, setViaOptions] = React.useState([] as ListOption[]);
    const [contract, setContract] = useState(null as null|Contract);
    const [prestacion, setPrestacion] = useState(null as null|Benefit);
    const [contractHolder, setContractHolder] = useState(null as  null|ContractHolder);
    const [contractHolders, setContractHolders] = useState([] as ContractHolder[]);
    const [formData, setFormData] = useState(null as null|any);

    const { sign } = useSignOperationsKey()
    const { showToast } = useToast()

    const [formErrors, setFormErrors] = useState([] as any);
    const [disabledCorrespondenceAddressProvince, setDisabledCorrespondenceAddressProvince] = useState(false);

    //Loading form
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingSave, setLoadingSave] = React.useState(false);

    useEffect(()=>{
        if(prop.contract){
            setContract(prop.contract);
            if(prop.contract.intervinientes){
                const intervinientes = Object.values(prop.contract.intervinientes);
                const holder = intervinientes.find(item=>item.NIF===user.nif);
                if(holder)
                    setContractHolder(holder);
                setContractHolders(intervinientes.filter(item=>item.NIF!==user.nif));
            }
            if(prop.contract.prestacion){
                setPrestacion(prop.contract.prestacion);
            }

            if(prop.contract.cliente){
                setFormData({
                    taxAddressEqual: prop.contract.cliente.mismaDireccionFiscalYPostal ?? false,
                    correspondenceAddressCountry: prop.contract.cliente.datosPostales?.domicilioPais?.toString() ?? "11",
                    correspondenceAddressZipcode: prop.contract.cliente.datosPostales?.domicilioCP ?? '',
                    correspondenceAddressProvince: prop.contract.cliente.datosPostales?.domicilioProvincia?.toString() ?? '',
                    correspondenceAddressLocality: prop.contract.cliente.datosPostales?.domicilioPoblacion ?? '',
                    correspondenceAddressVia: prop.contract.cliente.datosPostales?.domicilioTipoVia ?? '',
                    correspondenceAddressNameVia: prop.contract.cliente.datosPostales?.domicilioVia ?? '',
                    correspondenceAddressNumber: prop.contract.cliente.datosPostales?.domicilioNumero ?? '',
                    correspondenceAddressFloor: prop.contract.cliente.datosPostales?.domicilioPiso ?? '',
                    bicA: prop.contract.plan?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ? (prop.contract.cuentasBancarias && prop.contract.cuentasBancarias.length>0 ? prop.contract.cuentasBancarias[0].IBAN : '') : '',
                    bicB: prop.contract.plan?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ? (prop.contract.cuentasBancarias && prop.contract.cuentasBancarias.length>1 ? prop.contract.cuentasBancarias[1].IBAN : '') : ''
                });
            }
        }
    },[prop.contract]);

    useEffect(()=>{
        getMasterDatas('countries').then(options=>{
          setCountryOptions(options);
        });
        getMasterDatas('provinces').then(options=>{
          setProvinceOptions(options);
        });
        getMasterDatas('addressTypes').then(options=>{
          setViaOptions(options);
        });
    }, []);


     //Handle fields
    const handleError=(name:string) =>{
        if(formErrors && formErrors[name]){
            const error = formErrors[name];
            if(error){
                return intl.formatMessage({id: 'page_register_'+error.toLowerCase().replaceAll(' ', '_')});
            }
        }
        return null;
    }

    const handleChangeField= (evt:any) => {
        const { target } = evt;
        const { name, value } = target;
        const newValues = {
            ...formData,
            [name]: value,
        };
        setFormData(newValues);
        if(handleError(name)){
            delete formErrors[name];
        }
        validateField(name, value);
    };

    const handleChangeAnyField= (name:string, value:any) => {
        const newValues = {
          ...formData,
          [name]: value,
        };
        setFormData(newValues);

        if(handleError(name)){
          delete formErrors[name];
        }

        switch(name){
          case 'correspondenceAddressCountry':
              const disabledCorrespondenceAddress = value!==process.env.REACT_APP_ID_PAIS_ESPANA ?? "11";
              const newValuesCorrespondenceAddress = {
                ...newValues,
                ['correspondenceAddressProvince']: disabledCorrespondenceAddress ? '' : formData.correspondenceAddressProvince
              };
              setFormData(newValuesCorrespondenceAddress);
              setDisabledCorrespondenceAddressProvince(disabledCorrespondenceAddress);
              break;
        }
    };

    const handleZipcode = (evt:any) =>{
    try{
        const { target } = evt;
        const { name, value } = target;

        if(value && value.toString().trim().length>0){
            const correspondenceAddressCountry = formData.correspondenceAddressCountry;
            if (correspondenceAddressCountry === process.env.REACT_APP_ID_PAIS_ESPANA) {
            setLoadingForm(true);
            getProvinceCity(value.toString().trim()).then(zipData=>{
                if(zipData && zipData.locality && zipData.province){
                const province = provinceOptions.find(item=>item.descripcion===zipData.province);

                switch(name){
                    case 'correspondenceAddressZipcode':
                    const newValuesCorrespondenceAddress = {
                        ...formData,
                        ['correspondenceAddressLocality']: zipData.locality,
                        ['correspondenceAddressProvince']: province?.valor ?? formData.correspondenceAddressProvince
                    };
                    setFormData(newValuesCorrespondenceAddress);
                    break;
                }
            }
            setLoadingForm(false);
        });
        }
        }
        }catch(err){
            if(err){
            trackError(JSON.stringify(err));
            }
        }
        }

    const validateField = (name:string, value:any)=>{
        switch(name){
          case 'bicA':
            if((value.replace(/\s/g, "") === formData.bicB.replace(/\s/g, "")) && value !== ""){
                formErrors[name] = 'accounts cannot be the same';
                return false;
              }
              break;
          case 'bicB':
            if((value.replace(/\s/g, "") === formData.bicA.replace(/\s/g, ""))  && value !== ""){
              formErrors[name] = 'accounts cannot be the same';
              return false;
            }
            break;

        }
        return true;
      }

    const updateContractData= (params:any, tokenOperation:string|null=null, dateSign:number|null=null): Promise<void> =>
        new Promise((resolve) => {
            setLoadingSave(true);
            const operationsKey = getOperationKey();
            const tokenSign = getTokenSign();

            if(operationsKey || tokenSign){
              params.operationsKey= operationsKey ? operationsKey : undefined;
              params.tokenSign= tokenSign ? tokenSign : undefined;
              params.tokenOperation= tokenOperation ? tokenOperation : undefined;
              params.dateSign= dateSign ? dateSign : undefined;
              params.electronic= tokenOperation && tokenSign ? true : false;
              params.confirm = true;
            }
            setContractData(params).then((result)=>{
                    if(result && result.result){
                        showToast({type:'ok', title: intl.formatMessage({id:'page_contract_data_contract_data_save_response_ok_title'}), description: intl.formatMessage({id:'page_contract_data_contract_data_save_response_ok_description'})});

                        if(contract && contract.cliente){
                            contract.cliente.mismaDireccionFiscalYPostal = formData.taxAddressEqual;
                            if(!formData.taxAddressEqual){
                                const datosPostales: DatosPostales={
                                    domicilioPais : formData.correspondenceAddressCountry,
                                    domicilioCP : formData.correspondenceAddressCountry,
                                    domicilioProvincia : formData.correspondenceAddressProvince,
                                    domicilioPoblacion : formData.correspondenceAddressLocality,
                                    domicilioTipoVia : formData.correspondenceAddressVia,
                                    domicilioVia : formData.correspondenceAddressNameVia,
                                    domicilioNumero : formData.correspondenceAddressNumber,
                                    domicilioPiso : formData.correspondenceAddressFloor,
                                }

                                contract.cliente.datosPostales = datosPostales;
                            }


                            if(contract.plan?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS){
                                const cuentaBancaria1 : BankAccount={
                                    IBAN : formData.bicA.toString().trim()
                                };
                                const cuentaBancaria2 : BankAccount={
                                    IBAN : formData.bicB.toString().trim()
                                };
                                const cuentasBancarias: BankAccount[] = [];
                                cuentasBancarias.push(cuentaBancaria1);
                                cuentasBancarias.push(cuentaBancaria2);

                                contract.cuentasBancarias = cuentasBancarias;
                            }
                            setInfoContractCache(contract, contract.numContrato ?? '', contract?.plan?.cdCompania ?? 0, contract?.plan?.cdPlan ?? "", contract?.plan?.cdFondo ?? "", contract?.plan?.cdSubplan ?? "", getCurrentLang());
                        }
                    }else{
                        showToast({type:'ko', title: intl.formatMessage({id:'page_contract_data_contract_data_save_response_ko_title'}), description: intl.formatMessage({id:'page_contract_data_contract_data_save_response_ko_description'})});
                    }
                    removeOperationKey();
            }).finally(()=>{
                setLoadingSave(false);
                resolve();
            });

        });

    const handleSubmit  = (): Promise<void> =>
        new Promise((resolve) => {
            setLoadingSave(true);

            //Validate
            let params : any = {
                hasTheSameFiscalAndPostalAddress: formData.taxAddressEqual,
                viaTypeResidence: formData.correspondenceAddressVia,
                viaResidence: formData.correspondenceAddressNameVia,
                numResidence: formData.correspondenceAddressNumber,
                floor: formData.correspondenceAddressFloor,
                localityResidence: formData.correspondenceAddressLocality,
                residenceCP: formData.correspondenceAddressZipcode,
                provinceResidence: formData.correspondenceAddressProvince,
                countryResidence: formData.correspondenceAddressCountry,
                numContract: prop.contract?.numContrato ?? "",
                cdCompany: prop.contract?.plan?.cdCompania ?? 0,
                cdPlan: prop.contract?.plan?.cdPlan ?? "",
                cdFund:  prop.contract?.plan?.cdFondo ?? "",
                cdSubplan:  prop.contract?.plan?.cdSubplan ?? "",
                bicA: formData.bicA.trim(),
                ibanA: formData.bicA.trim(),
                bicB: formData.bicB.trim(),
                ibanB: formData.bicB.trim(),
                logSource: getPlatform(),
                lang: getCurrentLang(),
            };

            setContractData(params).then((result)=>{
                if(result && result.result){

                    sign('Actualizar',
                    () => {
                        updateContractData(params);
                    },
                    ()=>{
                      setLoadingSave(false);
                      resolve();
                    },
                    ()=>{

                      setLoadingSave(true);
                      const dateSign = moment().unix();
                      params.confirm=true;
                      params.electronic=true;
                      params.dateSign=dateSign;
                      setContractData(params).then(result=>{
                        if(result.tokenOperation){
                            digitalSign(result.tokenOperation
                            , ()=>{
                                updateContractData(params, result.tokenOperation, dateSign);
                            }, ()=>{
                              setLoadingSave(false);
                            });
                        }
                      }).finally(()=>{
                      })
                    }
                  );

                }else if(result && result.errors){
                    const errors = result.errors as any;
                    setFormErrors(result.errors);
                    setLoadingSave(false);
                }
            });
        });

  //Submit disabled
  const isSubmitDisabled = () => {
    if(!formData) return true;
    const countryDefault = (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11");
    return (formData.taxAddressEqual!==true && (
      formData.correspondenceAddressCountry.length === 0 || handleError('correspondenceAddressCountry')!==null ||
      formData.correspondenceAddressZipcode.length === 0 || handleError('correspondenceAddressZipcode')!==null ||
      (formData.correspondenceAddressCountry===countryDefault && (formData.correspondenceAddressProvince.length === 0 || handleError('correspondenceAddressProvince')!==null)) ||
      formData.correspondenceAddressLocality.length === 0 || handleError('correspondenceAddressLocality')!==null ||
      formData.correspondenceAddressVia.length === 0 || handleError('correspondenceAddressVia')!==null ||
      formData.correspondenceAddressNameVia.length === 0 || handleError('correspondenceAddressNameVia')!==null ||
      formData.correspondenceAddressNumber.length === 0 || handleError('correspondenceAddressNumber')!==null
      ) || (handleError('bicA')!= null || handleError('bicB')!= null)
      );
  }

return (<>{prop.loading ?
            <Box paddingY={screenSize.isDesktopOrBigger ? 32 : 16} className='contractData--content'>
                <Stack space={32}>
                    <Box>
                        {screenSize.isDesktopOrBigger &&
                            <Box paddingBottom={16}>
                                <SkeletonLine></SkeletonLine>
                            </Box>
                        }
                    </Box>

                    <GridLayout verticalSpace={16} template='6+6'
                        left={
                            <SkeletonRectangle height={252} />
                        }
                        right={
                            <SkeletonRectangle height={252} />
                        }></GridLayout>
                </Stack>
            </Box>
        :
        <>
            <LoadingBar  visible={loading || loadingForm || loadingSave} />
            <style>{
                '.contractData--content .html-content .color--textSecondary{color:'+skinVars.colors.textSecondary+';}'
            }</style>
            <Box paddingY={screenSize.isDesktopOrBigger ? 32 : 16} className='contractData--content'>
                <Stack space={32}>
                    <Box>
                        {screenSize.isDesktopOrBigger &&
                            <Box paddingBottom={16}>
                                <Text4 medium>{intl.formatMessage({id:'page_contract_data_contract_data_title'})}</Text4>
                            </Box>
                        }

                        {/* Contract holder */}
                        <Box paddingBottom={32}>
                            {!screenSize.isDesktopOrBigger &&
                                <Box paddingBottom={16}>
                                    <Text1 regular>{intl.formatMessage({id:'page_contract_data_contract_data_description'})}</Text1>
                                </Box>
                            }

                            {contractHolder &&

                                <>
                                    <Box paddingBottom={16}>
                                        <Text4 medium>{intl.formatMessage({id:'page_contract_data_contract_data_titular'})}</Text4>
                                    </Box>

                                    <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                        <Stack space={16}>
                                            <TextField disabled fullWidth name="" label={intl.formatMessage({id:'page_contract_data_contract_data_nif'})} value={contractHolder.NIF} />

                                            <TextField disabled fullWidth name="" label={intl.formatMessage({id:'page_contract_data_contract_data_name_surname'})} value={contractHolder.nombre+' '+contractHolder.apellidos} />

                                            <TextField disabled fullWidth name="" label={intl.formatMessage({id:'page_contract_data_contract_data_titular_type'})} value={contractHolder.tipoTitular?.nombre} />

                                            {contract?.plan?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS &&
                                                <TextField fullWidth disabled name="" label={intl.formatMessage({id:'page_contract_data_contract_data_regimen'})} value={contract?.tipoContrato}/>
                                            }

                                            <Switch disabled name="" checked={contract?.cliente?.puedeOperar ?? false}  render={({controlElement}) => (
                                                <Inline alignItems="flex-start" space={16}>
                                                    <Text2 medium >
                                                        {intl.formatMessage({id:'page_contract_data_contract_data_you_can_trade'})}
                                                    </Text2>
                                                    {controlElement}
                                                </Inline>
                                            )}></Switch>
                                        </Stack>


                                        <>
                                            {screenSize.isDesktopOrBigger &&
                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_contract_data_annotation'})}</Text1>
                                            }
                                        </>
                                    </Grid>
                                </>
                            }
                        </Box>

                        {/* Investment funds: Contract holders */}
                        {contract?.plan?.cdCompania?.toString()!==process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES && contractHolders && contractHolders.length>0 &&
                            <Box paddingBottom={32}>
                                <Box paddingBottom={16}>
                                    <Text4 medium>{intl.formatMessage({id:'page_contract_data_contract_data_titulares'})}</Text4>
                                </Box>

                                <Grid columns={size.width>567 ? 2 : 1} gap={24}>
                                    {contractHolders.map((item, index) => (
                                        <Stack key={'contract-holders-'+index} space={16}>
                                            <Text2 medium>{intl.formatMessage({id:'page_contract_data_contract_data_titular'})} {index+1}</Text2>

                                            <TextField fullWidth disabled name="" label={intl.formatMessage({id:'page_contract_data_contract_data_nif'})}  value={item.NIF} />

                                            <TextField fullWidth disabled name="" label={intl.formatMessage({id:'page_contract_data_contract_data_name_surname'})} value={item.nombre+' '+item.apellidos} />

                                            {item.tipoTitular && item.tipoTitular.nombre &&
                                                <TextField fullWidth disabled name="" label={intl.formatMessage({id:'page_contract_data_contract_data_titular_type'})} value={item.tipoTitular?.nombre}/>
                                            }
                                        </Stack>
                                    ))}
                                </Grid>
                            </Box>
                        }

                        {/* Pension plans: Beneficiaries */}
                        {contract?.plan?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES && havePermission(process.env.REACT_APP_MENU_PLANES_BENEFICIARIOS, contract?.menuApp) &&
                            <Box paddingBottom={32}>
                                <Box paddingBottom={16}>
                                    <Text4 medium>{intl.formatMessage({id:'page_contract_data_contract_data_beneficiaries'})}</Text4>
                                </Box>

                                <Box>
                                    <Stack space={8}>
                                        <Text2 regular>{intl.formatMessage({id:'page_contract_data_contract_data_beneficiaries_description'})}</Text2>
                                        <div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_contract_data_contract_data_beneficiaries_description2'})}}></div>

                                    </Stack>
                                </Box>
                            </Box>
                        }

                        {/* Pension plans: Prestaciones */}
                        {contract?.plan?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES && prestacion?.importe && prestacion?.importe > 0 &&
                            <Box paddingBottom={32}>
                                <Stack space={16}>
                                    <Box paddingBottom={16}>
                                        <Text4 medium>{intl.formatMessage({id:'page_contract_data_contract_data_benefits_title'})}</Text4>
                                    </Box>
                                    <Stack space={16}>
                                        <GridLayout verticalSpace={16} template='8+4'
                                            left={
                                                <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                        <TextField disabled fullWidth value={prestacion?.fechaNotifCtg ? (moment(prestacion?.fechaNotifCtg?.toString()).format("DD/MM/YYYY").toString() ?? '') : '_'} label={intl.formatMessage({id:'page_contract_data_contract_data_benefits_notification'})} name='notificacion'></TextField>
                                                    }
                                                    right={
                                                        <TextField disabled fullWidth value={prestacion?.prestFechaAlta ? (moment(prestacion?.prestFechaAlta?.toString()).format("DD/MM/YYYY").toString() ?? '') : '_'} label={intl.formatMessage({id:'page_contract_data_contract_data_benefits_high_contract'})} name='altaContrato'></TextField>
                                                    }></GridLayout>
                                            }
                                            right={
                                                <></>
                                            }></GridLayout>

                                        <GridLayout verticalSpace={16} template='8+4'
                                            left={
                                                <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                        <TextField disabled fullWidth value={prestacion?.tipoRenta?.toString() ?? '_'} label={intl.formatMessage({id:'page_contract_data_contract_data_benefits_type_rent'})} name="tipoRenta"></TextField>
                                                    }
                                                    right={
                                                        <TextField disabled fullWidth value={prestacion?.claseRenta?.toString() ?? '_'} label={intl.formatMessage({id:'page_contract_data_contract_data_benefits_class_rent'})} name="claseRenta"></TextField>
                                                    }></GridLayout>
                                            }
                                            right={
                                                <TextField disabled fullWidth value={prestacion?.periodicidad?.toString() ?? '_'} label={intl.formatMessage({id:'page_contract_data_contract_data_benefits_periodicity'})} name="periodicidad"></TextField>
                                            }></GridLayout>

                                        <GridLayout verticalSpace={16} template='8+4'
                                            left={
                                                <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                        <TextField disabled fullWidth value={prestacion?.percepcionPrestacion?.toString() ?? '_'} label={intl.formatMessage({id:'page_contract_data_contract_data_benefits_perception'})} name="percepcion"></TextField>
                                                    }
                                                    right={
                                                        <TextField disabled fullWidth value={prestacion?.cobro?.toString() ?? '_'} label={intl.formatMessage({id:'page_contract_data_contract_data_benefits_payment'})} name="cobro"></TextField>
                                                    }></GridLayout>
                                            }
                                            right={
                                                <TextField disabled fullWidth value={formatBalance(prestacion?.importe?.toString()) ?? '_'} label={intl.formatMessage({id:'page_contract_data_contract_data_benefits_import'})} name="importe"></TextField>
                                            }></GridLayout>

                                            {prestacion?.prestRevalorizacion === '1' ? (
                                                <TextField disabled value='IPC' label={intl.formatMessage({id:'page_contract_data_contract_data_benefits_type_reval'})} name="tipoReval"></TextField>
                                            ) : (
                                                prestacion?.prestRevalorizacion === '2' ? (
                                                    <TextField disabled value={formatBalance(prestacion?.porcRevalorizacion ? prestacion?.porcRevalorizacion : 0)+'%'} label={intl.formatMessage({id:'page_contract_data_contract_data_benefits_type_reval'})} name="tipoReval"></TextField>
                                                ) : (
                                                    <TextField disabled value='' label={intl.formatMessage({id:'page_contract_data_contract_data_benefits_type_reval'})} name="tipoReval"></TextField>
                                                )
                                            )}

                                    </Stack>
                                </Stack>
                            </Box>
                        }


                        {/* Postal address */}
                        {formData &&
                            <Box paddingBottom={32}>
                                <Box paddingBottom={16}>
                                    <Text4 medium>{intl.formatMessage({id:'page_contract_data_contract_data_postal_address'})}</Text4>
                                </Box>

                                <Form onSubmit={handleSubmit} initialValues={formData} className={loadingForm || loadingSave ? 'loading' : ''}>
                                    <Stack space={16}>
                                        <Checkbox onChange={(val:any)=> handleChangeAnyField('taxAddressEqual', val)} name="taxAddressEqual" render={({controlElement, labelId}:any) => (
                                            <Inline alignItems="center" space={16}>
                                            {controlElement}
                                                <Text1 regular>{intl.formatMessage({id:'page_contract_data_contract_data_agrees_tax'})}</Text1>
                                            </Inline>
                                        )}></Checkbox>

                                        {!formData.taxAddressEqual &&
                                            <Stack space={16}>

                                                <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                        <Select fullWidth label={intl.formatMessage({id:'page_contract_data_contract_data_postal_addres_country'})} name="correspondenceAddressCountry" onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressCountry', val)} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                                    }
                                                    right={
                                                        <TextField fullWidth name="correspondenceAddressZipcode" error={handleError('correspondenceAddressZipcode')!==null} helperText={handleError('correspondenceAddressZipcode')??undefined} label={intl.formatMessage({id:'page_contract_data_contract_data_postal_addres_zip_code'})} maxLength={formData.correspondenceAddressCountry === process.env.REACT_APP_ID_PAIS_ESPANA ? 5 : 10} onChange={handleChangeField} onBlur={handleZipcode} />
                                                    }></GridLayout>

                                                <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                        <Select fullWidth disabled={disabledCorrespondenceAddressProvince} value={formData.correspondenceAddressProvince} label={intl.formatMessage({id:'page_contract_data_contract_data_postal_addres_province'})} name='correspondenceAddressProvince' error={handleError('correspondenceAddressProvince')!==null} helperText={handleError('correspondenceAddressProvince')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressProvince', val)} options={provinceOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                                    }
                                                    right={
                                                        <TextField fullWidth value={formData.correspondenceAddressLocality} label={intl.formatMessage({id:'page_contract_data_contract_data_postal_addres_population'})} name='correspondenceAddressLocality' error={handleError('correspondenceAddressLocality')!==null} helperText={handleError('correspondenceAddressLocality')??undefined} onChange={handleChangeField} maxLength={70}></TextField>
                                                    }></GridLayout>

                                                <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                        <Select fullWidth label={intl.formatMessage({id:'page_contract_data_contract_data_postal_addres_via'})} name="correspondenceAddressVia" error={handleError('correspondenceAddressVia')!==null} helperText={handleError('correspondenceAddressVia')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressVia', val)} options={viaOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                                    }
                                                    right={
                                                        <TextField fullWidth name="correspondenceAddressNameVia" error={handleError('correspondenceAddressNameVia')!==null} helperText={handleError('correspondenceAddressNameVia')??undefined}  label={intl.formatMessage({id:'page_contract_data_contract_data_postal_addres_name_via'})} onChange={handleChangeField} />
                                                    }></GridLayout>

                                                <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                        <GridLayout verticalSpace={16} template='8+4'
															left={
																<IntegerField fullWidth label={intl.formatMessage({id:'page_contract_data_contract_data_postal_addres_number'})} name="correspondenceAddressNumber" error={handleError('correspondenceAddressNumber')!==null} helperText={handleError('correspondenceAddressNumber')??undefined}  maxLength={5} onChange={handleChangeField}></IntegerField>
															} 
															right={
															<Tooltip
																targetLabel="help text"
																target={<IconInformationUserRegular size={18} color={skinVars.colors.brand}/>}
																position="top"
																description={intl.formatMessage({id:'page_contract_data_contract_data_postal_addres_number'})}
															/>
														}/>
                                                        
                                                    }
                                                    right={
                                                        <TextField optional fullWidth name="correspondenceAddressFloor" error={handleError('correspondenceAddressFloor')!==null} helperText={handleError('correspondenceAddressFloor')??undefined}  label={intl.formatMessage({id:'page_contract_data_contract_data_postal_addres_floor'})} onChange={handleChangeField} maxLength={10}  />
                                                    }></GridLayout>
                                            </Stack>

                                        }


                                        {/* Associated accounts */}
                                        {contract?.plan?.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS &&
                                            <Box paddingTop={16}>
                                                <Box paddingBottom={16}>
                                                    <Text4 medium>{intl.formatMessage({id:'page_contract_data_contract_data_associated_accounts'})}</Text4>
                                                </Box>

                                                <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                                    <IbanField optional fullWidth name="bicA" error={handleError('bicA')!==null} helperText={handleError('bicA')??undefined}  label={intl.formatMessage({id:'page_contract_data_contract_data_associated_accounts_bica'})} onChange={handleChangeField}/>
                                                    <IbanField optional fullWidth name="bicB" error={handleError('bicB')!==null} helperText={handleError('bicB')??undefined}  label={intl.formatMessage({id:'page_contract_data_contract_data_associated_accounts_bicb'})} onChange={handleChangeField}/>
                                                </Grid>
                                            </Box>
                                        }

                                        <Box paddingTop={16}>
                                            <ButtonLayout align='left'>
                                                <ButtonPrimary disabled={isSubmitDisabled()} showSpinner={loadingSave} submit>{intl.formatMessage({id:'page_contract_data_contract_data_save'})}</ButtonPrimary>
                                            </ButtonLayout>
                                        </Box>
                                    </Stack>

                                </Form>
                            </Box>
                        }
                    </Box>

                </Stack>
            </Box>
        </>
        }</>
    );
};

export default ContractData;
