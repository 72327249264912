import React, { useEffect, useState } from 'react';

import { Box, Boxed, ButtonLayout, ButtonLink, ButtonSecondary, DateField, DoubleField, Form, GridLayout, IconInformationUserRegular, Inline, LoadingBar, SkeletonRectangle, skinVars, Text2, Text5, Tooltip, useScreenSize } from '@telefonica/mistica';

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS, Filler, Legend, LinearScale, LineController,
  LineElement, PointElement,
  Tick,
  Tooltip as TooltipChart
} from 'chart.js';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { NetAssetValue } from '../../entities/products/net-asset-value';
import { Product } from '../../entities/products/product';
import { exportNetAssetValue, getNetAssetValue } from '../../utils/apiclient';
import { isNativeApp } from '../../utils/utils';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    LineController,
    BarController,
    TooltipChart,
    Filler
  );

interface NetAssetValueEvolutionProps {
  product: Product | null,
  loading?: boolean,
  withoutBoxed?: boolean
}

const NetAssetValueEvolution: React.FC<NetAssetValueEvolutionProps> = (prop) => {
    const intl = useIntl()

    const screenSize = useScreenSize();
    const [dateFrom, setDateFrom]= useState(moment().format("YYYY-MM-DD").toString());
    const [dateTo, setDateTo]= useState(moment().format("YYYY-MM-DD").toString());
    const [labelsGraphic, setLabelsGraphic]= useState([] as string[]);
    const [datasGraphic, setDatasGraphic]= useState([] as number[]);
    const [loading, setLoading]= useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);

    useEffect(()=>{
      if(prop.product && prop.product.graficaValorLiquidativo && prop.product?.graficaValorLiquidativo.length>0){
        setDateFrom(moment(prop.product.graficaValorLiquidativo[0]?.fechaValor ?? '').format("YYYY-MM-DD").toString());
        setDateTo(moment(prop.product?.graficaValorLiquidativo[prop.product?.graficaValorLiquidativo.length-1]?.fechaValor ?? '').format("YYYY-MM-DD").toString());
      }

      updateNetAssetValue(prop.product?.graficaValorLiquidativo ?? null);
    },[prop.product]);



    const handleSubmit  = (data: any): Promise<void> =>
        new Promise((resolve) => {
            setLoading(true);
            if(prop.product && prop.product.cdCompania && prop.product.cdFondo && prop.product.cdPlan && prop.product.cdSubplan){
                getNetAssetValue({cdCompany:prop.product.cdCompania, cdFund:prop.product.cdFondo, cdPlan:prop.product.cdPlan, cdSubplan:prop.product.cdSubplan, dateFrom: data.dateFrom, dateTo: data.dateTo }).then((result)=>{
                  updateNetAssetValue(result);
                }).finally(()=>{
                    setLoading(false);
                    resolve();
                });
            }
        });

    const handleExport  = (): Promise<void> =>
        new Promise((resolve) => {
            setLoadingDownload(true);
            if(prop.product && prop.product.cdCompania && prop.product.cdFondo && prop.product.cdPlan && prop.product.cdSubplan){
                exportNetAssetValue({cdCompany:prop.product.cdCompania, cdFund:prop.product.cdFondo, cdPlan:prop.product.cdPlan, cdSubplan:prop.product.cdSubplan, dateFrom: dateFrom??'', dateTo: dateTo??'', title:'Valor liquidativo'}).then(()=>{}).finally(()=>{
                    setLoadingDownload(false);
                    resolve();
                });
            }
        });


    const monthsNames = [intl.formatMessage({id:'months_short_01'}),
                        intl.formatMessage({id:'months_short_02'}),
                        intl.formatMessage({id:'months_short_03'}),
                        intl.formatMessage({id:'months_short_04'}),
                        intl.formatMessage({id:'months_short_05'}),
                        intl.formatMessage({id:'months_short_06'}),
                        intl.formatMessage({id:'months_short_07'}),
                        intl.formatMessage({id:'months_short_08'}),
                        intl.formatMessage({id:'months_short_09'}),
                        intl.formatMessage({id:'months_short_10'}),
                        intl.formatMessage({id:'months_short_11'}),
                        intl.formatMessage({id:'months_short_12'})]

    const updateNetAssetValue = (netAssetValue : Array<NetAssetValue> | null) =>{
      let labels:string[] = [];
      let datas:number[] = [];
      if(netAssetValue){
        let count=1;

        netAssetValue.forEach(item=>{
          const fv = moment(item.fechaValor);
          labels.push(fv.format("DD/MM/YYYY"));
          if(item.valor && item.valor.length>0){
            datas.push(parseFloat(item.valor));
          }
          count++;
        });
      }

      setLabelsGraphic(labels);
      setDatasGraphic(datas);
    }

    const data = {
        labels:labelsGraphic,
        datasets: [
          {
            type: 'line' as const,
            label: '',
            borderColor:'#0066FF',
            borderWidth: 2,
            fill: false,
            backgroundColor:'#0066FF',
            data: datasGraphic,
          }
        ],
      };

    const options = {

        responsive: true,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          x: {
            border: {
              display: true
            },
            grid: {
              display: false
            },
            ticks: {
              autoSkip: datasGraphic.length<=31,
              maxRotation: 0,
              minRotation: 0,
              callback: function(tickValue: string | number, index: number, ticks: Tick[]) {
                let label = '';
                let fvFirst = index===0 ? moment(labelsGraphic[0], "DD/MM/YYYY") : null;
                let fvLast = index===labelsGraphic.length-1 ? moment(labelsGraphic[labelsGraphic.length-1], "DD/MM/YYYY") : null;
                let fv = moment(labelsGraphic[index], "DD/MM/YYYY");
                if(labelsGraphic.length>365){
                  if(fv.format('DD/MM')==='01/07' || (fvFirst && fvFirst>moment('01/07/'+fv.format('YYYY'), "DD/MM/YYYY") && fvFirst<moment('01/01/'+fv.format('YYYY'), "DD/MM/YYYY")) || (fvLast && fvLast<moment('01/07/'+fv.format('YYYY'), "DD/MM/YYYY"))){
                    label = fv.format("YYYY");
                  }
                }else if(labelsGraphic.length>31){
                    if(fv.format('DD')==='15' || (fvFirst && fvFirst>moment('15/'+fv.format('MM')+'/'+fv.format('YYYY'), "DD/MM/YYYY")) || (fvLast && fvLast<moment('01/'+fv.format('MM')+'/'+fv.format('YYYY'), "DD/MM/YYYY"))){
                      label = monthsNames[fv.month()];
                    }
                }else{
                  label = fv.format('DD');
                }
                return label;
              }
            }
          },
          y: {
            border: {
              display: true
            },
            grid: {
              display: false
            },
            ticks: {
              format: { maximumFractionDigits: 8, minimumFractionDigits:2 }
            }
          }
        },
      };

    const getNetAssetValueForm= () =>{
      return (<Form onSubmit={handleSubmit}>
        <Box paddingX={prop.withoutBoxed ? 0 : (screenSize.isDesktopOrBigger ? 64 : 12)} paddingTop={prop.withoutBoxed ? 0 : (screenSize.isDesktopOrBigger ? 48 : 16)} paddingBottom={screenSize.isDesktopOrBigger ? 32 : 16}>
          {!prop.withoutBoxed &&
            <Box paddingBottom={48}>
              <Inline space={8} alignItems='center'>
                <Text5>{intl.formatMessage({id:'page_investment_funds_customer_general_asset_value_evolution_information_title'})}</Text5>
              </Inline>
            </Box>
          }

          <Line options={options} data={data} />

          <GridLayout
            template='6+6'
            left={
              <Box paddingTop={32} paddingBottom={24}>
                <DoubleField fullWidth layout='50/50'>
                  <DateField
                    fullWidth
                    name="dateFrom"
                    value={dateFrom}
                    max={prop.product && prop.product?.rentabilidad && prop.product?.rentabilidad?.fechaValorLiquidativo ? moment(prop.product?.rentabilidad?.fechaValorLiquidativo).toDate() : undefined}
                    label={intl.formatMessage({id:'page_customer_online_correspondence_field_from'})}
                    onChangeValue={(value) => {setDateFrom(value)}}
                  />

                  <DateField
                    fullWidth
                    name="dateTo"
                    value={dateTo}
                    max={prop.product && prop.product?.rentabilidad && prop.product?.rentabilidad?.fechaValorLiquidativo ? moment(prop.product?.rentabilidad?.fechaValorLiquidativo).toDate() : undefined}
                    label={intl.formatMessage({id:'page_customer_online_correspondence_field_to'})}
                    onChangeValue={(value) => {setDateTo(value)}}
                  />
                </DoubleField>
              </Box>
            }
            right={
              screenSize.isDesktopOrBigger ?
                <Box paddingTop={40}>
                  <ButtonLayout align={screenSize.isDesktopOrBigger ? 'full-width' : 'center'}>
                    <><ButtonSecondary submit >{intl.formatMessage({id:'page_investment_funds_customer_general_asset_value_evolution_consult'})}</ButtonSecondary></>
                    {!isNativeApp &&
                      <>
                        <ButtonLink showSpinner={loadingDownload} onPress={()=>handleExport()}>{intl.formatMessage({id:'page_investment_funds_customer_general_asset_value_evolution_export'})}</ButtonLink>
                      </>
                    }
                  </ButtonLayout>
                </Box>
              :
                <></>
            }
          />

          {!screenSize.isDesktopOrBigger &&
            <ButtonLayout align={screenSize.isDesktopOrBigger ? 'full-width' : 'center'}>
              <>
                <ButtonSecondary submit >{intl.formatMessage({id:'page_investment_funds_customer_general_asset_value_evolution_consult'})}</ButtonSecondary>
              </>

              {!isNativeApp &&
                <>
                  <ButtonLink showSpinner={loadingDownload} onPress={()=>handleExport()}>{intl.formatMessage({id:'page_investment_funds_customer_general_asset_value_evolution_export'})}</ButtonLink>
                </>
              }
            </ButtonLayout>
          }
        </Box>
        </Form>)
    }

    return (<>{prop.loading ?
                  <Box paddingY={12}>
                      <SkeletonRectangle height={704} />
                  </Box>
              :
                prop.product ?
                <>
                  <LoadingBar visible={loading || loadingDownload} />
                  {prop.withoutBoxed ?
                    getNetAssetValueForm()
                  :
                    <Box className='lineGraph--content' paddingY={16}>
                      <Boxed>
                        {getNetAssetValueForm()}
                      </Boxed>
                    </Box>
                  }
                </>
              :
                <></>
            }</>);
};

export default NetAssetValueEvolution;
