import { Box, ButtonLink, DataCard, dialog, Divider, Grid, IconArrowLineRightRegular, IconArrowsLeftAndRightRegular, IconCheckedLight, IconDollarSymbolCircleRegular, IconFileErrorRegular, IconThrowLightRegular, LoadingBar, ResponsiveLayout, skinVars, Stack, Stepper, Text4, Text5, useScreenSize, useWindowSize } from "@telefonica/mistica";
import { useIntl } from "react-intl";
import LayoutPrivateArea from "../../../../../components/layout/LayoutPrivateArea";

import { useIonRouter } from "@ionic/react";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDigitalSign } from "../../../../../components/digital-sign/DigitalSign";
import ConfirmOperation from "../../../../../components/operate/ConfirmOperation";
import ConfirmPersonalData from "../../../../../components/operate/ConfirmPersonalData";
import ContractHoldersInvestmentFunds from "../../../../../components/operate/ContractHoldersInvestmentFunds";
import SubscriptionInvestmentFunds from "../../../../../components/operate/SubscriptionInvestmentFunds";
import SummaryOperate from "../../../../../components/operate/SummaryOperate";
import { useValidOperate } from "../../../../../components/operate/ValidOperate";
import { Contract } from "../../../../../entities/contract/contract";
import { OperationContract } from "../../../../../entities/contract/operations/operation-contract";
import { OperationContractFund } from "../../../../../entities/contract/operations/operation-contract-fund";
import { OperationContractHolders } from '../../../../../entities/contract/operations/operation-contract-holders';
import { OperationSubscription } from "../../../../../entities/contract/operations/operation-subscription";
import { OperationTransfer } from "../../../../../entities/contract/operations/operation-transfer";
import { addContractFund, trackStadistics } from "../../../../../utils/apiclient";
import { getUrlNavigation } from '../../../../../utils/navigation';
import { addNumberCourseOperationsCache, getContinueToContract, getCurrentProductToContract, getCurrentUserContract, getCurrentUserData, getOperationContract, getOperationContractHolders, getOperationKey, getOperationSubscription, getOperationTransfer, getTokenSign, removeContinueToContract, removeInvestmentFundsNotContractedCache, removeOperationKey, removeOperationSubscription, removeOperationTransfer, removeTokenSign } from "../../../../../utils/storage";
import { useToast } from "../../../../../utils/toast";
import { formatProduct, scrollToTop, trackEvent } from '../../../../../utils/utils';
import OperationsInProgressCustomerPage from "../operations-in-progress/OperationsInProgressCustomerPage";
import InvestmentFundsCustomerPage from "./InvestmentFundsCustomerPage";
import slugify from "react-slugify";


const ContractInvestmentFundsCustomerPage: React.FC = () => {


  const { showToast } = useToast()
  const intl = useIntl();
  const screenSize = useScreenSize();
  const size = useWindowSize();
  const currentProductToContract = getCurrentProductToContract();
  const currentUserContract =  getCurrentUserContract();
  const currentUser = getCurrentUserData();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingOperation, setLoadingOperation] = useState(null as null|string);
  const router = useIonRouter();
  const [title, setTitle] = useState('');
  const [operations, setOperations] = useState([] as number[]);
  const [currentOperation, setCurrentOperation] = useState(null as null|number);
  const [contract, setContract] = useState(null as null|Contract);
  const [operationSubscription, setOperationSubscription] = useState(null as null|OperationSubscription);
  const [operationTransfer, setOperationTransfer] = useState(null as null|OperationTransfer);
  const [operationContract, setOperationContract] = useState(null as null|OperationContract);
  const [operationContractHolders, setOperationContractHolders] = useState(null as null|OperationContractHolders);


  const [parentTitle, setParentTitle] = useState('');
  const parentPage = InvestmentFundsCustomerPage;


  //Stepper
  const [step, setStep] = useState(0);
  const [options, setOptions] = useState([] as string[]);

  const { validOperate } = useValidOperate();
  const { digitalSign } = useDigitalSign();
  const subscriptionId = parseInt(process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION ?? '19');
  const transferId = parseInt(process.env.REACT_APP_MENU_FONDOS_TRASLADO ?? '20');


    useLayoutEffect(()=>{
        setParentTitle(intl.formatMessage({id:'page_investment_funds_customer_metatitle'}));

        let opts = [];
        opts.push(intl.formatMessage({id:'page_contract_investment_funds_step_your_data'}));
        opts.push(intl.formatMessage({id:'page_contract_investment_funds_step_operative'}));
        opts.push(intl.formatMessage({id:'page_contract_investment_funds_step_operations_key'}));
        setOptions(opts);
      }, []);


    useEffect(()=>{
        if(!router.canGoBack() || !currentProductToContract || !currentUserContract || !currentUser){
            router.push(getUrlNavigation(parentPage));
            return;
        }
        setContract(currentUserContract);
    }, []);



    const confirmData = ()=>{
        scrollToTop();

        setOperationContract(getOperationContract());

        setStep(step+1)
    }

    const handleOperation = (operation:string): Promise<void> =>
        new Promise((resolve) => {
            //resolve(); return;
            setLoadingOperation(operation);

            switch(operation){
                case process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION:
                    trackEvent('perfil-fondo-'+formatProduct(currentProductToContract)+'-contratar-suscripcion');
                    setOperations([subscriptionId])
                    setCurrentOperation(subscriptionId);
                    break;
                case process.env.REACT_APP_MENU_FONDOS_TRASLADO:
                    trackEvent('perfil-fondo-'+formatProduct(currentProductToContract)+'-contratar-traslado');
                    setOperations([transferId])
                    setCurrentOperation(transferId);
                    break;
                case (process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION+'_'+process.env.REACT_APP_MENU_FONDOS_TRASLADO):
                    trackEvent('perfil-fondo-'+formatProduct(currentProductToContract)+'-contratar-suscripcion_traslado');
                    setOperations([transferId, subscriptionId])
                    setCurrentOperation(transferId);
                    break;
            }
            setLoadingOperation(null);
            resolve();
        });

    const handleCancelOperation= (operation:string): Promise<void> =>
        new Promise((resolve) => {
            setLoadingOperation(operation);

            switch(operation){
                case process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION:
                    removeOperationSubscription();

                    if(operations.length>1){
                        setCurrentOperation(subscriptionId);
                    }else{
                        setOperations([])
                        setCurrentOperation(null);
                    }
                    break;
                case process.env.REACT_APP_MENU_FONDOS_TRASLADO:
                    removeOperationTransfer();

                    setOperations([])
                    setCurrentOperation(null);
                    break;
            }
            setLoadingOperation(null);
            resolve();
        });

    const summaryOperation = (operation:string)=>{
        scrollToTop();
        setOperationContractHolders(getOperationContractHolders());
        switch(operation){

            case process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION:
                setOperationSubscription(getOperationSubscription());
                setStep(step+1)
                break;
            case process.env.REACT_APP_MENU_FONDOS_TRASLADO:
                setOperationTransfer(getOperationTransfer());
                if(operations.length>1){
                    setCurrentOperation(subscriptionId);
                }else{
                    setStep(step+1)
                }
                break;
        }
    }

    const confirmOperationDigitalSign= (): Promise<void> =>
        new Promise((resolve) => {
            setLoadingSave(true);

            const dateSign = moment().unix();

            let contractFund : OperationContractFund={
                ...operationContract,
            };

            if(operationContractHolders){
                contractFund={
                    ...contractFund,
                    ...operationContractHolders
                };

            }

            if(operationSubscription){
                contractFund={
                    ...contractFund,
                    ...operationSubscription,
                    amountSubscription : operationSubscription.amount
                };
            }

            if(operationTransfer){
                contractFund={
                    ...contractFund,
                    ...operationTransfer,
                    amountTransfer : operationTransfer.amount,
                };
            }
            contractFund={
                ...contractFund,
                dateSign:dateSign,
                electronic:true,
                confirm:true
            };
            addContractFund(contractFund).then((result)=>{

                if(result.tokenOperation){
                    digitalSign(result.tokenOperation
                    , ()=>{
                            confirmOperation(result.tokenOperation, dateSign);
                            resolve();
                    }, ()=>{
                        setLoadingSave(false);
                        resolve();
                    });
                }

            }).finally(()=>{
            })
        });


    const confirmOperation = (tokenOperation:string|null=null, dateSign:number|null=null)=>{
        setLoadingSave(true);
        const operationsKey = getOperationKey();
        const tokenSign = getTokenSign();
        const continueToContract = getContinueToContract();

        if(operationsKey || tokenSign || continueToContract){

            let contractFund : OperationContractFund={
                ...operationContract,
            };

            if(operationContractHolders){
                contractFund={
                    ...contractFund,
                    ...operationContractHolders
                };

            }

            if(operationSubscription){
                contractFund={
                    ...contractFund,
                    ...operationSubscription,
                    amountSubscription : operationSubscription.amount
                };
            }

            if(operationTransfer){
                contractFund={
                    ...contractFund,
                    ...operationTransfer,
                    amountTransfer : operationTransfer.amount,
                };
            }

            contractFund={
                ...contractFund,
                operationsKey:operationsKey ? operationsKey : undefined,
                dateSign: dateSign ? dateSign : undefined,
                tokenSign: tokenSign ? tokenSign : undefined,
                tokenOperation: tokenOperation ? tokenOperation : undefined,
                confirm:true,
                electronic: tokenOperation && tokenSign ? true : false,
                continueToContract: continueToContract ? true : undefined
            };


            addContractFund(contractFund).then((result)=>{
                if(result.result){
                    addNumberCourseOperationsCache();
                    removeOperationSubscription();
                    removeOperationKey();
                    removeTokenSign();
                    removeInvestmentFundsNotContractedCache();
                    removeContinueToContract();
                    trackStadistics('CONFIRMED_OPERATION', undefined, undefined, 'Fondos de inversión - Contratación - Solicitud de contratación procesada con éxito.', 'OPERACION_CONFIRMADA' );
                    dialog({
                        icon:<IconCheckedLight color={skinVars.colors.brand} />,
                        title: intl.formatMessage({id:'page_contract_investment_funds_dialog_add_contract_fund_title_ok'}),
                        acceptText: intl.formatMessage({id:'page_contract_investment_funds_dialog_add_contract_fund_button_ok'}),
                        message: "",
                        onAccept: ()=>{ router.push(getUrlNavigation(OperationsInProgressCustomerPage)); }
                    });
                    return;
                }

                dialog({
                    icon:<IconFileErrorRegular color={skinVars.colors.textLinkDanger} />,
                    title: intl.formatMessage({id:'page_contract_investment_funds_dialog_add_contract_fund_title_ko'}),
                    acceptText: intl.formatMessage({id:'page_contract_investment_funds_dialog_add_contract_fund_button_ko'}),
                    message: ""
                });
                return;
            }).finally(()=>{
                setLoadingSave(false);
            });
        }
    }

    return (
        <>
            <LoadingBar visible={loading} />
            <style>{'.stepper--operations.stepper--fixed{background: '+skinVars.colors.backgroundAlternative+';}'}</style>
            <LayoutPrivateArea
                seo={{metaTitle: title}}
                title={intl.formatMessage({id:'page_contract_investment_funds_title'})}
                titleResponsive={title}
                parents={[{item:parentPage, title: parentTitle}]}
                pageInfo={{option: process.env.REACT_APP_MENU_PLANES_PENSIONES, role:'client'}}
                showBack>
                    <ResponsiveLayout className={'contribution--content '+ (screenSize.isDesktopOrBigger ? 'inner first' : 'fullscreen fixed padding--none')} backgroundColor={!screenSize.isDesktopOrBigger ? skinVars.colors.backgroundAlternative : 'transparent'}>
                        {screenSize.isDesktopOrBigger &&
                            <Box paddingBottom={32}>
                                <Text5>{title}</Text5>
                            </Box>
                        }

                        <Box className={'stepper--operations'  + (!screenSize.isDesktopOrBigger ? ' stepper--fixed' : '')} paddingBottom={screenSize.isDesktopOrBigger ? 32 : 0}>
                            <div className='stepper'>
                                <Stepper
                                    currentIndex={step}
                                    steps={options}
                                />
                            </div>
                            <Divider />
                        </Box>
                    </ResponsiveLayout>

                    {options[step]===intl.formatMessage({id:'page_contract_investment_funds_step_your_data'}) &&
                        <ResponsiveLayout className={'inner' + (!screenSize.isDesktopOrBigger ? ' with-stepper--fixed' : '')}>
                            <Stack space={32}>
                                <Text5 color={skinVars.colors.brand}>
                                    {intl.formatMessage({id:'page_contract_investment_funds_confirm_your_data'})}
                                </Text5>

                                <ConfirmPersonalData inProcessContract={true} loading={loadingSave} contract={contract} client={currentUser ? currentUser : undefined} onCancel={()=>{setStep(step-1)}} onConfirm={()=>{confirmData()}} />
                            </Stack>
                        </ResponsiveLayout>
                    }

                    {options[step]===intl.formatMessage({id:'page_contract_investment_funds_step_operative'}) &&
                        <ResponsiveLayout className={'inner' + (!screenSize.isDesktopOrBigger ? ' with-stepper--fixed' : '')}>
                            {!currentOperation ?
                                <Stack space={32}>
                                    <Text5 color={skinVars.colors.brand}>
                                        {intl.formatMessage({id:'page_contract_investment_funds_operational'})}
                                    </Text5>
                                    <Text4 regular>{intl.formatMessage({id:'page_contract_investment_funds_what_you_want'})}</Text4>


                                    <Grid columns={size.width > 768 ? 3 : 1} gap={16}>
                                            <DataCard
                                                icon={
                                                    <IconDollarSymbolCircleRegular size={32} color={skinVars.colors.brand} />
                                                }
                                                title={intl.formatMessage({id:'page_contract_investment_funds_make_subscription_title'})}
                                                description={intl.formatMessage({id:'page_contract_investment_funds_make_subscription_description'})}
                                                buttonLink={
                                                    <ButtonLink showSpinner={loadingOperation===process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION} onPress={()=>{handleOperation(process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION ?? '19')}}>
                                                        {intl.formatMessage({id:'page_contract_investment_funds_start'})}
                                                        <IconArrowLineRightRegular color="currentColor" />
                                                    </ButtonLink>
                                                }
                                            />

                                            <DataCard
                                                icon={
                                                    <IconThrowLightRegular size={32} color={skinVars.colors.brand} />
                                                }
                                                title={intl.formatMessage({id:'page_contract_investment_funds_make_transfer_title'})}
                                                description={intl.formatMessage({id:'page_contract_investment_funds_make_transfer_description'})}
                                                buttonLink={
                                                    <ButtonLink showSpinner={loadingOperation===process.env.REACT_APP_MENU_FONDOS_TRASLADO} onPress={()=>{handleOperation(process.env.REACT_APP_MENU_FONDOS_TRASLADO ?? '20')}}>
                                                        {intl.formatMessage({id:'page_contract_investment_funds_start'})}
                                                        <IconArrowLineRightRegular color="currentColor" />
                                                    </ButtonLink>
                                                }
                                            />

                                            <DataCard
                                                icon={
                                                    <IconArrowsLeftAndRightRegular size={32} color={skinVars.colors.brand} />
                                                }
                                                title={intl.formatMessage({id:'page_contract_investment_funds_make_subscription_transfer_title'})}
                                                description={intl.formatMessage({id:'page_contract_investment_funds_make_subscription_transfer_description'})}
                                                buttonLink={
                                                    <ButtonLink showSpinner={loadingOperation===(process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION+'_'+process.env.REACT_APP_MENU_FONDOS_TRASLADO)} onPress={()=>{handleOperation((process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION??'19')+'_'+(process.env.REACT_APP_MENU_FONDOS_TRASLADO??'20'))}}>
                                                        {intl.formatMessage({id:'page_contract_investment_funds_start'})}
                                                        <IconArrowLineRightRegular color="currentColor" />
                                                    </ButtonLink>
                                                }
                                            />
                                        </Grid>
                                </Stack>
                            :
                                <>
                                    {currentOperation===subscriptionId &&
                                        (operations.length==1 ?
                                            <ContractHoldersInvestmentFunds loading={loading} subscription={true} client={currentUser ? currentUser : undefined} contract={contract} onCancel={()=>{ handleCancelOperation((process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION??'19')) }} onConfirm={()=>{summaryOperation((process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION??'19'));}} />
                                            :
                                            <SubscriptionInvestmentFunds loading={loading} contract={contract} onCancel={()=>{ handleCancelOperation((process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION??'19')) }} onConfirm={()=>{summaryOperation((process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION??'19'));}} />
                                        )
                                    }

                                    {currentOperation===transferId &&
                                        <ContractHoldersInvestmentFunds loading={loading} transfer={true} client={currentUser ? currentUser : undefined} contract={contract} onCancel={()=>{ handleCancelOperation((process.env.REACT_APP_MENU_FONDOS_TRASLADO??'20')) }} onConfirm={()=>{summaryOperation((process.env.REACT_APP_MENU_FONDOS_TRASLADO??'20'));}} />
                                    }
                                </>
                            }
                        </ResponsiveLayout>
                    }

                    {options[step]===intl.formatMessage({id:'page_contract_investment_funds_step_operations_key'}) &&
                        <ResponsiveLayout className={'inner new-contract-plan--content' + (!screenSize.isDesktopOrBigger ? ' with-stepper--fixed' : '')}>
                            <Stack space={32}>
                                <Text5 color={skinVars.colors.brand}>
                                    {intl.formatMessage({id:'page_contract_investment_funds_open_new_contract'})}
                                </Text5>

                                {operationContractHolders &&
                                    <SummaryOperate key={'data_contract_holders'}
                                        title={intl.formatMessage({id:'page_contract_investment_funds_holders'})}
                                        columnGrid={3}
                                        values={operationContractHolders.summary}
                                    />
                                }


                                {operationSubscription &&
                                    <SummaryOperate key={'data_subscription'}
                                        title={intl.formatMessage({id:'page_contract_investment_funds_subscription'})}
                                        values={operationSubscription.summary}
                                    />
                                }
                                {operationTransfer &&
                                    <SummaryOperate key={'data_transfer'}
                                        title={intl.formatMessage({id:'page_contract_investment_funds_transfer'})}
                                        values={operationTransfer.summary} />
                                }

                                <ConfirmOperation inProcessContract={true} loading={loadingSave} contract={contract} onCancel={()=>{}} onConfirm={()=>{confirmOperation()}} onSign={()=>confirmOperationDigitalSign()} />

                            </Stack>
                        </ResponsiveLayout>
                    }
            </LayoutPrivateArea>
        </>
    );
};

export default ContractInvestmentFundsCustomerPage;