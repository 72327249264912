import { Box, Divider, IconChevronDownLight, IconChevronDownRegular, IconChevronUpLight, IconChevronUpRegular, Inline, Row, Text3, skinVars } from '@telefonica/mistica';
import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import { trackEvent } from '../../utils/utils';
import './Accordion.scss';

interface AccordionProps {
    title:string
    children: any,
    prefixSlug?: string
}

const Accordion: React.FC<AccordionProps> = (prop) => {
    const [expanded, setExpanded] = useState(false);


    useEffect(()=>{
        if(expanded && prop.prefixSlug){
            trackEvent(prop.prefixSlug+'-'+slugify(prop.title).replaceAll('-', '_'));
        }
    }, [expanded]);

    return (
            <>
                <Box className='accordion-item'>
                    <Row title=''
                        right={null}
                        onPress={() => {setExpanded(!expanded)}}
                        extra={
                            <Inline space={'between'} alignItems='center'>
                                <Text3 regular>{prop.title}</Text3>
                                {expanded ? <IconChevronUpRegular size={24} color={skinVars.colors.brand}></IconChevronUpRegular> : <IconChevronDownRegular size={24} color={skinVars.colors.brand}></IconChevronDownRegular>}
                            </Inline>
                        }
                    />

                    <Box className={'accordion-content-item '+(expanded ? 'visible' : '')}>
                        <Box paddingY={24}>
                            {prop.children}
                        </Box>
                    </Box>

                    <Divider/>
                </Box>
            </>
        );
}

export default Accordion;
