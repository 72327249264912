import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { getOperationInProgress, getOperationKey, getReasonDeleteOperationInProgress, getSignOperationInProgress, getTokenSign, removeCurrentUserProduct, removeInvestmentFundsNotContractedCache, removeNumberCourseOperationsCache, removeOperationKey, removePlansNotContractedCache, removeReasonDeleteOperationInProgress, removeSignOperationInProgress, removeTokenSign } from '../../../../../utils/storage';

import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';

import { Box, ButtonDanger, ButtonFixedFooterLayout, ButtonLayout, ButtonPrimary, Inline, ResponsiveLayout, Text5, useScreenSize } from '@telefonica/mistica';

import { useIonRouter } from '@ionic/react';
import OperationInProgressContractInvestmentFund from '../../../../../components/operations-in-progress/OperationInProgressContractInvestmentFund';
import OperationInProgressContractPensionPlan from '../../../../../components/operations-in-progress/OperationInProgressContractPensionPlan';
import OperationInProgressContributionDetail from '../../../../../components/operations-in-progress/OperationInProgressContributionDetail';
import OperationInProgressMobilizationDetail from '../../../../../components/operations-in-progress/OperationInProgressMobilizationDetail';
import OperationInProgressRefundDetail from '../../../../../components/operations-in-progress/OperationInProgressRefundDetail';
import OperationInProgressSubscriptionDetail from '../../../../../components/operations-in-progress/OperationInProgressSubscriptionDetail';
import OperationInProgressTransferDetail from '../../../../../components/operations-in-progress/OperationInProgressTransferDetail';
import { useSignOperationsKey } from '../../../../../components/operations-key/SignOperationsKey';
import { cancelOperations, rejectOperations, signOperations } from '../../../../../utils/apiclient';
import { useToast } from '../../../../../utils/toast';
import './OperationsInProgressCustomerPage.scss';
import { useRejectOperationInProgress } from './RejectOperationInProgress';
import { useSignOperationsInProgress } from './SignOperationInProgress';
import moment from 'moment';
import { useDigitalSign } from '../../../../../components/digital-sign/DigitalSign';
import { getUrlNavigation } from '../../../../../utils/navigation';
import OperationsInProgressCustomerPage from './OperationsInProgressCustomerPage';
import slugify from 'react-slugify';
import { formatProduct, trackEvent } from '../../../../../utils/utils';

const OperationsInProgressDetailCustomerPage: React.FC = () => {
  const intl = useIntl();
  const screenSize = useScreenSize();
  const { showToast } = useToast();
  const { sign } = useSignOperationsKey()
  const { signOperationInProgress } = useSignOperationsInProgress()
  const { rejectOperationInProgress } = useRejectOperationInProgress()
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingSign, setLoadingSign] = useState(false);
  const [loadingOperation, setLoadingOperation] = React.useState(null as string | null);
  let operation = getOperationInProgress('user_operation_in_progress');
  let newContract = getOperationInProgress('user_newcontract_in_progress');


  const [title, setTitle] = React.useState('' as string);
  const router = useIonRouter();
  const { digitalSign } = useDigitalSign();




  useEffect(()=>{
    removeCurrentUserProduct();
    setTitle(intl.formatMessage({id:'page_operations_in_progress_detail_customer_title'}));
    if(operation){
      switch(operation.detalle.tipoOperacion){
        case 'Suscripción':
          setTitle(intl.formatMessage({ id: 'page_operations_in_progress_customer_subscription_detail' }));
          break;
        case 'Aportación':
          setTitle(intl.formatMessage({ id: 'page_operations_in_progress_customer_contribution_detail' }));
          break;
      }
    }
  })

  const deleteOperation= (numSolicitud:string, tokenOperation:string|null=null, dateSign:number|null=null): Promise<void> =>
    new Promise((resolve) => {
        const operationsKey = getOperationKey();
        const tokenSign = getTokenSign();

        if(operationsKey || tokenSign){

          let cancelOperation : any={
            numSolicitud: numSolicitud,
            operationsKey: operationsKey ? operationsKey : undefined,
            tokenSign: tokenSign ? tokenSign : undefined,
            tokenOperation: tokenOperation ? tokenOperation : undefined,
            dateSign: dateSign ? dateSign : undefined,
            electronic: tokenOperation && tokenSign ? true : false
        };

          setLoadingOperation(numSolicitud);
          setLoadingSign(true);
          cancelOperations(cancelOperation).then(result=>{
            if(result && result.result){
              removeOperationKey();
              removeTokenSign();
              removeNumberCourseOperationsCache();
              removePlansNotContractedCache();
              removeInvestmentFundsNotContractedCache();
              showToast({type:'ok', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_description'})});
              if(router.canGoBack()){
                router.goBack();
              }else{
                router.push(getUrlNavigation(OperationsInProgressCustomerPage));
              }
            }else{
              showToast({type:'ko', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_title_ko'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_description_ko'})});
            }
          }).finally(()=>{
            setLoadingSign(false);
            setLoadingOperation(null);
            resolve();
          });
        }
  });



  const confirmDeleteOperation= (numSolicitud:string): void =>{
    setLoadingOperation(numSolicitud);
    setLoadingSign(true);
    sign(intl.formatMessage({id:'page_operations_in_progress_customer_delete_operation_title'}),
      () => {
          deleteOperation(numSolicitud);
      },
      ()=>  {
            setLoadingSign(false);
            setLoadingOperation(null);
      },
      ()=>{
        setLoadingOperation(numSolicitud);
        setLoadingSign(true);

          const dateSign = moment().unix();
          const params: any={
            numSolicitud: numSolicitud,
            electronic: true,
            dateSign: dateSign
          }
          cancelOperations(params).then(result=>{
              if(result.tokenOperation){
                  digitalSign(result.tokenOperation
                  , ()=>{
                    deleteOperation(numSolicitud, result.tokenOperation, dateSign);
                  }, ()=>{
                      setLoadingSign(false);
                      setLoadingOperation(null);
                  });
              }

          }).finally(()=>{
          })
      });
  }

  const signOperation = (numSolicitud: string) =>{
    setLoadingOperation(numSolicitud);
    setLoadingSign(true);
    sign(intl.formatMessage({id:'page_operations_in_progress_customer_sign_operation_title'}),
      () => {
        confirmSignOperation(numSolicitud);
      },
      ()=>  {
          setLoadingSign(false);
          setLoadingOperation(null);
      },
      ()=>{
        setLoadingOperation(numSolicitud);
        setLoadingSign(true);
        const datos = getSignOperationInProgress();
        if(datos){

          const dateSign = moment().unix();
          const params: any={
            numSolicitud: numSolicitud,
            electronic: true,
            dateSign: dateSign,
            ocupacion: datos.employmentSituation,
            actividad: datos.activitySector,
            empleadoGrupoTelefonica: datos.telefonicaGroupEmployee,
            nifEEUU: datos.usTaxIdentificationNumber,
            procedenciaPatrimonio: datos.sourceMoney,
            personPublicResponsibility: datos.personPublicResponsibility,
            personPublicResponsibilityPosition: datos.personPublicResponsibilityPosition,
            personPublicResponsibilityOrganism: datos.personPublicResponsibilityOrganism,
            familyPublicResponsibilityOrganism: datos.familyPublicResponsibilityOrganism,
            relatedPublicResponsibilityOrganism: datos.relatedPublicResponsibilityOrganism,
            ongPositionLast2Years: datos.ongPositionLast2Years
          }
          signOperations(params).then(result=>{
              if(result.tokenOperation){
                  digitalSign(result.tokenOperation
                  , ()=>{
                    confirmSignOperation(numSolicitud, result.tokenOperation, dateSign);
                  }, ()=>{
                      setLoadingSign(false);
                      setLoadingOperation(null);
                  });
              }

          }).finally(()=>{
          })
        }
      });


    setLoadingOperation(numSolicitud);
    setLoadingSign(true);
  }


  const confirmSignOperation = (numSolicitud:string, tokenOperation:string|null=null, dateSign:number|null=null): Promise<void> =>
    new Promise((resolve) => {
      const operationsKey = getOperationKey();
      const tokenSign = getTokenSign();
      const datos = getSignOperationInProgress();

      if((operationsKey || tokenSign) && datos){

        let signOperationParams : any={
          numSolicitud: numSolicitud,
          operationsKey: operationsKey ? operationsKey : undefined,
          tokenSign: tokenSign ? tokenSign : undefined,
          tokenOperation: tokenOperation ? tokenOperation : undefined,
          dateSign: dateSign ? dateSign : undefined,
          electronic: tokenOperation && tokenSign ? true : false,
          ocupacion: datos.employmentSituation,
          actividad: datos.activitySector,
          empleadoGrupoTelefonica: datos.telefonicaGroupEmployee,
          nifEEUU: datos.usTaxIdentificationNumber,
          procedenciaPatrimonio: datos.sourceMoney,
          personPublicResponsibility: datos.personPublicResponsibility,
          personPublicResponsibilityPosition: datos.personPublicResponsibilityPosition,
          personPublicResponsibilityOrganism: datos.personPublicResponsibilityOrganism,
          familyPublicResponsibilityOrganism: datos.familyPublicResponsibilityOrganism,
          relatedPublicResponsibilityOrganism: datos.relatedPublicResponsibilityOrganism,
          ongPositionLast2Years: datos.ongPositionLast2Years
      };

        setLoadingOperation(numSolicitud);
        setLoadingSign(true);
        signOperations(signOperationParams).then(result=>{
          if(result && result.result){
            removeOperationKey();
            removeTokenSign();
            removeNumberCourseOperationsCache();
            removePlansNotContractedCache();
            removeInvestmentFundsNotContractedCache();
            removeSignOperationInProgress();
            showToast({type:'ok', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_description'})});
            if(router.canGoBack()){
              router.goBack();
            }else{
              router.push(getUrlNavigation(OperationsInProgressCustomerPage));
            }
          }else{
            showToast({type:'ko', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title_ko'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_description_ko'})});
          }
        }).finally(()=>{
          setLoadingSign(false);
          setLoadingOperation(null);
          resolve();
        });
      }
  });


  const rejectOperation = (numSolicitud: string) =>{
    setLoadingOperation(numSolicitud);
    setLoadingReject(true);
    sign(intl.formatMessage({id:'page_operations_in_progress_customer_reject_operation_title'}),
      () => {
        confirmRejectOperation(numSolicitud);
      },
      ()=>  {
          setLoadingReject(false);
          setLoadingOperation(null);
      },
      ()=>{
        setLoadingOperation(numSolicitud);
        setLoadingReject(true);
        const datos = getReasonDeleteOperationInProgress();

          const dateSign = moment().unix();
          const params: any={
            numSolicitud: numSolicitud,
            electronic: true,
            dateSign: dateSign,
            motivo: datos ? datos[1] : ''
          }
          rejectOperations(params).then(result=>{
              if(result.tokenOperation){
                  digitalSign(result.tokenOperation
                  , ()=>{
                    confirmRejectOperation(numSolicitud, result.tokenOperation, dateSign);
                  }, ()=>{
                      setLoadingReject(false);
                      setLoadingOperation(null);
                  });
              }

          }).finally(()=>{
          })
      });


    setLoadingOperation(numSolicitud);
    setLoadingReject(true);
  }


  const confirmRejectOperation = (numSolicitud:string, tokenOperation:string|null=null, dateSign:number|null=null): Promise<void> =>
    new Promise((resolve) => {
      const operationsKey = getOperationKey();
      const tokenSign = getTokenSign();
      const datos = getReasonDeleteOperationInProgress();

      if((operationsKey || tokenSign) && datos){

        let rejectOperationParams : any={
          numSolicitud: numSolicitud,
          operationsKey: operationsKey ? operationsKey : undefined,
          tokenSign: tokenSign ? tokenSign : undefined,
          tokenOperation: tokenOperation ? tokenOperation : undefined,
          dateSign: dateSign ? dateSign : undefined,
          electronic: tokenOperation && tokenSign ? true : false,
          motivo: datos[1]
      };

        setLoadingOperation(numSolicitud);
        setLoadingReject(true);
        rejectOperations(rejectOperationParams).then(result=>{
          if(result && result.result){
            removeOperationKey();
            removeTokenSign();
            removeNumberCourseOperationsCache();
            removePlansNotContractedCache();
            removeInvestmentFundsNotContractedCache();
            removeReasonDeleteOperationInProgress();
            showToast({type:'ok', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_reject_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_description'})});
            if(router.canGoBack()){
              router.goBack();
            }else{
              router.push(getUrlNavigation(OperationsInProgressCustomerPage));
            }
          }else{
            showToast({type:'ko', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_reject_title_ko'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_description_ko'})});
          }
        }).finally(()=>{
          setLoadingReject(false);
          setLoadingOperation(null);
          resolve();
        });
      }
  });

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_operations_in_progress_detail_customer_title'})}}
      title={title}
      pageInfo={{option: process.env.REACT_APP_MENU_OPERACIONES_CURSO, role:'client'}}
      showBack
    >

      <ResponsiveLayout className='inner'>
        {screenSize.isDesktopOrBigger &&
          <Box paddingBottom={32}>
            <Inline space={'between'} alignItems='center'>
              <Text5>{intl.formatMessage({id:'page_operations_in_progress_detail_customer_title'})}</Text5>
            </Inline>
          </Box>
        }

        {/* START detail of opening of new contracts... */}
        {newContract !== null &&
          <Box paddingY={24}>

            {newContract.compania.cdCompania === parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES??'1')  &&
              <OperationInProgressContractPensionPlan operation={newContract} />
            }

            {newContract.compania.cdCompania === parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS??'3')  &&
              <OperationInProgressContractInvestmentFund operation={newContract} />
            }
          </Box>
        }

        {/* ...END detail of opening of new contracts */}

        {/* START detail of operations... */}
        {operation !== null &&


          <Box paddingY={24}>
            {operation.detalle.tipoOperacion === 'Suscripción' &&
              <OperationInProgressSubscriptionDetail operation={operation} />
            }

            {operation.detalle.tipoOperacion === 'Aportación' &&
              <OperationInProgressContributionDetail operation={operation} />
            }

            {operation.detalle.tipoOperacion === 'Reembolso' &&
              <OperationInProgressRefundDetail operation={operation} />
            }

            {operation.detalle.tipoOperacion === 'Traslado' && operation.compania.cdCompania === parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES??'1')  &&
              <OperationInProgressMobilizationDetail operation={operation} />
            }

            {operation.detalle.tipoOperacion === 'Traslado' && operation.compania.cdCompania === parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS??'3')  &&
              <OperationInProgressTransferDetail operation={operation} />
            }
          </Box>
        }
        {/* ...END detail of operations */}

        {((newContract && !newContract.enviado) || (operation && !operation.enviado)) &&
          ((newContract && newContract.pendienteFirmaPropia) || (operation && operation.pendienteFirmaPropia) ? (
              <ButtonFixedFooterLayout
                button={
                  <ButtonLayout align="full-width">
                    <ButtonDanger showSpinner={(newContract ? newContract.numSolicitud : operation? operation.numSolicitud : "") === loadingOperation && loadingReject} onPress={() => {rejectOperationInProgress( newContract ? newContract.numSolicitud : operation? operation.numSolicitud : "", () => {rejectOperation(newContract ? newContract.numSolicitud : operation? operation.numSolicitud : ""); trackEvent('perfil-'+formatProduct(newContract ? (newContract.contrato.plan) : (operation ?  (operation.contrato.plan) : null))+'-operaciones_curso-rechazar')})}}>
                        {intl.formatMessage({id: 'page_operations_in_progress_customer_detail_reject'})}
                    </ButtonDanger>
                    <ButtonPrimary showSpinner={(newContract ? newContract.numSolicitud : operation? operation.numSolicitud : "") === loadingOperation && loadingSign} onPress={() => {signOperationInProgress( newContract ? newContract.numSolicitud : operation? operation.numSolicitud : "", () => {signOperation(newContract ? newContract.numSolicitud : operation? operation.numSolicitud : ""); trackEvent('perfil-'+formatProduct(newContract ? (newContract.contrato.plan) : (operation ?  (operation.contrato.plan) : null))+'-operaciones_curso-firmar')})}}>
                        {intl.formatMessage({id:'page_operations_in_progress_customer_button_sign'})}
                    </ButtonPrimary>
                  </ButtonLayout>
                }
              >
              </ButtonFixedFooterLayout>
            ) : (
              <ButtonFixedFooterLayout
                button={<ButtonLayout align="full-width"><ButtonDanger showSpinner={(newContract ? newContract.numSolicitud : operation? operation.numSolicitud : "") === loadingOperation && loadingSign} onPress={() => {confirmDeleteOperation(newContract ? newContract.numSolicitud : operation? operation.numSolicitud : ""); trackEvent('perfil-'+formatProduct(newContract ? (newContract.contrato.plan) : (operation ?  (operation.contrato.plan) : null))+'-operaciones_curso-anular')}}>
                          {intl.formatMessage({id:'page_operations_in_progress_detail_customer_cancel'})}
                        </ButtonDanger></ButtonLayout>}
              >
              </ButtonFixedFooterLayout>
            ))
          }
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default OperationsInProgressDetailCustomerPage;