import { Box, ButtonFixedFooterLayout, ButtonLayout, ButtonPrimary, ButtonSecondary, DataCard, DecimalField, Form, Grid, LoadingBar, ResponsiveLayout, skinVars, Stack, Text1, Text2, Text4, Text5, useScreenSize, useWindowSize } from '@telefonica/mistica';
import React, { useEffect, useState } from "react";
import { useIntl } from 'react-intl';

import { useIonRouter } from '@ionic/react';
import { Contract } from '../../entities/contract/contract';
import { OperationSubscription } from '../../entities/contract/operations/operation-subscription';
import { addSubscription } from '../../utils/apiclient';
import { getCurrentUserContract, removeOperationSubscription, setOperationSubscription } from '../../utils/storage';
import { formatAccountNumber, formatAmount } from '../../utils/utils';

interface SubscriptionInvestmentFundsProps {
    contract: Contract | null,
    loading?: boolean,
	inProcessContract?: boolean,
    onCancel?:() => void,
    onConfirm:() => void
}

const SubscriptionInvestmentFunds: React.FC<SubscriptionInvestmentFundsProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const router = useIonRouter();
    const [contract, setContract] = useState(null as null|Contract);


    const [loading, setLoading] = React.useState(true);

    //Loading form
    const [loadingForm, setLoadingForm] = React.useState(false);

    useEffect(()=>{
        removeOperationSubscription();
        if(prop.contract){
            setContract(prop.contract);

            const uc = getCurrentUserContract();
            if(uc){
                setContract(uc);
            }
            setLoading(false);

        }
    },[prop.contract]);

    //Form
    const initFormData = {
        amount: ''
    }
    const [formData, setFormData] = useState(initFormData);
    const [formErrors, setFormErrors] = useState([] as any);

    //Handle fields
    const handleError=(name:string) =>{
        if(formErrors && formErrors[name]){
            const error = formErrors[name];
            if(error){
                const errorKey = error.toLowerCase().replaceAll(' ', '_');
                switch(errorKey){
                    case 'minimum_amount_of_subscription':
                        return intl.formatMessage({id: 'page_operate_subcription_'+errorKey}, {minimum_amount: formatAmount(contract?.importeMinimoSuscripciones ?? '', true), maximum_amount: formatAmount(contract?.maxImporteBlanqueo?? '', true)});
                        break;

                    case 'amount_contributed_exceeds_limit':
                        return intl.formatMessage({id: 'page_operate_subcription_'+errorKey}, {maximum_amount: formatAmount(contract?.maxImporteBlanqueo ?? '', true)});
                        break;

                    case 'limit_contributions_last_months_exceeded':
                        return intl.formatMessage({id: 'page_operate_subcription_'+errorKey}, {months: contract?.mesesBlanqueo ?? '', maximum_amount: formatAmount(contract?.maxImporteBlanqueo ?? '', true)});
                        break;

                }
                return intl.formatMessage({id: 'page_operate_subcription_'+errorKey});
            }
        }
        return null;
    }

    const validateField = (name:string, value:any)=>{
        switch(name){
            case 'amount':
              if(value && value.length>0 && parseFloat(value.replace(',', '.')) < (contract?.importeMinimoSuscripciones ?? 0)){
                formErrors[name] = 'minimum amount of subscription';
                return false;
              }else if(value && value.length>0 && parseFloat(value.replace(',', '.')) > (contract?.maxImporteBlanqueo ?? 0)){
                formErrors[name] = 'amount contributed exceeds limit';
                return false;
              }

              break;
          }
          return true;
    }

    const handleChangeAnyField= (name:string, value:any) => {
        const newValues = {
            ...formData,
            [name]: value,
        };
        setFormData(newValues);

        if(handleError(name)){
            delete formErrors[name];
        }
        validateField(name, value);
    };

    const handleSubmit = (data: any): Promise<void> =>
        new Promise((resolve) => {
            setLoadingForm(true);

            let operation = getOperation();

            addSubscription(operation).then((result)=>{
                if(result.result){
                    operation.summary = getSummary();
                    setOperationSubscription(operation);
                    prop.onConfirm();
                }else if(result.errors){
                    const errors = result.errors as any;
                    setFormErrors(result.errors);
                }
            }).finally(()=>{
                setLoadingForm(false);
                resolve();
            })
        });

    const getOperation = ()=>{
        let operation: OperationSubscription = {
            amount: parseFloat(formData.amount.replace(',', '.')),
            confirm: false
        };
        return operation;
    }

    const getSummary= () => {
        let summary: Array<{title:string, value:string}> = [];
        summary.push({title:intl.formatMessage({id:'page_operate_subscription_amount'}), value: formatAmount(parseFloat(formData.amount.replace(',', '.')))+'€'});
        summary.push({title:intl.formatMessage({id:'page_operate_subscription_fund_account_number'}), value: formatAccountNumber(contract?.plan?.informacionGeneral?.ibanAportacion??'')});

        return summary;
    };


    const isSubmitDisabled = () => {
        return formData.amount.length===0 || handleError('amount')!==null;
    };

    return (
        <>{prop.loading ?
            <></>
        :
            <>
                <LoadingBar  visible={loading || loadingForm} />
                <Form onSubmit={handleSubmit} initialValues={initFormData} className={loadingForm ? 'loading' : ''}>
                    <ResponsiveLayout className={!prop.inProcessContract ? 'inner' : ''}>
                        <ButtonFixedFooterLayout
                            button={
                                <ButtonLayout>
                                    <ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_operate_subscription_continue'})}</ButtonPrimary>
                                </ButtonLayout>
                            }
                            secondaryButton={screenSize.isDesktopOrBigger ? <ButtonLayout><ButtonSecondary onPress={() => {prop.onCancel ? prop.onCancel() : router.goBack()}}>{intl.formatMessage({id:'page_operate_subscription_back_to_operational'})}</ButtonSecondary></ButtonLayout> : undefined}
                        >
                            <Box paddingBottom={screenSize.isDesktopOrBigger ? 56 : 16}>
                                <Stack space={32}>
                                    {!prop?.inProcessContract &&
                                        <Stack space={16}>
                                            <Text5 color={skinVars.colors.brand}>{intl.formatMessage({id:'page_operate_subscription_fund_subscription'})}</Text5>
                                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_subscription_fund_subscription_description'})}</Text2>
                                        </Stack>
                                    }

                                    <Stack space={24}>
                                        {!prop?.inProcessContract &&
                                            <Text4 medium>{intl.formatMessage({id:'page_operate_refund_subscription'})}</Text4>
                                        }

                                        <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={[40, 4]}>

                                            <DecimalField maxDecimals={2} fullWidth error={handleError('amount')!==null} helperText={handleError('amount')??undefined} name="amount" label={intl.formatMessage({id:'page_operate_subscription_amount'})} onChangeValue={(val:any)=> handleChangeAnyField('amount', val)}  />

                                            <Box paddingX={screenSize.isDesktopOrBigger ? 0 : 8}>
                                                <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id: 'page_operate_subscription_amount_info'}, {minimum_amount: formatAmount(contract?.importeMinimoSuscripciones ?? '', true), maximum_amount: formatAmount(contract?.maxImporteBlanqueo?? '', true)})}</Text1>
                                            </Box>
                                        </Grid>
                                    </Stack>

                                    <Stack space={24}>
                                        <Text4 medium>{intl.formatMessage({id:'page_operate_refund_way_to_pay'})}</Text4>

                                        <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={[40, 4]}>
                                            <DataCard title={intl.formatMessage({id:'page_operate_refund_fund_account_number'})} subtitle={ formatAccountNumber(contract?.plan?.informacionGeneral?.ibanAportacion??'')}></DataCard>

                                            <Box paddingX={screenSize.isDesktopOrBigger ? 0 : 8}>
                                                <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_refund_description'})}</Text1>
                                            </Box>
                                        </Grid>
                                    </Stack>
                                </Stack>
                            </Box>
                        </ButtonFixedFooterLayout>
                    </ResponsiveLayout>
                </Form>
            </>
        }</>
    );
};

export default SubscriptionInvestmentFunds;