import { Divider, Grid, skinVars, Stack, Text2, Text3, Text4, useScreenSize } from '@telefonica/mistica';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ListOption } from '../../entities/commons/list-option.model';
import { OperationInProgress } from '../../entities/operationsInProgress/operation-in-progress.model';
import { getMasterDatas } from '../../utils/apiclient';
import { formatAmount, formatNetAssetValue } from '../../utils/utils';
import OperationInProgressDetail from './OperationInProgressDetail';
import OperationInProgressDetailContract from './OperationInProgressDetailContract';


interface OperationInProgressContractInvestmentFundProps {
    operation: OperationInProgress
}

const OperationInProgressContractInvestmentFund: React.FC<OperationInProgressContractInvestmentFundProps> = (prop) => {

    const intl = useIntl();
    const screenSize = useScreenSize();
    const [items, setItems] = useState([] as Array<{title:string, items:Array<{title:string, value:string}>}>);

    useEffect(()=>{
        if(prop.operation){
            const operation = prop.operation;

            const operations : Array<{title:string, items:Array<{title:string, value:string}>}> = [];


            let subscription = operation.esMultiOperacion ? operation.operacionesAsociadas.Operaciones.find(item=>item.tipoOperacionDetalle===2) : (operation.tipoOperacionDetalle===2 ? operation : null);

            if(subscription){
                let itemSubscriptionValues: Array<{title:string, value:string}> = [];

                itemSubscriptionValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_amount' }), value: formatAmount(subscription.importeOperacion)+'€'});
                itemSubscriptionValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_way_to_pay' }), value:
                        subscription.formaPago===process.env.REACT_APP_SUSCRIPCION_FORMA_PAGO_TRANSFERENCIA ? intl.formatMessage({ id: 'page_operations_in_progress_customer_way_to_pay_transfer' })
                        : subscription.formaPago===process.env.REACT_APP_SUSCRIPCION_FORMA_PAGO_CHEQUE ? intl.formatMessage({ id: 'page_operations_in_progress_customer_way_to_pay_check' }) : ''
                });
                let itemSubscription : {title:string, items:Array<{title:string, value:string}>}={
                    title: intl.formatMessage({ id: 'page_operations_in_progress_customer_subscription_detail' }),
                    items: itemSubscriptionValues
                };
                operations.push(itemSubscription);
            }


            let transfer = operation.esMultiOperacion ? operation.operacionesAsociadas.Operaciones.find(item=>item.tipoOperacionDetalle===3) : (operation.tipoOperacionDetalle===3 ? operation : null);

            if(transfer){
                let itemTransferValues: Array<{title:string, value:string}> = [];

                if(transfer.gestoraOrigen && transfer.gestoraOrigen.nombre){
                    itemTransferValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_manager' }), value: transfer.gestoraOrigen.nombre});

                    if(transfer.fondoExterno && transfer.fondoExterno.nombre){
                        itemTransferValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_fund' }), value: transfer.fondoExterno.nombre});
                    }
                    if(transfer.codigoContratoExterno){
                        itemTransferValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_account' }), value: transfer.codigoContratoExterno});
                    }

                    if(transfer.fondoExterno && transfer.fondoExterno.isin){
                        itemTransferValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_isin' }), value: transfer.fondoExterno.isin});
                    }
                }else{
                    if(transfer.companiaFondoPlan && transfer.companiaFondoPlan.nombre){
                        itemTransferValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_manager' }), value: transfer.companiaFondoPlan.nombre});
                    }
                    if(transfer.detalle && transfer.detalle.planFondoOrigen && transfer.detalle.planFondoOrigen.plan?.descripcion){
                        itemTransferValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_fund' }), value: transfer.codigoContratoOrigen+' - '+transfer.detalle.planFondoOrigen.plan?.descripcion});
                    }
                }

                switch(transfer.tipoMovilizacion){
                    case process.env.REACT_APP_TRASLADO_TOTAL:
                        itemTransferValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_transfer_type' }), value: intl.formatMessage({ id: 'page_operations_in_progress_customer_total_transfer' })});
                        break;

                    case process.env.REACT_APP_TRASLADO_PARCIAL_IMPORTE:
                        itemTransferValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_amount' }), value: formatAmount(transfer.importeOperacion)+'€'});
                        break;
                    case process.env.REACT_APP_TRASLADO_PARCIAL_UNIDADES:
                        itemTransferValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_units' }), value: formatNetAssetValue(transfer.UnidadesONumParticip)});
                        break;
                }
                let itemTransfer: {title:string, items:Array<{title:string, value:string}>}={
                    title: intl.formatMessage({ id: 'page_operations_in_progress_customer_transfer_detail' }),
                    items: itemTransferValues
                };
                operations.push(itemTransfer);
            }

            setItems(operations);
        }

    },[]);
    return (<OperationInProgressDetailContract operation={prop.operation} items={items} />);
};

export default OperationInProgressContractInvestmentFund;
