import React from 'react';

import { useIntl } from 'react-intl';
import { Box, Divider, GridLayout, Inline, Placeholder, Stack, Text2, Text4, Text5, skinVars, useScreenSize, SkeletonRectangle } from '@telefonica/mistica';

import './ProfitabilityValues.scss';
import { Product } from '../../entities/products/product';
import { formatAmount, formatBalance, formatDate, formatNetAssetValue } from '../../utils/utils';

interface ProfitabilityValuesProps {
    product: Product | null
    loading?: boolean
}

const ProfitabilityValues: React.FC<ProfitabilityValuesProps> = (prop) => {
    const intl = useIntl()

    const screenSize = useScreenSize();

    return (<>{prop.loading ?
                <Box paddingY={12}>
                    <SkeletonRectangle height={309} />
                </Box>
            :
                prop.product ?
                <>
                    <style>{
                        '.profitabilityValues--content .boxValue--color{background-color:'+skinVars.colors.backgroundAlternative+';}'+
                        '.profitabilityValues--content [data-center="true"] .divider:before {background: '+skinVars.colors.divider+';}'
                    }</style>

                    <Stack className='profitabilityValues--content' space={40}>
                        
                        {prop.product.individual!==process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS &&
                            <Box paddingY={24}>
                                <GridLayout
                                    template="8+4"
                                    left={
                                        <Inline space={'around'}>
                                            <Box paddingTop={16}>
                                                <Inline space={'around'}>
                                                    <Stack space={16}>
                                                        <Box className='boxValue'>
                                                            <Text4 regular>{intl.formatMessage({id:'page_investment_funds_customer_profitability_values_liquidation_value'})}</Text4>
                                                        </Box>

                                                        <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                                                            <Text5 color={skinVars.colors.textButtonPrimaryInverse}>{prop.product?.rentabilidad && prop.product?.rentabilidad?.valorLiquidativo ? formatNetAssetValue(prop.product?.rentabilidad?.valorLiquidativo) : intl.formatMessage({id:'page_product_customer_not_available_short'})}</Text5>
                                                        </Box>
                                                    </Stack>
                                                </Inline>
                                            </Box>

                                            <Box paddingTop={16}>
                                                <Inline space={'around'}>
                                                    <Stack space={16}>
                                                        <Box className='boxValue'>
                                                            <Text4 regular>{intl.formatMessage({id:'page_investment_funds_customer_profitability_values_heritage'})}</Text4>
                                                        </Box>

                                                        <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                                                            <Text5 color={skinVars.colors.textButtonPrimaryInverse}>{prop.product.rentabilidad && prop.product.rentabilidad.patrimonio ? formatBalance(parseFloat(prop.product.rentabilidad.patrimonio.replace(',', '.'))/1000) : intl.formatMessage({id:'page_product_customer_not_available_short'})}</Text5>
                                                        </Box>
                                                    </Stack>
                                                </Inline>
                                            </Box>
                                        </Inline>
                                    }
                                    right={
                                        <Box paddingTop={16}>
                                            <Inline space={'around'}>
                                                <Stack space={16}>
                                                    <Box className='boxValue'>
                                                        <Text4 regular>{intl.formatMessage({id:'page_investment_funds_customer_profitability_values_participants'})}</Text4>
                                                    </Box>

                                                    <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                                                        <Text5 color={skinVars.colors.textButtonPrimaryInverse}>{prop.product.rentabilidad && prop.product.rentabilidad.numeroParticipes ? formatAmount(prop.product.rentabilidad.numeroParticipes, true) : intl.formatMessage({id:'page_product_customer_not_available_short'})}</Text5>
                                                    </Box>
                                                </Stack>
                                            </Inline>
                                        </Box>
                                    }
                                />
                            </Box>
                        }

                        {prop.product.individual!==process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS &&
                            <Divider />
                        }

                        <Box paddingY={24}>
                            <GridLayout
                                dataAttributes={ {'center':true}}
                                template="8+4"
                                left={
                                    <Inline space={'around'}>
                                        <Box paddingX={24} className='boxValue--center'>
                                            <Inline space={'around'}>
                                                <Stack space={4}>
                                                    <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_investment_funds_customer_profitability_values_date'})}</Text2>
                                                    <Text4 medium>{prop.product.rentabilidad?.fechaValorLiquidativo ? formatDate(prop.product.rentabilidad?.fechaValorLiquidativo) : intl.formatMessage({id:'page_product_customer_not_available_short'})}</Text4>
                                                </Stack>
                                            </Inline>
                                        </Box>

                                        <Box paddingX={24} className='divider boxValue--center'>
                                            <Inline space={'around'}>
                                                <Stack space={4}>
                                                    <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_investment_funds_customer_profitability_values_year_rentability'})}</Text2>
                                                    <Text4 medium>{prop.product.rentabilidad?.rentabilidadAnual ? formatBalance(prop.product.rentabilidad?.rentabilidadAnual)+'%' : intl.formatMessage({id:'page_product_customer_not_available_short'})}</Text4>
                                                </Stack>
                                            </Inline>
                                        </Box>
                                    </Inline>
                                }
                                right={
                                    <Box paddingX={24} className={screenSize.isDesktopOrBigger ? 'divider boxValue--center' : 'boxValue--center'}>
                                        <Inline space={'around'}>
                                            <Stack space={4}>
                                                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_investment_funds_customer_profitability_values_historical_rentability'})}</Text2>
                                                <Text4 medium>{prop.product.rentabilidad?.rentabilidadHistorica ? formatBalance(prop.product.rentabilidad.rentabilidadHistorica)+(prop.product.rentabilidad.tpRentabilidadHistorica ? ' '+intl.formatMessage({id:'page_product_customer_apr'}) : '%') : intl.formatMessage({id:'page_product_customer_not_available_short'})}</Text4>
                                            </Stack>
                                        </Inline>
                                    </Box>
                                }
                            />
                        </Box>
                    </Stack>
                </>
            :
                <></>
        }</>);
};

export default ProfitabilityValues;
