

import { useIonRouter } from '@ionic/react';
import { Box, ButtonPrimary, ResponsiveLayout, Select, Stack, Text7, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import slugify from 'react-slugify';
import ButtonTertiary from '../../../components/buttons/ButtonTertiary';
import ChipTabs from '../../../components/chip-tabs/ChipTabs';
import EmptyStateCustom from '../../../components/empty-state/EmptyStateCustom';
import HeroImage from '../../../components/hero/HeroImage';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import { useProductActions } from '../../../components/operate/ProductActions';
import AnnualEvolutionProfitability from '../../../components/product-data/AnnualEvolutionProfitability';
import Documentation from '../../../components/product-data/Documentation';
import GeneralInformation from '../../../components/product-data/GeneralInformation';
import HistoricalProfitability from '../../../components/product-data/HistoricalProfitability';
import NetAssetValueEvolution from '../../../components/product-data/NetAssetValueEvolution';
import PortfolioComposition from '../../../components/product-data/PortfolioComposition';
import ProfitabilityValues from '../../../components/product-data/ProfitabilityValues';
import Risk from '../../../components/product-data/Risk';
import { ListOption } from '../../../entities/commons/list-option.model';
import { Product } from '../../../entities/products/product';
import { getInfoProduct, getProduct, getProducts } from '../../../utils/apiclient';
import { getUrlNavigation } from '../../../utils/navigation';
import { formatProduct, isBot, scrollToTop, trackEvent } from '../../../utils/utils';
import Error404Page from '../error-404/Error404Page';
import CustomizeInvestmentFundPage from '../for-investor/calculators/DecideYourInvestmentCalculatorPage';
import InvestmentFundsPage from './InvestmentFundsPage';

const InvestmentFundsDetailPage: React.FC = () => {
  const intl = useIntl()
  const size = useScreenSize();
  const screenSize = useScreenSize();
  const router = useIonRouter();
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [products, setProducts] = useState(null as Product[]|null);
  const [product, setProduct] = useState(null as Product|null);
  const [productInfo, setProductInfo] = useState(null as Product|null);
  const [options, setOptions] = useState([] as string[]);
  const [fundClass, setFundClass] = useState('A');
  const [optClasses, setOptClasses] = useState([] as ListOption[]);
  const params = useParams() as any;
  const { contract } = useProductActions()
  const [optionsHash, setOptionsHash] = useState([intl.formatMessage({id:'page_investment_funds_detail_chip_tab1_name_hush'}), intl.formatMessage({id:'page_investment_funds_detail_chip_tab2_name_hush'}), intl.formatMessage({id:'page_investment_funds_detail_chip_tab3_name_hush'}), intl.formatMessage({id:'page_investment_funds_detail_chip_tab4_name_hush'})] as string[]);


  useLayoutEffect(()=>{
    setTimeout(function(){scrollToTop()}, 400);
    setLoading(true);
    setProduct(null);
    setSelectedIndex(0);
    setProducts(null);
    setOptions([]);
    setOptClasses([]);
    getProduct({cdCompany: parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ?? '3'), slug:params.slug}).then((result)=>{
        if(result){
            setProductInfo(result);
        }
    });

    getInfoProduct({cdCompany: parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ?? '3'), slug:params.slug}).then((result)=>{
        if(result){
            if(result.fondos && result.fondos.length){
                setFundClass(result?.clase?? 'A');
                let classes: ListOption[] = [{descripcion: intl.formatMessage({id:'page_investment_funds_detail_class_a'}), valor:'A'}];
                if(result.fondos.find(item=>item.clase==='B')){
                    classes.push({descripcion: intl.formatMessage({id:'page_investment_funds_detail_class_b'}), valor:'B'})
                }
                if(result.fondos.find(item=>item.clase==='C')){
                    classes.push({descripcion: intl.formatMessage({id:'page_investment_funds_detail_class_c'}), valor:'C'})
                }
                setOptClasses(classes);
                result.fondos.push(result);
                setProducts(result.fondos);
            }

            let opts = [intl.formatMessage({id:'page_investment_funds_detail_chip_tab1_name'}), intl.formatMessage({id:'page_investment_funds_detail_chip_tab2_name'}), intl.formatMessage({id:'page_investment_funds_detail_chip_tab3_name'})];
            if(result.documentacion && result.documentacion.filter(item=>(item.esSO??false)).length>0){
              opts.push(intl.formatMessage({id:'page_investment_funds_detail_chip_tab4_name'}))
            }
            setOptions(opts);
            setProduct(result);
            if(!productInfo){
                setProductInfo(result);
                getProducts(parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ?? '3')).then(()=>{})
            }
        }else{
            router.push(getUrlNavigation(Error404Page));
        }
    }).finally(()=>{
        setLoading(false);
    });
}, [params.slug]);

useLayoutEffect(()=>{
    if(window.location.hash.length>0 && optionsHash.length>0){
      
      let index = optionsHash.findIndex(item=>item===window.location.hash.replace('#', ''));
      if(index!=-1){
        setSelectedIndex(index);

        console.log(index);
        
        setTimeout(function(){
          scrollToTop('content-chips');
        }, 400);
      }
    }

  }, [window.location.hash]);


useEffect(()=>{
    if(product){
        trackEvent('fondo-'+formatProduct(product)+'-'+slugify(options[selectedIndex]).replaceAll('-', '_'));
    }

}, [selectedIndex]);

const handleSelectClass = (classname: string)=>{
    setFundClass(classname);
    const prod = products?.find(item=>item.clase===classname);
    if(prod){
        trackEvent('fondo-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-'+classname);
        setProductInfo(null);
        router.push(getUrlNavigation(InvestmentFundsPage)+'/'+prod.slug)
    }
};



const handleContract = (): Promise<void> =>
    new Promise((resolve) => {
        if(product){
            trackEvent('fondo-'+(product.slug?.replaceAll('-', '_') ?? '')+'-contratar');
            contract(product).finally(()=>{
                resolve()
            });
        }else{
          resolve()
        }
    });

  return (
    <LayoutPublic
        seo={{metaTitle: (product?.descripcion ?? (productInfo?.descripcion ?? '')), metaDescription: (product?.informacionGeneral?.descripcionAreaPublica ?? (productInfo?.informacionGeneral?.descripcionAreaPublica ?? ''))}}
        header={{title:(product?.descripcion ?? (productInfo?.descripcion ?? '')), parents:[{title:intl.formatMessage({id:'page_investment_funds_title'}), item:InvestmentFundsPage}]}}
    >
        <HeroImage
            image={(productInfo?.informacionGeneral?.Imagen ?? '')}
            title={(productInfo?.informacionGeneral?.tituloAreaPublica ?? '')}
            description={(productInfo?.informacionGeneral?.descripcionAreaPublica ?? '')}
            button={(product?.contratable ?? productInfo?.contratable ?? false) && <ButtonTertiary onPress={handleContract}>{intl.formatMessage({id:'page_investment_funds_detail_hero_action'})}</ButtonTertiary>}
        ></HeroImage>

        <ResponsiveLayout className='content' fullWidth={true}>
            <ResponsiveLayout>
                <Stack space={32}>
                    <Box paddingTop={screenSize.isDesktopOrBigger ? 80 : 32} className={screenSize.isTabletOrBigger ? 'boxAlignCenter': ''}>
                        <Text7>{(product?.descripcion ?? (productInfo?.descripcion ?? ''))}</Text7>
                    </Box>
                    {optClasses.length>1 &&
                        <Box className={screenSize.isTabletOrBigger ? 'boxAlignCenter': ''}>
                            <Box width={240}>
                                <Select fullWidth value={fundClass} label={''} onChangeValue={(val)=>{handleSelectClass(val)}} name={'fundClass'} options={optClasses.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                            </Box>
                        </Box>
                    }
                </Stack>

            </ResponsiveLayout>
        </ResponsiveLayout>

        <div id="content-chips">
            <ChipTabs
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
                tabs={options.map((text) => ({ text }))}
            />

            <ResponsiveLayout className={(selectedIndex===0 ? 'd-block' : 'd-none')}>
                <ProfitabilityValues loading={loading} product={product} />
                <Risk loading={loading} product={product} />
                <PortfolioComposition loading={loading}  product={product} />
                <GeneralInformation loading={loading} product={product} />
            </ResponsiveLayout>

            <ResponsiveLayout className={(selectedIndex===1 ? 'd-block' : 'd-none')}>
                <HistoricalProfitability loading={loading}  product={product} />
                <AnnualEvolutionProfitability loading={loading}  product={product} />
                <NetAssetValueEvolution loading={loading}  product={product} />
            </ResponsiveLayout>

            <ResponsiveLayout className={(selectedIndex===2 ? 'd-block' : 'd-none')}>
                <Documentation prefixSlug={'fondo-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_documentacion-'} full loading={loading} product={product} sustainability={false} />
            </ResponsiveLayout>

            <ResponsiveLayout className={(selectedIndex===3 ? 'd-block' : 'd-none')}>
                <Documentation prefixSlug={'fondo-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_sostenibilidad-'} full loading={loading} product={product} sustainability={true} />
            </ResponsiveLayout>

            <ResponsiveLayout className='content' fullWidth={true}>
                <ResponsiveLayout>

                <EmptyStateCustom
                    largeImageUrl="../assets/img/como-elegir-un-fondo.jpg"
                    title={intl.formatMessage({id:'page_investment_funds_empty_state_title'})}
                    description={intl.formatMessage({id:'page_investment_funds_empty_state_description'})}

                    button={
                        <ButtonGroup
                        primaryButton={isBot ?
                            <ButtonPrimary href={getUrlNavigation(CustomizeInvestmentFundPage)}><img src={'../assets/img/calculate-your-plan.svg'}/>
                            {intl.formatMessage({id:'page_investment_funds_empty_state_action_primary'})}
                            </ButtonPrimary>
                        :
                            <ButtonPrimary onPress={()=>{router.push(getUrlNavigation(CustomizeInvestmentFundPage)); trackEvent('fondo-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-ir_calculadora');}}><img src={'../assets/img/calculate-your-plan.svg'}/>
                            {intl.formatMessage({id:'page_investment_funds_empty_state_action_primary'})}
                            </ButtonPrimary>
                        }
                        ></ButtonGroup>
                    }
                />

                </ResponsiveLayout>
            </ResponsiveLayout>
        </div>
    </LayoutPublic>
  );
};

export default InvestmentFundsDetailPage;
