import { Box, Boxed, ButtonFixedFooterLayout, ButtonLayout, ButtonPrimary, ButtonSecondary, DecimalField, Form, Grid, Inline, LoadingBar, RadioButton, RadioGroup, ResponsiveLayout, Select, skinVars, Stack, Text1, Text2, Text4, Text5, TextField, useScreenSize, useWindowSize } from '@telefonica/mistica';
import React, { useEffect, useState } from "react";
import { useIntl } from 'react-intl';

import { useIonRouter } from '@ionic/react';
import { ExternalFund } from '../../entities/commons/external-fund.model';
import { Option } from '../../entities/commons/option.model';
import { Contract } from '../../entities/contract/contract';
import { OperationTransfer } from '../../entities/contract/operations/operation-transfer';
import { addTransfer, getExternalFunds, getPlanManagers } from '../../utils/apiclient';
import { getCurrentUserContract, removeOperationTransfer, setOperationTransfer } from '../../utils/storage';
import { closeDialog, formatAmount, trackError } from '../../utils/utils';

interface TransferInvestmentFundsProps {
    contract: Contract | null,
    loading?: boolean,
	inProcessContract?: boolean,
    onCancel?:() => void,
    onConfirm:() => void
}

const TransferInvestmentFunds: React.FC<TransferInvestmentFundsProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const size = useWindowSize();
    const router = useIonRouter();
    const [contract, setContract] = useState(null as null|Contract);
    const [planManagersOptions, setPlanManagersOptions] = React.useState([] as Option[]);
    const [externalFundsOptions, setExternalFundsOptions] = React.useState([] as ExternalFund[]);
    const [contractsUser, setContractsUser] = React.useState([] as Contract[]);

    const [loading, setLoading] = React.useState(true);

    //Loading form
    const [loadingForm, setLoadingForm] = React.useState(false);

    useEffect(()=>{
        removeOperationTransfer();
        if(prop.contract){
            setContract(prop.contract);

            const uc = getCurrentUserContract();
            if(uc){
                setContract(uc)

                if(uc.fondosContratados && uc.fondosContratados.length>0){
                    setContractsUser(uc.fondosContratados);
                }
            }

            getPlanManagers(prop.contract.plan?.cdCompania??-1).then(options=>{
                setPlanManagersOptions(options);
            }).finally(()=>{
                setLoading(false);
            })

        }
    },[prop.contract]);

    //Form
    const initFormData = {
        transferTypeParent: (process.env.REACT_APP_TRASLADO_TOTAL??'0'),
        transferType:  (process.env.REACT_APP_TRASLADO_PARCIAL_IMPORTE??'1'),
        originType: (process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO??'E'),
        cdExternalPromoter: '',
        cdExternalFund: '',
        cdFonditelContract: '',
        amount: '',
        numAccount: '',
        isin: ''
    }
    const [formData, setFormData] = useState(initFormData);
    const [formErrors, setFormErrors] = useState([] as any);

    //Handle fields
    const handleError=(name:string) =>{
            if(formErrors && formErrors[name]){
            const error = formErrors[name];
            if(error){
                return intl.formatMessage({id: 'page_operate_transfer_'+error.toLowerCase().replaceAll(' ', '_')});
            }
        }
        return null;
    }

    const validateField = (name:string, value:any)=>{
        switch(name){
            case 'numAccount':
                if(value && value.length>0 && (isNaN(parseFloat(value)) || value.length>10)){
                    formErrors[name] = 'numAccount is not valid';
                    return false;
                }
                break;
            case 'isin':
                const regexpIsin = new RegExp('^([A-Z]{2})([A-Z0-9]{9})([0-9]{1})$');
                if(value && value.length>0 && (!regexpIsin.test(value) || value.length>12)){
                    formErrors[name] = 'isin is not valid';
                    return false;
                }
                break;
        }
          return true;
    }

    const handleChangeAnyField= (name:string, value:any) => {
        const newValues = {
            ...formData,
            [name]: value,
        };
        setFormData(newValues);

        switch(name){
            case 'cdExternalPromoter':
                setExternalFundsOptions([]);
                try{
                  if(value && value.toString().trim().length>0){
                    setLoadingForm(true);
                    getExternalFunds(value.toString().trim()).then(options=>{
                      if(options){
                        setExternalFundsOptions(options);
                      }
                    }).finally(()=>{
                      setLoadingForm(false);
                    });
                  }
                }catch(err){
                    if(err){
                        trackError(JSON.stringify(err));
                    }
                }
                const newValuesISIN = {
                    ...newValues,
                    ['isin']: '',
                };
                setFormData(newValuesISIN);
                break;
            case 'cdExternalFund':
                setLoadingForm(true);
                const externalFund = value && value.length>0 ? externalFundsOptions.find(item=>item.id===value) : null;

                if(externalFund){
                    const newValuesISIN = {
                        ...newValues,
                        ['isin']: externalFund.isin,
                    };
                    setFormData(newValuesISIN);
                }
                setLoadingForm(false);

                break;
        }

        if(handleError(name)){
            delete formErrors[name];
        }
        validateField(name, value);
    };

    const handleSubmit = (data: any): Promise<void> =>
        new Promise((resolve) => {
            setLoadingForm(true);

            let operation = getOperation();

            addTransfer(operation).then((result)=>{
                if(result.result){
                    operation.summary = getSummary();
                    setOperationTransfer(operation);
                    prop.onConfirm();
                }else if(result.errors){
                    const errors = result.errors as any;
                    setFormErrors(result.errors);


                    if(errors['alert']){
                        let message = '';
                        const errorKey = 'page_operate_transfer_'+errors['alert'].toLowerCase().replaceAll(' ', '_');

                        message = intl.formatMessage({id: errorKey});

                        if(message.length>0 && message!=errorKey){
                            closeDialog().then(()=>{
                                alert({
                                    title: intl.formatMessage({id:'alert_info_title'}),
                                    message: message??''
                                });
                            });
                        }
                    }
                }
            }).finally(()=>{
                setLoadingForm(false);
                resolve();
            })
        });

    const getOperation = ()=>{
        let operation: OperationTransfer = {
            transferType: formData.transferTypeParent===process.env.REACT_APP_TRASLADO_TOTAL ? parseInt(formData.transferTypeParent) : parseInt(formData.transferType),
            originType: formData.originType,
            amount: formData.transferTypeParent!==process.env.REACT_APP_TRASLADO_TOTAL ? parseFloat(formData.amount.replace(',', '.')) : undefined,
            cdExternalPromoter: formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO ? formData.cdExternalPromoter : '',
            cdExternalFund: formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO ? formData.cdExternalFund : '',
            cdFonditelContract: formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_FONDITEL ? formData.cdFonditelContract : '',
            isin: formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO ? formData.isin : '',
            numAccount: formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO ? formData.numAccount : '',
            confirm: false
        };
        return operation;
    }

    const getSummary= () => {
        let summary: Array<{title:string, value:string}> = [];

        if(formData.transferTypeParent===process.env.REACT_APP_TRASLADO_TOTAL){
            summary.push({title:intl.formatMessage({id:'page_operate_transfer_type_transfer'}), value:'Todo el contenido del fondo'});

        }else{
            summary.push({title:intl.formatMessage({id:'page_operate_transfer_type_transfer'}), value:'Parcial'});

            switch(formData.transferType){
                case process.env.REACT_APP_TRASLADO_PARCIAL_IMPORTE:
                    summary.push({title:intl.formatMessage({id:'page_operate_transfer_amount'}), value:formatAmount(parseFloat(formData.amount.replace(',', '.')))+'€'});
                    break;
                case process.env.REACT_APP_TRASLADO_PARCIAL_UNIDADES:
                    summary.push({title:intl.formatMessage({id:'page_operate_transfer_units'}), value:formatAmount(parseFloat(formData.amount.replace(',', '.')))});
                    break;
            }
        }

        switch(formData.originType){
            case process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO:
                summary.push({title:intl.formatMessage({id:'page_operate_transfer_origin_manager'}), value: planManagersOptions.find(item=>item.id===formData.cdExternalPromoter)?.nombre ?? ''});
                summary.push({title:intl.formatMessage({id:'page_operate_transfer_origin_fund'}),  value: externalFundsOptions.find(item=>item.id===formData.cdExternalFund)?.nombre ?? ''});
                break;
            case process.env.REACT_APP_TRASLADO_ORIGEN_FONDITEL:
                summary.push({title:intl.formatMessage({id:'page_operate_transfer_origin_fund_fonditel'}), value: contractsUser.find(item=>item.id===formData.cdFonditelContract)?.plan?.descripcion ?? ''});
                break;
        }

        return summary;
    };


    const isSubmitDisabled = () => {
        return (formData.transferTypeParent!==process.env.REACT_APP_TRASLADO_TOTAL &&
                (formData.transferTypeParent.length===0 || formData.amount.length===0 || handleError('amount')!==null)) ||

                (formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO &&
                (formData.cdExternalPromoter.length===0 || formData.cdExternalFund.length===0 || formData.numAccount.length===0  || handleError('numAccount')!==null || formData.isin.length===0 || handleError('isin')!==null)) ||

                (formData.originType===process.env.REACT_APP_TRASLADO_ORIGEN_FONDITEL &&
                    (formData.cdFonditelContract.length===0));
    };

    return (
        <>{prop.loading ?
            <></>
        :
            <>
                <LoadingBar  visible={loading || loadingForm} />
                <Form onSubmit={handleSubmit} initialValues={initFormData} className={loadingForm ? 'loading' : ''}>
                    <ResponsiveLayout className={!prop.inProcessContract ? 'inner' : ''}>
                        <ButtonFixedFooterLayout
                            button={
                                <ButtonLayout>
                                    <ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_operate_transfer_continue'})}</ButtonPrimary>
                                </ButtonLayout>
                            }
                        secondaryButton={screenSize.isDesktopOrBigger ? <ButtonLayout><ButtonSecondary onPress={() => {router.goBack()}}>{intl.formatMessage({id:'page_operate_transfer_back_to_operational'})}</ButtonSecondary></ButtonLayout> : undefined}
                    >

                        <Box paddingBottom={screenSize.isDesktopOrBigger ? 56 : 16}>
                            <Stack space={32}>

                                {!prop?.inProcessContract &&
                                    <Stack space={16}>
                                        <Text5 color={skinVars.colors.brand}>{intl.formatMessage({id:'page_operate_transfer_transfer_to_the_fund'})}</Text5>
                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_transfer_transfer_to_the_fund_description'})}</Text2>
                                    </Stack>
                                }

                                <Stack space={16}>
                                    {!prop?.inProcessContract &&
                                        <Text4 medium>{intl.formatMessage({id:'page_operate_transfer_transfer_type'})}</Text4>
                                    }

                                    <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                        <Stack space={screenSize.isDesktopOrBigger ? 24 : 16}>
                                            <RadioGroup name="transferTypeParent" onChange={(val:any)=> handleChangeAnyField('transferTypeParent', val)}>
                                                <Grid columns={2} gap={screenSize.isDesktopOrBigger ? 24 : 16}>
                                                    <RadioButton
                                                        value={process.env.REACT_APP_TRASLADO_TOTAL??'0'}
                                                        render={({ controlElement, checked, labelId }:any) => (
                                                            <Boxed className={'rbx-option '+ (checked ? 'active' : '')}>
                                                                <Box padding={16}>
                                                                    <Inline space={8} alignItems='center'>
                                                                        {controlElement}
                                                                        <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_transfer_full_transfer'})}</Text2>
                                                                    </Inline>
                                                                </Box>
                                                            </Boxed>
                                                        )}
                                                    />

                                                    <RadioButton
                                                        value={process.env.REACT_APP_TRASLADO_PARCIAL??'-1'}
                                                        render={({ controlElement, checked, labelId }:any) => (
                                                            <Boxed className={'rbx-option '+ (checked ? 'active' : '')}>
                                                                <Box padding={16}>
                                                                    <Inline space={8} alignItems='center'>
                                                                        {controlElement}
                                                                        <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_transfer_partial_transfer'})}</Text2>
                                                                    </Inline>
                                                                </Box>
                                                            </Boxed>
                                                        )}
                                                    />
                                                </Grid>
                                            </RadioGroup>
                                        </Stack>
                                    </Grid>
                                </Stack>

                                {/* Rbx reembolso parcial */}
                                {formData.transferTypeParent===(process.env.REACT_APP_TRASLADO_PARCIAL??'-1') &&
                                    <Stack space={16}>
                                        <Text4 medium>{intl.formatMessage({id:'page_operate_transfer_partial_transfer'})}</Text4>

                                        <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                            <Stack space={16}>
                                                <RadioGroup name="transferType" value={formData.transferType} onChange={(val:any)=> handleChangeAnyField('transferType', val)}>
                                                    <Inline space={16}>
                                                        <RadioButton
                                                            value={process.env.REACT_APP_TRASLADO_PARCIAL_IMPORTE??'1'}
                                                            render={({ controlElement, checked, labelId }:any) => (
                                                                <Inline space={8} alignItems='center'>
                                                                    {controlElement}
                                                                    <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_transfer_for_amount'})}</Text2>
                                                                </Inline>
                                                            )}
                                                        />

                                                        <RadioButton
                                                            value={process.env.REACT_APP_TRASLADO_PARCIAL_UNIDADES??'2'}
                                                            render={({ controlElement, checked, labelId }:any) => (
                                                                <Inline space={8} alignItems='center'>
                                                                    {controlElement}
                                                                    <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_transfer_for_units'})}</Text2>
                                                                </Inline>
                                                            )}
                                                        />
                                                    </Inline>
                                                </RadioGroup>

                                                {/* Rbx por importe */}
                                                {formData.transferType===(process.env.REACT_APP_TRASLADO_PARCIAL_IMPORTE) &&
                                                    <DecimalField maxDecimals={2} fullWidth error={handleError('amount')!==null} helperText={handleError('amount')??undefined} name="amount" label={'Importe'} onChangeValue={(val:any)=> handleChangeAnyField('amount', val)}  />
                                                }

                                                {/* Rbx por unidades */}
                                                {formData.transferType===(process.env.REACT_APP_TRASLADO_PARCIAL_UNIDADES) &&

                                                    <DecimalField maxDecimals={5} fullWidth error={handleError('amount')!==null} helperText={handleError('amount')??undefined} name="amount" label={'Unidades'} onChangeValue={(val:any)=> handleChangeAnyField('amount', val)}  />
                                                }
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                }

                                <Stack space={24}>
                                    <Stack space={8}>
                                        <Text4 medium>{intl.formatMessage({id:'page_operate_transfer_origin_fund'})}</Text4>

                                        <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_transfer_origin_fund_description'})}</Text1>
                                    </Stack>

                                    <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                        <RadioGroup name="originType" value={formData.originType} onChange={(val:any)=> handleChangeAnyField('originType', val)}>
                                            <Inline space={16}>
                                                <RadioButton
                                                    value={process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO??'E'}
                                                    render={({ controlElement, checked, labelId }:any) => (
                                                        <Inline space={8} alignItems='center'>
                                                            {controlElement}
                                                            <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_transfer_another_entity'})}</Text2>
                                                        </Inline>
                                                    )}
                                                />

                                                {contractsUser && contractsUser.length>0 &&
                                                    <RadioButton
                                                        value={process.env.REACT_APP_TRASLADO_ORIGEN_FONDITEL??'F'}
                                                        render={({ controlElement, checked, labelId }:any) => (
                                                            <Inline space={8} alignItems='center'>
                                                                {controlElement}
                                                                <Text2 regular color={checked ? skinVars.colors.brand : 'currentColor'}>{intl.formatMessage({id:'page_operate_transfer_fonditel'})}</Text2>
                                                            </Inline>
                                                        )}
                                                    />
                                                }
                                            </Inline>
                                        </RadioGroup>
                                    </Grid>

                                    {/* Rbx otra entidad */}
                                    {formData.originType===(process.env.REACT_APP_TRASLADO_ORIGEN_EXTERNO??'E') &&
                                        <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                            <Stack space={16}>
                                                <Stack space={4}>
                                                    <Select
                                                        fullWidth
                                                        label={intl.formatMessage({id:'page_operate_transfer_manager'})}
                                                        name="cdExternalPromoter"
                                                        value={formData.cdExternalPromoter}
                                                        error={handleError('cdExternalPromoter')!==null} helperText={handleError('cdExternalPromoter')??undefined}
                                                        onChangeValue={(val:any)=> handleChangeAnyField('cdExternalPromoter', val)}
                                                        options={planManagersOptions.map(item => ({text:item.nombre, value:item.id}))}
                                                    />

                                                    <>
                                                        {!screenSize.isDesktopOrBigger &&
                                                            <Box paddingX={8}>
                                                                <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_transfer_manager_description'})}</Text1>
                                                            </Box>
                                                        }
                                                    </>
                                                </Stack>

                                                <Select
                                                    fullWidth
                                                    label={intl.formatMessage({id:'page_operate_transfer_origin_fund'})}
                                                    name="cdExternalFund"
                                                    value={formData.cdExternalFund}
                                                    disabled={!externalFundsOptions || externalFundsOptions.length===0}
                                                    error={handleError('cdExternalFund')!==null} helperText={handleError('cdExternalFund')??undefined}
                                                    onChangeValue={(val:any)=> handleChangeAnyField('cdExternalFund', val)}
                                                    options={externalFundsOptions.map(item => ({text:item.nombre, value:item.id}))}
                                                />

                                                <TextField
                                                    fullWidth
                                                    error={handleError('numAccount')!==null}
                                                    helperText={handleError('numAccount')??undefined}
                                                    name="numAccount"
                                                    onChangeValue={(val:any)=> handleChangeAnyField('numAccount', val)}
                                                    value={formData.numAccount}
                                                    label={intl.formatMessage({id:'page_operate_transfer_participe_number_account'})}
                                                    maxLength={10}
                                                />

                                                <TextField
                                                    fullWidth
                                                    error={handleError('isin')!==null}
                                                    helperText={handleError('isin')??undefined}
                                                    name="isin"
                                                    onChangeValue={(val:any)=> handleChangeAnyField('isin', val)}
                                                    value={formData.isin}
                                                    label={intl.formatMessage({id:'page_operate_transfer_isin'})}
                                                    maxLength={12}
                                                />
                                            </Stack>

                                            <>
                                                {screenSize.isDesktopOrBigger &&
                                                    <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_transfer_manager_description'})}</Text1>
                                                }
                                            </>
                                        </Grid>
                                    }


                                    {/* Rbx Fondos de inversión */}
                                    {formData.originType===(process.env.REACT_APP_TRASLADO_ORIGEN_FONDITEL??'F') && contractsUser.length>0 &&
                                        <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                            <Stack space={16}>
                                                <Select
                                                    fullWidth
                                                    label={intl.formatMessage({id:'page_operate_transfer_inversment_fund'})}
                                                    name="cdFonditelContract"
                                                    value={formData.cdFonditelContract}
                                                    error={handleError('cdFonditelContract')!==null} helperText={handleError('cdFonditelContract')??undefined}
                                                    onChangeValue={(val:any)=> handleChangeAnyField('cdFonditelContract', val)}
                                                    options={contractsUser.map(item => ({text:item.plan?.descripcion ?? '', value:item.id??''}))}
                                                />
                                            </Stack>
                                        </Grid>
                                    }
                                </Stack>

                            </Stack>
                        </Box>

                    </ButtonFixedFooterLayout>
                </ResponsiveLayout>

            </Form>
        </>}</>
    );
};

export default TransferInvestmentFunds;