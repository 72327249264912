import React, { useEffect } from 'react';

import { useScreenSize, Tooltip, Box, Boxed, Inline, Text5, IconInformationUserRegular, Text2, skinVars, Select, LoadingBar, SkeletonRectangle } from '@telefonica/mistica';

import './PortfolioComposition.scss';

import {
    ArcElement,
    Chart as ChartJS ,
    Tick,
    Tooltip as TooltipChart
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { Product } from '../../entities/products/product';
import {  PortfolioComposition as PortComp } from '../../entities/products/portfolio-composition';
import { formatBalance, formatDate } from '../../utils/utils';
import { getWalletCompositionProduct } from '../../utils/apiclient';


  ChartJS.register(ArcElement, TooltipChart);


interface PortfolioCompositionProps {
  product: Product | null,
  loading?: boolean,
  withoutBoxed?: boolean
}

const PortfolioComposition: React.FC<PortfolioCompositionProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const [loading, setLoading] = React.useState(false);
    const [currentDate, setCurrentDate]= React.useState('' as string);
    const [labelsGraphic, setLabelsGraphic]= React.useState([] as string[]);
    const [datasGraphic, setDatasGraphic]= React.useState([] as number[]);

    useEffect(()=>{
      if(prop.product?.fechasComposicionCartera && prop.product?.fechasComposicionCartera.length>0){
        setCurrentDate(formatDate(prop.product?.fechasComposicionCartera[0]));
      }

      updatePortfolioComposition(prop.product?.composicionCartera ?? null);
    },[prop.product]);

    const updateDatePortfolioComposition= (date:string) => {
      if(date!=currentDate){
        setLoading(true);
        setCurrentDate(date);
        if(prop.product && prop.product.cdCompania && prop.product.cdFondo && prop.product.cdPlan && prop.product.cdSubplan){
          getWalletCompositionProduct({cdCompany:prop.product.cdCompania, cdFund:prop.product.cdFondo, cdPlan:prop.product.cdPlan, cdSubplan:prop.product.cdSubplan, date: date }).then((portComp)=>{
            updatePortfolioComposition(portComp);
          }).finally(()=>{
            setLoading(false);
          })
        }
      }
    }

    const updatePortfolioComposition = (portfolioComposition : Array<PortComp> | null) =>{
      let labels:string[] = [];
      let datas:number[] = [];
      if(portfolioComposition){
        portfolioComposition.forEach(item=>{
          if(item.texto){
            labels.push(item.texto);
          }
          if(item.valor && item.valor?.length>0){
            const exposicion = item.exposicion && item.exposicion?.length>0 ? item.exposicion : '0';
            datas.push(parseFloat(item.valor)+parseFloat(exposicion));
          }
        });
      }
      setLabelsGraphic(labels);
      setDatasGraphic(datas);
    }

    const data = {
        labels: labelsGraphic,
        datasets: [
          {
            label: '',
            data: datasGraphic,
            backgroundColor: [
              '#0066FF',
              '#E7C2F8',
              '#F5E98A',
              '#E3A19A',
              '#67E0E5',
            ],
            hoverOffset: 4
          },
        ],
      };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: (screenSize.isDesktopOrBigger ? 'right' : 'bottom')  as any
          },
        },
        transitions: {
          active: {
            animation: {
              duration: 0
            }
          }
        }
      };

    return (<>{prop.loading ?
                <Box paddingY={12}>
                  <SkeletonRectangle height={prop.withoutBoxed ? 248 : 641} />
                </Box>
              :
                prop.product && datasGraphic.length>0 ?
                <>
                  <LoadingBar visible={loading}></LoadingBar>
                  {prop.withoutBoxed ?
                    <Box className="portfolio-composition-chart" paddingX={screenSize.isDesktopOrBigger ? 80 : 16} paddingBottom={screenSize.isDesktopOrBigger ? 40 : 16}>
                      <Doughnut data={data} options={options} />
                    </Box>
                  :
                    <Box paddingBottom={24} className={'doughnutGraph--content '+(loading ? 'loading': '')}>
                      <Boxed>
                        <Box paddingX={screenSize.isDesktopOrBigger ? 64 : 12} paddingY={screenSize.isDesktopOrBigger ? 48 : 16}>
                          <Box paddingBottom={48}>
                            <Inline space={8} alignItems='center'>
                              <Text5>{intl.formatMessage({id:'page_investment_funds_customer_portfolio_composition_title'})}</Text5>
                              <Tooltip
                                targetLabel="Información"
                                target={<IconInformationUserRegular size={22} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                description=""
                                position="top"
                                extra={
                                  <Inline space={'evenly'}>
                                    <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_investment_funds_customer_portfolio_composition_description'})}</Text2>
                                  </Inline>
                                }
                              />
                            </Inline>
                          </Box>

                          <Box className="portfolio-composition-chart" paddingBottom={screenSize.isDesktopOrBigger ? 40 : 16}>
                            <Doughnut data={data} options={options} />
                          </Box>

                          {prop.product?.fechasComposicionCartera &&
                            <Box className='selectBox'>
                              <Select
                                fullWidth
                                name="updatedData"
                                label={intl.formatMessage({id:'page_investment_funds_customer_portfolio_composition_data_update_to'})}
                                value={currentDate}
                                onChangeValue={value=>updateDatePortfolioComposition(value)}
                                options={prop.product?.fechasComposicionCartera?.map(item => ({text:formatDate(item), value:formatDate(item)})) ?? [] }
                              />
                            </Box>
                          }
                        </Box>
                      </Boxed>
                    </Box>
                  }
                </>
              :
                <></>
            }</>);
};

export default PortfolioComposition;
