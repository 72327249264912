import { Box, ButtonLink, DataCard, dialog, Divider, Grid, IconArrowLineRightRegular, IconArrowsLeftAndRightRegular, IconCheckedLight, IconDollarSymbolCircleRegular, IconFileErrorRegular, IconThrowLightRegular, LoadingBar, ResponsiveLayout, skinVars, Stack, Stepper, Text4, Text5, useScreenSize, useWindowSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';

import { useIonRouter } from '@ionic/react';
import moment from 'moment';
import { useEffect, useLayoutEffect, useState } from 'react';
import slugify from 'react-slugify';
import { useDigitalSign } from '../../../../../components/digital-sign/DigitalSign';
import ConfirmOperation from '../../../../../components/operate/ConfirmOperation';
import ConfirmPersonalData from '../../../../../components/operate/ConfirmPersonalData';
import ContributionPensionPlan from '../../../../../components/operate/ContributionPensionPlan';
import MobilizePensionPlan from '../../../../../components/operate/MobilizePensionPlan';
import SummaryOperate from '../../../../../components/operate/SummaryOperate';
import { useValidOperate } from '../../../../../components/operate/ValidOperate';
import { Contract } from '../../../../../entities/contract/contract';
import { OperationContract } from '../../../../../entities/contract/operations/operation-contract';
import { OperationContractPlan } from '../../../../../entities/contract/operations/operation-contract-plan';
import { OperationContribution } from '../../../../../entities/contract/operations/operation-contribution';
import { OperationMobilization } from '../../../../../entities/contract/operations/operation-mobilization';
import { addContractPlan, trackStadistics } from '../../../../../utils/apiclient';
import { getUrlNavigation } from '../../../../../utils/navigation';
import { addNumberCourseOperationsCache, getContinueToContract, getCurrentProductToContract, getCurrentUserContract, getCurrentUserData, getOperationContract, getOperationContribution, getOperationKey, getOperationMobilization, getTokenSign, removeContinueToContract, removeCurrentUserContract, removeOperationContribution, removeOperationKey, removeOperationMobilization, removePlansNotContractedCache, removeTokenSign, setContractUrl } from '../../../../../utils/storage';
import { useToast } from '../../../../../utils/toast';
import { formatProduct, scrollToTop, trackEvent } from '../../../../../utils/utils';
import OperationsInProgressCustomerPage from '../operations-in-progress/OperationsInProgressCustomerPage';
import { EmploymentPlansCustomerPage, IndividualPlansCustomerPage, SimplifiedEmploymentPlansCustomerPage } from './PensionPlansCustomerPage';
import SignContractSimplifiedEmploymentPlansCustomerPage from './SignContractSimplifiedEmploymentPlansCustomerPage';

export interface ContractPensionPlansCustomerPageProps {
    type: string
}


const ContractPensionPlansCustomerPage: React.FC<ContractPensionPlansCustomerPageProps> = (prop) => {

  const { showToast } = useToast()
  const intl = useIntl();
  const screenSize = useScreenSize();
  const size = useWindowSize();
  const currentProductToContract = getCurrentProductToContract();
  const currentUserContract =  getCurrentUserContract();
  const currentUser = getCurrentUserData();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingOperation, setLoadingOperation] = useState(null as null|string);
  const router = useIonRouter();
  const [title, setTitle] = useState('');
  const [operations, setOperations] = useState([] as number[]);
  const [currentOperation, setCurrentOperation] = useState(null as null|number);
  const [contract, setContract] = useState(null as null|Contract);
  const [operationContribution, setOperationContribution] = useState(null as null|OperationContribution);
  const [operationMobilization, setOperationMobilization] = useState(null as null|OperationMobilization);
  const [operationContract, setOperationContract] = useState(null as null|OperationContract);
  const { digitalSign } = useDigitalSign();

  const [parentTitle, setParentTitle] = useState('');
  const parentPage = (prop.type===process.env.REACT_APP_PLAN_PENSIONES_EMPRESA ? EmploymentPlansCustomerPage : (prop.type===process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL ? IndividualPlansCustomerPage : SimplifiedEmploymentPlansCustomerPage));


  //Stepper
  const [step, setStep] = useState(0);
  const [options, setOptions] = useState([] as string[]);

  const { validOperate } = useValidOperate();
  const contributionId = parseInt(process.env.REACT_APP_MENU_PLANES_HACER_APORTACION ?? '11');
  const mobilizationId = parseInt(process.env.REACT_APP_MENU_PLANES_MOVILIZAR ?? '12');


    useLayoutEffect(()=>{
        setTitle(intl.formatMessage({id:'page_contract_pension_plans_contract'})+' '+currentProductToContract?.descripcion);

        let opts = [];
        opts.push(intl.formatMessage({id:'page_contract_pension_plans_step_your_data'}));
        opts.push(intl.formatMessage({id:'page_contract_pension_plans_step_operative'}));
        opts.push(intl.formatMessage({id:'page_contract_pension_plans_step_operations_key'}));

        setOptions(opts);

        switch(prop.type){
            case process.env.REACT_APP_PLAN_PENSIONES_EMPRESA:
                setParentTitle(intl.formatMessage({id:'page_customer_pension_plans_employment_plans_title'}));
                break;
            case process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL:
                setParentTitle(intl.formatMessage({id:'page_customer_pension_plans_individual_plans_title'}));
                break;
            case process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS:
                setParentTitle(intl.formatMessage({id:'page_customer_pension_plans_simplified_employment_plans_title'}));
                break;
        }
    }, []);

    useEffect(()=>{
        if(!router.canGoBack() || !currentProductToContract || !currentUserContract || !currentUser){
            router.push(getUrlNavigation(parentPage));
            return;
        }
        setContract(currentUserContract);
    }, []);

    const confirmData = ()=>{
        scrollToTop();

        setOperationContract(getOperationContract());

        setStep(step+1)
    }

    const handleOperation = (operation:string): Promise<void> =>
        new Promise((resolve) => {
            setLoadingOperation(operation);

            switch(operation){
                case process.env.REACT_APP_MENU_PLANES_HACER_APORTACION:
                    trackEvent('perfil-plan-'+formatProduct(currentProductToContract)+'-contratar-aportacion');
                    setOperations([contributionId])
                    setCurrentOperation(contributionId);
                    break;
                case process.env.REACT_APP_MENU_PLANES_MOVILIZAR:
                    trackEvent('perfil-plan-'+formatProduct(currentProductToContract)+'-contratar-movilizacion');
                    setOperations([mobilizationId])
                    setCurrentOperation(mobilizationId);
                    break;
                case (process.env.REACT_APP_MENU_PLANES_HACER_APORTACION+'_'+process.env.REACT_APP_MENU_PLANES_MOVILIZAR):
                    trackEvent('perfil-plan-'+formatProduct(currentProductToContract)+'-contratar-aportacion_movilizacion');
                    setOperations([mobilizationId, contributionId])
                    setCurrentOperation(mobilizationId);
                    break;
            }
            setLoadingOperation(null);
            resolve();
        });

    const handleCancelOperation= (operation:string): Promise<void> =>
        new Promise((resolve) => {
            setLoadingOperation(operation);

            switch(operation){
                case process.env.REACT_APP_MENU_PLANES_HACER_APORTACION:
                    removeOperationContribution();
                    if(operations.length>1){
                        setCurrentOperation(mobilizationId);
                    }else{
                        setOperations([])
                        setCurrentOperation(null);
                    }
                    break;
                case process.env.REACT_APP_MENU_PLANES_MOVILIZAR:
                    removeOperationMobilization();
                    setOperations([])
                    setCurrentOperation(null);
                    break;
            }
            setLoadingOperation(null);
            resolve();
        });

    const summaryOperation = (operation:string)=>{
        scrollToTop();
        switch(operation){
            case process.env.REACT_APP_MENU_PLANES_HACER_APORTACION:
                setOperationContribution(getOperationContribution());
                setStep(step+1)
                break;
            case process.env.REACT_APP_MENU_PLANES_MOVILIZAR:
                setOperationMobilization(getOperationMobilization());
                if(operations.length>1){
                    setCurrentOperation(contributionId);
                }else{
                    setStep(step+1)
                }
                break;
        }
    }

    const confirmOperationDigitalSign= (): Promise<void> =>
        new Promise((resolve) => {
            setLoadingSave(true);

            let contractPlan : OperationContractPlan={
                ...operationContract
            };
            const dateSign = moment().unix();
            if(operationContribution){
                contractPlan={
                    ...contractPlan,
                    ...operationContribution,
                    amountContribution : operationContribution.amount
                };
            }

            if(operationMobilization){
                contractPlan={
                    ...contractPlan,
                    ...operationMobilization,
                    amountTransfer : operationMobilization.amount,
                };
            }
            contractPlan={
                ...contractPlan,
                dateSign:dateSign,
                electronic:true,
                confirm:true
            };
            addContractPlan(contractPlan).then((result)=>{

                if(result.tokenOperation){
                    digitalSign(result.tokenOperation
                    , ()=>{
                            confirmOperation(result.tokenOperation, dateSign);
                            resolve();
                    }, ()=>{
                        setLoadingSave(false);
                        resolve();
                    });
                }

            }).finally(()=>{
            })
        });

    const confirmOperation = (tokenOperation:string|null=null, dateSign:number|null=null)=>{
        setLoadingSave(true);
        const operationsKey = getOperationKey();
        const tokenSign = getTokenSign();
        const continueToContract = getContinueToContract();

        if(operationsKey || tokenSign || continueToContract){
            let contractPlan : OperationContractPlan={
                ...operationContract
            };
            if(operationContribution){
                contractPlan={
                    ...contractPlan,
                    ...operationContribution,
                    amountContribution : operationContribution.amount
                };
            }

            if(operationMobilization){
                contractPlan={
                    ...contractPlan,
                    ...operationMobilization,
                    amountTransfer : operationMobilization.amount,
                };
            }
            contractPlan={
                ...contractPlan,
                operationsKey:operationsKey ? operationsKey : undefined,
                dateSign: dateSign ? dateSign : undefined,
                tokenSign: tokenSign ? tokenSign : undefined,
                tokenOperation: tokenOperation ? tokenOperation : undefined,
                confirm:true,
                electronic: tokenOperation && tokenSign ? true : false,
                continueToContract: continueToContract ? true : undefined
            };

            addContractPlan(contractPlan).then((result)=>{
                if(result.result){
                    addNumberCourseOperationsCache();
                    removeOperationContribution();
                    removeOperationKey();
                    removeTokenSign();
                    removePlansNotContractedCache();
                    removeCurrentUserContract();
                    removeContinueToContract();
                    trackStadistics('CONFIRMED_OPERATION', undefined, undefined, 'Planes de pensiones - Contratación - Solicitud de contratación procesada con éxito.', 'OPERACION_CONFIRMADA' );
                    if(result.urlSignature){
                        setContractUrl(result.urlSignature);
                        router.push(getUrlNavigation(SignContractSimplifiedEmploymentPlansCustomerPage));
                        return;
                    }else{
                        dialog({
                            icon:<IconCheckedLight color={skinVars.colors.brand} />,
                            title: intl.formatMessage({id:'page_contract_pension_plans_add_contract_plan_title_ok'}),
                            acceptText: intl.formatMessage({id:'page_contract_pension_plans_add_contract_plan_button_ok'}),
                            message: "",
                            onAccept: ()=>{ router.push(getUrlNavigation(OperationsInProgressCustomerPage)); }
                        });
                    }
                    return;
                }

                dialog({
                    icon:<IconFileErrorRegular color={skinVars.colors.textLinkDanger} />,
                    title: intl.formatMessage({id:'page_contract_pension_plans_add_contract_plan_title_ko'}),
                    acceptText: intl.formatMessage({id:'page_contract_pension_plans_add_contract_plan_button_ko'}),
                    message: ""
                });
                return;
            }).finally(()=>{
                setLoadingSave(false);
            });
        }
    }


  return (
        <><LoadingBar visible={loading} />

            <LayoutPrivateArea
                seo={{metaTitle: title}}
                title={intl.formatMessage({id:'page_contract_pension_plans_title'})}
                titleResponsive={title}
                parents={[{item:parentPage, title: parentTitle}]}
                pageInfo={{option: process.env.REACT_APP_MENU_PLANES_PENSIONES, role:'client'}}
                showBack>


                <ResponsiveLayout className={'contribution--content '+ (screenSize.isDesktopOrBigger ? 'inner first' : 'fullscreen fixed padding--none')} backgroundColor={!screenSize.isDesktopOrBigger ? skinVars.colors.backgroundAlternative : 'transparent'}>
                    {screenSize.isDesktopOrBigger &&
                        <Box paddingBottom={32}>
                            <Text5>{title}</Text5>
                        </Box>
                    }

                    <Box className="stepper--operations" paddingBottom={screenSize.isDesktopOrBigger ? 32 : 0}>
                        <div className='stepper'>
                            <Stepper
                                currentIndex={step}
                                steps={options}
                            />
                        </div>
                        <Divider />
                    </Box>
                </ResponsiveLayout>

                {options[step]===intl.formatMessage({id:'page_contract_pension_plans_step_your_data'}) &&
                    <ResponsiveLayout className='inner'>
                        <Stack space={32}>
                            <Text5 color={skinVars.colors.brand}>
                                {intl.formatMessage({id:'page_contract_pension_plans_confirm_your_data'})}
                            </Text5>

                            <ConfirmPersonalData inProcessContract={true} loading={loadingSave} contract={contract} client={currentUser ? currentUser : undefined} onCancel={()=>{setStep(step-1)}} onConfirm={()=>{confirmData()}} />
                        </Stack>
                    </ResponsiveLayout>
                }

                {options[step]===intl.formatMessage({id:'page_contract_pension_plans_step_operative'}) &&
                    <ResponsiveLayout className='inner'>
                        {!currentOperation ?
                            <Stack space={32}>
                                <Text5 color={skinVars.colors.brand}>
                                    {intl.formatMessage({id:'page_contract_pension_plans_operational'})}
                                </Text5>
                                <Text4 regular>{intl.formatMessage({id:'page_contract_pension_plans_what_you_want'})}</Text4>


                                <Grid columns={size.width > 768 ? 3 : 1} gap={16}>
                                        <DataCard
                                            icon={
                                                <IconDollarSymbolCircleRegular size={32} color={skinVars.colors.brand} />
                                            }
                                            title={intl.formatMessage({id:'page_contract_pension_plans_make_contribution_title'})}
                                            description={intl.formatMessage({id:'page_contract_pension_plans_make_contribution_description'})}
                                            buttonLink={
                                                <ButtonLink showSpinner={loadingOperation===process.env.REACT_APP_MENU_PLANES_HACER_APORTACION} onPress={()=>{handleOperation(process.env.REACT_APP_MENU_PLANES_HACER_APORTACION ?? '11')}}>
                                                    {intl.formatMessage({id:'page_contract_pension_plans_start'})}
                                                    <IconArrowLineRightRegular color="currentColor" />
                                                </ButtonLink>
                                            }
                                        />

                                        <DataCard
                                            icon={
                                                <IconThrowLightRegular size={32} color={skinVars.colors.brand} />
                                            }
                                            title={intl.formatMessage({id:'page_contract_pension_plans_make_mobilization_title'})}
                                            description={intl.formatMessage({id:'page_contract_pension_plans_make_mobilization_description'})}
                                            buttonLink={
                                                <ButtonLink showSpinner={loadingOperation===process.env.REACT_APP_MENU_PLANES_MOVILIZAR} onPress={()=>{handleOperation(process.env.REACT_APP_MENU_PLANES_MOVILIZAR ?? '12')}}>
                                                    {intl.formatMessage({id:'page_contract_pension_plans_start'})}
                                                    <IconArrowLineRightRegular color="currentColor" />
                                                </ButtonLink>
                                            }
                                        />

                                        <DataCard
                                            icon={
                                                <IconArrowsLeftAndRightRegular size={32} color={skinVars.colors.brand} />
                                            }
                                            title={intl.formatMessage({id:'page_contract_pension_plans_make_contribution_mobilization_title'})}
                                            description={intl.formatMessage({id:'page_contract_pension_plans_make_contribution_mobilization_description'})}
                                            buttonLink={
                                                <ButtonLink showSpinner={loadingOperation===(process.env.REACT_APP_MENU_PLANES_HACER_APORTACION+'_'+process.env.REACT_APP_MENU_PLANES_MOVILIZAR)} onPress={()=>{handleOperation((process.env.REACT_APP_MENU_PLANES_HACER_APORTACION??'11')+'_'+(process.env.REACT_APP_MENU_PLANES_MOVILIZAR??'12'))}}>
                                                    {intl.formatMessage({id:'page_contract_pension_plans_start'})}
                                                    <IconArrowLineRightRegular color="currentColor" />
                                                </ButtonLink>
                                            }
                                        />
                                    </Grid>
                            </Stack>
                        :
                            <>
                            {currentOperation===contributionId &&
                                <ContributionPensionPlan loading={loading} contract={contract} onCancel={()=>{ handleCancelOperation((process.env.REACT_APP_MENU_PLANES_HACER_APORTACION??'11')) }} onConfirm={()=>{summaryOperation((process.env.REACT_APP_MENU_PLANES_HACER_APORTACION??'11'));}} />
                            }
                            {currentOperation===mobilizationId &&
                                <MobilizePensionPlan loading={loading} contract={contract} onCancel={()=>{ handleCancelOperation((process.env.REACT_APP_MENU_PLANES_MOVILIZAR??'12')) }} onConfirm={()=>{summaryOperation((process.env.REACT_APP_MENU_PLANES_MOVILIZAR??'12'));}} />
                            }
                            </>
                        }
                    </ResponsiveLayout>
                }

                {options[step]===intl.formatMessage({id:'page_contract_pension_plans_step_operations_key'}) &&
                    <ResponsiveLayout className='inner new-contract-plan--content'>
                        <Stack space={32}>
                            <Text5 color={skinVars.colors.brand}>
                                {intl.formatMessage({id:'page_contract_pension_plans_open_new_contract'})}
                            </Text5>


                            {operationContribution &&
                                <SummaryOperate key={'data_contribution'}
                                    title={intl.formatMessage({id:'page_contract_pension_plans_contribution'})}
                                    values={operationContribution.summary}
                                />
                            }
                            {operationMobilization &&
                                <SummaryOperate key={'data_mobilization'}
                                    title={intl.formatMessage({id:'page_contract_pension_plans_transfer'})}
                                    values={operationMobilization.summary} />
                            }

                            <ConfirmOperation inProcessContract={true} loading={loadingSave} contract={contract} onCancel={()=>{}} onConfirm={()=>{confirmOperation()}} onSign={()=>confirmOperationDigitalSign()} />

                        </Stack>
                    </ResponsiveLayout>

                }

            </LayoutPrivateArea>
        </>
    );
};

export default ContractPensionPlansCustomerPage;


export const ContractIndividualPlansCustomerPage: React.FC = () => {
    return (<ContractPensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL ?? "I"} />);
};

export const ContractSimplifiedEmploymentPlansCustomerPage: React.FC = () => {
    return (<ContractPensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS ?? "S"} />);
};

export const ContractEmploymentPlansCustomerPage: React.FC = () => {
    return (<ContractPensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_EMPRESA ?? "E"}  />);
};