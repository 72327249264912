import { App as AppCapacitor } from '@capacitor/app';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ReactGA from "react-ga4";


// Import Mistica styles. Depending on the bundler you use, you may need to import it in a different way.
import '@telefonica/mistica/css/mistica.css';
import '@telefonica/mistica/css/reset.css';
//import '@telefonica/mistica/css/roboto.css';

import './theme/telefonica-sans.css';

/* Theme variables */
import './theme/global.scss';
import './theme/variables.css';

// Use mistica components

import { getInitContent, getProvisionalTexts, getStatus } from './utils/apiclient';
import { registerPush } from './utils/notifications';
import { deleteCache, getInitContentCache, getTextsCache, removeFirstBiometricLogin, removeUser, setVersionApp } from './utils/storage';
import { isBot, isNativeApp, trackError } from './utils/utils';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { FirebasePerformance } from '@capacitor-firebase/performance';
import { getCurrentLang } from './utils/locate';

let container = document.getElementById('root');
let root = createRoot(container!);

try{
    if(isNativeApp){
        FirebaseCrashlytics.setEnabled({
            enabled: true,
        });
        FirebasePerformance.setEnabled({
            enabled: true,
        });
    }
}catch(err){}

if(!isBot){
    root.render(<App messages={getProvisionalTexts()} loaded={false} lockapp={false} newVersion={false}  />);
}


const initApp = ()=>{

    const preview = window.location.search.toLowerCase().indexOf('preview=true')!==-1;

    if(preview){
        deleteCache()
    }

    getStatus().then((data)=>{
        if((data?.maintenance ?? false)){
            let messages = getProvisionalTexts();
            root.render(<App messages={messages} loaded={true} lockapp={false} newVersion={false} />);
        }else{
            let dataContent = preview ? null : getInitContentCache(getCurrentLang());

            const lockapp : boolean = data!=null && data.message!=undefined && data.blockApp!=undefined && data.blockApp===true;
            const newVersion : boolean = data!=null && data.message!=undefined;
            const message : string|undefined = data!=null && data.message!=undefined ? data.message : undefined;

            let messages = preview ? null : getTextsCache();
            if(data && data.updateTexts && messages && dataContent){
                messages = data.messages;
                getInitContent(false).then(()=>{});
                root.render(<App messages={messages} loaded={true} lockapp={lockapp} newVersion={newVersion} message={message} />);
            }else{
                if(messages && dataContent){
                    root.render(<App messages={messages} loaded={true} lockapp={lockapp} newVersion={newVersion} message={message}  />);
                }else{
                    let updateTexts = false;
                    if(data && data.updateTexts){
                        updateTexts=true;
                        messages = data.messages;
                    }
                    getInitContent(preview).then((dataIC)=>{
                        root.render(<App messages={updateTexts && !dataIC.messages ? messages : (dataIC.messages ?? messages)} loaded={true} lockapp={lockapp} newVersion={newVersion} message={message} />);
                    });
                }
            }
        }
    });
}
try{
    if(isNativeApp){
        removeUser();
        registerPush();
        removeFirstBiometricLogin();
        AppCapacitor.getInfo().then((info)=>{
            setVersionApp(info.version);
            initApp();
        });
    }else{
        ReactGA.initialize([{trackingId : process.env.REACT_APP_GOOGLE_ANALYTICS ?? '' }]);
        initApp();
    }
}catch(err){
    trackError(JSON.stringify(err));
}




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();