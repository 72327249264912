import { useScreenSize } from '@telefonica/mistica';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { OperationInProgress } from '../../entities/operationsInProgress/operation-in-progress.model';
import OperationInProgressDetail from './OperationInProgressDetail';


interface OperationInProgressSubscriptionDetailProps {
    operation: OperationInProgress
}

const OperationInProgressSubscriptionDetail: React.FC<OperationInProgressSubscriptionDetailProps> = (prop) => {

    const intl = useIntl();
    const screenSize = useScreenSize();
    const [title, setTitle] = useState('' as string);
    const [items, setItems] = useState([] as Array<{title:string, value:string}>);

    useEffect(()=>{
        if(prop.operation){
            const operation = prop.operation;

            setTitle(intl.formatMessage({ id: 'page_operations_in_progress_customer_subscription_detail' }));

            let itemValues: Array<{title:string, value:string}> = [];
            if(!screenSize.isDesktopOrBigger){
                itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_product' }), value: operation.detalle.producto});
                itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_contract' }), value: operation.detalle.numContrato});
            }

            itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_amount' }), value: operation.detalle.importeOperacion});
            itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_way_to_pay' }), value:
                      operation.formaPago===process.env.REACT_APP_SUSCRIPCION_FORMA_PAGO_TRANSFERENCIA ? intl.formatMessage({ id: 'page_operations_in_progress_customer_way_to_pay_transfer' })
                    : operation.formaPago===process.env.REACT_APP_SUSCRIPCION_FORMA_PAGO_CHEQUE ? intl.formatMessage({ id: 'page_operations_in_progress_customer_way_to_pay_check' }) : ''
            });

            setItems(itemValues);
        }

    },[]);

    return (<OperationInProgressDetail title={title} items={items} numColumns={2} />);
};

export default OperationInProgressSubscriptionDetail;
