import { useScreenSize } from '@telefonica/mistica';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Periodicity } from '../../entities/commons/periodicity.model';
import { OperationInProgress } from '../../entities/operationsInProgress/operation-in-progress.model';
import { getPeriodicities } from '../../utils/apiclient';
import { formatAmount, formatNetAssetValue } from '../../utils/utils';
import OperationInProgressDetailContract from './OperationInProgressDetailContract';


interface OperationInProgressContractPensionPlanProps {
    operation: OperationInProgress
}

const OperationInProgressContractPensionPlan: React.FC<OperationInProgressContractPensionPlanProps> = (prop) => {

    const intl = useIntl();
    const screenSize = useScreenSize();
    const [items, setItems] = useState([] as Array<{title:string, items:Array<{title:string, value:string}>}>);
    const [periodicitiesOptions, setPeriodicitiesOptions] = React.useState([] as Periodicity[]);

    useEffect(()=>{
        getPeriodicities().then(options=>{
            setPeriodicitiesOptions(options.filter(item=>item.cdPeriodicidad!=0));
        });
        if(prop.operation){
            const operation = prop.operation;

            const operations : Array<{title:string, items:Array<{title:string, value:string}>}> = [];


            let contribution = operation.esMultiOperacion ? operation.operacionesAsociadas.Operaciones.find(item=>item.tipoOperacionDetalle===0) : (operation.tipoOperacionDetalle===0 ? operation : null);

            if(contribution){
                let itemContributionValues: Array<{title:string, value:string}> = [];

                if(contribution?.contrato.periodicidadAportacion){
                    if(contribution?.contrato.periodicidadAportacion===(process.env.REACT_APP_APORTACION_UNICA_V ?? 'U')){
                        itemContributionValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_contribution_type' }), value:'Única'});
                    }else{
                        itemContributionValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_contribution_type' }), value:'Periódica'});
                        const periodicity = periodicitiesOptions.find(item=>item.cdPeriodicidad===parseInt(contribution?.contrato.periodicidadAportacion??''));
                        if(periodicity){
                            itemContributionValues.push({title:intl.formatMessage({ id: 'page_operations_in_progress_customer_periodicity' }), value:periodicity.nombreMultiPeriodicidad});
                        }
                    }
                }

                itemContributionValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_amount' }), value: formatAmount(contribution.importeOperacion)+'€'});


                switch(contribution?.contrato.tipoRevalorizacionAportacion){
                    case process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_IPC:
                        itemContributionValues.push({title:intl.formatMessage({ id: 'page_operations_in_progress_customer_revaluation_type3' }), value:intl.formatMessage({id:'page_operate_contribution_annual_revaluation_ipc'})});
                        break;
                    case process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_FIJA:
                        itemContributionValues.push({title:intl.formatMessage({ id: 'page_operations_in_progress_customer_revaluation_type3' }), value: intl.formatMessage({id:'page_operate_contribution_annual_revaluation_fixed'})+': '+formatAmount(contribution?.contrato.porcentRevalorizacionAportacion ?? '')+'%'});
                        break;
                    case process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_SIN:
                        itemContributionValues.push({title:intl.formatMessage({ id: 'page_operations_in_progress_customer_revaluation_type3' }), value:intl.formatMessage({id:'page_operate_contribution_annual_revaluation_without'})});
                        break;
                }

                itemContributionValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_iban' }), value: contribution.cuentaBancaria.IBAN});

                let itemContribution : {title:string, items:Array<{title:string, value:string}>}={
                    title: intl.formatMessage({ id: 'page_operations_in_progress_customer_contribution_detail' }),
                    items: itemContributionValues
                };
                operations.push(itemContribution);
            }


            let mobilization = operation.esMultiOperacion ? operation.operacionesAsociadas.Operaciones.find(item=>item.tipoOperacionDetalle===1) : (operation.tipoOperacionDetalle===1 ? operation : null);

            if(mobilization){
                let itemMobilizationValues: Array<{title:string, value:string}> = [];

                if(mobilization.gestoraOrigen && mobilization.gestoraOrigen.nombre){
                    itemMobilizationValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_manager' }), value: mobilization.gestoraOrigen.nombre});
                }

                if(mobilization.planExterno && mobilization.planExterno.nombre){
                    itemMobilizationValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_plan' }), value: mobilization.planExterno.nombre});
                }else{
                    if(mobilization.companiaFondoPlan && mobilization.companiaFondoPlan.nombre){
                        itemMobilizationValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_manager' }), value: mobilization.companiaFondoPlan.nombre});
                    }
                    if(mobilization.planOrigen && mobilization.planOrigen.descripcion){
                        itemMobilizationValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_plan' }), value: mobilization.codigoContratoOrigen+' - '+mobilization.planOrigen.descripcion});
                    }
                }

                switch(mobilization.tipoMovilizacion){
                    case process.env.REACT_APP_TRASLADO_TOTAL:
                        itemMobilizationValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_transfer_type' }), value: intl.formatMessage({ id: 'page_operations_in_progress_customer_total_transfer' })});
                        break;

                    case process.env.REACT_APP_TRASLADO_PARCIAL_IMPORTE:
                        itemMobilizationValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_amount' }), value: formatAmount(mobilization.importeOperacion)+'€'});
                        break;
                    case process.env.REACT_APP_TRASLADO_PARCIAL_UNIDADES:
                        itemMobilizationValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_units' }), value: formatNetAssetValue(mobilization.UnidadesONumParticip)});
                        break;

                    case process.env.REACT_APP_TRASLADO_PARCIAL_PORCENTAJE:
                        itemMobilizationValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_percentage' }), value: formatAmount(mobilization.porcMovilizacion)+'%'});
                        break;
                }
                let itemMobilization : {title:string, items:Array<{title:string, value:string}>}={
                    title: intl.formatMessage({ id: 'page_operations_in_progress_customer_mobilization_detail' }),
                    items: itemMobilizationValues
                };
                operations.push(itemMobilization);

            }
            setItems(operations);
        }

    },[]);
    return (<OperationInProgressDetailContract operation={prop.operation} items={items} />);
};

export default OperationInProgressContractPensionPlan;
