import { useIonRouter } from '@ionic/react';


import { Box, ButtonPrimary, ButtonSecondary, Carousel, DataCard, DisplayDataCard, Divider, Grid, Hero, IconChatRegular, IconEmailRegular, IconMoneyRiseLight, IconSearchMoneyRegular, IconSmileyHappyRegular, Image, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Text2, Text4, Text5, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import Accordion from '../../../components/accordion/Accordion';
import EmptyStateCustom from '../../../components/empty-state/EmptyStateCustom';
import HeroNoImage from '../../../components/hero/HeroNoImage';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import RiskSmall from '../../../components/product-data/RiskSmall';
import OurAppContainer from '../../../components/staticPages/ourApp/OurApp';
import { Faq } from '../../../entities/contents/faq.model';
import { Product } from '../../../entities/products/product';
import { getFaqs, getProducts } from '../../../utils/apiclient';
import { getUrlNavigation } from '../../../utils/navigation';
import { formatBalance, isBot, scrollToTop, trackEvent } from '../../../utils/utils';
import ContactPage from '../contact/ContactPage';
import CustomizeInvestmentFundPage from '../for-investor/calculators/DecideYourInvestmentCalculatorPage';

const InvestmentFundsPage: React.FC = () => {
  const router = useIonRouter();
  const intl = useIntl()
  const size = useScreenSize();
  const screenSize = useScreenSize();
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [dataCardProducts, setDataCardProducts] = useState([] as JSX.Element[]);
  const [loadingFaqs, setLoadingFaqs] = useState(true);
  const [faqs, setFaqs] = useState([] as Faq[]);


  useEffect(()=>{
    getProducts(parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ?? '3')).then((result)=>{
        if(result){
            fillDataCardProducts(result);
        }
    }).finally(()=>{
      setLoadingProducts(false);
    });


    getFaqs(parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ?? '3'), false, true).then((result)=>{
      if(result){
          setFaqs(result);
      }
    }).finally(()=>{
      setLoadingFaqs(false);
    });


  }, []);

  useEffect(()=>{
    setTimeout(function(){
      if(window.location.hash.length>0){
          scrollToTop(window.location.hash.replace('#', ''));
      }else{
        scrollToTop();
      }
    }, 400);
  }, [window.location.hash]);

  const fillDataCardProducts= (items: Product[]) => {
    let dataCards : JSX.Element[] =[];

    items.map((product, index) => (
      dataCards.push(
        <DisplayDataCard
              key={'data-card-product-'+index}
              isInverse={true}
              title={product.descripcion??''}
              description={product?.informacionGeneral?.descripcionCortaAreaPublica??''}
              extra={
                <Box paddingTop={32} paddingBottom={24}>
                  <Stack space={16}>
                    <Grid columns={2}>
                      <Stack space={8}>
                        <Text2 regular>{intl.formatMessage({id:'page_pension_plans_blue_card_annual_profitability'})}</Text2>
                        <Text4 regular>{product.rentabilidad?.rentabilidadAnual ? formatBalance(product.rentabilidad?.rentabilidadAnual)+'%' : intl.formatMessage({id:'page_product_customer_not_available'})}</Text4>
                      </Stack>

                      <Stack space={8}>
                        <Text2 regular>{intl.formatMessage({id:'page_pension_plans_blue_card_annual_historical'})}</Text2>
                        <Text4 regular>{product.rentabilidad && product.rentabilidad.rentabilidadHistorica ? formatBalance(product.rentabilidad.rentabilidadHistorica)+(product.rentabilidad.tpRentabilidadHistorica ? ' '+intl.formatMessage({id:'page_product_customer_apr'}) : '%') : intl.formatMessage({id:'page_product_customer_not_available'})}</Text4>
                      </Stack>
                    </Grid>
                    <RiskSmall product={product} />
                  </Stack>
                </Box>
              }
              button={
                isBot ?
                    <ButtonSecondary href={getUrlNavigation(InvestmentFundsPage)+'/'+product.slug} small>{intl.formatMessage({id:'page_pension_plans_blue_card_more_info'})}</ButtonSecondary>
                :
                    <ButtonSecondary onPress={()=>{router.push(getUrlNavigation(InvestmentFundsPage)+'/'+product.slug); trackEvent('fondo-'+(product.slug?.replaceAll('-', '_') ?? '')+'-mas_informacion');}} small>{intl.formatMessage({id:'page_pension_plans_blue_card_more_info'})}</ButtonSecondary>
                }
            />
      )
    ));

    setDataCardProducts(dataCards);
  }


  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_investment_funds_seo_title'}), metaDescription:intl.formatMessage({id:'page_investment_funds_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_investment_funds_title'})}}
    >
      <HeroNoImage
        title={intl.formatMessage({id:'page_investment_funds_titulo1'})}
        subtitle={intl.formatMessage({id:'page_investment_funds_titulo2'})}
      >
        <Grid columns={screenSize.isTabletOrBigger ? 3 : 1} gap={screenSize.isTabletOrBigger ? 24 : 16}>
          <DataCard
            icon={<IconMoneyRiseLight size={40} color={skinVars.colors.buttonPrimaryBackground}></IconMoneyRiseLight>}
            title={intl.formatMessage({id:'page_investment_funds_card1_title'})}
            description={intl.formatMessage({id:'page_investment_funds_card1_description'})}/>
          <DataCard
            icon={<IconChatRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconChatRegular>}
            title={intl.formatMessage({id:'page_investment_funds_card2_title'})}
            description={intl.formatMessage({id:'page_investment_funds_card2_description'})}/>
          <DataCard
            icon={<IconSmileyHappyRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconSmileyHappyRegular>}
            title={intl.formatMessage({id:'page_investment_funds_card3_title'})}
            description={intl.formatMessage({id:'page_investment_funds_card3_description'})}/>
        </Grid>
      </HeroNoImage>


      <div id={slugify(intl.formatMessage({id:'menu_footer_investment_funds_all_funds'}))}>
        <ResponsiveLayout className='content' fullWidth={true}>
          <ResponsiveLayout>

            <Box paddingY={screenSize.isDesktopOrBigger ? 80 : 16}>
              <Stack space={56}>
                <Text5>{intl.formatMessage({id:'page_investment_funds_blue_card_title'})}</Text5>

                {loadingProducts ?
                  <Carousel initialActiveItem={0} itemsPerPage={size.isMobile ? 1 : (size.isTablet ? 2 : 3)}
                    withBullets
                    items={[
                      <SkeletonRectangle height={350} />,
                      <SkeletonRectangle height={350} />,
                      <SkeletonRectangle height={350} />
                    ]}
                  />
                :
                  <Carousel initialActiveItem={0} itemsPerPage={size.isMobile ? 1 : (size.isTablet ? 2 : 3)}
                    withBullets
                    items={ dataCardProducts }
                  />
                }
              </Stack>
            </Box>

          </ResponsiveLayout>
        </ResponsiveLayout>
      </div>

      <div id={slugify(intl.formatMessage({id:'menu_footer_investment_funds_how_choose_fund'}))}>
        <ResponsiveLayout className='content' fullWidth={true}>
          <ResponsiveLayout>

            <EmptyStateCustom
                largeImageUrl="../assets/img/como-elegir-un-fondo.jpg"
                title={intl.formatMessage({id:'page_investment_funds_empty_state_title'})}
                description={intl.formatMessage({id:'page_investment_funds_empty_state_description'})}
                button={
                  <ButtonGroup
                    primaryButton={isBot ?
                      <ButtonPrimary href={getUrlNavigation(CustomizeInvestmentFundPage)}><img src={'../assets/img/calculate-your-plan.svg'}/>
                        {intl.formatMessage({id:'page_investment_funds_empty_state_action_primary'})}
                      </ButtonPrimary>
                  :
                      <ButtonPrimary onPress={()=>{router.push(getUrlNavigation(CustomizeInvestmentFundPage)); trackEvent('fondo-ir_calculadora');}}><img src={'../assets/img/calculate-your-plan.svg'}/>
                        {intl.formatMessage({id:'page_investment_funds_empty_state_action_primary'})}
                      </ButtonPrimary>
                  }
                  ></ButtonGroup>
                }
            />

          </ResponsiveLayout>
        </ResponsiveLayout>
      </div>

      <div id={slugify(intl.formatMessage({id:'menu_footer_investment_funds_faqs'}))}>
        <style>{
            '.collapsibleBlock--content .html-content{color:'+skinVars.colors.textSecondary+'!important;}'
        }</style>
        <ResponsiveLayout className='content' fullWidth={true}>
          <ResponsiveLayout>
            {loadingFaqs ?
              <Box paddingTop={screenSize.isDesktopOrBigger ? 16 : 16} paddingBottom={screenSize.isDesktopOrBigger ? 56 : 32}>
                <Stack space={8}>
                  <SkeletonRectangle height={26}></SkeletonRectangle>
                  <Divider />
                  <SkeletonRectangle height={26}></SkeletonRectangle>
                  <Divider />
                  <SkeletonRectangle height={26}></SkeletonRectangle>
                  <Divider />
                  <SkeletonRectangle height={26}></SkeletonRectangle>
                  <Divider />
                </Stack>
              </Box>

            : faqs.length>0 &&

              <Box paddingTop={screenSize.isDesktopOrBigger ? 16 : 16} paddingBottom={screenSize.isDesktopOrBigger ? 56 : 32} className='collapsibleBlock--content'>
                {faqs.map((item, index)=>
                  <Accordion prefixSlug={'fondo'} key={'faqs-'+index} title={item.nbNombre}>
                    <div className='html-content' dangerouslySetInnerHTML={{__html: item.nbDescripcion??''}}></div>
                  </Accordion>
                )}
              </Box>
            }
          </ResponsiveLayout>
        </ResponsiveLayout>
      </div>

      <ResponsiveLayout className='content' fullWidth={true}>

        <Hero
          background='brand'
          media={<Image src="../assets/img/fondos-inversion-contratar.jpg" width="100%" height='337px'/>}
          title={intl.formatMessage({id:'page_investment_funds_blue_hero_title'})}
          description={intl.formatMessage({id:'page_investment_funds_blue_hero_description'})}
          desktopMediaPosition='left'
          button={isBot ?
                      <ButtonSecondary href={getUrlNavigation(ContactPage)}><IconEmailRegular color={"currentColor"} />{intl.formatMessage({id:'page_investment_funds_blue_hero_action'})}</ButtonSecondary>
                  :
                      <ButtonSecondary onPress={()=>{router.push(getUrlNavigation(ContactPage)); trackEvent('fondo-contacto');}}><IconEmailRegular color={"currentColor"} />{intl.formatMessage({id:'page_investment_funds_blue_hero_action'})}</ButtonSecondary>
                  }
        />

      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth={true}>

        <OurAppContainer/>

      </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default InvestmentFundsPage;
