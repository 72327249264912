import { Box, Boxed, Inline, skinVars, Stack, Text, Text2, Text3, Text6 } from '@telefonica/mistica';
import React from 'react';

import './GlobalCard.scss';

interface GlobalCardProps {
    values: Array<{title:string, value:string, small?:string}>
}

const GlobalCard: React.FC<GlobalCardProps> = (prop) => {

    return (
        <>
            <style>{
                '.globalPositionCards--cards .totalBox:before{background-color:'+skinVars.colors.background+';}'+
                '.globalPositionCards--cards .totalBox:after{background-color:'+skinVars.colors.background+';}'
            }</style>

            <Boxed isInverse className='globalPositionCards--cards'>
                <Box padding={16}>
                    <Inline space={prop.values.length !== 1 ? 'around' : 8} className={'totalBox totalBox--total'+prop.values.length}>
                        {prop.values?.map((item, index)=>(

                            <Stack key={'gc'+index} space={8} className='totalBox__item'>
                                <Text transform='uppercase'>{item.title}</Text>

                                {prop.values.length===3 ?
                                    prop.values.find(f=>f.value.length>10) ?
                                        <Text2 regular>{item.value}</Text2>
                                    :
                                    (prop.values.find(f=>f.value.length>6) ?
                                        <Text3 regular>{item.value}</Text3>
                                    :
                                        <Text6>{item.value}</Text6>)
                                :
                                    <Stack space={2}>
                                        <Text6>{item.value}</Text6>
                                        <Text2 regular>{item.small}</Text2>
                                    </Stack>
                                }
                            </Stack>

                        ))}
                    </Inline>
                </Box>
            </Boxed>
        </>
    );
};

export default GlobalCard;
