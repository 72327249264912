import { useIonRouter } from '@ionic/react';
import { Box, IconBalanceRegular, IconBlogRegular, IconCertificateRegular, IconChevronRightRegular, IconComputerUserRegular, IconHeadphonesRegular, IconListDocumentRegular, Inline, ResponsiveLayout, Row, RowList, skinVars, Text, Text1, useScreenSize } from '@telefonica/mistica';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import { getUrlNavigation } from '../../../../../utils/navigation';
import { getVersionApp, removeCurrentUserProduct } from '../../../../../utils/storage';
import { vibration } from '../../../../../utils/utils';
import UserContactPage from '../../fonditel/UserContactPage';
import UserCopyrightPage from '../../fonditel/UserCopyrightPage';
import UserDataProtectionPage from '../../fonditel/UserDataProtectionPage';
import UserOperationalWebPage from '../../fonditel/UserOperationalWebPage';
import ContractsCustomerPage from '../contracts/ContractsCustomerPage';
import TaxInformationCustomerPage from '../tax-information/TaxInformationCustomerPage';
import './MenuCustomerPage.scss';

const MenuCustomerPage: React.FC = () => {
  const intl = useIntl()
  const router = useIonRouter();
  const screenSize = useScreenSize();
  const [loading, setLoading] = useState(false);




  useEffect(()=>{
    /*if(!router.canGoBack() || !isNativeApp){
        router.push(getUrlNavigation(GlobalPositionCustomerPage));
        return;
    }*/

    removeCurrentUserProduct();
  }, []);

  return (
    <LayoutPrivateArea
      seo={{metaTitle: 'Menú'}}
      title={'Menú'}
      pageInfo={{option: process.env.REACT_APP_MENU_POSICION_GLOBAL, role:'client'}}
      showMenu
    >
      <ResponsiveLayout className='fullscreen' backgroundColor={skinVars.colors.backgroundAlternative}>
        <ResponsiveLayout className='inner'>
          <Text1 medium transform='uppercase' color={skinVars.colors.neutralMediumInverse}>{intl.formatMessage({id:'page_menu_customer_general'})}</Text1>
        </ResponsiveLayout>
      </ResponsiveLayout>
      <ResponsiveLayout className='fullscreen menu-customer-page'>

        <RowList dataAttributes={{"remove-paddings":true}}>
        <Row title=''
              right={null}
              onPress={() => {vibration();router.push(getUrlNavigation(TaxInformationCustomerPage))}}
              extra={
                <Inline space={'between'} alignItems='center'>
                    <Text weight={'regular'} color={skinVars.colors.neutralHigh}>
                        <Inline space={16} alignItems='center'>
                            <span><IconBalanceRegular></IconBalanceRegular></span>
                            <span>{intl.formatMessage({id:'page_menu_customer_fiscal_information'})}</span>
                        </Inline>
                    </Text>
                    <IconChevronRightRegular size={16} color={skinVars.colors.neutralMediumInverse}></IconChevronRightRegular>
                </Inline>
              }
          />
          <Row title=''
                right={null}
                onPress={() => {vibration();router.push(getUrlNavigation(ContractsCustomerPage))}}
                extra={
                  <Inline space={'between'} alignItems='center'>
                      <Text weight={'regular'} color={skinVars.colors.neutralHigh}>
                          <Inline space={16} alignItems='center'>
                              <span><IconBlogRegular></IconBlogRegular></span>
                              <span>{intl.formatMessage({id:'page_menu_customer_fiscal_contracts'})}</span>
                          </Inline>
                      </Text>
                      <IconChevronRightRegular size={16} color={skinVars.colors.neutralMediumInverse}></IconChevronRightRegular>
                  </Inline>
                }
            />
        </RowList>
      </ResponsiveLayout>

      <ResponsiveLayout className='fullscreen'>
        <Box paddingY={32}></Box>
      </ResponsiveLayout>

      <ResponsiveLayout className='fullscreen' backgroundColor={skinVars.colors.backgroundAlternative}>
        <ResponsiveLayout className='inner'>
          <Text1 medium transform='uppercase' color={skinVars.colors.neutralMediumInverse}>{intl.formatMessage({id:'page_menu_customer_fonditel'})}</Text1>
        </ResponsiveLayout>
      </ResponsiveLayout>
      <ResponsiveLayout className='fullscreen menu-customer-page'>

        <RowList dataAttributes={{"remove-paddings":true}}>
        <Row title=''
              right={null}
              onPress={() => {vibration();router.push(getUrlNavigation(UserOperationalWebPage))}}
              extra={
                <Inline space={'between'} alignItems='center'>
                    <Text weight={'regular'} color={skinVars.colors.neutralHigh}>
                        <Inline space={16} alignItems='center'>
                            <span><IconComputerUserRegular></IconComputerUserRegular></span>
                            <span>{intl.formatMessage({id:'page_menu_customer_fiscal_web_operation'})}</span>
                        </Inline>
                    </Text>
                    <IconChevronRightRegular size={16} color={skinVars.colors.neutralMediumInverse}></IconChevronRightRegular>
                </Inline>
              }
          />
          <Row title=''
                right={null}
                onPress={() => {vibration();router.push(getUrlNavigation(UserCopyrightPage))}}
                extra={
                  <Inline space={'between'} alignItems='center'>
                      <Text weight={'regular'} color={skinVars.colors.neutralHigh}>
                          <Inline space={16} alignItems='center'>
                              <span><IconListDocumentRegular></IconListDocumentRegular></span>
                              <span>{intl.formatMessage({id:'page_menu_customer_fiscal_web_copyright'})}</span>
                          </Inline>
                      </Text>
                      <IconChevronRightRegular size={16} color={skinVars.colors.neutralMediumInverse}></IconChevronRightRegular>
                  </Inline>
                }
            />
            <Row title=''
                  right={null}
                  onPress={() => {vibration();router.push(getUrlNavigation(UserDataProtectionPage))}}
                  extra={
                    <Inline space={'between'} alignItems='center'>
                        <Text weight={'regular'} color={skinVars.colors.neutralHigh}>
                            <Inline space={16} alignItems='center'>
                                <span><IconCertificateRegular></IconCertificateRegular></span>
                                <span>{intl.formatMessage({id:'page_menu_customer_data_protection'})}</span>
                            </Inline>
                        </Text>
                        <IconChevronRightRegular size={16} color={skinVars.colors.neutralMediumInverse}></IconChevronRightRegular>
                    </Inline>
                  }
              />
              <Row title=''
                    right={null}
                    onPress={() => {vibration();router.push(getUrlNavigation(UserContactPage))}}
                    extra={
                      <Inline space={'between'} alignItems='center'>
                          <Text weight={'regular'} color={skinVars.colors.neutralHigh}>
                              <Inline space={16} alignItems='center'>
                                  <span><IconHeadphonesRegular></IconHeadphonesRegular></span>
                                  <span>{intl.formatMessage({id:'page_menu_customer_contact'})}</span>
                              </Inline>
                          </Text>
                          <IconChevronRightRegular size={16} color={skinVars.colors.neutralMediumInverse}></IconChevronRightRegular>
                      </Inline>
                    }
                />
        </RowList>

        <ResponsiveLayout className='fullscreen'>
          <Box paddingY={32}></Box>
        </ResponsiveLayout>


        <ResponsiveLayout className='fullscreen' backgroundColor={'transparent'}>
          <ResponsiveLayout className='inner'>
            <Inline space={'between'}>
              <> </>
              <Text1 medium color={skinVars.colors.neutralMediumInverse}>v. {getVersionApp()}</Text1>
            </Inline>
          </ResponsiveLayout>
        </ResponsiveLayout>
      </ResponsiveLayout>


    </LayoutPrivateArea>
  );
};

export default MenuCustomerPage;
