import { useIonRouter } from '@ionic/react';
import { Box, ButtonLayout, ButtonPrimary, ButtonSecondary, GridLayout, IconSearchFileRegular, IconUmbrellaLight, Inline, LoadingBar, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Text4, Text5, useScreenSize, useWindowSize } from '@telefonica/mistica';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import ButtonTertiary from '../../../../../components/buttons/ButtonTertiary';
import EmptyCard from '../../../../../components/empty-card/EmptyCard';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import { useProductActions } from '../../../../../components/operate/ProductActions';
import { useValidOperate } from '../../../../../components/operate/ValidOperate';
import ProductCard from '../../../../../components/product/ProductCard';
import TotalBalance from '../../../../../components/total-balance/totalBalance';
import { Product } from '../../../../../entities/products/product';
import { UserProduct } from '../../../../../entities/products/userProduct';
import { getPlans, getPlansNotContracted } from '../../../../../utils/apiclient';
import { removeCurrentUserProduct } from '../../../../../utils/storage';
import { formatAnualProfit, formatBalance, formatDate, formatNetAssetValue, formatProduct, groupProducts, trackEvent } from '../../../../../utils/utils';

export interface PensionPlansCustomerPageProps {
  type: string
}


const PensionPlansCustomerPage: React.FC<PensionPlansCustomerPageProps> = (prop) => {
  const { contract, moreInfo, operate } = useProductActions()
  const intl = useIntl();
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [loadingNoContractPlans, setLoadingNoContractPlans] = useState(true);
  const screenSize = useScreenSize();
  const size = useWindowSize();
  const router = useIonRouter();
  const [products, setProducts] = useState([] as UserProduct[]);
  const [balance, setBalance] = useState(null as number|null);
  const [title, setTitle] = useState('');
  const [containerProducts, setContainerProducts] = useState([] as UserProduct[][]);
  const [productsNotContract, setProductsNotContract] = useState([] as Product[]);
  const [containerProductsNotContract, setContainerProductsNotContract] = useState([] as Product[][]);
  const [loadingProduct, setLoadingProduct] = useState(null as null|string);

  useLayoutEffect(()=>{
    removeCurrentUserProduct();
    switch(prop.type){
      case process.env.REACT_APP_PLAN_PENSIONES_EMPRESA:
        setTitle(intl.formatMessage({id:'page_customer_pension_plans_employment_plans_title'}));
        break;
      case process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL:
        setTitle(intl.formatMessage({id:'page_customer_pension_plans_individual_plans_title'}));
        break;
      case process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS:
        setTitle(intl.formatMessage({id:'page_customer_pension_plans_simplified_employment_plans_title'}));
        break;
    }
  },[]);

  useEffect(()=>{
    getPlans(prop.type).then((result)=>{
      if(result){
        const balances = result.map((planes) => parseFloat(planes.saldo ? (planes.saldo.toString()) : ('0')  || '0'));
        const balanceTotal = balances.reduce((total, bal) => total + bal, 0);
        setBalance(balanceTotal);

        setProducts(result);
        setContainerProducts(groupProducts(result));

      }
    }).finally(()=>{
      setLoadingPlans(false);
    });

    getPlansNotContracted(prop.type).then((result)=>{
      setProductsNotContract(result);
      setContainerProductsNotContract(groupProducts(result));

    }).finally(()=>{
      setLoadingNoContractPlans(false);
    })
  }, []);

  const handleMoreInformation = (product:Product): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('perfil-'+formatProduct(product)+'-mas_informacion');
      setLoadingProduct(formatProduct(product));
      moreInfo(product).finally(()=>{
        setLoadingProduct(null);
        resolve();
      });
  });

  const handleContract = (product:Product): Promise<void> =>
    new Promise((resolve) => {
        trackEvent('perfil-'+formatProduct(product)+'-contratar');
        setLoadingProduct(formatProduct(product));
        contract(product,
        ()=>{
          setLoadingProduct(null);
        }).finally(()=>{
            resolve()
        });
  });


  const handleOperate = (product:UserProduct): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('perfil-'+slugify(product?.product?.descripcion??'').replaceAll('-', '_')+'-operar');
      operate(product).finally(()=>{
        resolve();
      });
  });

  return (
    <LayoutPrivateArea
      seo={{metaTitle: title}}
      titleResponsive={title}
      pageInfo={{option: process.env.REACT_APP_MENU_PLANES_PENSIONES, role:'client'}}>
      <LoadingBar visible={loadingPlans || loadingNoContractPlans}/>
      <ResponsiveLayout className='inner first'>
          <Box paddingBottom={32}>
            <Inline space={'between'} alignItems='center'>
              {screenSize.isDesktopOrBigger &&
                <Text5>{title}</Text5>
              }

              {loadingPlans ?
                <SkeletonRectangle height={53} width={254} />
                :
                <TotalBalance
                  icon = {<IconUmbrellaLight size={27}></IconUmbrellaLight>}
                  rights = {balance ? balance : 0}
                />
              }
            </Inline>
          </Box>

          {loadingPlans ?
            <Box paddingBottom={40}>
              <GridLayout
                template="6+6"
                verticalSpace={24}
                left={<SkeletonRectangle height={150} />}
                right={<SkeletonRectangle height={150} />}
              />
            </Box>
          :
            products.length===0 ?
              <Box paddingY={40}>
                <EmptyCard
                  icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                  title={intl.formatMessage({id:'page_plans_customer_without_plans_title'})}
                  subtitle={intl.formatMessage({id:'page_plans_customer_without_plans_description'})}
                />
              </Box>
            :
            <Box paddingBottom={40}>
              <Stack space={24}>

                {containerProducts.map((group, index) => (
                  <GridLayout
                    key={`group-${index}`}
                    template="6+6"
                    verticalSpace={24}
                    left={
                      group[0] ?
                      <ProductCard
                        title={group[0].nbPlan??''}
                        category={group[0].tipoFondo??''}
                        ownership={group[0].nbTitular??''}
                        rights={formatBalance(group[0].saldo??0)}
                        annual_profitability={group[0].rentabilidadAnual ? formatAnualProfit(group[0].rentabilidadAnual) : '0'}
                        date_balance={group[0].fechaSaldo ? formatDate(group[0].fechaSaldo) : '0'}
                        button={
                          <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                            <><ButtonPrimary small onPress={() => {handleOperate(group[0])}}>
                                {intl.formatMessage({id:'page_product_customer_operate_button'})}
                            </ButtonPrimary></>

                            <ButtonSecondary small onPress={()=>{group[0].product && handleMoreInformation(group[0].product) }}>
                              {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                            </ButtonSecondary>
                          </ButtonLayout>
                        }
                      /> : <></>
                    }
                    right={
                      group[1] ?
                        <ProductCard
                          title={group[1].nbPlan??''}
                          category={group[1].tipoFondo??''}
                          ownership={group[1].nbTitular??''}
                          rights={formatBalance(group[1].saldo??0)}
                          annual_profitability={group[1].rentabilidadAnual ? formatAnualProfit(group[1].rentabilidadAnual) : '0'}
                          date_balance={group[1].fechaSaldo ? formatDate(group[1].fechaSaldo) : '0'}
                          button={

                            <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                              <><ButtonPrimary small onPress={() => {handleOperate(group[1])}}>
                                  {intl.formatMessage({id:'page_product_customer_operate_button'})}
                              </ButtonPrimary></>

                              <ButtonSecondary small onPress={()=>{group[1].product && handleMoreInformation(group[1].product) }}>
                                {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                              </ButtonSecondary>
                            </ButtonLayout>
                          }
                        />
                      : <></>
                    }/>
                ))}
              </Stack>
            </Box>
          }
      </ResponsiveLayout>

      {loadingNoContractPlans ?
        <ResponsiveLayout className='full'>
          <SkeletonRectangle height={350} />
        </ResponsiveLayout>
        :
        (productsNotContract.length===0 ?
          <></>
          :
          <ResponsiveLayout className='full' backgroundColor={skinVars.colors.backgroundAlternative}>
            <ResponsiveLayout className='inner'>
              <Box paddingTop={56} paddingBottom={32}>
                <Inline space={'between'} alignItems='center'>
                  <Text4 medium>{intl.formatMessage({id:'page_plans_customer_other_products'})}</Text4>
                </Inline>
              </Box>
                <Box paddingBottom={40}>
                  <Stack space={24}>
                  {containerProductsNotContract.map((group, index) => (
                    <GridLayout
                      key={`group-${index}`}
                      template="6+6"
                      verticalSpace={24}
                      left={
                        group[0] ?
                        <ProductCard
                          title={group[0].descripcion??''}
                          category={group[0].informacionGeneral?.tipoFondo??''}
                          net_asset_value={group[0].rentabilidad?.valorLiquidativo ? formatNetAssetValue(group[0].rentabilidad?.valorLiquidativo) : intl.formatMessage({id:'page_product_customer_not_available'})}
                          annual_profitability={group[0].rentabilidad?.rentabilidadAnual ? formatBalance(group[0].rentabilidad?.rentabilidadAnual) : intl.formatMessage({id:'page_product_customer_not_available'})}
                          historical_profitability={group[0].rentabilidad && group[0].rentabilidad.rentabilidadHistorica ? formatBalance(group[0].rentabilidad.rentabilidadHistorica)+(group[0].rentabilidad.tpRentabilidadHistorica ? ' '+intl.formatMessage({id:'page_product_customer_apr'}) : '%') : intl.formatMessage({id:'page_product_customer_not_available'})}
                          button={
                            <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                              <ButtonTertiary showSpinner={loadingProduct===formatProduct(group[0])} small onPress={()=>{handleContract(group[0])}}>
                                {intl.formatMessage({id:'page_product_customer_hire_button'})}
                              </ButtonTertiary>
                              <ButtonSecondary small onPress={() => {handleMoreInformation(group[0])}}>
                                {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                              </ButtonSecondary>
                            </ButtonLayout>
                          }
                        /> : <></>
                      }
                      right={
                        group[1] ?
                          <ProductCard
                            title={group[1].descripcion??''}
                            category={group[1].informacionGeneral?.tipoFondo??''}
                            net_asset_value={group[1].rentabilidad?.valorLiquidativo ? formatNetAssetValue(group[1].rentabilidad?.valorLiquidativo) : intl.formatMessage({id:'page_product_customer_not_available'})}
                            annual_profitability={group[1].rentabilidad?.rentabilidadAnual ? formatBalance(group[1].rentabilidad?.rentabilidadAnual) : intl.formatMessage({id:'page_product_customer_not_available'})}
                            historical_profitability={group[1].rentabilidad && group[1].rentabilidad.rentabilidadHistorica ? formatBalance(group[1].rentabilidad.rentabilidadHistorica)+(group[1].rentabilidad.tpRentabilidadHistorica ? ' '+intl.formatMessage({id:'page_product_customer_apr'}) : '%') : intl.formatMessage({id:'page_product_customer_not_available'})}
                            button={
                              <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                                <ButtonTertiary showSpinner={loadingProduct===formatProduct(group[1])} small onPress={()=>{handleContract(group[1])}}>
                                  {intl.formatMessage({id:'page_product_customer_hire_button'})}
                                </ButtonTertiary>
                                <ButtonSecondary small onPress={() => {handleMoreInformation(group[1])}}>
                                  {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                                </ButtonSecondary>
                              </ButtonLayout>
                            }
                          />
                        : <></>
                      }/>
                  ))}
                  </Stack>
                </Box>
            </ResponsiveLayout>
          </ResponsiveLayout>
        )
      }
    </LayoutPrivateArea>
  );
};

export default PensionPlansCustomerPage;


export const IndividualPlansCustomerPage: React.FC = () => {
  return (<PensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL ?? "I"} />);
};

export const SimplifiedEmploymentPlansCustomerPage: React.FC = () => {
  return (<PensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS ?? "S"} />);
};

export const EmploymentPlansCustomerPage: React.FC = () => {
  return (<PensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_EMPRESA ?? "E"} />);
};
