import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';

import { ContractSituation } from '../../../../../entities/contract/contract-situation.model';
import { downloadContractDocument, getContractsSituation } from '../../../../../utils/apiclient';
import { fonditel_search_in_options } from '../../../../../utils/utils';

import { Box, Boxed, ButtonLink, Divider, IconArrowLineRightRegular, IconButton, IconDownloadRegular, IconInformationUserLight, Inline, LoadingBar, ResponsiveLayout, SkeletonRectangle, Stack, Tag, Text, Text1, Text2, Text3, Text5, Tooltip, skinVars, useScreenSize } from '@telefonica/mistica';

import './ContractsCustomerPage.scss';
import { removeCurrentUserProduct } from '../../../../../utils/storage';

const ContractsCustomerPage: React.FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const screenSize = useScreenSize();

  const [contractSituation, setContractSituation] = useState({} as ContractSituation);

  useEffect(()=>{
    removeCurrentUserProduct();
    setLoading(true);
    getContractsSituation({}).then(contracts=>{
      setContractSituation(contracts);
      setLoading(false);
    });
  }, []);

  const downloadDocument= () => {
      setLoadingDownload(true);
      downloadContractDocument(intl.formatMessage({id:'page_contracts_customer_title_document'})).then(()=>{
        setLoadingDownload(false);
      });
  };

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_contracts_customer_title'})}}
      title={intl.formatMessage({id:'page_contracts_customer_title'})}
      pageInfo={{option: process.env.REACT_APP_MENU_OPERATIVA_WEB_CAMBIAR_SITUACION_CONTRATOS, role:'client'}}
      showBack
    >
      <LoadingBar visible={loading || loadingDownload}/>
      <ResponsiveLayout className='inner first'>
        {screenSize.isDesktopOrBigger ?
          <>
            <Box paddingBottom={40}>
              <Inline space={'between'} alignItems='center'>
                  <Text5>{intl.formatMessage({id:'page_contracts_customer_title'})}</Text5>
              </Inline>
            </Box>

            <Box paddingBottom={40}>
              <Stack space={8} className='table--contractsCustomer'>
                <Inline space="between">
                  <Box className='col-1'>
                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contracts_customer_column_name'})}</Text>
                  </Box>
                  <Box className='col-2'>
                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contracts_customer_column_state'})}</Text>
                  </Box>
                  <Box className='col-3'>
                    <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_contracts_customer_column_detail'})}</Text>
                  </Box>
                </Inline>
                <Divider/>

                {loading ?
                  <Stack space={8}>
                    <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                    <Divider />
                    <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                    <Divider />
                  </Stack>
                :
                  <>
                    {contractSituation.signState &&
                    <>
                      <Inline space='between' alignItems='center' className='row'>
                        <Box className='col-1'>
                          <Text2 regular>{intl.formatMessage({ id: 'page_contracts_customer_in_your_own_name' })}</Text2>
                        </Box>
                        <Box className='col-2'>
                          {(() => {
                            switch (contractSituation.signState.estado) {
                              case 'ACTIVO':
                                return (
                                  <Tag type="success">{intl.formatMessage({ id: 'page_contracts_customer_state_active' })}</Tag>
                                );
                              case 'INACTIVO':
                                return (
                                  <Tag type="inactive">{intl.formatMessage({ id: 'page_contracts_customer_state_inactive' })}</Tag>
                                );
                              case 'SIN_SOLICITAR':
                                return (
                                  contractSituation.signState.incidencia ? (
                                    <Inline space={4}>
                                      <Tag type="promo">{intl.formatMessage({ id: 'page_contracts_customer_state_not_requested' })}</Tag>
                                      <Tooltip
                                          targetLabel="help text"
                                          target={<IconInformationUserLight />}
                                          position="bottom"
                                          description={fonditel_search_in_options(contractSituation.rejectionReasons, Number(contractSituation.signState.incidencia), 'cdRechazo').descripcion}
                                        />
                                    </Inline>
                                  ) : (
                                    <Tag type="promo">{intl.formatMessage({ id: 'page_contracts_customer_state_not_requested' })}</Tag>
                                  )
                                );
                              case 'EN_TRAMITE':
                                return (
                                  <Tag type="active">{intl.formatMessage({ id: 'page_contracts_customer_state_in_process' })}</Tag>
                                );
                              case 'RECHAZADO':
                                return (
                                  <Inline space={4}>
                                    <Tag type="error">{intl.formatMessage({ id: 'page_contracts_customer_state_rejected' })}</Tag>
                                    <Tooltip
                                        targetLabel="help text"
                                        target={<IconInformationUserLight />}
                                        position="bottom"
                                        description={fonditel_search_in_options(contractSituation.rejectionReasons, Number(contractSituation.signState.incidencia), 'cdRechazo').descripcion}
                                      />
                                  </Inline>
                                );
                              default:
                                return (
                                  <Tag type="promo">{intl.formatMessage({ id: 'page_contracts_customer_state_not_requested' })}</Tag>
                                );
                            }
                          })()}
                        </Box>
                        {(contractSituation.signState.estado === 'ACTIVO' || contractSituation.signState.estado === 'INACTIVO') ?
                          (
                            <Box className='col-3'>
                              <IconButton onPress= {()=>downloadDocument()} aria-label="Icon">
                                <IconDownloadRegular color={skinVars.colors.brand} />
                              </IconButton>
                            </Box>
                          ) : (
                            <Box className='col-3'>
                            </Box>
                          )
                        }
                      </Inline>
                      <Divider/>
                    </>
                    }

                    {contractSituation.represented && contractSituation.represented.map((represented, index) =>(
                      <Box key={index}>
                        <Inline space='between' alignItems='center' className='row'>
                          <Box className='col-1'>
                            <Text2 regular>{represented.participe} {intl.formatMessage({ id: 'page_contracts_customer_of' })} {represented.nombreTitular}</Text2>
                          </Box>
                          <Box className='col-2'>
                            {['AUTORIZADO', 'REPRESENTANTE', 'APODERADO'].includes(represented.tipoIntervencion) ? (
                                <Tag type="warning">{intl.formatMessage({ id: 'page_contracts_customer_state_not_available' })}</Tag>
                              ) : (
                                (() => {
                                  switch (represented.estadoFirmaAjena) {
                                    case 'ACTIVO':
                                      return (
                                        <Tag type="success">{intl.formatMessage({ id: 'page_contracts_customer_state_active' })}</Tag>
                                      );
                                    case 'INACTIVO':
                                      return (
                                        <Tag type="inactive">{intl.formatMessage({ id: 'page_contracts_customer_state_inactive' })}</Tag>
                                      );
                                    case 'SIN_SOLICITAR':
                                      represented.descripcionIncidencia ? (
                                        <Inline space={4}>
                                          <Tag type="promo">{intl.formatMessage({ id: 'page_contracts_customer_state_not_requested' })}</Tag>
                                          <Tooltip
                                              targetLabel="help text"
                                              target={<IconInformationUserLight />}
                                              position="bottom"
                                              description={represented.descripcionIncidencia}
                                            />
                                        </Inline>
                                      ) : (
                                        <Tag type="promo">{intl.formatMessage({ id: 'page_contracts_customer_state_not_requested' })}</Tag>
                                      )
                                      return (
                                        <Tag type="promo">{intl.formatMessage({ id: 'page_contracts_customer_state_not_requested' })}</Tag>
                                      );
                                    case 'EN_TRAMITE':
                                      return (
                                        <Tag type="active">{intl.formatMessage({ id: 'page_contracts_customer_state_in_process' })}</Tag>
                                      );
                                    case 'RECHAZADO':
                                      return (
                                        <Inline space={4}>
                                          <Tag type="error">{intl.formatMessage({ id: 'page_contracts_customer_state_rejected' })}</Tag>
                                          <Tooltip
                                            targetLabel="help text"
                                            target={<IconInformationUserLight />}
                                            position="bottom"
                                            description={represented.descripcionIncidencia}
                                          />
                                        </Inline>
                                      );
                                    default:
                                      return (
                                        <Tag type="promo">{intl.formatMessage({ id: 'page_contracts_customer_state_not_requested' })}</Tag>
                                      );
                                  }
                                })()
                              )
                            }
                          </Box>
                          {(represented.estadoFirmaAjena === 'ACTIVO' || represented.estadoFirmaAjena === 'INACTIVO') ?
                            (
                              <Box className='col-3'>
                                <IconButton onPress={() => {}} aria-label="Icon">
                                  <IconDownloadRegular color={skinVars.colors.brand} />
                                </IconButton>
                              </Box>
                            ) : (
                              <Box className='col-3'>
                              </Box>
                            )
                          }
                        </Inline>
                        <Divider/>
                      </Box>
                    ))}
                    <Box paddingTop={4}>
                      <Text size={11} color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_contracts_customer_table_info' })}</Text>
                    </Box>
                  </>
                }
              </Stack>
            </Box>
          </>
        :
          <Box paddingY={12}>
            {loading ?
              <Stack space={12}>
                <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
              </Stack>
            :
              <Stack space={12}>
                {contractSituation.signState &&
                    <Boxed>
                      <Box paddingX={16} paddingY={24}>
                        <Stack space={16}>
                          {(() => {
                            switch (contractSituation.signState.estado) {
                              case 'ACTIVO':
                                return (
                                  <Tag type="success">{intl.formatMessage({ id: 'page_contracts_customer_state_active' })}</Tag>
                                );
                              case 'INACTIVO':
                                return (
                                  <Tag type="inactive">{intl.formatMessage({ id: 'page_contracts_customer_state_inactive' })}</Tag>
                                );
                              case 'SIN_SOLICITAR':
                                return (
                                  <Tag type="promo">{intl.formatMessage({ id: 'page_contracts_customer_state_not_requested' })}</Tag>
                                );
                              case 'EN_TRAMITE':
                                return (
                                  <Tag type="active">{intl.formatMessage({ id: 'page_contracts_customer_state_in_process' })}</Tag>
                                );
                              case 'RECHAZADO':
                                return (
                                  <Inline space={4}>
                                    <Tag type="error">{intl.formatMessage({ id: 'page_contracts_customer_state_rejected' })}</Tag>
                                  </Inline>
                                );
                              default:
                                return (
                                  <Tag type="promo">{intl.formatMessage({ id: 'page_contracts_customer_state_not_requested' })}</Tag>
                                );
                            }
                          })()}

                          <Stack space={2}>
                            <Text1 regular>{intl.formatMessage({ id: 'page_contracts_customer_title' })}</Text1>
                            <Text3 medium>{intl.formatMessage({ id: 'page_contracts_customer_in_your_own_name' })}</Text3>
                          </Stack>

                          {contractSituation.signState.incidencia && (contractSituation.signState.estado === 'SIN_SOLICITAR' || contractSituation.signState.estado === 'RECHAZADO') &&
                            <Tooltip
                              targetLabel="help text"
                              target={<IconInformationUserLight />}
                              position="bottom"
                              description={fonditel_search_in_options(contractSituation.rejectionReasons, Number(contractSituation.signState.incidencia), 'cdRechazo').descripcion}
                            />
                          }
                          {(contractSituation.signState.estado === 'ACTIVO' || contractSituation.signState.estado === 'INACTIVO') && (
                            <ButtonLink onPress={()=>downloadDocument()} aligned>
                              {intl.formatMessage({ id: 'page_contracts_customer_see_more' })}
                              <IconArrowLineRightRegular color="currentColor" />
                            </ButtonLink>
                          )}
                        </Stack>
                      </Box>
                    </Boxed>
                }

                {contractSituation.represented && contractSituation.represented.map((represented, index) =>(
                  <Boxed key={index}>
                    <Box paddingX={16} paddingY={24}>
                      <Stack space={16}>
                        {['AUTORIZADO', 'REPRESENTANTE', 'APODERADO'].includes(represented.tipoIntervencion) ? (
                                  <Tag type="warning">{intl.formatMessage({ id: 'page_contracts_customer_state_not_available' })}</Tag>
                          ) : (
                            (() => {
                            switch (represented.estadoFirmaAjena) {
                              case 'ACTIVO':
                                return (
                                  <Tag type="success">{intl.formatMessage({ id: 'page_contracts_customer_state_active' })}</Tag>
                                );
                              case 'INACTIVO':
                                return (
                                  <Tag type="inactive">{intl.formatMessage({ id: 'page_contracts_customer_state_inactive' })}</Tag>
                                );
                              case 'SIN_SOLICITAR':
                                return (
                                  <Tag type="promo">{intl.formatMessage({ id: 'page_contracts_customer_state_not_requested' })}</Tag>
                                );
                              case 'EN_TRAMITE':
                                return (
                                  <Tag type="active">{intl.formatMessage({ id: 'page_contracts_customer_state_in_process' })}</Tag>
                                );
                              case 'RECHAZADO':
                                return (
                                  <Inline space={4}>
                                    <Tag type="error">{intl.formatMessage({ id: 'page_contracts_customer_state_rejected' })}</Tag>
                                  </Inline>
                                );
                              default:
                                return (
                                  <Tag type="promo">{intl.formatMessage({ id: 'page_contracts_customer_state_not_requested' })}</Tag>
                                );
                              }
                            })
                          )()
                        }

                        <Stack space={2}>
                          <Text1 regular>{intl.formatMessage({ id: 'page_contracts_customer_title' })}</Text1>
                          <Text3 medium>{represented.participe} {intl.formatMessage({ id: 'page_contracts_customer_of' })} {represented.nombreTitular}</Text3>
                        </Stack>
                      </Stack>

                      {(represented.estadoFirmaAjena === 'ACTIVO' || represented.estadoFirmaAjena === 'INACTIVO') && (
                        <ButtonLink onPress={()=>downloadDocument()} aligned>
                          {intl.formatMessage({ id: 'page_contracts_customer_see_more' })}
                          <IconArrowLineRightRegular color="currentColor" />
                        </ButtonLink>
                      )}
                    </Box>
                  </Boxed>
                ))}
                <Text size={11} color={skinVars.colors.textSecondary}>{intl.formatMessage({ id: 'page_contracts_customer_table_info' })}</Text>
              </Stack>
            }
          </Box>
        }
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default ContractsCustomerPage;
