import { useIonRouter } from '@ionic/react';
import React, { useLayoutEffect } from 'react';

import { Box } from '@telefonica/mistica';
import LoginCard from '../../../components/login/LoginCard';
import { getUrlNavigation } from '../../../utils/navigation';
import { getModeCallCenter } from '../../../utils/storage';
import HomePage from '../../public/home/HomePage';

const CallCenterPage: React.FC = () => {
const router = useIonRouter();
useLayoutEffect(()=>{
  const modeCallCenter = getModeCallCenter();
  if(modeCallCenter==null){
    router.push(getUrlNavigation(HomePage));
    return;
  }
}, []);

  return (
    <Box className='d-none'>
      <LoginCard />
    </Box>
  );
};

export default CallCenterPage;
