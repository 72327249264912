import { Box, Boxed, ButtonFixedFooterLayout, ButtonPrimary, ButtonSecondary, DecimalField, Form, Grid, alert, IbanField, Inline, LoadingBar, RadioButton, RadioGroup, ResponsiveLayout, Select, skinVars, Stack, Text1, Text2, Text4, useScreenSize, useWindowSize, ButtonLayout, ButtonDanger, Text5 } from '@telefonica/mistica';
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useIntl } from 'react-intl';

import { useIonRouter } from '@ionic/react';
import { Periodicity } from '../../entities/commons/periodicity.model';
import { Contract } from '../../entities/contract/contract';
import { OperationContribution } from '../../entities/contract/operations/operation-contribution';
import { addContribution, getPeriodicities } from '../../utils/apiclient';
import { getCurrentUserContract, removeOperationContribution, setOperationContribution } from '../../utils/storage';
import { closeDialog, formatAmount, maskAccountNumber } from '../../utils/utils';

interface ContributionPensionPlanProps {
    contract: Contract | null,
    loading?: boolean,
    onCancel?:() => void,
    onConfirm:() => void
}

const ContributionPensionPlan: React.FC<ContributionPensionPlanProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const size = useWindowSize();
    const router = useIonRouter();
    const [contract, setContract] = useState(null as null|Contract);
    const [periodicitiesOptions, setPeriodicitiesOptions] = React.useState([] as Periodicity[]);
    const [editContributionPeriodic, setEditContributionPeriodic] = React.useState(false);
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');

    const [loading, setLoading] = React.useState(true);

    //Loading form
    const [loadingForm, setLoadingForm] = React.useState(false);

    useEffect(()=>{
        removeOperationContribution();
        if(prop.contract){
            setContract(prop.contract);

            const uc = getCurrentUserContract();
            if(uc){
                setContract(uc)
            }
            setTitle(intl.formatMessage({id: 'page_operate_contribution_fiscal_limit_contribution_plan'}));
            switch(prop.contract?.plan?.individual){
                case process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL:
                    setSummary(intl.formatMessage({id: 'page_operate_contribution_fiscal_limit_individual_plan'}));
                    break;
                case process.env.REACT_APP_PLAN_PENSIONES_EMPRESA:
                    setSummary(intl.formatMessage({id: 'page_operate_contribution_fiscal_limit_employment_plan'}));
                    break;
                case process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS:
                    setSummary(intl.formatMessage({id: 'page_operate_contribution_fiscal_limit_simplified_employment_plan'}));
                    break;
            }
        }
    },[prop.contract]);

    useLayoutEffect(()=>{
        getPeriodicities().then(options=>{
            setPeriodicitiesOptions(options.filter(item=>item.cdPeriodicidad!=0));
        }).finally(()=>{
            setLoading(false);
        })
    }, []);



    //Form
    const initFormData = {
        contributionType: (process.env.REACT_APP_APORTACION_UNICA??'1'),
        amount: '',
        iban: '',
        periodicity: '',
        annualRevaluation: (process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_IPC??'1'),
        revaluationPercentage: '',
    }
    const [formData, setFormData] = useState(initFormData);
    const [formErrors, setFormErrors] = useState([] as any);

    //Handle fields
    const handleError=(name:string) =>{
        if(formErrors && formErrors[name]){
            const error = formErrors[name];
            if(error){
                const errorKey = error.toLowerCase().replaceAll(' ', '_');
                switch(errorKey){
                    case 'minimum_amount_of_contributions':
                        return intl.formatMessage({id: 'page_operate_contribution_'+errorKey}, {minimum_amount: formatAmount(contract?.importeMinimoAportaciones ?? '', true)});
                        break;

                    case 'contribution_exceeds_fiscal_limit':
                        if(formErrors['amount_max']){
                            let message = '';
                            const amount_max= formatAmount(formErrors['amount_max'], true);
                            delete formErrors['amount_max'];
                            switch(contract?.plan?.individual){
                                case process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL:
                                    message = intl.formatMessage({id: 'page_operate_contribution_alert_contribution_exceeds_fiscal_limit_individual_plan'}, {maximum_amount: amount_max, pension_plan: contract?.plan.descripcion});
                                    break;
                                case process.env.REACT_APP_PLAN_PENSIONES_EMPRESA:
                                    message = intl.formatMessage({id: 'page_operate_contribution_alert_contribution_exceeds_fiscal_limit_employment_plan'}, {maximum_amount: amount_max, pension_plan: contract?.plan.descripcion});
                                    break;
                                case process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS:
                                    message = intl.formatMessage({id: 'page_operate_contribution_alert_contribution_exceeds_fiscal_limit_simplified_employment_plan'}, {maximum_amount: amount_max, pension_plan: contract?.plan.descripcion});
                                    break;
                            }

                            closeDialog().then(()=>{
                                alert({
                                    title: intl.formatMessage({id:'alert_info_title'}),
                                    message: message??''
                                });
                            });
                        }
                        break;

                }
                return intl.formatMessage({id: 'page_operate_contribution_'+errorKey});
            }
        }
        return null;
    }

    const validateField = (name:string, value:any)=>{
        switch(name){
            case 'amount':
              if(value && value.length>0 && parseFloat(value.replace(',', '.')) < (contract?.importeMinimoAportaciones ?? 0)){
                formErrors[name] = 'minimum amount of contributions';
                return false;
              }
              break;
            case 'revaluationPercentage':
                if(value && value.length>0 && formData.annualRevaluation===process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_FIJA && (parseFloat(value.replace(',', '.')) < 0 || parseFloat(value.replace(',', '.')) > 100)){
                    formErrors[name] = 'revaluation percentage between 0 100';
                    return false;
                }
                break;
          }
          return true;
    }

    const handleChangeAnyField= (name:string, value:any) => {
        const newValues = {
            ...formData,
            [name]: value,
        };
        setFormData(newValues);

        switch(name){
            case 'contributionType':
                if(value===process.env.REACT_APP_APORTACION_PERIODICA){
                    let periodicity = periodicitiesOptions.find(item=>item.valorBBDD===(contract?.periodicidadAportacion??''));
                    if(periodicity){

                        closeDialog().then(()=>{
                            alert({
                                title: intl.formatMessage({id:'alert_info_title'}),
                                message: intl.formatMessage({id:'page_operate_contribution_alert_exists_periodic_contribution'}),
                            });
                        });


                        const existContribution = {
                            ...newValues,
                            ['periodicity']: periodicity.cdPeriodicidad.toString(),
                            ['annualRevaluation']: contract?.tipoRevalorizacionAportacion ?? (process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_SIN??'0'),
                            ['revaluationPercentage']: contract?.tipoRevalorizacionAportacion===process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_FIJA ? (contract?.porcentRevalorizacionAportacion?.replace('.', ',') ?? '' ): '',
                            ['iban']: contract?.IBAN ?? '',
                            ['amount']: contract?.importeAportacion?.replace('.', ',') ?? ''
                        };
                        setFormData(existContribution);
                        setEditContributionPeriodic(true);

                        delete formErrors['periodicity'];
                        delete formErrors['annualRevaluation'];
                        delete formErrors['revaluationPercentage'];
                        delete formErrors['iban'];
                        delete formErrors['amount'];
                    }
                }else{
                    setEditContributionPeriodic(false);
                }
                break;
        }

        if(handleError(name)){
            delete formErrors[name];
        }
        validateField(name, value);
    };

    const handleCancelContributionPeriodic= (): Promise<void> =>
        new Promise((resolve) => {
            let contribution = getOperation();
            contribution.cancel = true;
            contribution.summary = [{title:'', value:'Anular aportación periódica'}].concat(getSummary());
            setOperationContribution(contribution);
            prop.onConfirm();
            resolve();
        });

    const handleSubmit = (data: any): Promise<void> =>
        new Promise((resolve) => {
            setLoadingForm(true);

            let operation = getOperation();
            addContribution(operation).then((result)=>{
                if(result.result){
                    operation.summary = getSummary();
                    setOperationContribution(operation);
                    prop.onConfirm();
                }else if(result.errors){
                    const errors = result.errors as any;
                    setFormErrors(result.errors);
                }
            }).finally(()=>{
                setLoadingForm(false);
                resolve();
            })
        });

    const getOperation = ()=>{
        let operation: OperationContribution = {
            contributionType: parseInt(formData.contributionType),
            amount: parseFloat(formData.amount.replace(',', '.')),
            iban: formData.iban,
            exists: editContributionPeriodic,
            confirm: false
        };

        if(formData.contributionType===process.env.REACT_APP_APORTACION_PERIODICA){
            operation.periodicity = parseInt(formData.periodicity);
            operation.annualRevaluation = parseInt(formData.annualRevaluation);
            if(formData.annualRevaluation===process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_FIJA){
                operation.revaluationPercentage = parseFloat(formData.revaluationPercentage.replace(',', '.'));
            }
        }
        return operation;
    }

    const getSummary= () => {
        let summary: Array<{title:string, value:string}> = [];

        if(formData.contributionType===process.env.REACT_APP_APORTACION_PERIODICA){
            summary.push({title:intl.formatMessage({id:'page_operate_contribution_title_aportation_type'}), value:'Periódica'});
            const periodicity = periodicitiesOptions.find(item=>item.cdPeriodicidad===parseInt(formData.periodicity));
            if(periodicity){
                summary.push({title:intl.formatMessage({id:'page_operate_contribution_title_periodicity'}), value:periodicity.nombreMultiPeriodicidad});
            }
            summary.push({title:intl.formatMessage({id:'page_operate_contribution_title_amount'}), value: formatAmount(parseFloat(formData.amount.replace(',', '.')))+'€'});

            switch(formData.annualRevaluation){
                case process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_IPC:
                    summary.push({title:intl.formatMessage({id:'page_operate_contribution_title_annual_revaluation'}), value:intl.formatMessage({id:'page_operate_contribution_annual_revaluation_ipc'})});
                    break;
                case process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_FIJA:
                    summary.push({title:intl.formatMessage({id:'page_operate_contribution_title_annual_revaluation'}), value: intl.formatMessage({id:'page_operate_contribution_annual_revaluation_fixed'})+': '+formatAmount(formData.revaluationPercentage)+'%'});
                    break;
                case process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_SIN:
                    summary.push({title:intl.formatMessage({id:'page_operate_contribution_title_annual_revaluation'}), value:intl.formatMessage({id:'page_operate_contribution_annual_revaluation_without'})});
                    break;
            }
            summary.push({title:intl.formatMessage({id:'page_operate_contribution_title_iban_origin_account'}), value: maskAccountNumber(formData.iban)});

        }else{
            summary.push({title:intl.formatMessage({id:'page_operate_contribution_title_aportation_type'}), value:'Única'});
            summary.push({title:intl.formatMessage({id:'page_operate_contribution_title_amount'}), value: formatAmount(parseFloat(formData.amount.replace(',', '.')))+'€'});
            summary.push({title:intl.formatMessage({id:'page_operate_contribution_title_iban_origin_account'}), value: maskAccountNumber(formData.iban)});
        }
        return summary;
    };


    const isSubmitDisabled = () => {
        if(formData.contributionType === process.env.REACT_APP_APORTACION_UNICA){
            return formData.amount.length===0 || formData.iban.length===0 || handleError('amount')!==null;
        }else if(formData.contributionType === process.env.REACT_APP_APORTACION_PERIODICA){
            return formData.amount.length===0 || handleError('amount')!==null
                    || formData.iban.length===0
                    || formData.periodicity.length===0
                    || formData.annualRevaluation.length===0
                    || (formData.annualRevaluation===process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_FIJA && (formData.revaluationPercentage.length===0 || handleError('revaluationPercentage')!==null))
        }
        return false;
    };

    return (
        <>{prop.loading ?
            <></>
        :
        <>
            <LoadingBar  visible={loading || loadingForm} />
            <Form onSubmit={handleSubmit} initialValues={initFormData} className={loadingForm ? 'loading' : ''}>
                <ResponsiveLayout className="inner">
                    <ButtonFixedFooterLayout
                        button={
                            screenSize.isDesktopOrBigger ? <ButtonLayout><ButtonSecondary onPress={() => {prop.onCancel ? prop.onCancel() : router.goBack()}}>{intl.formatMessage({id:'page_operate_contribution_action_return_operate'})}</ButtonSecondary></ButtonLayout> : undefined
                        }
                        secondaryButton={
                            <>
                                {
                                editContributionPeriodic ?
                                <>
                                    <ButtonLayout><ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_operate_contribution_periodic_action_continue'})}</ButtonPrimary></ButtonLayout>
                                    <ButtonLayout><ButtonDanger onPress={handleCancelContributionPeriodic}>{intl.formatMessage({id:'page_operate_contribution_periodic_action_cancel'})}</ButtonDanger></ButtonLayout>
                                </>
                                :
                                    <ButtonLayout><ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_operate_contribution_action_continue'})}</ButtonPrimary></ButtonLayout>
                                }
                            </>
                        }
                    >
                        <Box paddingBottom={screenSize.isDesktopOrBigger ? 56 : 16}>
                            <Stack space={32}>
                                <Stack space={16}>
                                    <Text5 color={skinVars.colors.brand}>{title}</Text5>
                                    <Text2 regular color={skinVars.colors.textSecondary}>{summary}</Text2>
                                </Stack>

                                <Stack space={16}>
                                    <Inline space={16} alignItems={'center'}>

                                        <Text4 medium>{intl.formatMessage({id:'page_operate_contribution_contribution_type'})}</Text4>
                                    </Inline>

                                    <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                        <Stack space={screenSize.isDesktopOrBigger ? 24 : 16}>
                                            <RadioGroup name="contributionType" onChange={(val:any)=> handleChangeAnyField('contributionType', val)}>
                                                <Grid columns={2} gap={screenSize.isDesktopOrBigger ? 24 : 16}>
                                                    <RadioButton
                                                        value={process.env.REACT_APP_APORTACION_UNICA??'1'}
                                                        render={({ controlElement, checked, labelId }:any) => (
                                                            <Boxed className={'rbx-option '+ (checked ? 'active' : '')}>
                                                                <Box padding={16}>
                                                                    <Inline space={8} alignItems='center'>
                                                                        {controlElement}
                                                                        <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_contribution_contribution_type_single'})}</Text2>
                                                                    </Inline>
                                                                </Box>
                                                            </Boxed>
                                                        )}
                                                    />

                                                    <RadioButton
                                                        value={process.env.REACT_APP_APORTACION_PERIODICA??'2'}
                                                        render={({ controlElement, checked, labelId }:any) => (
                                                            <Boxed className={'rbx-option '+ (checked ? 'active' : '')}>
                                                                <Box padding={16}>
                                                                    <Inline space={8} alignItems='center'>
                                                                        {controlElement}
                                                                        <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_contribution_contribution_type_periodic'})}</Text2>
                                                                    </Inline>
                                                                </Box>
                                                            </Boxed>
                                                        )}
                                                    />
                                                </Grid>
                                            </RadioGroup>

                                            {/* Rbx única */}
                                            {formData.contributionType===(process.env.REACT_APP_APORTACION_UNICA??'1') &&
                                                <Stack space={screenSize.isDesktopOrBigger ? 24 : 16}>
                                                    <DecimalField maxDecimals={2} fullWidth error={handleError('amount')!==null} helperText={handleError('amount')??intl.formatMessage({id: 'page_operate_contribution_minimum_amount_of_contributions'}, {minimum_amount: formatAmount(contract?.importeMinimoAportaciones ?? '', true)})} name="amount" label={intl.formatMessage({id:'page_operate_contribution_amount'})} onChangeValue={(val:any)=> handleChangeAnyField('amount', val)}  />
                                                </Stack>
                                            }

                                            {/* Rbx periódica */}
                                            {formData.contributionType===(process.env.REACT_APP_APORTACION_PERIODICA??'2') &&
                                                <Stack space={screenSize.isDesktopOrBigger ? 24 : 16}>
                                                    <Select
                                                        fullWidth
                                                        label={intl.formatMessage({id:'page_operate_contribution_periodicity'})}
                                                        name="periodicity"
                                                        value={formData.periodicity}
                                                        error={handleError('periodicity')!==null} helperText={handleError('periodicity')??undefined}
                                                        onChangeValue={(val:any)=> handleChangeAnyField('periodicity', val)}
                                                        options={periodicitiesOptions.map(item => ({text:item.nombreMultiPeriodicidad, value:item.cdPeriodicidad.toString()}))}
                                                    />

                                                    <DecimalField value={formData.amount} maxDecimals={2} fullWidth error={handleError('amount')!==null} helperText={handleError('amount')??intl.formatMessage({id: 'page_operate_contribution_minimum_amount_of_contributions'}, {minimum_amount: formatAmount(contract?.importeMinimoAportaciones ?? '', true)})} name="amount" label={intl.formatMessage({id:'page_operate_contribution_amount'})} onChangeValue={(val:any)=> handleChangeAnyField('amount', val)}  />

                                                    <Stack space={16}>
                                                        <Text4 medium>{intl.formatMessage({id:'page_operate_contribution_annual_revaluation'})}</Text4>

                                                        <RadioGroup name="annualRevaluation" value={formData.annualRevaluation} onChange={(val:any)=> handleChangeAnyField('annualRevaluation', val)}>
                                                            <Inline space={16} wrap>
                                                                <RadioButton
                                                                    value={process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_IPC??'1'}
                                                                    render={({ controlElement, checked, labelId }:any) => (
                                                                        <Inline space={8} alignItems='center'>
                                                                            {controlElement}
                                                                            <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_contribution_annual_revaluation_ipc'})}</Text2>
                                                                        </Inline>
                                                                    )}
                                                                />

                                                                <RadioButton
                                                                    value={process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_FIJA??'2'}
                                                                    render={({ controlElement, checked, labelId }:any) => (
                                                                        <Inline space={8} alignItems='center'>
                                                                            {controlElement}
                                                                            <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_contribution_annual_revaluation_fixed'})}</Text2>
                                                                        </Inline>
                                                                    )}
                                                                />

                                                                <RadioButton
                                                                    value={process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_SIN??'0'}
                                                                    render={({ controlElement, checked, labelId }:any) => (
                                                                        <Inline space={8} alignItems='center'>
                                                                            {controlElement}
                                                                            <Text2 regular color={checked ? skinVars.colors.brand : skinVars.colors.textPrimary}>{intl.formatMessage({id:'page_operate_contribution_annual_revaluation_without'})}</Text2>
                                                                        </Inline>
                                                                    )}
                                                                />
                                                            </Inline>
                                                        </RadioGroup>

                                                        {/* Rbx fija */}
                                                        {formData.annualRevaluation===(process.env.REACT_APP_APORTACION_PERIODICA_REVALORIZACION_FIJA??'2') &&
                                                            <DecimalField
                                                                fullWidth
                                                                name="revaluationPercentage"
                                                                value={formData.revaluationPercentage}
                                                                error={handleError('revaluationPercentage')!==null} helperText={handleError('revaluationPercentage')??undefined}
                                                                onChangeValue={(val:any)=> handleChangeAnyField('revaluationPercentage', val)}
                                                                label={intl.formatMessage({id:'page_operate_contribution_annual_revaluation_fixed'})}
                                                                maxDecimals={2}

                                                            />
                                                        }
                                                    </Stack>
                                                </Stack>
                                            }
                                        </Stack>
                                    </Grid>
                                </Stack>

                                <Stack space={16}>
                                    <Stack space={8}>
                                        <Text4 medium>{intl.formatMessage({id:'page_operate_contribution_origin_account'})} </Text4>

                                        <>
                                            {!screenSize.isDesktopOrBigger &&
                                                <Text2 regular>{intl.formatMessage({id:'page_operate_contribution_origin_account_description'})}</Text2>
                                            }
                                        </>
                                    </Stack>

                                    <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                        <Stack space={screenSize.isDesktopOrBigger ? 24 : 16}>
                                            <IbanField value={formData.iban} fullWidth name="iban" label={intl.formatMessage({id:'page_operate_contribution_iban_account'})} error={handleError('iban')!==null} helperText={handleError('iban')??undefined}  onChangeValue={(val:any)=> handleChangeAnyField('iban', val)}/>
                                        </Stack>

                                        <>
                                            {screenSize.isDesktopOrBigger &&
                                                <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_contribution_origin_account_description'})}</Text1>
                                            }
                                        </>
                                    </Grid>
                                </Stack>
                            </Stack>
                        </Box>
                    </ButtonFixedFooterLayout>
                </ResponsiveLayout>
            </Form>
        </>
        }</>
    );
};

export default ContributionPensionPlan;