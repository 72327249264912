import { useIntl } from 'react-intl';

import './Risk.scss';
import { Box, Circle, IconAddMoreRegular, IconInformationUserRegular, Inline, Stack, Text1, Text2, Text5, Text6, Tooltip, skinVars, useScreenSize, useWindowSize, SkeletonRectangle } from '@telefonica/mistica';
import { useEffect, useState } from 'react';
import { Product } from '../../entities/products/product';

interface RiskProps {
    product: Product | null
    loading?: boolean
}

const Risk: React.FC<RiskProps> = (prop) => {
    const intl = useIntl()

    const screenSize = useScreenSize();
    const size = useWindowSize();
    const [spaceLevel, setSpaceLevel] = useState(32 as -16 | -12 | -8 | -4 | -2 | 0 | 2 | 4 | 8 | 12 | 16 | 24 | 32 | 40 | 48 | 56 | 64 | 'between' | 'around' | 'evenly');

    useEffect(()=>{
        if(screenSize.isMobile){
            if(size.width<350){
                setSpaceLevel(4);
            }else if(size.width<400){
                setSpaceLevel(8);
            }else if(size.width<480){
                setSpaceLevel(12);
            }
        }else{
            setSpaceLevel(32);
        }

    }, [screenSize, size]);

    return (
            <>

                <style>{
                        '.risk--content{background-color:'+skinVars.colors.backgroundAlternative+';}'
                }</style>
                {prop.loading ?
                    <Box paddingY={12}>
                        <SkeletonRectangle height={248} />
                    </Box>
                    :
                    prop.product?.tipoRiesgoNumerico &&
                        <Box paddingY={24}>
                            <Box className='risk--content' paddingY={screenSize.isDesktopOrBigger ? 48 : 32} paddingX={screenSize.isDesktopOrBigger ? 80 : 16}>
                                <Stack space={24}>
                                    <Inline space={8} alignItems='center'>
                                        <Text5>{intl.formatMessage({id:'page_investment_funds_customer_risk_title'})}</Text5>
                                        <Tooltip
                                            targetLabel= {intl.formatMessage({id:'page_investment_funds_customer_risk_information'})}
                                            target={<IconInformationUserRegular size={22} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                            description=""
                                            position="top"
                                            extra={
                                                <Inline space={'evenly'}>
                                                    <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_investment_funds_customer_risk_description'})}</Text2>
                                                </Inline>
                                            }
                                        />
                                    </Inline>

                                    <Inline space={'between'} alignItems='center'>
                                        <Circle size={40} backgroundColor={skinVars.colors.background}>
                                            <Text6  color={skinVars.colors.brand}>-</Text6>
                                        </Circle>

                                        <Inline space={spaceLevel}>
                                            {Array.from({ length: 7 }, (_, i) => i + 1).map(item=>{

                                                return (
                                                    <Stack key={'risk-level-'+item} space={8}>
                                                        <Circle size={20} backgroundColor={(prop.product?.tipoRiesgoNumerico??0)>=item ? skinVars.colors.brand : 'transparent'} border={skinVars.colors.brand} />
                                                        <Text1 medium textAlign='center' dataAttributes={{'center':true}}>{item}</Text1>
                                                    </Stack>);
                                            })}
                                        </Inline>

                                        <Circle size={40} backgroundColor={skinVars.colors.background}>
                                            <IconAddMoreRegular color={skinVars.colors.brand}></IconAddMoreRegular>
                                        </Circle>
                                    </Inline>

                                    {screenSize.isDesktopOrBigger &&
                                        <Inline space={'between'}>
                                            <Box className='infoBox'>
                                                <Stack space={0}>
                                                    <Text2 regular>{intl.formatMessage({id:'page_investment_funds_customer_risk_info_lower_risk_description'})}</Text2>
                                                    <Text2 medium>{intl.formatMessage({id:'page_investment_funds_customer_risk_info_lower_risk'})}</Text2>
                                                </Stack>
                                            </Box>

                                            <Box className='infoBox align-right'>
                                                <Stack space={0}>
                                                    <Text2 regular>{intl.formatMessage({id:'page_investment_funds_customer_risk_info_higher_risk_description'})}</Text2>
                                                    <Text2 medium>{intl.formatMessage({id:'page_investment_funds_customer_risk_info_higher_risk'})}</Text2>
                                                </Stack>
                                            </Box>
                                        </Inline>
                                    }
                                </Stack>
                            </Box>
                        </Box>
                }
            </>
        );
};

export default Risk;