import { useIonRouter } from '@ionic/react';
import { Box, Boxed, ButtonLayout, ButtonPrimary, Checkbox, DateField, EmailField, Form, GridLayout, Inline, IntegerField, LoadingBar, PasswordField, ResponsiveLayout, Select, skinVars, Stack, Text1, Text3, Text4, TextField, TextLink } from "@telefonica/mistica";
import { NativeBiometric } from "capacitor-native-biometric";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAlert } from "../../../components/alerts/Alert";
import LayoutPublic from "../../../components/layout/LayoutPublic";
import { ListOption } from '../../../entities/commons/list-option.model';
import { getMasterDatas, updateDataUser, updateOperationsKeyUser, updatePasswordUser } from "../../../utils/apiclient";
import { getUrlNavigation } from "../../../utils/navigation";
import { getBiometricLogin, getLoginSaved, getPendingAction, getUser, removePendingAction, setBiometricLogin, setLoginSaved } from '../../../utils/storage';
import { getUrlDefaultUser, isNativeApp } from "../../../utils/utils";
import BiometricLoginPage from "../login/BiometricLoginPage";
import './IncompleteRegistrationPage.scss';

const IncompleteRegistrationPage: React.FC = (prop) => {
    const router = useIonRouter();
    const intl = useIntl();
    const { showAlert } = useAlert()
    const [loading, setLoading] = useState(false);
    const [biometricIsAvailable, setBiometricIsAvailable] = useState(false);
    const [pendingAction, setPendingAction] = useState(null as string|null);
    const biometricLogin = getBiometricLogin();

    const [genderOptions, setGenderOptions] = React.useState([] as ListOption[])
    const [countryOptions, setCountryOptions] = React.useState([] as ListOption[])
    const [provinceOptions, setProvinceOptions] = React.useState([] as ListOption[])
    const [viaOptions, setViaOptions] = React.useState([] as ListOption[])
    const [employmentSituationOptions, setEmploymentSituationOptions] = React.useState([] as ListOption[])
    const [activitySectorOptions, setActivitySectorOptions] = React.useState([] as ListOption[])
    const [activitySectorOptionsInit, setActivitySectorOptionsInit] = React.useState([] as ListOption[])

    //Loading form
    const [loadingForm, setLoadingForm] = useState(false);
    const user = getUser();

    const [formData, setFormData] = useState({
        acceptPrivacyPolicy: false,

        newPassword:(getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltaPassword') ? '' :undefined,
        repeatNewPassword:(getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltaPassword') ? '' :undefined,

        newOperationsKey:getPendingAction()==='AbrirVentanaDatosClaveRegistro' ? '' : undefined,
        repeatNewOperationsKey:getPendingAction()==='AbrirVentanaDatosClaveRegistro' ? '' : undefined,

        idCard: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user?.nif ?? "") : undefined,

        //Personal data
        dateOfExpiry : (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (!(user?.noCaducaNunca ?? false) ? user && user.fechaCaducidadNIF ? (moment.unix((user.fechaCaducidadNIF as any).timestamp).format("YYYY-MM-DD").toString()) : (user && user.fechaCaducidadTarjetaResidenteTS ? (moment.unix((user.fechaCaducidadTarjetaResidenteTS as any).timestamp).format("YYYY-MM-DD").toString()) : '') : '') : undefined,
        neverExpires: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user?.noCaducaNunca ?? false) : undefined,
        name: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user?.nombre ?? "" ) : undefined,
        surname: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user?.apellidos ?? "") : undefined,
        birthDate: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user && user.fechaNacimiento ? moment.unix((user.fechaNacimiento as any).timestamp).format("YYYY-MM-DD").toString() : '') : undefined,
        countryOfBirth: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user?.paisNacimiento?.toString() ?? '') : undefined,
        nationality: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user?.nacionalidad?.toString() ?? '') : undefined,
        countryOfResidence: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user?.paisResidencia?.toString() ?? '') : undefined,
        gender: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user?.genero ?? "") : undefined,

        //Contact information
        telephone: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user?.telefono ?? "") : undefined,
        mobile: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user?.movil ?? "") : undefined,
        email: (getPendingAction()==='AbrirVentanaDatosRegistroFaltaTodo' || getPendingAction()==='AbrirVentanaDatosRegistroFaltanDatos') ? (user?.email?.toLowerCase() ?? "") : undefined,

        //Tax address
        taxAddressCountry: user?.datosFiscales?.domicilioPais?.toString() ?? "",
        taxAddressZipcode: user?.datosFiscales?.domicilioCP ?? "",
        taxAddressProvince: user?.datosFiscales?.domicilioProvincia?.toString().padStart(2, '0') ?? "",
        taxAddressLocality: user?.datosFiscales?.domicilioPoblacion ?? "",
        taxAddressVia: user?.datosFiscales?.domicilioTipoVia?.toString() ?? "",
        taxAddressNameVia: user?.datosFiscales?.domicilioVia ?? "",
        taxAddressNumber: user?.datosFiscales?.domicilioNumero ?? "",
        taxAddressFloor: user?.datosFiscales?.domicilioPiso ?? "",
    } as {
        acceptPrivacyPolicy: boolean,

        newPassword?: string,
        repeatNewPassword?: string,

        newOperationsKey?: string,
        repeatNewOperationsKey?: string,

        idCard?: string,

        dateOfExpiry?: string,
        neverExpires?: boolean,
        name?: string,
        surname?: string,
        birthDate?: string,
        countryOfBirth?: string,
        nationality?: string,
        countryOfResidence?: string,
        gender?: string,

        telephone?: string,
        mobile?: string,
        email?: string,


        taxAddressCountry?: string,
        taxAddressZipcode?: string,
        taxAddressProvince?: string,
        taxAddressLocality?: string,
        taxAddressVia?: string,
        taxAddressNameVia?: string,
        taxAddressNumber?: string,
        taxAddressFloor?: string
    });


    const [formErrors, setFormErrors] = useState([] as any);

    useEffect(()=>{
        const pendingActionValue = getPendingAction();
        if(!pendingActionValue){
            router.push(getUrlDefaultUser());
            return;
        }


        setPendingAction(pendingActionValue);
        getMasterDatas('genres').then(options=>{
            setGenderOptions(options);
        });
        getMasterDatas('countries').then(options=>{
            setCountryOptions(options);
        });
        getMasterDatas('provinces').then(options=>{
            setProvinceOptions(options);
        });
        getMasterDatas('addressTypes').then(options=>{
            setViaOptions(options);
        });
        getMasterDatas('laboralSituation').then(options=>{
            setEmploymentSituationOptions(options);
        });
        getMasterDatas('laboralSectors').then(options=>{
            setActivitySectorOptionsInit(options);
        });

        if(isNativeApp){
          NativeBiometric.isAvailable().then((result)=>{
            setBiometricIsAvailable(result.isAvailable);
          });
        }
    }, []);

  const validateField = (name:string, value:any)=>{
    switch(name){
        case 'newPassword':
            if(formData.repeatNewPassword && formData.repeatNewPassword.length>0 && formData.repeatNewPassword!==value){
                formErrors['repeatNewPassword'] = 'password not match';
                return false;
            }
          break;
        case 'repeatNewPassword':
            if(formData.newPassword && formData.newPassword.length>0 && formData.newPassword!==value){
                formErrors[name] = 'password not match';
                return false;
            }
          break;
        case 'newOperationsKey':
            if(formData.repeatNewOperationsKey && formData.repeatNewOperationsKey.length>0 && formData.repeatNewOperationsKey!==value){
                formErrors['repeatNewOperationsKey'] = 'operations key not match';
                return false;
            }
            break;
        case 'repeatNewOperationsKey':
            if(formData.newOperationsKey && formData.newOperationsKey.length>0 && formData.newOperationsKey!==value){
                formErrors[name] = 'operations key not match';
                return false;
            }
            break;
        case 'name':
        case 'surname':
            if(!value || value.length===0){
            formErrors[name] = 'field required';
            return false;
            }
            break;
        case 'dateOfExpiry':
            const date = moment(value);
            const currentDate =  moment();
            if(date<currentDate){
            formErrors[name] = 'the date has expired';
            return false;
            }
            break;
        case 'email':
            const expressionMail: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if(!expressionMail.test(value)){
            formErrors[name] = 'is not a valid email';
            return false;
            }
            break;
    }
    return true;
  }


    const handleChangeAnyField= (name:string, value:any) => {
        const newValues = {
            ...formData,
            [name]: value,
        };
        setFormData(newValues);

        if(handleError(name)){
            delete formErrors[name];
        }
        validateField(name, value);

        switch(name){
          case 'neverExpires':
            validateField('dateOfExpiry', formData.dateOfExpiry);
            if(value===true){
              delete formErrors['dateOfExpiry'];
            }
            break;
        }
    };

    //Handle fields
    const handleError=(name:string) =>{
      if(formErrors && formErrors[name]){
        const error = formErrors[name];
        if(error){
          return intl.formatMessage({id: 'page_register_'+error.toLowerCase().replaceAll(' ', '_')});
        }
      }
      return null;
    }

    const isSubmitFormDisabled = () => {
      return !formData.acceptPrivacyPolicy ||
            (pendingAction==='AbrirVentanaDatosRegistroFaltaTodo' || pendingAction==='AbrirVentanaDatosRegistroFaltaPassword') && (
                (formData.newPassword!==undefined && (formData.newPassword.length===0 ||
                    !validateField('newPassword', formData.newPassword) ||
                    handleError('newPassword')!==null)) ||
                (formData.repeatNewPassword!==undefined && (formData.repeatNewPassword.length === 0 ||
                    !validateField('repeatNewPassword', formData.repeatNewPassword) ||
                    handleError('repeatNewPassword')!==null))) ||

            pendingAction==='AbrirVentanaDatosClaveRegistro' && (
                (formData.newOperationsKey!==undefined && (formData.newOperationsKey.length===0 ||
                    !validateField('newOperationsKey', formData.newOperationsKey) ||
                    handleError('newOperationsKey')!==null)) ||
                (formData.repeatNewOperationsKey!==undefined && (formData.repeatNewOperationsKey.length === 0 ||
                    !validateField('repeatNewOperationsKey', formData.repeatNewOperationsKey) ||
                    handleError('repeatNewOperationsKey')!==null))) ||

            (pendingAction==='AbrirVentanaDatosRegistroFaltaTodo' || pendingAction==='AbrirVentanaDatosRegistroFaltanDatos') && (
                      (!formData.neverExpires && (formData.dateOfExpiry!==undefined && (formData.dateOfExpiry.length===0 || handleError('dateOfExpiry')!==null ))) ||
                      (formData.name!==undefined && (formData.name.length === 0 || handleError('name')!==null)) ||
                      (formData.surname!==undefined && (formData.surname.length === 0 || handleError('surname')!==null)) ||
                      (formData.countryOfBirth!==undefined && (formData.countryOfBirth.length === 0 || handleError('countryOfBirth')!==null)) ||
                      (formData.nationality!==undefined && (formData.nationality.length === 0 || handleError('nationality')!==null)) ||
                      (formData.countryOfResidence!==undefined && (formData.countryOfResidence.length === 0 || handleError('countryOfResidence')!==null)) ||
                      (formData.gender!==undefined && (formData.gender.length === 0 || handleError('gender')!==null)) ||
                      (handleError('telephone')!==null) ||
                      (formData.mobile!==undefined && (formData.mobile.length === 0 || handleError('mobile')!==null)) ||
                      (formData.email!==undefined && (formData.email.length === 0 || handleError('email')!==null)));
    }

    const handleSubmit = (): Promise<void> =>
          new Promise((resolve) => {
              setLoadingForm(true);
              switch(pendingAction){
                case 'AbrirVentanaDatosRegistroFaltaPassword':
                    updatePasswordUser(formData).then((response)=>{
                        setTimeout(function(){
                          if(response.errors){
                            setFormErrors(response.errors);
                          }else if(response.result==='ok'){
                            afterCompleteData();
                          }
                        }, 500);
                      }).finally(()=>{
                        setLoadingForm(false);
                        resolve();
                      });
                    break;
                case 'AbrirVentanaDatosClaveRegistro':
                    updateOperationsKeyUser(formData).then((response)=>{
                        setTimeout(function(){
                            if(response.errors){
                                setFormErrors(response.errors);
                            }else if(response.result==='ok'){
                                afterCompleteData();
                            }
                        }, 500);
                    }).finally(()=>{
                        setLoadingForm(false);
                        resolve();
                    });
                    break;
                case 'AbrirVentanaDatosRegistroFaltaTodo':
                case 'AbrirVentanaDatosRegistroFaltanDatos':
                    const newValues = {
                        ...formData,
                        ['dateOfExpiry']: formData.neverExpires ? null : formData.dateOfExpiry,
                        ['countryOfBirth']: formData.countryOfBirth ? parseInt(formData.countryOfBirth) : '',
                        ['taxAddressNumber']: formData.taxAddressNumber ? parseInt(formData.taxAddressNumber) : '',
                        ['nationality']: formData.nationality ? parseInt(formData.nationality) : '',
                        ['countryOfResidence']: formData.countryOfResidence ? parseInt(formData.countryOfResidence) : ''
                        };
                    updateDataUser(newValues).then((response)=>{
                        setTimeout(function(){
                            if(response.errors){
                                setFormErrors(response.errors);
                            }else if(response.result){
                                afterCompleteData();
                            }
                        }, 500);
                    }).finally(()=>{
                        setLoadingForm(false);
                        resolve();
                    });
                    break;
              }
        });

    const afterCompleteData = ()=>{
        let loginSaved = getLoginSaved();
        if(loginSaved && (pendingAction==='AbrirVentanaDatosRegistroFaltaPassword' || pendingAction==='AbrirVentanaDatosRegistroFaltaTodo')){
            loginSaved.password = formData.newPassword;
            setLoginSaved(loginSaved);
        }

        removePendingAction();

        if(loginSaved && biometricIsAvailable){
            if(!biometricLogin){
              router.push(getUrlNavigation(BiometricLoginPage));
              return;
            }else{
              setBiometricLogin(loginSaved);
            }
        }
        router.push(getUrlDefaultUser());
    }


    return (
        <LayoutPublic
          seo={{metaTitle: intl.formatMessage({id:'page_register_seo_title'})}}
          header={{small:true, title: intl.formatMessage({id:'page_register_title'})}}
          hideFooter={true}
          requiresLoggedIn={true}
        >

            <ResponsiveLayout className='content' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
                <LoadingBar  visible={loading || loadingForm} />

                <ResponsiveLayout className='content' backgroundColor={isNativeApp ? skinVars.colors.backgroundAlternative : "transparent"}>
                    <ResponsiveLayout  fullWidth>
                        <div className='form-incomplete-register-page'>
                            <Box paddingY={80}>
                                <Boxed>
                                    {pendingAction==='AbrirVentanaDatosRegistroFaltaPassword' &&
                                        <Box padding={40}>
                                            <Stack space={32}>
                                                <Stack space={16}>
                                                    <Text4 medium>{intl.formatMessage({id:'page_incomplete_register_password_title'})}</Text4>
                                                    <Text3 regular>{intl.formatMessage({id:'page_incomplete_register_password_description'})}</Text3>
                                                </Stack>
                                                <Form onSubmit={handleSubmit} className={loadingForm ? 'loading' : ''}>
                                                    <Stack space={32}>
                                                        <GridLayout template='6+6' verticalSpace={16}
                                                            left={
                                                                <Stack space={8}>
                                                                    <PasswordField error={handleError('newPassword')!==null} helperText={handleError('newPassword')??undefined} fullWidth onChangeValue={(val)=>{handleChangeAnyField('newPassword', val)}} name="newPassword" label={intl.formatMessage({id:'page_incomplete_register_new_pasword'})}></PasswordField>
                                                                    <Text1 regular color={skinVars.colors.neutralMedium}>{intl.formatMessage({id:'page_register_access_password_help_text'})}</Text1>
                                                                </Stack>
                                                            }
                                                            right={<PasswordField error={handleError('repeatNewPassword')!==null} helperText={handleError('repeatNewPassword')??undefined} fullWidth onChangeValue={(val)=>{handleChangeAnyField('repeatNewPassword', val)}} name="repeatNewPassword" label={intl.formatMessage({id:'page_incomplete_register_new_repeat_password'})}></PasswordField>}
                                                        ></GridLayout>

                                                        <Box paddingTop={16}>
                                                            <Stack space={16}>
                                                                <div className='html-content contain-politics-text' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_register_politics_text'})}}></div>

                                                                <Checkbox onChange={(val:any)=> handleChangeAnyField('acceptPrivacyPolicy', val)} name="acceptPrivacyPolicy" render={({controlElement, labelId}:any) => (
                                                                    <Inline alignItems="center" space={16}>
                                                                        {controlElement}
                                                                        <Text3 regular id={labelId}>
                                                                            {intl.formatMessage({id:'page_register_accept_privacy_policy'},{privacy_policy:<TextLink onPress={()=>{ showAlert('_privacy_policy_') }}>{intl.formatMessage({id:'page_register_accept_privacy_policy_link_text'})}</TextLink>})}
                                                                        </Text3>
                                                                    </Inline>
                                                                )}></Checkbox>
                                                            </Stack>
                                                        </Box>



                                                        <ButtonLayout align='full-width'>
                                                            <ButtonPrimary submit disabled={isSubmitFormDisabled()}>
                                                                {intl.formatMessage({id:'page_login_recover_password_accept_text'})}
                                                            </ButtonPrimary>
                                                        </ButtonLayout>
                                                    </Stack>
                                                    </Form>
                                            </Stack>
                                        </Box>
                                    }
                                    {pendingAction==='AbrirVentanaDatosClaveRegistro' &&
                                        <Box padding={40}>
                                            <Stack space={32}>
                                                <Stack space={16}>
                                                    <Text4 medium>{intl.formatMessage({id:'page_incomplete_register_operations_key_title'})}</Text4>
                                                    <Text3 regular>{intl.formatMessage({id:'page_incomplete_register_operations_key_description'})}</Text3>
                                                </Stack>
                                                <Form onSubmit={handleSubmit} className={loadingForm ? 'loading' : ''}>
                                                    <Stack space={32}>
                                                        <GridLayout template='6+6' verticalSpace={16}
                                                            left={
                                                                <Stack space={8}>
                                                                    <PasswordField error={handleError('newOperationsKey')!==null} helperText={handleError('newOperationsKey')??undefined} fullWidth onChangeValue={(val)=>{handleChangeAnyField('newOperationsKey', val)}} name="newOperationsKey" label={intl.formatMessage({id:'page_incomplete_register_new_operations_key'})}></PasswordField>
                                                                    <Text1 regular color={skinVars.colors.neutralMedium}>{intl.formatMessage({id:'page_register_operations_key_help_text'})}</Text1>
                                                                </Stack>
                                                            }
                                                            right={<PasswordField error={handleError('repeatNewOperationsKey')!==null} helperText={handleError('repeatNewOperationsKey')??undefined} fullWidth onChangeValue={(val)=>{handleChangeAnyField('repeatNewOperationsKey', val)}} name="repeatNewOperationsKey" label={intl.formatMessage({id:'page_incomplete_register_new_repeat_operations_key'})}></PasswordField>}
                                                        ></GridLayout>

                                                        <Box paddingTop={16}>
                                                            <Stack space={16}>
                                                                <div className='html-content contain-politics-text' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_register_politics_text'})}}></div>

                                                                <Checkbox onChange={(val:any)=> handleChangeAnyField('acceptPrivacyPolicy', val)} name="acceptPrivacyPolicy" render={({controlElement, labelId}:any) => (
                                                                    <Inline alignItems="center" space={16}>
                                                                        {controlElement}
                                                                        <Text3 regular id={labelId}>
                                                                            {intl.formatMessage({id:'page_register_accept_privacy_policy'},{privacy_policy:<TextLink onPress={()=>{ showAlert('_privacy_policy_') }}>{intl.formatMessage({id:'page_register_accept_privacy_policy_link_text'})}</TextLink>})}
                                                                        </Text3>
                                                                    </Inline>
                                                                )}></Checkbox>
                                                            </Stack>
                                                        </Box>



                                                        <ButtonLayout align='full-width'>
                                                            <ButtonPrimary submit disabled={isSubmitFormDisabled()}>
                                                                {intl.formatMessage({id:'page_incomplete_register_action_continue'})}
                                                            </ButtonPrimary>
                                                        </ButtonLayout>
                                                    </Stack>
                                                    </Form>
                                            </Stack>
                                        </Box>
                                    }
                                    {(pendingAction==='AbrirVentanaDatosRegistroFaltaTodo' || pendingAction==='AbrirVentanaDatosRegistroFaltanDatos') &&
                                        <Box padding={40}>
                                            <Stack space={32}>
                                                <Stack space={16}>
                                                    <Text4 medium>{intl.formatMessage({id:'page_incomplete_register_personal_data_title'})}</Text4>
                                                    <Text3 regular>{intl.formatMessage({id:'page_incomplete_register_personal_data_description'})}</Text3>
                                                </Stack>
                                                <Form initialValues={formData} onSubmit={handleSubmit} className={loadingForm ? 'loading' : ''}>
                                                    <Stack space={32}>
                                                    <Stack space={16}>
                                                        <GridLayout verticalSpace={16} template='6+6'
                                                            left={
                                                             <TextField fullWidth disabled name="idCard" label={intl.formatMessage({id:'page_register_id_card'})}  />
                                                            }
                                                            right={
                                                            <Stack space={8}>
                                                                <DateField disabled={formData.neverExpires} error={handleError('dateOfExpiry')!==null} helperText={handleError('dateOfExpiry')??undefined}  fullWidth name="dateOfExpiry" label={intl.formatMessage({id:'page_register_date_of_expiry'})} onChangeValue={(val)=>{handleChangeAnyField('dateOfExpiry', val)}} />
                                                                <Checkbox onChange={(val:any)=> handleChangeAnyField('neverExpires', val)} name="neverExpires" render={({controlElement, labelId}:any) => (
                                                                    <Inline alignItems="center" space={16}>
                                                                    {controlElement}
                                                                    <Text1 regular>{intl.formatMessage({id:'page_register_never_expires'})}</Text1>
                                                                    </Inline>
                                                                )}></Checkbox>
                                                            </Stack>
                                                            }></GridLayout>

                                                        <GridLayout verticalSpace={16} template='6+6'
                                                            left={
                                                            <TextField fullWidth name="name" error={handleError('name')!==null} helperText={handleError('name')??undefined} label={intl.formatMessage({id:'page_register_name'})} onChangeValue={(val)=>{handleChangeAnyField('name', val)}} />
                                                            }
                                                            right={
                                                            <TextField fullWidth name="surname" error={handleError('surname')!==null} helperText={handleError('surname')??undefined} label={intl.formatMessage({id:'page_register_surname'})} onChangeValue={(val)=>{handleChangeAnyField('surname', val)}} />
                                                            }></GridLayout>

                                                        <GridLayout verticalSpace={16} template='6+6'
                                                            left={
                                                            <DateField disabled fullWidth name="birthDate" error={handleError('birthDate')!==null} helperText={handleError('birthDate')??undefined}  label={intl.formatMessage({id:'page_register_birthdate'})} />
                                                            }
                                                            right={
                                                            <Select fullWidth label={intl.formatMessage({id:'page_register_country_of_birth'})} name={'countryOfBirth'} error={handleError('countryOfBirth')!==null} helperText={handleError('countryOfBirth')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('countryOfBirth', val)}></Select>
                                                            }></GridLayout>

                                                        <GridLayout verticalSpace={16} template='6+6'
                                                            left={
                                                            <Select fullWidth label={intl.formatMessage({id:'page_register_nationality'})} name={'nationality'} error={handleError('nationality')!==null} helperText={handleError('nationality')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('nationality', val)}></Select>
                                                            }
                                                            right={
                                                            <Select fullWidth label={intl.formatMessage({id:'page_register_country_of_residence'})} name={'countryOfResidence'} error={handleError('countryOfResidence')!==null} helperText={handleError('countryOfResidence')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('countryOfResidence', val)}></Select>
                                                            }></GridLayout>


                                                        <GridLayout verticalSpace={16} template='6+6'
                                                            left={
                                                            <Select fullWidth label={intl.formatMessage({id:'page_register_gender'})} name={'gender'} error={handleError('gender')!==null} helperText={handleError('gender')??undefined} options={genderOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('gender', val)}></Select>
                                                            }
                                                            right={
                                                            <></>
                                                            }></GridLayout>

                                                        <Box paddingTop={16}>
                                                            <Text3 medium>{intl.formatMessage({id:'page_register_contact_details'})}</Text3>
                                                        </Box>

                                                        <GridLayout verticalSpace={16} template='6+6'
                                                            left={
                                                            <TextField fullWidth name="telephone" optional error={handleError('telephone')!==null} helperText={handleError('telephone')??undefined} label={intl.formatMessage({id:'page_register_telephone'})} onChangeValue={(val:any)=> handleChangeAnyField('telephone', val)} />
                                                            }
                                                            right={
                                                            <TextField fullWidth name="mobile" error={handleError('mobile')!==null} helperText={handleError('mobile')??undefined} label={intl.formatMessage({id:'page_register_mobile'})} onChangeValue={(val:any)=> handleChangeAnyField('mobile', val)} />
                                                            }></GridLayout>

                                                        <GridLayout verticalSpace={16} template='6+6'
                                                            left={
                                                            <EmailField fullWidth name="email" error={handleError('email')!==null} helperText={handleError('email')??undefined}  label={intl.formatMessage({id:'page_register_email'})} onChangeValue={(val:any)=> handleChangeAnyField('email', val)} maxLength={80} />
                                                            }
                                                            right={
                                                            <></>
                                                            }></GridLayout>

                                                        <Box paddingTop={16}>
                                                            <Text3 medium>{intl.formatMessage({id:'page_register_tax_residence'})}</Text3>
                                                        </Box>

                                                        <GridLayout verticalSpace={16} template='6+6'
                                                            left={
                                                            <Select disabled fullWidth label={intl.formatMessage({id:'page_register_country'})} name="taxAddressCountry" error={handleError('taxAddressCountry')!==null} helperText={handleError('taxAddressCountry')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressCountry', val)}></Select>
                                                            }
                                                            right={
                                                            <TextField disabled fullWidth name="taxAddressZipcode" error={handleError('taxAddressZipcode')!==null} helperText={handleError('taxAddressZipcode')??undefined} label={intl.formatMessage({id:'page_register_zipcode'})} maxLength={5} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressZipcode', val)} />
                                                            }></GridLayout>

                                                        <GridLayout verticalSpace={16} template='6+6'
                                                            left={
                                                            <Select fullWidth disabled  value={formData.taxAddressProvince} label={intl.formatMessage({id:'page_register_province'})} name='taxAddressProvince' error={handleError('taxAddressProvince')!==null} helperText={handleError('taxAddressProvince')??undefined} options={provinceOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressProvince', val)}></Select>
                                                            }
                                                            right={
                                                            <TextField disabled fullWidth value={formData.taxAddressLocality} label={intl.formatMessage({id:'page_register_locality'})} name='taxAddressLocality' error={handleError('taxAddressLocality')!==null} helperText={handleError('taxAddressLocality')??undefined} maxLength={70}  onChangeValue={(val:any)=> handleChangeAnyField('taxAddressLocality', val)} ></TextField>
                                                            }></GridLayout>

                                                        <GridLayout verticalSpace={16} template='6+6'
                                                            left={
                                                            <Select disabled fullWidth label={intl.formatMessage({id:'page_register_via'})} name="taxAddressVia" options={viaOptions.map(item => ({text:item.descripcion, value:item.valor}))} error={handleError('taxAddressVia')!==null} helperText={handleError('taxAddressVia')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressVia', val)}></Select>
                                                            }
                                                            right={
                                                            <TextField disabled fullWidth name="taxAddressNameVia" error={handleError('taxAddressNameVia')!==null} helperText={handleError('taxAddressNameVia')??undefined} label={intl.formatMessage({id:'page_register_name_via'})} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressNameVia', val)} />
                                                            }></GridLayout>

                                                        <GridLayout verticalSpace={16} template='6+6'
                                                            left={
                                                            <IntegerField disabled fullWidth label={intl.formatMessage({id:'page_register_number'})} name="taxAddressNumber"  error={handleError('taxAddressNumber')!==null} helperText={handleError('taxAddressNumber')??undefined} maxLength={5} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressNumber', val)}></IntegerField>
                                                            }
                                                            right={
                                                            <TextField disabled optional fullWidth name="taxAddressFloor" label={intl.formatMessage({id:'page_register_floor'})}  error={handleError('taxAddressFloor')!==null} helperText={handleError('taxAddressFloor')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressFloor', val)} maxLength={10}  />
                                                            }></GridLayout>
                                                        </Stack>

                                                        <Stack space={16}>
                                                            <Text4 medium>{intl.formatMessage({id:'page_incomplete_register_password_title'})}</Text4>
                                                            <Text3 regular>{intl.formatMessage({id:'page_incomplete_register_password_description'})}</Text3>
                                                        </Stack>
                                                        <GridLayout template='6+6' verticalSpace={16}
                                                            left={
                                                                <Stack space={8}>
                                                                    <PasswordField error={handleError('newPassword')!==null} helperText={handleError('newPassword')??undefined} fullWidth onChangeValue={(val)=>{handleChangeAnyField('newPassword', val)}} name="newPassword" label={intl.formatMessage({id:'page_incomplete_register_new_pasword'})}></PasswordField>
                                                                    <Text1 regular color={skinVars.colors.neutralMedium}>{intl.formatMessage({id:'page_register_access_password_help_text'})}</Text1>
                                                                </Stack>
                                                            }
                                                            right={<PasswordField error={handleError('repeatNewPassword')!==null} helperText={handleError('repeatNewPassword')??undefined} fullWidth onChangeValue={(val)=>{handleChangeAnyField('repeatNewPassword', val)}} name="repeatNewPassword" label={intl.formatMessage({id:'page_incomplete_register_new_repeat_password'})}></PasswordField>}
                                                        ></GridLayout>

                                                        <Box paddingTop={16}>
                                                            <Stack space={16}>
                                                                <div className='html-content contain-politics-text' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_register_politics_text'})}}></div>

                                                                <Checkbox onChange={(val:any)=> handleChangeAnyField('acceptPrivacyPolicy', val)} name="acceptPrivacyPolicy" render={({controlElement, labelId}:any) => (
                                                                    <Inline alignItems="center" space={16}>
                                                                        {controlElement}
                                                                        <Text3 regular id={labelId}>
                                                                            {intl.formatMessage({id:'page_register_accept_privacy_policy'},{privacy_policy:<TextLink onPress={()=>{ showAlert('_privacy_policy_') }}>{intl.formatMessage({id:'page_register_accept_privacy_policy_link_text'})}</TextLink>})}
                                                                        </Text3>
                                                                    </Inline>
                                                                )}></Checkbox>
                                                            </Stack>
                                                        </Box>



                                                        <ButtonLayout align='full-width'>
                                                            <ButtonPrimary submit disabled={isSubmitFormDisabled()}>
                                                                {intl.formatMessage({id:'page_incomplete_register_action_continue'})}
                                                            </ButtonPrimary>
                                                        </ButtonLayout>
                                                    </Stack>
                                                    </Form>
                                            </Stack>
                                        </Box>
                                    }
                                </Boxed>
                            </Box>
                        </div>
                    </ResponsiveLayout>
                </ResponsiveLayout>
        </ResponsiveLayout>
    </LayoutPublic>
  );
};


export default IncompleteRegistrationPage;