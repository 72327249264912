import { Box, Boxed, ButtonLink, ButtonPrimary, ButtonSecondary, dialog, Grid, GridLayout, IconArrowDownRegular, IconCheckedLight, IconFileErrorRegular, Inline, LoadingBar, ResponsiveLayout, skinVars, Stack, Text2, Text4, Text5, useScreenSize } from '@telefonica/mistica';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DropZone from '../../../../../components/drop-zone/DropZone';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import { PlanPromotor } from '../../../../../entities/clients/plan-promotor';
import { getPromoterDocument, sendWorkerProfile } from '../../../../../utils/apiclient';
import { getSelectedPlanPromotor } from '../../../../../utils/storage';
import { trackEvent } from '../../../../../utils/utils';

import './WorkerProfilePromoterPage.scss';

const WorkerProfilePromoterPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
  const [uploadedFile, setUploadedFile]= useState(null as null|any);
  const [planPromotor, setPlanPromotor]= useState(null as null|PlanPromotor);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(null as string|null);

  useEffect(()=>{
    getDataPromotor();
  }, []);

  const getDataPromotor = ()=>{
    const planPromotorSel = getSelectedPlanPromotor();

    if(planPromotorSel){
      setPlanPromotor(planPromotorSel);
    }

  }
  const downloadDocument= (type:string) => {
    setLoadingDownload(true);
    setLoadingDocument(type);
    getPromoterDocument(type).finally(()=>{
      setLoadingDownload(false);
      setLoadingDocument(null);
    });
  };


  const onUploaded= (file:any) => {
    setUploadedFile(file);
  }



  const uploadDocument = ()=>{
    if(uploadedFile?.binaryStr && uploadedFile?.name){
      const file = uploadedFile?.binaryStr;
      const fileName = uploadedFile?.name ?? '';
      setLoadingForm(true);
      sendWorkerProfile({file: file, fileName: fileName}).then(result=>{
        let extra='';

        if(result.result){
            dialog({
              icon:<IconCheckedLight color={skinVars.colors.brand} />,
              title: intl.formatMessage({id:'page_promoter_worker_profile_sended_ok_title'}),
              acceptText: intl.formatMessage({id:'page_promoter_worker_profile_action_accept'}),
              message: "",
          });
          return;
        }else if(result.errors){
            extra+='<ul class="errors-worker-profile">';
            result.errors.map((item, index)=>{
                extra+= '<li><strong>'+item.split('|')[0]+'</strong>: '+item.split('|')[1];
            });
            extra+='</ul>';
        }

        dialog({
            icon:<IconFileErrorRegular color={skinVars.colors.textLinkDanger} />,
            title: intl.formatMessage({id:'page_promoter_worker_profile_sended_error_title'}),
            subtitle: intl.formatMessage({id:'page_promoter_worker_profile_sended_error_subtitle'}, {mail:planPromotor?.emailTipoTrabajadores}),
            acceptText: "",
            message: "",
            extra: extra.length>0 ? <div className='html-content' color={skinVars.colors.textSecondary} dangerouslySetInnerHTML={{__html: extra}}></div> : undefined,
            className:'dialog-without-buttons multiple-lines'
        });

      }).finally(()=>{
        setLoadingForm(false);
      });

    }
  };

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_promoter_worker_profile_title'})}}
      pageInfo={{option: process.env.REACT_APP_AREA_PROMOTORES, role:'promoter'}}
      onChangeUser={()=>{getDataPromotor()}}
      title={intl.formatMessage({id:'page_promoter_worker_profile_title'})}>

      <style>{
        '.workerProfile--content .boxColor{background-color:'+skinVars.colors.backgroundAlternative+';}'+
        '.workerProfile--content [data-column-data="true"] >div:before{background-color:'+skinVars.colors.textSecondary+';}'+
        '.html-content ul.errors-worker-profile {color:'+skinVars.colors.textSecondary+';}'
      }</style>
      <LoadingBar visible={loadingDownload || loadingForm} />

      <ResponsiveLayout className='inner first workerProfile--content'>
        <Stack space={screenSize.isDesktopOrBigger ? 40 : 24}>

          {screenSize.isDesktopOrBigger &&
            <Stack space={16}>
              <Inline space={'between'} alignItems='center'>
                <Text5>{intl.formatMessage({id:'page_promoter_worker_profile_title'})}</Text5>
              </Inline>
            </Stack>
          }

          <Box paddingBottom={32}>
            <GridLayout template={'8+4'} verticalSpace={32}
              left={
                <Stack space={24}>
                  <Boxed>
                    <Box className='boxStep' padding={24}>
                      <Stack space={16}>
                        <Stack space={2}>
                          <Text4 medium>{intl.formatMessage({id:'page_promoter_worker_profile_step_1'})}</Text4>
                          <Text2 regular>{intl.formatMessage({id:'page_promoter_worker_profile_step_1_description'})}</Text2>
                        </Stack>

                        <Inline space={16} alignItems='center'>
                          <ButtonSecondary showSpinner={loadingDownload && loadingDocument==='model-worker-profile'} onPress={() => {downloadDocument('model-worker-profile');  trackEvent('promotor-tipo_trabajador-descargar_modelo')}}>
                            {intl.formatMessage({id:'page_promoter_worker_profile_step_1_download_model'})}
                            <IconArrowDownRegular color="currentColor" />
                          </ButtonSecondary>

                          <ButtonLink showSpinner={loadingDownload && loadingDocument==='help-worker-profile'} onPress={() => {downloadDocument('help-worker-profile');  trackEvent('promotor-tipo_trabajador-consultar_guia') }}>{intl.formatMessage({id:'page_promoter_worker_profile_step_1_download_help'})}</ButtonLink>
                        </Inline>
                      </Stack>
                    </Box>
                  </Boxed>

                  <Boxed>
                    <Box className='boxStep' padding={24}>
                      <Stack space={screenSize.isDesktopOrBigger ? 32 : 16}>
                        <Stack space={2}>
                          <Text4 medium>{intl.formatMessage({id:'page_promoter_worker_profile_step_2'})}</Text4>
                          <Text2 regular>{intl.formatMessage({id:'page_promoter_worker_profile_step_2_description'})}</Text2>
                        </Stack>
                        <DropZone reset={!loadingForm} title={intl.formatMessage({id:'page_promoter_worker_profile_step_2_upload_file'})} onUploaded={(val)=>onUploaded(val)} />

                        <ButtonPrimary showSpinner={loadingForm} disabled={!uploadedFile} onPress={() => {uploadDocument()}}>
                          {intl.formatMessage({id:'page_promoter_worker_profile_step_2_action_send'})}
                        </ButtonPrimary>
                      </Stack>
                    </Box>
                  </Boxed>
                </Stack>
              }
              right={
                <Stack space={16}>
                  <Boxed className='boxColor'>
                    <Box paddingX={24} paddingY={32}>
                      <Stack space={16}>
                        <Text2 regular>{intl.formatMessage({id:'page_promoter_worker_profile_help_title'})}</Text2>
                        <Grid columns={3} gap={2} dataAttributes={{"column-data":true}}>
                          <Stack space={2} className='columnData'>
                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_promoter_worker_profile_help_fund'})}</Text2>
                            <Text2 medium>{planPromotor?.codigo_fondo}</Text2>
                          </Stack>

                          <Stack space={2} className='columnData'>
                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_promoter_worker_profile_help_plan'})}</Text2>
                            <Text2 medium>{planPromotor?.codigo_plan}</Text2>
                          </Stack>

                          <Stack space={2} className='columnData'>
                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_promoter_worker_profile_help_subplan'})}</Text2>
                            <Text2 medium>{planPromotor?.codigo_subplan}</Text2>
                          </Stack>
                        </Grid>
                      </Stack>
                    </Box>
                  </Boxed>
                </Stack>
              }
            />
          </Box>
        </Stack>
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default WorkerProfilePromoterPage;
