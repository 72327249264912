import { Language } from '../entities/commons/language.model';
import { isNativeApp } from './utils';
let languages : Language[]= [{cdLang: 1, text:'ES',value:'es', href:'/', locale:'es-ES', phoneNumberFormattingRegionCode:'ES'}, {cdLang: 2,text:'EN',value:'en', href:'/en', locale:'en-US', phoneNumberFormattingRegionCode:'ES'}];

export const getCurrentLanguage= () : Language => {
    const currentLang = languages.find(item=>item.value===getCurrentLang())
    return currentLang ? currentLang : languages[0];
}

export const getCurrentLang = () : string => {
    let currentLanguage = 'es';
    if(isNativeApp){
        if (window.Intl && typeof window.Intl === 'object' && (window?.navigator?.language?.toLocaleLowerCase() ?? 'es').indexOf('en')===0) {
            currentLanguage='en'
        }
    }
    languages.forEach(item=>{
        if(window.location.href.indexOf('/'+item.value+'/')!==-1 || window.location.pathname==='/en'){
            currentLanguage = item.value;
        }
    })

    return currentLanguage;
}

export const getLanguages = () : Language[] => {
    const language = getCurrentLang();
    languages.map(item=>{
        if(item.value===(language ? language : process.env.REACT_APP_LANGUAGE_DEFAULT)){
            item.selected=true;
        }
        return item;
    })

    return languages;
};