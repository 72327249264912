import { Box, Inline, ResponsiveLayout, Text2, Text4 } from '@telefonica/mistica';
import React from 'react';

import { useIntl } from 'react-intl';
import { formatBalance } from '../../utils/utils';
import './totalBalance.scss';


interface TotalBalanceBaseProps {
    icon: React.ReactNode
}

interface TotalBalanceProps extends TotalBalanceBaseProps {
    balance: number,
    rights?: number
}

interface TotalRightsProps extends TotalBalanceBaseProps {
    balance?: number,
    rights: number
}
type TotalBalancePr = TotalBalanceProps | TotalRightsProps;

const TotalBalance: React.FC<TotalBalancePr> = ({
    icon,
    balance,
    rights
  } : TotalBalancePr) => {
    const intl = useIntl();

return (
            <ResponsiveLayout fullWidth isInverse className='box--totalBalance'>
                <Inline space={0} alignItems='center'>
                    <Box padding={12} className='box--icon'>
                        {icon}
                    </Box>

                    <Box padding={12}>
                        <Inline space={8} alignItems='center'>
                            <Text2 regular>{typeof rights==='number' ? intl.formatMessage({id:'page_plans_customer_rights_total'}) : intl.formatMessage({id:'page_investment_funds_customer_balance_total'})}</Text2>
                            <Text4 medium>{formatBalance(typeof rights==='number' ? rights : balance ?? 0)}€</Text4>
                        </Inline>
                    </Box>
                </Inline>
            </ResponsiveLayout>
        );
};

export default TotalBalance;