import { useIntl } from 'react-intl';
import { useIonRouter } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import ChipTabs from '../../../../components/chip-tabs/ChipTabs';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import MembersOf from '../../../../components/staticPages/membersOf/MembersOf';
import EmptyStateCustom from '../../../../components/empty-state/EmptyStateCustom';

import ForInvestorPage from '../../for-investor/ForInvestorPage';

import { getUrlNavigation } from '../../../../utils/navigation';

import { Box, ButtonPrimary, GridLayout, Hero, IconAntennaRegular, IconBillQueriesEuroLight, IconComputerUserLight, IconCreditBalanceEuroLight, IconDisabledLight, IconLogoutLight, IconMobileEuroLight, IconMultisaveLight, IconSharedMobileDataLight, IconTeamLight, Image, Inline, ResponsiveLayout, Row, RowList, Stack, Text3, Text5, Text6, Text7, Text8, skinVars } from '@telefonica/mistica';
import '../LandingPage.scss';


const FiscalExercisePage: React.FC = () => {
  const intl = useIntl();
  const router = useIonRouter();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [options, setOptions] = useState([intl.formatMessage({id:'page_fiscal_exercise_chip_ppi'}), intl.formatMessage({id:'page_fiscal_exercise_chip_fi'}), intl.formatMessage({id:'page_fiscal_exercise_chip_ppe'})] as string[]);
  const [valuesPP, setValuesPP] = useState([] as Array<{title:string, icon:React.ReactNode, active?:boolean, description: React.ReactNode  }>);
  const [valuesFI, setValuesFI] = useState([] as Array<{title:string, icon:React.ReactNode, active?:boolean, description: React.ReactNode  }>);
  const [valuesPPE, setValuesPPE] = useState([] as Array<{title:string, icon:React.ReactNode, active?:boolean, description: React.ReactNode  }>);

  useEffect(()=>{
    handleActiveItemPP(0);
    handleActiveItemFI(0);
    handleActiveItemPPE(0);
  }, []);

  const handleActiveItemPP = (value: number) => {
    let itemsPP: Array<{
      title: string,
      icon: React.ReactNode,
      active?: boolean,
      description: React.ReactNode 
    }>;

    itemsPP = [
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_1_title'}),
        icon: <IconCreditBalanceEuroLight/>,
        active: true,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_1_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_2_title'}),
        icon: <IconDisabledLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_2_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_3_title'}),
        icon: <IconComputerUserLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_3_description'})}}></div>    
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_4_title'}),
        icon: <IconBillQueriesEuroLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_4_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_5_title'}),
        icon: <IconMultisaveLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_5_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_6_title'}),
        icon: <IconTeamLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppi_question_6_description'})}}></div>
      }
    ];

    const itemsActive = itemsPP.map((item, index)=>{
        if(index==value){
            item.active=true;
        }else{
            item.active=false;
        }
        return item;
    });

    setValuesPP(itemsActive);
  };

  const handleActiveItemFI = (value: number) => {
    let itemsFI: Array<{
      title: string,
      icon: React.ReactNode,
      active?: boolean,
      description: React.ReactNode 
    }>;

    itemsFI = [
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_fi_question_1_title'}),
        icon: <IconMobileEuroLight/>,
        active: true,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_fi_question_1_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_fi_question_2_title'}),
        icon: <IconSharedMobileDataLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_fi_question_2_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_fi_question_3_title'}),
        icon: <IconLogoutLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_fi_question_3_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_fi_question_4_title'}),
        icon: <IconMobileEuroLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_fi_question_4_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_fi_question_5_title'}),
        icon: <IconSharedMobileDataLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_fi_question_5_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_fi_question_6_title'}),
        icon: <IconMobileEuroLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_fi_question_6_description'})}}></div>
      }
    ];

    const itemsActive = itemsFI.map((item, index)=>{
        if(index==value){
            item.active=true;
        }else{
            item.active=false;
        }
        return item;
    });

    setValuesFI(itemsActive);
  };

  const handleActiveItemPPE = (value: number) => {
    let itemsPPE: Array<{
      title: string,
      icon: React.ReactNode,
      active?: boolean,
      description: React.ReactNode 
    }>;

    itemsPPE = [
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_1_title'}),
        icon: <IconCreditBalanceEuroLight/>,
        active: true,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_1_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_2_title'}),
        icon: <IconBillQueriesEuroLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_2_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_3_title'}),
        icon: <IconCreditBalanceEuroLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_3_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_4_title'}),
        icon: <IconBillQueriesEuroLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_4_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_5_title'}),
        icon: <IconCreditBalanceEuroLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_5_description'})}}></div>
      },
      {
        title: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_6_title'}),
        icon: <IconBillQueriesEuroLight/>,
        description: <div className='html-content' 
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_fiscal_exercise_ppe_question_6_description'})}}></div>
      }
    ];

    const itemsActive = itemsPPE.map((item, index)=>{
        if(index==value){
            item.active=true;
        }else{
            item.active=false;
        }
        return item;
    });

    setValuesPPE(itemsActive);
  };

  return (
      <LayoutPublic
        landing={true}
        header={{small:true}}
        seo={{metaTitle: intl.formatMessage({id:'page_fiscal_exercise_seo_title'}), metaDescription: intl.formatMessage({id:'page_fiscal_exercise_seo_description'})}}
      >
        <ResponsiveLayout className='content landingPage--content' fullWidth backgroundColor={skinVars.colors.backgroundAlternative}>
          <Box paddingBottom={40}>
                <Hero
                  background="alternative"
                  title= {intl.formatMessage({id:'page_fiscal_exercise_title'})}
                  media={<Image src="../assets/img/Renta 2020_slider.jpg" width="100%" height='337px'/>}
                  desktopMediaPosition="right"
                  extra={
                  <div className='html-content landingPageERE--subtitle' style={{ color: `${skinVars.colors.textSecondary} !important` }} dangerouslySetInnerHTML={{__html:
                    intl.formatMessage({id:'page_fiscal_exercise_subtitle'})
                  }}></div>
                  }
                />
          </Box>
        </ResponsiveLayout>
        <ResponsiveLayout className='content landingPage--content' fullWidth backgroundColor={skinVars.colors.background}>
          <div id='content-chips'>
            <ChipTabs
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex} 
                tabs={options.map((text) => ({
                  text,
                }))}
            />
          </div>
          <ResponsiveLayout className={(options.length>0 && options[selectedIndex]===intl.formatMessage({id:'page_fiscal_exercise_chip_ppi'}) ? 'd-block' : 'd-none')}>
            <Stack space={24}>
              <Box paddingBottom={24}>
                <GridLayout
                    template='3+9'
                    left={
                      <Box>
                        <RowList dataAttributes={{ "list-menu": true }}>
                          {valuesPP.map((item, index) => (
                            item.title != '' && 
                              <Row
                                  title=''
                                  onPress={() => { handleActiveItemPP(index) }}
                                  right={null}
                                  key={'ppi-items' + index}
                                  extra={
                                      <Inline space={'between'} alignItems='center'>
                                          <Text3 regular color={(item.active ?? false) ? skinVars.colors.brand : skinVars.colors.textPrimary }>
                                              <Inline space={16} alignItems='center'>
                                                  {item.icon}
                                                  <Text3 regular color={'currentColor'}>{item.title}</Text3>
                                              </Inline>
                                          </Text3>
                                          {item.active &&
                                              <div className='hr--vertical' style={{ backgroundColor: skinVars.colors.brand }}></div>
                                          }
                                      </Inline>
                                  }
                              />
                          ))}
                        </RowList>
                      </Box>
                    }
                    right={
                      <Box className='pane-menu'>
                        {
                          valuesPP.map((itemParent)=>(
                            itemParent.active===true &&
                            <>{itemParent.description}</>
                          ))
                        }
                      </Box>
                    }
                />
              </Box>
              <div className='landingPageIRPF--containerVideos'>
                <iframe 
                    src={intl.formatMessage({id:'page_fiscal_exercise_ppi_video_1'})}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    className='leftVideo'
                ></iframe>
                <iframe 
                    src={intl.formatMessage({id:'page_fiscal_exercise_ppi_video_2'})}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    className='rightVideo'
                ></iframe>
              </div>

              <EmptyStateCustom 
                largeImageUrl="../assets/img/ejercicio-fiscal.jpg"
                title={intl.formatMessage({id:'page_fiscal_exercise_calculator_title'})}
                button={<ButtonPrimary onPress={() => {router.push(getUrlNavigation(ForInvestorPage));}}>{intl.formatMessage({id:'page_fiscal_exercise_calculator_button'})}</ButtonPrimary>}
              />
              <Box paddingBottom={80}>
                <div style={{  margin: '0 auto' }}> 
                    <Text5 color={skinVars.colors.brand}>
                      <div style={{textAlign: 'center'}}>
                        {intl.formatMessage({id:'page_fiscal_exercise_ppi_blue_title'})}
                      </div>
                    </Text5>
                </div>
              </Box>
            </Stack>
          </ResponsiveLayout>
          <ResponsiveLayout className={(options.length>0 && options[selectedIndex]===intl.formatMessage({id:'page_fiscal_exercise_chip_fi'}) ? 'd-block' : 'd-none')}>
            <Stack space={24}>
              <Box paddingBottom={24}>
                <GridLayout
                    template='3+9'
                    left={
                      <Box>
                        <RowList dataAttributes={{ "list-menu": true }}>
                          {valuesFI.map((item, index) => (
                            item.title != '' && 
                              <Row
                                  title=''
                                  onPress={() => { handleActiveItemFI(index) }}
                                  right={null}
                                  key={'fi-items' + index}
                                  extra={
                                    <Inline space={'between'} alignItems='center'>
                                        <Text3 regular color={(item.active ?? false) ? skinVars.colors.brand : skinVars.colors.textPrimary }>
                                            <Inline space={16} alignItems='center'>
                                                {item.icon}
                                                <Text3 regular color={'currentColor'}>{item.title}</Text3>
                                            </Inline>
                                        </Text3>
                                        {item.active &&
                                            <div className='hr--vertical' style={{ backgroundColor: skinVars.colors.brand }}></div>
                                        }
                                    </Inline>
                                  }
                              />
                          ))}
                        </RowList>
                      </Box>
                    }
                    right={
                      <Box className='pane-menu'>
                        {
                          valuesFI.map((itemParent)=>(
                            itemParent.active===true &&
                            <>{itemParent.description}</>
                          ))
                        }
                      </Box>
                    }
                />
              </Box>
              <div className='landingPageIRPF--containerSingleVideo'>
                <iframe 
                    src={intl.formatMessage({id:'page_fiscal_exercise_fi_video_1'})}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    className='video'
                ></iframe>
              </div>
              <Box paddingY={80}>
                <div style={{ maxWidth: '800px', margin: '0 auto' }}> 
                    <Text5 color={skinVars.colors.brand}>
                      <div style={{textAlign: 'center'}}>
                        {intl.formatMessage({id:'page_fiscal_exercise_fi_blue_title'})}
                      </div>
                    </Text5>
                </div>
              </Box>
            </Stack>
          </ResponsiveLayout>
          <ResponsiveLayout className={(options.length>0 && options[selectedIndex]===intl.formatMessage({id:'page_fiscal_exercise_chip_ppe'}) ? 'd-block' : 'd-none')}>
            <Stack space={24}>
              <Box paddingBottom={24}>
                <GridLayout
                    template='3+9'
                    left={
                      <Box>
                        <RowList dataAttributes={{ "list-menu": true }}>
                          {valuesPPE.map((item, index) => (
                            item.title != '' && 
                              <Row
                                  title=''
                                  onPress={() => { handleActiveItemPPE(index) }}
                                  right={null}
                                  key={'ppe-items' + index}
                                  extra={
                                      <Inline space={'between'} alignItems='center'>
                                          <Text3 regular color={(item.active ?? false) ? skinVars.colors.brand : skinVars.colors.textPrimary }>
                                              <Inline space={16} alignItems='center'>
                                                  {item.icon}
                                                  <Text3 regular color={'currentColor'}>{item.title}</Text3>
                                              </Inline>
                                          </Text3>
                                          {item.active &&
                                              <div className='hr--vertical' style={{ backgroundColor: skinVars.colors.brand }}></div>
                                          }
                                      </Inline>
                                  }
                              />
                          ))}
                        </RowList>
                      </Box>
                    }
                    right={
                      <Box className='pane-menu'>
                        {
                          valuesPPE.map((itemParent)=>(
                            itemParent.active===true &&
                            <>{itemParent.description}</>
                          ))
                        }
                      </Box>
                    }
                />
              </Box>
              <div style={{ maxWidth: '800px', margin: '0 auto', width: '60%' }}>
                <iframe 
                    src={intl.formatMessage({id:'page_fiscal_exercise_ppe_video_1'})}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    style={{ width: '100%', height: '350px' }} 
                ></iframe>
              </div>
              <EmptyStateCustom 
                largeImageUrl="../assets/img/ejercicio-fiscal.jpg"
                title={intl.formatMessage({id:'page_fiscal_exercise_calculator_title'})}
                button={<ButtonPrimary onPress={() => {router.push(getUrlNavigation(ForInvestorPage));}}>{intl.formatMessage({id:'page_fiscal_exercise_calculator_button'})}</ButtonPrimary>}
              />
              <Box paddingBottom={80}>
                <div style={{ maxWidth: '800px', margin: '0 auto' }}> 
                    <Text5 color={skinVars.colors.brand}>
                      <div style={{textAlign: 'center'}}>
                        {intl.formatMessage({id:'page_fiscal_exercise_ppe_blue_title'})}
                      </div>
                    </Text5>
                </div>
              </Box>
            </Stack>
          </ResponsiveLayout>
        </ResponsiveLayout>
      </LayoutPublic> 
  );
};

export default FiscalExercisePage;