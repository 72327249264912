import { useIntl } from 'react-intl';

import { Box, Boxed, Circle, Inline, skinVars, Text1, useScreenSize, useWindowSize } from '@telefonica/mistica';
import { Product } from '../../entities/products/product';
import './RiskSmall.scss';

interface RiskProps {
    product: Product | null
}

const RiskSmall: React.FC<RiskProps> = (prop) => {
    const intl = useIntl()

    return (
            <Boxed className='riskLevelBox'>
                <Box padding={12}>
                <Inline space={'between'} alignItems='center'>
                    <Text1 medium>{intl.formatMessage({id:'page_pension_plans_blue_card_risk'})}</Text1>

                    <Inline space={4}>
                        {Array.from({ length: 7 }, (_, i) => i + 1).map(item=>{
                            return (
                                <Circle key={'risk-level-'+item}  size={11} backgroundColor={(prop.product?.tipoRiesgoNumerico??0)>=item ? skinVars.colors.brand : 'transparent'} border={skinVars.colors.brand} />);
                        })}
                    </Inline>
                </Inline>
                </Box>
            </Boxed>
        );
};

export default RiskSmall;