import { useIonRouter } from '@ionic/react';
import { Box, ButtonSecondary, GridLayout, IconFlowerLight, IconSearchFileRegular, Inline, LoadingBar, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Text5, useScreenSize } from '@telefonica/mistica';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import EmptyCard from '../../../../../components/empty-card/EmptyCard';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import { useProductActions } from '../../../../../components/operate/ProductActions';
import ProductCard from '../../../../../components/product/ProductCard';
import TotalBalance from '../../../../../components/total-balance/totalBalance';
import { UserProduct } from '../../../../../entities/products/userProduct';
import { getOtherProducts } from '../../../../../utils/apiclient';
import { removeCurrentUserProduct } from '../../../../../utils/storage';
import { formatAnualProfit, formatBalance, formatDate, formatProduct, groupProducts, trackEvent } from '../../../../../utils/utils';

const OtherProductsCustomerPage: React.FC = () => {
  const intl = useIntl()
  const router = useIonRouter();
  const [loading, setLoading] = useState(true);
  const screenSize = useScreenSize();
  const [products, setProducts] = useState([] as UserProduct[]);
  const [balance, setBalance] = useState(null as number|null);
  const [containerProducts, setContainerProducts] = useState([] as UserProduct[][]);
  const { operate } = useProductActions()

  useEffect(()=>{
    removeCurrentUserProduct();
    getOtherProducts().then((result)=>{
      if(result){

        const balances = result.map((products) => parseFloat(products.saldo ? (products.saldo.toString()) : ('0')  || '0'));
        const balanceTotal = balances.reduce((total, bal) => total + bal, 0);
        setBalance(balanceTotal);

        setProducts(result);
        setContainerProducts(groupProducts(result));

      }
    }).finally(()=>{
      setLoading(false);
    })
  }, []);


  const handleOperate = (product:UserProduct): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('perfil-'+formatProduct(product?.product)+'-operar');
      operate(product).finally(()=>{
        resolve();
      });
  });

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_other_products_title'})}}
      titleResponsive={intl.formatMessage({id:'page_other_products_title'})}
      pageInfo={{option: process.env.REACT_APP_MENU_OTROS_PRODUCTOS, role:'client'}}
    >
      <LoadingBar visible={loading}/>
      <ResponsiveLayout className='inner first'>
        <Box paddingBottom={32}>
          <Inline space={'between'} alignItems='center'>
            {screenSize.isDesktopOrBigger &&
              <Text5>{intl.formatMessage({id:'page_other_products_title'})}</Text5>
            }

            {loading ?
              <SkeletonRectangle height={53} width={254} />
              :
              <TotalBalance
                icon = {<IconFlowerLight size={27}></IconFlowerLight>}
                balance = {balance ? balance : 0}
              />
            }
          </Inline>
        </Box>

        {loading ?
          <Box paddingBottom={40}>
            <GridLayout
              template="6+6"
              verticalSpace={24}
              left={<SkeletonRectangle height={150} />}
              right={<SkeletonRectangle height={150} />}
            />
          </Box>
        :
          products.length===0 ?
            <Box paddingY={40}>
              <EmptyCard
                icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                title={intl.formatMessage({id:'page_other_products_without_other_products_title'})}
              />
            </Box>
          :
          <Box paddingBottom={40}>
            <Stack space={24}>

              {containerProducts.map((group, index) => (
                <GridLayout
                  key={`group-${index}`}
                  template="6+6"
                  verticalSpace={24}
                  left={
                    group[0] ?
                    <ProductCard
                      title={group[0].product?.descripcion??''}
                      category={group[0].tipoFondo??''}
                      ownership={group[0].nbTitular??''}
                      balance={formatBalance(group[0].saldo??0)}
                      annual_profitability={group[0].rentabilidadAnual ? formatAnualProfit(group[0].rentabilidadAnual) : '0'}
                      date_balance={group[0].fechaSaldo ? formatDate(group[0].fechaSaldo) : '0'}
                      button={
                        <ButtonSecondary small onPress={() => {handleOperate(group[0])}}>
                          {intl.formatMessage({
                            id: 'page_product_customer_more_information_button',
                          })}
                        </ButtonSecondary>
                      }
                    /> : <></>
                  }
                  right={
                    group[1] ?
                      <ProductCard
                        title={group[1].product?.descripcion??''}
                        category={group[1].tipoFondo??''}
                        ownership={group[1].nbTitular??''}
                        balance={formatBalance(group[1].saldo??0)}
                        annual_profitability={group[1].rentabilidadAnual ? formatAnualProfit(group[1].rentabilidadAnual) : '0'}
                        date_balance={group[1].fechaSaldo ? formatDate(group[1].fechaSaldo) : '0'}
                        button={
                          <ButtonSecondary small onPress={() => {handleOperate(group[1])}}>
                            {intl.formatMessage({
                              id: 'page_customer_global_position_operate_button',
                            })}
                          </ButtonSecondary>
                        }
                      />
                    : <></>
                  }/>
              ))}
            </Stack>
          </Box>
        }
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default OtherProductsCustomerPage;

