import React, { useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useIonRouter } from '@ionic/react';
import { Box, ButtonLink, Grid, IconArrowLineRightRegular, Image, MediaCard, SkeletonRectangle, skinVars, Stack, Text, Text5, TextLink, useScreenSize } from '@telefonica/mistica';
import { NewsParameters } from '../../../entities/news/news-parameters.model';
import { News } from '../../../entities/news/news.model';
import NewsPage from '../../../pages/public/news/NewsPage';
import { getNews } from '../../../utils/apiclient';
import { getUrlNavigation } from '../../../utils/navigation';
import { formatDate, isBot, trackEvent } from '../../../utils/utils';
import TagNewContainer from '../TagNew/TagNew';
import './LastNews.scss';

interface LastNews {

}

const LastNewsContainer: React.FC<LastNews> = (prop) => {

const intl = useIntl();
const screenSize = useScreenSize();
const [news, setNews] = useState([] as News[]);
const router = useIonRouter();
const [loading, setLoading] = useState(true);
const [notFound, setNotFound] = useState(false);

useLayoutEffect(()=>{
    const params: NewsParameters = new NewsParameters();

    params.numberNews = 3;
    params.starting = 0;

    getNews(params).then((result: News[])=>{

        if(!result || result.length==0){
            setNotFound(true);
        }else{
            setNews(result);
        }
    }).finally(()=>{
        setLoading(false);
    });
}, []);

  return (notFound
            ?
                <></>
            :
                <Box paddingTop={screenSize.isDesktopOrBigger ? 80 : 40} paddingBottom={screenSize.isDesktopOrBigger ? 80 : 24} className='lastNews--content'>
                    <Stack space={screenSize.isTabletOrBigger ? 40 : 24}>
                        <Grid columns={screenSize.isTabletOrBigger ? 2 : 1} gap={16} alignItems='center'>
                            <Text5>{intl.formatMessage({id:'page_home_last_news_title'})}</Text5>
                            <Box className='align-right'>
                                {
                                    isBot ?
                                        <TextLink href={getUrlNavigation(NewsPage)}><Text weight='medium' size={16} color={skinVars.colors.brand}>{intl.formatMessage({id:'page_home_last_news_small'})}</Text></TextLink>
                                    :
                                        <TextLink onPress={() => {router.push(getUrlNavigation(NewsPage)); trackEvent('home-noticias-click'); }}><Text weight='medium' size={16} color={skinVars.colors.brand}>{intl.formatMessage({id:'page_home_last_news_small'})}</Text></TextLink>
                                }
                            </Box>
                        </Grid>

                        <Grid columns={screenSize.isTabletOrBigger ? 3 : 1} gap={24}>
                            {loading ?
                                <><SkeletonRectangle height={390} />
                                <SkeletonRectangle height={390} />
                                <SkeletonRectangle height={390} />
                                </>
                            : news.map((item, index) => (
                                <MediaCard
                                    key={'last-news-home'+index}
                                    headline={item.tags && item.tags.length > 0 ? <TagNewContainer tag={item.tags[0]}/> : ''}
                                    pretitle={formatDate(item.publication)}
                                    title={item.title}
                                    media={item.image && item.image.length ? <Image aspectRatio="16:9" src={item.image} /> : <Image aspectRatio="16:9" src='/assets/img/background-news.jpg'></Image>}
                                    button={isBot ?
                                                <ButtonLink href={getUrlNavigation(NewsPage)+'/'+item.slug} withChevron={false}>{intl.formatMessage({id:'page_news_card_button'})}<IconArrowLineRightRegular color='currentColor'/></ButtonLink>
                                            :
                                                <ButtonLink onPress={() => {router.push(getUrlNavigation(NewsPage)+'/'+item.slug)}}>{intl.formatMessage({id:'page_news_card_button'})}<IconArrowLineRightRegular color='currentColor'/></ButtonLink>
                                            }
                                />
                            ))}
                        </Grid>
                    </Stack>
                </Box>
  );
};

export default LastNewsContainer;