import { useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';



import ContentNews from '../../../components/news/contentNews/ContentNews';
import ContentMostRead from '../../../components/news/mostRead/MostRead';
import ContentNewsletter from '../../../components/news/newsletter/Newsletter';
import ContentReleatedPosts from '../../../components/news/releatedPosts/ReleatedPosts';
import TagNewContainer from '../../../components/news/TagNew/TagNew';

import { NewsParameters } from '../../../entities/news/news-parameters.model';
import { News } from '../../../entities/news/news.model';
import { TagNews } from '../../../entities/news/tag.model';
import { getNewBySlug } from '../../../utils/apiclient';

import { Box, ButtonLayout, Divider, GridLayout, IconButton, Image, Inline, LoadingBar, ResponsiveLayout, SkeletonLine, SkeletonRectangle, skinVars, Stack, Text2, Text3, Text7, useScreenSize } from '@telefonica/mistica';

import LayoutPublic from '../../../components/layout/LayoutPublic';
import { formatDate, scrollToTop } from '../../../utils/utils';
import './NewsDetailPage.scss';
import NewsPage from './NewsPage';
import { getUrlNavigation } from '../../../utils/navigation';
import Error404Page from '../error-404/Error404Page';
import { useIonRouter } from '@ionic/react';

const NewsDetailPage: React.FC = () => {

const [title, setTitle] = useState('');
const [seoTitle, setSeoTitle] = useState('');
const [titleNewsTag, setTitleNewsTag] = useState('');
const [description, setDescription] = useState('');
const [seoDescription, setSeoDescription] = useState('');
const [seoImage, setSeoImage] = useState('');
const [contentNew, setContentNew] = useState({} as News);
const [relatedContent, setRelatedContent] = useState([] as News[]);
const [tagNew, setTagNew] = useState({} as TagNews);
const [newsTag, setNewsTag] = useState([] as News[]);
const [isTag, setIsTag] = useState(true);
const initNewParameters: NewsParameters = new NewsParameters();
const [loading, setLoading] = useState(true);

const [urlLinkedin, setUrlLinkedin] = useState('');
const [urlTwitter, setUrlTwitter] = useState('');
const [urlWhatsApp, setUrlWhatsApp] = useState('');
const [urlFaceBook, setUrlFaceBook] = useState('');

const size = useScreenSize();
const intl = useIntl();
const params = useParams() as any;
const router = useIonRouter();

useLayoutEffect(()=>{
  scrollToTop();
  setContentNew({} as News);
  setRelatedContent([] as News[]);
  setTagNew({} as TagNews);
  setNewsTag([] as News[]);
  setIsTag(false);
  setTitle('');
  setSeoDescription('');
  setSeoImage('');

  if(params.id)
    initNewParameters.slug = params.id;

  if(params.preview){
    if(params.preview === 'preview'){
      initNewParameters.preview = true;
    }else{
      initNewParameters.preview = false;
    }
  }else{
    initNewParameters.preview = false;
  }

  initNewParameters.numberNews = 9;
  setLoading(true);
  getNewBySlug(initNewParameters).then(newDetail=>{
    if(newDetail.content.cdNews){
      setIsTag(false);
      setContentNew(newDetail.content);
      setTitle(newDetail.content.title);
      setSeoTitle(newDetail.content.seoTitle && newDetail.content.seoTitle.length>0 ? newDetail.content.seoTitle : newDetail.content.title);
      setTitleNewsTag(newDetail.content.title);
      setSeoDescription(newDetail.content.seoDescription);
      if(newDetail.content.image){
        setSeoImage(newDetail.content.image);
      }

      if(newDetail.relatedContent){
        setRelatedContent(newDetail.relatedContent);
      }


      setUrlLinkedin('https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(window.location.href));
      setUrlTwitter('https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href) + '&text=' + encodeURIComponent(newDetail.content.title));
      setUrlWhatsApp('https://api.whatsapp.com/send/?text=' + encodeURIComponent(newDetail.content.title+' ' + window.location.href));
      setUrlFaceBook('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href)+'&t='+encodeURIComponent(newDetail.content.title)+'&p[images][0]='+encodeURIComponent(newDetail.content.image ?? ''));

    }
    else if(newDetail.tag.cdTerm){
      setIsTag(true);
      setTagNew(newDetail.tag);

      setTitle(newDetail.tag.name);
      setSeoTitle(newDetail.tag.name);
      setTitleNewsTag(newDetail.tag.name);
      setDescription(newDetail.tag.description);
      setSeoDescription(newDetail.tag.description);
    }else{
      router.push(getUrlNavigation(Error404Page));
    }

    if(newDetail.newsTag){
      setNewsTag(newDetail.newsTag);
    }

  }).finally(()=>{
    setLoading(false);
  });
}, [params.id]);

  return (
      <LayoutPublic
        seo={{metaTitle: seoTitle, metaDescription:seoDescription, metaImage: seoImage}}
        header={{title:title, inverse: false, parents:[{title:intl.formatMessage({id:'page_news_title'}), item:NewsPage}]}}
      >

        <ResponsiveLayout fullWidth={true} backgroundColor={"transparent"}>
        <LoadingBar visible={loading}/>
        <div className={'containerNewDetail '+(!isTag && Object.keys(contentNew).length !== 0 && !contentNew.image && contentNew.image.length==0 ? 'withoutImage' : '')} style={{ backgroundColor: skinVars.colors.backgroundAlternative }}></div>
          { isTag && tagNew ? (
              <>
                <ResponsiveLayout className='content contentNewDetail' backgroundColor={"transparent"}>
                  <Box paddingY={40}>
                    <div className='newsTag'>
                      <Stack space={64}>
                        <Box paddingRight={80} paddingTop={32}>
                          <Text7 as={'h1'}>{tagNew.name}</Text7>
                        </Box>
                        { newsTag && newsTag.length > 0 ? (
                            <ContentNews slugTag = {tagNew.slug} hastagNews={newsTag}/>
                          ) : (
                            <></>
                          )
                        }
                        <ContentNewsletter/>
                      </Stack>
                    </div>
                  </Box>
                </ResponsiveLayout>
              </>
            ) : !isTag && Object.keys(contentNew).length !== 0 ? (
              <>
                <ResponsiveLayout className={'content contentNewDetail '+(!contentNew.image && contentNew.image.length==0 ? 'withoutImage' : '')}  backgroundColor={"transparent"}>
                  <Box paddingY={24}>
                    <Stack space={32}>
                      <Box paddingRight={size.isDesktopOrBigger ? 80 : 0} paddingY={32}>
                        <Text7 as={'h1'}>{contentNew.title}</Text7>
                      </Box>
                      {contentNew.image && contentNew.image.length>0  &&
                        <Image
                            src={contentNew.image}
                            aspectRatio={0}
                        />
                      }
                      <GridLayout template='6+6' verticalSpace={24}
                        left={
                            <Inline space={8} wrap>
                              {contentNew.tags ? (
                                contentNew.tags.map(tag => (
                                  <TagNewContainer key={tag.name} tag={tag}/>
                                ))
                              ) : (
                                <></>
                              )}
                            </Inline>
                        }
                        right={
                          <ButtonLayout align={size.isMobile || size.isTabletOrSmaller ? ('left') : ('right')}>
                            <Box paddingBottom={24}>
                              <Inline space={12}>
                                  <Text3 medium>{intl.formatMessage({id:'page_news_related_share'})}</Text3>
                                  <IconButton newTab={true} href={urlFaceBook} icon="/assets/img/facebook.svg" aria-label="FaceBook" />
                                  <IconButton newTab={true} href={urlTwitter}  icon="/assets/img/twitter.svg" aria-label="Twitter" />
                                  <IconButton newTab={true} href={urlLinkedin}  icon="/assets/img/linkedin.svg" aria-label="LinkedIn" />
                                  <IconButton newTab={true} href={urlWhatsApp} icon="/assets/img/ws.svg" aria-label="WhatsApp" />
                              </Inline>
                            </Box>
                          </ButtonLayout>
                        }
                      />
                      <GridLayout template='8+4'
                        left={
                          <Stack space={size.isDesktopOrBigger ? 24 : 0}>
                          <Text2 regular>{formatDate(contentNew.publication)}</Text2>
                          <Box paddingRight={size.isDesktopOrBigger ? 80 : 0}>
                            <div className='html-content' dangerouslySetInnerHTML={{__html: contentNew.content}}></div>
                          </Box>
                          </Stack>
                        }
                        right={
                          <>
                            <ContentMostRead side={true}/>
                            <ContentNewsletter side={true}/>
                          </>
                        }
                      />
                      {relatedContent && relatedContent.length>0 &&
                        <Stack space={32}>
                          <Divider />
                          <ContentReleatedPosts news={relatedContent}/>
                        </Stack>
                      }
                    </Stack>
                  </Box>
                </ResponsiveLayout>
              </>
            ) : (
              <>
                <ResponsiveLayout className='content contentNewDetail' backgroundColor={"transparent"}>
                  <Box paddingY={24}>
                    <Stack space={80}>
                    <SkeletonLine width={40}/>
                      <SkeletonLine width="100%"/>
                      <SkeletonRectangle width="100%" height={size.isMobile || size.isTabletOrSmaller ? (194) : (394)}/>
                      <SkeletonLine width="100%"/>
                      <GridLayout template='8+4' verticalSpace={24}
                        left={
                          <SkeletonRectangle width="100%" height={size.isMobile || size.isTabletOrSmaller ? (194) : (394)}/>
                        }
                        right={
                          <SkeletonRectangle width="100%" height={size.isMobile || size.isTabletOrSmaller ? (194) : (394)}/>
                        }
                      />
                    </Stack>
                  </Box>
                </ResponsiveLayout>
              </>
            )
          }
        </ResponsiveLayout>
      </LayoutPublic>
  );
};

export default NewsDetailPage;
