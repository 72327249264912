import { Grid, skinVars, Stack, Text2, Text3, Text4, useScreenSize } from '@telefonica/mistica';
import React from 'react';


interface OperationInProgressDetailProps {
    title: string,
    items: Array<{title:string, value:string}>,
    numColumns: number
}

const OperationInProgressDetail: React.FC<OperationInProgressDetailProps> = (prop) => {
    const screenSize = useScreenSize();



return (screenSize.isDesktopOrBigger ?
            <Stack space={16}>
                <Text3 regular>{prop.title}</Text3>

                <Grid columns={2} gap={12}>
                    {prop.items.map((item, index) => (
                        <Stack key={'oipd-'+index} space={4}>
                            <Text2 regular color={skinVars.colors.textSecondary}>{item.title}</Text2>
                            <Text2 medium>{item.value}</Text2>
                        </Stack>
                    ))}
                </Grid>
            </Stack>
        :
            <Stack space={16}>
                <Grid columns={screenSize.isMobile ? 1 : 2} gap={12}>
                    {prop.items.map((item, index) => (
                        <Stack key={'oipdm-'+index} space={4}>
                            <Text3 regular color={skinVars.colors.textSecondary}>{item.title}</Text3>
                            <Text4 medium>{item.value}</Text4>
                        </Stack>
                    ))}
                </Grid>
            </Stack>
        );
};

export default OperationInProgressDetail;
