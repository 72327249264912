import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { isNativeApp, trackError } from "./utils";
import { setDeviceId } from "./storage";


export const initPush = async() => {
    if (isNativeApp) {
        try{
            let permStatus = await FirebaseMessaging.checkPermissions();

            if (permStatus.receive === 'prompt') {
                permStatus = await FirebaseMessaging.requestPermissions();
            }

            if (permStatus.receive !== 'granted') {
                throw new Error('User denied permissions!');
            }
            FirebaseMessaging.getToken().then(result=>{
                setDeviceId(result.token);
            })
        }catch(err){
            if(err){
              trackError(JSON.stringify(err));
            }
        }

        try{
            FirebaseMessaging.removeAllDeliveredNotifications();
        }catch(err){
            if(err){
                trackError(JSON.stringify(err));
            }
        }
    }
}

export const registerPush = async () => {
    if (isNativeApp) {
        await FirebaseMessaging.addListener('tokenReceived', result => {
            console.info('Registration token: ', result);
            setDeviceId(result.token);
        });


        try{
            FirebaseMessaging.removeAllDeliveredNotifications().then(()=>{});
        }catch(err){
            if(err){
                trackError(JSON.stringify(err));
            }
        }
        await initPush();
    }
}
