import { Box, IconInformationUserRegular, LoadingBar, ResponsiveLayout, skinVars, Tabs, Text5, Touchable, useScreenSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import ContractInfo from '../../../../../components/contract-data/ContractInfo';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';

import { useEffect, useState } from 'react';
import BalanceRights from '../../../../../components/contract-data/BalanceRights';
import Movements from '../../../../../components/contract-data/Movements';
import ProductInformation from '../../../../../components/product-data/ProductInformation';
import { Contract } from '../../../../../entities/contract/contract';
import { Product } from '../../../../../entities/products/product';
import { getInfoContract, trackStadistics } from '../../../../../utils/apiclient';
import { getCurrentUserProduct } from '../../../../../utils/storage';
import { isIOS, isNativeApp } from '../../../../../utils/utils';
import OtherProductsCustomerPage from './OtherProductsCustomerPage';
import { useIonRouter } from '@ionic/react';
import { getUrlNavigation } from '../../../../../utils/navigation';

const OperateOtherProductsCustomerPage: React.FC = () => {
  const intl = useIntl();
  const screenSize = useScreenSize();
  const router = useIonRouter();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [product, setProduct] = useState(null as null|Product);
  const [contract, setContract] = useState(null as null|Contract);
  const currentUserProduct = getCurrentUserProduct();
  const currentProduct = currentUserProduct?.product;
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    if(!router.canGoBack() || !currentProduct){
        router.push(getUrlNavigation(OtherProductsCustomerPage));
        return;
    }
    getInfoContract({numContract: currentUserProduct?.numContrato ?? '', cdCompany:currentProduct?.cdCompania ?? -1, cdPlan: currentProduct?.cdPlan ?? '', cdFund: currentProduct?.cdFondo?? '', cdSubplan: currentProduct?.cdSubplan?? ''}).then((result)=>{
        if(result){
            setContract(result);
            if(result.plan){
                setProduct(result.plan)
            }
        }
      /*if(result){
        setProduct(result);
      }*/
    }).finally(()=>{
        setLoading(false);
    });
  }, []);

  useEffect(()=>{
    switch (selectedIndex){
      case 1:
        trackStadistics('PAGE', '_MOVIMIENTOS' );
        break;
      case 2:
        trackStadistics('PAGE', '_INFORMACION_PRODUCTO' );
        break;
      default:
        trackStadistics('PAGE', '_SALDO' );
        break;
    }
  }, [selectedIndex]);

  return (
        <><LoadingBar visible={loading} />
            {!screenSize.isDesktopOrBigger &&
                <Touchable className={'info-button--operatePlan '+(isNativeApp ? ' is-native-app ' : '')+(isIOS ? ' is-ios ' : '')} onPress={()=>{setShowFilter(!showFilter)}}>
                    <IconInformationUserRegular color={skinVars.colors.background}></IconInformationUserRegular>
                </Touchable>
            }

            <LayoutPrivateArea
                seo={{metaTitle: currentProduct?.descripcion}}
                title={currentProduct?.descripcion}
                parents={[{item:OtherProductsCustomerPage, title: intl.formatMessage({id:'page_other_products_title'})}]}
                pageInfo={{option: process.env.REACT_APP_MENU_OTROS_PRODUCTOS, role:'client'}}
                showBack>

                <ResponsiveLayout className={'info--operatePlan'+(screenSize.isDesktopOrBigger ? ' inner first' : ' fullscreen fixed padding--none ')+(showFilter ? ' visible' : ' hide')}>
                    <Box paddingBottom={screenSize.isDesktopOrBigger ? 32 : 0}>
                        {screenSize.isDesktopOrBigger &&
                            <Text5>{currentProduct?.descripcion}</Text5>
                        }
                    </Box>

                    <ContractInfo loading={loading} contract={contract} />
                </ResponsiveLayout>

                <ResponsiveLayout className={!screenSize.isDesktopOrBigger ? 'fullScreen tabs--fixed' : 'inner'} backgroundColor={!screenSize.isDesktopOrBigger ? skinVars.colors.backgroundAlternative : 'transparent'}>
                    <Box paddingTop={screenSize.isDesktopOrBigger ? 16 : 0}>
                        <Tabs
                            selectedIndex={selectedIndex}
                            onChange={setSelectedIndex}
                            tabs={[intl.formatMessage({id:'page_other_products_tab1'}), intl.formatMessage({id:'page_other_products_tab2'}), intl.formatMessage({id:'page_other_products_tab3'})].map((text) => ({
                                text,
                            }))}
                        />
                    </Box>
                </ResponsiveLayout>

                <ResponsiveLayout className={'inner '+(selectedIndex===0 ? 'd-block' : 'd-none')+(!screenSize.isDesktopOrBigger ? ' with-tabs--fixed' : '')}>

                    <BalanceRights loading={loading} contract={contract} />

                </ResponsiveLayout>

                <ResponsiveLayout className={'inner '+(selectedIndex===1 ? 'd-block' : 'd-none')+(!screenSize.isDesktopOrBigger ? ' with-tabs--fixed' : '')}>

                    <Movements loading={loading} contract={contract} />

                </ResponsiveLayout>

                <ResponsiveLayout className={'inner '+(selectedIndex===2 ? 'd-block' : 'd-none')+(!screenSize.isDesktopOrBigger ? ' with-tabs--fixed' : '')}>
                    <ProductInformation loading={loading} product={product} />

                </ResponsiveLayout>



            </LayoutPrivateArea>
        </>
    );
};

export default OperateOtherProductsCustomerPage;
