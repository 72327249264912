import { Box, Boxed, Grid, IconInformationUserRegular, Inline, Placeholder, Stack, Text2, Text3, Text5, Tooltip, skinVars, useScreenSize, useWindowSize, SkeletonRectangle} from '@telefonica/mistica';
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS, Filler, Legend, LinearScale, LineController,
    LineElement, PointElement,
    Tooltip as TooltipChart
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';

import './AnnualEvolutionProfitability.scss';
import { Product } from '../../entities/products/product';
import { formatAnualProfit } from '../../utils/utils';
import moment from 'moment';
import { ComparativeIndex } from '../../entities/products/comparative-index';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    LineController,
    BarController,
    TooltipChart,
    Filler
  );

interface AnnualEvolutionProfitabilityProps {
    full?:boolean,
    product: Product | null,
    loading?: boolean
}

const AnnualEvolutionProfitability: React.FC<AnnualEvolutionProfitabilityProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const size = useWindowSize();
    const [columnGrid, setColumnGrid] = useState(3 as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12);
    const [labelsGraphic, setLabelsGraphic]= useState([] as string[]);
    const [datasGraphic, setDatasGraphic]= useState([] as number[]);
    const [datasGraphicRef, setDatasGraphicRef]= useState([] as number[]);

    useEffect(()=>{
        if(prop.full){
            if(size.width > 1024){
                setColumnGrid(1);
            }else if(size.width > 768){
                setColumnGrid(2);
            }else{
                setColumnGrid(1);
            }
        }else{
            if(size.width > 768){
                setColumnGrid(2);
            }else{
                setColumnGrid(1);
            }
        }
    });

    const labelNames = [intl.formatMessage({id:'page_investment_funds_customer_general_annual_evolution_profitability_this_year'}), intl.formatMessage({id:'page_investment_funds_customer_general_annual_evolution_profitability_1_year'}), intl.formatMessage({id:'page_investment_funds_customer_general_annual_evolution_profitability_3_year'})]
    useEffect(()=>{
        if(prop.product){
          if(prop.product.comparativaEvolucionAnual && !prop.product.comparativaReferenciaAnual){
            prop.product.comparativaReferenciaAnual = prop.product.comparativaEvolucionAnual
          }

          if(prop.product.comparativaReferenciaAnual?.comparativaIndice){
              let comparativeIndex = Object.values(prop.product.comparativaReferenciaAnual?.comparativaIndice);
              updateAnualEvolutionProfitability(Object.values(comparativeIndex[0]));
          }
        }
      },[prop.product]);

    const updateAnualEvolutionProfitability = (comparativeIndex : Array<ComparativeIndex> | null) =>{
      let labels:string[] = [];
      let datas:number[] = [];
      let datasRef:number[] = [];
      if(comparativeIndex){
        let count=0;

        comparativeIndex.forEach(item=>{
            labels.push(labelNames[count]);
            datas.push(parseFloat(item.indiceProducto ?? '0'));
            datasRef.push(parseFloat(item.indiceReferencia ?? '0'));
          count++;

        });
      }
      setLabelsGraphic(labels);
      setDatasGraphic(datas);
      setDatasGraphicRef(datasRef);
    }

    const data = {
        labels:labelsGraphic,
        datasets: [
          {
            label: '',
            backgroundColor:'#0066FF',
            data: datasGraphic,
          },
          {
            label: '',
            backgroundColor:'#f2f4ff',
            data: datasGraphicRef,
          }
        ],
      };

    const options = {

        responsive: true,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          x: {
            border: {
              display: true
            },
            grid: {
              display: true
            },
          },
          y: {
            border: {
              display: true
            },
            grid: {
              display: true
            },
          }
        }
      };

return (<>{prop.loading ?
            <Box paddingY={12}>
              <SkeletonRectangle height={169} />
            </Box>
            :
            prop.product ?
            <>
              {/* OCULTADO TEMPORALMENTE GRAFICA DE RENTABILIDAD */}
              {/*
              <style>{
                  '.annualEvolutionGraph--content .boxWidth--color{background-color:'+skinVars.colors.backgroundAlternative+';}'
              }</style>
              <Box className={'annualEvolutionGraph--content'+(prop.full ? ' fullBlock' : '')} paddingY={16}>
                  <Boxed>
                      <Box paddingX={screenSize.isDesktopOrBigger ? 64 : 12} paddingTop={screenSize.isDesktopOrBigger ? 48 : 16}>
                          <Box paddingBottom={48}>
                              <Inline space={8} alignItems='center'>
                                  <Text5>{intl.formatMessage({id:'page_investment_funds_customer_general_annual_evolution_profitability_comparative'})}</Text5>
                                  <Tooltip
                                  targetLabel={intl.formatMessage({id:'page_investment_funds_customer_general_annual_evolution_profitability_information'})}
                                  target={<IconInformationUserRegular size={22} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                  description=""
                                  position="top"
                                  extra={
                                      <Inline space={'evenly'}>
                                          <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_investment_funds_customer_general_annual_evolution_profitability_information_regarding_fund'})} {moment(prop.product?.comparativaReferenciaAnual?.fechaCarga??'').format('DD/MM/YYYY')}</Text2>
                                      </Inline>
                                  }
                                  />
                              </Inline>
                          </Box>
                      </Box>

                      <Box paddingX={screenSize.isDesktopOrBigger ? 40 : 12} paddingBottom={screenSize.isDesktopOrBigger ? 32 : 16}>
                          <Grid columns={screenSize.isDesktopOrBigger ? (prop.full ? 2 : 1) : 1} gap={24} alignContent='space-between'>

                              <Bar options={options} data={data} />

                              <Box className='chartLegend'>
                                  <Grid columns={columnGrid}>
                                      <Inline space={'between'} alignItems='center' className='chartLegend__item'>
                                          <Text3 light>{intl.formatMessage({id:'page_investment_funds_customer_general_annual_evolution_profitability_max_revenue'})}</Text3>

                                          <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                              <Text3 regular>{prop.product?.comparativaReferenciaAnual?.indiceReferenciaAnual?.maxGananciaMensual ? formatAnualProfit(prop.product?.comparativaReferenciaAnual?.indiceReferenciaAnual?.maxGananciaMensual)+'%' : ' - '}</Text3>
                                          </Box>
                                      </Inline>

                                      <Inline space={'between'} alignItems='center' className='chartLegend__item'>
                                          <Text3 light>{intl.formatMessage({id:'page_investment_funds_customer_general_annual_evolution_profitability_max_loss'})}</Text3>

                                          <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                              <Text3 regular>{prop.product?.comparativaReferenciaAnual?.indiceReferenciaAnual?.maxPerdidaMensual ? formatAnualProfit(prop.product?.comparativaReferenciaAnual?.indiceReferenciaAnual?.maxPerdidaMensual)+'%' : ' - '}</Text3>
                                          </Box>
                                      </Inline>

                                      <Inline space={'between'} alignItems='center' className='chartLegend__item'>
                                          <Text3 light>{intl.formatMessage({id:'page_investment_funds_customer_general_annual_evolution_profitability_average_monthly_earning'})}</Text3>

                                          <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                              <Text3 regular>{prop.product?.comparativaReferenciaAnual?.indiceReferenciaAnual?.gananciaMediaMensual ? formatAnualProfit(prop.product?.comparativaReferenciaAnual?.indiceReferenciaAnual?.gananciaMediaMensual)+'%' : ' - '}</Text3>
                                          </Box>
                                      </Inline>

                                      <Inline space={'between'} alignItems='center' className='chartLegend__item'>
                                          <Text3 light>{intl.formatMessage({id:'page_investment_funds_customer_general_annual_evolution_profitability_average_monthly_loss'})}</Text3>

                                          <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                              <Text3 regular>{prop.product?.comparativaReferenciaAnual?.indiceReferenciaAnual?.perdidaMediaMensual ? formatAnualProfit(prop.product?.comparativaReferenciaAnual?.indiceReferenciaAnual?.perdidaMediaMensual)+'%' : ' - '}</Text3>
                                          </Box>
                                      </Inline>

                                      <Inline space={'between'} alignItems='center' className='chartLegend__item'>
                                          <Text3 light>{intl.formatMessage({id:'page_investment_funds_customer_general_annual_evolution_profitability_volatility'})}</Text3>

                                          <Box className='boxWidth boxWidth--color' paddingX={screenSize.isDesktopOrBigger ? 16 : 4} paddingY={screenSize.isDesktopOrBigger ? 12 : 8}>
                                              <Text3 regular>{prop.product?.comparativaReferenciaAnual?.indiceReferenciaAnual?.volatilidad12Meses ? formatAnualProfit(prop.product?.comparativaReferenciaAnual?.indiceReferenciaAnual?.volatilidad12Meses)+'%' : ' - '}</Text3>
                                          </Box>
                                      </Inline>
                                  </Grid>
                              </Box>
                          </Grid>
                      </Box>
                  </Boxed>
              </Box>
              */}
            </>
            : <></>
    }</>);
};

export default AnnualEvolutionProfitability;
