import { IonContent, useIonRouter } from '@ionic/react';
import { Box, ResponsiveLayout, skinVars, useIsInViewport, useScreenSize, useWindowSize } from '@telefonica/mistica';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import IncompleteRegistrationPage from '../../pages/private/incomplete-registration/IncompleteRegistrationPage';
import LoginPage from '../../pages/private/login/LoginPage';
import GlobalPositionCustomerPage from '../../pages/private/users/customer/global-position/GlobalPositionCustomerPage';
import SelectProfile from '../../pages/private/users/select-profile/SelectProfile';
import { isValidToken } from '../../utils/apiclient';
import { getOptionNavigationInverse, getUrlNavigation } from '../../utils/navigation';
import { getAlertExpiredIdCard, getAlertStatusSign, getPendingAction, getUser, removeAlertExpiredIdCard, removeToken, removeUser, setAlertStatusSign } from '../../utils/storage';
import { havePermission, isNativeApp, trackPageView } from '../../utils/utils';
import { useAlert } from '../alerts/Alert';
import Breadcrumb from '../breacrumb/Breadcrumb';
import FooterPrivateArea from '../footer/FooterPrivateArea';
import HeaderPrivateArea from '../header/HeaderPrivateArea';
import OnBoardingFirstLogin from '../onboarding-first-login/OnBoardingFirstLogin';
import ScrollToTop from '../scroll-to-top/ScrollToTop';
import './LayoutPrivateArea.scss';
import { SeoProps } from './LayoutPublic';

interface LayoutPrivateAreaProps {
    seo?: SeoProps,
    //header?: HeaderPrivateAreaProps,
    title?: string,
    titleResponsive?:string,
    parents?: Array<{title:string, item:React.FC}>,
    description?: string,
    welcome?: boolean,
    pageInfo?: {option?:string, role: string},
    children?: any,
    showBack?:boolean,
    showBigAvatar?:boolean,
    showMenu?:boolean,
    onChangeUser?: () => void,
 }

const LayoutPrivateArea: React.FC<LayoutPrivateAreaProps> = (prop) => {
    const router = useIonRouter();
    const intl = useIntl();
    const screenSize = useScreenSize();

    let retrieveTokenRef = useRef<number>();
    const ref = React.useRef<HTMLDivElement>(null);
    const inView = useIsInViewport(ref, false);
    const [loaded, setLoaded] = useState(false);

    const showFooterMenu = isNativeApp && !screenSize.isDesktopOrBigger && prop.showMenu;

    let user = getUser();
    const { showAlert } = useAlert()
    const [currentPathname, setCurrentPathname] = useState(null as null|string);


    const location = useLocation();

    useLayoutEffect(() => {
        if(!currentPathname || currentPathname!=location.pathname){
            setCurrentPathname(location.pathname);
            trackPageView(location.pathname);
        }
    }, [location]);

    useLayoutEffect(() => {
        if (inView) {
            setLoaded(true);
        }
    }, [inView, loaded, screenSize])

    useLayoutEffect(()=>{

        retrieveIsValidToken();
        user = getUser();
        if(user){
            if(getPendingAction()!==null){
                router.push(getUrlNavigation(IncompleteRegistrationPage));
            }


            const page = getOptionNavigationInverse(router.routeInfo.pathname);
            let showingAlert = false;
            if(getAlertStatusSign() && page && page.props.children.type.name===GlobalPositionCustomerPage.name){
                setAlertStatusSign();
                switch(user.estadoFirmaPropio){
                    case 'INACTIVO':
                        showingAlert= true;
                        showAlert('_inactive_own_signature_');
                        break;
                    case 'RECHAZADO':
                        showingAlert= true;
                        showAlert('_rejected_own_signature_');
                        break;
                    case 'SIN_SOLICITAR':
                        showingAlert= true;
                        showAlert('_inactive_own_without_request_');
                        break;
                    case 'EN_TRAMITE':
                        switch(user.estadoSignaturit){
                            case 'DECLINADO':
                            case 'ERROR':
                            case 'FIRMADO':
                                showingAlert= true;
                                showAlert('_contract_in_proccess_');
                                break;
                            default:
                                showingAlert= true;
                                showAlert('_inactive_own_without_request_');
                                break;
                        }
                        break;
                }
            }

            if(!showingAlert && getAlertExpiredIdCard()){
                removeAlertExpiredIdCard();
                showAlert('_expired_idcard_');
            }

            if(prop.pageInfo && prop.pageInfo.role && user.selectedRole!==prop.pageInfo.role){
                if(user.roles && user.roles.length>1 && user.roles.find(role=>role===prop?.pageInfo?.role)){
                    router.push(getUrlNavigation(SelectProfile));
                    return;
                }else{
                    router.push(getUrlNavigation(LoginPage));
                    return;
                }
            }
            if(prop.pageInfo && prop.pageInfo.option){
                if(!havePermission(prop.pageInfo.option)){
                    router.push(getUrlNavigation(LoginPage));
                    return;
                }
            }
        }
      }, []);

    function retrieveIsValidToken(setValue=true){
        if(!retrieveTokenRef || !retrieveTokenRef.current){
            retrieveTokenRef.current = setInterval(retrieveIsValidToken, 15000);
        }

        const currentUser = getUser();
        const validToken = isValidToken();
        if(!currentUser || !validToken){
            removeUser();
            router.push(getUrlNavigation(LoginPage));
            showAlert('_expired_session_');
            return;
        }
    }


    React.useEffect(() => {
        return () => {
            try{
                clearInterval(retrieveTokenRef.current);
            }catch(err){}
        };
      }, []);


return (
    <>
        {user &&
            <IonContent class={"page private "+(isNativeApp ? "nativeapp" : "")}>
                <HelmetProvider>
                    <Helmet>‍
                        <title>{ (prop?.seo?.metaTitle?? (prop?.title??'')) +' - '+ intl.formatMessage({id:'site_name'})}</title>‍
                        <meta name="description" content={prop?.seo?.metaDescription} />
                    </Helmet>
                </HelmetProvider>
                <OnBoardingFirstLogin></OnBoardingFirstLogin>
                <ResponsiveLayout className='container' fullWidth={true} backgroundColor={"transparent"}>
                    {user && user.callCenter!=null && user.callCenter &&
                        <Box paddingY={2} className='mode-callcenter'>
                            MODO CALLCENTER : {user.nif}
                        </Box>
                    }
                    <HeaderPrivateArea showFooterMenu={!screenSize.isDesktopOrBigger && prop.showMenu} showBack={prop.showBack} showBigAvatar={prop.showBigAvatar} title={prop.titleResponsive ? prop.titleResponsive : prop.title} welcome={prop.welcome} onChangeUser={prop.onChangeUser}></HeaderPrivateArea>
                    {/*<style>{`.page.private .content.content-wrapper{height: calc(100vh - `+heightPrivateArea+`px)}`}</style>*/}
                    <ResponsiveLayout className='content content-wrapper' backgroundColor={skinVars.colors.background} fullWidth>
                        <Box ref={ref}></Box>
                        <ResponsiveLayout fullWidth>
                            <ResponsiveLayout className={'content-private-area '+(showFooterMenu ? 'with-footer-menu' : '')+(prop.welcome ? ' big ' : '')}>
                                {screenSize.isDesktopOrBigger && prop.title && prop.title.length>0 &&
                                    <ResponsiveLayout className='inner first'>
                                        <Breadcrumb privateArea={true} title={prop.title} parents={prop.parents}></Breadcrumb>
                                    </ResponsiveLayout>
                                }
                                {prop.children}
                            </ResponsiveLayout>
                        </ResponsiveLayout>
                    </ResponsiveLayout>

                </ResponsiveLayout>

                <ScrollToTop showFooterMenu={showFooterMenu}  visible={!inView && loaded }></ScrollToTop>
                {showFooterMenu && <FooterPrivateArea></FooterPrivateArea>}
            </IonContent>
        }
    </>);
};

export default LayoutPrivateArea;
