import { useIonRouter } from '@ionic/react';
import React from 'react';
import { useIntl } from 'react-intl';

import CopyToClipboard from '../../../components/copy-to-clipboard/CopyToClipboard';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import OurAppContainer from '../../../components/staticPages/ourApp/OurApp';


import { Box, ButtonPrimary, DisplayDataCard, GridLayout, IconCopyRegular, IconMobileDeviceRegular, Image, MediaCard, ResponsiveLayout, skinVars, Stack, Text3, Text7, useScreenSize } from '@telefonica/mistica';

import ContactForm from '../../../components/contact-form/ContactForm';
import './ContactPage.scss';
import Map from '../../../components/map/Map';
import { trackEvent } from '../../../utils/utils';

const ContactPage: React.FC = () => {
  const intl = useIntl();
  const router = useIonRouter();
  const size = useScreenSize();

  function handlePhoneClick() {
    trackEvent('contacto-llamar_ahora');
    window.location.href = `tel:${intl.formatMessage({id:'page_contact_card_phone'})}`;
    /*if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) {
      window.location.href = `tel:${intl.formatMessage({id:'page_contact_card_phone'})}`;
    } else {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => {
          window.location.href = `tel:${intl.formatMessage({id:'page_contact_card_phone'})}`;
        })
        .catch(() => {
          alert(intl.formatMessage({id:'page_contact_card_error'}));
        });
    }*/
  }

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_contact_seo_title'}), metaDescription:intl.formatMessage({id:'page_contact_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_contact_title'})}}
    >
      <ResponsiveLayout className='container' fullWidth={true} backgroundColor={"transparent"}>
        <ResponsiveLayout className='content' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
          <ResponsiveLayout className='content' backgroundColor={"transparent"}>
            <Box paddingY={40}>
              <GridLayout verticalSpace={24} template='6+6'
                left={
                  <Box paddingRight={80}>
                    <Stack space={16}>
                      <Box>
                        <Text7>{intl.formatMessage({id:'page_contact_title'})}</Text7>
                      </Box>
                      <Box>
                        <Text3 regular>{intl.formatMessage({id:'page_contact_description'})}</Text3>
                      </Box>
                    </Stack>
                  </Box>
                }
                right={
                  <GridLayout template='6+6'
                    left={
                      <></>
                    } right={
                      <DisplayDataCard
                        isInverse
                        button={<ButtonPrimary onPress={handlePhoneClick}>{intl.formatMessage({id:'page_contact_card_button'})}</ButtonPrimary>}
                        icon={<IconMobileDeviceRegular size={40} ></IconMobileDeviceRegular>}
                        title={intl.formatMessage({id:'page_contact_card_phone'})}
                        description={intl.formatMessage({id:'page_contact_card_description'})}/>
                    }
                  />
                }
              />
            </Box>
          </ResponsiveLayout>
        </ResponsiveLayout>
        <ResponsiveLayout className='content' fullWidth={true} backgroundColor={"transparent"}>
          <ResponsiveLayout className='content' backgroundColor={"transparent"}>
            <Box paddingY={40}>
              <Stack space={40}>
                <GridLayout verticalSpace={24} template='6+6'
                  left={<ContactForm></ContactForm>}
                  right={
                    <Map></Map>
                  }
                />
                <GridLayout verticalSpace={24} template='6+6'
                  left={
                    <MediaCard
                        title={intl.formatMessage({id:'page_contact_card_fonditel_pensiones_title'})}
                        description={intl.formatMessage({id:'page_contact_card_fonditel_pensiones_description'})}
                        media={<Image width="100%" height={193} src='../assets/img/fonditel-pensiones-egfp-sa.jpg' />}
                        button={<CopyToClipboard text={intl.formatMessage({id:'page_contact_card_fonditel_pensiones_description'})} button={<IconCopyRegular color={skinVars.colors.textLink} />} />
                        }
                    />
                  }
                  right={
                    <MediaCard
                        title={intl.formatMessage({id:'page_contact_card_fonditel_gestion_title'})}
                        description={intl.formatMessage({id:'page_contact_card_fonditel_gestion_description'})}
                        media={<Image width="100%" height={193} src='../assets/img/fonditel-gestion-sgiic-sa.jpg' />}
                        button={<CopyToClipboard text={intl.formatMessage({id:'page_contact_card_fonditel_gestion_description'})} button={<IconCopyRegular color={skinVars.colors.textLink} />} />
                        }
                    />
                  }
                />
              </Stack>
            </Box>
          </ResponsiveLayout>

          <ResponsiveLayout className='content' fullWidth={true}>

            <OurAppContainer/>

          </ResponsiveLayout>
        </ResponsiveLayout>
      </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default ContactPage;
