import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Grid, Image, ResponsiveLayout, Stack, Video, skinVars, useScreenSize } from '@telefonica/mistica';
import './Hero.scss';

interface HeroShadowProp {
    image?: any,
    video?: any,
    title?: any,
    button?: any,
    children?: any
}

const HeroShadow: React.FC<HeroShadowProp> = (prop) => {

    const intl = useIntl();
    const screenSize = useScreenSize();

    return (
        <>
            <style>{
                '.heroShadow .heroShadow__background{background-color:'+skinVars.colors.backgroundAlternative+';}'+
                '.heroShadow .heroShadow__color .image:before{background: linear-gradient(332deg, '+skinVars.colors.brand+' 47%, '+skinVars.colors.backgroundContainerBrandPressed+' 100%);}'
            }</style>

            <ResponsiveLayout className='content' fullWidth={true}>

                <Box className={'heroShadow'+(prop.children ? '' : ' fullHeight')} paddingBottom={screenSize.isTabletOrBigger ? 40 : 16}>
                    <Box className='heroShadow__background'>
                        <Box className='heroShadow__color'>
                            <Box className='imageBox'>
                                <Box className='imageBox__radial'>
                                    {prop.video ?
                                        (prop.video && prop.video.length>0 && !screenSize.isMobile && !screenSize.isTabletOrSmaller) ?
                                            <Video src={prop.video} />
                                        :
                                            <Image src={prop.image ?? ''} />
                                    :
                                        <Image src={prop.image} />
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <ResponsiveLayout className='heroShadow__content'>
                        <Stack space={80}>
                            <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1}>
                                <Box paddingTop={screenSize.isDesktopOrBigger ? 56 : 0} className='textBox'>
                                    <Stack space={screenSize.isDesktopOrBigger ? 24 : 40}>
                                        {prop.title}
                                        {prop.button}
                                    </Stack>
                                </Box>
                            </Grid>

                            {prop.children}

                        </Stack>
                    </ResponsiveLayout>

                </Box>

            </ResponsiveLayout>
        </>
    );
};

export default HeroShadow;