import { useIonRouter } from '@ionic/react';
import NewsPage from '../../pages/public/news/NewsPage';
import TagNewContainer from './TagNew/TagNew';

import { News } from '../../entities/news/news.model';
import { getUrlNavigation } from '../../utils/navigation';

import { ButtonLink, HighlightedCard, IconArrowLineRightRegular, Image, MediaCard } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import { formatDate, isBot, trackEvent } from '../../utils/utils';

interface CardNew {
    news:  News,
    type: string,
    prefixSlug?: string
}

const CardNewContainer: React.FC<CardNew> = (prop) => {

const router = useIonRouter();
const intl = useIntl();


  return (
     prop.news  ?
    (
        prop.type === "Media" ? (
            <>
                <MediaCard
                    headline={prop.news.tags && prop.news.tags.length > 0 ? <TagNewContainer tag={prop.news.tags[0]}/> : ''}
                    pretitle={formatDate(prop.news.publication)}
                    title={prop.news.title}
                    media={prop.news.image && prop.news.image.length ? <Image aspectRatio={0} src={prop.news.image} /> : <Image aspectRatio="16:9" src='/assets/img/background-news.jpg'></Image>}
                    button={isBot ?
                                <ButtonLink href={getUrlNavigation(NewsPage)+'/'+prop.news.slug} withChevron={false}>{intl.formatMessage({id:'page_news_card_button'})}<IconArrowLineRightRegular color='currentColor'/></ButtonLink>
                            :
                                <ButtonLink onPress={() => {router.push(getUrlNavigation(NewsPage)+'/'+prop.news.slug); trackEvent(prop.prefixSlug ? prop.prefixSlug+'-'+prop.news.title : 'noticias-'+prop.news.title+'-ver_mas'); }}>{intl.formatMessage({id:'page_news_card_button'})}<IconArrowLineRightRegular color='currentColor'/></ButtonLink>
                            }
                />
            </>
        ) : prop.type === "Highlighted" ? (
            <>
                {isBot ?
                    <HighlightedCard
                        title=''
                        description={prop.news.title}
                        imageUrl={prop.news.image && prop.news.image.length > 0 ? prop.news.image : '/assets/img/background-news.jpg'}
                        imageFit={'fill-center'}
                        href ={getUrlNavigation(NewsPage)+'/'+prop.news.slug}
                    />
                :
                    <HighlightedCard
                        title=''
                        description={prop.news.title}
                        imageFit={'fill-center'}
                        imageUrl={prop.news.image && prop.news.image.length > 0 ? prop.news.image : '/assets/img/background-news.jpg'}
                        onPress={() => {router.push(getUrlNavigation(NewsPage)+'/'+prop.news.slug); trackEvent('noticias-detalle-masLeido-'+prop.news.title); }}
                    />
                }
            </>
        ) : (
            <></>
        )

    ) : (
        <></>
    )
  );
};

export default CardNewContainer;