import React from 'react';
import { useIntl } from 'react-intl';

import './Numbers.scss';
import { Box, Carousel, Divider, ResponsiveLayout, Stack, Text10, Text3, Text4, Text5, Text9, useScreenSize } from '@telefonica/mistica';

interface Numbers {

}

const NumbersContainer: React.FC<Numbers> = (prop) => {

const intl = useIntl();
const screenSize = useScreenSize();
const size = useScreenSize();

    return (
        <Box className='numbers--content' paddingY={80}>
            <Stack space={64}>
                <Text5>{intl.formatMessage({id:'page_home_numbers_title'})}</Text5>
                <Carousel initialActiveItem={0} itemsPerPage={size.isMobile ? 1 : (size.isTablet ? 3 : 4)}
                    items={[
                        <Box paddingRight={32} paddingBottom={32} className='itemNumber'>
                            <Stack space={'between'}>
                                <Stack space={2}>
                                    <Text9>{intl.formatMessage({id:'page_home_cifra1_title'})}</Text9>
                                    <Text4 medium>{intl.formatMessage({id:'page_home_cifra1_subtitle'})}</Text4>
                                </Stack>

                                <Stack space={32}>
                                    <Text3 light>{intl.formatMessage({id:'page_home_cifra1_description'})}</Text3>

                                    <Divider />
                                </Stack>
                            </Stack>
                        </Box>,

                        <Box paddingRight={32} paddingBottom={32} className='itemNumber'>
                            <Stack space={'between'}>
                                <Stack space={2}>
                                    <Text9>{intl.formatMessage({id:'page_home_cifra2_title'})}</Text9>
                                    <Text4 medium>{intl.formatMessage({id:'page_home_cifra2_subtitle'})}</Text4>
                                </Stack>

                                <Stack space={32}>
                                    <Text3 light>{intl.formatMessage({id:'page_home_cifra2_description'})}</Text3>

                                    <Divider />
                                </Stack>
                            </Stack>
                        </Box>,

                        <Box paddingRight={32} paddingBottom={32} className='itemNumber'>
                            <Stack space={'between'}>
                                <Stack space={2}>
                                    <Text9>{intl.formatMessage({id:'page_home_cifra3_title'})}</Text9>
                                    <Text4 medium>{intl.formatMessage({id:'page_home_cifra3_subtitle'})}</Text4>
                                </Stack>

                                <Stack space={32}>
                                    <Text3 light>{intl.formatMessage({id:'page_home_cifra3_description'})}</Text3>

                                    <Divider />
                                </Stack>
                            </Stack>
                        </Box>,

                        <Box paddingRight={32} paddingBottom={32} className='itemNumber'>
                            <Stack space={'between'}>
                                <Stack space={2}>
                                    <Text9>{intl.formatMessage({id:'page_home_cifra4_title'})}</Text9>
                                    <Text4 medium>{intl.formatMessage({id:'page_home_cifra4_subtitle'})}</Text4>
                                </Stack>

                                <Stack space={32}>
                                    <Text3 light>{intl.formatMessage({id:'page_home_cifra4_description'})}</Text3>

                                    <Divider />
                                </Stack>
                            </Stack>
                        </Box>
                    ]}
                />
            </Stack>
        </Box>
    );
};

export default NumbersContainer;