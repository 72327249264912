import { ButtonPrimary, ButtonSecondary, skinVars } from '@telefonica/mistica';
import React from 'react';

import { useIntl } from 'react-intl';

interface ButtonTertiaryProps {
    onPress: any,
    children?: any,
    small?:boolean,
    secondary?:boolean,
    showSpinner?:boolean,
}

const ButtonTertiary: React.FC<ButtonTertiaryProps> = (prop) => {
    const intl = useIntl()

    return (prop.secondary ?
                <ButtonSecondary showSpinner={prop.showSpinner} small={prop.small} style={{borderColor:'#54B9BF', color:'#54B9BF'}}  onPress={prop.onPress ? prop.onPress : undefined}>{prop.children}</ButtonSecondary>
            :
                <ButtonPrimary showSpinner={prop.showSpinner} small={prop.small} style={{backgroundColor:'#54B9BF', color: skinVars.colors.background}}  onPress={prop.onPress ? prop.onPress : undefined}>{prop.children}</ButtonPrimary>);
}

export default ButtonTertiary;
