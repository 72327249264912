import { useIonRouter } from '@ionic/react';
import { Box, Boxed, Carousel, DisplayDataCard, Divider, Grid, Inline, ResponsiveLayout, Select, SkeletonRectangle, skinVars, Stack, Text, Text2, Text3, Text5, useScreenSize, useWindowSize } from '@telefonica/mistica';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import GlobalCard from '../../../../../components/multi-data-card/GlobalCard';
import NetAssetValueEvolution from '../../../../../components/product-data/NetAssetValueEvolution';
import PortfolioComposition from '../../../../../components/product-data/PortfolioComposition';
import { Product } from '../../../../../entities/products/product';
import { GlobalPositionPromoter } from '../../../../../entities/promoters-control-commision/global-position-promoter';
import { getGlobalPositionControlCommision } from '../../../../../utils/apiclient';
import { getSelectedPlanComisionControl, getUser, removeCurrentUserProduct, selectPlanComisionControl } from '../../../../../utils/storage';
import { useTranslate } from '../../../../../utils/translates';
import { formatAmount, formatAnualProfit, formatPlanComisionControl } from '../../../../../utils/utils';
import './GlobalPositionControlCommisionPage.scss';

const GlobalPositionControlCommisionPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
  const size = useWindowSize();
  const [loading, setLoading] = useState(true);
  const router = useIonRouter();
  const [globalPosition, setGlobalPosition] = useState({} as GlobalPositionPromoter);
  const [product, setProduct] = useState(null as null|Product);
  const {formatMonth} = useTranslate();
  let planComisionControlSelected = '';

  const [user, setUser] = useState(getUser());
  const [planComisionControl, setPlanComisionControl] = useState(getUser()?.selectedPlanComisionControl??'');


  useEffect(()=>{
    removeCurrentUserProduct();
    getGlobalPosition();
  }, []);


  useEffect(()=>{
    if(!screenSize.isDesktopOrBigger){
      handleChangePlanComisionControl(getUser()?.selectedPlanComisionControl??'');
    }

  }, [screenSize])

  const handleChangePlanComisionControl = (planComisionControlId: string) =>{
      setPlanComisionControl(planComisionControlId);
      selectPlanComisionControl(planComisionControlId);

      if(planComisionControlId!=planComisionControlSelected){
        getGlobalPosition();
      }
  }

  const getGlobalPosition = ()=>{

    const planCC = getSelectedPlanComisionControl();
    if(planCC){

      const newComisionControlPlanSelected = getUser()?.selectedPlanComisionControl??'';
      if(newComisionControlPlanSelected && newComisionControlPlanSelected.length>0 && newComisionControlPlanSelected!=planComisionControlSelected){
        planComisionControlSelected = newComisionControlPlanSelected;
        setLoading(true);
        getGlobalPositionControlCommision().then(globalPosition=>{
          if(globalPosition){
            setGlobalPosition(globalPosition);
            setProduct({ cdCompania: planCC.cdCompania, cdFondo: planCC.cdFondo, cdPlan: planCC.cdPlan, cdSubplan: planCC.cdSubplan,  composicionCartera: globalPosition?.composicionCartera, graficaValorLiquidativo: globalPosition?.graficaValorLiquidativo});
          }
        }).finally(()=>{
          setLoading(false);
        });
      }
    }
  };

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_control_commision_global_position_title'})}}
      pageInfo={{option: process.env.REACT_APP_AREA_COMISION_CONTROL, role:'control-commision'}}
      onChangeUser={()=>{getGlobalPosition()}}
      showMenu
      welcome>
      <ResponsiveLayout className='inner first globalPositionControlCommision--content'>
        <Stack space={screenSize.isDesktopOrBigger ? 48 : 24}>

          {screenSize.isDesktopOrBigger &&
            <Box>
                <Inline space={'between'} alignItems='center'>
                  <Text5>{intl.formatMessage({id:'page_control_commision_global_position_plan_title'})}</Text5>
                </Inline>
            </Box>
          }

          <Stack space={16}>
            {screenSize.isDesktopOrBigger &&
              (loading ?
                <SkeletonRectangle height={23} />
              :
                <Text3 medium>{intl.formatMessage({id:'page_control_commision_global_position_closing_date_title'},{month:formatMonth(moment(globalPosition.datosCierre?.fechaDatosCierre ?? '').format('MM')), year: moment(globalPosition.datosCierre?.fechaDatosCierre ?? '').year()})}</Text3>
              )
            }

            {screenSize.isDesktopOrBigger ?
              <>
                {loading ?

                  <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : 3}
                  items={[
                    <SkeletonRectangle height={140} />,
                    <SkeletonRectangle height={140} />,
                    <SkeletonRectangle height={140} />
                  ]} />

                :

                  <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : 3}
                    items={[
                      <DisplayDataCard
                        isInverse
                        title=''
                        extra={
                          <Box paddingTop={32}>
                            <Stack space={8}>
                              <Text size={screenSize.isDesktopOrBigger ? 24 : 12} weight='light'>{intl.formatMessage({id:'page_control_commision_global_position_closing_date_patrimony'})}</Text>
                              <Text size={screenSize.isDesktopOrBigger ? 28 : 24} weight='light'>{globalPosition.datosCierre?.patrimonio ? formatAmount(globalPosition.datosCierre?.patrimonio/1000)+' €' : ' ND '}</Text>
                            </Stack>
                          </Box>
                        }
                      />,
                      <DisplayDataCard
                        isInverse
                        title=''
                        extra={
                          <Box paddingTop={32}>
                            <Stack space={8}>
                              <Text size={screenSize.isDesktopOrBigger ? 24 : 12} weight='light'>{intl.formatMessage({id:'page_control_commision_global_position_closing_date_shares'})}</Text>
                              <Text size={screenSize.isDesktopOrBigger ? 28 : 24} weight='light'>{globalPosition.datosCierre?.numParticipes ? formatAmount(globalPosition.datosCierre?.numParticipes, true) : ' ND '}</Text>
                            </Stack>
                          </Box>
                        }
                      />,
                      <DisplayDataCard
                        isInverse
                        title=''
                        extra={
                          <Box paddingTop={32}>
                            <Stack space={8}>
                              <Text size={screenSize.isDesktopOrBigger ? 24 : 12} weight='light'>{intl.formatMessage({id:'page_control_commision_global_position_closing_date_beneficiaries'})}</Text>
                              <Text size={screenSize.isDesktopOrBigger ? 28 : 24} weight='light'>{globalPosition.datosCierre?.numBeneficiarios ? formatAmount(globalPosition.datosCierre?.numBeneficiarios, true) : ' ND '}</Text>
                            </Stack>
                          </Box>
                        }
                      />
                    ]}
                  />

                }
              </>
            :
              <>
                {loading ?
                  <SkeletonRectangle height={88} />
                :
                <GlobalCard values={[
                              {title: intl.formatMessage({id:'page_control_commision_global_position_closing_date_patrimony_short'}), value: (globalPosition.datosCierre?.patrimonio ? formatAmount(globalPosition.datosCierre?.patrimonio/1000)+'€' : ' ND ')},
                              {title: intl.formatMessage({id:'page_control_commision_global_position_closing_date_shares_short'}), value: (globalPosition.datosCierre?.numParticipes ? formatAmount(globalPosition.datosCierre?.numParticipes, true) : ' ND ')},
                              {title: intl.formatMessage({id:'page_control_commision_global_position_closing_date_beneficiaries_short'}), value: (globalPosition.datosCierre?.numBeneficiarios ? formatAmount(globalPosition.datosCierre?.numBeneficiarios, true) : ' ND ')}]}
                ></GlobalCard>
                }
              </>
            }

            {!screenSize.isDesktopOrBigger &&
              <Stack space={24}>
                <Stack space={12}>
                  <Select fullWidth
                      name="pensionPlans"
                      label={intl.formatMessage({id:'page_control_commision_global_select_pension_plans'})}
                      value={planComisionControl}
                      onChangeValue={(val:string)=>handleChangePlanComisionControl(val)}
                      options={user.planesComisionControl?.map(item => ({text:item.NombrePlan??'', value: formatPlanComisionControl(item)})) ?? []}
                  />
                </Stack>

                <Text5>{intl.formatMessage({id:'page_control_commision_global_position_plan_title'})}</Text5>
              </Stack>
            }

          </Stack>

            {loading ?
                <Stack space={24}>
                  <SkeletonRectangle height={23} />

                  <Stack space={16}>
                    <Grid columns={5} gap={screenSize.isDesktopOrBigger ? 24 : 8}>
                      <SkeletonRectangle height={97} />
                      <SkeletonRectangle height={97} />
                      <SkeletonRectangle height={97} />
                      <SkeletonRectangle height={97} />
                      <SkeletonRectangle height={97} />
                    </Grid>
                  </Stack>
                </Stack>
              :

                <Stack space={24}>
                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_global_position_profitability_title'},{date:moment(globalPosition.rentabilidad?.fechaRentabilidad ?? '').format('DD-MM-YYYY')})}</Text3>

                  <Stack space={16}>
                    <Grid columns={5}>
                      <Inline space={2} alignItems='center' fullWidth className='flexWidth'>
                        <Text2 medium color={skinVars.colors.brand}>{intl.formatMessage({id:'page_control_commision_global_position_profitability_current_year'})}</Text2>
                      </Inline>

                      <Inline space={2} alignItems='center' fullWidth className='flexWidth'>
                        <Text2 medium color={skinVars.colors.brand}>1</Text2>
                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_control_commision_global_position_profitability_year'})}</Text2>
                      </Inline>
                      <Inline space={2} alignItems='center' fullWidth className='flexWidth'>
                        <Text2 medium color={skinVars.colors.brand}>3º</Text2>
                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_control_commision_global_position_profitability_year'})}</Text2>
                      </Inline>

                      <Inline space={2} alignItems='center' fullWidth className='flexWidth'>
                        <Text2 medium color={skinVars.colors.brand}>5º</Text2>
                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_control_commision_global_position_profitability_year'})}</Text2>
                      </Inline>

                      <Inline space={2} alignItems='center' fullWidth className='flexWidth'>
                        <Text2 medium color={skinVars.colors.brand}>10º</Text2>
                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_control_commision_global_position_profitability_year'})}</Text2>
                      </Inline>
                    </Grid>

                    <Divider />

                    <Grid columns={5} gap={screenSize.isDesktopOrBigger ? 24 : 8}>
                      <Boxed isInverse>
                        <Box paddingY={8} className='boxWidth'>
                          <Text3 regular>{globalPosition.rentabilidad?.planActual ? formatAnualProfit(globalPosition.rentabilidad?.planActual)+'%' : ' - '}</Text3>
                        </Box>
                      </Boxed>

                      <Boxed isInverse>
                        <Box paddingY={8} className='boxWidth'>
                          <Text3 regular>{globalPosition.rentabilidad?.planUltimo ? formatAnualProfit(globalPosition.rentabilidad?.planUltimo)+'%' : ' - '}</Text3>
                        </Box>
                      </Boxed>

                      <Boxed isInverse>
                        <Box paddingY={8} className='boxWidth'>
                          <Text3 regular>{globalPosition.rentabilidad?.plan_3 ? formatAnualProfit(globalPosition.rentabilidad?.plan_3)+'%' : ' - '}</Text3>
                        </Box>
                      </Boxed>

                      <Boxed isInverse>
                        <Box paddingY={8} className='boxWidth'>
                          <Text3 regular>{globalPosition.rentabilidad?.plan_5 ? formatAnualProfit(globalPosition.rentabilidad?.plan_5)+'%' : ' - '}</Text3>
                        </Box>
                      </Boxed>

                      <Boxed isInverse>
                        <Box paddingY={8} className='boxWidth'>
                          <Text3 regular>{globalPosition.rentabilidad?.plan_8 ? formatAnualProfit(globalPosition.rentabilidad?.plan_8)+'%' : ' - '}</Text3>
                        </Box>
                      </Boxed>
                    </Grid>
                  </Stack>
                </Stack>
            }

            {loading ?
                <Stack space={24}>
                  <SkeletonRectangle height={23} />
                  <SkeletonRectangle height={248} />
                </Stack>
              :
                <Stack space={24}>
                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_global_position_portfolio_composition_title'},{date:moment(globalPosition.composicionCartera?.[0].fechaValor ?? '').format('DD-MM-YYYY')})}</Text3>
                  <PortfolioComposition loading={loading} product={product} withoutBoxed={true}  />
                </Stack>
            }



            {loading ?
                <Stack space={24}>
                  <SkeletonRectangle height={23} />
                  <SkeletonRectangle height={248} />
                </Stack>
              :
                <Stack space={24}>
                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_global_position_net_asset_value_evolution_title'})}</Text3>
                  <NetAssetValueEvolution loading={loading} product={product} withoutBoxed={true}  />
                </Stack>
            }
        </Stack>
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default GlobalPositionControlCommisionPage;
