import { Box, ResponsiveLayout, Text8, skinVars } from '@telefonica/mistica';
import { useIntl } from 'react-intl';

import LayoutPublic from '../../../components/layout/LayoutPublic';

const CookiesPolicyPage: React.FC = () => {
  const intl = useIntl()

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_cookies_policy_seo_title'}), metaDescription:intl.formatMessage({id:'page_cookies_policy_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_cookies_policy_title'})}}
    >
        <ResponsiveLayout className='content' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
          <ResponsiveLayout className='content' backgroundColor={"transparent"}>
            <Box paddingY={48}>
              <Text8>{intl.formatMessage({id:'page_cookies_policy_title'})}</Text8>
            </Box>
          </ResponsiveLayout>
        </ResponsiveLayout>
        <ResponsiveLayout className='content' fullWidth={true} backgroundColor={"transparent"}>
          <ResponsiveLayout className='content' backgroundColor={"transparent"}>
            <Box paddingY={48}>
              <div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_cookies_policy_text'})}}></div>
            </Box>
          </ResponsiveLayout>
        </ResponsiveLayout>

    </LayoutPublic>
  );
};

export default CookiesPolicyPage;
