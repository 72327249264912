import Highlighter from "react-highlight-words";
import { useIntl } from 'react-intl';

import { useIonRouter } from '@ionic/react';
import { Box, Boxed, ButtonPrimary, Divider, EmptyStateCard, Form, Grid, IconArrowLineRightRegular, IconButton, IconChevronLeftRegular, IconChevronRightRegular, IconCloseRegular, IconEmailRegular, IconSearchFileRegular, Inline, LoadingBar, ResponsiveLayout, SearchField, skinVars, Stack, Tag, TagType, Text, Text1, Text2, Text3, Text4, Text5, TextLink, Touchable, useScreenSize, useWindowSize } from '@telefonica/mistica';
import ButtonGroup from "@telefonica/mistica/dist/button-group";
import { useEffect, useLayoutEffect, useState } from 'react';
import slugify from 'react-slugify';
import { NavOption } from '../../entities/commons/nav-option.model';
import { SuggestionSearch } from "../../entities/contents/suggestion-search.model";
import ContactPage from '../../pages/public/contact/ContactPage';
import SolutionsForFreelancersPage from "../../pages/public/employment-system-pension-plans/SolutionsForFreelancersPage";
import ForInvestorPage from "../../pages/public/for-investor/ForInvestorPage";
import HomePage from '../../pages/public/home/HomePage';
import InvestmentFundsPage from "../../pages/public/investment-funds/InvestmentFundsPage";
import NewsPage from "../../pages/public/news/NewsPage";
import PensionPlansPage from "../../pages/public/pension-plans/PensionPlansPage";
import { getDictionary, getSuggestionsSearch, search } from '../../utils/apiclient';
import { getFooterNavigation, getUrlNavigation } from '../../utils/navigation';
import { formatDate, isBot, scrollToTop, trackError } from '../../utils/utils';
import EmptyStateCustom from "../empty-state/EmptyStateCustom";
import './Searcher.scss';

interface SearcherProps {
    small?: boolean,
    inverse?: boolean,
    showSearch: boolean,
    onClose:() => void,
}

const Searcher: React.FC<SearcherProps> = (prop) => {
    const intl = useIntl()

    const router = useIonRouter();
    const screenSize = useScreenSize();
    const size = useWindowSize();
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(false);
    const [notResults, setNotResults] = useState(false);
    const [searcher, setSearcher] = useState('');
    const [totalMatchs, setTotalMatchs] = useState([]);
    const [searched, setSearched] = useState('');
    const [showingSuggestions, setShowingSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([] as SuggestionSearch[]);
    const [suggestionsFilter, setSuggestionsFilter] = useState([] as SuggestionSearch[]);
    const [paginate, setPaginate] = useState(null as null|{previousPage:number|null, nextPage:number|null, total:number, totalPages:number, items:any[], currentPage: number, perPage: number, initItem: number, finishItem: number});

    const [footerNavigation, setFooterNavigation] = useState([] as NavOption[])
    useLayoutEffect(()=>{
        const options = getFooterNavigation(true);
        setFooterNavigation(options);

        getSuggestionsSearch().then((result)=>{
            setSuggestions(result);
        })
    }, []);

    useEffect(()=>{
        setShowingSuggestions(false);
        setResults(false);
        setNotResults(false);



    },[prop.showSearch]);

    const toPaginate = (items:any[], page = 1, perPage = 10) => {
        const offset = perPage * (page - 1);
        const totalPages = Math.ceil(items.length / perPage);
        const paginatedItems = items.slice(offset, perPage * page);

        return {
            previousPage: page - 1 ? page - 1 : null,
            nextPage: (totalPages > page) ? page + 1 : null,
            total: items.length,
            totalPages: totalPages,
            items: paginatedItems,
            currentPage: page,
            perPage: perPage,
            initItem: (perPage*page) - perPage + 1,
            finishItem: (perPage*page)>items.length ? items.length : perPage*page
        };
    };

    const prevPage  = () =>{
        if(paginate?.previousPage){
            scrollToTop('search-results');
            setPaginate(toPaginate(totalMatchs, paginate?.previousPage));
        }
    }

    const nextPage  = () =>{
        if(paginate?.nextPage){
            scrollToTop('search-results');
            setPaginate(toPaginate(totalMatchs, paginate?.nextPage));
        }
    }

    const showSuggestions  = (text:string) =>{
        setSearcher(text);
        setShowingSuggestions(true);

        if(text.length<3){
            const sugg = suggestions.filter(item=>item.orden).sort((n1,n2) => {
                if(!n1.orden || !n2.orden){
                    return -1;
                }
                if (n1.orden > n2.orden) {
                    return 1;
                }

                if (n1.orden < n2.orden) {
                    return -1;
                }

                return 0;
            }).concat(suggestions.filter(item=>!item.orden));
            if(sugg.length>0 && sugg.length>6){
                setSuggestionsFilter(sugg.slice(0, 6));
            }else{
                setSuggestionsFilter(sugg);
            }
        }else{
            const sugg = suggestions.sort((n1,n2) => {
                if (n1.nbNombre > n2.nbNombre) {
                    return 1;
                 }
                 if (n1.nbNombre < n2.nbNombre) {
                     return -1;
                 }
                 return 0;
            });
            const suggFilter = sugg.filter(item=>item.nbNombre.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(text.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))===0).concat(sugg.filter(item=>item.nbNombre.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(text.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))>0));
            if(suggFilter.length>0){
                if(suggFilter.length>10){
                    setSuggestionsFilter(suggFilter.slice(0, 5));
                }else{
                    setSuggestionsFilter(suggFilter);
                }
            }else{
                setSuggestionsFilter([]);
            }
        }
    };

    const handleSubmit  = (text:any): Promise<void> =>
        new Promise((resolve) => {
            setLoading(true);
            setPaginate(null);
            setTotalMatchs([]);
            setNotResults(false);
            setResults(false);
            setShowingSuggestions(false);
            if(typeof text ==='string' ? text.trim().length>2 : searcher.trim().length>2){
                search(typeof text ==='string' ? text.trim() : searcher.trim()).then((result)=>{
                    setSearched(result.search);
                    if(result.matchs && Object.entries(result.matchs).length>0){
                        setNotResults(false);
                        setResults(true);
                        setTotalMatchs(result.matchs);
                        setPaginate(toPaginate(result.matchs))
                    }else{
                        setNotResults(true);
                        setResults(false);
                    }
                }).finally(()=>{
                    setLoading(false);
                    resolve();
                });
            }else{
                setLoading(false);
                resolve();
            }
        });


    function getContentMatch(index:number, match: any, search:string): JSX.Element {
        let title='';
        let content='';
        let titleType='';
        let url='';
        let type : TagType='promo';
        let date = '';

        switch(match.type){
            case 'faq':
                title = match.item.nbNombre;
                content = match.item.nbDescripcion;
                titleType =intl.formatMessage({id:'menu_footer_for_investor_faqs'});
                type = 'promo';

                url = getUrlNavigation(ForInvestorPage)+'#'+slugify(intl.formatMessage({id:'menu_footer_for_investor_faqs'}));

                if(!match.item.paraElInversor){
                    if(match.item.cdCompania.toString()===(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1')){
                        url = getUrlNavigation(PensionPlansPage)+'#'+slugify(intl.formatMessage({id:'menu_footer_pension_plans_faqs'}));
                    }else{
                        url = getUrlNavigation(InvestmentFundsPage)+'#'+slugify(intl.formatMessage({id:'menu_footer_investment_funds_faqs'}));
                    }
                }

                break;
            case 'term':
                title = match.item.nbNombre;
                content = match.item.nbDescripcion;
                titleType =intl.formatMessage({id:'menu_footer_for_investor_glossary_terms'});
                type = 'active';
                url = getUrlNavigation(ForInvestorPage)+'#'+slugify(intl.formatMessage({id:'menu_footer_for_investor_glossary_terms'}));
                break;
            case 'report':
                title = match.item.nbTitulo;
                content = match.item.nbDescripcion;
                titleType =intl.formatMessage({id:'menu_footer_for_investor_guide_reports'});
                type = 'success';
                url = getUrlNavigation(ForInvestorPage)+'#'+slugify(intl.formatMessage({id:'menu_footer_for_investor_guide_reports'}));
                break;
            case 'pension_plan':
                if(match.item.individual===process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS){
                    title = intl.formatMessage({id:'page_solutions_for_freelancers_hero_title'});
                    content = intl.formatMessage({id:'page_solutions_for_freelancers_hero_description'});
                    titleType =intl.formatMessage({id:'page_pension_plans_title'});
                    type = 'error';
                    url = getUrlNavigation(SolutionsForFreelancersPage);
                }else{
                    title = match.item.descripcion;
                    content = match.item.informacionGeneral.descripcionCortaAreaPublica;
                    titleType =intl.formatMessage({id:'page_pension_plans_title'});
                    type = 'error';
                    url = getUrlNavigation(PensionPlansPage)+'/'+match.item.slug;
                }
                break;
            case 'investment_fund':
                title = match.item.descripcion;
                content = match.item.informacionGeneral.descripcionCortaAreaPublica;
                titleType =intl.formatMessage({id:'page_investment_funds_title'});
                type = 'error';
                url = getUrlNavigation(InvestmentFundsPage)+'/'+match.item.slug;
                break;
            case 'news':
                date = formatDate(match.item.publication);
                title = match.item.title;
                content = match.item.content;
                titleType =intl.formatMessage({id:'page_news_title'});
                type = 'warning';
                url = getUrlNavigation(NewsPage)+'/'+match.item.slug;
                break;
            case 'pages':
                title = intl.formatMessage({id:match.title});
                content = match.content;
                titleType = intl.formatMessage({id:'page_pages'});
                type = 'inactive';
                url = match.url;
                break;
        }

        return <Touchable key={'search-result-url'+paginate?.currentPage+'-'+index}  onPress={()=>{ setShowingSuggestions(false); prop.onClose();router.push(url)}}>
                    <Stack key={'search-result-'+paginate?.currentPage+'-'+index} space={8}>
                        <Box key={'search-result-tag-box-'+paginate?.currentPage+'-'+index} paddingTop={16}>
                            <Inline key={'search-result-tag-inline-'+paginate?.currentPage+'-'+index} space={4} alignItems='center'>
                                <Tag key={'search-result-tag-'+paginate?.currentPage+'-'+index} type={type}>{titleType}</Tag>
                            </Inline>
                        </Box>
                        <Text3 color={skinVars.rawColors.textPrimary} key={'search-result-title-text-'+paginate?.currentPage+'-'+index} regular>
                            <Highlighter
                                key={'search-result-title-'+paginate?.currentPage+'-'+index}
                                searchWords={search.trim().split(' ')}
                                autoEscape={true}
                                textToHighlight={title}
                                sanitize={(text)=>{
                                    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                                }}
                            />
                        </Text3>

                        <Text1 regular color={skinVars.rawColors.textSecondary}>{date}</Text1>

                        <Text2 color={skinVars.rawColors.textSecondary}  key={'search-result-content-text-'+paginate?.currentPage+'-'+index} regular truncate={2}>
                            <Highlighter
                                key={'search-result-content-'+paginate?.currentPage+'-'+index}
                                searchWords={search.trim().split(' ')}
                                autoEscape={true}
                                textToHighlight={content}
                                sanitize={(text)=>{
                                    let t = text;
                                    if(text && text.length > 0){
                                        try{
                                            t = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                        }catch(err){
                                            if(err){
                                                trackError(JSON.stringify(err));
                                            }
                                        }
                                    }
                                    return t;
                                }}
                            />
                        </Text2>
                </Stack>
            </Touchable>;
    }

    return (
        <>
            <LoadingBar visible={loading} />

            <style>{
                '.mainSearcher .verticalDivider:before{background-color:'+(prop.inverse ? skinVars.colors.dividerInverse : skinVars.colors.divider)+';}'+
                '.mainSearcher .mainSearcher__input svg path{fill:'+(skinVars.colors.textPrimary)+';}'+
                '.mainSearcher .groupLinks:before{background:'+(skinVars.colors.divider)+'}'+
                '.mainSearcher .mainSearcher__collapsible{background:'+(skinVars.colors.background)+'}'+
                (prop.inverse ? '.mainSearcher__itemsResults [data-component-name="Tag"]{background:'+(skinVars.colors.backgroundAlternative)+'}' : '')
            }</style>

            <Box className={'mainSearcher'+(prop.showSearch ? ' visible': '')+(notResults || results ? ' mainSearcher--fixed' : '')}>
                <ResponsiveLayout isInverse={prop.inverse} backgroundColor={`${prop.inverse ? skinVars.colors.backgroundBrand : skinVars.colors.background}`}>
                    <Box paddingY={12}>
                        <Form onSubmit={handleSubmit}>
                            <Inline space='between' className='mainSearcher__inline'>
                                <Box className='mainSearcher__input'>
                                    <SearchField  onFocus={()=>{showSuggestions(searcher)}} autoComplete="off" fullWidth name='searcher' value={searcher} onChangeValue={((val)=>{showSuggestions(val)})} label='Buscar'  />
                                </Box>

                                {showingSuggestions && (suggestionsFilter.length>0) &&
                                    <Boxed className="mainSearcher__suggestions">
                                        <Stack space={0}>
                                            {(!searcher || searcher.length==0) &&
                                                <Box padding={16}>
                                                    <Text color={skinVars.colors.neutralMedium}>
                                                        {intl.formatMessage({id:'page_searcher_suggestions_title'})}
                                                    </Text>
                                                </Box>
                                            }

                                            {suggestionsFilter.map((item, index) => (
                                                <Touchable key={'suggestion-'+index} onPress={()=>{setSearcher(item.nbNombre); handleSubmit(item.nbNombre).then(()=>{}); setShowingSuggestions(false); }}>
                                                    <Box padding={16}>
                                                        {searcher.length>2 ?
                                                            <Highlighter
                                                                key={'suggestion-hightlighter-'+paginate?.currentPage+'-'+index}
                                                                searchWords={searcher.trim().split(' ')}
                                                                autoEscape={true}
                                                                highlightStyle={{backgroundColor: 'transparent', fontWeight:'bold'}}
                                                                textToHighlight={item.nbNombre}
                                                                sanitize={(text)=>{
                                                                    let t = text;
                                                                    if(text && text.length > 0){
                                                                        try{
                                                                            t = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                                                        }catch(err){
                                                                            if(err){
                                                                                trackError(JSON.stringify(err));
                                                                            }
                                                                        }
                                                                    }
                                                                    return t;
                                                                }}
                                                            />
                                                        :
                                                                item.nbNombre
                                                        }
                                                    </Box>
                                                </Touchable>
                                            ))}
                                        </Stack>
                                    </Boxed>
                                }

                                <Inline space={screenSize.isDesktopOrBigger ? 32 : 24} className='mainSearcher__options'>
                                    <Box paddingY={12} paddingX={4}>
                                        <Box paddingTop={2}>
                                            <IconButton onPress={handleSubmit} disabled={searcher.trim().length<=2}>
                                                <IconArrowLineRightRegular size={screenSize.isDesktopOrBigger ? 32 : 24} color={prop.inverse ? skinVars.colors.background : skinVars.colors.brand} />
                                            </IconButton>
                                        </Box>
                                    </Box>

                                    <Box className='verticalDivider'></Box>

                                    <Box paddingY={12} paddingX={4}>
                                        <Box paddingTop={2}>
                                            <IconButton onPress={()=>{setShowingSuggestions(false); prop.onClose()}}>
                                                <IconCloseRegular size={screenSize.isDesktopOrBigger ? 32 : 24} color={prop.inverse ? skinVars.colors.background : skinVars.colors.brand} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Inline>
                            </Inline>
                        </Form>
                    </Box>
                </ResponsiveLayout>

                <Divider />

                {notResults &&
                    <Stack space={0} className='mainSearcher__collapsible mainSearcher__collapsible--empty'>
                        <ResponsiveLayout className='mainSearcher__totalResults' backgroundColor={skinVars.colors.backgroundAlternative}>
                            <Box paddingBottom={48}>
                                <EmptyStateCard
                                    icon={
                                        <IconSearchFileRegular size="56" color={skinVars.colors.brand} />
                                    }
                                    title={intl.formatMessage({id:'page_searcher_empty_title'}) + " '"+searched+"'"}
                                    description={intl.formatMessage({id:'page_searcher_empty_description'})}
                                />
                            </Box>
                        </ResponsiveLayout>


                        <ResponsiveLayout className='content' fullWidth={true}>
                            <ResponsiveLayout>

                                <EmptyStateCustom
                                    largeImageUrl="../assets/img/necesitas-ayuda.jpg"
                                    title={intl.formatMessage({id:'page_searcher_need_help_title'})}
                                    description={intl.formatMessage({id:'page_searcher_need_help_description'}) + " '"+searched+"'"}
                                    button={
                                    <ButtonGroup
                                        primaryButton={isBot ?
                                        <ButtonPrimary href={getUrlNavigation(ContactPage)}>
                                            <IconEmailRegular color={"currentColor"} />
                                            {intl.formatMessage({id:'page_searcher_need_help_action_button'})}
                                        </ButtonPrimary>
                                    :
                                        <ButtonPrimary onPress={()=>router.push(getUrlNavigation(ContactPage))}>
                                            <IconEmailRegular color={"currentColor"} />
                                            {intl.formatMessage({id:'page_searcher_need_help_action_button'})}
                                        </ButtonPrimary>
                                    }
                                    ></ButtonGroup>
                                    }
                                />

                            </ResponsiveLayout>
                        </ResponsiveLayout>

                        <ResponsiveLayout isInverse={prop.inverse} backgroundColor={prop.inverse ? skinVars.colors.brand : skinVars.colors.background}>
                            <Box paddingTop={prop.inverse ? 48 : 0} paddingBottom={80}>
                                <Stack space={56}>
                                    <Stack space={16}>
                                        <Text5>{intl.formatMessage({id:'page_searcher_empty_response_title'})}</Text5>

                                        <Text4 regular>{intl.formatMessage({id:'page_searcher_empty_response_description'})}</Text4>
                                    </Stack>

                                    <Box className='mainSearcher__optionsLink'>
                                        <Grid columns={screenSize.isDesktopOrBigger ? 3 : 1} gap={32}>
                                            {footerNavigation.map((item, index) => (
                                                <Stack key={'fs'+index} space={screenSize.isDesktopOrBigger ? 24 : 16} className='groupLinks'>
                                                    {(item.href || item.page) ?
                                                        (item.href ?
                                                            <TextLink key={'footer-nav-'+index} href={item.href} newTab><Text1 medium>{intl.formatMessage({id:item.title})}</Text1></TextLink>
                                                        :
                                                            <TextLink key={'footer-nav-'+index} href={getUrlNavigation(item.page??HomePage)+(item.anchor ? '#'+slugify(intl.formatMessage({id:item.title})) : '')}><Text1 medium>{intl.formatMessage({id:item.title})}</Text1></TextLink>)
                                                    :
                                                        <Text1 medium>{intl.formatMessage({id:item.title})}</Text1>
                                                    }



                                                    {item.options && item.options.length>0 &&
                                                        <Stack key={'fss'+index} space={4}>
                                                            {item.options.map((option, subindex) => (
                                                                option.href ?
                                                                    <TextLink key={'footer-nav-'+index+'-option-'+subindex} href={option.href} newTab><Text1 regular>{intl.formatMessage({id:option.title})}</Text1></TextLink>
                                                                :
                                                                    <TextLink key={'footer-nav-'+index+'-option-'+subindex} href={getUrlNavigation(option.page??HomePage)+(option.anchor ? '#'+slugify(intl.formatMessage({id:option.title})) : '')}><Text1 regular>{intl.formatMessage({id:option.title})}</Text1></TextLink>
                                                            ))}
                                                        </Stack>
                                                    }
                                                </Stack>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Stack>
                            </Box>
                        </ResponsiveLayout>
                    </Stack>
                }



                {results &&
                    <Stack space={0} className='mainSearcher__collapsible'>
                        <ResponsiveLayout className='mainSearcher__totalResults' backgroundColor={skinVars.colors.backgroundAlternative}>
                            <Box paddingY={24}>
                                <Text4 color={skinVars.rawColors.textPrimary} regular>{totalMatchs.length} {totalMatchs.length==1 ? intl.formatMessage({id:'page_searcher_result'}) : intl.formatMessage({id:'page_searcher_results'})}</Text4>
                            </Box>
                        </ResponsiveLayout>

                        <ResponsiveLayout className='mainSearcher__itemsResults' backgroundColor={skinVars.colors.background}>
                            <div id="search-results">
                                <Box paddingTop={24} paddingBottom={16}>
                                    <Stack space={16}>

                                        {paginate && paginate.items.map((match, index) => (
                                            <Stack space={32} key={'search-result-content-'+paginate?.currentPage+'-'+index}>
                                                <Stack space={8} key={'search-result-container-'+paginate?.currentPage+'-'+index}>
                                                    { getContentMatch(index, match, searched) }

                                                </Stack>

                                                <Divider />
                                            </Stack>
                                        ))}

                                        <Inline space='between'>
                                            {paginate &&
                                                <Text2 color={skinVars.rawColors.textPrimary} regular>{paginate.initItem}-{paginate.finishItem} {intl.formatMessage({id:'page_searcher_of'})} {totalMatchs.length} {totalMatchs.length==1 ? intl.formatMessage({id:'page_searcher_input'}) : intl.formatMessage({id:'page_searcher_inputs'})}</Text2>
                                            }

                                            <Inline space={24} alignItems="center">
                                                <Inline space={16} alignItems='center'>
                                                    <IconButton disabled={!(paginate && paginate.previousPage)} onPress={() => {prevPage()}}>
                                                        <IconChevronLeftRegular color={(paginate && paginate.previousPage) ? skinVars.colors.brand : skinVars.colors.buttonSecondaryBackgroundSelected} />
                                                    </IconButton>

                                                    <IconButton disabled={!(paginate && paginate.nextPage)} onPress={() => {nextPage()}}>
                                                        <IconChevronRightRegular color={(paginate && paginate.nextPage) ? skinVars.colors.brand : skinVars.colors.buttonSecondaryBackgroundSelected} />
                                                    </IconButton>
                                                </Inline>
                                            </Inline>
                                        </Inline>
                                    </Stack>
                                </Box>
                            </div>



                            <ResponsiveLayout className='content' fullWidth={true}>
                                <ResponsiveLayout>

                                    <EmptyStateCustom
                                        largeImageUrl="../assets/img/necesitas-ayuda.jpg"
                                        title={intl.formatMessage({id:'page_searcher_need_help_title'})}
                                        description={intl.formatMessage({id:'page_searcher_need_help_description'}) + " '"+searched+"'"}
                                        button={
                                        <ButtonGroup
                                            primaryButton={isBot ?
                                            <ButtonPrimary href={getUrlNavigation(ContactPage)}>
                                                <IconEmailRegular color={"currentColor"} />
                                                {intl.formatMessage({id:'page_searcher_need_help_action_button'})}
                                            </ButtonPrimary>
                                        :
                                            <ButtonPrimary onPress={()=>router.push(getUrlNavigation(ContactPage))}>
                                                <IconEmailRegular color={"currentColor"} />
                                                {intl.formatMessage({id:'page_searcher_need_help_action_button'})}
                                            </ButtonPrimary>
                                        }
                                        ></ButtonGroup>
                                        }
                                    />

                                </ResponsiveLayout>
                            </ResponsiveLayout>

                        </ResponsiveLayout>
                    </Stack>
                }
            </Box>
        </>
    );
};

export default Searcher;