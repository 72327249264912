import { Box, Divider, LoadingBar, ResponsiveLayout, skinVars, Stack, Stepper, Text5, useScreenSize } from "@telefonica/mistica";
import { useIntl } from "react-intl";
import LayoutPrivateArea from "../../../../../components/layout/LayoutPrivateArea";

import { useIonRouter } from "@ionic/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ConfirmOperation from "../../../../../components/operate/ConfirmOperation";
import ContributionPensionPlan from "../../../../../components/operate/ContributionPensionPlan";
import MobilizePensionPlan from "../../../../../components/operate/MobilizePensionPlan";
import SummaryOperate from "../../../../../components/operate/SummaryOperate";
import { Contract } from "../../../../../entities/contract/contract";
import { OperationContribution } from "../../../../../entities/contract/operations/operation-contribution";
import { OperationMobilization } from "../../../../../entities/contract/operations/operation-mobilization";
import { addContribution, addMobilization, getInfoContract, trackStadistics } from "../../../../../utils/apiclient";
import { getUrlNavigation } from '../../../../../utils/navigation';
import { addNumberCourseOperationsCache, getCurrentUserProduct, getOperationContribution, getOperationKey, getOperationMobilization, getTokenSign, removeCurrentUserContract, removeOperationContribution, removeOperationKey, removeOperationMobilization, removeTokenSign } from "../../../../../utils/storage";
import { useToast } from "../../../../../utils/toast";
import { scrollToTop } from '../../../../../utils/utils';
import { OperateEmploymentPlansCustomerPage, OperateIndividualPlansCustomerPage, OperateSimplifiedEmploymentPlansCustomerPage } from "./OperatePensionPlanCustomerPage";
import { EmploymentPlansCustomerPage, IndividualPlansCustomerPage, SimplifiedEmploymentPlansCustomerPage } from "./PensionPlansCustomerPage";
import moment from "moment";
import { useDigitalSign } from "../../../../../components/digital-sign/DigitalSign";

export interface OperationsPensionPlansCustomerPageProps {
    type: string,
    operation: string
  }

const OperationsPensionPlansCustomerPage: React.FC<OperationsPensionPlansCustomerPageProps> = (prop) => {
    const intl = useIntl();
    const router = useIonRouter();
    const screenSize = useScreenSize();
    const currentUserProduct = getCurrentUserProduct();
    const currentProduct = currentUserProduct?.product;
    const [grandParentTitle, setGrandParentTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingFirst, setLoadingFirst] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);
    const grandParentPage = (prop.type===process.env.REACT_APP_PLAN_PENSIONES_EMPRESA ? EmploymentPlansCustomerPage : (prop.type===process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL ? IndividualPlansCustomerPage : SimplifiedEmploymentPlansCustomerPage));
    const [parentTitle, setParentTitle] = useState('');
    const parentPage = (prop.type===process.env.REACT_APP_PLAN_PENSIONES_EMPRESA ? OperateEmploymentPlansCustomerPage : (prop.type===process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL ? OperateIndividualPlansCustomerPage : OperateSimplifiedEmploymentPlansCustomerPage));
    const [title, setTitle] = useState('');
    const [titleResponsive, setTitleResponsive] = useState('');
    const [options, setOptions] = useState([] as string[]);
    const [contract, setContract] = useState(null as null|Contract);
    const [operationContribution, setOperationContribution] = useState(null as null|OperationContribution);
    const [operationMobilization, setOperationMobilization] = useState(null as null|OperationMobilization);
    const { showToast } = useToast()
    const { digitalSign } = useDigitalSign();

    useLayoutEffect(()=>{
        removeOperationContribution();
        setOptions([intl.formatMessage({id:'page_customer_pension_plans_operations_step_operative'}), intl.formatMessage({id:'page_customer_pension_plans_operations_step_operations_key'})]);
        switch(prop.type){
          case process.env.REACT_APP_PLAN_PENSIONES_EMPRESA:
            setGrandParentTitle(intl.formatMessage({id:'page_customer_pension_plans_employment_plans_title'}));
            break;
          case process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL:
            setGrandParentTitle(intl.formatMessage({id:'page_customer_pension_plans_individual_plans_title'}));
            break;
          case process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS:
            setGrandParentTitle(intl.formatMessage({id:'page_customer_pension_plans_simplified_employment_plans_title'}));
            break;
        }
        switch(prop.operation){
            case process.env.REACT_APP_MENU_PLANES_HACER_APORTACION:
                setTitle(intl.formatMessage({id:'page_customer_pension_plans_aportation_to'}) + ' ' + currentProduct?.descripcion??'')
                setTitleResponsive('Aportación')
                break;
            case process.env.REACT_APP_MENU_PLANES_MOVILIZAR:
                setTitle(intl.formatMessage({id:'page_customer_pension_plans_mobilize_to'}) + ' ' + currentProduct?.descripcion??'')
                setTitleResponsive('Movilizar')
                break;
        }
        setParentTitle(currentProduct?.descripcion??'');
      }, []);



    useEffect(()=>{
        if(!router.canGoBack() || !currentUserProduct){
            router.push(getUrlNavigation(parentPage));
            return;
        }
        setLoadingFirst(false);
        getInfoContract({numContract: currentUserProduct?.numContrato ?? '', cdCompany:currentProduct?.cdCompania ?? -1, cdPlan: currentProduct?.cdPlan ?? '', cdFund: currentProduct?.cdFondo?? '', cdSubplan: currentProduct?.cdSubplan?? ''}).then((result)=>{
            if(result){
                setContract(result);
            }
        }).finally(()=>{
            setLoading(false);
        });
    }, []);

    //Stepper
    const [step, setStep] = useState(0);


    const summaryOperation = ()=>{
        scrollToTop();
        setOperationContribution(getOperationContribution());
        setOperationMobilization(getOperationMobilization());
        setStep(1)
    }

    const confirmOperationDigitalSign= (): Promise<void> =>
        new Promise((resolve) => {
            setLoadingSave(true);

            const dateSign = moment().unix();
            if(operationContribution){
                const opContributionDS ={
                    ...operationContribution,
                    dateSign:dateSign,
                    electronic:true,
                    confirm:true
                };
                setLoadingSave(true);
                addContribution(opContributionDS).then((result)=>{

                    if(result.tokenOperation){
                        digitalSign(result.tokenOperation
                        , ()=>{
                                confirmOperation(result.tokenOperation, dateSign);
                                resolve();
                        }, ()=>{
                            setLoadingSave(false);
                            resolve();
                        });
                    }

                }).finally(()=>{
                })
            }
            if(operationMobilization){
                const opMobilizationDS ={
                    ...operationMobilization,
                    dateSign:dateSign,
                    electronic:true,
                    confirm:true
                };
                setLoadingSave(true);
                addMobilization(opMobilizationDS).then((result)=>{

                    if(result.tokenOperation){
                        digitalSign(result.tokenOperation
                        , ()=>{
                                confirmOperation(result.tokenOperation, dateSign);
                                resolve();
                        }, ()=>{
                            setLoadingSave(false);
                            resolve();
                        });
                    }

                }).finally(()=>{
                })
            }

        });


    const confirmOperation = (tokenOperation:string|null=null, dateSign: number|null=null)=>{
        const operationsKey = getOperationKey();
        const tokenSign = getTokenSign();

        if(operationsKey || tokenSign){
            if(operationContribution){
                const opContribution ={
                    ...operationContribution,
                    confirm:true,
                    operationsKey:operationsKey ? operationsKey : undefined,
                    dateSign: dateSign ? dateSign : undefined,
                    tokenSign: tokenSign ? tokenSign : undefined,
                    tokenOperation: tokenOperation ? tokenOperation : undefined,
                    electronic: tokenOperation && tokenSign && dateSign ? true : false
                };
                setLoadingSave(true);
                addContribution(opContribution).then((result)=>{
                    if(result.result){
                        if(!opContribution.exists && !opContribution.periodicity){
                            addNumberCourseOperationsCache();
                        }
                        removeCurrentUserContract();
                        removeOperationContribution();
                        removeOperationKey();
                        removeTokenSign();
                        router.goBack();
                        trackStadistics('CONFIRMED_OPERATION', undefined, undefined, 'Planes de pensiones - Hacer aportación - Solicitud de aportación procesada con éxito.', 'OPERACION_CONFIRMADA' );
                        showToast({type:'ok', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_description'})});
                        return;
                    }
                    showToast({type:'ko', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title_ko'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_description_ko'})});
                    return;
                }).finally(()=>{
                    setLoadingSave(false);
                });
            }

            if(operationMobilization){

                const opMobilization ={
                    ...operationMobilization,
                    confirm:true,
                    operationsKey:operationsKey ? operationsKey : undefined,
                    dateSign: dateSign ? dateSign : undefined,
                    tokenSign: tokenSign ? tokenSign : undefined,
                    tokenOperation: tokenOperation ? tokenOperation : undefined,
                    electronic: tokenOperation && tokenSign && dateSign ? true : false
                };
                setLoadingSave(true);
                addMobilization(opMobilization).then((result)=>{
                    if(result.result){
                        addNumberCourseOperationsCache();
                        removeOperationMobilization();
                        removeOperationKey();
                        removeTokenSign();
                        router.goBack();
                        trackStadistics('CONFIRMED_OPERATION', undefined, undefined, 'Planes de pensiones - Movilizar - Solicitud de traslado procesada.', 'OPERACION_CONFIRMADA');
                        showToast({type:'ok', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_description'})});
                        return;
                    }
                    showToast({type:'ko', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_description'})});
                    return;
                }).finally(()=>{
                    setLoadingSave(false);
                });
            }
        }
    }

    return (
        <LayoutPrivateArea
            seo={{metaTitle: titleResponsive}}
            title={titleResponsive}
            parents={[{item:grandParentPage, title: grandParentTitle}, {item:parentPage, title: parentTitle}]}
            showBack={true}
            pageInfo={{option: process.env.REACT_APP_MENU_PLANES_PENSIONES, role:'client'}}>

            {loadingFirst ?
                <></>
            :
            <>
                <LoadingBar visible={loadingSave} />
                <style>{'.stepper--operations.stepper--fixed{background: '+skinVars.colors.backgroundAlternative+';}'}</style>
                <ResponsiveLayout className={'contribution--content '+ (screenSize.isDesktopOrBigger ? 'inner first' : 'fullscreen fixed padding--none')} backgroundColor={!screenSize.isDesktopOrBigger ? skinVars.colors.backgroundAlternative : 'transparent'}>
                    {screenSize.isDesktopOrBigger &&
                        <Box paddingBottom={32}>
                            <Text5>{title}</Text5>
                        </Box>
                    }

                    <Box className={'stepper--operations' + (!screenSize.isDesktopOrBigger ? ' stepper--fixed' : '')} paddingBottom={screenSize.isDesktopOrBigger ? 32 : 0}>
                        <div className='stepper'>
                            <Stepper
                                currentIndex={step}
                                steps={options}
                            />
                        </div>
                        <Divider />
                    </Box>
                </ResponsiveLayout>

                {options[step]===intl.formatMessage({id:'page_customer_pension_plans_operations_step_operative'}) &&
                    <ResponsiveLayout className={'inner' + (!screenSize.isDesktopOrBigger ? ' with-stepper--fixed' : '')}>
                        {(prop.operation===process.env.REACT_APP_MENU_PLANES_HACER_APORTACION && <ContributionPensionPlan loading={loading} contract={contract} onCancel={()=>{router.goBack()}} onConfirm={()=>{summaryOperation();}} />)}
                        {(prop.operation=== process.env.REACT_APP_MENU_PLANES_MOVILIZAR && <MobilizePensionPlan loading={loading} contract={contract} onCancel={()=>{router.goBack()}} onConfirm={()=>{summaryOperation()}} />)}
                    </ResponsiveLayout>
                }

                {options[step]===intl.formatMessage({id:'page_customer_pension_plans_operations_step_operations_key'}) &&
                        <ResponsiveLayout className={'inner' + (!screenSize.isDesktopOrBigger ? ' with-stepper--fixed min' : '')}>

                            <Box paddingBottom={screenSize.isDesktopOrBigger ? 56 : 16}>
                                <Stack space={40}>


                                    {operationContribution &&
                                        <SummaryOperate key={'data_contribution'}
                                            title={'Aportación'}
                                            values={operationContribution.summary}
                                        />
                                    }
                                    {operationMobilization &&
                                        <SummaryOperate key={'data_mobilization'}
                                            title={'Traslado'}
                                            values={operationMobilization.summary} />
                                    }


                                    <ConfirmOperation loading={loadingSave} contract={contract} onCancel={()=>{router.goBack()}} onConfirm={()=>{confirmOperation()}} onSign={()=>confirmOperationDigitalSign()}></ConfirmOperation>
                                </Stack>
                            </Box>

                        </ResponsiveLayout>
                }
            </>}
        </LayoutPrivateArea>
    );
};

export default OperationsPensionPlansCustomerPage;


export const ContributionsIndividualPlansCustomerPage: React.FC = () => {
    return (<OperationsPensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL ?? "I"} operation={process.env.REACT_APP_MENU_PLANES_HACER_APORTACION ?? '11'} />);
};

export const ContributionsSimplifiedEmploymentPlansCustomerPage: React.FC = () => {
    return (<OperationsPensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS ?? "S"} operation={process.env.REACT_APP_MENU_PLANES_HACER_APORTACION ?? '11'} />);
};

export const ContributionsEmploymentPlansCustomerPage: React.FC = () => {
    return (<OperationsPensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_EMPRESA ?? "E"} operation={process.env.REACT_APP_MENU_PLANES_HACER_APORTACION ?? '11'} />);
};


export const MobilizeIndividualPlansCustomerPage: React.FC = () => {
    return (<OperationsPensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL ?? "I"} operation={process.env.REACT_APP_MENU_PLANES_MOVILIZAR ?? '12'} />);
};

export const MobilizeSimplifiedEmploymentPlansCustomerPage: React.FC = () => {
    return (<OperationsPensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS ?? "S"} operation={process.env.REACT_APP_MENU_PLANES_MOVILIZAR ?? '12'} />);
};

export const MobilizeEmploymentPlansCustomerPage: React.FC = () => {
    return (<OperationsPensionPlansCustomerPage type={process.env.REACT_APP_PLAN_PENSIONES_EMPRESA ?? "E"} operation={process.env.REACT_APP_MENU_PLANES_MOVILIZAR ?? '12'} />);
};

