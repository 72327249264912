import { Box, Boxed, Grid, skinVars, Stack, Text2, Text3, Text4, useScreenSize, useWindowSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';

import { useEffect, useState } from 'react';
import './SummaryOperate.scss';

interface SummaryOperateProps {
    title: string,
    columnGrid?: number
    values?: Array<{title:string, value:string}>
}

const SummaryOperate: React.FC<SummaryOperateProps> = (prop) => {
    const screenSize = useScreenSize();
    const size = useWindowSize();
    const [columnGrid, setColumnGrid] = useState(3 as any);

    useEffect(()=>{
        if(size.width>768){
            if(prop.columnGrid){
                setColumnGrid(prop.columnGrid);
            }else{
                if(prop.values?.length===4){
                    setColumnGrid(4);
                }else if(prop.values?.length===2){
                    setColumnGrid(2);
                }else{
                    setColumnGrid(3);
                }
            }
        }else if(size.width>450){
            setColumnGrid(2);
        }else{
            setColumnGrid(1);
        }
    }, [size]);

    return (
        <>
            <style>{
                '.summaryOperate--content .boxResults {background: '+skinVars.colors.backgroundAlternative+';}'+
                '.summaryOperate--content .boxValue:before {background: '+skinVars.colors.borderHigh+';}'
            }</style>

            <Stack space={16}>
                <Text4 medium>{prop.title}</Text4>

                <Box className='summaryOperate--content'>
                    <Stack space={40}>
                        <Boxed className='boxResults'>
                            <Box paddingX={screenSize.isDesktopOrBigger ? 32 : 16} paddingY={screenSize.isDesktopOrBigger ? 24 : 16}>
                                <Stack space={16}>
                                    <Grid columns={columnGrid} gap={16} alignItems='center'>
                                        {prop.values?.map((item, index)=>(
                                            <Box key={'box-value-'+index} className={'boxValue '+((index%columnGrid===0) ? 'box--first' : '')}>
                                                <Stack space={4} >
                                                    <Text2 regular color={skinVars.colors.textSecondary}>{item.title}</Text2>
                                                    <Text3 medium truncate={2}>{item.value}</Text3>
                                                </Stack>
                                            </Box>
                                        ))}
                                    </Grid>
                                </Stack>
                            </Box>
                        </Boxed>
                    </Stack>
                </Box>
            </Stack>
        </>
    );
};

export default SummaryOperate;