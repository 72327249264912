

import { useIonRouter } from '@ionic/react';
import { Box, Boxed, ButtonPrimary, DecimalField, Divider, Form, IconInformationUserRegular, Inline, LoadingBar, RadioButton, RadioGroup, ResponsiveLayout, Select, Stack, Text2, Text3, Text5, TextField, Tooltip, skinVars, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import { useAlert } from '../../../../components/alerts/Alert';
import EmptyStateCustom from '../../../../components/empty-state/EmptyStateCustom';
import HeroCalculator from '../../../../components/hero/HeroCalculator';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import { ResultCalculateFinalCapitalTaxSavingsContribution } from '../../../../entities/contents/result-calculate-final-capital-tax-savings-contribution';
import { ResultCalculateFinalCapitalTaxSavingsTransfer } from '../../../../entities/contents/result-calculate-final-capital-tax-savings-transfer';
import { calculateFinalCapitalTaxSavingsContribution, calculateFinalCapitalTaxSavingsTransfer, trackStadistics } from '../../../../utils/apiclient';
import { getUrlNavigation } from '../../../../utils/navigation';
import { formatAmount, scrollToTop, trackEvent } from '../../../../utils/utils';
import ContactPage from '../../contact/ContactPage';
import ForInvestorPage from '../ForInvestorPage';
import './Calculators.scss';

const FinalCapitalCalculatorPage: React.FC = () => {
  const intl = useIntl()
  const { showAlert } = useAlert();
	const router = useIonRouter();
  const screenSize = useScreenSize();
  const [loading, setLoading] = useState(false);
  const [resultContribution, setResultContribution] = useState(null as ResultCalculateFinalCapitalTaxSavingsContribution|null);
  const [resultTransfer, setResultTransfer] = useState(null as ResultCalculateFinalCapitalTaxSavingsTransfer|null);

  //Form
  const initFormData = {
    'operationType': ('A'),
    contribution: '',
    periodicity_contribution: '',
    contribution_analyzed: '',
    nettable_basis: '',
    age: '',
    retirement_age: '',
    expected_annual_revaluation: '',
    amount: '',
    age_transfer: '',
    retirement_age_transfer: '',
    expected_annual_revaluation_transfer: ''
  }

  const [formData, setFormData] = useState(initFormData);
  const [formErrors, setFormErrors] = useState([] as any);

  const handleError=(name:string) =>{
    if(formErrors && formErrors[name]){
        const error = formErrors[name];
        if(error){
            return intl.formatMessage({id: 'page_final_capital_calculator_'+error.toLowerCase().replaceAll(' ', '_')});
        }
    }
    return null;
  }

  const handleChangeAnyField= (name:string, value:any) => {
    const newValues = {
        ...formData,
        [name]: value,
    };

    if(newValues.contribution !== '' && newValues.periodicity_contribution !== ''){
      const contribution = parseFloat(newValues.contribution.replace(',', '.'));
      switch(newValues.periodicity_contribution){
        case '1':
          newValues.contribution_analyzed = formatAmount(String(contribution * 1)) + '€';
          break;
        case '2':
          newValues.contribution_analyzed = formatAmount(String(contribution * 12)) + '€';
          break;
        case '3':
          newValues.contribution_analyzed = formatAmount(String(contribution * 4)) + '€';
          break;
        case '4':
          newValues.contribution_analyzed = formatAmount(String(contribution * 2)) + '€';
          break;
        case '5':
          break;
      }
    }
    setFormData(newValues);

    if(handleError(name)){
        delete formErrors[name];
    }

    validateField(name, value);
  };

  const validateField = (name:string, value:any)=>{
    switch(name){
      case 'nettable_basis':
        if(value && value.length>0){
          if(formData.periodicity_contribution == '5' && formData.contribution !== ''){
            if(value < formData.contribution){
              formErrors[name] = 'nettable basis error';
              return false;
            }
          }else{
            let contribution_analyzed = 0;
            if(formData.contribution !== '' && formData.periodicity_contribution !== '')
            switch(formData.periodicity_contribution){
              case '1':
                contribution_analyzed = parseFloat(formData.contribution.replace(',', '.')) * 1;
                break;
              case '2':
                contribution_analyzed = parseFloat(formData.contribution.replace(',', '.')) * 12;
                break;
              case '3':
                contribution_analyzed = parseFloat(formData.contribution.replace(',', '.')) * 4;
                break;
              case '4':
                contribution_analyzed = parseFloat(formData.contribution.replace(',', '.')) * 2;
                break;
            }
            if(value < contribution_analyzed){
              formErrors[name] = 'nettable basis error';
              return false;
            }
          }
        }
        break;
      }
      return true;
  }

  const isSubmitDisabled = () => {
    if(formData.operationType==='A'){
      return (formData.operationType==='A' &&
           (formData.nettable_basis.length===0 || handleError('nettable_basis')!==null) ||
           (formData.contribution.length===0 || handleError('contribution')!==null) ||
           (formData.periodicity_contribution.length===0 || handleError('periodicity_contribution')!==null) ||
           (formData.age.length===0 || handleError('age')!==null) ||
           (formData.retirement_age.length===0 || handleError('retirement_age')!==null) ||
           (formData.expected_annual_revaluation.length===0 || handleError('expected_annual_revaluation')!==null));
    }else{
      return (formData.operationType==='T' &&
          (formData.amount.length===0 || handleError('amount')!==null) ||
          (formData.age_transfer.length===0 || handleError('age_transfer')!==null) ||
          (formData.retirement_age_transfer.length===0 || handleError('retirement_age_transfer')!==null) ||
          (formData.expected_annual_revaluation_transfer.length===0 || handleError('expected_annual_revaluation_transfer')!==null));
    }
  };

const handleCalculate = (data: any): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('para_inversor-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_final_capital_calculator_title'})).replaceAll('-', '_')+'-calcular');
      setLoading(true);
      switch(formData.operationType){
        case 'A':
          const paramsContribution: any = {
            contribution:  parseFloat(formData.contribution.replace(',', '.')),
            periodicityContribution: parseFloat(formData.periodicity_contribution),
            liquidableBasis: parseFloat(formData.nettable_basis.replace(',', '.')),
            age: parseInt(formData.age),
            retirementAge: parseInt(formData.retirement_age),
            expectedAnnualRevaluation: formData.expected_annual_revaluation
          };
          setResultContribution(null);
          calculateFinalCapitalTaxSavingsContribution(paramsContribution).then((result)=>{
            if(result){
              trackStadistics('CALCULATOR', undefined, undefined, 'Calculadora capital final.' );
              setResultContribution(result);
              scrollToTop('result-contribution');
            }
          }).finally(()=>{
            showAlert('_final_capital_calculator_form_', 'Calculadora de capital final A');
            setLoading(false);
            resolve();
          });
          break;
        case 'T':
          const paramsTransfer: any = {
            amount: parseFloat(formData.amount.replace(',', '.')),
            age: parseInt(formData.age_transfer),
            retirementAge: parseInt(formData.retirement_age_transfer),
            expectedAnnualRevaluation: formData.expected_annual_revaluation_transfer
          }
          setResultTransfer(null);
          calculateFinalCapitalTaxSavingsTransfer(paramsTransfer).then((result)=>{
            if(result){
              setResultTransfer(result);
              scrollToTop('result-transfer');
            }
          }).finally(()=>{
            showAlert('_final_capital_calculator_form_', 'Calculadora de capital final T');
            setLoading(false);
            resolve();
          });
          break;
      }
  });

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_final_capital_calculator_seo_title'}), metaDescription:intl.formatMessage({id:'page_final_capital_calculator_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_final_capital_calculator_title'}), parents:[{title:intl.formatMessage({id:'page_for_investor_page_title'}), item:ForInvestorPage}]}}
    >
      <LoadingBar visible={loading} />
      <HeroCalculator
        title={intl.formatMessage({id:'page_final_capital_calculator_title'})}
        description={intl.formatMessage({id:'page_final_capital_calculator_description'})}
        children={
          <Boxed className='calculatorBox'>
            <ResponsiveLayout backgroundColor={skinVars.colors.background}>
              <Box paddingX={24} paddingY={32}>
                <Form initialValues={formData} onSubmit={handleCalculate}>
                  <Stack space={24}>
                    <RadioGroup
                      name="operationType"
                      onChange={(val=>{handleChangeAnyField('operationType', val)})}
                      aria-labelledby="label"
                    >
                      <Inline space={16}>
                        <RadioButton value="A">{intl.formatMessage({id:'page_calculator_label_contribution'})}</RadioButton>
                        <RadioButton value="T">{intl.formatMessage({id:'page_calculator_label_transfer'})}</RadioButton>
                      </Inline>
                    </RadioGroup>

                    <Stack space={16}>
                      {formData.operationType==='A' &&
                        <Stack space={16}>
                          <DecimalField
                            fullWidth
                            name="contribution"
                            onChangeValue={(val=>{handleChangeAnyField('contribution', val)})}
                            label={intl.formatMessage({id:'page_calculator_label_contribution'})}
                          />

                          <Select
                            fullWidth
                            name="periodicity_contribution"
                            onChangeValue={(val=>{handleChangeAnyField('periodicity_contribution', val)})}
                            label={intl.formatMessage({id:'page_calculator_label_periodicity_contribution'})}
                            options={[
                              { value: "1", text: intl.formatMessage({id:'page_calculator_option_annual'}) },
                              { value: "2", text: intl.formatMessage({id:'page_calculator_option_monthly'}) },
                              { value: "3", text: intl.formatMessage({id:'page_calculator_option_quarterly'}) },
                              { value: "4", text: intl.formatMessage({id:'page_calculator_option_biannual'}) },
                              { value: "5", text: intl.formatMessage({id:'page_calculator_option_only'}) }
                            ]}
                          />

                          {formData.periodicity_contribution!=='5' &&
                            <TextField
                              fullWidth
                              disabled
                              name="contribution_analyzed"
                              value={formData.contribution_analyzed}
                              label={intl.formatMessage({id:'page_calculator_label_contribution_analyzed'})}
                            />
                          }

                          <Inline space={8} fullWidth alignItems='center' className='inline--inputInfo'>
                            <DecimalField
                              fullWidth
                              name="nettable_basis"
                              error={handleError('nettable_basis')!==null} helperText={handleError('nettable_basis')??undefined}
                              onChangeValue={(val=>{handleChangeAnyField('nettable_basis', val)})}
                              label={intl.formatMessage({id:'page_calculator_label_nettable_basis'})}
                            />

                            <Tooltip
                              targetLabel=''
                              target={<IconInformationUserRegular size={24} color={skinVars.colors.brand}></IconInformationUserRegular>}
                              description=""
                              position="left"
                              extra={
                                  <Inline space={'evenly'}>
                                      <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_final_capital_calculator_information_nettable_basis'})}</Text2>
                                  </Inline>
                              }
                            />
                          </Inline>

                          <Inline space={16}>
                            <TextField
                              fullWidth
                              name="age"
                              onChangeValue={(val=>{handleChangeAnyField('age', val)})}
                              label={intl.formatMessage({id:'page_calculator_label_age'})}
                            />

                            <TextField
                              fullWidth
                              name="retirement_age"
                              onChangeValue={(val=>{handleChangeAnyField('retirement_age', val)})}
                              label={intl.formatMessage({id:'page_calculator_label_retirement_age'})}
                            />
                          </Inline>

                          <Inline space={8} fullWidth alignItems='center' className='inline--inputInfo'>
                            <Select
                              fullWidth
                              name="expected_annual_revaluation"
                              onChangeValue={(val=>{handleChangeAnyField('expected_annual_revaluation', val)})}
                              label={intl.formatMessage({id:'page_calculator_label_expected_annual_revaluation'})}
                              options={[
                                { value: "2", text: intl.formatMessage({id:'page_calculator_2%'}) },
                                { value: "3", text: intl.formatMessage({id:'page_calculator_3%'}) },
                                { value: "4", text: intl.formatMessage({id:'page_calculator_4%'}) },
                                { value: "5", text: intl.formatMessage({id:'page_calculator_5%'}) },
                                { value: "6", text: intl.formatMessage({id:'page_calculator_6%'}) },
                                { value: "7", text: intl.formatMessage({id:'page_calculator_7%'}) },
                                { value: "8", text: intl.formatMessage({id:'page_calculator_8%'}) },
                                { value: "9", text: intl.formatMessage({id:'page_calculator_9%'}) },
                                { value: "10", text: intl.formatMessage({id:'page_calculator_10%'}) }
                              ]}
                            />

                            <Tooltip
                              targetLabel=''
                              target={<IconInformationUserRegular size={24} color={skinVars.colors.brand}></IconInformationUserRegular>}
                              description=""
                              position="left"
                              extra={
                                <Inline space={'evenly'}>
                                  <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_final_capital_calculator_information_expected_annual_revaluation'})}</Text2>
                                </Inline>
                              }
                            />
                          </Inline>
                        </Stack>
                      }

                      {formData.operationType==='T' &&
                        <Stack space={16}>
                          <DecimalField
                            fullWidth
                            name="amount"
                            onChangeValue={(val=>{handleChangeAnyField('amount', val)})}
                            label={intl.formatMessage({id:'page_calculator_label_amount'})}
                          />

                          <Inline space={16}>
                            <TextField
                              fullWidth
                              name="age_transfer"
                              onChangeValue={(val=>{handleChangeAnyField('age_transfer', val)})}
                              label={intl.formatMessage({id:'page_calculator_label_age'})}
                            />

                            <TextField
                              fullWidth
                              name="retirement_age_transfer"
                              onChangeValue={(val=>{handleChangeAnyField('retirement_age_transfer', val)})}
                              label={intl.formatMessage({id:'page_calculator_label_retirement_age'})}
                            />
                          </Inline>

                          <Select
                            fullWidth
                            name="expected_annual_revaluation_transfer"
                            onChangeValue={(val=>{handleChangeAnyField('expected_annual_revaluation_transfer', val)})}
                            label={intl.formatMessage({id:'page_calculator_label_expected_annual_revaluation'})}
                            options={[
                              { value: "2", text: intl.formatMessage({id:'page_calculator_2%'}) },
                                { value: "3", text: intl.formatMessage({id:'page_calculator_3%'}) },
                                { value: "4", text: intl.formatMessage({id:'page_calculator_4%'}) },
                                { value: "5", text: intl.formatMessage({id:'page_calculator_5%'}) },
                                { value: "6", text: intl.formatMessage({id:'page_calculator_6%'}) },
                                { value: "7", text: intl.formatMessage({id:'page_calculator_7%'}) },
                                { value: "8", text: intl.formatMessage({id:'page_calculator_8%'}) },
                                { value: "9", text: intl.formatMessage({id:'page_calculator_9%'}) },
                                { value: "10", text: intl.formatMessage({id:'page_calculator_10%'}) }
                            ]}
                          />
                        </Stack>
                      }

                      <ButtonPrimary disabled={isSubmitDisabled()} submit>
                        {intl.formatMessage({id:'page_calculator_button_calculate'})}
                      </ButtonPrimary>
                    </Stack>
                  </Stack>
                </Form>
              </Box>
            </ResponsiveLayout>
          </Boxed>
        }
      ></HeroCalculator>

      {/* calculation results */}
      <style>{
          '.calculatorPage--content .boxWidth--color{background-color:'+skinVars.colors.backgroundAlternative+';}'
      }</style>
      <ResponsiveLayout className='content' fullWidth={true}>
        <ResponsiveLayout className='calculatorPage--content'>

          <div id="result-contribution">
          {resultContribution && formData.operationType==='A' &&
            <>
              <Box paddingY={screenSize.isTabletOrBigger ? 64 : 32}>
                <Stack space={16}>
                  <Text5>{intl.formatMessage({id:'page_calculator_result_title'})}</Text5>
                </Stack>
              </Box>
              {/* ** RESULT:: CONTRIBUTION ** */}
              <Box paddingX={screenSize.isDesktopOrBigger ? 80 : (screenSize.isTabletOrBigger ? 40 : 0)} paddingY={screenSize.isTabletOrBigger ? 4 : 16} className='result--box'>
                <Box paddingTop={24}>
                  <Stack space={screenSize.isTabletOrBigger ? 56 : 32}>
                    <Stack space={screenSize.isTabletOrBigger ? 12 : 16}>
                      <Stack space={8}>
                        {!screenSize.isTabletOrBigger &&
                          <Box className='boxAlignCenter'>
                            <Inline space={8} alignItems='center'>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_annual_tax_savings'})}</Text3>

                              <Tooltip
                                targetLabel=''
                                target={<IconInformationUserRegular size={24} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                description=""
                                position="left"
                                extra={
                                  <Inline space={'evenly'}>
                                    <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_final_capital_calculator_information_annual_tax_savings'})}</Text2>
                                  </Inline>
                                }
                              />
                            </Inline>
                          </Box>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                          {screenSize.isTabletOrBigger &&
                            <Inline space={8} alignItems='center'>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_annual_tax_savings'})}</Text3>

                              <Tooltip
                                targetLabel=''
                                target={<IconInformationUserRegular size={24} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                description=""
                                position="left"
                                extra={
                                  <Inline space={'evenly'}>
                                    <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_final_capital_calculator_information_annual_tax_savings'})}</Text2>
                                  </Inline>
                                }
                              />
                            </Inline>
                          }

                          <Box className='boxWidth boxWidth--color boxWidth--xl' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                            <Text3 regular>{formatAmount(resultContribution.AhorroFiscalAnual) + '€'}</Text3>
                          </Box>
                        </Inline>
                      </Stack>

                      {!screenSize.isTabletOrBigger &&
                        <Divider />
                      }

                      <Stack space={8}>
                        {!screenSize.isTabletOrBigger &&
                          <Box className='boxAlignCenter'>
                            <Inline space={8} alignItems='center'>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_capital_aported'})}</Text3>

                              <Tooltip
                                targetLabel=''
                                target={<IconInformationUserRegular size={24} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                description=""
                                position="left"
                                extra={
                                  <Inline space={'evenly'}>
                                    <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_final_capital_calculator_information_contributed_capital'})}</Text2>
                                  </Inline>
                                }
                              />
                            </Inline>
                          </Box>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                          {screenSize.isTabletOrBigger &&
                            <Inline space={8} alignItems='center'>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_capital_aported'})}</Text3>

                              <Tooltip
                                targetLabel=''
                                target={<IconInformationUserRegular size={24} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                description=""
                                position="left"
                                extra={
                                  <Inline space={'evenly'}>
                                    <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_final_capital_calculator_information_contributed_capital'})}</Text2>
                                  </Inline>
                                }
                              />
                            </Inline>
                          }

                          <Box className='boxWidth boxWidth--color boxWidth--xl' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                            <Text3 regular>{formatAmount(resultContribution.CapitalAportado) + '€'}</Text3>
                          </Box>
                        </Inline>
                      </Stack>

                      {!screenSize.isTabletOrBigger &&
                        <Divider />
                      }

                      <Stack space={8}>
                        {!screenSize.isTabletOrBigger &&
                          <Box className='boxAlignCenter'>
                            <Inline space={8} alignItems='center'>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_accumulated_capital'})}</Text3>

                              <Tooltip
                                targetLabel=''
                                target={<IconInformationUserRegular size={24} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                description=""
                                position="left"
                                extra={
                                  <Inline space={'evenly'}>
                                    <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_final_capital_calculator_information_accumulated_capital'})}</Text2>
                                  </Inline>
                                }
                              />
                            </Inline>
                          </Box>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                          {screenSize.isTabletOrBigger &&
                            <Inline space={8} alignItems='center'>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_accumulated_capital'})}</Text3>

                              <Tooltip
                                targetLabel=''
                                target={<IconInformationUserRegular size={24} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                description=""
                                position="left"
                                extra={
                                  <Inline space={'evenly'}>
                                    <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_final_capital_calculator_information_accumulated_capital'})}</Text2>
                                  </Inline>
                                }
                              />
                            </Inline>
                          }

                          <Box className='boxWidth boxWidth--color boxWidth--xl' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                            <Text3 regular>{formatAmount(resultContribution.CapitalAcumuladoEdadJubilación) + '€'}</Text3>
                          </Box>
                        </Inline>
                      </Stack>
                    </Stack>

                    <Stack space={screenSize.isTabletOrBigger ? 12 : 16}>
                      <Box>
                        <Inline space={8} alignItems='center' className='flexAlignCenter'>
                          <Text5>{intl.formatMessage({id:'page_calculator_data_condition'})}</Text5>

                          <Tooltip
                            targetLabel=''
                            target={<IconInformationUserRegular size={24} color={skinVars.colors.brand}></IconInformationUserRegular>}
                            description=""
                            position="left"
                            extra={
                              <Inline space={'evenly'}>
                                <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_final_capital_calculator_information_condition_increase'})}</Text2>
                              </Inline>
                            }
                          />
                        </Inline>
                        {screenSize.isTabletOrBigger &&
                          <Box paddingTop={24} paddingBottom={16}>
                            <Divider />
                          </Box>
                        }
                      </Box>

                      <Stack space={8}>
                        {!screenSize.isTabletOrBigger &&
                          <Box className='boxAlignCenter'>
                            <Inline space={8}>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_capital_aported'})}</Text3>
                            </Inline>
                          </Box>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                          {screenSize.isTabletOrBigger &&
                            <Inline space={8}>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_capital_aported'})}</Text3>
                            </Inline>
                          }

                          <Box className='boxWidth boxWidth--color boxWidth--xl' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                            <Text3 regular>{formatAmount(resultContribution.CapitalAportado1) + '€'}</Text3>
                          </Box>
                        </Inline>
                      </Stack>

                      {!screenSize.isTabletOrBigger &&
                        <Divider />
                      }

                      <Stack space={8}>
                        {!screenSize.isTabletOrBigger &&
                          <Box className='boxAlignCenter'>
                            <Inline space={8}>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_accumulated_capital'})}</Text3>
                            </Inline>
                          </Box>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                          {screenSize.isTabletOrBigger &&
                            <Inline space={8}>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_accumulated_capital'})}</Text3>
                            </Inline>
                          }

                          <Box className='boxWidth boxWidth--color boxWidth--xl' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                            <Text3 regular>{formatAmount(resultContribution.CapitalAcumuladoEdadJubilación1) + '€'}</Text3>
                          </Box>
                        </Inline>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </>
            }
          </div>

          <div id="result-transfer">
          {resultTransfer && formData.operationType==='T' &&
            <>
              <Box paddingY={screenSize.isTabletOrBigger ? 64 : 32}>
                <Stack space={16}>
                  <Text5>{intl.formatMessage({id:'page_calculator_result_title'})}</Text5>
                </Stack>
              </Box>
              {/* ** RESULT:: TRANSFER ** */}
              <Box paddingX={screenSize.isDesktopOrBigger ? 80 : (screenSize.isTabletOrBigger ? 40 : 0)} paddingY={screenSize.isTabletOrBigger ? 56 : 16} className='result--box'>
                <Box paddingTop={24}>
                  <Stack space={screenSize.isTabletOrBigger ? 56 : 32}>
                    <Stack space={screenSize.isTabletOrBigger ? 12 : 16}>
                      <Stack space={8}>
                        {!screenSize.isTabletOrBigger &&
                          <Box className='boxAlignCenter'>
                            <Inline space={8} alignItems='center'>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_accumulated_capital'})}</Text3>

                              <Tooltip
                                targetLabel=''
                                target={<IconInformationUserRegular size={24} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                description=""
                                position="left"
                                extra={
                                  <Inline space={'evenly'}>
                                    <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_final_capital_calculator_information_accumulated_capital'})}</Text2>
                                  </Inline>
                                }
                              />
                            </Inline>
                          </Box>
                        }

                        <Inline space={screenSize.isTabletOrBigger ? 'between' : 'around'} alignItems='center'>
                          {screenSize.isTabletOrBigger &&
                            <Inline space={8} alignItems='center'>
                              <Text3 light>{intl.formatMessage({id:'page_calculator_data_accumulated_capital'})}</Text3>

                              <Tooltip
                                targetLabel=''
                                target={<IconInformationUserRegular size={24} color={skinVars.colors.brand}></IconInformationUserRegular>}
                                description=""
                                position="left"
                                extra={
                                  <Inline space={'evenly'}>
                                    <Text2 regular textAlign={'center'}>{intl.formatMessage({id:'page_final_capital_calculator_information_accumulated_capital'})}</Text2>
                                  </Inline>
                                }
                              />
                            </Inline>
                          }

                          <Box className='boxWidth boxWidth--color boxWidth--xl' paddingX={screenSize.isTabletOrBigger ? 16 : 4} paddingY={screenSize.isTabletOrBigger ? 12 : 8}>
                            <Text3 regular>{formatAmount(resultTransfer.result) + '€'}</Text3>
                          </Box>
                        </Inline>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </>
          }
          </div>

        </ResponsiveLayout>
      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth={true}>
          <ResponsiveLayout>

            <EmptyStateCustom
              largeImageUrl="../assets/img/necesitas-ayuda.jpg"
              title={intl.formatMessage({id:'page_for_investor_empty_state_title'})}
              description={intl.formatMessage({id:'page_for_investor_empty_state_description'})}
              button={
                <ButtonGroup
                    primaryButton={
                      <ButtonPrimary onPress={() => {router.push(getUrlNavigation(ContactPage)); trackEvent('para_inversor-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_final_capital_calculator_title'})).replaceAll('-', '_')+'-contacto');}}> <img src={'../assets/img/contactar.svg'}/>
                        {intl.formatMessage({id:'page_for_investor_empty_state_action_primary'})}
                      </ButtonPrimary>
                    }
                ></ButtonGroup>
              }
            />
          </ResponsiveLayout>
      </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default FinalCapitalCalculatorPage;