import { Box, Inline, ResponsiveLayout, Text5, useScreenSize } from '@telefonica/mistica';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import Documentation from '../../../../../components/product-data/Documentation';
import { Product } from '../../../../../entities/products/product';
import { getDocumentsPromoter } from '../../../../../utils/apiclient';
import { getSelectedPlanPromotor, getUser } from '../../../../../utils/storage';

const MonthlyReportPromoterPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null as null|Product);
  let promotorSelected = '';
  let promotorPlanSelected = '';

  useEffect(()=>{
    getDocuments();
  }, []);

  const getDocuments = ()=>{
    const planPromotor = getSelectedPlanPromotor();

    if(planPromotor){
      const newPromotorSelected = getUser()?.selectedPromotor??'';
      const newPlanPromotorSelected = getUser()?.selectedPlanPromotor??'';

      if(newPromotorSelected && newPromotorSelected.length>0 && newPromotorSelected!=promotorSelected && newPlanPromotorSelected && newPlanPromotorSelected.length>0 && newPlanPromotorSelected!=promotorPlanSelected){
        promotorSelected = newPromotorSelected;
        promotorPlanSelected = newPlanPromotorSelected;
        setLoading(true);
        setProduct(null);
        getDocumentsPromoter('im').then(documents=>{
          if(documents){
            setProduct({ cdCompania: planPromotor?.compania, cdFondo: planPromotor?.codigo_fondo, cdPlan: planPromotor?.codigo_plan, cdSubplan: planPromotor?.codigo_subplan,  documentacion: documents});
          }
        }).finally(()=>{
          setLoading(false);
        });
      }
    }
  };

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_promoter_monthly_report_title'})}}
      pageInfo={{option: process.env.REACT_APP_AREA_PROMOTORES_INFORME_MENSUAL, role:'promoter'}}
      title={intl.formatMessage({id:'page_promoter_monthly_report_title'})}
      onChangeUser={()=>{getDocuments()}}
      showMenu>
      <ResponsiveLayout className='inner first'>
        {screenSize.isDesktopOrBigger &&
          <Box paddingBottom={32}>
            <Inline space={'between'} alignItems='center'>
              <Text5>{intl.formatMessage({id:'page_promoter_monthly_report_title'})}</Text5>
            </Inline>
          </Box>
        }
        <Documentation prefixSlug='promotor-informes-descarga_' loading={loading} product={product}  />
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default MonthlyReportPromoterPage;
