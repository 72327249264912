import { useIonRouter } from '@ionic/react';
import { Box, LoadingBar, ResponsiveLayout, SkeletonRectangle, skinVars, Tabs, Text5, useScreenSize, useWindowSize } from '@telefonica/mistica';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import AnnualEvolutionProfitability from '../../../../../components/product-data/AnnualEvolutionProfitability';
import Documentation from '../../../../../components/product-data/Documentation';
import GeneralInformation from '../../../../../components/product-data/GeneralInformation';
import HistoricalProfitability from '../../../../../components/product-data/HistoricalProfitability';
import NetAssetValueEvolution from '../../../../../components/product-data/NetAssetValueEvolution';
import PortfolioComposition from '../../../../../components/product-data/PortfolioComposition';
import ProfitabilityValues from '../../../../../components/product-data/ProfitabilityValues';
import Risk from '../../../../../components/product-data/Risk';
import { Product } from '../../../../../entities/products/product';
import { getInfoProduct, trackStadistics } from '../../../../../utils/apiclient';
import { getUrlNavigation } from '../../../../../utils/navigation';
import { getCurrentProduct } from '../../../../../utils/storage';
import InvestmentFundsCustomerPage from './InvestmentFundsCustomerPage';

const InfoInvestmentFundsCustomerPage: React.FC = () => {
  const intl = useIntl();
  const router = useIonRouter();
  const [loading, setLoading] = useState(true);
  const screenSize = useScreenSize();
  const size = useWindowSize();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [product, setProduct] = useState(null as null|Product);
  const currentProduct = getCurrentProduct();
  const [options, setOptions] = useState([] as string[]);

  useEffect(()=>{
    if(!router.canGoBack() || !currentProduct){
        router.push(getUrlNavigation(InvestmentFundsCustomerPage));
        return;
    }
    getInfoProduct({cdCompany:currentProduct?.cdCompania ?? -1, cdPlan: currentProduct?.cdPlan ?? '', cdFund: currentProduct?.cdFondo?? '', cdSubplan: currentProduct?.cdSubplan?? ''}).then((result)=>{
      if(result){
        let opts = [ intl.formatMessage({id:'page_investment_funds_customer_list_option_1'}),  intl.formatMessage({id:'page_investment_funds_customer_list_option_2'}),  intl.formatMessage({id:'page_investment_funds_customer_list_option_3'})];
        if(result.documentacion && result.documentacion.filter(item=>(item.esSO??false)).length>0){
          opts.push(intl.formatMessage({id:'page_investment_funds_customer_list_option_4'}))
        }
        setOptions(opts);
        setProduct(result);
      }
    }).finally(()=>{
        setLoading(false);
    });
}, []);

useEffect(()=>{
  switch (selectedIndex){
    case 1:
      trackStadistics('PAGE', '_RENTABILIDADES' );
      break;
    case 2:
      trackStadistics('PAGE', '_DOCUMENTACION' );
      break;
    default:
      trackStadistics('PAGE', '_DATOS_GENERALES' );
      break;
  }
}, [selectedIndex]);

  return (
    <LayoutPrivateArea
      seo={{metaTitle: currentProduct?.descripcion}}
      title={currentProduct?.descripcion}
      parents={[{item:InvestmentFundsCustomerPage, title: intl.formatMessage({id:'page_investment_funds_customer_metatitle'})}]}
      pageInfo={{option: process.env.REACT_APP_MENU_FONDOS_INVERSION, role:'client'}}
      showBack>
      <LoadingBar visible={loading}></LoadingBar>
      <ResponsiveLayout className='inner first'>
          <Box paddingBottom={screenSize.isDesktopOrBigger ? 32 : 0}>
              {screenSize.isDesktopOrBigger &&
                <Text5>{currentProduct?.descripcion}</Text5>
              }
          </Box>
      </ResponsiveLayout>
      <ResponsiveLayout className={!screenSize.isDesktopOrBigger ? 'fullScreen tabs--fixed' : 'inner'} backgroundColor={!screenSize.isDesktopOrBigger ? skinVars.colors.backgroundAlternative : 'transparent'}>
        <Box paddingTop={screenSize.isDesktopOrBigger ? 16 : 0}>
        {loading ?
            <SkeletonRectangle height={56} />
          :
            <Tabs
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
              tabs={options.map((text) => ({
                text,
            }))}/>
          }
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner '+(selectedIndex===0 ? 'd-block' : 'd-none')+(!screenSize.isDesktopOrBigger ? ' with-tabs--fixed min' : '')}>

        <ProfitabilityValues loading={loading} product={product} />

        <Risk loading={loading} product={product} />

        <PortfolioComposition loading={loading}  product={product} />

        <GeneralInformation loading={loading} product={product} />

      </ResponsiveLayout>

      <ResponsiveLayout className={'inner first '+(selectedIndex===1 ? 'd-block' : 'd-none')}>

        <HistoricalProfitability loading={loading}  product={product} />

        <AnnualEvolutionProfitability loading={loading}  product={product} />

        <NetAssetValueEvolution loading={loading}  product={product} />
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner first '+(selectedIndex===2 ? 'd-block' : 'd-none')}>
        <Documentation prefixSlug={'perfil-fondo-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_documentacion-'} loading={loading}  product={product} sustainability={false} />
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner first '+(selectedIndex===3 ? 'd-block' : 'd-none')}>
        <Documentation prefixSlug={'perfil-fondo-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_sostenibilidad-'} loading={loading}  product={product} sustainability={true} />
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default InfoInvestmentFundsCustomerPage;
