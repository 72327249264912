import { useIonRouter } from '@ionic/react';
import React, { useLayoutEffect, useState } from 'react';

import { Box, Boxed, ButtonLayout, ButtonPrimary, dialog, Form, GridLayout, IconInformationUserLight, Image, Inline, LoadingBar, PasswordField, ResponsiveLayout, Row, SkeletonRectangle, skinVars, Stack, Tabs, Text4, useScreenSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import InitRegisterCard from '../../../components/login/InitRegisterCard';
import LoginCard from '../../../components/login/LoginCard';
import { getDataRecoveryPassword, setDataOperationsKeyRecovery, setDataRecoveryPassword } from '../../../utils/apiclient';
import { getUrlNavigation } from '../../../utils/navigation';
import { isBot, isNativeApp } from '../../../utils/utils';
import './LoginPage.scss';

const LoginPage: React.FC = () => {

const intl = useIntl();
const router = useIonRouter();

const size = useScreenSize();
const [loading, setLoading] = useState(true);
const [loadingForm, setLoadingForm] = useState(false);

const [isRecoveryPasswordForm, setIsRecoveryPasswordForm] = useState(false);
const [formDataRecoveryPassword, setFormDataRecoveryPassword] = useState({
  activate_id : '',
  identifier: '',
  password:'',
  repeatPassword:''
});
const [formErrors, setFormErrors] = useState([] as any);

const handleSubmitRecoveryPassword = (): Promise<void> =>
      new Promise((resolve) => {
          setLoadingForm(true);
          setDataRecoveryPassword(formDataRecoveryPassword).then((response)=>{
            setTimeout(function(){
              if(response.errors){
                setFormErrors(response.errors);
              }else if(response.result==='ok'){
                setIsRecoveryPasswordForm(false);
                dialog({
                  icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                  title: intl.formatMessage({id:'page_login_recover_password'}),
                  message: intl.formatMessage({id:'page_login_recovery_password_process_completed'}),
                  onAccept: ()=>{router.push(getUrlNavigation(LoginPage))},
                  acceptText: intl.formatMessage({id:'page_login_recover_password_accept_text'})});              }
            }, 500);
          }).finally(()=>{
            setLoadingForm(false);
            resolve();
          });
    });



  //Handle fields
  const handleError=(name:string) =>{
    if(formErrors && formErrors[name]){
      const error = formErrors[name];
      if(error){
        return intl.formatMessage({id: 'page_register_'+error.toLowerCase().replaceAll(' ', '_')});
      }
    }
    return null;
  }

  const validateField = (name:string, value:any)=>{
    switch(name){
      case 'password':
          if(formDataRecoveryPassword.repeatPassword.length>0 && formDataRecoveryPassword.repeatPassword!=value){
            formErrors['repeatPassword'] = 'password not match';
            return false;
          }
          break;
      case 'repeatPassword':
          if(formDataRecoveryPassword.password.length>0 && formDataRecoveryPassword.password!=value){
            formErrors[name] = 'password not match';
            return false;
          }
          break;
    }
    return true;
  }

  const handleChangeField= (evt:any) => {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
      ...formDataRecoveryPassword,
      [name]: value,
    };
    setFormDataRecoveryPassword(newValues);
    if(handleError(name)){
      delete formErrors[name];
    }
    validateField(name, value);
};

const isSubmitFormRecoveryPasswordDisabled = () => {
  return formDataRecoveryPassword.password.length===0 ||
         !validateField('password', formDataRecoveryPassword.password) ||
         handleError('password')!==null ||
         formDataRecoveryPassword.repeatPassword.length === 0 ||
         !validateField('repeatPassword', formDataRecoveryPassword.repeatPassword) ||
         handleError('repeatPassword')!==null;
}

useLayoutEffect(()=>{
  if(!isBot){
    if(router.routeInfo.search && router.routeInfo.search.indexOf('?id=')!=-1){
      const activate_id = router.routeInfo.search.replace('?id=', '');
      if(activate_id.trim().length>0){
        getDataRecoveryPassword({activate_id:activate_id.trim()}).then((result)=>{
          let redirectLogin = true;
          let title=intl.formatMessage({id:'page_login_recovery_password_process_not_exists'});
          let message = '';
          if(result){
            switch(result.result){
              case 'ok':
                const newValues = {
                  ...formDataRecoveryPassword,
                  ['activate_id']: activate_id.trim(),
                  ['identifier']: result.identifier,
                };
                setFormDataRecoveryPassword(newValues);
                setIsRecoveryPasswordForm(true);
                return;
            }
          }
          dialog({
            icon: <IconInformationUserLight color={skinVars.colors.brand} />,
            title: title,
            message: message,
            onAccept: redirectLogin ? ()=>{router.push(getUrlNavigation(LoginPage))} : undefined,
            acceptText: intl.formatMessage({id:'page_register_continue'})});

        }).finally(()=>{
          setLoading(false);
        })
      }else{
        setLoading(false);
      }
    }else if(router.routeInfo.search && router.routeInfo.search.indexOf('?idco=')!=-1){
      const activate_id = router.routeInfo.search.replace('?idco=', '');
      if(activate_id.trim().length>0){
        setDataOperationsKeyRecovery({activate_id:activate_id.trim()}).then((result)=>{
          let redirectLogin = true;
          let title=intl.formatMessage({id:'page_login_recovery_password_process_not_exists'});
          let message = '';
          if(result){
            switch(result.result){
              case 'ok':
                dialog({
                  icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                  title: intl.formatMessage({id:'page_login_recovery_operation_key_title_ok'}),
                  message: '',
                  onAccept: redirectLogin ? ()=>{router.push(getUrlNavigation(LoginPage))} : undefined,
                  acceptText: intl.formatMessage({id:'page_register_continue'})});
                return;
            }
          }
          dialog({
            icon: <IconInformationUserLight color={skinVars.colors.brand} />,
            title: title,
            message: message,
            onAccept: redirectLogin ? ()=>{router.push(getUrlNavigation(LoginPage))} : undefined,
            acceptText: intl.formatMessage({id:'page_register_continue'})});

        }).finally(()=>{
          setLoading(false);
        })
      }else{
        setLoading(false);
      }
    }else{
      setLoading(false);
    }
  }else{
    setLoading(false);
  }
}, []);

const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_login_title'})}}
      header={{hide:true}}
      hideFooter={true}
    >
      <LoadingBar  visible={loading || loadingForm} />
      {loading ?
        <ResponsiveLayout className='content' backgroundColor={isNativeApp ? skinVars.colors.backgroundAlternative : "transparent"}>
          <ResponsiveLayout fullWidth>
            <div className='form-login-page'>
              {size.isMobile ?
                <Stack space={24}>
                  <SkeletonRectangle height={56}></SkeletonRectangle>
                  <SkeletonRectangle height={439}></SkeletonRectangle>
                </Stack>
                :
                <Box paddingY={80}>
                    <GridLayout verticalSpace={32} template="6+6"
                      left=
                      {
                        <SkeletonRectangle height={439}></SkeletonRectangle>
                      }
                      right=
                      {
                        <SkeletonRectangle height={439}></SkeletonRectangle>
                      }
                    />
                  </Box>
              }
            </div>
          </ResponsiveLayout>
        </ResponsiveLayout>
      :
        <ResponsiveLayout className='content' backgroundColor={isNativeApp ? skinVars.colors.backgroundAlternative : "transparent"}>
          <ResponsiveLayout fullWidth>
            <div className='form-login-page'>

            {isRecoveryPasswordForm ?
              <Box paddingY={80}>
                <Boxed>
                  <Box padding={40}>
                    <Stack space={32}>
                      <Text4 medium>{intl.formatMessage({id:'page_login_recover_password'})}</Text4>
                      <Form onSubmit={handleSubmitRecoveryPassword} className={loadingForm ? 'loading' : ''}>
                          <Stack space={32}>
                            <GridLayout template='6+6' verticalSpace={16}
                              left={<PasswordField error={handleError('password')!==null} helperText={handleError('password')??undefined} fullWidth onChange={handleChangeField} name="password" label={intl.formatMessage({id:'page_login_recovery_password_process_new_password'})}></PasswordField>}
                              right={<PasswordField error={handleError('repeatPassword')!==null} helperText={handleError('repeatPassword')??undefined} fullWidth onChange={handleChangeField} name="repeatPassword" label={intl.formatMessage({id:'page_login_recovery_password_process_new_repeat_password'})}></PasswordField>}></GridLayout>
                            <ButtonLayout align='full-width'>
                              <ButtonPrimary submit disabled={isSubmitFormRecoveryPasswordDisabled()}>
                                {intl.formatMessage({id:'page_login_recover_password_accept_text'})}
                              </ButtonPrimary>
                            </ButtonLayout>
                          </Stack>
                        </Form>
                      </Stack>
                    </Box>
                  </Boxed>
                </Box>
              : size.isMobile ? (
                <>
                  {isNativeApp &&
                    <Box className='box-center' paddingTop={72} paddingBottom={56}>
                      <Inline space='around'>
                          <Image src={`/assets/img/logo.svg`} width={150} aspectRatio={0} alt={intl.formatMessage({id:'site_name'})} noBorderRadius={true} ></Image>
                      </Inline>
                    </Box>}

                  <Stack space={24}>
                    <Tabs
                      selectedIndex={selectedIndex}
                      onChange={setSelectedIndex}
                      tabs={[intl.formatMessage({id:'page_login_tab_get_into'}), intl.formatMessage({id:'page_login_tab_sign_up'})].map((text) => ({
                        text,
                      }))}
                    />
                    {selectedIndex === 1 ? (
                      <>
                        <InitRegisterCard/>
                        <Row title=''/>
                      </>
                    ) : (
                      <>
                        <LoginCard />
                        <Row title=''/>
                      </>
                    )}
                  </Stack>
                </>
                ) : (
                  <Box paddingY={80}>
                    <GridLayout verticalSpace={32} template="6+6"
                      left=
                      {
                        <LoginCard/>
                      }
                      right=
                      {
                        <InitRegisterCard/>
                      }
                    />
                  </Box>
                )
              }
            </div>
            </ResponsiveLayout>
        </ResponsiveLayout>
      }
    </LayoutPublic>
  );
};

export default LoginPage;
