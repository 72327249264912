import { useIonRouter } from '@ionic/react';
import { Box, Boxed, ButtonLayout, ButtonPrimary, Form, Stack, Text4, TextField, useScreenSize } from '@telefonica/mistica';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import RegisterPage from '../../pages/private/register/RegisterPage';
import { getUrlNavigation } from '../../utils/navigation';
import { setNameRegister } from '../../utils/storage';
import { trackEvent } from '../../utils/utils';

interface InitRegisterCardProp {}

const InitRegisterCard: React.FC<InitRegisterCardProp> = (prop) => {

const intl = useIntl();
const router = useIonRouter();
const size = useScreenSize();

  const [formData, setFormData] = useState({
    name: ""
  });

//Submit disabled
const isSubmitDisabled = () => {
  return formData.name.length===0;
}

//Submit
const handleSubmit = (): Promise<void> =>
  new Promise((resolve) => {
      setTimeout(() => {
          trackEvent('acceso-registro-iniciar');
          setNameRegister(formData.name);
          router.push(getUrlNavigation(RegisterPage));
          resolve();
      }, 100);
  });

  //Handle fields
  const handleChangeField= (evt:any) => {
      const { target } = evt;
      const { name, value } = target;
      const newValues = {
        ...formData,
        [name]: value,
      };
      setFormData(newValues);
  };

  return (

    <Boxed>
      <Box padding={40}>
            <Stack space={48}>
                <Text4 medium>{intl.formatMessage({id:'page_login_title_record_card'})}</Text4>
                <Form onSubmit={handleSubmit} initialValues={formData}>

                <Stack space={size.isDesktopOrBigger ? 72 : 32}>
                  <TextField  autoFocus={false} name="name" label={intl.formatMessage({id:'page_login_tell_us_your_dni'})} onChange={handleChangeField} autoComplete="off" fullWidth/>

                  <Box paddingTop={size.isDesktopOrBigger ? 48 : 0}>
                    <ButtonLayout>
                      <ButtonPrimary submit disabled={isSubmitDisabled()}>
                          {intl.formatMessage({id:'page_login_start_record'})}
                        </ButtonPrimary>
                    </ButtonLayout>
                  </Box>
                </Stack>
                </Form>
            </Stack>
          </Box>
        </Boxed>
  );
};

export default InitRegisterCard;