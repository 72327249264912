import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';


import ContentNews from '../../../components/news/contentNews/ContentNews';
import ContentMostRead from '../../../components/news/mostRead/MostRead';
import ContentNewsletter from '../../../components/news/newsletter/Newsletter';
import TagNewContainer from '../../../components/news/TagNew/TagNew';

import { TagNews } from '../../../entities/news/tag.model';
import { getNewsPage } from '../../../utils/apiclient';

import { Box, ButtonLayout, ButtonPrimary, Form, GridLayout, Inline, LoadingBar, ResponsiveLayout, Select, SkeletonLine, SkeletonText, skinVars, Stack, Text3, Text4, Text6, TextField } from '@telefonica/mistica';


import LayoutPublic from '../../../components/layout/LayoutPublic';
import { News } from '../../../entities/news/news.model';
import { trackEvent } from '../../../utils/utils';
import './NewsPage.scss';



const currentYear = new Date().getFullYear();
const fiveYearsAgo = currentYear - 5;
const years = [];

for (let i = currentYear; i >= fiveYearsAgo; i--) {
  years.push(i.toString());
}

const yearsOptions = years.map(year => ({ text: year, value: year }));
const NewsPage: React.FC = () => {

const [formData, setFormData] = useState({month: '', year: '', search: ''});
const [monthValue, setMonthValue] = useState(undefined as unknown as number);
const [yearValue, setYearValue] = useState(undefined as unknown as number);
const [searchValue, setSearchValue] = useState('');
const [months, setMonths] = useState([['']]);
const [tags, setTags] = useState([] as TagNews[]);
const [news, setNews] = useState([] as News[]);
const [newsMostRead, setNewsMostRead] = useState([] as News[]);
const [loading, setLoading] = useState(true);
const monthsOptions = months.map(([text, value]) => ({text, value}));
const intl = useIntl();

const handleSubmit = (data: any): Promise<void> =>
  new Promise((resolve) => {
    trackEvent('noticias-filtrar');
    if (data.month){
      setMonthValue(Number(data.month));
    }
    if (data.year){
      setYearValue(Number(data.year));
    }

    setSearchValue(data.search);
    resolve();
  });

useLayoutEffect(()=>{
  setMonths([  [intl.formatMessage({id:'months_01'}), '1'], [intl.formatMessage({id:'months_02'}), '2'], [intl.formatMessage({id:'months_03'}), '3'], [intl.formatMessage({id:'months_04'}), '4'], [intl.formatMessage({id:'months_05'}), '5'], [intl.formatMessage({id:'months_06'}), '6'], [intl.formatMessage({id:'months_07'}), '7'], [intl.formatMessage({id:'months_08'}), '8'], [intl.formatMessage({id:'months_09'}), '9'], [intl.formatMessage({id:'months_10'}), '10'], [intl.formatMessage({id:'months_11'}), '11'], [intl.formatMessage({id:'months_12'}), '12']]);
  getNewsPage({numberNews: 9, numberNewsMostRead: 3}).then(result=>{
    if(result){
      if(result.tags){
        setTags(result.tags);
      }

      if(result.news){
        setNews(result.news);
      }

      if(result.newsMostRead){
        setNewsMostRead(result.newsMostRead);
      }
    }
  }).finally(()=>{
    setLoading(false);
  });
}, []);

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_news_title_helmet'}), metaDescription:intl.formatMessage({id:'page_news_description_helmet'})}}
      header={{title:intl.formatMessage({id:'page_news_title'}), inverse: true}}
    >
        <LoadingBar visible={loading} />
        <ResponsiveLayout fullWidth={true} backgroundColor={skinVars.colors.backgroundBrand}>
          <ResponsiveLayout>
            <Box paddingTop={40} paddingBottom={80}>
              <GridLayout template="6+6"  verticalSpace={32}
                left={
                  <Text6 color={skinVars.colors.textPrimaryInverse}>{intl.formatMessage({id:'page_news_to_be_informed'})}</Text6>
                }
                right={
                  <Text4 regular color={skinVars.colors.textPrimaryInverse}>{intl.formatMessage({id:'page_news_check_the_news'})}</Text4>
                }
              />
            </Box>
          </ResponsiveLayout>
        </ResponsiveLayout>
        <ResponsiveLayout className='content' backgroundColor={"transparent"}>
          <Box paddingBottom={64}>
            <Stack space={48}>
              <Form initialValues={formData} onSubmit={handleSubmit}>
                <div style={{marginTop: "-40px",border: `1.5px solid ${skinVars.colors.borderSelected}`, borderRadius: `8px`, backgroundColor: `${skinVars.colors.background}`}}>
                  <Box padding={16}>
                    <GridLayout template='6+6'  verticalSpace={16}
                      left={
                        <GridLayout template='6+6'
                          left={
                            <Select
                              name="month"
                              options={monthsOptions}
                              label={intl.formatMessage({id:'page_news_search_month'})}
                              optional
                              fullWidth
                            />
                          }
                          right={
                            <Select
                              name="year"
                              options={yearsOptions}
                              label={intl.formatMessage({id:'page_news_search_year'})}
                              optional
                              fullWidth
                            />
                          }
                        />
                      }
                      right={
                        <GridLayout template='8+4'
                          left={
                            <TextField
                              name="search"
                              label={intl.formatMessage({id:'page_news_search_search'})}
                              optional
                              fullWidth
                            />
                          }
                          right={
                            <Box paddingTop={8} >
                              <ButtonLayout align='left'>
                                <ButtonPrimary submit>{intl.formatMessage({id:'page_news_search_button'})}</ButtonPrimary>
                              </ButtonLayout>
                            </Box>
                          }
                        />
                      }
                    />
                  </Box>
                </div>
              </Form>

              {Object.keys(tags).length === 0 ?(

                <Stack space={24}>
                  <SkeletonLine width="20%"/>
                  <SkeletonText/>
                </Stack>
              ) : (
                <Stack space={24}>
                  <Text3 regular color='black'>
                    {intl.formatMessage({id:'page_news_featured_tags'})}
                  </Text3>
                  <Inline space={8} wrap>
                    {tags.map(tag => (
                      <TagNewContainer key={tag.name} tag={tag}/>
                    ))}
                  </Inline>
                </Stack>
              )}

            </Stack>
          </Box>
        </ResponsiveLayout>
        <ResponsiveLayout fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
          <ResponsiveLayout>
            <Box paddingY={64}>
              <ContentNews news={news} month={monthValue} year={yearValue} search={searchValue}/>
            </Box>
          </ResponsiveLayout>
        </ResponsiveLayout>
        <ResponsiveLayout fullWidth={true} backgroundColor={skinVars.colors.background}>
            <ResponsiveLayout>
              <Box paddingY={80}>
                <Stack space={40}>
                  <ContentMostRead news={newsMostRead} />
                  <ContentNewsletter/>
                </Stack>
              </Box>
            </ResponsiveLayout>
        </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default NewsPage;

