import { useIonRouter } from '@ionic/react';
import { Box, ButtonLayout, ButtonPrimary, ButtonSecondary, GridLayout, IconEcoRegular, IconSearchFileRegular, Inline, LoadingBar, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Text4, Text5, useScreenSize } from '@telefonica/mistica';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import ButtonTertiary from '../../../../../components/buttons/ButtonTertiary';
import EmptyCard from '../../../../../components/empty-card/EmptyCard';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import { useProductActions } from '../../../../../components/operate/ProductActions';
import ProductCard from '../../../../../components/product/ProductCard';
import TotalBalance from '../../../../../components/total-balance/totalBalance';
import { Product } from '../../../../../entities/products/product';
import { UserProduct } from '../../../../../entities/products/userProduct';
import { getInvestmentFunds, getInvestmentFundsNotContracted } from '../../../../../utils/apiclient';
import { removeCurrentUserProduct } from '../../../../../utils/storage';
import { formatAnualProfit, formatBalance, formatDate, formatNetAssetValue, formatProduct, groupProducts, trackEvent } from '../../../../../utils/utils';

const InvestmentFundsCustomerPage: React.FC = () => {
  const intl = useIntl()
  const router = useIonRouter();
  const [loadingInvestmentFunds, setLoadingInvestmentFunds] = useState(true);
  const [loadingNoContractInvestmentFunds, setLoadingNoContractInvestmentFunds] = useState(true);
  const screenSize = useScreenSize();
  const [products, setProducts] = useState([] as UserProduct[]);
  const [balance, setBalance] = useState(null as number|null);
  const [containerProducts, setContainerProducts] = useState([] as UserProduct[][]);
  const [productsNotContract, setProductsNotContract] = useState([] as Product[]);
  const [containerProductsNotContract, setContainerProductsNotContract] = useState([] as Product[][]);
  const [loadingProduct, setLoadingProduct] = useState(null as null|string);


  const { contract, moreInfo, operate } = useProductActions()

  useEffect(()=>{
    removeCurrentUserProduct();
    getInvestmentFunds().then((result)=>{
      if(result){

        const balances = result.map((products) => parseFloat(products.saldo ? (products.saldo.toString()) : ('0')  || '0'));
        const balanceTotal = balances.reduce((total, bal) => total + bal, 0);
        setBalance(balanceTotal);

        setProducts(result);
        setContainerProducts(groupProducts(result));

      }
    }).finally(()=>{
      setLoadingInvestmentFunds(false);
    })

    getInvestmentFundsNotContracted().then((result)=>{
      setProductsNotContract(result);
      setContainerProductsNotContract(groupProducts(result));
    }).finally(()=>{
      setLoadingNoContractInvestmentFunds(false);
    })
  }, []);



  const handleMoreInformation = (product:Product): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('perfil-'+formatProduct(product)+'-mas_informacion');
      setLoadingProduct(formatProduct(product));
      moreInfo(product).finally(()=>{
        setLoadingProduct(null);
        resolve();
      });
  });

  const handleContract = (product:Product): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('perfil-'+formatProduct(product)+'-contratar');
        setLoadingProduct(formatProduct(product));
        contract(product,
        ()=>{
          setLoadingProduct(null);
        }).finally(()=>{
            resolve()
        });
  });

  const handleOperate = (product:UserProduct): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('perfil-'+formatProduct(product?.product)+'-operar');
      operate(product).finally(()=>{
        resolve();
      });
  });

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_investment_funds_customer_metatitle'})}}
      titleResponsive={intl.formatMessage({id:'page_investment_funds_customer_metatitle'})}
      pageInfo={{option: process.env.REACT_APP_MENU_FONDOS_INVERSION, role:'client'}}
    >
      <LoadingBar visible={loadingInvestmentFunds || loadingNoContractInvestmentFunds}/>
      <ResponsiveLayout className='inner first'>
        <Box paddingBottom={32}>
          <Inline space={'between'} alignItems='center'>
            {screenSize.isDesktopOrBigger &&
              <Text5>{intl.formatMessage({id:'page_investment_funds_customer_title'})}</Text5>
            }

            {loadingInvestmentFunds ?
              <SkeletonRectangle height={53} width={254} />
              :
              <TotalBalance
                icon = {<IconEcoRegular size={27}></IconEcoRegular>}
                balance = {balance ? balance : 0}
              />
            }
          </Inline>
        </Box>

        {loadingInvestmentFunds ?
          <Box paddingBottom={40}>
            <GridLayout
              template="6+6"
              verticalSpace={24}
              left={<SkeletonRectangle height={341} />}
              right={<SkeletonRectangle height={341} />}
            />
          </Box>
        :
          products.length===0 ?
            <Box paddingY={40}>
              <EmptyCard
                icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                title={intl.formatMessage({id:'page_investment_funds_customer_without_investment_funds_title'})}
                subtitle={intl.formatMessage({id:'page_investment_funds_customer_without_investment_funds_description'})}
              />
            </Box>
          :
          <Box paddingBottom={40}>
            <Stack space={24}>

              {containerProducts.map((group, index) => (
                <GridLayout
                  key={`group-${index}`}
                  template="6+6"
                  verticalSpace={24}
                  left={
                    group[0] ?
                    <ProductCard
                      title={group[0].product?.descripcion??''}
                      category={group[0].tipoFondo??''}
                      ownership={group[0].nbTitular??''}
                      balance={formatBalance(group[0].saldo??0)}
                      annual_profitability={group[0].rentabilidadAnual ? formatAnualProfit(group[0].rentabilidadAnual) : '0'}
                      date_balance={group[0].fechaSaldo ? formatDate(group[0].fechaSaldo) : '0'}
                      button={
                        <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                            <><ButtonPrimary small onPress={() => {handleOperate(group[0])}}>
                            {intl.formatMessage({id:'page_product_customer_operate_button'})}
                          </ButtonPrimary></>

                          <ButtonSecondary small onPress={()=>{group[0].product && handleMoreInformation(group[0].product) }}>
                            {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                          </ButtonSecondary>
                        </ButtonLayout>
                      }
                    /> : <></>
                  }
                  right={
                    group[1] ?
                      <ProductCard
                        title={group[1].product?.descripcion??''}
                        category={group[1].tipoFondo??''}
                        ownership={group[1].nbTitular??''}
                        balance={formatBalance(group[1].saldo??0)}
                        annual_profitability={group[1].rentabilidadAnual ? formatAnualProfit(group[1].rentabilidadAnual) : '0'}
                        date_balance={group[1].fechaSaldo ? formatDate(group[1].fechaSaldo) : '0'}
                        button={
                              <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                                  <><ButtonPrimary small onPress={() => {handleOperate(group[1])}}>
                                  {intl.formatMessage({id:'page_product_customer_operate_button'})}
                                </ButtonPrimary></>

                                <ButtonSecondary small onPress={()=>{group[1].product && handleMoreInformation(group[1].product) }}>
                                  {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                                </ButtonSecondary>
                              </ButtonLayout>
                        }
                      />
                    : <></>
                  }/>
              ))}
            </Stack>
          </Box>
        }
      </ResponsiveLayout>

      {loadingNoContractInvestmentFunds ?
        <ResponsiveLayout className='full'>
          <SkeletonRectangle height={350} />
        </ResponsiveLayout>
        :
        (productsNotContract.length===0 ?
          <></>
          :
          <ResponsiveLayout className='full' backgroundColor={skinVars.colors.backgroundAlternative}>
            <ResponsiveLayout className='inner'>
              <Box paddingTop={56} paddingBottom={32}>
                <Inline space={'between'} alignItems='center'>
                  <Text4 medium>{intl.formatMessage({id:'page_investment_funds_customer_other_products'})}</Text4>
                </Inline>
              </Box>
                <Box paddingBottom={40}>
                  <Stack space={24}>
                  {containerProductsNotContract.map((group, index) => (
                    <GridLayout
                      key={`group-${index}`}
                      template="6+6"
                      verticalSpace={24}
                      left={
                        group[0] ?
                        <ProductCard
                          title={group[0].descripcion??''}
                          category={group[0].informacionGeneral?.tipoFondo??''}
                          net_asset_value={group[0].rentabilidad?.valorLiquidativo ? formatNetAssetValue(group[0].rentabilidad?.valorLiquidativo) : intl.formatMessage({id:'page_product_customer_not_available'})}
                          annual_profitability={group[0].rentabilidad?.rentabilidadAnual ? formatBalance(group[0].rentabilidad?.rentabilidadAnual) : intl.formatMessage({id:'page_product_customer_not_available'})}
                          historical_profitability={group[0].rentabilidad && group[0].rentabilidad.rentabilidadHistorica ? formatBalance(group[0].rentabilidad.rentabilidadHistorica)+(group[0].rentabilidad.tpRentabilidadHistorica ? ' '+intl.formatMessage({id:'page_product_customer_apr'}) : '%') : intl.formatMessage({id:'page_product_customer_not_available'})}
                          button={
                            <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                              <ButtonTertiary showSpinner={loadingProduct===formatProduct(group[0])} small onPress={()=>{handleContract(group[0])}}>
                                {intl.formatMessage({id:'page_product_customer_hire_button'})}
                              </ButtonTertiary>
                              <ButtonSecondary small onPress={()=>{handleMoreInformation(group[0])}}>
                                {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                              </ButtonSecondary>
                            </ButtonLayout>
                          }
                        /> : <></>
                      }
                      right={
                        group[1] ?
                          <ProductCard
                            title={group[1].descripcion??''}
                            category={group[1].informacionGeneral?.tipoFondo??''}
                            net_asset_value={group[1].rentabilidad?.valorLiquidativo ? formatNetAssetValue(group[1].rentabilidad?.valorLiquidativo) : intl.formatMessage({id:'page_product_customer_not_available'})}
                            annual_profitability={group[1].rentabilidad?.rentabilidadAnual ? formatBalance(group[1].rentabilidad?.rentabilidadAnual) : intl.formatMessage({id:'page_product_customer_not_available'})}
                            historical_profitability={group[1].rentabilidad && group[1].rentabilidad.rentabilidadHistorica ? formatBalance(group[1].rentabilidad.rentabilidadHistorica)+(group[1].rentabilidad.tpRentabilidadHistorica ? ' '+intl.formatMessage({id:'page_product_customer_apr'}) : '%') : intl.formatMessage({id:'page_product_customer_not_available'})}
                            button={
                              <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                                <ButtonTertiary showSpinner={loadingProduct===formatProduct(group[1])} small onPress={()=>{handleContract(group[1])}}>
                                  {intl.formatMessage({id:'page_product_customer_hire_button'})}
                                </ButtonTertiary>
                                <ButtonSecondary small onPress={()=>{handleMoreInformation(group[1])}}>
                                  {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                                </ButtonSecondary>
                              </ButtonLayout>
                            }
                          />
                        : <></>
                      }/>
                  ))}
                  </Stack>
                </Box>
            </ResponsiveLayout>
          </ResponsiveLayout>
        )
      }
    </LayoutPrivateArea>
  );
};

export default InvestmentFundsCustomerPage;
