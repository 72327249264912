import { Box, Chip, HorizontalScroll, Inline, ResponsiveLayout, SkeletonRectangle, useScreenSize } from '@telefonica/mistica';
import React from 'react';
import { scrollToTop } from '../../utils/utils';
import './ChipTabs.scss';

interface ChipTabsProps {
    noPadding?: boolean,
    selectedIndex: number,
    onChange: (selectedIndex: number) => void,
    tabs: ReadonlyArray<{
        readonly text: string;
        readonly icon?: React.ReactNode;
        readonly 'aria-controls'?: string;
    }>;
}

const ChipTabs: React.FC<ChipTabsProps> = (prop) => {
    const screenSize = useScreenSize();

    return (
        <div id="chipTabs">
            <ResponsiveLayout >
                <Box className='chipTabs' paddingTop={!prop.noPadding ? (screenSize.isDesktopOrBigger ? 40 : 32) : 0} paddingBottom={!prop.noPadding ? (screenSize.isDesktopOrBigger ? 80 : 40) : 0}>
                    <HorizontalScroll noScrollbar={true}>
                        <Inline space={16} className='chipTabs__list'>
                            {prop.tabs.length==0 ?
                                <SkeletonRectangle width={340} height={32} />
                            :
                                prop.tabs.map((item, index)=>(
                                    <Chip key={'chip-'+index} onPress={()=>{prop.onChange(index); scrollToTop('chipTabs');}} active={index===prop.selectedIndex}>{item.text}</Chip>
                                ))
                            }
                        </Inline>
                    </HorizontalScroll>
                </Box>
            </ResponsiveLayout>
        </div>
    );
}

export default ChipTabs;
