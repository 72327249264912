import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Contact } from '../../entities/contact/contact.model';

import { ButtonPrimary, Checkbox, dialog, EmailField, Form, GridLayout, IconAlertLight, IconInformationUserLight, skinVars, Stack, TextField, TextLink } from '@telefonica/mistica';

import DataProtectionPage from '../../pages/public/data-protection/DataProtectionPage';
import { addContact, sendMailTechnicalClaims, trackStadistics } from '../../utils/apiclient';
import { getUrlNavigation } from '../../utils/navigation';
import { isBot, trackEvent, validatePhoneNumber } from '../../utils/utils';
import { useAlert } from '../alerts/Alert';
import { Claim } from '../../entities/claim/claim.model';

const TechnicalClaimsForm: React.FC = () => {
  const { showAlert } = useAlert()
  const intl = useIntl();
  const initContact = {name: '', lastname: '', email: '', phone: '', claim: ''} as Claim;
  const [formContact, setFormContact] = useState(initContact);

  const [checkConditions, setCheckConditions] = React.useState(false);
  const [formWriteUs, setFormWriteUs] = React.useState(true);

  const handleChangeField= (evt:any) => {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
      ...formContact,
      [name]: value,
    };
    setFormContact(newValues);
  };

  const handleSubmit = (data: any): Promise<void> =>
  new Promise((resolve) => {
    if(data){
      trackEvent('reclamacion-enviar');
      sendMailTechnicalClaims(data).then(response=>{
        const scalarValue = response.scalar;
        if(response && scalarValue === 'OK'){
          trackStadistics('CONTACT', undefined, undefined, data.formType );
          dialog({
            title: intl.formatMessage({id:'page_technical_claims_form_title_dialog_ok'}),
            message: intl.formatMessage({id:'page_technical_claims_form_description_dialog_ok'}),
            acceptText: intl.formatMessage({id:'page_technical_claims_form_button_dialog'}),
            forceWeb: true,
            showCancel: false,
            icon: <IconInformationUserLight color={skinVars.colors.brand} />,
          });
          setFormContact(initContact);
        }else{
          dialog({
            title: intl.formatMessage({id:'page_technical_claims_form_title_dialog_ko'}),
            message: intl.formatMessage({id:'page_technical_claims_form_description_dialog_ko'}),
            acceptText: intl.formatMessage({id:'page_technical_claims_form_button_dialog'}),
            forceWeb: true,
            showCancel: false,
            icon: <IconAlertLight color={skinVars.colors.buttonDangerBackground} />,
          });
        }
        resolve();
      });
    }
  });

  return (
    <Form onSubmit={handleSubmit}>
        <Stack space={24}>
          <GridLayout template='6+6' 
            left={
              <Stack space={24}>
                <TextField
                    name="name"
                    value={formContact.name}
                    onChange={handleChangeField}
                    label={intl.formatMessage({id:'page_technical_claims_form_text_name'})}
                    autoComplete="off"
                    fullWidth />
                <TextField
                    name="lastname"
                    value={formContact.lastname}
                    onChange={handleChangeField}
                    label={intl.formatMessage({id:'page_technical_claims_form_text_lastname'})}
                    autoComplete="off"
                    fullWidth />
                <EmailField
                    name="email"
                    value={formContact.email}
                    onChange={handleChangeField}
                    label={intl.formatMessage({id:'page_technical_claims_form_text_email'})}
                    autoComplete="off"
                    fullWidth />
                <TextField
                    name="phone"
                    value={formContact.phone}
                    onChange={handleChangeField}
                    label={intl.formatMessage({id:'page_technical_claims_form_text_phone'})}
                    autoComplete="off"
                    fullWidth
                    error={formContact.phone && formContact.phone.length > 0 ? !validatePhoneNumber(formContact.phone) : undefined}
                    helperText={ formContact.phone && formContact.phone.length > 0 ? !validatePhoneNumber(formContact.phone) ? intl.formatMessage({id:'page_technical_claims_form_text_phone_error'}) : undefined : undefined}/>
                <Checkbox name="checkConditions" checked={checkConditions} onChange={setCheckConditions}>{intl.formatMessage({id:'page_technical_claims_form_check_terms'},{privacy_policy: (isBot ? <TextLink newTab href={getUrlNavigation(DataProtectionPage)}>{intl.formatMessage({id:'page_technical_claims_form_check_terms_policy_text'})}</TextLink> : <TextLink onPress={()=>{showAlert('_privacy_policy_')}}>{intl.formatMessage({id:'page_technical_claims_form_check_terms_policy_text'})}</TextLink>) })}</Checkbox>
              </Stack>
            }right={
              <TextField
                name="claim"
                value={formContact.claim}
                onChange={handleChangeField}
                label={intl.formatMessage({id:'page_technical_claims_form_text_claim'})}
                autoComplete="off"
                fullWidth
                multiline />
            }/>
          <ButtonPrimary disabled={!checkConditions} submit>{intl.formatMessage({id:'page_technical_claims_form_button_send'})}</ButtonPrimary>
        </Stack>
    </Form>
  );
};

export default TechnicalClaimsForm;
