import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Box, Boxed, IconButton, Text2 } from "@telefonica/mistica";

import './CopyToClipboard.scss';

export interface CopyToClipboardProps {
  text: string;
  button: JSX.Element;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = (props) => {
  const intl = useIntl();
  
  const [showMessage, setShowMessage] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.text);
    setShowMessage(true);
  };

  useEffect(() => {
    let timerId: number;
    if (showMessage) {
      timerId = window.setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [showMessage]);

  return (
    <>
      <IconButton onPress={handleCopy}>
        {props.button}
      </IconButton>
      {showMessage && 
        <div className="copyToClipBoard--copy">
            <Box paddingTop={8}>
                <Boxed isInverse>
                    <Box padding={8}>
                        <Text2 regular>{intl.formatMessage({id:'clipboard_copy'})}</Text2>
                    </Box>
                </Boxed>
            </Box>
        </div>}
    </>
  );
};

export default CopyToClipboard;
