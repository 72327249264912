import { useIonRouter } from '@ionic/react';
import slugify from 'react-slugify';

import { Box, Boxed, ButtonPrimary, ButtonSecondary, Carousel, DisplayDataCard, Grid, GridLayout, IconAddMoreQueryRegular, IconArrowDownRegular, IconEcoRegular, IconEmailRegular, IconMoneyRiseRegular, IconSearchMoneyRegular, IconUmbrellaRegular, IconWalletRegular, Image, MediaCard, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Text4, Text5, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAlert } from '../../../components/alerts/Alert';
import ChipTabs from '../../../components/chip-tabs/ChipTabs';
import EmptyStateCustom from '../../../components/empty-state/EmptyStateCustom';
import Faqs from '../../../components/faqs/faqs';
import HeroImage from '../../../components/hero/HeroImage';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import Documentation from '../../../components/product-data/Documentation';
import { Report } from '../../../entities/contents/report.model';
import { Product } from '../../../entities/products/product';
import { getForInvestor, trackStadistics } from '../../../utils/apiclient';
import { getUrlNavigation } from '../../../utils/navigation';
import { getUser } from '../../../utils/storage';
import { isBot, scrollToTop, trackEvent } from '../../../utils/utils';
import ContactPage from '../contact/ContactPage';
import CommissionSavingsCalculatorPage from './calculators/CommissionSavingsCalculatorPage';
import CustomizeInvestmentFundPage from './calculators/DecideYourInvestmentCalculatorPage';
import FinalCapitalCalculatorPage from './calculators/FinalCapitalCalculatorPage';
import './ForInvestorPage.scss';

const ForInvestorPage: React.FC = () => {
  const intl = useIntl()
  const size = useScreenSize();
  const { showAlert } = useAlert();
  const screenSize = useScreenSize();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const router = useIonRouter();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(getUser());
  const [faqs, setFaqs] = useState([] as Array<{title:string, icon:React.ReactNode, items: Array<{title:string, value:any}> }>);
  const [terms, setTerms] = useState([] as Array<{title:string, icon:React.ReactNode, items: Array<{title:string, value:any}> }>);
  const [optionsHash, setOptionsHash] = useState([intl.formatMessage({id:'page_for_investor_glossary_terms'}), intl.formatMessage({id:'page_for_investor_calculators'}), intl.formatMessage({id:'page_for_investor_faqs'}), intl.formatMessage({id:'page_for_investor_guides_report'}), intl.formatMessage({id:'page_for_investor_customer_defens'})] as string[]);


  const [guides, setGuides] = useState([] as Report[]);
  const [reports, setReports] = useState([] as Report[]);

  const [product, setProduct] = useState(null as null|Product);

  const [options, setOptions] = useState([] as string[]);

  useEffect(()=>{
    getForInvestor().then((result)=>{
      if(result){
        let opts: string[] = [];
        if(result.glossaryTerms){
          const glossaryTermsData : Array<{title:string, icon:React.ReactNode, items: Array<{title:string, value:any}> }> = [];
          //Planes de pensiones
          const glossaryTermsDataPP = result.glossaryTerms.filter(item=>item.cdCompania===parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1'))
          if(glossaryTermsDataPP && glossaryTermsDataPP.length>0){
            glossaryTermsData.push({
              title: intl.formatMessage({id:'page_for_investor_pension_plans_title'}),
              icon: <IconUmbrellaRegular color={'currentColor'} />,
              items: glossaryTermsDataPP.map((item, index)=>{
                return {title: item.nbNombre, value: item.nbDescripcion};
              })
            })
          }

          //Fondos de inversión
          const glossaryTermsDataFI = result.glossaryTerms.filter(item=>item.cdCompania===parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ?? '3'))
          if(glossaryTermsDataFI && glossaryTermsDataFI.length>0){
            glossaryTermsData.push({
              title: intl.formatMessage({id:'page_for_investor_investment_funds_title'}),
              icon: <IconEcoRegular color={'currentColor'} />,
              items: glossaryTermsDataFI.map((item, index)=>{
                return {title: item.nbNombre, value: item.nbDescripcion};
              })
            })
          }
          setTerms(glossaryTermsData);

          if(glossaryTermsData.length>0){
            opts.push(intl.formatMessage({id:'menu_footer_for_investor_glossary_terms'}));
          }
        }

        //Calculadoras
        opts.push(intl.formatMessage({id:'menu_footer_for_investor_calculators'}));

        if(result.faqs){
          const faqsData : Array<{title:string, icon:React.ReactNode, items: Array<{title:string, value:any}> }> = [];
          //Planes de pensiones
          const faqsPP = result.faqs.filter(item=>item.cdCompania===parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1'))
          if(faqsPP && faqsPP.length>0){
            faqsData.push({
              title: intl.formatMessage({id:'page_for_investor_pension_plans_title'}),
              icon: <IconUmbrellaRegular color={'currentColor'} />,
              items: faqsPP.map((item, index)=>{
                return {title: item.nbNombre, value: item.nbDescripcion};
              })
            })
          }

          //Fondos de inversión
          const faqsFI = result.faqs.filter(item=>item.cdCompania===parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ?? '3'))
          if(faqsFI && faqsFI.length>0){
            faqsData.push({
              title: intl.formatMessage({id:'page_for_investor_investment_funds_title'}),
              icon: <IconEcoRegular color={'currentColor'} />,
              items: faqsFI.map((item, index)=>{
                return {title: item.nbNombre, value: item.nbDescripcion};
              })
            })
          }
          setFaqs(faqsData);

          if(faqsData.length>0){
            opts.push(intl.formatMessage({id:'menu_footer_for_investor_faqs'}));
          }
        }

        if(result.reports){
          setGuides(result.reports.filter(item=>item.nbTablaContenidos && item.nbTablaContenidos.trim().length>0));
          setReports(result.reports.filter(item=>!item.nbTablaContenidos || item.nbTablaContenidos.trim().length==0));


          if(result.reports.length>0){
            opts.push(intl.formatMessage({id:'menu_footer_for_investor_guide_reports'}));
          }
        }

        if(result.documentationCustomerDefence){
          setProduct({ documentacion: result.documentationCustomerDefence});
          if(result.documentationCustomerDefence.length>0){
            opts.push(intl.formatMessage({id:'menu_footer_for_investor_customer_defence'}));
          }
        }
        setOptions(opts);
      }

    }).finally(()=>{
        setLoading(false);
    });
  }, []);

  useLayoutEffect(()=>{
    if(window.location.hash.length>0 && optionsHash.length>0){
      
      let index = optionsHash.findIndex(item=>item===window.location.hash.replace('#', ''));
      if(index!=-1){
        setSelectedIndex(index);
        
        setTimeout(function(){
          scrollToTop('content-chips');
        }, 400);
      }
    }

  }, [window.location.hash]);

  useEffect(()=>{
    trackEvent('para_inversor-'+slugify(options[selectedIndex]).replaceAll('-', '_'));

    switch (selectedIndex){
      case 1:
        trackStadistics('PAGE', '_CALCULADORAS' );
        break;
      case 2:
        trackStadistics('PAGE', '_PREGUNTAS_FRECUENTES' );
        break;
      case 3:
        trackStadistics('PAGE', '_GUIAS_INFORMES' );
        break;
      default:
        trackStadistics('PAGE', '_GLOSARIO_TERMINOS' );
        break;
    }
  }, [selectedIndex]);

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_for_investor_seo_title'}), metaDescription:intl.formatMessage({id:'page_for_investor_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_for_investor_page_title'})}}
    >
      <HeroImage
        image='../assets/img/glosario.jpg'
        title={intl.formatMessage({id:'page_for_investor_hero_title'})}
        description={intl.formatMessage({id:'page_for_investor_hero_description'})}
        imageNotRound={true}
      ></HeroImage>

      <div id="content-chips">
        {loading ?
          <Box paddingY={screenSize.isDesktopOrBigger ? 80 : 40}>
            <Stack space={16}>
              <Box paddingX={16} className="boxAlignCenter" paddingTop={screenSize.isDesktopOrBigger ? 40 : 32} paddingBottom={screenSize.isDesktopOrBigger ? 80 : 40}>
                <SkeletonRectangle width={500}  height={35} />
              </Box>
              <ResponsiveLayout>
                <Stack space={16}>
                  <SkeletonRectangle width={300} height={32} />
                  <SkeletonRectangle height={55} />
                  <SkeletonRectangle height={300} />
                </Stack>
              </ResponsiveLayout>
            </Stack>
          </Box>
        :
          <ChipTabs
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
            tabs={options.map((text) => ({
              text,
            }))}
          />

        }

        <div id={slugify(intl.formatMessage({id:'menu_footer_for_investor_glossary_terms'}))}>
          <ResponsiveLayout className={(options.length>0 && options[selectedIndex]===intl.formatMessage({id:'menu_footer_for_investor_glossary_terms'}) ? 'd-block' : 'd-none')}>
            <Stack space={screenSize.isDesktopOrBigger ? 56 : 16}>
              <Stack space={16}>
                <Text5>{intl.formatMessage({id:'menu_footer_for_investor_glossary_terms'})}</Text5>

                <Text4 light>{intl.formatMessage({id:'page_for_investor_chip_tab1_description'})}</Text4>
              </Stack>

                {terms.length>0 &&
                  <Faqs values={terms} prefixSlug={'para_inversor-'+slugify(options[selectedIndex]).replaceAll('-', '_')} />
                }
            </Stack>
          </ResponsiveLayout>
        </div>

        <div id={slugify(intl.formatMessage({id:'menu_footer_for_investor_calculators'}))}>
          <ResponsiveLayout className={(options.length>0 && options[selectedIndex]===intl.formatMessage({id:'menu_footer_for_investor_calculators'}) ? 'd-block' : 'd-none')}>
            <Stack space={screenSize.isDesktopOrBigger ? 56 : 16}>
              <Stack space={16}>
                <Text5>{intl.formatMessage({id:'menu_footer_for_investor_calculators'})}</Text5>

                <Text4 light>{intl.formatMessage({id:'page_for_investor_chip_tab2_description'})}</Text4>
              </Stack>

              <Grid columns={screenSize.isDesktopOrBigger ? (user ? 4 : 3) : (screenSize.isTabletOrBigger ? 2 : 1)} gap={screenSize.isTabletOrBigger ? 24 : 16}>
                <DisplayDataCard
                  icon={<IconAddMoreQueryRegular size={36}  color={skinVars.colors.brand} />}
                  pretitle={intl.formatMessage({id:'page_for_investor_pension_plans'})}
                  title=""
                  extra={<Text4 regular>{intl.formatMessage({id:'page_for_investor_commission_savings_calculator_title'})}</Text4>}
                  button={
                    isBot ?
                      <ButtonSecondary small href={getUrlNavigation(CommissionSavingsCalculatorPage)}>
                        {intl.formatMessage({id:'page_for_investor_start'})}
                      </ButtonSecondary>
                    :
                      <ButtonSecondary small onPress={() => {router.push(getUrlNavigation(CommissionSavingsCalculatorPage));trackEvent('para_inversor-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_commission_savings_calculator_title'}).replaceAll('-', '_'))); }}>
                        {intl.formatMessage({id:'page_for_investor_start'})}
                      </ButtonSecondary>
                  }
                />
                <DisplayDataCard
                  icon={<IconMoneyRiseRegular size={36}  color={skinVars.colors.brand} />}
                  pretitle={intl.formatMessage({id:'page_for_investor_pension_plans'})}
                  title=""
                  extra={<Text4 regular> {intl.formatMessage({id:'page_for_investor_final_capital_calculator_title'})}</Text4>}
                  button={
                    isBot ?
                      <ButtonSecondary small href={getUrlNavigation(FinalCapitalCalculatorPage)}>
                        {intl.formatMessage({id:'page_for_investor_start'})}
                      </ButtonSecondary>
                    :
                      <ButtonSecondary small onPress={() => {router.push(getUrlNavigation(FinalCapitalCalculatorPage)); trackEvent('para_inversor-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_final_capital_calculator_title'})).replaceAll('-', '_'));}}>
                        {intl.formatMessage({id:'page_for_investor_start'})}
                      </ButtonSecondary>
                  }
                />
                {user &&
                  <DisplayDataCard
                    icon={<IconWalletRegular size={36}  color={skinVars.colors.brand} />}
                    pretitle={intl.formatMessage({id:'page_for_investor_pension_plans'})}
                    title=""
                    extra={<Text4 regular> {intl.formatMessage({id:'page_for_investor_calculator_for_withholdings_capital_collections_title'})}</Text4>}
                    button={
                      <ButtonSecondary small onPress={()=>{ trackStadistics('CALCULATOR', undefined, undefined, 'Calculadora decide tu inversión.', 'CALCULADORA_RETENCIONES_COBROS_CAPITAL_DERECHO_REDUCCION' ); window.open('https://www2.agenciatributaria.gob.es/wlpl/PRET-R200/R231/index.zul', '_blank'); trackEvent('para_inversor-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_calculator_for_withholdings_capital_collections_title'})).replaceAll('-', '_'));}}>
                        {intl.formatMessage({id:'page_for_investor_go_to_tax_agency'})}
                      </ButtonSecondary>
                    }
                  />
                }
                <DisplayDataCard
                  icon={<IconSearchMoneyRegular size={36} color={skinVars.colors.brand} />}
                  pretitle={intl.formatMessage({id:'page_for_investor_pension_plans_inversment_funds'})}
                  title=""
                  extra={<Text4 regular>{intl.formatMessage({id:'page_for_investor_choose_your_nvestment'})}</Text4>}
                  button={
                    isBot ?
                      <ButtonSecondary small href={getUrlNavigation(CustomizeInvestmentFundPage)}>
                        {intl.formatMessage({id:'page_for_investor_start'})}
                      </ButtonSecondary>
                    :
                      <ButtonSecondary small onPress={() => {router.push(getUrlNavigation(CustomizeInvestmentFundPage)); trackEvent('para_inversor-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_choose_your_nvestment'})).replaceAll('-', '_'));}}>
                        {intl.formatMessage({id:'page_for_investor_start'})}
                      </ButtonSecondary>
                  }
                />

                {/*
                  <DisplayDataCard
                    icon={<IconWalletRegular size={36}  color={skinVars.colors.brand} />}
                    pretitle="Planes de pensiones"
                    title=""
                    extra={<Text4 regular>Calculadora de Ahorro Complementario</Text4>}
                    button={
                      <ButtonSecondary small onPress={() => {}}>
                        Empezar
                      </ButtonSecondary>
                    }
                  />
                */}
              </Grid>
            </Stack>
          </ResponsiveLayout>
        </div>

        <div id={slugify(intl.formatMessage({id:'menu_footer_for_investor_faqs'}))}>
          <ResponsiveLayout className={(options.length>0 && options[selectedIndex]===intl.formatMessage({id:'menu_footer_for_investor_faqs'}) ? 'd-block' : 'd-none')}>
            <Stack space={screenSize.isDesktopOrBigger ? 56 : 16}>
              <Stack space={16}>
                <Text5>{intl.formatMessage({id:'menu_footer_for_investor_faqs'})}</Text5>

                <Text4 light>{intl.formatMessage({id:'page_for_investor_chip_tab3_description'})}</Text4>
              </Stack>

                {faqs.length>0 &&
                  <Faqs values={faqs} prefixSlug={'para_inversor-'+slugify(options[selectedIndex]).replaceAll('-', '_')} />
                }
            </Stack>
          </ResponsiveLayout>
        </div>

        <div id={slugify(intl.formatMessage({id:'menu_footer_for_investor_guide_reports'}))} className="guides-reports">
          <ResponsiveLayout className={(options.length>0 && options[selectedIndex]===intl.formatMessage({id:'menu_footer_for_investor_guide_reports'}) ? 'd-block' : 'd-none')}>
            <Stack space={screenSize.isDesktopOrBigger ? 56 : 16}>
              <Stack space={16}>
                <Text5>{intl.formatMessage({id:'menu_footer_for_investor_guide_reports'})}</Text5>

                <Text4 light>{intl.formatMessage({id:'page_for_investor_chip_tab4_description'})}</Text4>
              </Stack>

              <Box paddingX={screenSize.isDesktopOrBigger ? 80 : (screenSize.isTabletOrBigger ? 40 : 0)}>
                <Stack space={screenSize.isDesktopOrBigger ? 40 : 24}>
                  <Stack space={screenSize.isDesktopOrBigger ? 32 : 16}>

                    {guides.map((item, index)=>
                      <GridLayout key={'guide-'+index}
                        verticalSpace={16}
                          template='8+4'
                          left={
                            <MediaCard
                              media={<Image src={item.ImagenGuiaNombre ?? ''} width={'100%'} aspectRatio={0} />}
                              title={item.nbTitulo}
                              description={item.nbDescripcion}
                              button={
                                <ButtonSecondary small onPress={()=>{showAlert('_reports_', item.nbTitulo + '&' + item.DocumentoGuiaNombre);trackEvent('para_inversor-guias-'+slugify(item.nbTitulo).replaceAll('-', '_'));}}>
                                  {intl.formatMessage({id:'page_for_investor_download_report'})}
                                  <IconArrowDownRegular color="currentColor" />
                                </ButtonSecondary>
                              }
                            />
                          }
                          right={
                            <Boxed className='reportsBoxed'>
                              <Box paddingX={screenSize.isTabletOrBigger ? 24 : 16} paddingY={screenSize.isTabletOrBigger ? 32 : 16}>
                                <Stack space={12}>
                                  <Text4 regular>{intl.formatMessage({id:'page_for_investor_table_of_contents'})}</Text4>

                                  <div className='html-content' dangerouslySetInnerHTML={{__html: item.nbTablaContenidos??''}}></div>
                                </Stack>
                              </Box>
                            </Boxed>
                          }
                        />
                    )}
                  </Stack>


                  <Carousel initialActiveItem={0} itemsPerPage={size.isTabletOrBigger ? 2 : 1}
                    items={reports.map((item, index)=>(
                      <MediaCard key={'report-'+index}
                        media={<Image src={item.ImagenGuiaNombre ?? ''} aspectRatio={'16:9'} />}
                        title={item.nbTitulo}
                        description={item.nbDescripcion}
                        button={
                          <ButtonSecondary small onPress={()=>{showAlert('_reports_', item.nbTitulo + '&' + item.DocumentoGuiaNombre); trackEvent('para_inversor-informes-'+slugify(item.nbTitulo).replaceAll('-', '_'));}}>
                            {intl.formatMessage({id:'page_for_investor_download_report'})}
                            <IconArrowDownRegular color="currentColor" />
                          </ButtonSecondary>
                        }
                      />
                    ))}
                  />
                </Stack>
              </Box>
            </Stack>
          </ResponsiveLayout>

          <div id={slugify(intl.formatMessage({id:'menu_footer_for_investor_customer_defence'}))} className="customer-defence">
            <ResponsiveLayout className={(options.length>0 && options[selectedIndex]===intl.formatMessage({id:'menu_footer_for_investor_customer_defence'}) ? 'd-block' : 'd-none')}>


                <Stack space={screenSize.isDesktopOrBigger ? 56 : 16}>
                  <Stack space={16}>
                    <Text5>{intl.formatMessage({id:'menu_footer_for_investor_customer_defence'})}</Text5>

                    <Text4 light>{intl.formatMessage({id:'page_for_investor_chip_tab5_description'})}</Text4>
                  </Stack>

                  <Documentation prefixSlug={'para_inversor-defensa_cliente-descarga_documentacion-'} loading={loading} product={product} all={true}  />
                </Stack>
            </ResponsiveLayout>
          </div>

        </div>
      </div>

      <ResponsiveLayout className='content' fullWidth={true}>
          <ResponsiveLayout>

              <EmptyStateCustom
                  largeImageUrl="../assets/img/necesitas-ayuda.jpg"
                  title={intl.formatMessage({id:'page_for_investor_empty_state_title'})}
                  description={intl.formatMessage({id:'page_for_investor_empty_state_description'})}
                  button={
                      <ButtonGroup
                          primaryButton={
                            isBot ?
                              <ButtonPrimary href={getUrlNavigation(ContactPage)}><IconEmailRegular color={"currentColor"} />{intl.formatMessage({id:'page_for_investor_empty_state_action_primary'})}</ButtonPrimary>
                          :
                              <ButtonPrimary onPress={()=>router.push(getUrlNavigation(ContactPage))}><IconEmailRegular color={"currentColor"} />{intl.formatMessage({id:'page_for_investor_empty_state_action_primary'})}</ButtonPrimary>
                          }
                      ></ButtonGroup>
                  }
              />

          </ResponsiveLayout>
      </ResponsiveLayout>

  </LayoutPublic>
  );
};

export default ForInvestorPage;
