import { Box, Hero, Image, ResponsiveLayout, Stack, Text6, skinVars, useScreenSize } from '@telefonica/mistica';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import ChipTabs from '../../../../components/chip-tabs/ChipTabs';
import HeroImage from '../../../../components/hero/HeroImage';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import MembersOf from '../../../../components/staticPages/membersOf/MembersOf';
import '../LandingPage.scss';

const FiscalExerciseInvestmentFundsPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [options, setOptions] = useState(['Suscripciones', 'Traspaso de fondos', 'Reembolsos']);

  return (
    <LayoutPublic
      seo={{metaTitle: 'Fonditel IRPF 2022', metaDescription:'Fonditel IRPF 2022'}}
    >
      <style>{'.landingPage--content .html-content ul li:before{background: '+skinVars.colors.textPrimary+';}'}</style>
      <HeroImage
        image=''
        video={
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/3Lq9fK0zV9M" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        }
        title='Fondos Inversión'
        imageNotRound={true}
      ></HeroImage>

      <ChipTabs
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
        tabs={options.map((text) => ({
          text,
        }))}
      />

      <ResponsiveLayout className={(selectedIndex===0 ? 'landingPage--content d-block' : 'd-none')}>
        <div className='html-content' dangerouslySetInnerHTML={{__html:
          `<p>La suscripción de un fondo inversión implica la posibilidad de acceder a una gran variedad de estilos y estrategias de inversión con importantes ventajas fiscales.</p>
           <p>Uno de los puntos fuertes de los fondos de inversión es que permite diferir el pago de impuestos, ya que solo se tributa cuando se produce el reembolso.</p>
           <p>Otra ventaja de cara a la planificación patrimonial es lo que se conoce como 'plusvalía del muerto', regla fiscal especial que se aplica al heredar fondos de inversión gracias a la cual los herederos sólo pagan impuestos por las ganancias acumuladas en el fondo de inversión desde la fecha del fallecimiento.</p>`
        }}></div>
      </ResponsiveLayout>

      <ResponsiveLayout className={(selectedIndex===1 ? 'landingPage--content d-block' : 'd-none')}>
        <div className='html-content' dangerouslySetInnerHTML={{__html:
          `<p>Los partícipes de los Fondos de Inversión gozan de un régimen especial en el caso de traspaso. Este régimen permite aplazar el pago de impuestos si el importe obtenido del reembolso de las participaciones, se destina a la suscripción de otro fondo de inversión.</p>
           <p>En estos casos, la ganancia o pérdida patrimonial no computa y las nuevas participaciones suscritas conservan el valor y la fecha de adquisición de las participaciones transmitidas.</p>`
        }}></div>
      </ResponsiveLayout>

      <ResponsiveLayout className={(selectedIndex===2 ? 'landingPage--content d-block' : 'd-none')}>
        <div className='html-content' dangerouslySetInnerHTML={{__html:
          `<p>El partícipe de un fondo de inversión sólo tributa cuando realiza el reembolso de las participaciones. Dependiendo del resultado de la inversión, tributará como ganancia o pérdida patrimonial en la base imponible del ahorro en el IRPF.</p>
           <p>Según la cuantía de la ganancia, hay cuatro tipos de tributación para el ejercicio fiscal 2022:</p>
           <ul>
                <li>Hasta 6.000 euros tributa al 19%</li>
                <li>Entre 6.000 y 50.000 euros tributa al 21%</li>
                <li>Entre 50.000 y 200.000 euros tributa al 23%</li>
                <li>A partir de 200.000 euros tributa al 26%</li>
           </ul>
           <p>En el caso de pérdida patrimonial se podrá compensar con otras ganancias patrimoniales. Si el resultado sigue siendo negativo puede minorar la base imponible del ahorro con determinados límites.</p>`
        }}></div>
      </ResponsiveLayout>

      <ResponsiveLayout className='content landingPage--content' fullWidth>
        <ResponsiveLayout>

          <Box className='blueText--block' paddingY={screenSize.isDesktopOrBigger ? 80 : 24}>
            <Stack space={16} className='boxAlignCenter'>
              <Text6 color={skinVars.colors.brand}>“Los fondos de inversión solo tributan si hay ganancia patrimonial y a un tipo fijo.”</Text6>
            </Stack>
          </Box>

        </ResponsiveLayout>
      </ResponsiveLayout>

      <Hero
        background="default"
        media={
          <Image src={"/assets/img/ejercicio-fiscal-fondo-inversion.jpg"} aspectRatio={'7:10'} />
        }
        title="En Fonditel disponemos de una gama completa de fondos de inversión que cubren todos los perfiles de riesgo, para que puedas encontrar de manera fácil y rápida la combinación la combinación de ellos que mejor se adapte a tus necesidades en cada momento."
        desktopMediaPosition="right"
      />

      <ResponsiveLayout className='content' fullWidth>
        <MembersOf></MembersOf>
      </ResponsiveLayout>

    </LayoutPublic>
  );
};

export default FiscalExerciseInvestmentFundsPage;
