import { useIntl } from "react-intl";

import { closeDialog } from "../../utils/utils";
import { formalizeOperationKey } from "../../utils/apiclient";

import { ButtonLayout, ButtonPrimary, dialog, Form, FormValues, GridLayout, IconInformationUserLight, PasswordField, skinVars, Stack } from "@telefonica/mistica";

export const useFormalizeOperationKey = () => {

    const intl = useIntl();

    const handleSubmit= (values: FormValues, acceptText: string, onConfirm:() => void): Promise<void> =>
        new Promise((resolve) => {

            if(values.newKey !== values.newKeyRepeat){
                closeDialog().then(()=>{
                    dialog({
                      icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                      title: intl.formatMessage({id:'page_change_operations_key_operations_key_not_match'}),
                      message: '',
                      onAccept: ()=>{formalize(acceptText, ()=>{onConfirm()})},
                      acceptText: intl.formatMessage({id:'sign_operations_key_error_action_continue'})});

                    resolve();
                });
            }else{
                formalizeOperationKey(values).then((result)=>{
                    let title = intl.formatMessage({id:'sign_operations_key_error_incorrect_title'});
                    if(result.result === 'ok'){
                        closeDialog().then(() => {
                            onConfirm();
                        });

                        resolve();
                        return;

                    }else if(result.errors){
                        const errors = result.errors as any;
                        if(errors['operationsKey']){
                            switch(errors['operationsKey']){
                                case 'operations key too short':
                                    title = intl.formatMessage({id:'page_change_operations_key_password_too_short'});
                                    break;
                                case 'operations key too long':
                                    title = intl.formatMessage({id:'page_change_operations_key_password_too_long'});
                                    break;
                                case 'operations key too weak':
                                    title = intl.formatMessage({id:'page_change_operations_key_password_too_weak'});
                                    break;
                            }
                        }
                    }
                    closeDialog().then(()=>{
                        dialog({
                          icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                          title: title,
                          message: '',
                          onAccept: ()=>{formalize(acceptText, ()=>{onConfirm()})},
                          acceptText: intl.formatMessage({id:'sign_operations_key_error_action_continue'})});

                        resolve();
                    });
                }).finally(()=>{
                });
            }
        });

	const formalize = (acceptText:string, onConfirm:() => void) => {
        closeDialog().then(()=>{

            const showLoading=()=>{
                const buttonSubmit = document.querySelector('.button-sign-operations-key-submit') as any;
                const buttonLoading = document.querySelector('.button-sign-operations-key-loading') as any;
                document.getElementById('form-sign-operations-key')?.classList.add('loading');
                if(buttonSubmit){
                    buttonSubmit.style.display = 'none';
                }
                if(buttonLoading){
                    buttonLoading.style.display = 'block';
                }
            }

            dialog({
                title: intl.formatMessage({id:'sign_operations_key_dialog_title'}),
                message:'',
                extra: <>
                        <Form id="form-sign-operations-key" onSubmit={(data)=>{showLoading();handleSubmit(data, acceptText, onConfirm)}}>
                        <Stack space={16}>

                            <GridLayout
                                verticalSpace={16}
                                template={"6+6"}
                                left={
                                    <PasswordField fullWidth name="newKey" label={intl.formatMessage({id:'sign_operations_key_dialog_password_text'})}
                                    helperText= {intl.formatMessage({id:'page_change_operations_key_customer_confirm_new_key_helper_text'})}></PasswordField>
                                }
                                right={
                                    <PasswordField fullWidth name="newKeyRepeat" label={intl.formatMessage({id:'sign_operations_key_dialog_repeat_password_text'})}></PasswordField>
                                }
                            />
                            <ButtonLayout align='full-width'>
                                <ButtonPrimary className="button-sign-operations-key-submit"  submit>
                                    {acceptText}
                                </ButtonPrimary>
                                <ButtonPrimary className="button-sign-operations-key-loading" onPress={()=>{}} showSpinner={true} style={{display:'none'}}>
                                    {acceptText}
                                </ButtonPrimary>
                            </ButtonLayout>
                        </Stack>
                    </Form>
                </>,
                className:'dialog-without-buttons'
            });
        });

        return (<></>);
	}
	return { formalize };
};