import { Client } from '../entities/clients/client';
import { ListOption } from '../entities/commons/list-option.model';

import secureLocalStorage from "react-secure-storage";
import { ClientsLoginBody } from '../entities/clients/clients-login-body';
import { PostIt } from '../entities/clients/postit';
import { ExternalFund } from '../entities/commons/external-fund.model';
import { Option } from '../entities/commons/option.model';
import { Periodicity } from '../entities/commons/periodicity.model';
import { Contents } from '../entities/contents/contents.model';
import { Faq } from '../entities/contents/faq.model';
import { ForInvestor } from '../entities/contents/for-investor.model';
import { SuggestionSearch } from '../entities/contents/suggestion-search.model';
import { Contract } from '../entities/contract/contract';
import { Contribution } from '../entities/contract/contribution';
import { OperationContract } from '../entities/contract/operations/operation-contract';
import { OperationContractHolders } from '../entities/contract/operations/operation-contract-holders';
import { OperationContribution } from '../entities/contract/operations/operation-contribution';
import { OperationMobilization } from '../entities/contract/operations/operation-mobilization';
import { OperationRefund } from '../entities/contract/operations/operation-refund';
import { OperationSubscription } from '../entities/contract/operations/operation-subscription';
import { OperationTransfer } from '../entities/contract/operations/operation-transfer';
import { Rights } from '../entities/contract/rights';
import { GlobalPosition } from '../entities/globalPosition/globalPosition';
import { NewDetail } from '../entities/news/news-detail.model';
import { News } from '../entities/news/news.model';
import { TagNews } from '../entities/news/tag.model';
import { DocumentTypeCO } from '../entities/onlineCorrespondence/document-type.model';
import { OperationInProgress } from '../entities/operationsInProgress/operation-in-progress.model';
import { SignOperation } from '../entities/operationsInProgress/sign-operation.model';
import { Company } from '../entities/products/company.model';
import { NetAssetValue } from '../entities/products/net-asset-value';
import { PortfolioComposition } from '../entities/products/portfolio-composition';
import { Product } from '../entities/products/product';
import { ProductDocument } from '../entities/products/product-document';
import { UserProduct } from '../entities/products/userProduct';
import { ClosingData } from '../entities/promoters-control-commision/closing-data';
import { GlobalPositionControlCommision } from '../entities/promoters-control-commision/global-position-control-commision';
import { GlobalPositionPromoter } from '../entities/promoters-control-commision/global-position-promoter';
import { OtherQueriesPromoter } from '../entities/promoters-control-commision/other-queries';
import { OtherQueriesControlCommision } from '../entities/promoters-control-commision/other-queries-control-commision';
import { Transfers } from '../entities/promoters-control-commision/transfers';
import { TaxInformationFunds } from '../entities/taxInformation/tax-information-funds.model';
import { TaxInformation } from '../entities/taxInformation/tax-information.model';
import { extractPlanComisionControl, extractPlanPromotor, formatPlanPromotor, isNativeApp } from './utils';
import { SignState } from '../entities/contract/sign-state.model';

function getItem(key:string) : any{
    return isNativeApp ? localStorage.getItem(key) : secureLocalStorage.getItem(key);
}

function setItem(key:string, value: any): any{
    try{
        isNativeApp ? localStorage.setItem(key, value) : secureLocalStorage.setItem(key, value);
    }catch(err){

    }
}

function removeItem(key:string){
    try{
        isNativeApp ? localStorage.removeItem(key) : secureLocalStorage.removeItem(key);
    }catch(err){}
}

function getExpire(minutes?: number): string{
    if(!minutes){
        minutes = 12*60;
    }
    return (new Date().getTime() + 60*minutes*1000).toString();
}

export function deleteCache(){
    if(!isNativeApp){
        try{
            for(const [key, ] of Object.entries(Object.entries(secureLocalStorage)[0][1])){
                const keyStorage = key.replace(process.env.REACT_APP_SECURE_LOCAL_STORAGE_PREFIX+'.', '').replace('@secure.', '');
                removeItem(keyStorage);
            }
        }catch(err){}
    }
}

export const addUser = (client: Client) => {
    setItem('user', JSON.stringify(client));
};

export const getUser = () : Client => {
    const persistedState = getItem('user');
	return persistedState ? JSON.parse(persistedState) : null;
};

export const updatePostIts = (postits: PostIt[]) => {
    const user = getUser();
    if(user){
        user.postIts = postits;
        addUser(user);
    }
};

export const removeBanners = () => {
    const user = getUser();
    if(user){
        user.banner = undefined;
        addUser(user);
    }
};

export const selectRoleUser = (role: string) => {
    const user = getUser();
    if(user){
        user.selectedRole = role;
        addUser(user);
    }
};

export const selectPromotor = (idPromotor: string) => {
    const user = getUser();
    if(user){
        user.selectedPromotor = idPromotor;
        user.planesPromotor = user.promotores?.find(item=>item.idPromotor===idPromotor)?.planesPromotor ?? [];
        if(user.planesPromotor.length>0){
            user.selectedPlanPromotor = formatPlanPromotor(user.planesPromotor[0]);
        }
        addUser(user);
        return user;
    }
};


export const selectPlanPromotor = (planPromotor: string) => {
    const user = getUser();
    if(user){
        user.selectedPlanPromotor = planPromotor;
        addUser(user);
    }
};


export const getSelectedPlanPromotor = () => {
    const user = getUser();
    if(user && user.selectedPromotor && user.selectedPlanPromotor){

        user.planesPromotor = user.promotores?.find(item=>item.idPromotor===user.selectedPromotor)?.planesPromotor ?? [];
        let planPromotorSelected = extractPlanPromotor(user.selectedPlanPromotor);
        if(user.planesPromotor.length>0){
            user.planesPromotor.map((item, index)=>{
                if(user.selectedPlanPromotor === formatPlanPromotor(item)){
                    planPromotorSelected = item;
                }
            });
        }
        return planPromotorSelected;
    }
    return null;
};


export const selectPlanComisionControl = (planComisionControl: string) => {
    const user = getUser();
    if(user){
        user.selectedPlanComisionControl = planComisionControl;
        addUser(user);
    }
};


export const getSelectedPlanComisionControl= () => {
    const user = getUser();
    if(user && user.selectedPlanComisionControl){
        return extractPlanComisionControl(user.selectedPlanComisionControl);
    }
    return null;
};


export const removeUser = (logout:boolean=false) => {
    removeItem('user');
    removeItem('token');

    if(logout){
        removeFirstBiometricLogin();
        removeBiometricLogin();
        removeItem('first_biometric_login');
        removeItem('status_biometric_login');
        removeItem('login_saved');
    }


    removeDataUser();
};

export const removeDataUser = () =>{
    if(isNativeApp){

        try{
            for(const [key, ] of Object.entries(localStorage)){
                const keyStorage = key.replace(process.env.REACT_APP_SECURE_LOCAL_STORAGE_PREFIX+'.', '').replace('s.', '').replace('@secure.', '');
                if(keyStorage.indexOf('user_')===0){
                    removeItem(keyStorage);
                }
            }
        }catch(err){}

    }else{
        try{
            for(const [key, ] of Object.entries(Object.entries(secureLocalStorage)[0][1])){
                const keyStorage = key.replace(process.env.REACT_APP_SECURE_LOCAL_STORAGE_PREFIX+'.', '').replace('@secure.', '');
                if(keyStorage.indexOf('user_')===0){
                    removeItem(keyStorage);
                }
            }
        }catch(err){}
    }
}


export const isExpired = (key:string): boolean =>{
    const value  = getItem(key) as string|null;
    if(!value || parseInt(value)<new Date().getTime()){
        return true;
    }
    return false;
}
/* Version APP
========================================================= */
export const setVersionApp = (version:string) => {
    setItem("version_app", version);
};

export const getVersionApp = () : string|null => {
   return getItem('version_app') as string|null;
};

/* Token
========================================================= */
export const setToken = (token:string) => {
    setItem("token", token);
};

export const getToken = () : string|null => {
   return getItem('token') as string|null;
};

export const removeToken = () => {
   return removeItem('token');
};
/* OperationContract
========================================================= */
export const setOperationContract = (data: OperationContract) => {
    setItem('user_operation_contract', JSON.stringify(data));
};

export const removeOperationContract = () => {
    removeItem('user_operation_contract');
};

export const getOperationContract = () : OperationContract|null => {
	const persistedState = getItem("user_operation_contract") as string|null;
	return persistedState ? JSON.parse(persistedState) as OperationContract : null;
};


/* OperationContractHolders
========================================================= */
export const setOperationContractHolders = (data: OperationContractHolders) => {
    setItem('user_operation_contract_holders', JSON.stringify(data));
};

export const removeOperationContractHolders = () => {
    removeItem('user_operation_contract_holders');
};

export const getOperationContractHolders = () : OperationContractHolders|null => {
	const persistedState = getItem("user_operation_contract_holders") as string|null;
	return persistedState ? JSON.parse(persistedState) as OperationContractHolders : null;
};


/* OperationContribution
========================================================= */
export const setOperationContribution = (data: OperationContribution) => {
    setItem('user_operation_contribution', JSON.stringify(data));
};

export const removeOperationContribution = () => {
    removeItem('user_operation_contribution');
};

export const getOperationContribution = () : OperationContribution|null => {
	const persistedState = getItem("user_operation_contribution") as string|null;
	return persistedState ? JSON.parse(persistedState) as OperationContribution : null;
};

/* OperationMobilization
========================================================= */
export const setOperationMobilization = (data: OperationMobilization) => {
    setItem('user_operation_mobilization', JSON.stringify(data));
};

export const removeOperationMobilization = () => {
    removeItem('user_operation_mobilization');
};

export const getOperationMobilization = () : OperationMobilization|null => {
	const persistedState = getItem("user_operation_mobilization") as string|null;
	return persistedState ? JSON.parse(persistedState) as OperationMobilization : null;
};

/* OperationSubscription
========================================================= */
export const setOperationSubscription = (data: OperationSubscription) => {
    setItem('user_operation_subscription', JSON.stringify(data));
};

export const removeOperationSubscription = () => {
    removeItem('user_operation_subscription');
};

export const getOperationSubscription = () : OperationSubscription|null => {
	const persistedState = getItem("user_operation_subscription") as string|null;
	return persistedState ? JSON.parse(persistedState) as OperationSubscription : null;
};

/* OperationTransfer
========================================================= */
export const setOperationTransfer = (data: OperationTransfer) => {
    setItem('user_operation_transfer', JSON.stringify(data));
};

export const removeOperationTransfer = () => {
    removeItem('user_operation_transfer');
};

export const getOperationTransfer = () : OperationTransfer|null => {
	const persistedState = getItem("user_operation_transfer") as string|null;
	return persistedState ? JSON.parse(persistedState) as OperationTransfer : null;
};

/* OperationRefund
========================================================= */
export const setOperationRefund = (data: OperationRefund) => {
    setItem('user_operation_refund', JSON.stringify(data));
};

export const removeOperationRefund = () => {
    removeItem('user_operation_refund');
};

export const getOperationRefund = () : OperationRefund|null => {
	const persistedState = getItem("user_operation_refund") as string|null;
	return persistedState ? JSON.parse(persistedState) as OperationRefund : null;
};

/* signState
========================================================= */
export const setSignState = (data: SignState) => {
    setItem('user_sign_state', JSON.stringify(data));
};

export const removeSignState = () => {
    removeItem('user_sign_state');
};

export const getSignState= () : SignState|null => {
	const setSignState = getItem("user_sign_state") as string|null;
	return setSignState ? JSON.parse(setSignState) as SignState : null;
};


/* Alert
========================================================= */
/*export const setAlert = (alert_key:string) => {
    setItem("alert_key", alert_key);
};

export const getAlert = () : string|null => {
    const key = 'alert_key';
    const alertKey = getItem(key) as string|null;
    removeItem(key);
    return alertKey;
};*/


/* DeviceId
========================================================= */
export const setDeviceId = (deviceId: string) => {
    setItem('unique_device_id', deviceId);
};

export const getDeviceId = () : string => {
    const devicedId = getItem('unique_device_id') as string | '';
    return devicedId ? devicedId : '';
}

/* Mode maintenance
========================================================= */
export const setModeMaintenance = (maintenance?:boolean) => {
    if(maintenance){
        removeTextsVersion();
        setItem('mode_maintenance', "1");
    }else{
        deleteModeMaintenance();
    }
};

export const getModeMaintenance = () : boolean => {
    return getItem('mode_maintenance')!==null;
}

export const deleteModeMaintenance = () => {
    removeItem('mode_maintenance');
}

/* Mode callcenter
========================================================= */
export const setModeCallCenter = (data:any) => {
    setItem('mode_callcenter', JSON.stringify(data));
};

export const getModeCallCenter = () : any | null => {
    const value = getItem('mode_callcenter') as string|null;
    if(value){
        return JSON.parse(value) as any;
    }
    return null;
}

export const deleteModeCallCenter = () => {
    removeItem('mode_callcenter');
}

/* Texts
========================================================= */
export const setTextsCache = (data:any) => {
    setItem('translates_texts', JSON.stringify(data));
};
export const getTextsCache = () : any | null => {
    const value = getItem('translates_texts') as string|null;
    if(value){
        return JSON.parse(value) as any;
    }
    return null;
};

/* Text version
========================================================= */
export const setTextsVersion = (textsVersion: string) => {
    setItem('texts_version', textsVersion);
};

export const getTextsVersion = () : string => {
    const textsVersion = getItem('texts_version') as string | '';
    return textsVersion ? textsVersion : '';
}

export const removeTextsVersion = () => {
    removeItem('texts_version');
}

/* OnBoarding Init APP
========================================================= */
export const setFirstTimeApp = () => {
    setItem('first_time_app', "1");
};

export const getFirstTimeApp = () : boolean => {
    return getItem('first_time_app')===null;
}

/* Init App
========================================================= */


export const setFirstBiometricLogin = () => {
    setItem('first_biometric_login', '1' );
};

export const getFirstBiometricLogin = () : boolean => {
    return getItem('first_biometric_login')===null;
}
export const removeFirstBiometricLogin = () => {
    removeItem('first_biometric_login');
};

/* Biometric Login
========================================================= */
export const setBiometricLogin = (login: ClientsLoginBody) => {
    setItem('biometric_login', JSON.stringify(login) );
};

export const setLoginSaved = (login: ClientsLoginBody) => {
    setItem('login_saved', JSON.stringify(login) );
}

export const getLoginSaved = () : ClientsLoginBody|null => {
	const persistedState = getItem("login_saved") as string|null;
	return persistedState ? JSON.parse(persistedState) as ClientsLoginBody : null;
}

export const getBiometricLogin = () : ClientsLoginBody|null => {
	const persistedState = getItem("biometric_login") as string|null;
	return persistedState ? JSON.parse(persistedState) as ClientsLoginBody : null;
}

export const removeBiometricLogin = () => {
    removeItem('biometric_login');
}

/* Enabled Biometric Login
========================================================= */
export const setStatusBiometricLogin = (status:boolean) => {
    setItem('status_biometric_login', status );
};

export const getStatusBiometricLogin = () : boolean|null => {
    return getItem('status_biometric_login') as boolean|null;
}

/* OnBoarding First Login
========================================================= */
export const setFirstLoginApp = () => {
    setItem('first_time_login', 1);
};

export const getFirstLoginApp = () : boolean => {
    return getItem('first_time_login')===null;
}

/* Alert Status Sign
========================================================= */
export const setAlertStatusSign = () => {
    setItem('user_alert_status_sign', 1);
};

export const getAlertStatusSign = () : boolean => {
    return getItem('user_alert_status_sign')===null;
}

/* Alert Expired IdCard
========================================================= */
export const setAlertExpiredIdCard = () => {
    setItem('user_alert_expired_id_card', 1);
};

export const getAlertExpiredIdCard = () : boolean => {
    return getItem('user_alert_expired_id_card')!==null;
}

export const removeAlertExpiredIdCard = () => {
    removeItem('user_alert_expired_id_card');
}

/* Register
========================================================= */
export const setNameRegister = (name?:string) => {
    const key = 'name_register';
    if(name){
        setItem(key, name);
    }else{
        removeItem(key);
    }
};

export const getNameRegister = () : string|null => {
   const name = getItem('name_register') as string|null;
   setNameRegister();
   return name;
};

/* Periodicity
========================================================= */
export const setPeriodicitiesCache = (option:string, options:Periodicity[], language:string) => {
    setItem(option+"_"+language, JSON.stringify(options));
    setItem(option+"_"+language+"_expire", getExpire());
};

export const getPeriodicitiesCache = (option:string, language:string) : Periodicity[] => {
    let items : Periodicity[] = [];

    if(!(isExpired(option+"_"+language+"_expire"))){
        const value = getItem(option+"_"+language) as string|null;
        if(value){
            items = JSON.parse(value)
        }
    }
    return items;
};

/* External Funds
========================================================= */
export const setExternalFundsCache = (option:string, options:ExternalFund[], language:string) => {
    setItem(option+"_"+language, JSON.stringify(options));
    setItem(option+"_"+language+"_expire", getExpire());
};

export const getExternalFundsCache = (option:string, language:string) : ExternalFund[] => {
    let items : ExternalFund[] = [];

    if(!(isExpired(option+"_"+language+"_expire"))){
        const value = getItem(option+"_"+language) as string|null;
        if(value){
            items = JSON.parse(value)
        }
    }
    return items;
};

/* Option
========================================================= */
export const setOptionsCache = (option:string, options:Option[], language:string) => {
    setItem(option+"_"+language, JSON.stringify(options));
    setItem(option+"_"+language+"_expire", getExpire());
};

export const getOptionsCache = (option:string, language:string) : Option[] => {
    let items : Option[] = [];

    if(!(isExpired(option+"_"+language+"_expire"))){
        const value = getItem(option+"_"+language) as string|null;
        if(value){
            items = JSON.parse(value)
        }
    }
    return items;
};


/* List option
========================================================= */
export const setListOptionsCache = (option:string, options:ListOption[], language:string) => {
    setItem(option+"_"+language, JSON.stringify(options));
    setItem(option+"_"+language+"_expire", getExpire());
};

export const getListOptionsCache = (option:string, language:string) : ListOption[] => {
    let items : ListOption[] = [];

    if(!(isExpired(option+"_"+language+"_expire"))){
        const value = getItem(option+"_"+language) as string|null;
        if(value){
            items = JSON.parse(value)
        }
    }
    return items;
};



/* Contents
========================================================= */
export const setInitContentCache = (data:Contents, lang:string) => {
    setItem('contents_data_lang_'+lang, JSON.stringify(data));
    setItem('contents_data_expire_lang_'+lang, getExpire(60));
};

export const getInitContentCache = (lang:string) : Contents | null => {
    if(!(isExpired('contents_data_expire_lang_'+lang)) && getTextsCache()){
        const value = getItem('contents_data_lang_'+lang) as string|null;
        if(value){
            return JSON.parse(value) as Contents;
        }
    }
    return null;
};

/* Search suggestions
========================================================= */
export const setSuggestionsSearchCache = (data:SuggestionSearch[], key:string) => {
    setItem('suggestions_search_'+key, JSON.stringify(data));
    setItem('suggestions_search_expire_'+key, getExpire(120));
};

export const getSuggestionsSearchCache = (key:string) : SuggestionSearch[] | null => {
    if(!(isExpired('suggestions_search_expire_'+key))){
        const value = getItem('suggestions_search_'+key) as string|null;
        if(value){
            return JSON.parse(value) as SuggestionSearch[];
        }
    }
    return null;
};



/* Search words
========================================================= */
export const setWordsSearchCache = (data:string[], key:string) => {
    setItem('words_search_'+key, JSON.stringify(data));
    setItem('words_search_expire_'+key, getExpire(120));
};

export const getWordsSearchCache = (key:string) : string[] | null => {
    if(!(isExpired('words_search_expire_'+key))){
        const value = getItem('words_search_'+key) as string|null;
        if(value){
            return JSON.parse(value) as string[];
        }
    }
    return null;
};

/* Faqs
========================================================= */
export const setFaqsCache = (data:Faq[], key:string) => {
    setItem('faqs_'+key, JSON.stringify(data));
    setItem('faqs_expire_'+key, getExpire(60));
};

export const getFaqsCache = (key:string) : Faq[] | null => {
    if(!(isExpired('faqs_expire_'+key))){
        const value = getItem('faqs_'+key) as string|null;
        if(value){
            return JSON.parse(value) as Faq[];
        }
    }
    return null;
};

/* ForInvestor
========================================================= */
export const setForInvestorCache = (data:ForInvestor, key:string) => {
    setItem('for_investor_data_'+key, JSON.stringify(data));
    setItem('for_investor_data_expire_'+key, getExpire(60));
};

export const getForInvestorCache = (key:string) : ForInvestor | null => {
    if(!(isExpired('for_investor_data_expire_'+key))){
        const value = getItem('for_investor_data_'+key) as string|null;
        if(value){
            return JSON.parse(value) as ForInvestor;
        }
    }
    return null;
};

/* Pending action
========================================================= */
export const setPendingAction = (data: string) => {
    setItem('user_pending_action', data);
};

export const getPendingAction = () : string | null => {
    const value = getItem('user_pending_action') as string|null;
    return value;
};

export const removePendingAction = () :void => {
    removeItem('user_pending_action');
};

/* Number Advises
========================================================= */
export const setNumberAdvisesCache = (data: { result: number } | null) => {
    if(data){
        setItem('user_number_advises', JSON.stringify(data));
        setItem('user_number_advises_expire', getExpire(60));
    }else{
        removeItem('user_number_advises');
        removeItem('user_number_advises_expire');
    }
};

export const getNumberAdvisesCache = (expired:boolean=true) : { result: number } | null => {
    if(!expired || !(isExpired('user_number_advises_expire'))){
        const value = getItem('user_number_advises') as string|null;
        if(value){
            return JSON.parse(value) as { result: number };
        }
    }
    return null;
};


/* Course Operations
========================================================= */
export const setNumberCourseOperationsCache = (data: { result: number }) => {
    setItem('user_number_course_operations', JSON.stringify(data));
    setItem('user_number_course_operations_expire', getExpire(5));
};

export const getNumberCourseOperationsCache = (expired:boolean=true) : { result: number } | null => {
    if(!expired || !(isExpired('user_number_course_operations_expire'))){
        const value = getItem('user_number_course_operations') as string|null;
        if(value){
            return JSON.parse(value) as { result: number };
        }
    }
    return null;
};
export const removeNumberCourseOperationsCache = () => {
    let numberCourseOperations = getNumberCourseOperationsCache();;
    if(numberCourseOperations && numberCourseOperations.result){
        numberCourseOperations.result--;
    }else{
        numberCourseOperations = {result: 0};
    }
    setNumberCourseOperationsCache(numberCourseOperations);
};
export const addNumberCourseOperationsCache = () => {
    let numberCourseOperations = getNumberCourseOperationsCache();;
    if(numberCourseOperations && numberCourseOperations.result){
        numberCourseOperations.result++;
    }else{
        numberCourseOperations = {result: 1};
    }
    setNumberCourseOperationsCache(numberCourseOperations);
};

export const setOperationInProgress = (key: string, operation: OperationInProgress) => {
    setItem(key, JSON.stringify(operation));
    setItem(key+'_expire', getExpire());
};

export const getOperationInProgress = (key: string) : OperationInProgress | null => {
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as OperationInProgress;
        }
    }
    return null;
}
export const removeOperationInProgress = () => {
    removeItem('user_operation_in_progress');
    removeItem('user_newcontract_in_progress');
};


/* Current product to contract
========================================================= */
export const setCurrentProductToContract = (data: Product) => {
    setItem('user_current_product_to_contract', JSON.stringify(data));
};

export const getCurrentProductToContract = () : Product | null => {
    const value = getItem('user_current_product_to_contract') as string|null;
    if(value){
        return JSON.parse(value) as Product;
    }
    return null;
};


/* Current product
========================================================= */
export const setCurrentProduct = (data: Product) => {
    setItem('user_current_product', JSON.stringify(data));
};

export const getCurrentProduct = () : Product | null => {
    const value = getItem('user_current_product') as string|null;
    if(value){
        return JSON.parse(value) as Product;
    }
    return null;
};

/* Product to contract
========================================================= */
export const setProductToContract = (data: Product) => {
    const key='product_to_contract';
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(60));
};
export const getProductToContract = () : Product | null => {
    const key='product_to_contract';
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as Product;
        }
    }
    return null;
};
export const removeProductToContract = () => {
    removeItem('product_to_contract');
};

/* Continue to contract
========================================================= */
export const setContinueToContract = () => {
    setItem('user_continue_to_contract', '1' );
};

export const getContinueToContract = () : boolean => {
    return getItem('user_continue_to_contract')!==null;
}
export const removeContinueToContract = () => {
    removeItem('user_continue_to_contract');
};


/* Current user product
========================================================= */
export const setCurrentUserProduct = (data: UserProduct) => {
    setItem('user_current_user_product', JSON.stringify(data));
};
export const getCurrentUserProduct = () : UserProduct | null => {
    const value = getItem('user_current_user_product') as string|null;
    if(value){
        return JSON.parse(value) as UserProduct;
    }
    return null;
};
export const removeCurrentUserProduct = () => {
    removeItem('user_current_user_product');
};

/* Current contract user
========================================================= */
export const setCurrentUserData = (data: Client) => {
    setItem('user_current_user_data', JSON.stringify(data));
};
export const getCurrentUserData = () : Client | null => {
    const value = getItem('user_current_user_data') as string|null;
    if(value){
        return JSON.parse(value) as Client;
    }
    return null;
};
export const removeCurrentUserData = () => {
    removeItem('user_current_user_data');
};

/* Current contract user
========================================================= */
export const setCurrentUserContract = (data: Contract) => {
    setItem('user_current_user_contract', JSON.stringify(data));
};
export const getCurrentUserContract = () : Contract | null => {
    const value = getItem('user_current_user_contract') as string|null;
    if(value){
        return JSON.parse(value) as Contract;
    }
    return null;
};
export const removeCurrentUserContract = () => {
    removeItem('user_current_user_contract');
};




/* Current contract url
========================================================= */
export const setContractUrl = (data: string) => {
    setItem('user_contract_url', data);
};
export const getContractUrl = () : string | null => {
    const value = getItem('user_contract_url') as string|null;
    if(value){
        return value;
    }
    return null;
};
export const removeContractUrl = () => {
    removeItem('user_contract_url');
};



export const setSignOperationInProgress = (data: SignOperation) => {
    setItem('user_sign_operation_in_progress', JSON.stringify(data));
    setItem('user_sign_operation_in_progress_expire', getExpire());
};

export const getSignOperationInProgress = () : SignOperation | null => {
    if(!(isExpired('user_sign_operation_in_progress_expire'))){
        const value = getItem('user_sign_operation_in_progress') as string|null;
        if(value){
            return JSON.parse(value) as SignOperation;
        }
    }
    return null;
};

export const removeSignOperationInProgress = () => {
    removeItem('user_sign_operation_in_progress');
};

export const setReasonDeleteOperationInProgress = (data: string[]) => {
    setItem('user_reason_delete_operation_in_progress', JSON.stringify(data));
    setItem('user_reason_delete_operation_in_progress_expire', getExpire());
};

export const getReasonDeleteOperationInProgress = () : string[] | null => {
    if(!(isExpired('user_reason_delete_operation_in_progress_expire'))){
        const value = getItem('user_reason_delete_operation_in_progress') as string|null;
        if(value){
            return JSON.parse(value) as string[];
        }
    }
    return null;
};

export const removeReasonDeleteOperationInProgress = () => {
    removeItem('user_reason_delete_operation_in_progress');
};

/* Operation key
========================================================= */

export const setOperationKey = (operationKey: string) => {
    setItem('user_operation_key', JSON.stringify(operationKey));
};

export const getOperationKey = () : string | null => {
    const value = getItem('user_operation_key') as string|null;
    if(value){
        return JSON.parse(value) as string;
    }
    return null;
};

export const removeOperationKey = () => {
    removeItem('user_operation_key');
};

/* Operation
========================================================= */
export const setTokenSign = (operationKey: string) => {
    setItem('user_token_sign', JSON.stringify(operationKey));
};

export const getTokenSign = () : string | null => {
    const value = getItem('user_token_sign') as string|null;
    if(value){
        return JSON.parse(value) as string;
    }
    return null;
};

export const removeTokenSign = () => {
    removeItem('user_token_sign');
};

/* Global position promoter
========================================================= */
export const setGlobalPositionPromoterCache = (data:GlobalPositionPromoter, key:string) => {
    setItem('user_pro_global_position_'+key, JSON.stringify(data));
    setItem('user_pro_global_position_'+key+'_expire', getExpire(60));
};

export const getGlobalPositionPromoterCache = (key:string) : GlobalPositionPromoter | null => {
    if(!(isExpired('user_pro_global_position_'+key+'_expire'))){
        const value = getItem('user_pro_global_position_'+key) as string|null;
        if(value){
            return JSON.parse(value) as GlobalPositionPromoter;
        }
    }
    return null;
};

/* Documents promoter
========================================================= */
export const setDocumentsPromoterCache = (data:ProductDocument[], key:string) => {
    setItem('user_pro_documents_'+key, JSON.stringify(data));
    setItem('user_pro_documents_'+key+'_expire', getExpire(60));
};

export const getDocumentsPromoterCache = (key:string) : ProductDocument[] | null => {
    if(!(isExpired('user_pro_documents_'+key+'_expire'))){
        const value = getItem('user_pro_documents_'+key) as string|null;
        if(value){
            return JSON.parse(value) as ProductDocument[];
        }
    }
    return null;
};

/* Other queries promoter
========================================================= */
export const setOtherQueriesPromotersCache = (data:OtherQueriesPromoter, key:string) => {
    setItem('user_pro_other_queries_'+key, JSON.stringify(data));
    setItem('user_pro_other_queries_'+key+'_expire', getExpire(60));
};

export const getOtherQueriesPromotersCache = (key:string) : OtherQueriesPromoter | null => {
    if(!(isExpired('user_pro_other_queries_'+key+'_expire'))){
        const value = getItem('user_pro_other_queries_'+key) as string|null;
        if(value){
            return JSON.parse(value) as OtherQueriesPromoter;
        }
    }
    return null;
};

/* Other queries: Participants Variation Beneficiaries
========================================================= */
export const setParticipantsVariationBeneficiariesCache = (data:ClosingData, key:string) => {
    setItem('user_participants_variation_beneficiaries_'+key, JSON.stringify(data));
    setItem('user_participants_variation_beneficiaries_'+key+'_expire', getExpire(60));
};

export const getParticipantsVariationBeneficiariesCache = (key:string) : ClosingData | null => {
    if(!(isExpired('user_participants_variation_beneficiaries_'+key+'_expire'))){
        const value = getItem('user_participants_variation_beneficiaries_'+key) as string|null;
        if(value){
            return JSON.parse(value) as ClosingData;
        }
    }
    return null;
};



/* Other queries: Participants Variation Beneficiaries
========================================================= */
export const setConsolidatedRightsValueCache = (data:Rights, key:string) => {
    setItem('user_consolidated_rights_value_'+key, JSON.stringify(data));
    setItem('user_consolidated_rights_value_'+key+'_expire', getExpire(60));
};

export const getConsolidatedRightsValueCache = (key:string) : Rights | null => {
    if(!(isExpired('user_consolidated_rights_value_'+key+'_expire'))){
        const value = getItem('user_consolidated_rights_value_'+key) as string|null;
        if(value){
            return JSON.parse(value) as Rights;
        }
    }
    return null;
};


/* Other queries: Totals Contributions
========================================================= */
export const setTotalsContributionsCache = (data:Contribution, key:string) => {
    setItem('user_totals_contributions_value_'+key, JSON.stringify(data));
    setItem('user_totals_contributions_value_'+key+'_expire', getExpire(60));
};

export const getTotalsContributionsCache = (key:string) : Contribution | null => {
    if(!(isExpired('user_totals_contributions_value_'+key+'_expire'))){
        const value = getItem('user_totals_contributions_value_'+key) as string|null;
        if(value){
            return JSON.parse(value) as Contribution;
        }
    }
    return null;
};


/* Other queries: Payment Total Benefits
========================================================= */
export const setPaymentTotalBenefitsCache = (data:Contribution, key:string) => {
    setItem('user_payment_total_benefits_value_'+key, JSON.stringify(data));
    setItem('user_payment_total_benefits_value_'+key+'_expire', getExpire(60));
};

export const getPaymentTotalBenefitsCache = (key:string) : Contribution | null => {
    if(!(isExpired('user_payment_total_benefits_value_'+key+'_expire'))){
        const value = getItem('user_payment_total_benefits_value_'+key) as string|null;
        if(value){
            return JSON.parse(value) as Contribution;
        }
    }
    return null;
};


/* Other queries control commision: Transfers
========================================================= */
export const setTransfersCache = (data:Transfers, key:string) => {
    setItem('user_transfers_value_'+key, JSON.stringify(data));
    setItem('user_transfers_value_'+key+'_expire', getExpire(60));
};

export const getTransfersCache = (key:string) : Transfers | null => {
    if(!(isExpired('user_transfers_value_'+key+'_expire'))){
        const value = getItem('user_transfers_value_'+key) as string|null;
        if(value){
            return JSON.parse(value) as Transfers;
        }
    }
    return null;
};


/* Other queries promoter: Payment Total Benefits
========================================================= */
export const setContributionsQueryPromoterCache = (data:Array<Contribution>, key:string) => {
    setItem('user_pro_contributions_query_value_'+key, JSON.stringify(data));
    setItem('user_pro_contributions_query_value_'+key+'_expire', getExpire(60));
};

export const getContributionsQueryPromoterCache = (key:string) : Array<Contribution> | null => {
    if(!(isExpired('user_pro_contributions_query_value_'+key+'_expire'))){
        const value = getItem('user_pro_contributions_query_value_'+key) as string|null;
        if(value){
            return JSON.parse(value) as Array<Contribution>;
        }
    }
    return null;
};




/* Global control commision
========================================================= */
export const setGlobalPositionControlCommisionCache = (data:GlobalPositionControlCommision, key:string) => {
    setItem('user_cc_global_position_'+key, JSON.stringify(data));
    setItem('user_cc_global_position_'+key+'_expire', getExpire(60));
};

export const getGlobalPositionControlCommisionCache = (key:string) : GlobalPositionControlCommision | null => {
    if(!(isExpired('user_cc_global_position_'+key+'_expire'))){
        const value = getItem('user_cc_global_position_'+key) as string|null;
        if(value){
            return JSON.parse(value) as GlobalPositionControlCommision;
        }
    }
    return null;
};

/* Documents ControlCommision
========================================================= */
export const setDocumentsControlCommisionCache = (data:ProductDocument[], key:string) => {
    setItem('user_cc_documents_'+key, JSON.stringify(data));
    setItem('user_cc_documents_'+key+'_expire', getExpire(60));
};

export const getDocumentsControlCommisionCache = (key:string) : ProductDocument[] | null => {
    if(!(isExpired('user_cc_documents_'+key+'_expire'))){
        const value = getItem('user_cc_documents_'+key) as string|null;
        if(value){
            return JSON.parse(value) as ProductDocument[];
        }
    }
    return null;
};



/* Other queries ControlCommision
========================================================= */
export const setOtherQueriesControlCommisionCache = (data:OtherQueriesControlCommision, key:string) => {
    setItem('user_cc_other_queries_'+key, JSON.stringify(data));
    setItem('user_cc_other_queries_'+key+'_expire', getExpire(60));
};

export const getOtherQueriesControlCommisionCache = (key:string) : OtherQueriesControlCommision | null => {
    if(!(isExpired('user_cc_other_queries_'+key+'_expire'))){
        const value = getItem('user_cc_other_queries_'+key) as string|null;
        if(value){
            return JSON.parse(value) as OtherQueriesControlCommision;
        }
    }
    return null;
};

/* Global position
========================================================= */
export const setGlobalPositionCache = (data:GlobalPosition, key:string) => {
    setItem('user_global_position_'+key, JSON.stringify(data));
    setItem('user_global_position_expire', getExpire(60));
};

export const getGlobalPositionCache = (key:string) : GlobalPosition | null => {
    if(!(isExpired('user_global_position_expire'))){
        const value = getItem('user_global_position_'+key) as string|null;
        if(value){
            return JSON.parse(value) as GlobalPosition;
        }
    }
    return null;
};

/* Plans
========================================================= */
export const setPlansCache = (data: UserProduct[]) => {
    setItem('user_contract_plans', JSON.stringify(data));
    setItem('user_contract_plans_expire', getExpire(60));
};

export const getPlansCache = () : UserProduct[] | null => {
    if(!(isExpired('user_contract_plans_expire'))){
        const value = getItem('user_contract_plans') as string|null;
        if(value){
            return JSON.parse(value) as UserProduct[];
        }
    }
    return null;
};


export const setPlansNotContractedCache = (data: Product[]) => {
    setItem('user_not_contract_plans', JSON.stringify(data));
    setItem('user_not_contract_plans_expire', getExpire(60));
};

export const getPlansNotContractedCache = () : Product[] | null => {
    if(!(isExpired('user_not_contract_plans_expire'))){
        const value = getItem('user_not_contract_plans') as string|null;
        if(value){
            return JSON.parse(value) as Product[];
        }
    }
    return null;
};

export const removePlansNotContractedCache = () => {
    removeItem('user_not_contract_plans');
    removeItem('user_not_contract_plans_expire');
};

/* Investment funds
========================================================= */
export const setInvestmentFundsCache = (data: UserProduct[]) => {
    setItem('user_contract_investment_funds', JSON.stringify(data));
    setItem('user_contract_investment_funds_expire', getExpire(60));
};

export const getInvestmentFundsCache = () : UserProduct[] | null => {
    if(!(isExpired('user_contract_investment_funds_expire'))){
        const value = getItem('user_contract_investment_funds') as string|null;
        if(value){
            return JSON.parse(value) as UserProduct[];
        }
    }
    return null;
};


export const setInvestmentFundsNotContractedCache = (data: Product[]) => {
    setItem('user_not_contract_investment_funds', JSON.stringify(data));
    setItem('user_not_contract_investment_funds_expire', getExpire(60));
};

export const getInvestmentFundsNotContractedCache = () : Product[] | null => {
    if(!(isExpired('user_not_contract_investment_funds_expire'))){
        const value = getItem('user_not_contract_investment_funds') as string|null;
        if(value){
            return JSON.parse(value) as Product[];
        }
    }
    return null;
};

export const removeInvestmentFundsNotContractedCache = () => {
    removeItem('user_not_contract_investment_funds');
    removeItem('user_not_contract_investment_funds_expire');
};

/* Other products
========================================================= */
export const setOtherProductsCache = (data: UserProduct[]) => {
    setItem('user_contract_other_products', JSON.stringify(data));
    setItem('user_contract_other_products_expire', getExpire(10));
};

export const getOtherProductsCache = () : UserProduct[] | null => {
    if(!(isExpired('user_contract_other_products_expire'))){
        const value = getItem('user_contract_other_products') as string|null;
        if(value){
            return JSON.parse(value) as UserProduct[];
        }
    }
    return null;
};

/* Products
========================================================= */
export const setProductsCache = (data: Product[], cdCompany:number) => {
    const key = 'products_cdCompany'+cdCompany;
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(60));
};

export const getProductsCache = (cdCompany:number) : Product[] | null => {
    const key = 'products_cdCompany'+cdCompany;
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as Product[];
        }
    }
    return null;
};

/* Info product
========================================================= */
export const setInfoProductCache = (data: Product, cdCompany:number, cdPlan:string, cdFund:string, cdSubplan:string, lang:string, slug:string) => {
    const key = 'info_product_cdCompany_'+cdCompany+'_cdPlan_'+cdPlan+'_cdFund_'+cdFund+'_cdSubplan_'+cdSubplan+'_lang_'+lang+'_slug_'+slug;
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(60));
};

export const getInfoProductCache = (cdCompany:number, cdPlan:string, cdFund:string, cdSubplan:string, lang:string, slug:string) : Product | null => {
    const key = 'info_product_cdCompany_'+cdCompany+'_cdPlan_'+cdPlan+'_cdFund_'+cdFund+'_cdSubplan_'+cdSubplan+'_lang_'+lang+'_slug_'+slug;
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as Product;
        }
    }
    return null;
};


/* News page
========================================================= */
export const setNewsPageCache = (data: {news: News[], tags:TagNews[], newsMostRead: News[]}, key:string) => {
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(10));
};

export const getNewsPageCache = (key:string) : {news: News[], tags:TagNews[], newsMostRead: News[]} | null => {
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as {news: News[], tags:TagNews[], newsMostRead: News[]};
        }
    }
    return null;
};


/* News
========================================================= */
export const setNewsCache = (data: News[], key:string) => {
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(10));
};

export const getNewsCache = (key:string) : News[] | null => {
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as News[];
        }
    }
    return null;
};


/* News by slug
========================================================= */
export const setNewBySlugCache = (data: NewDetail, key:string) => {
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(10));
};

export const getNewBySlugCache = (key:string) : NewDetail | null => {
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as NewDetail;
        }
    }
    return null;
};


/* News Most Read
========================================================= */
export const setNewsMostReadCache = (data: News[], lang:string) => {
    const key = 'news_most_read_'+lang;
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(60));
};

export const getNewsMostReadCache = (lang:string) : News[] | null => {
    const key = 'news_most_read_'+lang;
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as News[];
        }
    }
    return null;
};




/* Info contract
========================================================= */
export const setInfoContractCache = (data: Contract, numContract:string, cdCompany:number, cdPlan:string, cdFund:string, cdSubplan:string, lang:string) => {
    const key = 'user_info_contract_numContract_'+numContract+'_cdCompany_'+cdCompany+'_cdPlan_'+cdPlan+'_cdFund_'+cdFund+'_cdSubplan_'+cdSubplan+'_lang_'+lang;
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(60));
};

export const getInfoContractCache = (numContract:string, cdCompany:number, cdPlan:string, cdFund:string, cdSubplan:string, lang:string) : Contract | null => {
    const key = 'user_info_contract_numContract_'+numContract+'_cdCompany_'+cdCompany+'_cdPlan_'+cdPlan+'_cdFund_'+cdFund+'_cdSubplan_'+cdSubplan+'_lang_'+lang;
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as Contract;
        }
    }
    return null;
};


/* Profitability Percentage
========================================================= */
export const setProfitabilityPercentageCache = (data: { result: number }, cdCompany:number, cdPlan:string, cdFund:string, cdSubplan:string, dateFrom:string, dateTo:string) => {
    const key = 'profitability_percentage_product_cdCompany_'+cdCompany+'_cdPlan_'+cdPlan+'_cdFund_'+cdFund+'_cdSubplan_'+cdSubplan+'_dateFrom_'+dateFrom+'_dateTo_'+dateTo;
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(60));
};

export const getProfitabilityPercentageCache = (cdCompany:number, cdPlan:string, cdFund:string, cdSubplan:string, dateFrom:string, dateTo:string) : { result: number } | null => {
    const key = 'profitability_percentage_product_cdCompany_'+cdCompany+'_cdPlan_'+cdPlan+'_cdFund_'+cdFund+'_cdSubplan_'+cdSubplan+'_dateFrom_'+dateFrom+'_dateTo_'+dateTo;
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as { result: number };
        }
    }
    return null;
};

/* Net Asset Value
========================================================= */
export const setNetAssetValueCache = (data: Array<NetAssetValue>, cdCompany:number, cdPlan:string, cdFund:string, cdSubplan:string, dateFrom:string, dateTo:string) => {
    const key = 'net_asset_value_product_cdCompany_'+cdCompany+'_cdPlan_'+cdPlan+'_cdFund_'+cdFund+'_cdSubplan_'+cdSubplan+'_dateFrom_'+dateFrom+'_dateTo_'+dateTo;
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(60));
};

export const getNetAssetValueCache = (cdCompany:number, cdPlan:string, cdFund:string, cdSubplan:string, dateFrom:string, dateTo:string) : Array<NetAssetValue> | null => {
    const key = 'net_asset_value_product_cdCompany_'+cdCompany+'_cdPlan_'+cdPlan+'_cdFund_'+cdFund+'_cdSubplan_'+cdSubplan+'_dateFrom_'+dateFrom+'_dateTo_'+dateTo;
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as Array<NetAssetValue>;
        }
    }
    return null;
};

/* Wallet Composition
========================================================= */
export const setWalletCompositionProductCache = (data: Array<PortfolioComposition>, cdCompany:number, cdPlan:string, cdFund:string, cdSubplan:string, date:string, lang:string) => {
    const key = 'wallet_composition_product_cdCompany_'+cdCompany+'_cdPlan_'+cdPlan+'_cdFund_'+cdFund+'_cdSubplan_'+cdSubplan+'_date_'+date+'_lang_'+lang;
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(60));
};

export const getWalletCompositionProductCache = (cdCompany:number, cdPlan:string, cdFund:string, cdSubplan:string, date:string, lang:string) : Array<PortfolioComposition> | null => {
    const key = 'wallet_composition_product_cdCompany_'+cdCompany+'_cdPlan_'+cdPlan+'_cdFund_'+cdFund+'_cdSubplan_'+cdSubplan+'_date_'+date+'_lang_'+lang;
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as Array<PortfolioComposition>;
        }
    }
    return null;
};

/* Plans Fiscal Information
========================================================= */
export const setPlanFiscalInformationCache = (data: TaxInformation[]) => {
    setItem('user_plans_fiscal_information', JSON.stringify(data));
    setItem('user_plans_fiscal_information_expire', getExpire(60));
};

export const getPlanFiscalInformationCache = () : TaxInformation[] | null => {
    if(!(isExpired('user_plans_fiscal_information_expire'))){
        const value = getItem('user_plans_fiscal_information') as string|null;
        if(value){
            return JSON.parse(value) as TaxInformation[];
        }
    }
    return null;
};


/* Funds Fiscal Information
========================================================= */
export const setFundFiscalInformationCache = (data: TaxInformationFunds[]) => {
    setItem('user_funds_fiscal_information', JSON.stringify(data));
    setItem('user_funds_fiscal_information_expire', getExpire(60));
};

export const getFundFiscalInformationCache = () : TaxInformationFunds[] | null => {
    if(!(isExpired('user_funds_fiscal_information_expire'))){
        const value = getItem('user_funds_fiscal_information') as string|null;
        if(value){
            return JSON.parse(value) as TaxInformationFunds[];
        }
    }
    return null;
};



/* Online correspondence
========================================================= */
export const setDocumentsTypeCompaniesOnlineCorrespondenceCache = (lang:string, data: Company[]) => {
    const key = 'user_companies_online_correspondence_lang_'+lang;
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(60));
};

export const getDocumentsTypeCompaniesOnlineCorrespondenceCache = (lang:string) : Company[] | null => {
    const key = 'user_companies_online_correspondence_lang_'+lang;
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as Company[];
        }
    }
    return null;
};

export const setDocumentsTypeOnlineCorrespondenceCache = (lang:string, cdCompany:number, data: DocumentTypeCO[]) => {
    const key = 'user_document_type_online_correspondence_lang'+lang+'_cdCompany_'+cdCompany;
    setItem(key, JSON.stringify(data));
    setItem(key+'_expire', getExpire(60));
};

export const getDocumentsTypeOnlineCorrespondenceCache = (lang:string, cdCompany:number) : DocumentTypeCO[] | null => {
    const key = 'user_document_type_online_correspondence_lang'+lang+'_cdCompany_'+cdCompany;
    if(!(isExpired(key+'_expire'))){
        const value = getItem(key) as string|null;
        if(value){
            return JSON.parse(value) as DocumentTypeCO[];
        }
    }
    return null;
};