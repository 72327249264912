import { useIonRouter } from '@ionic/react';
import { Box, ButtonLayout, ButtonSecondary, Carousel, IconSearchFileRegular, Inline, ResponsiveLayout, SkeletonLine, SkeletonRectangle, SkeletonText, skinVars, Text4, Text5, useScreenSize, useWindowSize } from '@telefonica/mistica';
import { resolve } from 'dns';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import ButtonTertiary from '../../../../../components/buttons/ButtonTertiary';
import EmptyCard from '../../../../../components/empty-card/EmptyCard';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import { useProductActions } from '../../../../../components/operate/ProductActions';
import { useValidOperate } from '../../../../../components/operate/ValidOperate';
import ProductCard from '../../../../../components/product/ProductCard';
import { Product } from '../../../../../entities/products/product';
import { UserProduct } from '../../../../../entities/products/userProduct';
import { getInvestmentFundsNotContracted, getPlansNotContracted } from '../../../../../utils/apiclient';
import { getUrlNavigation } from '../../../../../utils/navigation';
import { removeCurrentUserContract, removeCurrentUserData, removeCurrentUserProduct, setCurrentProduct, setCurrentProductToContract, setCurrentUserProduct } from '../../../../../utils/storage';
import { formatNetAssetValue, formatBalance, formatProduct, trackEvent } from '../../../../../utils/utils';
import ContractInvestmentFundsCustomerPage from '../investment-funds/ContractInvestmentFundsCustomerPage';
import InfoInvestmentFundsCustomerPage from '../investment-funds/InfoInvestmentFundsCustomerPage';
import { ContractEmploymentPlansCustomerPage, ContractIndividualPlansCustomerPage, ContractSimplifiedEmploymentPlansCustomerPage } from '../pension-plans/ContractPensionPlansCustomerPage';
import { InfoEmploymentPlansCustomerPage, InfoIndividualPlansCustomerPage, InfoSimplifiedEmploymentPlansCustomerPage } from '../pension-plans/InfoPensionPlanCustomerPage';

const ContractCustomerPage: React.FC = () => {
  const intl = useIntl()
  const router = useIonRouter();
  const screenSize = useScreenSize();
  const size = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(null as null|string);
  const [investmentFundsNotContracted, setInvestmentFundsNotContracted] = useState([] as Product[]);
  const [loadingNoContractInvestmentFunds, setLoadingNoContractInvestmentFunds] = useState(true);
  const [pensionPlansSimplifiedNotContracted, setPensionPlansSimplifiedNotContracted] = useState([] as Product[]);
  const [pensionPlansIndividualNotContracted, setPensionPlansIndividualNotContracted] = useState([] as Product[]);
  const [loadingNoContractPensionPlans, setLoadingNoContractPensionPlans] = useState(true);

  const { contract, moreInfo } = useProductActions()


  useEffect(()=>{
    removeCurrentUserProduct();
    getPlansNotContracted(process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL).then((pensionPlansIndividual)=>{
      getPlansNotContracted(process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS).then((pensionPlansSimplified)=>{
        if(pensionPlansSimplified){
          setPensionPlansSimplifiedNotContracted(pensionPlansSimplified)
        }
      }).finally(()=>{
        if(pensionPlansIndividual){
          setPensionPlansIndividualNotContracted(pensionPlansIndividual)
        }
        setLoadingNoContractPensionPlans(false);
      });

    }).finally(()=>{
    })

    getInvestmentFundsNotContracted().then((result)=>{
      setInvestmentFundsNotContracted(result);
    }).finally(()=>{
      setLoadingNoContractInvestmentFunds(false);
    })
  }, []);

  const handleMoreInformation = (product:Product): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('perfil-'+formatProduct(product).replaceAll('-', '_')+'-mas_informacion');
      setLoadingProduct(formatProduct(product));
      moreInfo(product).finally(()=>{
        setLoadingProduct(null);
        resolve();
      });
  });

  const handleContract = (product:Product): Promise<void> =>
    new Promise((resolve) => {
        trackEvent('perfil-'+formatProduct(product).replaceAll('-', '_')+'-contratar');
        setLoadingProduct(formatProduct(product));
        contract(product,
        ()=>{
          setLoadingProduct(null);
        }).finally(()=>{
            resolve()
        });
  });

  return (
    <LayoutPrivateArea
      seo={{metaTitle: 'Contratar'}}
      title={'Contratar'}
      pageInfo={{option: process.env.REACT_APP_MENU_NUEVO_CONTRATO, role:'client'}}
      showMenu
    >
       <ResponsiveLayout className='inner first'>

            {screenSize.isDesktopOrBigger &&
                <Box paddingBottom={40}>
                    <Inline space={'between'} alignItems='center'>
                        <Text5>{intl.formatMessage({id:'page_product_customer_hire_fonditel_products'})}</Text5>
                    </Inline>
                </Box>
            }
      </ResponsiveLayout>

      {loadingNoContractInvestmentFunds ?
        <ResponsiveLayout className={'full'} fullWidth>
          <Box paddingBottom={24}>
              <ResponsiveLayout className={'inner'} fullWidth>
                <SkeletonLine ariaLabel="loading" />
              </ResponsiveLayout>
          </Box>

          <Box paddingBottom={40}>
              <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1700 ? 3 : 2)}
              items={[
                <SkeletonRectangle height={180} />,
                <SkeletonRectangle height={180} />
              ]} />
              </Box>
        </ResponsiveLayout>
        :
        <>{investmentFundsNotContracted && investmentFundsNotContracted.length>0 &&
            <ResponsiveLayout className={(investmentFundsNotContracted.length > 0)  ? 'full' : 'inner'} fullWidth>
                <Box paddingBottom={24}>
                  <Text4 medium>{intl.formatMessage({id:'page_product_customer_investment_funds_title'})}</Text4>
                </Box>
                <Box paddingBottom={40}>
                    <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1700 ? 3 : 2)}
                      items={investmentFundsNotContracted
                        ? investmentFundsNotContracted.map((product) => (
                          <ProductCard
                            title={product.descripcion??''}
                            category={product.informacionGeneral?.tipoFondo??''}
                            net_asset_value={product.rentabilidad?.valorLiquidativo ? formatNetAssetValue(product.rentabilidad?.valorLiquidativo) : intl.formatMessage({id:'page_product_customer_not_available'})}
                            annual_profitability={product.rentabilidad?.rentabilidadAnual ? formatBalance(product.rentabilidad?.rentabilidadAnual) : intl.formatMessage({id:'page_product_customer_not_available'})}
                            historical_profitability={product.rentabilidad && product.rentabilidad.rentabilidadHistorica ? formatBalance(product.rentabilidad.rentabilidadHistorica)+(product.rentabilidad.tpRentabilidadHistorica ? ' '+intl.formatMessage({id:'page_product_customer_apr'}) : '%') : intl.formatMessage({id:'page_product_customer_not_available'})}
                            button={
                              <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                                <ButtonTertiary showSpinner={loadingProduct===formatProduct(product)} small onPress={()=>{handleContract(product)}}>
                                  {intl.formatMessage({id:'page_product_customer_hire_button'})}
                                </ButtonTertiary>
                                <ButtonSecondary small onPress={()=>{handleMoreInformation(product)}}>
                                  {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                                </ButtonSecondary>
                              </ButtonLayout>
                            }
                          />
                          ))
                        : []
                      }
                    />
                </Box>
            </ResponsiveLayout>
          }</>
        }

      {loadingNoContractPensionPlans ?
        <ResponsiveLayout className={'full'} fullWidth>
          <Box paddingBottom={24}>
              <ResponsiveLayout className={'inner'} fullWidth>
                <SkeletonLine ariaLabel="loading" />
              </ResponsiveLayout>
          </Box>

          <Box paddingBottom={40}>
              <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1700 ? 3 : 2)}
              items={[
                <SkeletonRectangle height={180} />,
                <SkeletonRectangle height={180} />
              ]} />
          </Box>
        </ResponsiveLayout>
        :
        <>{pensionPlansSimplifiedNotContracted && pensionPlansSimplifiedNotContracted.length>0 &&
            <ResponsiveLayout className={(pensionPlansSimplifiedNotContracted.length > 0)  ? 'full' : 'inner'} fullWidth>
                <Box paddingBottom={24}>
                  <Text4 medium>{intl.formatMessage({id:'page_product_customer_pension_plans_self_employed_title'})}</Text4>
                </Box>
                <Box paddingBottom={40}>
                    <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1700 ? 3 : 2)}
                      items={pensionPlansSimplifiedNotContracted
                        ? pensionPlansSimplifiedNotContracted.map((product) => (
                          <ProductCard
                            title={product.descripcion??''}
                            category={product.informacionGeneral?.tipoFondo??''}
                            net_asset_value={product.rentabilidad?.valorLiquidativo ? formatNetAssetValue(product.rentabilidad?.valorLiquidativo) : intl.formatMessage({id:'page_product_customer_not_available'})}
                            annual_profitability={product.rentabilidad?.rentabilidadAnual ? formatBalance(product.rentabilidad?.rentabilidadAnual) : intl.formatMessage({id:'page_product_customer_not_available'})}
                            historical_profitability={product.rentabilidad && product.rentabilidad.rentabilidadHistorica ? formatBalance(product.rentabilidad.rentabilidadHistorica)+(product.rentabilidad.tpRentabilidadHistorica ? ' '+intl.formatMessage({id:'page_product_customer_apr'}) : '%') : intl.formatMessage({id:'page_product_customer_not_available'})}
                            button={
                              <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                                <ButtonTertiary showSpinner={loadingProduct===formatProduct(product)} small onPress={()=>{handleContract(product)}}>
                                  {intl.formatMessage({id:'page_product_customer_hire_button'})}
                                </ButtonTertiary>
                                <ButtonSecondary small onPress={()=>{handleMoreInformation(product)}}>
                                  {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                                </ButtonSecondary>
                              </ButtonLayout>
                            }
                          />
                          ))
                        : []
                      }
                    />
                </Box>
            </ResponsiveLayout>
          }
          {pensionPlansIndividualNotContracted && pensionPlansIndividualNotContracted.length>0 &&
            <ResponsiveLayout className={(pensionPlansIndividualNotContracted.length > 0)  ? 'full' : 'inner'} fullWidth>
                <Box paddingBottom={24}>
                  <Text4 medium>{intl.formatMessage({id:'page_product_customer_pension_plans_title'})}</Text4>
                </Box>
                <Box paddingBottom={40}>
                    <Carousel initialActiveItem={0}  itemsPerPage={screenSize.isMobile ? 1 : (size.width >  1700 ? 3 : 2)}
                      items={pensionPlansIndividualNotContracted
                        ? pensionPlansIndividualNotContracted.map((product) => (
                          <ProductCard
                            title={product.descripcion??''}
                            category={product.informacionGeneral?.tipoFondo??''}
                            net_asset_value={product.rentabilidad?.valorLiquidativo ? formatNetAssetValue(product.rentabilidad?.valorLiquidativo) : intl.formatMessage({id:'page_product_customer_not_available'})}
                            annual_profitability={product.rentabilidad?.rentabilidadAnual ? formatBalance(product.rentabilidad?.rentabilidadAnual) : intl.formatMessage({id:'page_product_customer_not_available'})}
                            historical_profitability={product.rentabilidad && product.rentabilidad.rentabilidadHistorica ? formatBalance(product.rentabilidad.rentabilidadHistorica)+(product.rentabilidad.tpRentabilidadHistorica ? ' '+intl.formatMessage({id:'page_product_customer_apr'}) : '%') : intl.formatMessage({id:'page_product_customer_not_available'})}
                            button={
                              <ButtonLayout align={screenSize.isDesktopOrBigger ? 'left': 'center'}>
                                <ButtonTertiary showSpinner={loadingProduct===formatProduct(product)} small onPress={()=>{handleContract(product)}}>
                                  {intl.formatMessage({id:'page_product_customer_hire_button'})}
                                </ButtonTertiary>
                                <ButtonSecondary small onPress={()=>{handleMoreInformation(product)}}>
                                  {screenSize.isDesktopOrBigger ? intl.formatMessage({id:'page_product_customer_more_information_button'}) : intl.formatMessage({id:'page_product_customer_more_information_short_button'})}
                                </ButtonSecondary>
                              </ButtonLayout>
                            }
                          />
                          ))
                        : []
                      }
                    />
                </Box>
            </ResponsiveLayout>
          }
          </>
        }
    </LayoutPrivateArea>
  );
};

export default ContractCustomerPage;
