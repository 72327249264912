import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';

import { setPasswordUser } from '../../../../../utils/apiclient';

import { Box, ButtonFixedFooterLayout, ButtonPrimary, Form, Inline, PasswordField, ResponsiveLayout, Stack, Text4, Text5, useScreenSize } from '@telefonica/mistica';

import { removeCurrentUserProduct } from '../../../../../utils/storage';
import { useToast } from '../../../../../utils/toast';
import './ChangePasswordCustomerPage.scss';
import { trackEvent } from '../../../../../utils/utils';
import { getCurrentLang } from '../../../../../utils/locate';


const ChangePasswordCustomerPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();

  const { showToast } = useToast();

  const [formData, setFormData] = useState({password: "", new_password: "", confirm_new_password: ""});
  const [formErrors, setFormErrors] = useState([] as any);


  useEffect(()=>{
    removeCurrentUserProduct();
  }, []);


  const handleSubmit = (data: any): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('perfil-editar-contraseña-guardar');
      if(data.new_password !== data.confirm_new_password){
        setFormErrors( { 'new_password': intl.formatMessage({id:'password not match'}) });
        resolve();
      }else{
        setPasswordUser({oldPassword: data.password, newPassword: data.new_password, lang: getCurrentLang()}).then(response => {
          if(response.result.result === 'ko'){
            setFormErrors(response.result.errors);
          }else{
            showToast({type:'ok', title: intl.formatMessage({id:'page_change_password_changed_password_dialog'}), description: intl.formatMessage({id:'page_change_password_changed_password_dialog_description'})});
          }
        }).finally(()=>{
          resolve();
        });
      }
  });

  const handleChangeField= (evt:any) => {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
        ...formData,
        [name]: value,
    };
    setFormData(newValues);
    if(handleError(name)){
        delete formErrors[name];
    }
  };

  const handleError=(name:string) =>{
    if(formErrors && formErrors[name]){
      const error = formErrors[name];

      if(error){
          return intl.formatMessage({id: 'page_change_password_'+error.toLowerCase().replaceAll(' ', '_')});
      }
    }
    return null;
  };

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_change_password_customer_full_title'})}}
      title={intl.formatMessage({id:'page_change_password_customer_title'})}
      pageInfo={{option: process.env.REACT_APP_MENU_OPERATIVA_WEB_CAMBIAR_PASSWORD, role:'client'}}
      showBack
    >
      <ResponsiveLayout className='inner first'>
        {screenSize.isDesktopOrBigger &&
          <Box paddingBottom={40}>
            <Inline space={'between'} alignItems='center'>
                  <Text5>{intl.formatMessage({id:'page_change_password_customer_full_title'})}</Text5>
            </Inline>
          </Box>
        }

        <Form initialValues={formData} onSubmit={handleSubmit} className='form--srcollY'>
          <Stack space={40}>
            <Stack space={32}>
              <Stack space={16}>
                <Text4 medium>{intl.formatMessage({id:'page_change_password_customer_password_title'})}</Text4>
                <PasswordField
                  name="password"
                  onChange={handleChangeField}
                  error={handleError('password')!==null} helperText={handleError('password')??undefined}
                  label={intl.formatMessage({id:'page_change_password_customer_password_title'})} />
              </Stack>

              <Stack space={16}>
                <Text4 medium>{intl.formatMessage({id:'page_change_password_customer_new_password_title'})}</Text4>
                <PasswordField
                  name="new_password"
                  onChange={handleChangeField}
                  error={handleError('new_password')!==null} helperText={handleError('new_password')??undefined}
                  label={intl.formatMessage({id:'page_change_password_customer_confirm_new_password_title'})} />
                <PasswordField
                  name="confirm_new_password"
                  onChange={handleChangeField}
                  error={handleError('new_password')!==null} helperText={handleError('new_password') ?? intl.formatMessage({id:'page_change_password_customer_confirm_new_password_helper_text'})}
                  label={intl.formatMessage({id:'page_change_password_customer_confirm_new_password_label'})} />
              </Stack>
            </Stack>

            <ButtonFixedFooterLayout
              button={<ButtonPrimary submit>{intl.formatMessage({id:'page_change_password_customer_save'})}</ButtonPrimary>}>
            </ButtonFixedFooterLayout>
          </Stack>
        </Form>
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default ChangePasswordCustomerPage;
