import { useIonRouter } from '@ionic/react';
import { Box, Inline, Text, TextLink, skinVars, useScreenSize } from '@telefonica/mistica';
import * as React from 'react';

import { useIntl } from 'react-intl';
import HomePage from '../../pages/public/home/HomePage';
import { getUrlNavigation } from '../../utils/navigation';
import './Breadcrumb.scss';
import GlobalPositionCustomerPage from '../../pages/private/users/customer/global-position/GlobalPositionCustomerPage';
import { getUser } from '../../utils/storage';
import { isClient, isControlCommision, isPromoter } from '../../utils/utils';
import GlobalPositionPromoterPage from '../../pages/private/users/promoter/global-position/GlobalPositionPromoterPage';
import GlobalPositionControlCommisionPage from '../../pages/private/users/control-commision/global-position/GlobalPositionControlCommisionPage';
import SelectProfile from '../../pages/private/users/select-profile/SelectProfile';
export interface BreadcrumbProps {
    privateArea?: boolean,
    title?: string,
    parents?: Array<{title:string, item:React.FC}>,
    inverse?: boolean
}
const Breadcrumb: React.FC<BreadcrumbProps> = (prop) => {
    const router = useIonRouter();
    const intl = useIntl();
    const screenSize = useScreenSize();

    return (
        <Inline space={4} alignItems='center'>
            <TextLink onPress={()=>{router.push(getUrlNavigation(prop.privateArea ? (isClient() ? GlobalPositionCustomerPage : (isPromoter() ? GlobalPositionPromoterPage : (isControlCommision() ? GlobalPositionControlCommisionPage : SelectProfile))) : HomePage))}}>
                <Text color={prop.inverse ? skinVars.colors.background : skinVars.colors.neutralHigh} size={14}>{intl.formatMessage({id:'page_breadcumb_home_title'})}</Text>
            </TextLink>

            {prop.parents && prop.parents.length>0 && prop.parents.map((parent, i)=> {

                return (
                    <Inline key={'breadcrumb-item'+i} space={4} alignItems='center'>
                        <Text color={prop.inverse ? skinVars.colors.background : skinVars.colors.neutralHigh} size={14} >/</Text>
                        <TextLink onPress={()=>{router.push(getUrlNavigation(parent.item))}}>
                            <Text truncate={!screenSize.isLargeDesktop} color={prop.inverse ? skinVars.colors.background : skinVars.colors.neutralHigh} size={14} >{parent.title}</Text>
                        </TextLink>
                    </Inline>
                );}
            )}

            <Text color={prop.inverse ? skinVars.colors.background : skinVars.colors.neutralHigh} size={14}  >/</Text>
            <Text truncate={!screenSize.isLargeDesktop} size={14}  color={prop.inverse ? skinVars.colors.background : skinVars.colors.neutralMedium}>{prop.title}</Text>
        </Inline>
    );
};

export default Breadcrumb;