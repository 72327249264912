import { useIonRouter } from '@ionic/react';
import { Box, ButtonPrimary, ResponsiveLayout, Stack, Text6, skinVars, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import ChipTabs from '../../../../components/chip-tabs/ChipTabs';
import EmptyStateCustom from '../../../../components/empty-state/EmptyStateCustom';
import HeroImage from '../../../../components/hero/HeroImage';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import MembersOf from '../../../../components/staticPages/membersOf/MembersOf';
import { getUrlNavigation } from '../../../../utils/navigation';
import { trackEvent } from '../../../../utils/utils';
import FinalCapitalCalculatorPage from '../../for-investor/calculators/FinalCapitalCalculatorPage';
import '../LandingPage.scss';

const FiscalExerciseEmploymentPensionPlans: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
	const router = useIonRouter();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [options, setOptions] = useState(['Aportaciones', '¿Cómo tributan las prestaciones?']);

  return (
    <LayoutPublic
      seo={{metaTitle: 'Fonditel IRPF 2022', metaDescription:'Fonditel IRPF 2022'}}
    >
      <style>{'.landingPage--content .html-content ul li:before{background: '+skinVars.colors.textPrimary+';}'}</style>
      <HeroImage
        image=''
        video={
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/yMSY1UYNZiA" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        }
        title='Planes de Pensiones Empleo'
        imageNotRound={true}
      ></HeroImage>

      <ChipTabs
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
        tabs={options.map((text) => ({
          text,
        }))}
      />

      <ResponsiveLayout className={(selectedIndex===0 ? 'landingPage--content d-block' : 'd-none')}>
        <div className='html-content' dangerouslySetInnerHTML={{__html:
          `<p>Además de los 1.500 € de contribuciones realizados por el trabajador bien a un plan de pensiones individual o de empleo, el límite podrá incrementarse en otros 8.500 euros adicionales, hasta un total de 10.000 euros, siempre que tal incremento provenga de contribuciones empresariales o de aportaciones del trabajador al mismo instrumento por importe igual o inferior a la respectiva contribución del empleador.</p>
           <p>En 2023, este límite se incrementará en 8.500 euros, siempre que tal incremento provenga de contribuciones empresariales o de aportaciones del trabajador al plan de pensiones de empleo, aplicando un multiplicador en función de si el importe anual de la contribución empresarial es superior o inferior a 1.500 euros anuales. En todo caso se aplicará el multiplicador 1 cuando el trabajador obtenga en el ejercicio rendimientos íntegros del trabajo superiores a 60.000 euros.</p>
           <p>A estos efectos, las cantidades aportadas por la empresa que deriven de una decisión del trabajador tendrán la consideración de aportaciones del trabajador.</p>`
        }}></div>
      </ResponsiveLayout>

      <ResponsiveLayout className={(selectedIndex===1 ? 'landingPage--content d-block' : 'd-none')}>
        <div className='html-content' dangerouslySetInnerHTML={{__html:
          `<ul>
                <li>Si la prestación de los planes de pensiones es en <strong>forma de renta</strong>, la cantidad total percibida se integrará en la base imponible del IRPF como rendimiento del trabajo.</li>
                <li>Si la prestación de los planes de pensiones es en forma de capital, también se integrará en la base imponible del IRPF como rendimiento del trabajo. Dependiendo de la fecha de la contingencia, existen unos plazos para aplicar una reducción del 40% a las cantidades aportadas antes del 31 de diciembre de 2006.</li>
            </ul>`
        }}></div>
      </ResponsiveLayout>

      <ResponsiveLayout className='content landingPage--content' fullWidth>
        <ResponsiveLayout>

          <Box className='blueText--block' paddingY={screenSize.isDesktopOrBigger ? 80 : 24}>
            <Stack space={16} className='boxAlignCenter'>
              <Text6 color={skinVars.colors.brand}>“Más de 30 años de experiencia en la gestión de fondos de pensiones.”</Text6>
            </Stack>
          </Box>

        </ResponsiveLayout>
      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth>

        <EmptyStateCustom
          largeImageUrl="../assets/img/ejercicio-fiscal.jpg"
          title='Accede a nuestra web y usa nuestra calculadora de Capital Final y Ahorro Fiscal'
          button={
            <ButtonGroup
              primaryButton={
                <ButtonPrimary onPress={() => {router.push(getUrlNavigation(FinalCapitalCalculatorPage)); trackEvent('landing_irpf-calculadoras-'+slugify(intl.formatMessage({id:'page_for_investor_final_capital_calculator_title'})).replaceAll('-', '_')); }}>
                    Calculadoras
                </ButtonPrimary>
              }
            ></ButtonGroup>
          }
        />

      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth>
        <MembersOf></MembersOf>
      </ResponsiveLayout>

    </LayoutPublic>
  );
};

export default FiscalExerciseEmploymentPensionPlans;
