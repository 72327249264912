

import { useIonRouter } from '@ionic/react';
import { Box, ButtonPrimary, ResponsiveLayout, Text7, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import slugify from 'react-slugify';
import ButtonTertiary from '../../../components/buttons/ButtonTertiary';
import ChipTabs from '../../../components/chip-tabs/ChipTabs';
import EmptyStateCustom from '../../../components/empty-state/EmptyStateCustom';
import HeroImage from '../../../components/hero/HeroImage';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import { useProductActions } from '../../../components/operate/ProductActions';
import AnnualEvolutionProfitability from '../../../components/product-data/AnnualEvolutionProfitability';
import Documentation from '../../../components/product-data/Documentation';
import GeneralInformation from '../../../components/product-data/GeneralInformation';
import HistoricalProfitability from '../../../components/product-data/HistoricalProfitability';
import NetAssetValueEvolution from '../../../components/product-data/NetAssetValueEvolution';
import PortfolioComposition from '../../../components/product-data/PortfolioComposition';
import ProfitabilityValues from '../../../components/product-data/ProfitabilityValues';
import Risk from '../../../components/product-data/Risk';
import { Product } from '../../../entities/products/product';
import { getInfoProduct, getProduct, getProducts } from '../../../utils/apiclient';
import { getUrlNavigation } from '../../../utils/navigation';
import { formatProduct, isBot, trackEvent } from '../../../utils/utils';
import Error404Page from '../error-404/Error404Page';
import CustomizeInvestmentFundPage from '../for-investor/calculators/DecideYourInvestmentCalculatorPage';
import PensionPlansPage from './PensionPlansPage';

const PensionPlansDetailPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
  const router = useIonRouter();
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [product, setProduct] = useState(null as Product|null);
  const [productInfo, setProductInfo] = useState(null as Product|null);
  const [options, setOptions] = useState([] as string[]);
  const params = useParams() as any;
  const { contract } = useProductActions()

  useLayoutEffect(()=>{
    getProduct({cdCompany: parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1'), slug:params.slug}).then((result)=>{
        if(result){
            setProductInfo(result);
        }
    });

    getInfoProduct({cdCompany: parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1'), slug:params.slug}).then((result)=>{
        if(result){
            let opts = [intl.formatMessage({id:'page_pension_plans_detail_chip_tab1_name'}), intl.formatMessage({id:'page_pension_plans_detail_chip_tab2_name'}), intl.formatMessage({id:'page_pension_plans_detail_chip_tab3_name'})];

            if(result.documentacion && result.documentacion.filter(item=>(item.esEI??false)).length>0){
                opts.push(intl.formatMessage({id:'page_pension_plans_detail_chip_tab5_name'}))
            }
            if(result.documentacion && result.documentacion.filter(item=>(item.esSO??false)).length>0){
                opts.push(intl.formatMessage({id:'page_pension_plans_detail_chip_tab4_name'}))
            }
            setOptions(opts);
            setProduct(result);
            if(!productInfo){
                setProductInfo(result);
                getProducts(parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1')).then(()=>{});
            }
        }else{
            router.push(getUrlNavigation(Error404Page));
        }
    }).finally(()=>{
        setLoading(false);
    });
}, [params.slug]);

useEffect(()=>{
    if(product){
        trackEvent('plan-'+formatProduct(product)+'-'+slugify(options[selectedIndex]).replaceAll('-', '_'));
    }

}, [selectedIndex]);

const handleContract = (): Promise<void> =>
    new Promise((resolve) => {
        if(product){
            trackEvent('plan-'+(product.slug?.replaceAll('-', '_') ?? '')+'-contratar');
            contract(product).finally(()=>{
                resolve()
            });
        }else{
          resolve()
        }
    });

  return (
    <LayoutPublic
      seo={{metaTitle: (product?.descripcion ?? (productInfo?.descripcion ?? '')), metaDescription: (product?.informacionGeneral?.descripcionAreaPublica  ?? (productInfo?.informacionGeneral?.descripcionAreaPublica ?? ''))}}
      header={{title:(product?.descripcion ?? (productInfo?.descripcion ?? '')), parents:[{title:intl.formatMessage({id:'page_pension_plans_title'}), item:PensionPlansPage}]}}
    >
        <HeroImage
            image={(productInfo?.informacionGeneral?.Imagen ?? '')}
            title={(productInfo?.informacionGeneral?.tituloAreaPublica ?? '')}
            description={(productInfo?.informacionGeneral?.descripcionAreaPublica ?? '')}
            button={(product?.contratable ?? productInfo?.contratable ?? false) ? <ButtonTertiary onPress={handleContract}>{intl.formatMessage({id:'page_pension_plans_detail_hero_action'})}</ButtonTertiary> : undefined}
        ></HeroImage>

        <ResponsiveLayout className='content' fullWidth={true}>
            <ResponsiveLayout>

                <Box paddingTop={screenSize.isDesktopOrBigger ? 80 : 32} className={screenSize.isTabletOrBigger ? 'boxAlignCenter': ''}>
                    <Text7>{(product?.descripcion ?? (productInfo?.descripcion ?? ''))}</Text7>
                </Box>

            </ResponsiveLayout>
        </ResponsiveLayout>

        <ChipTabs
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
            tabs={options.map((text) => ({ text }))}
        />

        <ResponsiveLayout className={(options[selectedIndex]===intl.formatMessage({id:'page_pension_plans_detail_chip_tab1_name'}) ? 'd-block' : 'd-none')}>
            <ProfitabilityValues loading={loading} product={product} />
            <Risk loading={loading} product={product} />
            <PortfolioComposition loading={loading} product={product} />
            <GeneralInformation loading={loading} product={product} />
        </ResponsiveLayout>

        <ResponsiveLayout className={(options[selectedIndex]===intl.formatMessage({id:'page_pension_plans_detail_chip_tab2_name'}) ? 'd-block' : 'd-none')}>
            <HistoricalProfitability loading={loading}  product={product} />
            <AnnualEvolutionProfitability loading={loading}  product={product} />
            <NetAssetValueEvolution loading={loading} product={product} />
        </ResponsiveLayout>

        <ResponsiveLayout className={(options[selectedIndex]===intl.formatMessage({id:'page_pension_plans_detail_chip_tab3_name'}) ? 'd-block' : 'd-none')}>
            <Documentation prefixSlug={'plan-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_documentacion-'} full loading={loading} product={product} legal={true}  />
        </ResponsiveLayout>

        <ResponsiveLayout className={(options[selectedIndex]===intl.formatMessage({id:'page_pension_plans_detail_chip_tab5_name'}) ? 'd-block' : 'd-none')}>
            <Documentation prefixSlug={'plan-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_impreso-'} full loading={loading} product={product} printed={true}  />
        </ResponsiveLayout>

        <ResponsiveLayout className={(options[selectedIndex]===intl.formatMessage({id:'page_pension_plans_detail_chip_tab4_name'}) ? 'd-block' : 'd-none')}>
            <Documentation prefixSlug={'plan-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-descarga_sostenibilidad-'} full loading={loading} product={product} sustainability={true} />
        </ResponsiveLayout>

        <ResponsiveLayout className='content' fullWidth={true}>
            <ResponsiveLayout>

                <EmptyStateCustom
                    largeImageUrl="../assets/img/como-elegir-un-plan.jpg"
                    title={intl.formatMessage({id:'page_pension_plans_empty_state_title'})}
                    description={intl.formatMessage({id:'page_pension_plans_empty_state_description'})}

                    button={
                        <ButtonGroup
                          primaryButton={isBot ?
                            <ButtonPrimary href={getUrlNavigation(CustomizeInvestmentFundPage)}><img src={'../assets/img/calculate-your-plan.svg'}/>
                              {intl.formatMessage({id:'page_pension_plans_empty_state_action_primary'})}
                            </ButtonPrimary>
                        :
                            <ButtonPrimary onPress={()=>{router.push(getUrlNavigation(CustomizeInvestmentFundPage)); trackEvent('plan-'+(product?.slug?.replaceAll('-', '_') ?? '')+'-ir_calculadora');}}><img src={'../assets/img/calculate-your-plan.svg'}/>
                              {intl.formatMessage({id:'page_pension_plans_empty_state_action_primary'})}
                            </ButtonPrimary>
                        }
                        ></ButtonGroup>
                      }
                />

            </ResponsiveLayout>
        </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default PensionPlansDetailPage;
