import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Contact } from '../../entities/contact/contact.model';

import { ButtonPrimary, Checkbox, Chip, dialog, EmailField, Form, IconAlertLight, IconInformationUserLight, Inline, skinVars, Stack, TextField, TextLink } from '@telefonica/mistica';

import DataProtectionPage from '../../pages/public/data-protection/DataProtectionPage';
import { addContact, trackStadistics } from '../../utils/apiclient';
import { getUrlNavigation } from '../../utils/navigation';
import { isBot, trackEvent, validatePhoneNumber } from '../../utils/utils';
import { useAlert } from '../alerts/Alert';

const ContactForm: React.FC = () => {
  const { showAlert } = useAlert()
  const intl = useIntl();
  const initContact = {name: '', lastName: '', email: '', phone: '', comentary: ''} as Contact;
  const [formContact, setFormContact] = useState(initContact);

  const [checkConditions, setCheckConditions] = React.useState(false);
  const [formWriteUs, setFormWriteUs] = React.useState(true);

  const handleChangeField= (evt:any) => {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
      ...formContact,
      [name]: value,
    };
    setFormContact(newValues);
  };

  const handleSubmit = (data: any): Promise<void> =>
  new Promise((resolve) => {
    if(data){
      trackEvent('contacto-enviar');
      if(formWriteUs){
        data.formType = intl.formatMessage({id:'page_contact_form_writeus_title'});
      }else{
        data.formType = intl.formatMessage({id:'page_contact_form_wecallyou_title'});
        data.comentary = '';
      }

      addContact(data).then(response=>{
        if(response && response.result === true){
          trackStadistics('CONTACT', undefined, undefined, data.formType );
          dialog({
            title: intl.formatMessage({id:'page_contact_form_writeus_title_dialog_ok'}),
            message: intl.formatMessage({id:'page_contact_form_writeus_description_dialog_ok'}),
            acceptText: intl.formatMessage({id:'page_contact_form_writeus_button_dialog'}),
            forceWeb: true,
            showCancel: false,
            icon: <IconInformationUserLight color={skinVars.colors.brand} />,
          });
          setFormContact(initContact);
        }else{
          dialog({
            title: intl.formatMessage({id:'page_contact_form_writeus_title_dialog_ko'}),
            message: intl.formatMessage({id:'page_contact_form_writeus_description_dialog_ko'}),
            acceptText: intl.formatMessage({id:'page_contact_form_writeus_button_dialog'}),
            forceWeb: true,
            showCancel: false,
            icon: <IconAlertLight color={skinVars.colors.buttonDangerBackground} />,
          });
        }
        resolve();
      });
    }
  });

  function toggleState(value: boolean) {
    setFormWriteUs(value);
    if(value){
      trackEvent('contacto-formulario-mail');
    }else{
      trackEvent('contacto-formulario-telefono');
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
        <Stack space={24}>
            <Inline space={24}>
                <Chip onPress={()=>{toggleState(true)}} active={formWriteUs}>{intl.formatMessage({id:'page_contact_form_writeus_title'})}</Chip>
                <Chip  onPress={()=>{toggleState(false)}}  active={!formWriteUs}>{intl.formatMessage({id:'page_contact_form_wecallyou_title'})}</Chip>
            </Inline>
            <TextField
                name="name"
                value={formContact.name}
                onChange={handleChangeField}
                label={intl.formatMessage({id:'page_contact_form_writeus_text_name'})}
                autoComplete="off"
                fullWidth />
            <TextField
                name="lastName"
                value={formContact.lastName}
                onChange={handleChangeField}
                label={intl.formatMessage({id:'page_contact_form_writeus_text_lastname'})}
                autoComplete="off"
                fullWidth />
            <EmailField
                name="email"
                value={formContact.email}
                onChange={handleChangeField}
                label={intl.formatMessage({id:'page_contact_form_writeus_text_email'})}
                autoComplete="off"
                fullWidth />
            <TextField
                name="phone"
                value={formContact.phone}
                onChange={handleChangeField}
                label={intl.formatMessage({id:'page_contact_form_writeus_text_phone'})}
                autoComplete="off"
                fullWidth
                error={formContact.phone && formContact.phone.length > 0 ? !validatePhoneNumber(formContact.phone) : undefined}
                helperText={ formContact.phone && formContact.phone.length > 0 ? !validatePhoneNumber(formContact.phone) ? intl.formatMessage({id:'page_contact_form_writeus_text_phone_error'}) : undefined : undefined}/>
            {formWriteUs && (
                <TextField
                    name="comentary"
                    value={formContact.comentary}
                    onChange={handleChangeField}
                    label={intl.formatMessage({id:'page_contact_form_writeus_text_comments'})}
                    autoComplete="off"
                    optional
                    fullWidth
                    multiline />
                )
            }
            <Checkbox name="checkConditions" checked={checkConditions} onChange={setCheckConditions}>{intl.formatMessage({id:'page_contact_form_writeus_check_terms'},{privacy_policy: (isBot ? <TextLink newTab href={getUrlNavigation(DataProtectionPage)}>{intl.formatMessage({id:'page_contact_form_writeus_check_terms_policy_text'})}</TextLink> : <TextLink onPress={()=>{showAlert('_privacy_policy_')}}>{intl.formatMessage({id:'page_contact_form_writeus_check_terms_policy_text'})}</TextLink>) })}</Checkbox>
            <ButtonPrimary disabled={!checkConditions} submit>{intl.formatMessage({id:'page_contact_form_writeus_button_send'})}</ButtonPrimary>
        </Stack>
    </Form>
  );
};

export default ContactForm;
