import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Circle, Hero, Image, ResponsiveLayout, skinVars, useScreenSize } from '@telefonica/mistica';
import './Hero.scss';

interface HeroImageProp {
    image: string,
    title: string,
    description?: string,
    button?: any,
    imageNotRound?: boolean,
    video?: any
}

const HeroImage: React.FC<HeroImageProp> = (prop) => {

const intl = useIntl();
const screenSize = useScreenSize();
const size = useScreenSize();

    return (
        <>
            <ResponsiveLayout className='content' fullWidth backgroundColor={skinVars.colors.backgroundAlternative}>
                <Box className={'imageHero'}>
                    <Hero
                        background="alternative"
                        media=
                        {
                            prop.video ?
                                prop.video
                            :
                                (
                                    prop.image ? (prop.imageNotRound ? <Box className='imageHero__image not--circle'><Image src={prop.image} border height={450} aspectRatio={'4:3'}></Image></Box> : <Box className='imageHero__image'><Image circular={true} src={prop.image} height={screenSize.isMobile ? 280 : 450}></Image></Box>) : <></>
                                )
                        }
                        title={prop.title}
                        extra={<div className='hero--html' style={{color:skinVars.colors.textSecondary}} dangerouslySetInnerHTML={{__html: prop?.description ?? ''}}></div>}
                        button={prop.button}
                        desktopMediaPosition="right"
                    />
                </Box>
            </ResponsiveLayout>
        </>
    );
};

export default HeroImage;