import { Box, ButtonPrimary, Hero, Image, ResponsiveLayout, useScreenSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import ButtonTertiary from '../../../../../components/buttons/ButtonTertiary';
import HeroImage from '../../../../../components/hero/HeroImage';
import LayoutPublic from '../../../../../components/layout/LayoutPublic';
import { getHeaderEuroHorizonte } from '../../../../../utils/navigation';
import '../../LandingPage.scss';

const EuroHorizonInformationPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();

  return (
    <LayoutPublic
    landing={true}
    header={{options:getHeaderEuroHorizonte()}}
      seo={{metaTitle: 'Fonditel Euro Horizonte 2026, FI', metaDescription:'Fonditel Euro Horizonte 2026, FI'}}
    >

      <HeroImage
        image='../assets/img/fonditel-euro-horizonte.jpg'
        title='Fonditel Euro Horizonte 2026, FI'
        button={<ButtonTertiary onPress={()=>{}}>Suscríbelo</ButtonTertiary>}
      ></HeroImage>

      <ResponsiveLayout className='content' fullWidth>
        <ResponsiveLayout>

          <Box paddingTop={screenSize.isDesktopOrBigger ? 64 : 40} paddingBottom={screenSize.isDesktopOrBigger ? 0 : 32}>
            <div className='html-content' dangerouslySetInnerHTML={{__html:
              `<p>En el contexto actual, tras las subidas de tipos de interés por parte de los Bancos Centrales, la rentabilidad ofrecida por la renta fija corporativa europea con grado de inversión (no especulativa) se encuentra en máximos de los últimos 10 años, lo cual entendemos que representa una oportunidad para las emisiones de bonos de compañías de calidad.</p>
               <p>Dirigido a inversores defensivos (aversos al riesgo) que busquen una alternativa a la liquidez y mantener su poder adquisitivo y que puedan mantener su inversión durante un horizonte temporal de 4 años (el vencimiento de la estrategia está fijado en diciembre de 2026).</p>
               <p>El indicador resumido de riesgo es una guía del nivel de riesgo de este producto en comparación con Unit link. Muestra las probabilidades de que el producto pierda dinero debido a la evolución de los mercados. Hemos clasificado este producto en la clase de riesgo 1 en una escala de 7, en la que 1 significa el riesgo más bajo.</p>
               <p>La rentabilidad bruta a vencimiento estimada* para esta cartera (no garantizada) es del 3,60%. La estimación de rentabilidad neta objetivo anual (no garantizada) del fondo está podría estar en el 3%.*</p>`
            }}></div>
          </Box>

        </ResponsiveLayout>
      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth>
        <Hero
          background='default'
          media={
            <Image src='../assets/img/fonditel-euro-horizonte-informacion.jpg' aspectRatio='1:1' />
          }
          description='Se trata de un dato orientativo para los inversores que mantengan su inversión hasta vencimiento y en función de mercado a cierre de la construcción de la cartera. La rentabilidad de la cartera se calcula como una media de nuestras estimaciones de los rendimientos de los bonos de la cartera y puede diferir de los rendimientos obtenidos al final de la vida del fondo de inversión en particular como consecuencia de los cambios que pudieran producirse en los activos mantenidos en cartera o de la evolución de mercado de los tipos de interés y del crédito de los emisores. Además, la rentabilidad neta de comisiones será sistemáticamente inferior. Rentabilidades pasadas no garantizan rentabilidades futuras. La consecución de los objetivos de inversión no está garantizada.'
          button={<ButtonPrimary fake>Suscríbelo</ButtonPrimary>}
          desktopMediaPosition="left"
        />
      </ResponsiveLayout>

    </LayoutPublic>
  );
};

export default EuroHorizonInformationPage;