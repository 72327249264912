import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { NewsParameters } from '../../../entities/news/news-parameters.model';
import { News } from '../../../entities/news/news.model';
import { getNews } from '../../../utils/apiclient';

import CardNewContainer from '../CardNew';
import GridLoadMoreContainer from '../GridLoadMore';

import { Box, ButtonLayout, ButtonLink, GridLayout, Header, IconChevronDownRegular, IconSearchFileLight, LoadingBar, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Text3 } from '@telefonica/mistica';

import './ContentNews.scss';

interface ContentNews {
    month?:  number,
    year?:  number,
    search?:  string,
    slugTag?:  string,
    news?: News[],
    hastagNews?: News[],
}

const NewsContainer: React.FC<ContentNews> = (prop) => {

const intl = useIntl();

const [contentNews, setContentNews] = useState([] as News[]);
const [notFound, setNotFound] = useState(false);
const [loading, setLoading] = useState(false);
const [containerNews, setContainerNews] = useState([] as News[][]);
const [lineCardValue, setLineCardValue] = useState(9);
const [loadMoreValue, setLoadMoreValue] = useState(true);
const initNewParameters: NewsParameters = new NewsParameters();

useEffect(()=>{

    if(prop.hastagNews){
        setContentNews(prop.hastagNews);
    }else{
        let search=false;
        if(prop.month){
            initNewParameters.month = prop.month;
            search=true;
        }else{
            initNewParameters.month = null;
        }

        if(prop.year){
            initNewParameters.year = prop.year;
            search=true;
        }else{
            initNewParameters.year = null;
        }

        if(prop.search){
            initNewParameters.search = prop.search;
            search=true;
        }else{
            initNewParameters.search = "";
        }

        if(prop.slugTag){
            initNewParameters.slug = prop.slugTag;
        }else{
            initNewParameters.slug = "";
        }

        initNewParameters.numberNews = 9;
        initNewParameters.starting = 0;

        if(prop.news && (!search)){
            setContentNews(prop.news);
        }else{
            setContentNews([]);
            setNotFound(false);
            setLoading(true);
            getNews(initNewParameters).then(news=>{
                setLoading(false);
                if(news){
                    setContentNews(news);
                    if(news.length === 0 && (prop.month || prop.year || prop.search)){
                        setNotFound(true);
                    }
                }
            });
        }
    }
}, [, prop]);

useEffect(()=>{
    if(contentNews){
        if(prop.slugTag){
            setContainerNews(groupNewsTag(contentNews));
        }else{
            setContainerNews(groupNews(contentNews));
        }
    }
}, [contentNews]);

function groupNews(news: News[]): News[][] {
    let firstCardsValue = false;
    return news.reduce((groupCard: News[][], card: News, i: number) => {
        if (i === 0) {
            groupCard.push(news.slice(i, i + 2));
        } else if (i % 3 === 0 && !firstCardsValue) {
            groupCard.push(news.slice(i - 1, i + 2));
        } else if ((i % 3 === 2 && i > 0) && (news.length < 9) && !firstCardsValue) {
            groupCard.push(news.slice(i, i + 1));
        }
        return groupCard;
    }, []);
}



function groupNewsTag(news: News[]): News[][] {
    return news.reduce((groupCard: News[][], card: News, i: number) => {
        if (i === 0) {
            groupCard.push(news.slice(i, i + 3));
        } else if (i % 3 === 0) {
            groupCard.push(news.slice(i, i + 3));
        }
      return groupCard;
    }, []);
}

function loadMoreClick() {
    if(prop.month)
        initNewParameters.month = prop.month;
    if(prop.year)
        initNewParameters.year = prop.year;
    if(prop.search)
        initNewParameters.search = prop.search;
    if(prop.slugTag)
        initNewParameters.slug = prop.slugTag;

    initNewParameters.numberNews = 9;
    initNewParameters.starting = lineCardValue;
    setNotFound(true);
    setLoading(true);
    setLoadMoreValue(false);
    getNews(initNewParameters).then((news: News[])=>{
        setNotFound(false);
        setLoading(false);
        if(news === undefined || news.length === 0){
            setLoadMoreValue(false);
        }else{
            setContentNews(contentNews.concat(news));
            setLineCardValue(lineCardValue + 9);
            setLoadMoreValue(true);
        }
    });
}
  return (
    <>
        <ResponsiveLayout fullWidth>
            <LoadingBar visible={loading}/>
            <Stack space={32}>
                { !notFound && containerNews.length === 0 ? (
                    <Stack space={16}>
                        <GridLayout template="6+6" verticalSpace={32}
                            left={
                                <SkeletonRectangle width="100%" height={300}/>
                            }
                            right={
                                <SkeletonRectangle width="100%" height={300}/>
                            }
                        />
                        <GridLayout template="8+4" verticalSpace={32}
                            left={
                                <GridLayout template="6+6" verticalSpace={32}
                                    left={
                                        <SkeletonRectangle width="100%" height={300}/>
                                    }
                                    right={
                                        <SkeletonRectangle width="100%" height={300}/>
                                    }
                                />
                            }
                            right={
                                <SkeletonRectangle width="100%" height={300}/>
                            }
                        />
                    </Stack>

                ) : notFound && containerNews.length === 0 ? (
                        <Stack space={16}>
                            <IconSearchFileLight color={skinVars.colors.textLink} size={40}/>
                            { prop.search ? (
                                    <Header title={`${intl.formatMessage({id:'page_news_related_share_error_not_found1'})}'${prop.search}'`} description={intl.formatMessage({id:'page_news_related_share_error_not_found_description'})} />
                                ) : (
                                    <Header title={intl.formatMessage({id:'page_news_related_share_error_not_found2'})} description={intl.formatMessage({id:'page_news_related_share_error_not_found_description'})} />
                                )
                            }
                        </Stack>
                    ) : (
                        prop.slugTag ? (
                            <GridLoadMoreContainer groupCardsNews={containerNews} startIndex={0}/>
                        ) : (
                            <Stack space={32}>
                                <GridLayout template="6+6" verticalSpace={32}
                                    left={
                                        containerNews && containerNews[0] && containerNews[0][0] && (
                                            <CardNewContainer news={containerNews[0][0]} type='Media' />
                                        )
                                    }
                                    right={
                                        containerNews && containerNews[0] && containerNews[0][1] && (
                                            <CardNewContainer news={containerNews[0][1]} type='Media' />
                                        )
                                    }
                                />
                                <GridLoadMoreContainer groupCardsNews={containerNews} startIndex={1}/>
                            </Stack>
                        )
                    )
                }
                { loadMoreValue && containerNews.length !== 0 &&  containerNews.length > 2 ?
                    (
                        <Box padding={24}>
                            <ButtonLayout align='center'>
                                <ButtonLink onPress={loadMoreClick}><Text3 color="currentColor" regular>{intl.formatMessage({id:'page_news_load_more'})}</Text3> <IconChevronDownRegular color="currentColor" size={12}/></ButtonLink>
                            </ButtonLayout>
                        </Box>
                    ) : null
                }

            </Stack>
        </ResponsiveLayout>
    </>
  );
};

export default NewsContainer;

