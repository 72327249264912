import { IonContent, useIonRouter } from '@ionic/react';
import { Box, ResponsiveLayout, useIsInViewport, useScreenSize } from '@telefonica/mistica';
import React, { useLayoutEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router';
import LoginPage from '../../pages/private/login/LoginPage';
import { isValidToken } from '../../utils/apiclient';
import { getCurrentLanguage } from '../../utils/locate';
import { getUrlNavigation } from '../../utils/navigation';
import { getUser, removeUser } from '../../utils/storage';
import { isNativeApp, trackPageView } from '../../utils/utils';
import { useAlert } from '../alerts/Alert';
import Footer from '../footer/Footer';
import Header, { HeaderProps } from '../header/Header';
import ScrollToTop from '../scroll-to-top/ScrollToTop';

export interface SeoProps{
    metaTitle?: string,
    metaDescription?: string,
    metaImage?: string,

}

interface LayoutPublicProps {
    seo?: SeoProps,
    header?: HeaderProps,
    hideFooter?: boolean,
    requiresLoggedIn?: boolean,
    showBack?:boolean,
    landing?:boolean,
    children?: any
}

const LayoutPublic: React.FC<LayoutPublicProps> = (prop) => {
    const router = useIonRouter();
    const screenSize = useScreenSize();
    const ref = React.useRef<HTMLIonHeaderElement>(null);
    const inView = useIsInViewport(ref, false);
    const [loaded, setLoaded] = useState(false);
    const user = getUser();
    const { showAlert } = useAlert()
    const [currentPathname, setCurrentPathname] = useState(null as null|string);


    const location = useLocation();

    useLayoutEffect(() => {
        if(!currentPathname || currentPathname!==location.pathname){
            setCurrentPathname(location.pathname);
            trackPageView(location.pathname);
        }
    }, [location]);

    useLayoutEffect(() => {
        if (inView) {
            setLoaded(true);
        }
    }, [inView, loaded, screenSize]);

    useLayoutEffect(()=>{
        if(prop.requiresLoggedIn){
            if(!user){
                router.push(getUrlNavigation(LoginPage));
                return;
            }
            const validToken = isValidToken();
            const currentUser = getUser();
            if(currentUser && !validToken){
                removeUser();
                showAlert('_expired_session_');
                router.push(getUrlNavigation(LoginPage));
                return;
            }
        }
      }, []);

return (
    <>
    {((prop.requiresLoggedIn && user) || !prop.requiresLoggedIn) &&
        <IonContent class={"page "+(isNativeApp ? "nativeapp" : "")}>
            <HelmetProvider>
                <Helmet>‍
                    <title>{prop?.seo?.metaTitle}</title>‍
                    <meta name="description" content={prop?.seo?.metaDescription} />
                    <meta property="og:title" content={prop?.seo?.metaTitle} />
                    <meta property="og:description" content={prop?.seo?.metaDescription} />
                    {prop?.seo?.metaImage &&
                        <meta property="og:image" content={prop?.seo?.metaImage} />
                    }
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:locale" content={getCurrentLanguage().locale.toLowerCase()} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@fonditel" />
                    <meta name="twitter:creator" content="@fonditel" />
                    <meta name="twitter:title" content={prop?.seo?.metaTitle} />
                    <meta name="twitter:description" content={prop?.seo?.metaDescription} />
                    {prop?.seo?.metaImage &&
                        <meta name="twitter:image" content={prop?.seo?.metaImage} />
                    }
                </Helmet>
            </HelmetProvider>
            <ResponsiveLayout className='container' fullWidth backgroundColor={"transparent"}>
                <ResponsiveLayout fullWidth>
                    {user && user.callCenter!=null && user.callCenter &&
                        <Box paddingY={2} className='mode-callcenter'>
                            MODO CALLCENTER : {user.nif}
                        </Box>
                    }
                    <Header
                        refHeader={ref}
                        title={prop?.header?.title}
                        parents={prop?.header?.parents}
                        inverse={prop?.header?.inverse}
                        small={prop?.header?.small}
                        hide={prop?.header?.hide}
                        options={prop.header?.options}
                        showBack={prop.showBack}
                        landing={prop.landing}
                    ></Header>
                </ResponsiveLayout>
                <ResponsiveLayout fullWidth className={'content-public-area '+(isNativeApp && !(prop?.header?.hide) ? 'with-header' : '')}>
                    {prop.children}
                </ResponsiveLayout>

                <ScrollToTop visible={!inView && loaded }></ScrollToTop>

                {!prop.hideFooter &&
                    <Footer landing={prop.landing}></Footer>
                }
            </ResponsiveLayout>
        </IonContent>
    }</>);
};

export default LayoutPublic;
