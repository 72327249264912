import { Box, ButtonLink, DataCard, Grid, IconArrowLineRightRegular, IconDollarSymbolCircleRegular, IconInformationUserRegular, IconThrowLightRegular, IconWalletRegular, LoadingBar, ResponsiveLayout, SkeletonLine, SkeletonRectangle, skinVars, Stack, Tabs, Text4, Text5, Touchable, useScreenSize, useWindowSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import ContractInfo from '../../../../../components/contract-data/ContractInfo';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';

import { useIonRouter } from '@ionic/react';
import { useEffect, useState } from 'react';
import BalanceRights from '../../../../../components/contract-data/BalanceRights';
import ContractData from '../../../../../components/contract-data/ContractData';
import Movements from '../../../../../components/contract-data/Movements';
import { useValidOperate } from '../../../../../components/operate/ValidOperate';
import ProductInformation from '../../../../../components/product-data/ProductInformation';
import { Contract } from '../../../../../entities/contract/contract';
import { Product } from '../../../../../entities/products/product';
import { getInfoContract, trackStadistics } from '../../../../../utils/apiclient';
import { getUrlNavigation } from '../../../../../utils/navigation';
import { getCurrentUserProduct, removeOperationRefund, removeOperationSubscription, removeOperationTransfer } from '../../../../../utils/storage';
import { formatProduct, havePermission, isIOS, isNativeApp, trackEvent } from '../../../../../utils/utils';
import InvestmentFundsCustomerPage from './InvestmentFundsCustomerPage';
import { RefundInvestmentFundsCustomerPage, SubscriptionInvestmentFundsCustomerPage, TransferInvestmentFundsCustomerPage } from './OperationsInvestmentFundsCustomerPage';
import { useToast } from '../../../../../utils/toast';
import slugify from 'react-slugify';

const OperateInvestmentFundsCustomerPage: React.FC = () => {
  const intl = useIntl();
  const screenSize = useScreenSize();
  const size = useWindowSize();
  const router = useIonRouter();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [product, setProduct] = useState(null as null|Product);
  const [contract, setContract] = useState(null as null|Contract);
  const currentUserProduct = getCurrentUserProduct();
  const currentProduct = currentUserProduct?.product;
  const [loading, setLoading] = useState(true);
  const [loadingOperation, setLoadingOperation] = useState(null as null|string);
  const [permissions, setPermissions] = useState({reembolso:false, suscripcion:false, traslado:false, operar:false, datosContrato:false, saldo:false, movimientos:false, informacion:true})
  const [options, setOptions] = useState([] as string[]);
  const { validOperate } = useValidOperate()

  useEffect(()=>{
    if(!router.canGoBack() || !currentProduct){
        router.push(getUrlNavigation(InvestmentFundsCustomerPage));
        return;
    }
    removeOperationSubscription();
    removeOperationTransfer();
    removeOperationRefund();
    const perm = {
        reembolso : havePermission(process.env.REACT_APP_MENU_FONDOS_REEMBOLSO, currentUserProduct?.menuApp),
        suscripcion : havePermission(process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION, currentUserProduct?.menuApp),
        traslado: havePermission(process.env.REACT_APP_MENU_FONDOS_TRASLADO, currentUserProduct?.menuApp),
        operar: havePermission(process.env.REACT_APP_MENU_FONDOS_REEMBOLSO, currentUserProduct?.menuApp) || havePermission(process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION, currentUserProduct?.menuApp) || havePermission(process.env.REACT_APP_MENU_FONDOS_TRASLADO, currentUserProduct?.menuApp),
        datosContrato: havePermission(process.env.REACT_APP_MENU_FONDOS_DATOS_CONTRATO, currentUserProduct?.menuApp),
        saldo: havePermission(process.env.REACT_APP_MENU_FONDOS_SALDO, currentUserProduct?.menuApp),
        movimientos: havePermission(process.env.REACT_APP_MENU_FONDOS_MOVIMIENTOS, currentUserProduct?.menuApp),
        informacion: true
    };
    setPermissions(perm);

    let opts = [];
    (perm.operar) && opts.push(intl.formatMessage({id:'page_customer_investment_funds_actions_operate'}));
    perm.datosContrato && opts.push(intl.formatMessage({id:'page_customer_investment_funds_actions_contract_data'}));
    perm.saldo && opts.push(intl.formatMessage({id:'ppage_customer_investment_funds_actions_balance'}));
    perm.movimientos && opts.push(intl.formatMessage({id:'page_customer_investment_funds_actions_movements'}));
    perm.informacion && opts.push(intl.formatMessage({id:'page_customer_investment_funds_actions_fund_information'}));
    setOptions(opts);

    getInfoContract({numContract: currentUserProduct?.numContrato ?? '', cdCompany:currentProduct?.cdCompania ?? -1, cdPlan: currentProduct?.cdPlan ?? '', cdFund: currentProduct?.cdFondo?? '', cdSubplan: currentProduct?.cdSubplan?? ''}).then((result)=>{
        if(result){
            setContract(result);
            if(result.plan){
                setProduct(result.plan)
            }
        }
    }).finally(()=>{
        setLoading(false);
    });
  }, []);

  useEffect(()=>{
    switch (selectedIndex){
      case 1:
        trackStadistics('PAGE', '_DATOS_CONTRATO' );
        break;
      case 2:
        trackStadistics('PAGE', '_SALDO' );
        break;
      case 3:
        trackStadistics('PAGE', '_MOVIMIENTOS' );
        break;
      case 4:
        trackStadistics('PAGE', '_INFORMACION_FONDO' );
        break;
      default:
        trackStadistics('PAGE', '_OPERAR' );
        break;
    }
  }, [selectedIndex]);

  const handleOperation = (operation:string): Promise<void> =>
    new Promise((resolve) => {
        setLoadingOperation(operation);

        switch(operation){
            case process.env.REACT_APP_MENU_FONDOS_REEMBOLSO:
                trackEvent('perfil-fondo-'+formatProduct(currentProduct)+'-operar-reembolso');
                validOperate(parseInt(operation), ()=>{router.push(getUrlNavigation(RefundInvestmentFundsCustomerPage))}).finally(()=>{setLoadingOperation(null); resolve()});
                break;
            case process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION:
                trackEvent('perfil-fondo-'+formatProduct(currentProduct)+'-operar-suscripcion');
                validOperate(parseInt(operation), ()=>{router.push(getUrlNavigation(SubscriptionInvestmentFundsCustomerPage))}).finally(()=>{setLoadingOperation(null); resolve()});
                break;
            case process.env.REACT_APP_MENU_FONDOS_TRASLADO:
                trackEvent('perfil-fondo-'+formatProduct(currentProduct)+'-operar-traslado');
                validOperate(parseInt(operation), ()=>{router.push(getUrlNavigation(TransferInvestmentFundsCustomerPage))}).finally(()=>{setLoadingOperation(null); resolve()});
                break;
        }
  });

  return (
        <><LoadingBar visible={loading} />
            {!screenSize.isDesktopOrBigger &&
                <Touchable className={'info-button--operatePlan '+(isNativeApp ? ' is-native-app ' : '')+(isIOS ? ' is-ios ' : '')} onPress={()=>{setShowFilter(!showFilter)}}>
                    <IconInformationUserRegular color={skinVars.colors.background}></IconInformationUserRegular>
                </Touchable>
            }

            <LayoutPrivateArea
                seo={{metaTitle: currentProduct?.descripcion}}
                title={currentProduct?.descripcion}
                parents={[{item:InvestmentFundsCustomerPage, title: intl.formatMessage({id:'page_investment_funds_customer_metatitle'})}]}
                pageInfo={{option: process.env.REACT_APP_MENU_FONDOS_INVERSION, role:'client'}}
                showBack>

                <ResponsiveLayout className={'info--operatePlan'+(screenSize.isDesktopOrBigger ? ' inner first' : ' fullscreen fixed padding--none ')+(showFilter ? ' visible' : ' hide')}>
                    <Box paddingBottom={screenSize.isDesktopOrBigger ? 32 : 0}>
                        {screenSize.isDesktopOrBigger &&
                            <Text5>{currentProduct?.descripcion}</Text5>
                        }
                    </Box>

                    <ContractInfo loading={loading} contract={contract} />
                </ResponsiveLayout>

                <ResponsiveLayout className={!screenSize.isDesktopOrBigger ? 'fullScreen tabs--fixed' : 'inner'} backgroundColor={!screenSize.isDesktopOrBigger ? skinVars.colors.backgroundAlternative : 'transparent'}>
                    <Box paddingTop={screenSize.isDesktopOrBigger ? 16 : 0}>
                        <Tabs
                            selectedIndex={selectedIndex}
                            onChange={setSelectedIndex}
                            tabs={options.map((text) => ({
                                text,
                            }))}
                        />
                    </Box>
                </ResponsiveLayout>

                {permissions.operar &&
                    <ResponsiveLayout className={'inner '+(options[selectedIndex]===intl.formatMessage({id:'page_customer_investment_funds_actions_operate'}) ? 'd-block' : 'd-none')+(!screenSize.isDesktopOrBigger ? ' with-tabs--fixed' : '')}>

                        <Box paddingY={screenSize.isDesktopOrBigger ? 32 : 16} className='contractData--content'>
                            <Stack space={16}>
                                <Box>
                                    {screenSize.isDesktopOrBigger &&
                                        <Box paddingBottom={16}>
                                            {loading ?
                                                <SkeletonLine />
                                            :
                                                <Text4 medium>{intl.formatMessage({id:'page_investment_funds_customer_operate'})}</Text4>
                                            }
                                        </Box>
                                    }
                                </Box>

                                <Grid columns={size.width > 768 ? 3 : 1} gap={16}>
                                    {permissions.suscripcion &&
                                        (loading ?
                                            <SkeletonRectangle height={250} />
                                        :
                                        <DataCard
                                            icon={
                                                <IconDollarSymbolCircleRegular size={32} color={skinVars.colors.brand} />
                                            }
                                            title= {intl.formatMessage({id:'page_customer_investment_funds_actions_make_subscription_title'})}
                                            description= {intl.formatMessage({id:'page_customer_investment_funds_actions_make_subscription_description'})}
                                            buttonLink={
                                                <ButtonLink showSpinner={loadingOperation===process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION?? '19'} onPress={()=>{handleOperation(process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION?? '19')}}>
                                                    {intl.formatMessage({id:'page_investment_funds_customer_begin'})}
                                                    <IconArrowLineRightRegular color="currentColor" />
                                                </ButtonLink>
                                            }
                                        />)
                                    }

                                    {permissions.traslado &&
                                        (loading ?
                                            <SkeletonRectangle height={250} />
                                        :
                                        <DataCard
                                            icon={
                                                <IconThrowLightRegular size={32} color={skinVars.colors.brand} />
                                            }
                                            title={intl.formatMessage({id:'page_investment_funds_customer_make_transfer'})}
                                            description={intl.formatMessage({id:'page_investment_funds_customer_make_transfer_description'})}
                                            buttonLink={
                                                <ButtonLink showSpinner={loadingOperation===process.env.REACT_APP_MENU_FONDOS_TRASLADO?? '20'} onPress={()=>{handleOperation(process.env.REACT_APP_MENU_FONDOS_TRASLADO?? '20')}}>
                                                    {intl.formatMessage({id:'page_investment_funds_customer_begin'})}
                                                    <IconArrowLineRightRegular color="currentColor" />
                                                </ButtonLink>
                                            }
                                        />)
                                    }

                                    {permissions.reembolso &&
                                        (loading ?
                                            <SkeletonRectangle height={250} />
                                        :
                                        <DataCard
                                            icon={
                                                <IconWalletRegular size={32} color={skinVars.colors.brand} />
                                            }
                                            title={intl.formatMessage({id:'page_investment_funds_customer_make_refund'})}
                                            description={intl.formatMessage({id:'page_investment_funds_customer_make_refund_description'})}
                                            buttonLink={
                                                <ButtonLink showSpinner={loadingOperation===process.env.REACT_APP_MENU_FONDOS_REEMBOLSO?? '18'} onPress={()=>{handleOperation(process.env.REACT_APP_MENU_FONDOS_REEMBOLSO?? '18')}}>
                                                    {intl.formatMessage({id:'page_investment_funds_customer_begin'})}
                                                    <IconArrowLineRightRegular color="currentColor" />
                                                </ButtonLink>
                                            }
                                        />)
                                    }
                                </Grid>
                            </Stack>
                        </Box>

                    </ResponsiveLayout>
                }

                {permissions.datosContrato &&
                    <ResponsiveLayout className={'inner '+(options[selectedIndex]===intl.formatMessage({id:'page_customer_investment_funds_actions_contract_data'}) ? 'd-block' : 'd-none')+(!screenSize.isDesktopOrBigger ? ' with-tabs--fixed' : '')}>

                        <ContractData loading={loading} contract={contract} />

                    </ResponsiveLayout>
                }


                {permissions.saldo &&
                    <ResponsiveLayout className={'inner '+(options[selectedIndex]===intl.formatMessage({id:'ppage_customer_investment_funds_actions_balance'}) ? 'd-block' : 'd-none')+(!screenSize.isDesktopOrBigger ? ' with-tabs--fixed' : '')}>

                        <BalanceRights loading={loading} contract={contract} />

                    </ResponsiveLayout>
                }


                {permissions.movimientos &&
                    <ResponsiveLayout className={'inner '+(options[selectedIndex]===intl.formatMessage({id:'page_customer_investment_funds_actions_movements'}) ? 'd-block' : 'd-none')+(!screenSize.isDesktopOrBigger ? ' with-tabs--fixed' : '')}>

                        <Movements loading={loading} contract={contract} />

                    </ResponsiveLayout>
                }

                {permissions.informacion &&
                    <ResponsiveLayout className={'inner '+(options[selectedIndex]===intl.formatMessage({id:'page_customer_investment_funds_actions_fund_information'}) ? 'd-block' : 'd-none')+(!screenSize.isDesktopOrBigger ? ' with-tabs--fixed' : '')}>
                        <ProductInformation loading={loading} product={product} />

                    </ResponsiveLayout>
                }



            </LayoutPrivateArea>
        </>
    );
};

export default OperateInvestmentFundsCustomerPage;
