

import { useIonRouter } from '@ionic/react';
import { Box, Boxed, ButtonPrimary, ButtonSecondary, Checkbox, DecimalField, EmailField, Form, IconInformationUserRegular, Inline, RadioButton, RadioGroup, ResponsiveLayout, Stack, Tag, Text, Text2, Text4, Text5, TextField, TextLink, Tooltip, skinVars, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import EmptyStateCustom from '../../../../components/empty-state/EmptyStateCustom';
import HeroCalculator from '../../../../components/hero/HeroCalculator';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import { getUrlNavigation } from '../../../../utils/navigation';
import { trackEvent } from '../../../../utils/utils';
import ContactPage from '../../contact/ContactPage';
import ForInvestorPage from '../ForInvestorPage';
import './Calculators.scss';

const ComplementarySavingsCalculatorPage: React.FC = () => {
	const intl = useIntl()
	const screenSize = useScreenSize();
	const router = useIonRouter();
	const [step, setStep] = useState(1);

	return (
		<LayoutPublic
			seo={{metaTitle: intl.formatMessage({id:'page_complementary_savings_calculator_seo_title'}), metaDescription:intl.formatMessage({id:'page_complementary_savings_calculator_seo_description'})}}
			header={{title:intl.formatMessage({id:'page_complementary_savings_calculator_title'}), parents:[{title:intl.formatMessage({id:'page_for_investor_page_title'}), item:ForInvestorPage}]}}
		>
			<HeroCalculator
				title={intl.formatMessage({id:'page_complementary_savings_calculator_title'})}
				description={intl.formatMessage({id:'page_complementary_savings_calculator_description'})}
				children={
					<Boxed className='calculatorBox'>
						<ResponsiveLayout backgroundColor={skinVars.colors.background}>
							<Box paddingX={24} paddingY={32}>
                                <Form onSubmit={() => {}}>
                                    <Stack space={16}>
                                        <TextField
                                            fullWidth
                                            name="age"
                                            label={intl.formatMessage({id:'page_calculator_label_age'})}
                                        />

                                        <DecimalField
                                            fullWidth
                                            name="annual_salary"
                                            label={intl.formatMessage({id:'page_calculator_label_annual_salary'})}
                                        />

                                        <DecimalField
                                            fullWidth
                                            name="quoted_years"
                                            label={intl.formatMessage({id:'page_calculator_label_quoted_years'})}
                                        />

                                        <DecimalField
                                            fullWidth
                                            name="quoted_years"
                                            label={intl.formatMessage({id:'page_calculator_label_savings_other_plans'})}
                                        />

                                        <ButtonPrimary onPress={() => {}}>
                                            {intl.formatMessage({id:'page_calculator_button_calculate'})}
                                        </ButtonPrimary>
                                    </Stack>
                                </Form>
							</Box>
						</ResponsiveLayout>
					</Boxed>
				}
			></HeroCalculator>

			{/* calculation results */}
			<ResponsiveLayout className='content' fullWidth={true}>
				<ResponsiveLayout className='calculatorPage--content'>

					<Box paddingY={screenSize.isTabletOrBigger ? 64 : 32}>
						<Stack space={16}>
							<Text5>{intl.formatMessage({id:'page_calculator_result_title'})}</Text5>
						</Stack>
					</Box>

					{/* ** RESULT ** */}
					<Box paddingX={screenSize.isDesktopOrBigger ? 80 : (screenSize.isTabletOrBigger ? 40 : 0)} paddingY={screenSize.isTabletOrBigger ? 48 : 32} className='result--card'>

					</Box>

				</ResponsiveLayout>
			</ResponsiveLayout>

			<ResponsiveLayout className='content' fullWidth={true}>
                <ResponsiveLayout>

                    <EmptyStateCustom
                        largeImageUrl="../assets/img/necesitas-ayuda.jpg"
                        title={intl.formatMessage({id:'page_for_investor_empty_state_title'})}
                        description={intl.formatMessage({id:'page_for_investor_empty_state_description'})}
                        button={
                            <ButtonGroup
                                primaryButton={
                                    <ButtonPrimary onPress={() => {router.push(getUrlNavigation(ContactPage)); trackEvent('para_inversor-calculadoras-'+slugify(intl.formatMessage({id:'page_complementary_savings_calculator_title'})).replaceAll('-', '_')+'-contacto');}}> <img src={'../assets/img/contactar.svg'}/>
                                        {intl.formatMessage({id:'page_for_investor_empty_state_action_primary'})}
                                    </ButtonPrimary>
                                }
                            ></ButtonGroup>
                        }
                    />

                </ResponsiveLayout>
			</ResponsiveLayout>
		</LayoutPublic>
	);
};

export default ComplementarySavingsCalculatorPage;