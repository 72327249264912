import { Box, Boxed, ButtonPrimary, ButtonSecondary, DateField, Divider, Grid, IconButton, IconDownloadRegular, Inline, LoadingBar, MonthField, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Tabs, Text, Text1, Text2, Text3, Text4, Text5, TextField, useScreenSize } from '@telefonica/mistica';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import { OtherQueriesControlCommision } from '../../../../../entities/promoters-control-commision/other-queries-control-commision';
import { getConsolidatedRightsValueControlCommision, getOtherQueriesControlCommision, getParticipantsVariationBeneficiariesControlCommision, getPaymentTotalBenefitsControlCommision, getTotalsContributionsControlCommision, getTransfers, trackStadistics } from '../../../../../utils/apiclient';
import { getSelectedPlanComisionControl, getUser } from '../../../../../utils/storage';
import { useTranslate } from '../../../../../utils/translates';
import { formatAmount, formatBalance, trackEvent } from '../../../../../utils/utils';
import './OtherQueriesControlCommisionPage.scss';

const OtherQueriesControlCommisionPage: React.FC = () => {

  const intl = useIntl()
  const {formatMonth} = useTranslate();
  const screenSize = useScreenSize();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [data, setData] = useState({} as OtherQueriesControlCommision);
  let planComisionControlSelected = '';

  const [dateClosingData, setDateClosingData]= useState(undefined as undefined|string);
  const [dateMaxClosingData, setDateMaxClosingData]= useState(undefined as undefined|Date);


  const [dateRights, setDateRights]= useState(undefined as undefined|string);
  const [dateMaxRights, setDateMaxRights]= useState(undefined as undefined|Date);


  const [dateTotalContributions, setDateTotalContributions]= useState(undefined as undefined|string);
  const [dateMaxTotalContributions, setDateMaxTotalContributions]= useState(undefined as undefined|Date);


  const [dateTotalBenefits, setDateTotalBenefits]= useState(undefined as undefined|string);
  const [dateMaxTotalBenefits, setDateMaxTotalBenefits]= useState(undefined as undefined|Date);


  const [dateTransfers, setDateTransfers]= useState(undefined as undefined|string);
  const [dateMaxTransfers, setDateMaxTransfers]= useState(undefined as undefined|Date);
  useEffect(()=>{
    getOtherQueries();
  }, []);
  
  useEffect(()=>{
    switch (selectedIndex){
      case 1:
        trackStadistics('PAGE', '_DERECHOS_CONSOLIDADOS' );
        break;
      case 2:
        trackStadistics('PAGE', '_APORTACIONES' );
        break;
      case 3:
        trackStadistics('PAGE', '_PAGO_PRESTACIONES' );
        break;
      case 4:
        trackStadistics('PAGE', '_TRASLADOS' );
        break;
      default:
        trackStadistics('PAGE', '_CONSULTA_DATOS' );
        break;
    }
  }, [selectedIndex]);

  const getOtherQueries = ()=>{


    const planCC = getSelectedPlanComisionControl();


    if(planCC){

      const newComisionControlPlanSelected = getUser()?.selectedPlanComisionControl??'';
      if(newComisionControlPlanSelected && newComisionControlPlanSelected.length>0 && newComisionControlPlanSelected!=planComisionControlSelected){
        planComisionControlSelected = newComisionControlPlanSelected;

        setLoading(true);
        getOtherQueriesControlCommision().then(result=>{
          if(result){
            setData(result);
            if(result.datosCierre?.fechaDatosCierre){
              setDateClosingData(moment(result.datosCierre?.fechaDatosCierre).format("YYYY-MM").toString());
              setDateMaxClosingData(moment(result.datosCierre?.fechaDatosCierre).toDate());
            }
            if(result.derechos?.valorLiquidativo?.fechaValor){
              setDateRights(moment(result.derechos?.valorLiquidativo?.fechaValor).format("YYYY-MM").toString());
              setDateMaxRights(moment(result.derechos?.valorLiquidativo?.fechaValor).toDate());
            }
            if(result.aportacionesTotales?.fechaHasta){
              setDateTotalContributions(moment(result.aportacionesTotales?.fechaHasta).format("YYYY-MM").toString());
              setDateMaxTotalContributions(moment(result.aportacionesTotales?.fechaHasta).toDate());
            }
            if(result.prestacionesTotales?.fechaHasta){
              setDateTotalBenefits(moment(result.prestacionesTotales?.fechaHasta).format("YYYY-MM").toString());
              setDateMaxTotalBenefits(moment(result.prestacionesTotales?.fechaHasta).toDate());
            }
            if(result.traslados?.fechaHasta){
              setDateTransfers(moment(result.traslados?.fechaHasta).format("YYYY-MM").toString());
              setDateMaxTransfers(moment(result.traslados?.fechaHasta).toDate());
            }
          }
        }).finally(()=>{
          setLoading(false);
        });
      }
    }
  };

  const handleDateClosingData = (val:string)=>{
    setDateClosingData(val);
  }

  const searchParticipantsVariationBeneficiaries  = () : Promise<void> =>
    new Promise((resolve) => {
      trackEvent('comision-otras_consultas-'+slugify(intl.formatMessage({id:'page_control_commision_other_queries_closing_data_title'})).replaceAll('-', '_'));
      setLoadingForm(true);
      getParticipantsVariationBeneficiariesControlCommision(parseInt(moment(dateClosingData).format('MM')), parseInt(moment(dateClosingData).format('YYYY'))).then((result)=>{
        if(result){
          const newData={
            ...data,
            datosCierre: result
          };
          setData(newData);
        }
      }).finally(()=>{
        setLoadingForm(false);
        resolve();
      })
    });

    const handleDateRights = (val:string)=>{
      setDateRights(val);
    }

    const searchConsolidatedRightsValue  = () : Promise<void> =>
      new Promise((resolve) => {
        trackEvent('comision-otras_consultas-'+slugify(intl.formatMessage({id:'page_control_commision_other_queries_rights_title'})).replaceAll('-', '_'));
        setLoadingForm(true);
        getConsolidatedRightsValueControlCommision(parseInt(moment(dateRights).format('MM')), parseInt(moment(dateRights).format('YYYY'))).then((result)=>{
          if(result){
            const newData={
              ...data,
              derechos: result
            };
            setData(newData);
          }
        }).finally(()=>{
          setLoadingForm(false);
          resolve();
        })
      });

    const handleDateTotalContributions = (val:string)=>{
      setDateTotalContributions(val);
    }

    const searchTotalsContributions  = () : Promise<void> =>
      new Promise((resolve) => {
        trackEvent('comision-otras_consultas-'+slugify(intl.formatMessage({id:'page_control_commision_other_queries_total_contributions_title'})).replaceAll('-', '_'));
        setLoadingForm(true);
        getTotalsContributionsControlCommision(parseInt(moment(dateTotalContributions).format('MM')), parseInt(moment(dateTotalContributions).format('YYYY'))).then((result)=>{
          if(result){
            const newData={
              ...data,
              aportacionesTotales: result
            };
            setData(newData);
          }
        }).finally(()=>{
          setLoadingForm(false);
          resolve();
        })
      });

    const handleDateTotalBenefits = (val:string)=>{
      setDateTotalBenefits(val);
    }

    const searchPaymentTotalBenefits  = () : Promise<void> =>
      new Promise((resolve) => {
        trackEvent('comision-otras_consultas-'+slugify(intl.formatMessage({id:'page_control_commision_other_queries_total_benefits_title'})).replaceAll('-', '_'));
        setLoadingForm(true);
        getPaymentTotalBenefitsControlCommision(parseInt(moment(dateTotalBenefits).format('MM')), parseInt(moment(dateTotalBenefits).format('YYYY'))).then((result)=>{
          if(result){
            const newData={
              ...data,
              prestacionesTotales: result
            };
            setData(newData);
          }
        }).finally(()=>{
          setLoadingForm(false);
          resolve();
        })
      });

    const handleDateTransfers = (val:string)=>{
      setDateTransfers(val);
    }

    const searchTransfers  = () : Promise<void> =>
      new Promise((resolve) => {
        trackEvent('comision-otras_consultas-'+slugify(intl.formatMessage({id:'page_control_commision_other_queries_query_transfers_title'})).replaceAll('-', '_'));
        setLoadingForm(true);
        getTransfers(parseInt(moment(dateTransfers).format('MM')), parseInt(moment(dateTransfers).format('YYYY'))).then((result)=>{
          if(result){
            const newData={
              ...data,
              traslados: result
            };
            setData(newData);
          }
        }).finally(()=>{
          setLoadingForm(false);
          resolve();
        })
      });

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_control_commision_other_queries_title'})}}
      pageInfo={{option: process.env.REACT_APP_AREA_COMISION_CONTROL_OTRAS_CONSULTAS, role:'control-commision'}}
      title={intl.formatMessage({id:'page_control_commision_other_queries_title'})}
      onChangeUser={()=>{getOtherQueries()}}
      showMenu>
      <LoadingBar visible={loading || loadingForm} />
      <ResponsiveLayout className='inner first'>

        {screenSize.isDesktopOrBigger &&
          <Box>
            <Inline space={'between'} alignItems='center'>
              <Text5>{intl.formatMessage({id:'page_control_commision_other_queries_title'})}</Text5>
            </Inline>
          </Box>
      }
      </ResponsiveLayout>
      <ResponsiveLayout className={'otherQueriesControlCommision--tabs '+(!screenSize.isDesktopOrBigger ? 'fullScreen tabs--fixed' : 'inner')} backgroundColor={!screenSize.isDesktopOrBigger ? skinVars.colors.backgroundAlternative : 'transparent'}>
        <Tabs
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
            tabs={[intl.formatMessage({id:'page_control_commision_other_queries_closing_data_title'}), intl.formatMessage({id:'page_control_commision_other_queries_rights_title'}), intl.formatMessage({id:'page_control_commision_other_queries_total_contributions_title'}), intl.formatMessage({id:'page_control_commision_other_queries_total_benefits_title'}), intl.formatMessage({id:'page_control_commision_other_queries_query_transfers_title'})].map((text) => ({
                text,
            }))}/>
      </ResponsiveLayout><style>{
          '.otherQueriesControlCommision--content .boxValue--color{background-color:'+skinVars.colors.backgroundAlternative+';}'
      }</style>
      <ResponsiveLayout className={'inner otherQueriesControlCommision--content '+(selectedIndex===0 ? 'd-block' : 'd-none')+(loadingForm ? ' loading' : '')}>
        <Box paddingY={32}>
          <Stack space={40}>
            <Stack space={32}>
                {loading ?
                    <SkeletonRectangle height={23} />
                :
                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_closing_data_closing_title'},{month:formatMonth(moment(data.datosCierre?.fechaDatosCierre ?? dateClosingData).format('MM')), year: moment(data.datosCierre?.fechaDatosCierre ?? dateClosingData).year()})}</Text3>
                }

              <Inline space={32}>
                <Box paddingTop={16}>
                    <Inline space={'around'}>

                        {loading ?
                          <Stack space={16}>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={23} />
                            </Box>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={60} />
                            </Box>
                          </Stack>
                        :
                          <Stack space={16}>
                              <Box className='boxValue'>
                                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_closing_data_shares'})}</Text3>
                              </Box>

                              <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                                <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.datosCierre?.numParticipes ? formatAmount(data.datosCierre?.numParticipes, true) : ' ND '}</Text4>
                              </Box>
                          </Stack>
                        }
                    </Inline>
                </Box>

                <Box paddingTop={16}>
                    <Inline space={'around'}>

                        {loading ?
                          <Stack space={16}>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={23} />
                            </Box>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={60} />
                            </Box>
                          </Stack>
                        :
                          <Stack space={16}>
                              <Box className='boxValue'>
                                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_closing_data_beneficiaries'})}</Text3>
                              </Box>

                              <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                                <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.datosCierre?.numBeneficiarios ? formatAmount(data.datosCierre?.numBeneficiarios, true) : ' ND '}</Text4>
                              </Box>
                          </Stack>
                        }
                    </Inline>
                </Box>
              </Inline>
            </Stack>
              {!loading &&
                <Stack space={16}>
                  <MonthField value={dateClosingData} max={dateMaxClosingData} onChangeValue={(val)=>{handleDateClosingData(val)}} name="dateClosingData" label="Mes y año" />

                  <ButtonPrimary disabled={!dateClosingData} onPress={searchParticipantsVariationBeneficiaries}>{intl.formatMessage({id:'page_control_commision_other_queries_action_calculate'})}</ButtonPrimary>
                </Stack>
              }
          </Stack>
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner otherQueriesControlCommision--content '+(selectedIndex===1 ? 'd-block' : 'd-none')+(loadingForm ? ' loading' : '')}>
        <Box paddingY={32}>
          <Stack space={40}>
            <Stack space={32}>
              {loading ?
                <SkeletonRectangle height={23} />
              :
                <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_rights_closing_title'},{month:formatMonth(moment(data.derechos?.valorLiquidativo?.fechaValor ?? dateRights).format('MM')), year: moment(data.derechos?.valorLiquidativo?.fechaValor ?? dateRights).year()})}</Text3>
              }


              {loading ?
                  <Inline space={12} alignItems={'center'}>
                    <Box className='boxValue'>
                      <SkeletonRectangle height={60} />
                    </Box>
                  </Inline>
                :
                <Inline space={12} alignItems={'center'}>
                  <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                      <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.derechos?.derechos ? formatBalance(data.derechos?.derechos/1000)+'€' : ' ND '}</Text4>
                  </Box>
                  <Text3 regular>{intl.formatMessage({id:'page_control_commision_other_queries_rights_thousands'})}</Text3>
                </Inline>
              }
            </Stack>

            {!loading &&
              <Stack space={16}>
                <MonthField value={dateRights} max={dateMaxRights} onChangeValue={(val)=>{handleDateRights(val)}} name="dateRights" label="Mes y año" />

                <ButtonPrimary onPress={searchConsolidatedRightsValue}>{intl.formatMessage({id:'page_control_commision_other_queries_action_calculate'})}</ButtonPrimary>
              </Stack>
            }
          </Stack>
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner otherQueriesControlCommision--content '+(selectedIndex===2 ? 'd-block' : 'd-none')+(loadingForm ? ' loading' : '')}>
      <Box paddingY={32}>
          <Stack space={40}>
            <Stack space={32}>
              {loading ?
                <SkeletonRectangle height={23} />
              :
                <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_total_contributions_closing_title'},{month:formatMonth(moment(data.aportacionesTotales?.fechaHasta ?? dateTotalContributions).format('MM')), year: moment(data.aportacionesTotales?.fechaHasta ?? dateTotalContributions).year()})}</Text3>
              }


              {loading ?
                  <Inline space={12} alignItems={'center'}>
                    <Box className='boxValue'>
                      <SkeletonRectangle height={60} />
                    </Box>
                  </Inline>
              :
                <Inline space={32}>
                  <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                      <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.aportacionesTotales?.AportacionPromotor ? formatBalance(data.aportacionesTotales?.AportacionPromotor)+'€' : ' ND '}</Text4>
                  </Box>
                </Inline>
              }
            </Stack>

            {!loading &&
              <Stack space={16}>
                <MonthField value={dateTotalContributions} max={dateMaxTotalContributions} onChangeValue={(val)=>{handleDateTotalContributions(val)}} name="dateTotalContributions" label="Mes y año" />

                <ButtonPrimary onPress={searchTotalsContributions}>{intl.formatMessage({id:'page_control_commision_other_queries_action_calculate'})}</ButtonPrimary>
              </Stack>
            }
          </Stack>
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner otherQueriesControlCommision--content '+(selectedIndex===3 ? 'd-block' : 'd-none')+(loadingForm ? ' loading' : '')}>
      <Box paddingY={32}>
          <Stack space={40}>
            <Stack space={32}>
              {loading ?
                <SkeletonRectangle height={23} />
              :
                <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_total_benefits_closing_title'},{month:formatMonth(moment(data.prestacionesTotales?.fechaHasta ?? dateMaxTotalBenefits).format('MM')), year: moment(data.prestacionesTotales?.fechaHasta ?? dateMaxTotalBenefits).year()})}</Text3>
              }

              {loading ?
                  <Inline space={12} alignItems={'center'}>
                    <Box className='boxValue'>
                      <SkeletonRectangle height={60} />
                    </Box>
                  </Inline>
              :
                <Inline space={32}>
                  <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                      <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.prestacionesTotales?.AportacionPromotor ? formatBalance(data.prestacionesTotales?.AportacionPromotor)+'€' : ' ND '}</Text4>
                  </Box>
                </Inline>
              }
            </Stack>

            {!loading &&
              <Stack space={16}>
                <MonthField value={dateTotalBenefits} max={dateMaxTotalBenefits} onChangeValue={(val)=>{handleDateTotalBenefits(val)}} name="dateTotalBenefits" label="Mes y año" />

                <ButtonPrimary onPress={searchPaymentTotalBenefits}>{intl.formatMessage({id:'page_control_commision_other_queries_action_calculate'})}</ButtonPrimary>
              </Stack>
            }
          </Stack>
        </Box>
      </ResponsiveLayout>


      <ResponsiveLayout className={'inner otherQueriesControlCommision--content '+(selectedIndex===4 ? 'd-block ' : 'd-none ')+(loadingForm ? ' loading' : '')}>
        <Box paddingY={32}>
          <Stack space={40}>
            <Stack space={32}>
                {loading ?
                    <SkeletonRectangle height={23} />
                :
                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_transfer_closing_title'},{month:formatMonth(moment(data.traslados?.fechaHasta ?? dateClosingData).format('MM')), year: moment(data.traslados?.fechaHasta ?? dateClosingData).year()})}</Text3>
                }

              <Inline space={32}>
                <Box paddingTop={16}>
                    <Inline space={'around'}>

                        {loading ?
                          <Stack space={16}>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={23} />
                            </Box>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={60} />
                            </Box>
                          </Stack>
                        :
                          <Stack space={16}>
                              <Box className='boxValue'>
                                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_closing_data_shares'})}</Text3>
                              </Box>

                              <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                                <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.traslados?.trasladosEntradaParticipes ? formatAmount(data.traslados?.trasladosEntradaParticipes, true)+' €' : ' ND '}</Text4>
                              </Box>
                          </Stack>
                        }
                    </Inline>
                </Box>

                <Box paddingTop={16}>
                    <Inline space={'around'}>

                        {loading ?
                          <Stack space={16}>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={23} />
                            </Box>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={60} />
                            </Box>
                          </Stack>
                        :
                          <Stack space={16}>
                              <Box className='boxValue'>
                                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_closing_data_beneficiaries'})}</Text3>
                              </Box>

                              <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                                <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.traslados?.trasladosEntradaBeneficiarios ? formatAmount(data.traslados?.trasladosEntradaBeneficiarios, true)+' €' : ' ND '}</Text4>
                              </Box>
                          </Stack>
                        }
                    </Inline>
                </Box>
              </Inline>
            </Stack>
            <Stack space={32}>
                {loading ?
                    <SkeletonRectangle height={23} />
                :
                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_transfer_starting_title'},{month:formatMonth(moment(data.traslados?.fechaHasta ?? dateClosingData).format('MM')), year: moment(data.traslados?.fechaHasta ?? dateClosingData).year()})}</Text3>
                }

              <Inline space={32}>
                <Box paddingTop={16}>
                    <Inline space={'around'}>

                        {loading ?
                          <Stack space={16}>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={23} />
                            </Box>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={60} />
                            </Box>
                          </Stack>
                        :
                          <Stack space={16}>
                              <Box className='boxValue'>
                                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_closing_data_shares'})}</Text3>
                              </Box>

                              <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                                <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.traslados?.trasladosSalidaParticipes ? formatAmount(data.traslados?.trasladosSalidaParticipes, true)+' €' : ' ND '}</Text4>
                              </Box>
                          </Stack>
                        }
                    </Inline>
                </Box>

                <Box paddingTop={16}>
                    <Inline space={'around'}>

                        {loading ?
                          <Stack space={16}>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={23} />
                            </Box>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={60} />
                            </Box>
                          </Stack>
                        :
                          <Stack space={16}>
                              <Box className='boxValue'>
                                  <Text3 medium>{intl.formatMessage({id:'page_control_commision_other_queries_closing_data_beneficiaries'})}</Text3>
                              </Box>

                              <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                                <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.traslados?.trasladosSalidaBeneficiarios ? formatAmount(data.traslados?.trasladosSalidaBeneficiarios, true)+' €' : ' ND '}</Text4>
                              </Box>
                          </Stack>
                        }
                    </Inline>
                </Box>
              </Inline>
            </Stack>
              {!loading &&
                <Stack space={16}>
                  <MonthField value={dateTransfers} max={dateMaxTransfers} onChangeValue={(val)=>{handleDateTransfers(val)}} name="dateTransfers" label="Mes y año" />

                  <ButtonPrimary disabled={!dateTransfers} onPress={searchTransfers}>{intl.formatMessage({id:'page_control_commision_other_queries_action_calculate'})}</ButtonPrimary>
                </Stack>
              }
          </Stack>
        </Box>
      </ResponsiveLayout>

    </LayoutPrivateArea>
  );
};

export default OtherQueriesControlCommisionPage;
