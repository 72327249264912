import { Box, Grid, IconAutenticationFailureRegular, IconDartboardRegular, IconPayInvoiceRegular, IconTimeRegular, IconWalletRegular, Image, Inline, ResponsiveLayout, skinVars, Stack, Text1, Text4, Text5, useScreenSize } from '@telefonica/mistica';
import ButtonTertiary from '../../../../../components/buttons/ButtonTertiary';
import HeroImage from '../../../../../components/hero/HeroImage';
import LayoutPublic from '../../../../../components/layout/LayoutPublic';
import { getHeaderEuroHorizonte } from '../../../../../utils/navigation';
import '../../LandingPage.scss';

const EuroHorizonWalletPage: React.FC = () => {
  const screenSize = useScreenSize();

  return (
    <LayoutPublic
        landing={true}
        header={{options:getHeaderEuroHorizonte()}}
      seo={{metaTitle: 'Fonditel Euro Horizonte 2026, FI', metaDescription:'Fonditel Euro Horizonte 2026, FI'}}
    >

      <HeroImage
        image='../assets/img/fonditel-euro-horizonte.jpg'
        title='Fonditel Euro Horizonte 2026, FI'
        button={<ButtonTertiary onPress={()=>{}}>Suscríbelo</ButtonTertiary>}
      ></HeroImage>

      <ResponsiveLayout className='content' fullWidth>
        <ResponsiveLayout>

          <Box paddingTop={screenSize.isDesktopOrBigger ? 64 : 40} paddingBottom={40}>
            <div className='html-content' dangerouslySetInnerHTML={{__html:
              `<p>El fondo invertirá en una cartera diversificada y estable de 40-50 emisiones de renta fija privada (bonos corporativos) de países de la OCDE denominadas en euro con calidad crediticia media-alta y con un vencimiento inferior a 4 años.</p>`
            }}></div>
          </Box>

        </ResponsiveLayout>
      </ResponsiveLayout>

      <ResponsiveLayout className='content landingPage--content' fullWidth>
        <ResponsiveLayout>

            <Stack space={0} className='limit--block'>
                <Inline fullWidth={true} space={'evenly'} wrap={screenSize.isMobile}>
                    <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                        <Stack space={16}>
                            <Inline space={'evenly'}>
                                <IconDartboardRegular size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                            </Inline>

                            <Stack space={8}>
                                <Box className='boxAlignCenter'>
                                    <Text4 regular color={skinVars.colors.textSecondary}>Calidad crediticia media de las emisiones: BBB-</Text4>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>

                    <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                        <Stack space={16}>
                            <Inline space={'evenly'}>
                                <IconTimeRegular size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                            </Inline>

                            <Stack space={8}>
                                <Box className='boxAlignCenter'>
                                    <Text4 regular color={skinVars.colors.textSecondary}>Duración: &lt; 4 años</Text4>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>

                    <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                        <Stack space={16}>
                            <Inline space={'evenly'}>
                                <IconPayInvoiceRegular size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                            </Inline>

                            <Stack space={8}>
                                <Box className='boxAlignCenter'>
                                    <Text4 regular color={skinVars.colors.textSecondary}>Inversión mínima: 50 euros</Text4>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                </Inline>

                <Inline fullWidth={true} space={'evenly'} wrap={screenSize.isMobile}>
                    <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                        <Stack space={16}>
                            <Inline space={'evenly'}>
                                <IconWalletRegular size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                            </Inline>

                            <Stack space={8}>
                                <Box className='boxAlignCenter'>
                                    <Text4 regular color={skinVars.colors.textSecondary}>Valor liquidativo: diario</Text4>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>

                    <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                        <Stack space={16}>
                            <Inline space={'evenly'}>
                                <IconAutenticationFailureRegular size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                            </Inline>

                            <Stack space={8}>
                                <Box className='boxAlignCenter'>
                                    <Text4 regular color={skinVars.colors.textSecondary}>Código ISIN: ES013766002</Text4>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                </Inline>
            </Stack>

        </ResponsiveLayout>
      </ResponsiveLayout>

      <ResponsiveLayout className='content' fullWidth>
        <ResponsiveLayout>

            <Box paddingY={screenSize.isDesktopOrBigger ? 64 : 32}>
                <Stack space={16}>
                    <Stack space={screenSize.isDesktopOrBigger ? 32 : 16}>
                        <Text5>Distribución orientativa de la cartera del fondo en función del rating, del peso sectorial**</Text5>

                        <Grid columns={screenSize.isTabletOrBigger ? 2 : 1} gap={screenSize.isDesktopOrBigger ? 40 : 16}>
                            <Image src="../assets/img/peso-sectores.jpg" aspectRatio="16:9" />

                            <Image src="../assets/img/distribucion-rating.jpg" aspectRatio="16:9" />
                        </Grid>
                    </Stack>

                    <Text1 regular>** Se trata de un dato orientativo en función de mercado a cierre de la construcción de la cartera Los datos están basados en la distribución por rating y sectorial del Índice Bonos Corporativos 3-5 años, grado Inversión, zona euro (ER02).</Text1>
                </Stack>
            </Box>

        </ResponsiveLayout>
      </ResponsiveLayout>

    </LayoutPublic>
  );
};

export default EuroHorizonWalletPage;