import { Box, ButtonFixedFooterLayout, ButtonLayout, ButtonLink, ButtonPrimary, ButtonSecondary, Callout, Checkbox, dialog, Form, FormValues, Grid, IconIdCardFilled, IconInformationUserLight, IconInformationUserRegular, Inline, LoadingBar, PasswordField, skinVars, Stack, Text2, Text3, Text4, TextLink, useScreenSize } from '@telefonica/mistica';
import { useIntl } from 'react-intl';

import React, { useEffect, useState } from 'react';
import { Contract } from '../../entities/contract/contract';
import { ProductDocument } from '../../entities/products/product-document';
import { getDocument, isValidOperationsKey } from '../../utils/apiclient';
import { getContinueToContract, getSignState, setOperationKey } from '../../utils/storage';
import { formatProduct, isNativeApp, trackEvent } from '../../utils/utils';

interface ConfirmOperationProps {
    contract: Contract | null,
    loading?: boolean,
	inProcessContract?: boolean,
    onCancel?:() => void,
    onConfirm:() => void,
    onSign:()=> Promise<void>,
    children?: any
}

const ConfirmOperation: React.FC<ConfirmOperationProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const [contract, setContract] = useState(null as null|Contract);
    const [loadingDownload, setLoadingDownload] = React.useState(false);
    const [blockOperationKey, setBlockOperationKey] = React.useState(false);

    //Loading form
    const [loadingForm, setLoadingForm] = React.useState(false);

    useEffect(()=>{
        if(prop.contract){
            setContract(prop.contract);
        }
        
        const signState = getSignState();
        if(signState && signState.estaPendienteValidar){
            setBlockOperationKey(true);
        }
    },[prop.contract]);


    //Form
    const initFormData = {
        operationsKey: '',
        checkOperative: false,
        checkReadedDFP_UIS: false,
        checkReadedDFI_UIS: false
    }
    const [formData, setFormData] = useState(initFormData);
    const [formErrors, setFormErrors] = useState([] as any);



    //Handle fields
    const handleError=(name:string) =>{
        if(formErrors && formErrors[name]){
        const error = formErrors[name];
        if(error){
            return intl.formatMessage({id: 'page_operate_confirm_operations_key_'+error.toLowerCase().replaceAll(' ', '_')});
        }
        }
        return null;
    }

    const handleOpenOperative= () => {
        dialog({
            title: intl.formatMessage({id:'page_operational_web_title'}),
            message: '',
            extra: <div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_operational_web_text'})}}></div>,
            showClose: true,
            className:'dialog-without-buttons'
        })
    }

    const downloadDocument= (typeDocument:string) => {
        setLoadingDownload(true);
        let document: ProductDocument|undefined;

        switch(typeDocument){
            case 'dfp':
                document = contract?.plan?.documentacion?.find(item=>item.esElDFP)
                break;
            case 'reglamento_pp':
                document = contract?.plan?.documentacion?.find(item=>item.esReglamentoActivo)
                break;
            case 'dfi':
                document = contract?.plan?.documentacion?.find(item=>item.esElDFI)
                break;
            case 'informe_semestral_fi':
                document = contract?.plan?.documentacion?.find(item=>item.EsElUltimoInformeFI)
                break;
            case 'beneficiarios_pp':
                document = contract?.plan?.documentacion?.find(item=>item.esImpresoBeneficiariosActivo)
                break;
        }

        if(document && document.ruta && document.cdDocumento){
            getDocument(document.ruta, document.cdDocumento).finally(()=>{
              setLoadingDownload(false);
            });

        }else{
            setLoadingDownload(false);

        }
    };

    const validateField = (name:string, value:any)=>{
          return true;
    }

    const handleChangeAnyField= (name:string, value:any) => {
        const newValues = {
            ...formData,
            [name]: value,
        };
        setFormData(newValues);

        if(handleError(name)){
            delete formErrors[name];
        }
        validateField(name, value);
    };


    const isSubmitDisabled = (validateOperationsKey:boolean=true) => {
        if(getContinueToContract()){
            validateOperationsKey = false;
        }

        return (validateOperationsKey && formData.operationsKey.length===0) ||
                //(contract?.plan?.individual!==process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS && !formData.checkOperative) ||
                !formData.checkOperative ||

                (prop.inProcessContract && contract?.plan?.cdCompania===parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1') && contract?.plan?.individual===process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL && !formData.checkReadedDFP_UIS) ||

                (contract?.plan?.cdCompania===parseInt(process.env.COMPANIA_FONDITEL_FONDOS ?? '3') && !formData.checkReadedDFI_UIS);
    };


    const handleDigitalSign = (): Promise<void> =>
        new Promise((resolve) => {
            trackEvent('perfil-'+(contract?.plan?.cdCompania===parseInt(process.env.COMPANIA_FONDITEL_FONDOS ?? '3') ? 'fondo' : 'plan')+'-'+(formatProduct(contract?.plan)).replaceAll('-', '_')+'-'+(prop.inProcessContract ? 'contratar' : 'operar')+'-firmar_dnie');
            setLoadingForm(true);
            if(prop.onSign){
                prop?.onSign().then(()=>{
                    setLoadingForm(false);
                    resolve();
                })
            }

        });

    const handleSubmitContinueToContract= (values: FormValues): Promise<void> =>
        new Promise((resolve) => {
            prop.onConfirm();
            resolve();
        });


    const handleSubmit = (values: FormValues): Promise<void> =>
        new Promise((resolve) => {
            setLoadingForm(true);
            isValidOperationsKey(values).then((result)=>{
                let title = intl.formatMessage({id:'sign_operations_key_error_incorrect_title'});
                let message='';
                if(result.result){
                    setOperationKey(values.operationsKey);
                    prop.onConfirm();
                    resolve();
                    return;

                }else if(result.errors){
                    const errors = result.errors as any;

                    if(errors['operationsKey']){
                        switch(errors['operationsKey']){
                            case 'operations key is blocked':
                                title = intl.formatMessage({id:'sign_operations_key_error_blocked_title'});
                                message = intl.formatMessage({id:'sign_operations_key_error_blocked_description'});
                                break;
                            case 'operations key has been blocked':
                                title = intl.formatMessage({id:'sign_operations_key_error_has_been_blocked_title'});
                                message = intl.formatMessage({id:'sign_operations_key_error_has_been_blocked_description'});
                                break;
                            case 'operations key is incorrect':
                                title = intl.formatMessage({id:'sign_operations_key_error_incorrect_title'});
                                message = result.retries && result.retries>0 ? (result.retries===1 ? intl.formatMessage({id:'sign_operations_key_error_incorrect_last_attemp'}) : intl.formatMessage({id:'sign_operations_key_error_incorrect_attemps'}, {attemps: result.retries})): '';
                                break;
                        }
                    }
                }
                dialog({
                    icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                    title: title,
                    message: message,
                    acceptText: intl.formatMessage({id:'sign_operations_key_error_action_continue'})});

                  resolve();
            }).finally(()=>{
                setLoadingForm(false);
                resolve();
            })
        });
    return (
        <>
            <LoadingBar visible={loadingForm || loadingDownload || (prop.loading ? true : false)} />
            <Form onSubmit={getContinueToContract() ? handleSubmitContinueToContract : handleSubmit} initialValues={initFormData} className={loadingForm || prop.loading ? 'loading' : ''}>
                <ButtonFixedFooterLayout
                    button={
                        <ButtonLayout>
                            {
                                prop.loading ?
                                    <ButtonPrimary onPress={()=>{}} showSpinner={prop.loading}>{intl.formatMessage({id:'page_operate_confirm_operations_sign'})}</ButtonPrimary>
                                :
                                    <ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_operate_confirm_operations_sign'})}</ButtonPrimary>
                            }
                        </ButtonLayout>
                    }
                >
                    <Box paddingBottom={40} className='confirmOperation--content'>
                        <Stack space={40}>
                            <Stack space={24}>
                                <Text4 medium>{intl.formatMessage({id:'page_operate_confirm_operations_key_title'})}</Text4>

                                <Stack space={16}>
                                    {/*contract?.plan?.individual!==process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS && */}
                                    <Checkbox onChange={(val:any)=> handleChangeAnyField('checkOperative', val)} name="checkOperative" render={({controlElement, labelId}:any) => (
                                            <Inline alignItems="center" space={16}>
                                                {controlElement}
                                                <Text3 regular id={labelId}>{intl.formatMessage({id:'page_operate_confirm_operation_check_readed_operative'},{link_operative:<TextLink onPress={()=>{handleOpenOperative()}}>{intl.formatMessage({id:'page_operate_confirm_operation_check_readed_operative_operative'})}</TextLink>})}</Text3>
                                            </Inline>
                                        )}
                                    />

                                    {prop.inProcessContract && contract?.plan?.cdCompania===parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1') && contract?.plan?.individual===process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL &&
                                        <Checkbox onChange={(val:any)=> handleChangeAnyField('checkReadedDFP_UIS', val)} name="checkReadedDFP_UIS" render={({controlElement, labelId}:any) => (
                                            <Inline alignItems="center" space={16}>
                                                {controlElement}
                                                <Text3 regular id={labelId}>{intl.formatMessage({id:'page_operate_confirm_operation_check_readed_dfp_uis'},{regulation_link:<TextLink onPress={()=>{downloadDocument('reglamento_pp')}}>{intl.formatMessage({id:'page_operate_confirm_operation_check_readed_dfp_uis_regulation'})}</TextLink>, dfp_link:<TextLink onPress={()=>{downloadDocument('dfp')}}>{intl.formatMessage({id:'page_operate_confirm_operation_check_readed_dfp_uis_dfp'})}</TextLink>})}</Text3>
                                            </Inline>
                                            )}
                                        />
                                    }

                                    {contract?.plan?.cdCompania===parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ?? '3') &&
                                        <Checkbox onChange={(val:any)=> handleChangeAnyField('checkReadedDFI_UIS', val)} name="checkReadedDFI_UIS" render={({controlElement, labelId}:any) => (
                                            <Inline alignItems="center" space={16}>
                                                {controlElement}
                                                <Text3 regular id={labelId}>{intl.formatMessage({id:'page_operate_confirm_operation_check_readed_dfi_uis'},{semi_annual_report_link:<TextLink onPress={()=>{downloadDocument('informe_semestral_fi')}}>{intl.formatMessage({id:'page_operate_confirm_operation_check_readed_dfi_uis_semi_annual_report'})}</TextLink>, dfi_link:<TextLink onPress={()=>{downloadDocument('dfi')}}>{intl.formatMessage({id:'page_operate_confirm_operation_check_readed_dfi_uis_dfi'})}</TextLink>})}</Text3>
                                            </Inline>
                                            )}
                                        />
                                    }
                                </Stack>
                            </Stack>
                            
                            {!getContinueToContract() &&
                                <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={24}>
                                    
                                    <PasswordField onChangeValue={(val:any)=> handleChangeAnyField('operationsKey', val)} error={handleError('operationsKey')!==null && blockOperationKey} helperText={blockOperationKey ? intl.formatMessage({id:'page_operate_confirm_operation_operation_key_pending_reset'}) : (handleError('operationsKey')??undefined)} disabled={blockOperationKey} fullWidth name="operationsKey" label={intl.formatMessage({id:'page_operate_confirm_operation_enter_operation_key'})} />
                                        
                                    {!isNativeApp &&
                                        <ButtonLayout align='left'>
                                            <ButtonSecondary showSpinner={prop.loading} disabled={isSubmitDisabled(false)} onPress={handleDigitalSign}>
                                                <IconIdCardFilled color="currentColor" />
                                                {intl.formatMessage({id:'page_operate_confirm_operation_access_with_electronic_id'})}
                                            </ButtonSecondary>
                                        </ButtonLayout>
                                    }
                                </Grid>
                            }

                            {prop.inProcessContract && contract?.plan?.cdCompania===parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1') && contract?.plan?.individual===process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS &&
                                <Box className='boxCallOut--info-confirm-operation'>
                                    <Callout
                                        icon={<IconInformationUserRegular color={skinVars.colors.brand} />}
                                        title={intl.formatMessage({id:'page_operate_confirm_operation_next_step'})}
                                        description={intl.formatMessage({id:'page_operate_confirm_operation_next_step_description'})}
                                    />
                                </Box>
                            }

                            {prop.inProcessContract && contract?.plan?.cdCompania===parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1') && contract?.plan?.individual===process.env.REACT_APP_PLAN_PENSIONES_INDIVIDUAL &&
                                <Box className='boxCallOut--info-confirm-operation'>
                                    <Callout
                                        icon={<IconInformationUserRegular color={skinVars.colors.brand} />}
                                        title={intl.formatMessage({id:'page_operate_confirm_operation_assign_other_payees'})}
                                        description={intl.formatMessage({id:'page_operate_confirm_operation_assign_other_payees_description'})}
                                        buttonLink={
                                            <ButtonLink onPress={() => {downloadDocument('beneficiarios_pp')}}>{intl.formatMessage({id:'page_operate_confirm_operation_download_print'})}</ButtonLink>
                                        }
                                    />
                                </Box>
                            }
                        </Stack>
                    </Box>

                </ButtonFixedFooterLayout>
            </Form>
        </>
    );
};

export default ConfirmOperation;