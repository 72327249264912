import { useScreenSize } from '@telefonica/mistica';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { OperationInProgress } from '../../entities/operationsInProgress/operation-in-progress.model';
import { getUser } from '../../utils/storage';
import { formatAmount, formatNetAssetValue } from '../../utils/utils';
import OperationInProgressDetail from './OperationInProgressDetail';


interface OperationInProgressMobilizationDetailProps {
    operation: OperationInProgress
}

const OperationInProgressMobilizationDetail: React.FC<OperationInProgressMobilizationDetailProps> = (prop) => {

    const intl = useIntl();
    const screenSize = useScreenSize();
    const [title, setTitle] = useState('' as string);
    const [items, setItems] = useState([] as Array<{title:string, value:string}>);

    useEffect(()=>{
        if(prop.operation){
            const operation = prop.operation;

            const user = getUser();

            setTitle(intl.formatMessage({ id: 'page_operations_in_progress_customer_mobilization_detail' }));

            let itemValues: Array<{title:string, value:string}> = [];
            if(!screenSize.isDesktopOrBigger){
                itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_product' }), value: operation.detalle.producto});
                itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_contract' }), value: operation.detalle.numContrato});
            }

            if(user){
                itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_headline' }), value: user.nombre+' '+user.apellidos});
                itemValues.push({title:'', value:''});
            }

            if(operation.gestoraOrigen && operation.gestoraOrigen.nombre){
                itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_manager' }), value: operation.gestoraOrigen.nombre});

                if(operation.planExterno && operation.planExterno.nombre){
                    itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_plan' }), value: operation.planExterno.nombre});
                }
            }else{
                if(operation.companiaFondoPlan && operation.companiaFondoPlan.nombre){
                    itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_manager' }), value: operation.companiaFondoPlan.nombre});
                }
                if(operation.planOrigen && operation.planOrigen.descripcion){
                    itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_detail_origin_plan' }), value: operation.codigoContratoOrigen+' - '+operation.planOrigen.descripcion});
                }
            }

            switch(operation.tipoMovilizacion){
                case process.env.REACT_APP_TRASLADO_TOTAL:
                    itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_transfer_type' }), value: intl.formatMessage({ id: 'page_operations_in_progress_customer_total_transfer' })});
                    break;

                case process.env.REACT_APP_TRASLADO_PARCIAL_IMPORTE:
                    itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_amount' }), value: formatAmount(operation.importeOperacion)+'€'});
                    break;
                case process.env.REACT_APP_TRASLADO_PARCIAL_UNIDADES:
                    itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_units' }), value: formatNetAssetValue(operation.UnidadesONumParticip)});
                    break;

                case process.env.REACT_APP_TRASLADO_PARCIAL_PORCENTAJE:
                    itemValues.push({title: intl.formatMessage({ id: 'page_operations_in_progress_customer_percentage' }), value: formatAmount(operation.porcMovilizacion)+'%'});
                    break;
            }

            setItems(itemValues);
        }

    },[]);

    return (<OperationInProgressDetail title={title} items={items} numColumns={2} />);
};

export default OperationInProgressMobilizationDetail;
