import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import EmptyCard from '../../../../../components/empty-card/EmptyCard';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';

import { Notices } from '../../../../../entities/notices/notices.model';
import { getAdvises, setReleaseState } from '../../../../../utils/apiclient';
import { formatDate } from '../../../../../utils/utils';

import { Box, Boxed, Circle, IconBellProgramRegular, Inline, LoadingBar, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Text2, Text3, Text5, useScreenSize } from '@telefonica/mistica';
import { removeCurrentUserProduct, setNumberAdvisesCache } from '../../../../../utils/storage';

const NoticesCustomerPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
  const [loading, setLoading] = useState(true);
  const [notices, setNotices] = useState([] as Notices[]);



  useEffect(()=>{
    removeCurrentUserProduct();
    setLoading(true);
    getAdvises().then(advises => {
      setLoading(false);
      setNotices(advises);
      advises.map(advice => {
        if(!advice.fechaLeido || advice.fechaLeido === null){
          setReleaseState(advice.cdInfo);
        }
      });
      setNumberAdvisesCache(null);
    });
  }, []);

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_notices_title'})}}
      title={intl.formatMessage({id:'page_notices_title'})}
      pageInfo={{option: process.env.REACT_APP_MENU_POSICION_GLOBAL, role:'client'}}
      showBack
    >
      <ResponsiveLayout className='inner first'>
      <LoadingBar visible={loading}/>
        {screenSize.isDesktopOrBigger &&
          <Box paddingBottom={40}>
            <Inline space={'between'} alignItems='center'>
              <Text5>{intl.formatMessage({id:'page_notices_title'})}</Text5>
            </Inline>
          </Box>
        }

        {notices.length === 0 && loading === false ? (
            <Box paddingY={40}>
              <EmptyCard
                icon = {<IconBellProgramRegular size={52} color={skinVars.colors.brand} />}
                title={intl.formatMessage({id:'page_notices_empty_card_title'})}
                subtitle={intl.formatMessage({id:'page_notices_empty_card_subtitle'})}
              />
            </Box>
          ) : (
            <Box paddingBottom={40}>
              {loading ?
                <Stack space={12}>
                  <SkeletonRectangle ariaLabel="loading" height={80}></SkeletonRectangle>
                  <SkeletonRectangle ariaLabel="loading" height={80}></SkeletonRectangle>
                </Stack>
              :
                <Stack space={12}>
                  {notices.map((notice) => (
                    <Boxed key={notice.cdInfo}>
                      <Box padding={16}>
                        <Inline space={'between'} alignItems="center">
                          <Stack space={2}>
                            <Text3 regular>{notice.titulo}</Text3>
                            <Text2 regular color={skinVars.colors.textSecondary}>{formatDate(notice.tsCreacion)}</Text2>
                            {notice.descripcion ? (
                                <div className='html-content' style={{ color: skinVars.colors.textSecondary }} dangerouslySetInnerHTML={{ __html: notice.descripcion }}></div>
                              ):(
                                <></>
                              )
                            }
                          </Stack>
                          {(notice.fechaLeido && notice.fechaLeido !== null) ? (
                              <></>
                            ) : (
                              <Circle size={screenSize.isDesktopOrBigger ? 19 : 11} backgroundColor={skinVars.colors.brand} />
                            )
                          }
                        </Inline>
                      </Box>
                    </Boxed>
                  ))}
                </Stack>
              }
            </Box>
          )
        }
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default NoticesCustomerPage;
