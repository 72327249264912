import { Box, ButtonDanger, ButtonLayout, ButtonPrimary, ButtonSecondary, dialog, Form, FormValues, GridLayout, IconIdCardFilled, IconInformationUserLight, PasswordField, skinVars, Stack, Text3 } from "@telefonica/mistica";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getContractsSituation, isValidOperationsKey } from "../../utils/apiclient";
import { closeDialog, isNativeApp } from "../../utils/utils";
import { setOperationKey } from "../../utils/storage";
import { useAlert } from "../alerts/Alert";



export const useSignOperationsKey = () => {

    const intl = useIntl();
    const { showAlert } = useAlert()


    const handleDigitalSign = (onSign?:()=>void): Promise<void> =>
        new Promise((resolve) => {

            closeDialog().then(()=>{
                if(onSign){
                    onSign();
                }
                resolve();
            })
        });

    const handleSubmit= (values: FormValues, acceptText: string, onConfirm:() => void, onAfterVerify:()=>void, onSign?:()=>void): Promise<void> =>
        new Promise((resolve) => {

            isValidOperationsKey(values).then((result)=>{
                let title = intl.formatMessage({id:'sign_operations_key_error_incorrect_title'});
                let message='';
                if(result.result){
                    setOperationKey(values.operationsKey);
                    resolve();
                    closeDialog();
                    onConfirm();
                    return;

                }else if(result.errors){
                    const errors = result.errors as any;

                    if(errors['operationsKey']){
                        switch(errors['operationsKey']){
                            case 'operations key is blocked':
                                title = intl.formatMessage({id:'sign_operations_key_error_blocked_title'});
                                message = intl.formatMessage({id:'sign_operations_key_error_blocked_description'});
                                break;
                            case 'operations key has been blocked':
                                title = intl.formatMessage({id:'sign_operations_key_error_has_been_blocked_title'});
                                message = intl.formatMessage({id:'sign_operations_key_error_has_been_blocked_description'});
                                break;
                            case 'operations key is incorrect':
                                title = intl.formatMessage({id:'sign_operations_key_error_incorrect_title'});
                                message = result.retries && result.retries>0 ? (result.retries===1 ? intl.formatMessage({id:'sign_operations_key_error_incorrect_last_attemp'}) : intl.formatMessage({id:'sign_operations_key_error_incorrect_attemps'}, {attemps: result.retries})): '';
                                break;
                        }
                    }
                }
                closeDialog().then(()=>{
                    dialog({
                      icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                      title: title,
                      message: message,
                      onAccept: ()=>{sign(acceptText, ()=>{onConfirm()}, ()=>{onAfterVerify()}, onSign, false)},
                      acceptText: intl.formatMessage({id:'sign_operations_key_error_action_continue'})});

                    resolve();
                });
            }).finally(()=>{
            });
        });


	const sign = (acceptText:string, onConfirm:() => void, onAfterVerify:()=>void, onSign?:()=>void, validateStatus:boolean=true) => {

        const openDialogSign=()=>{
            const showLoading=()=>{
                const buttonSubmit = document.querySelector('.button-sign-operations-key-submit') as any;
                const buttonLoading = document.querySelector('.button-sign-operations-key-loading') as any;
                document.getElementById('form-sign-operations-key')?.classList.add('loading');
                if(buttonSubmit){
                    buttonSubmit.style.display = 'none';
                }
                if(buttonLoading){
                    buttonLoading.style.display = 'block';
                }


                /*const buttonSubmitElectronic = document.querySelector('.button-sign-electronic-submit') as any;
                const buttonLoadingElectronic = document.querySelector('.button-sign-electronic-loading') as any;
                document.getElementById('form-sign-operations-key')?.classList.add('loading');
                if(buttonSubmitElectronic){
                    buttonSubmitElectronic.style.display = 'none';
                }
                if(buttonLoadingElectronic){
                    buttonLoadingElectronic.style.display = 'block';
                }*/
            }

            dialog({
                title: intl.formatMessage({id:'sign_operations_key_title'}),
                message:'',
                extra: <>
                        <Form id="form-sign-operations-key" onSubmit={(data)=>{showLoading();handleSubmit(data, acceptText, onConfirm, onAfterVerify, onSign)}}>
                        <Stack space={16}>

                            <GridLayout
                                verticalSpace={16}
                                template={"6+6"}
                                left={
                                    <PasswordField fullWidth name="operationsKey" label={intl.formatMessage({id:'sign_operations_key_input'})}></PasswordField>
                                }
                                right={isNativeApp ? <></> :
                                    <Box paddingY={4}>
                                        <ButtonLayout align='full-width'>
                                            <ButtonSecondary className="button-sign-electronic-submit"  onPress={()=>{showLoading(); handleDigitalSign(onSign)}}> <IconIdCardFilled color="currentColor" /> <Text3 regular color="currentColor">{intl.formatMessage({id:'sign_operations_key_dnie'})}</Text3></ButtonSecondary>
                                            <ButtonSecondary className="button-sign-electronic-loading" onPress={()=>{}} showSpinner={true} style={{display:'none'}}> <IconIdCardFilled color="currentColor" /> <Text3 regular color="currentColor">{intl.formatMessage({id:'sign_operations_key_dnie'})}</Text3></ButtonSecondary>
                                        </ButtonLayout>
                                    </Box>
                                }
                            />
                            <ButtonLayout align='full-width'>
                                <ButtonSecondary onPress={()=>{closeDialog()}}>
                                    {intl.formatMessage({id:'sign_operations_key_action_cancel'})}
                                </ButtonSecondary>
                                <>
                                    <ButtonDanger className="button-sign-operations-key-submit"  submit>
                                        {acceptText}
                                    </ButtonDanger>
                                    <ButtonDanger className="button-sign-operations-key-loading" onPress={()=>{}} showSpinner={true} style={{display:'none'}}>
                                        {acceptText}
                                    </ButtonDanger>
                                </>
                            </ButtonLayout>
                        </Stack>
                    </Form>
                </>,
                className:'dialog-without-buttons'
            });
        }

        closeDialog().then(()=>{

            if(validateStatus){

                getContractsSituation({}).then((result)=>{
                    if(result.signState.estado==='ACTIVO'){
                        onAfterVerify();
                        if(result.signState.estaPendienteValidar){
                            showAlert('_pending_validation_own_signature_');
                        }else{
                            openDialogSign();
                        }
                    }else{
                        onAfterVerify();
                        switch(result.signState.estado){
                            case 'INACTIVO':
                                showAlert('_inactive_own_signature_');
                                break;
                            case 'RECHAZADO':
                                showAlert('_rejected_own_signature_');
                                break;
                            case 'SIN_SOLICITAR':
                            case 'EN_TRAMITE':
                                showAlert('_inactive_own_without_request_');
                                break;
                        }
                    }
                });

            }else{
                openDialogSign();
            }


        });

        return (<></>);
	}
	return { sign };
};