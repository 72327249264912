import { Box, Divider, ResponsiveLayout, skinVars, Stack, Stepper, Text5, useScreenSize } from "@telefonica/mistica";
import { useIntl } from "react-intl";
import LayoutPrivateArea from "../../../../../components/layout/LayoutPrivateArea";

import { useIonRouter } from "@ionic/react";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import ConfirmOperation from "../../../../../components/operate/ConfirmOperation";
import ConfirmCustomerKnowledgeData from "../../../../../components/operate/ConfirmCustomerKnowledgeData";
import RefundInvestmentFunds from "../../../../../components/operate/RefundInvestmentFunds";
import SubscriptionInvestmentFunds from '../../../../../components/operate/SubscriptionInvestmentFunds';
import SummaryOperate from "../../../../../components/operate/SummaryOperate";
import TransferInvestmentFunds from "../../../../../components/operate/TransferInvestmentFunds";
import CustomThemeContext from "../../../../../context/ThemeContext";
import { Contract } from "../../../../../entities/contract/contract";
import { OperationRefund } from "../../../../../entities/contract/operations/operation-refund";
import { OperationSubscription } from "../../../../../entities/contract/operations/operation-subscription";
import { OperationTransfer } from "../../../../../entities/contract/operations/operation-transfer";
import { addRefund, addSubscription, addTransfer, getInfoContract, trackStadistics } from "../../../../../utils/apiclient";
import { getUrlNavigation } from '../../../../../utils/navigation';
import { addNumberCourseOperationsCache, getCurrentUserProduct, getOperationKey, getOperationRefund, getOperationSubscription, getOperationTransfer, getTokenSign, removeOperationKey, removeOperationRefund, removeOperationSubscription, removeOperationTransfer, removeTokenSign } from "../../../../../utils/storage";
import { scrollToTop } from '../../../../../utils/utils';
import InvestmentFundsCustomerPage from "./InvestmentFundsCustomerPage";
import OperateInvestmentFundsCustomerPage from "./OperateInvestmentFundsCustomerPage";
import { useToast } from "../../../../../utils/toast";
import moment from "moment";
import { useDigitalSign } from "../../../../../components/digital-sign/DigitalSign";

export interface OperationsInvestmentFundsCustomerPageProps {
    operation: string
  }

const OperationsInvestmentFundsCustomerPage: React.FC<OperationsInvestmentFundsCustomerPageProps> = (prop) => {
    const intl = useIntl();
    const router = useIonRouter();
    const screenSize = useScreenSize();
    const currentUserProduct = getCurrentUserProduct();
    const currentProduct = currentUserProduct?.product;
    const grandParentPage = InvestmentFundsCustomerPage;
    const [grandParentTitle, setGrandParentTitle] = useState('');
    const [loadingFirst, setLoadingFirst] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);
    const [parentTitle, setParentTitle] = useState('');
    const parentPage = OperateInvestmentFundsCustomerPage;
    const [title, setTitle] = useState('');
    const [titleResponsive, setTitleResponsive] = useState('');
    const [options, setOptions] = useState([] as string[]);
    const [loading, setLoading] = useState(true);
    const [contract, setContract] = useState(null as null|Contract);
    const [operationSubscription, setOperationSubscription] = useState(null as null|OperationSubscription);
    const [operationTransfer, setOperationTransfer] = useState(null as null|OperationTransfer);
    const [operationRefund, setOperationRefund] = useState(null as null|OperationRefund);
    const { showToast } = useToast()
    const { digitalSign } = useDigitalSign();

    useLayoutEffect(()=>{

        let opts = [];
        opts.push(intl.formatMessage({id:'page_customer_investment_funds_operations_step_operative'}));
        switch(prop.operation){
            case process.env.REACT_APP_MENU_FONDOS_REEMBOLSO:
                setTitle(intl.formatMessage({id:'page_customer_investment_funds_refund_to_the_fund'}) + ' ' + currentProduct?.descripcion??'')
                setTitleResponsive('Reembolso');
                break;
            case process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION:
                setTitle(intl.formatMessage({id:'page_customer_investment_funds_subscription_to_the_fund'}) + ' ' + currentProduct?.descripcion??'')
                setTitleResponsive('Suscripción')
                opts.push(intl.formatMessage({id:'page_customer_investment_funds_operations_step_confirm'}));
                break;
            case process.env.REACT_APP_MENU_FONDOS_TRASLADO:
                setTitle(intl.formatMessage({id:'page_customer_investment_funds_transfer_of_the_fund'}) + ' ' + currentProduct?.descripcion??'')
                setTitleResponsive('Traslado')
                opts.push(intl.formatMessage({id:'page_customer_investment_funds_operations_step_confirm'}));
                break;
        }
        opts.push(intl.formatMessage({id:'page_customer_investment_funds_operations_step_operations_key'}));
        setOptions(opts);
        setGrandParentTitle(intl.formatMessage({id:'page_investment_funds_customer_metatitle'}));
        setParentTitle(currentProduct?.descripcion??'');
      }, []);


    useEffect(()=>{
        if(!router.canGoBack() || !currentUserProduct){
            router.push(getUrlNavigation(parentPage));
            return;
        }
        setLoadingFirst(false);
        getInfoContract({numContract: currentUserProduct?.numContrato ?? '', cdCompany:currentProduct?.cdCompania ?? -1, cdPlan: currentProduct?.cdPlan ?? '', cdFund: currentProduct?.cdFondo?? '', cdSubplan: currentProduct?.cdSubplan?? ''}).then((result)=>{
            if(result){
                setContract(result);
            }
        }).finally(()=>{
            setLoading(false);
        });
    }, []);


    const summaryOperation = ()=>{
        scrollToTop();

        setOperationSubscription(getOperationSubscription());

        setOperationTransfer(getOperationTransfer());

        setOperationRefund(getOperationRefund());

        setStep(step+1)
    }

    const confirmData = ()=>{
        scrollToTop();



        setOperationSubscription(getOperationSubscription());

        setOperationTransfer(getOperationTransfer());

        setOperationRefund(getOperationRefund());


        setStep(step+1)
    }



    const confirmOperationDigitalSign= (): Promise<void> =>
        new Promise((resolve) => {
            setLoadingSave(true);

            const dateSign = moment().unix();
            if(operationSubscription){
                const opSubscriptionDS ={
                    ...operationSubscription,
                    dateSign:dateSign,
                    electronic:true,
                    confirm:true
                };
                setLoadingSave(true);
                addSubscription(opSubscriptionDS).then((result)=>{

                    if(result.tokenOperation){
                        digitalSign(result.tokenOperation
                        , ()=>{
                                confirmOperation(result.tokenOperation, dateSign);
                                resolve();
                        }, ()=>{
                            setLoadingSave(false);
                            resolve();
                        });
                    }

                }).finally(()=>{
                })
            }

            if(operationTransfer){
                const opTransferDS ={
                    ...operationTransfer,
                    dateSign:dateSign,
                    electronic:true,
                    confirm:true
                };
                setLoadingSave(true);
                addTransfer(opTransferDS).then((result)=>{

                    if(result.tokenOperation){
                        digitalSign(result.tokenOperation
                        , ()=>{
                                confirmOperation(result.tokenOperation, dateSign);
                                resolve();
                        }, ()=>{
                            setLoadingSave(false);
                            resolve();
                        });
                    }

                }).finally(()=>{
                })
            }

            if(operationRefund){
                const opRefundDS ={
                    ...operationRefund,
                    dateSign:dateSign,
                    electronic:true,
                    confirm:true
                };
                setLoadingSave(true);
                addRefund(opRefundDS).then((result)=>{

                    if(result.tokenOperation){
                        digitalSign(result.tokenOperation
                        , ()=>{
                                confirmOperation(result.tokenOperation, dateSign);
                                resolve();
                        }, ()=>{
                            setLoadingSave(false);
                            resolve();
                        });
                    }

                }).finally(()=>{
                })
            }


        });


    const confirmOperation = (tokenOperation:string|null=null, dateSign:number|null=null)=>{
        const operationsKey = getOperationKey();
        const tokenSign = getTokenSign();

        if(operationsKey || tokenSign){
            if(operationSubscription){
                const opSubscription ={
                    ...operationSubscription,
                    confirm:true,
                    operationsKey:operationsKey ? operationsKey : undefined,
                    dateSign: dateSign ? dateSign : undefined,
                    tokenSign: tokenSign ? tokenSign : undefined,
                    tokenOperation: tokenOperation ? tokenOperation : undefined,
                    electronic: tokenOperation && tokenSign && dateSign ? true : false
                };
                setLoadingSave(true);
                addSubscription(opSubscription).then((result)=>{
                    if(result.result){
                        addNumberCourseOperationsCache();
                        removeOperationSubscription();
                        removeOperationKey();
                        removeTokenSign();
                        router.goBack();
                        trackStadistics('CONFIRMED_OPERATION', undefined, undefined, 'Fondos de inversión – Suscripción – Solicitud de suscripción procesada.', 'OPERACION_CONFIRMADA' );
                        showToast({type:'ok', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_description'})});
                        return;
                    }
                    showToast({type:'ko', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_description'})});
                    setLoadingSave(false);
                }).finally(()=>{

                });
            }

            if(operationTransfer){
                const opTransfer ={
                    ...operationTransfer,
                    confirm:true,
                    operationsKey:operationsKey ? operationsKey : undefined,
                    dateSign: dateSign ? dateSign : undefined,
                    tokenSign: tokenSign ? tokenSign : undefined,
                    tokenOperation: tokenOperation ? tokenOperation : undefined,
                    electronic: tokenOperation && tokenSign && dateSign ? true : false
                };
                setLoadingSave(true);
                addTransfer(opTransfer).then((result)=>{
                    if(result.result){
                        addNumberCourseOperationsCache();
                        removeOperationTransfer();
                        removeOperationKey();
                        removeTokenSign();
                        router.goBack();
                        trackStadistics('CONFIRMED_OPERATION', undefined, undefined, 'Fondos de inversión – Traspaso – Solicitud de traslado procesada.', 'OPERACION_CONFIRMADA' );
                        showToast({type:'ok', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_description'})});
                        return;
                    }
                    showToast({type:'ko', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_description'})});
                    setLoadingSave(false);
                }).finally(()=>{

                });
            }

            if(operationRefund){
                const opRefund ={
                    ...operationRefund,
                    confirm:true,
                    operationsKey:operationsKey ? operationsKey : undefined,
                    dateSign: dateSign ? dateSign : undefined,
                    tokenSign: tokenSign ? tokenSign : undefined,
                    tokenOperation: tokenOperation ? tokenOperation : undefined,
                    electronic: tokenOperation && tokenSign && dateSign ? true : false
                };
                setLoadingSave(true);
                addRefund(opRefund).then((result)=>{
                    if(result.result){
                        addNumberCourseOperationsCache();
                        removeOperationRefund();
                        removeOperationKey();
                        removeTokenSign();
                        router.goBack();
                        trackStadistics('CONFIRMED_OPERATION', undefined, undefined, 'Fondos de inversión – Reembolso – Solicitud de reembolso procesada.', 'OPERACION_CONFIRMADA' );
                        showToast({type:'ok', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_description'})});
                        return;
                    }
                    showToast({type:'ko', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title_ko'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_description_ko'})});
                    setLoadingSave(false);
                }).finally(()=>{

                });
            }
        }
    }


    //Stepper
    const [step, setStep] = useState(0);

    return (
        <LayoutPrivateArea
            seo={{metaTitle: titleResponsive}}
            title={titleResponsive}
            parents={[{item:grandParentPage, title: grandParentTitle}, {item:parentPage, title: parentTitle}]}
            showBack={true}
            pageInfo={{option: process.env.REACT_APP_MENU_PLANES_PENSIONES, role:'client'}}>

            {loadingFirst ?
                <></>
            :
            <>
                <style>{'.stepper--operations.stepper--fixed{background: '+skinVars.colors.backgroundAlternative+';}'}</style>
                <ResponsiveLayout className={'contribution--content '+ (screenSize.isDesktopOrBigger ? 'inner first' : 'fullscreen fixed padding--none')} backgroundColor={!screenSize.isDesktopOrBigger ? skinVars.colors.backgroundAlternative : 'transparent'}>
                    {screenSize.isDesktopOrBigger &&
                        <Box paddingBottom={32}>
                            <Text5>{title}</Text5>
                        </Box>
                    }

                    <Box className={'stepper--operations' + (!screenSize.isDesktopOrBigger ? ' stepper--fixed' : '')} paddingBottom={screenSize.isDesktopOrBigger ? 32 : 0}>
                        <div className='stepper'>
                            <Stepper
                                currentIndex={step}
                                steps={options}
                            />
                        </div>
                        <Divider />
                    </Box>
                </ResponsiveLayout>

                {options[step]===intl.formatMessage({id:'page_customer_investment_funds_operations_step_operative'}) &&
                    <ResponsiveLayout className={'inner' + (!screenSize.isDesktopOrBigger ? ' with-stepper--fixed' : '')}>
                        {(prop.operation===process.env.REACT_APP_MENU_FONDOS_REEMBOLSO && <RefundInvestmentFunds loading={loading} contract={contract} onCancel={()=>{router.goBack()}} onConfirm={()=>{summaryOperation();}}  />)}
                        {(prop.operation=== process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION && <SubscriptionInvestmentFunds loading={loading} contract={contract} onCancel={()=>{router.goBack()}} onConfirm={()=>{summaryOperation();}}  />)}
                        {(prop.operation=== process.env.REACT_APP_MENU_FONDOS_TRASLADO && <TransferInvestmentFunds loading={loading} contract={contract} onCancel={()=>{router.goBack()}} onConfirm={()=>{summaryOperation();}}  />)}
                    </ResponsiveLayout>
                }

                {options[step]===intl.formatMessage({id:'page_customer_investment_funds_operations_step_confirm'}) &&
                    <>
                        <ConfirmCustomerKnowledgeData loading={loading} contract={contract} onCancel={()=>{setStep(step-1)}} onConfirm={()=>{confirmData()}} />
                    </>
                }

                {options[step]===intl.formatMessage({id:'page_customer_investment_funds_operations_step_operations_key'}) &&
                        <ResponsiveLayout className="inner">

                            <Box paddingBottom={screenSize.isDesktopOrBigger ? 56 : 16}>
                                <Stack space={40}>
                                    {operationSubscription &&
                                        <SummaryOperate
                                            title={'Suscripción'}
                                            values={operationSubscription.summary}
                                        />
                                    }
                                    {operationTransfer &&
                                        <SummaryOperate
                                            title={'Traslado'}
                                            values={operationTransfer.summary}
                                        />
                                    }
                                    {operationRefund &&
                                        <SummaryOperate
                                            title={'Reembolso'}
                                            values={operationRefund.summary}
                                        />
                                    }

                                    <ConfirmOperation loading={loadingSave} contract={contract} onCancel={()=>{router.goBack()}} onConfirm={()=>{confirmOperation()}} onSign={()=>confirmOperationDigitalSign()}></ConfirmOperation>
                                </Stack>
                            </Box>

                        </ResponsiveLayout>
                }
            </>}
        </LayoutPrivateArea>
    );
};

export default OperationsInvestmentFundsCustomerPage;


export const RefundInvestmentFundsCustomerPage: React.FC = () => {
    return (<OperationsInvestmentFundsCustomerPage operation={process.env.REACT_APP_MENU_FONDOS_REEMBOLSO ?? '18'} />);
};


export const SubscriptionInvestmentFundsCustomerPage: React.FC = () => {
    return (<OperationsInvestmentFundsCustomerPage operation={process.env.REACT_APP_MENU_FONDOS_SUSCRIPCION ?? '19'} />);
};

export const TransferInvestmentFundsCustomerPage: React.FC = () => {
    return (<OperationsInvestmentFundsCustomerPage operation={process.env.REACT_APP_MENU_FONDOS_TRASLADO ?? '20'} />);};
