import { alert, Box, Boxed, ButtonPrimary, ButtonSecondary, DateField, Divider, Grid, IconButton, IconDownloadRegular, Inline, LoadingBar, MonthField, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Tabs, Text, Text1, Text2, Text3, Text4, Text5, TextField, useScreenSize } from '@telefonica/mistica';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import { OtherQueriesPromoter } from '../../../../../entities/promoters-control-commision/other-queries';

import { exportContributionsQuery, getConsolidatedRightsValuePromoter, getContributionsQueryPromoter, getOtherQueriesPromoters, getParticipantsVariationBeneficiariesPromoter, getPaymentTotalBenefitsPromoter, getTotalsContributionsPromoters, trackStadistics } from '../../../../../utils/apiclient';
import { getSelectedPlanPromotor, getUser } from '../../../../../utils/storage';
import { useTranslate } from '../../../../../utils/translates';
import { formatAmount, formatBalance, trackEvent } from '../../../../../utils/utils';
import './OtherQueriesPromoterPage.scss';

const OtherQueriesPromoterPage: React.FC = () => {

  const intl = useIntl()
  const {formatMonth} = useTranslate();
  const screenSize = useScreenSize();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [data, setData] = useState({} as OtherQueriesPromoter);
  let promotorSelected = '';
  let promotorPlanSelected = '';

  const [dateClosingData, setDateClosingData]= useState(undefined as undefined|string);
  const [dateMaxClosingData, setDateMaxClosingData]= useState(undefined as undefined|Date);


  const [dateRights, setDateRights]= useState(undefined as undefined|string);
  const [dateMaxRights, setDateMaxRights]= useState(undefined as undefined|Date);


  const [dateTotalContributions, setDateTotalContributions]= useState(undefined as undefined|string);
  const [dateMaxTotalContributions, setDateMaxTotalContributions]= useState(undefined as undefined|Date);


  const [dateTotalBenefits, setDateTotalBenefits]= useState(undefined as undefined|string);
  const [dateMaxTotalBenefits, setDateMaxTotalBenefits]= useState(undefined as undefined|Date);


  const [dateFromContributions, setDateFromContributions]= useState(undefined as undefined|string);
  const [dateToContributions, setDateToContributions]= useState(undefined as undefined|string);
  const [idCardContributions, setIdCardContributions]= useState(undefined as undefined|string);
  const [textContributionDates, setTextContributionDates]= useState('');


  useEffect(()=>{
    getOtherQueries();
  }, []);

  useEffect(()=>{
    switch (selectedIndex){
      case 1:
        trackStadistics('PAGE', '_DERECHOS_CONSOLIDADOS' );
        break;
      case 2:
        trackStadistics('PAGE', '_APORTACIONES' );
        break;
      case 3:
        trackStadistics('PAGE', '_PAGO_PRESTACIONES' );
        break;
      case 4:
        trackStadistics('PAGE', '_TRASLADOS' );
        break;
      default:
        trackStadistics('PAGE', '_CONSULTA_DATOS' );
        break;
    }
  }, [selectedIndex]);

  const getOtherQueries = ()=>{
    const planPromotor = getSelectedPlanPromotor();

    if(planPromotor){
      const newPromotorSelected = getUser()?.selectedPromotor??'';
      const newPlanPromotorSelected = getUser()?.selectedPlanPromotor??'';

      if(newPromotorSelected && newPromotorSelected.length>0 && newPromotorSelected!=promotorSelected && newPlanPromotorSelected && newPlanPromotorSelected.length>0 && newPlanPromotorSelected!=promotorPlanSelected){
        promotorSelected = newPromotorSelected;
        promotorPlanSelected = newPlanPromotorSelected;
        setLoading(true);
        getOtherQueriesPromoters().then(result=>{
          if(result){
            setData(result);
            if(result.datosCierre?.fechaDatosCierre){
              setDateClosingData(moment(result.datosCierre?.fechaDatosCierre).format("YYYY-MM").toString());
              setDateMaxClosingData(moment(result.datosCierre?.fechaDatosCierre).toDate());
            }
            if(result.derechos?.valorLiquidativo?.fechaValor){
              setDateRights(moment(result.derechos?.valorLiquidativo?.fechaValor).format("YYYY-MM").toString());
              setDateMaxRights(moment(result.derechos?.valorLiquidativo?.fechaValor).toDate());
            }
            if(result.aportacionesTotales?.fechaHasta){
              setDateTotalContributions(moment(result.aportacionesTotales?.fechaHasta).format("YYYY-MM").toString());
              setDateMaxTotalContributions(moment(result.aportacionesTotales?.fechaHasta).toDate());
            }
            if(result.prestacionesTotales?.fechaHasta){
              setDateTotalBenefits(moment(result.prestacionesTotales?.fechaHasta).format("YYYY-MM").toString());
              setDateMaxTotalBenefits(moment(result.prestacionesTotales?.fechaHasta).toDate());
            }
            if(result.aportaciones && result.aportaciones?.length>0){
              if(result.aportaciones[0].fechaDesde){
                setDateFromContributions(moment(result.aportaciones[0].fechaDesde).format("YYYY-MM-DD").toString());
              }
              if(result.aportaciones[0].fechaHasta){
                setDateToContributions(moment(result.aportaciones[0].fechaHasta).format("YYYY-MM-DD").toString());
              }

              if(result.aportaciones[0].fechaDesde && result.aportaciones[0].fechaHasta){
                setTextContributionDates(intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_dates'},{dateFrom:moment(result.aportaciones[0].fechaDesde).format('DD/MM/YYYY'), dateTo: moment(result.aportaciones[0].fechaHasta).format('DD/MM/YYYY')}));
              }else{
                setTextContributionDates(intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_no_data'}));
              }
            }
          }
        }).finally(()=>{
          setLoading(false);
        });
      }
    }
  };

  const handleDateClosingData = (val:string)=>{
    setDateClosingData(val);
  }

  const searchParticipantsVariationBeneficiaries  = () : Promise<void> =>
    new Promise((resolve) => {
      trackEvent('promotor-otras_consultas-'+slugify(intl.formatMessage({id:'page_promoter_other_queries_closing_data_title'})).replaceAll('-', '_'));
      setLoadingForm(true);
      getParticipantsVariationBeneficiariesPromoter(parseInt(moment(dateClosingData).format('MM')), parseInt(moment(dateClosingData).format('YYYY'))).then((result)=>{
        if(result){
          const newData={
            ...data,
            datosCierre: result
          };
          setData(newData);
        }
      }).finally(()=>{
        setLoadingForm(false);
        resolve();
      })
    });

  const handleDateRights = (val:string)=>{
    setDateRights(val);
  }

  const searchConsolidatedRightsValue  = () : Promise<void> =>
    new Promise((resolve) => {
      trackEvent('promotor-otras_consultas-'+slugify(intl.formatMessage({id:'page_promoter_other_queries_rights_title'})).replaceAll('-', '_'));
      setLoadingForm(true);
      getConsolidatedRightsValuePromoter(parseInt(moment(dateRights).format('MM')), parseInt(moment(dateRights).format('YYYY'))).then((result)=>{
        if(result){
          const newData={
            ...data,
            derechos: result
          };
          setData(newData);
        }
      }).finally(()=>{
        setLoadingForm(false);
        resolve();
      })
    });

  const handleDateTotalContributions = (val:string)=>{
    setDateTotalContributions(val);
  }

  const searchTotalsContributions  = () : Promise<void> =>
    new Promise((resolve) => {
      trackEvent('promotor-otras_consultas-'+slugify(intl.formatMessage({id:'page_promoter_other_queries_total_contributions_title'})).replaceAll('-', '_'));
      setLoadingForm(true);
      getTotalsContributionsPromoters(parseInt(moment(dateTotalContributions).format('MM')), parseInt(moment(dateTotalContributions).format('YYYY'))).then((result)=>{
        if(result){
          const newData={
            ...data,
            aportacionesTotales: result
          };
          setData(newData);
        }
      }).finally(()=>{
        setLoadingForm(false);
        resolve();
      })
    });

  const handleDateTotalBenefits = (val:string)=>{
    setDateTotalBenefits(val);
  }

  const searchPaymentTotalBenefits  = () : Promise<void> =>
    new Promise((resolve) => {
      trackEvent('promotor-otras_consultas-'+slugify(intl.formatMessage({id:'page_promoter_other_queries_total_benefits_title'})).replaceAll('-', '_'));
      setLoadingForm(true);
      getPaymentTotalBenefitsPromoter(parseInt(moment(dateTotalBenefits).format('MM')), parseInt(moment(dateTotalBenefits).format('YYYY'))).then((result)=>{
        if(result){
          const newData={
            ...data,
            prestacionesTotales: result
          };
          setData(newData);
        }
      }).finally(()=>{
        setLoadingForm(false);
        resolve();
      })
    });

    const searchContributions  = () : Promise<void> =>
      new Promise((resolve) => {
        trackEvent('promotor-otras_consultas-'+slugify(intl.formatMessage({id:'page_promoter_other_queries_query_contributions_title'})).replaceAll('-', '_'));
        if(moment.duration(moment(dateToContributions).diff(moment(dateFromContributions))).asYears()>1){
          alert({
            title: intl.formatMessage({id:'alert_info_title'}),
            message: intl.formatMessage({id:'page_promoter_other_queries_query_contributions_exceed_year'})
          })
          resolve();
          return;
        }

        setLoadingForm(true);
        getContributionsQueryPromoter(idCardContributions, dateFromContributions, dateToContributions).then((result)=>{
          if(result){
            const newData={
              ...data,
              aportaciones: result
            };
            setData(newData);

            if(result.length>0 && result[0].fechaDesde && result[0].fechaHasta){
              setTextContributionDates(intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_dates'},{dateFrom:moment(result[0].fechaDesde).format('DD/MM/YYYY'), dateTo: moment(result[0].fechaHasta).format('DD/MM/YYYY')}));
            }else{
              setTextContributionDates(intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_no_data'}));
            }
          }
        }).finally(()=>{
          setLoadingForm(false);
          resolve();
        })
      });

    const exportContributions  = () : Promise<void> =>
      new Promise((resolve) => {

        setLoadingForm(true);
        exportContributionsQuery(intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_export_file'}), idCardContributions, dateFromContributions, dateToContributions).then().finally(()=>{
          setLoadingForm(false);
          resolve();
        })
      });




  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_promoter_other_queries_title'})}}
      pageInfo={{option: process.env.REACT_APP_AREA_PROMOTORES_OTRAS_CONSULTAS, role:'promoter'}}
      title={intl.formatMessage({id:'page_promoter_other_queries_title'})}
      onChangeUser={()=>{getOtherQueries()}}
      showMenu>
      <LoadingBar visible={loading || loadingForm} />

      <ResponsiveLayout className='inner first'>
        {screenSize.isDesktopOrBigger &&
          <Box>
            <Inline space={'between'} alignItems='center'>
              <Text5>{intl.formatMessage({id:'page_promoter_other_queries_title'})}</Text5>
            </Inline>
          </Box>
        }
      </ResponsiveLayout>
      <ResponsiveLayout className={'otherQueriesPromoter--tabs '+(!screenSize.isDesktopOrBigger ? 'fullScreen tabs--fixed' : 'inner')} backgroundColor={!screenSize.isDesktopOrBigger ? skinVars.colors.backgroundAlternative : 'transparent'}>
        <Box paddingTop={screenSize.isDesktopOrBigger ? 16 : 0}>
          <Tabs
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
            tabs={[intl.formatMessage({id:'page_promoter_other_queries_closing_data_title'}), intl.formatMessage({id:'page_promoter_other_queries_rights_title'}), intl.formatMessage({id:'page_promoter_other_queries_total_contributions_title'}), intl.formatMessage({id:'page_promoter_other_queries_total_benefits_title'}), intl.formatMessage({id:'page_promoter_other_queries_query_contributions_title'})].map((text) => ({
              text,
          }))}/>
        </Box>
      </ResponsiveLayout>

      <style>{
          '.otherQueriesPromoter--content .boxValue--color{background-color:'+skinVars.colors.backgroundAlternative+';}'
      }</style>
      <ResponsiveLayout className={'inner otherQueriesPromoter--content '+(selectedIndex===0 ? 'd-block' : 'd-none')+(loadingForm ? ' loading' : '')}>
          <Box paddingY={32}>
            <Stack space={40}>
              <Stack space={32}>
                {loading ?
                    <SkeletonRectangle height={23} />
                :
                  <Text3 medium>{intl.formatMessage({id:'page_promoter_other_queries_closing_data_closing_title'},{month:formatMonth(moment(data.datosCierre?.fechaDatosCierre ?? dateClosingData).format('MM')), year: moment(data.datosCierre?.fechaDatosCierre ?? dateClosingData).year()})}</Text3>
                }

                <Inline space={32}>
                  <Box paddingTop={16}>
                      <Inline space={'around'}>
                        {loading ?
                          <Stack space={16}>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={23} />
                            </Box>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={60} />
                            </Box>
                          </Stack>
                        :
                          <Stack space={16}>
                              <Box className='boxValue'>
                                <Text3 medium>{intl.formatMessage({id:'page_promoter_other_queries_closing_data_shares'})}</Text3>
                              </Box>

                              <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                                  <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.datosCierre?.numParticipes ? formatAmount(data.datosCierre?.numParticipes, true) : ' ND '}</Text4>
                              </Box>
                          </Stack>
                        }
                      </Inline>
                  </Box>

                  <Box paddingTop={16}>
                      <Inline space={'around'}>

                        {loading ?
                          <Stack space={16}>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={23} />
                            </Box>
                            <Box className='boxValue'>
                              <SkeletonRectangle height={60} />
                            </Box>
                          </Stack>
                        :
                          <Stack space={16}>
                              <Box className='boxValue'>
                                  <Text3 medium>{intl.formatMessage({id:'page_promoter_other_queries_closing_data_beneficiaries'})}</Text3>
                              </Box>

                              <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                                  <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.datosCierre?.numBeneficiarios ? formatAmount(data.datosCierre?.numBeneficiarios, true) : ' ND '}</Text4>
                              </Box>
                          </Stack>
                        }
                      </Inline>
                  </Box>
                </Inline>
              </Stack>
              {!loading &&
                <Stack space={16}>
                  <MonthField value={dateClosingData} max={dateMaxClosingData} onChangeValue={(val)=>{handleDateClosingData(val)}} name="dateClosingData" label="Mes y año" />

                  <ButtonPrimary disabled={!dateClosingData} onPress={searchParticipantsVariationBeneficiaries}>{intl.formatMessage({id:'page_promoter_other_queries_action_calculate'})}</ButtonPrimary>
                </Stack>
              }
            </Stack>
          </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner otherQueriesPromoter--content '+(selectedIndex===1 ? 'd-block' : 'd-none')+(loadingForm ? ' loading' : '')}>
        <Box paddingY={32}>
          <Stack space={40}>
            <Stack space={32}>
              {loading ?
                <SkeletonRectangle height={23} />
              :
                <Text3 medium>{intl.formatMessage({id:'page_promoter_other_queries_rights_closing_title'},{month:formatMonth(moment(data.derechos?.valorLiquidativo?.fechaValor ?? dateRights).format('MM')), year: moment(data.derechos?.valorLiquidativo?.fechaValor ?? dateRights).year()})}</Text3>
              }


              {loading ?
                  <Inline space={12} alignItems={'center'}>
                    <Box className='boxValue'>
                      <SkeletonRectangle height={60} />
                    </Box>
                  </Inline>
                :
                <Inline space={12} alignItems={'center'}>
                  <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                      <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.derechos?.derechos ? formatBalance(data.derechos?.derechos/1000)+'€' : ' ND '}</Text4>
                  </Box>
                  <Text3 regular>{intl.formatMessage({id:'page_promoter_other_queries_rights_thousands'})}</Text3>
                </Inline>
              }
            </Stack>

            {!loading &&
              <Stack space={16}>
                <MonthField value={dateRights} max={dateMaxRights} onChangeValue={(val)=>{handleDateRights(val)}} name="dateRights" label="Mes y año" />

                <ButtonPrimary onPress={searchConsolidatedRightsValue}>{intl.formatMessage({id:'page_promoter_other_queries_action_calculate'})}</ButtonPrimary>
              </Stack>
            }
          </Stack>
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner otherQueriesPromoter--content '+(selectedIndex===2 ? 'd-block' : 'd-none')+(loadingForm ? ' loading' : '')}>
        <Box paddingY={32}>
          <Stack space={40}>
            <Stack space={32}>
              {loading ?
                <SkeletonRectangle height={23} />
              :
                <Text3 medium>{intl.formatMessage({id:'page_promoter_other_queries_total_contributions_closing_title'},{month:formatMonth(moment(data.aportacionesTotales?.fechaHasta ?? dateTotalContributions).format('MM')), year: moment(data.aportacionesTotales?.fechaHasta ?? dateTotalContributions).year()})}</Text3>
              }


              {loading ?
                  <Inline space={12} alignItems={'center'}>
                    <Box className='boxValue'>
                      <SkeletonRectangle height={60} />
                    </Box>
                  </Inline>
              :
                <Inline space={32}>
                  <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                      <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.aportacionesTotales?.AportacionPromotor ? formatBalance(data.aportacionesTotales?.AportacionPromotor)+'€' : ' ND '}</Text4>
                  </Box>
                </Inline>
              }
            </Stack>

            {!loading &&
              <Stack space={16}>
                <MonthField value={dateTotalContributions} max={dateMaxTotalContributions} onChangeValue={(val)=>{handleDateTotalContributions(val)}} name="dateTotalContributions" label="Mes y año" />

                <ButtonPrimary onPress={searchTotalsContributions}>{intl.formatMessage({id:'page_promoter_other_queries_action_calculate'})}</ButtonPrimary>
              </Stack>
            }
          </Stack>
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner otherQueriesPromoter--content '+(selectedIndex===3 ? 'd-block' : 'd-none')+(loadingForm ? ' loading' : '')}>
        <Box paddingY={32}>
          <Stack space={40}>
            <Stack space={32}>
              {loading ?
                <SkeletonRectangle height={23} />
              :
                <Text3 medium>{intl.formatMessage({id:'page_promoter_other_queries_total_benefits_closing_title'},{month:formatMonth(moment(data.prestacionesTotales?.fechaHasta ?? dateMaxTotalBenefits).format('MM')), year: moment(data.prestacionesTotales?.fechaHasta ?? dateMaxTotalBenefits).year()})}</Text3>
              }

              {loading ?
                  <Inline space={12} alignItems={'center'}>
                    <Box className='boxValue'>
                      <SkeletonRectangle height={60} />
                    </Box>
                  </Inline>
              :
                <Inline space={32}>
                  <Box className='boxValue boxValue--color' paddingX={24} paddingY={16}>
                      <Text4 regular color={skinVars.colors.textButtonPrimaryInverse}>{data.prestacionesTotales?.AportacionPromotor ? formatBalance(data.prestacionesTotales?.AportacionPromotor)+'€' : ' ND '}</Text4>
                  </Box>
                </Inline>
              }
            </Stack>

            {!loading &&
              <Stack space={16}>
                <MonthField value={dateTotalBenefits} max={dateMaxTotalBenefits} onChangeValue={(val)=>{handleDateTotalBenefits(val)}} name="dateTotalBenefits" label="Mes y año" />

                <ButtonPrimary onPress={searchPaymentTotalBenefits}>{intl.formatMessage({id:'page_promoter_other_queries_action_calculate'})}</ButtonPrimary>
              </Stack>
            }
          </Stack>
        </Box>
      </ResponsiveLayout>


      <ResponsiveLayout className={'inner otherQueriesPromoter--content '+(selectedIndex===4 ? 'd-block' : 'd-none')+(loadingForm ? ' loading' : '')}>
        <Box paddingY={32}>
          <Stack space={screenSize.isDesktopOrBigger ? 40 : 16}>
            {loading ?
              <Grid columns={screenSize.isDesktopOrBigger ? 4 : 1} gap={screenSize.isDesktopOrBigger ? 16 : 12}>
                <SkeletonRectangle height={60} />
                <SkeletonRectangle height={60} />
                <SkeletonRectangle height={60} />
                <SkeletonRectangle height={60} />
              </Grid>
            :
              <Grid columns={screenSize.isDesktopOrBigger ? 4 : 1} gap={screenSize.isDesktopOrBigger ? 16 : 12}>
                <DateField name="dateFromContributions" value={dateFromContributions} onChangeValue={(val)=>setDateFromContributions(val)} label={intl.formatMessage({id:'page_promoter_other_queries_query_contributions_date_from'})} fullWidth />

                <DateField name="dateToContributions" value={dateToContributions} onChangeValue={(val)=>setDateToContributions(val)} label={intl.formatMessage({id:'page_promoter_other_queries_query_contributions_date_to'})} fullWidth />

                <TextField name="idCard" value={idCardContributions} onChangeValue={(val)=>setIdCardContributions(val)} label={intl.formatMessage({id:'page_promoter_other_queries_query_contributions_idcard'})} fullWidth />

                <ButtonPrimary onPress={searchContributions}>{intl.formatMessage({id:'page_promoter_other_queries_action_calculate'})}</ButtonPrimary>
              </Grid>
            }
            {screenSize.isDesktopOrBigger ?
              <Box paddingBottom={40}>
                <Stack space={8} className='table--otherQueriesPromoter'>
                  {!loading &&
                    <Stack space={8}>
                      <Inline space="between">
                        <Box className='col-1'>
                          <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_period'})}</Text>
                        </Box>

                        <Box className='col-2'>
                          <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_participants'})}</Text>
                        </Box>

                        <Box className='col-3'>
                          <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_promotor'})}</Text>
                        </Box>

                        <Box className='col-4'>
                          <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_action_download'})}</Text>
                        </Box>
                      </Inline>

                      <Divider/>
                    </Stack>
                  }

                  {loading ?
                    <Stack space={8}>
                      <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                      <Divider />
                      <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>

                    </Stack>
                  :
                    <Stack space={8}>
                      <Inline space='between' alignItems='center' className='row'>
                        <Box className='col-1'>
                          <Text2 regular>{textContributionDates}</Text2>
                        </Box>

                        <Box className='col-2'>
                          <Text2 regular>{data?.aportaciones && data?.aportaciones.length>0 && data?.aportaciones[0].AportacionParticipe ? formatAmount(data?.aportaciones[0]?.AportacionParticipe)+' €' : ' ND ' }</Text2>
                        </Box>

                        <Box className='col-3'>
                          <Text2 regular>{data?.aportaciones && data?.aportaciones.length>0 && data?.aportaciones[0].AportacionPromotor ? formatAmount(data?.aportaciones[0]?.AportacionPromotor)+' €' : ' ND ' }</Text2>
                        </Box>

                        <Box className='col-4'>
                          <IconButton onPress= {exportContributions} aria-label="Icon">
                            <IconDownloadRegular color={skinVars.colors.brand} />
                          </IconButton>
                        </Box>
                      </Inline>

                      <Divider />
                    </Stack>
                  }
                </Stack>
              </Box>
            :
              <>
                {loading ?
                  <Stack space={12}>
                    <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                  </Stack>
                :
                  <Stack space={12}>
                    <Boxed>
                      <Box paddingX={16} paddingY={24}>
                          <Stack space={16}>
                            <Stack space={12}>
                              <Text4 medium>{textContributionDates}</Text4>

                              <Grid columns={2} gap={8}>
                                <Stack space={2}>
                                  <Text1 regular>{intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_promotor'})}</Text1>
                                  <Text3 medium>{data?.aportaciones && data?.aportaciones.length>0 && data?.aportaciones[0].AportacionPromotor ? formatAmount(data?.aportaciones[0]?.AportacionPromotor)+' €' : ' ND ' }</Text3>
                                </Stack>

                                <Stack space={2}>
                                  <Text1 regular>{intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_participants'})}</Text1>
                                  <Text3 medium>{data?.aportaciones && data?.aportaciones.length>0 && data?.aportaciones[0].AportacionParticipe ? formatAmount(data?.aportaciones[0]?.AportacionParticipe)+' €' : ' ND ' }</Text3>
                                </Stack>
                              </Grid>
                            </Stack>

                            <ButtonSecondary onPress={exportContributions}>
                              {intl.formatMessage({id:'page_promoter_other_queries_query_contributions_contributions_action_download'})}
                              <IconDownloadRegular color={skinVars.colors.brand} />
                            </ButtonSecondary>
                          </Stack>
                        </Box>
                    </Boxed>
                  </Stack>
                }
              </>
            }
          </Stack>
        </Box>
      </ResponsiveLayout>

    </LayoutPrivateArea>
  );
};

export default OtherQueriesPromoterPage;
