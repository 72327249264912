import { Box, Boxed, ButtonLayout, ButtonLink, ButtonPrimary, dialog, Form, Grid, IconCheckedRegular, IconInformationUserLight, IconTrashCanRegular, Inline, LoadingBar, ResponsiveLayout, Select, skinVars, Stack, Text2, Text4, TextField, Touchable, useScreenSize, useWindowSize } from '@telefonica/mistica';
import React, { useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { Client } from '../../entities/clients/client';

import { useIonRouter } from '@ionic/react';
import { UserInvalidException } from '../../entities/clients/user-invalid-exception';
import { Contract } from '../../entities/contract/contract';
import { OperationContractHolders } from '../../entities/contract/operations/operation-contract-holders';
import { getUserByNif, isValidContracHolders } from '../../utils/apiclient';
import { getCurrentUserContract, getOperationContract, removeOperationSubscription, setOperationContractHolders } from '../../utils/storage';
import SubscriptionInvestmentFunds from './SubscriptionInvestmentFunds';
import TransferInvestmentFunds from './TransferInvestmentFunds';

interface ContractHoldersInvestmentFundsProps {
    contract: Contract | null,
	client?: Client,
    loading?: boolean,
	subscription?: boolean,
	transfer?: boolean,
    onCancel?:() => void,
    onConfirm:() => void
}

const ContractHoldersInvestmentFunds: React.FC<ContractHoldersInvestmentFundsProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const size = useWindowSize();
    const router = useIonRouter();
    const [contract, setContract] = useState(null as null|Contract);
    const [contractData, setContractData] = useState(getOperationContract());
    const [contractHolders, setContractHolders] = useState([] as Client[]);
    const [contractType, setContractType] = useState(process.env.REACT_APP_TITULARES_CONTRATO_SOLIDARIO ?? 'S' );
    const [idCard, setIdCard] = useState('' as string);


    const [loading, setLoading] = React.useState(true);

	//DataCardStep 0
	const [dataCardStep, setDataCardStep] = React.useState(0);
	const editDataCard= (cardStep:any) => {
	  setDataCardStep(cardStep);
	};


    //Loading form
    const [loadingForm, setLoadingForm] = React.useState(false);

    useEffect(()=>{
        removeOperationSubscription();
        if(prop.contract){
            setContract(prop.contract);

            const uc = getCurrentUserContract();
            if(uc){
                setContract(uc);
            }
            setLoading(false);

        }
    },[prop.contract]);

    const handleRemoveContractHolder= (idCard: string): Promise<void> =>
        new Promise((resolve) => {
            let listContractHolders: Client[] = [] ;
            contractHolders.map((item, index)=>{
                if(item.nif!=idCard){
                    listContractHolders.push(item);
                }
            });
            setContractHolders(listContractHolders);
            resolve();
        });



    const handleSubmit = (data: any): Promise<void> =>
        new Promise((resolve) => {
            setLoadingForm(true);
            if(idCard){
                if(idCard.replace(/^0+/g, "")=== (prop?.client?.nif ?? '').replace(/^0+/g, "") || contractHolders.find(item=>(item.nif ?? '').replace(/^0+/g, "")===idCard.replace(/^0+/g, ""))){
                    setLoadingForm(false);
                    dialog({
                        icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                        title: intl.formatMessage({id:'page_operate_contract_holders_repeat_idcard'}),
                        message: '',
                        acceptText: intl.formatMessage({id:'page_operate_contract_holders_invalid_action_continue'})});
                    resolve();
                    return;
                }

                getUserByNif(idCard).then((result)=>{

                    if(result.estaEnTablaClientes){
                        let listContractHolders: Client[] = [] ;
                        contractHolders.map((item, index)=>{
                            listContractHolders.push(item);
                        });
                        listContractHolders.push({nif: result.nif, nombre: result.nombre, apellidos: result.apellidos});
                        setContractHolders(listContractHolders);
                        setIdCard('');
                        setLoadingForm(false);
                        resolve();
                    }else{

                    }
                }).catch((reason)=>{
                    setLoadingForm(false);
                    const userInvalid = reason.response.data as UserInvalidException;
                    if(userInvalid && userInvalid.message){
                        switch(userInvalid.message){
                            case 'NIF is not valid':
                                dialog({
                                    icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                                    title: intl.formatMessage({id:'page_operate_contract_holders_invalid_idcard'}),
                                    message: '',
                                    acceptText: intl.formatMessage({id:'page_operate_contract_holders_invalid_action_continue'})});
                                break;
                            case 'NIF is not register':
                                dialog({
                                    icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                                    title: intl.formatMessage({id:'page_operate_contract_holders_invalid_idcard'}),
                                    message: '',
                                    acceptText: intl.formatMessage({id:'page_operate_contract_holders_invalid_action_continue'})});

                                break;
                        }
                        resolve();
                        return;
                    }
                }).finally(()=>{
                });
            }

        });
    const handleSaveContractHolders= (): Promise<void> =>
        new Promise((resolve) => {

            if(contractData){
                setLoadingForm(true);

                let contractFund : OperationContractHolders={
                    contractType: contractType,
                    contractData: contractData,
                    contractHolders: contractHolders,
                    confirm:true
                };

                isValidContracHolders(contractFund).then((result)=>{
                    contractFund.summary = getSummary();
                    if(result && result.result){
                        setOperationContractHolders(contractFund);
                        setDataCardStep(dataCardStep+1);
                    }else{
                        dialog({
                            icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                            title: intl.formatMessage({id:'alert_info_title'}),
                            message: intl.formatMessage({id:'page_operate_contract_holders_same_contract_holders'}),
                            acceptText: intl.formatMessage({id:'page_operate_contract_holders_invalid_action_continue'})});
                    }

                }).finally(()=>{
                    setLoadingForm(false);
                    resolve();
                })
            }
        });




    const getSummary= () => {
        let summary: Array<{title:string, value:string}> = [];

        summary.push({title:intl.formatMessage({id:'page_operate_contract_holders_get_summary_type_of_contract'}), value: contractType===process.env.REACT_APP_TITULARES_CONTRATO_SOLIDARIO ? 'Solidario' : 'Mancomunado' });

        if(contractData){
            summary.push({title:intl.formatMessage({id:'page_operate_contract_holders_get_summary_name_lastname'}), value: contractData.name+' '+contractData.surname});
            summary.push({title:intl.formatMessage({id:'page_operate_contract_holders_get_summary_nif'}), value: contractData.idCard??''});
        }
        contractHolders.forEach(item=>{
            summary.push({title:'', value: '' });
            summary.push({title:intl.formatMessage({id:'page_operate_contract_holders_get_summary_name_lastname'}), value: item.nombre+' '+item.apellidos});
            summary.push({title:intl.formatMessage({id:'page_operate_contract_holders_get_summary_nif'}), value: item.nif??''});
        })

        return summary;
    };
    return (
        <>{prop.loading ?
            <></>
        :
            <>
                <LoadingBar  visible={loading || loadingForm} />
                <Box paddingBottom={32}>
                    <ResponsiveLayout className='form'>
                        <Stack space={16}>
                            <Boxed>
                            <Box padding={32}>
                                <Form id='form-confirm-data' autoJump onSubmit={handleSubmit} className={loadingForm ? 'loading' : ''}>
                                    <Stack space={32}>
                                        <Inline space={'between'} alignItems='center'>
                                            <Inline space={8} alignItems='center'>
                                                <Text4 medium color={dataCardStep===0 ? skinVars.colors.buttonPrimaryBackground : skinVars.colors.neutralMedium}>{'Titulares'}</Text4>
                                                {dataCardStep>0 &&
                                                    <IconCheckedRegular size={24} color={skinVars.colors.buttonPrimaryBackground}></IconCheckedRegular>
                                                }
                                            </Inline>
                                            {dataCardStep>0 &&
                                                <ButtonLink onPress={()=>editDataCard(0)}>{intl.formatMessage({id:'page_register_actions_edit'})}</ButtonLink>
                                            }
                                        </Inline>
                                        {dataCardStep===0 &&
                                        <>
                                            <Stack space={12}>
                                                <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={12}>
                                                    <Boxed>
                                                        <Box padding={16}>
                                                            <Stack space={16}>
                                                                <Inline space={'between'} alignItems='center'>
                                                                    <Text4 medium>{intl.formatMessage({id:'page_operate_contract_holders_headline'})} 1</Text4>
                                                                </Inline>
                                                                <Grid columns={1} gap={12}>
                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_contract_holders_get_summary_nif'})}</Text2>
                                                                        <Text2 medium>{prop?.client?.nif}</Text2>
                                                                    </Stack>
                                                                    <Stack space={4}>
                                                                        <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_contract_holders_get_summary_name_lastname'})}</Text2>
                                                                        <Text2 medium>{(prop?.client?.nombre ?? '')+' '+(prop?.client?.apellidos ?? '')}</Text2>
                                                                    </Stack>
                                                                </Grid>
                                                            </Stack>
                                                        </Box>
                                                    </Boxed>
                                                    {contractHolders.map((item, index)=>(
                                                        <Boxed key={'contract-holders-'+index}>
                                                            <Box padding={16}>
                                                                <Stack space={16}>
                                                                    <Inline space={'between'} alignItems='center'>
                                                                        <Text4 medium>{intl.formatMessage({id:'page_operate_contract_holders_headline'})} {index+2}</Text4>
                                                                        <Touchable onPress={()=>{handleRemoveContractHolder(item.nif ?? '')}}>
                                                                            <Inline space={8} alignItems='center'>
                                                                                <IconTrashCanRegular color={skinVars.colors.buttonDangerBackground} />
                                                                                <Text2 regular color={skinVars.colors.buttonDangerBackground}>{intl.formatMessage({id:'page_operate_contract_holders_delete'})}</Text2>
                                                                            </Inline>
                                                                        </Touchable>
                                                                    </Inline>
                                                                    <Grid columns={1} gap={12}>
                                                                        <Stack space={4}>
                                                                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_contract_holders_get_summary_nif'})}</Text2>
                                                                            <Text2 medium>{item.nif}</Text2>
                                                                        </Stack>
                                                                        <Stack space={4}>
                                                                            <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operate_contract_holders_get_summary_name_lastname'})}</Text2>
                                                                            <Text2 medium>{(item.nombre ?? '')+' '+(item.apellidos ?? '')}</Text2>
                                                                        </Stack>
                                                                    </Grid>
                                                                </Stack>
                                                            </Box>
                                                        </Boxed>
                                                    ))}
                                                </Grid>

                                                <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={12}>
                                                    {contractHolders.length>0 &&
                                                        <Inline space={12} alignItems='center'>
                                                            <Select fullWidth
                                                                name="contractType"
                                                                value={contractType}
                                                                onChangeValue={(val)=>{setContractType(val)}}
                                                                label={intl.formatMessage({id:'page_operate_contract_holders_get_summary_type_of_contract'})}
                                                                options={[
                                                                { value: process.env.REACT_APP_TITULARES_CONTRATO_SOLIDARIO ?? 'S', text: "Solidario" },
                                                                { value: process.env.REACT_APP_TITULARES_CONTRATO_MANCOMUNADO ?? 'M', text: "Mancomunado" },
                                                                ]}
                                                            />
                                                        </Inline>
                                                    }
                                                    <Inline space={12} alignItems='flex-start'>
                                                        <TextField fullWidth value={idCard} onChangeValue={(val)=>{setIdCard(val)}} name="idCard" label={intl.formatMessage({id:'page_operate_contract_holders_get_summary_nif'})}></TextField>

                                                        <ButtonLayout withMargins align={screenSize.isMobile ? 'full-width' : 'left'}>
                                                            <ButtonPrimary disabled={!idCard || idCard?.length==0} small submit>{intl.formatMessage({id:'page_operate_contract_holders_add_holder'})}</ButtonPrimary>
                                                        </ButtonLayout>
                                                    </Inline>
                                                </Grid>

                                                <Box paddingTop={16}>
                                                    <ButtonLayout align='left'>
                                                        <ButtonPrimary onPress={handleSaveContractHolders}>{'Continuar'}</ButtonPrimary>
                                                    </ButtonLayout>
                                                </Box>
                                            </Stack>
                                        </>
                                        }
                                    </Stack>
                                </Form>
                            </Box>
                            </Boxed>
                            <Boxed>
                                <Box padding={32}>
                                    <Stack space={32}>
                                        <Inline space={'between'} alignItems='center'>
                                            <Inline space={8} alignItems='center'>
                                            <Text4 medium color={dataCardStep===1 ? skinVars.colors.buttonPrimaryBackground : skinVars.colors.neutralMedium}>
                                                {prop.subscription && 'Suscripción'}
                                                {prop.transfer && 'Traspaso'}
                                            </Text4>
                                                {dataCardStep>1 &&
                                                    <IconCheckedRegular size={24} color={skinVars.colors.buttonPrimaryBackground}></IconCheckedRegular>
                                                }
                                            </Inline>
                                            {dataCardStep>1 &&
                                                <ButtonLink onPress={()=>editDataCard(1)}>{intl.formatMessage({id:'page_register_actions_edit'})}</ButtonLink>
                                            }
                                        </Inline>
                                        {dataCardStep===1 &&
                                            <>
                                                {prop.subscription && <SubscriptionInvestmentFunds inProcessContract={true} loading={loading} contract={contract} onCancel={prop.onCancel} onConfirm={prop.onConfirm} />}
                                                {prop.transfer && <TransferInvestmentFunds inProcessContract={true} loading={loading} contract={contract} onCancel={prop.onCancel} onConfirm={prop.onConfirm} />}
                                            </>
                                        }
                                    </Stack>
                                </Box>
                            </Boxed>
                        </Stack>
                    </ResponsiveLayout>
                </Box>
            </>
        }</>
    );
};

export default ContractHoldersInvestmentFunds;