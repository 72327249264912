import { ResponsiveLayout } from '@telefonica/mistica';
import { useIntl } from 'react-intl';
import ContactForm from '../../../../components/contact-form/ContactForm';
import LayoutPrivateArea from '../../../../components/layout/LayoutPrivateArea';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import { getUser } from '../../../../utils/storage';


const UserContactPage: React.FC = () => {
  const intl = useIntl()

  const user = getUser();

  return (<>
    {user ?
    <LayoutPrivateArea
      title={intl.formatMessage({id:'page_contact_title'})}
      showBack
    >
      <ResponsiveLayout className='inner first'>
        <ContactForm></ContactForm>
      </ResponsiveLayout>
    </LayoutPrivateArea>

    :
      <LayoutPublic
        seo={{metaTitle: intl.formatMessage({id: 'page_contact_title'})}}
        header={{hide:true}}
        hideFooter={true}
        requiresLoggedIn={false}
        showBack>
      <ResponsiveLayout className='inner first'>
        <ContactForm></ContactForm>
      </ResponsiveLayout>
    </LayoutPublic>
    }
</>
);
};

export default UserContactPage;
