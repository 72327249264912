import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { closeDialog } from "../../../../../utils/utils";

import { ButtonDanger, ButtonLayout, ButtonSecondary, dialog, Form, FormValues, Stack, TextField } from "@telefonica/mistica";
import { setReasonDeleteOperationInProgress } from "../../../../../utils/storage";
import { trackStadistics } from "../../../../../utils/apiclient";

export const useRejectOperationInProgress= () => {

    const intl = useIntl();
    
    const [formData, setFormData] = useState({
        numSolicitud: "",
        rechazo: ""
      });
      
    const handleChangeField= (evt:any) => {
        const { target } = evt;
        const { name, value } = target;
        const newValues = {
          ...formData,
          [name]: value,
        };
        setFormData(newValues);
    };

    const handleSubmit= (values: FormValues, onConfirm:() => void): Promise<void> =>
        new Promise((resolve) => {
            trackStadistics('CANCELAR_OPERACION', undefined, undefined, 'Operación cancelada.' );
            setReasonDeleteOperationInProgress([formData.numSolicitud, values.rechazo]);
            onConfirm();
            return;                
    });

	const rejectOperationInProgress = (numSolicitud:string, onConfirm:() => void) => {

        formData.numSolicitud = numSolicitud;

        closeDialog().then(()=>{

            const showLoading=()=>{
                const buttonSubmit = document.querySelector('.button-sign-operations-key-submit') as any;
                const buttonLoading = document.querySelector('.button-sign-operations-key-loading') as any;
                document.getElementById('form-sign-operations-progress')?.classList.add('loading');
                if(buttonSubmit){
                    buttonSubmit.style.display = 'none';
                }
                if(buttonLoading){
                    buttonLoading.style.display = 'block';
                }
            }

            dialog({
                title: 'Indica el motivo del rechazo',
                message:'',
                extra: <>
                        <Form id="form-sign-operations-progress" initialValues={formData} onSubmit={(data)=>{showLoading(); handleSubmit(data, onConfirm)}}>
                            <Stack space={32}>
                                <TextField fullWidth name="rechazo" label="Escriba el motivo" multiline onChange={handleChangeField}/>
                                <ButtonLayout align='full-width'>
                                    <ButtonSecondary onPress={()=>{closeDialog()}}>
                                        {intl.formatMessage({id:'page_operations_in_progress_reject_operation_button2'})}
                                    </ButtonSecondary>
                                    <>
                                        <ButtonDanger className="button-sign-operations-key-submit"  submit>
                                            {intl.formatMessage({id:'page_operations_in_progress_reject_operation_button1'})}
                                        </ButtonDanger>
                                        <ButtonDanger className="button-sign-operations-key-loading" onPress={()=>{}} showSpinner={true} style={{display:'none'}}>
                                            {intl.formatMessage({id:'page_operations_in_progress_reject_operation_button1'})}
                                        </ButtonDanger>
                                    </>
                                </ButtonLayout>
                            </Stack>
                        </Form>
                    </>,
                className:'dialog-without-buttons'
            });
        });
        return (<></>);
	}
	return { rejectOperationInProgress };
};