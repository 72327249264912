import { useState } from "react";
import { useIntl } from "react-intl";
import { useIonRouter } from "@ionic/react";

import FormalizeContractSignaturit from "../../pages/private/signContract/FormalizeContractSignaturit";
import DataProtectionPage from "../../pages/public/data-protection/DataProtectionPage";

import { addContact, downloadFile, formalizeOperationKey, formalizeSignUserData, generateRecoveryOperationsKey, trackStadistics } from "../../utils/apiclient";
import { getCurrentLang } from "../../utils/locate";
import { getUrlNavigation } from "../../utils/navigation";
import { getUser } from "../../utils/storage";
import { closeDialog, isNativeApp } from '../../utils/utils';
import { useFormalizeOperationKey } from "../operations-key/FormalizeOperationKey";

import { ButtonLayout, ButtonPrimary, ButtonSecondary, Checkbox, EmailField, Form, FormValues, GridLayout, IconInformationUserLight, Inline, PasswordField, PhoneNumberField, Stack, Text3, TextField, TextLink, alert, dialog, skinVars } from "@telefonica/mistica";
import { useToast } from "../../utils/toast";
import ContactPage from "../../pages/public/contact/ContactPage";
import UserContactPage from "../../pages/private/users/fonditel/UserContactPage";
import UserDataProtectionPage from "../../pages/private/users/fonditel/UserDataProtectionPage";


export const useAlert = () => {

    const intl = useIntl();
    const router = useIonRouter();
    const user = getUser();
    const { showToast } = useToast()
    const { formalize } = useFormalizeOperationKey();

    const [loading, setLoading] = useState(false);

    const [formDataFormalize, setFormDataFormalize] = useState({
        email: user && user.email ? user.email.toLowerCase() : "",
        phone: user && user.telefono ? user.telefono : "",
        mobile: user && user.movil ? user.movil : "",
      });

    const [formDataCalculator, setFormDataCalculator] = useState({
        name: "",
        lastName: "",
        email: "",
        phone: "",
        formType: "",
        comentary: "",
        checkConditions: false
    });

    const handleSubmitFormalize= (values: FormValues): Promise<void> =>
        new Promise((resolve) => {
            setLoading(true);
            formalizeSignUserData({email: values.email, phone: values.phone, mobile: values.mobile, lang: getCurrentLang()}).then((result)=>{
                if(result.result = 'ok'){
                    formalize('Continuar', () => {router.push(getUrlNavigation(FormalizeContractSignaturit))})
                    setLoading(false);
                    resolve();
                }else{
                    setLoading(false);
                    resolve();
                }
            });
    });

    const handleSubmitCalculator= (values: any, parametroAdicional: any): Promise<void> =>
        new Promise((resolve) => {
            setLoading(true);
            values.formType =  parametroAdicional;
            values.comentary = '';
            setLoading(false);
            addContact(values).then(response=>{
                if(response && response.result === true){
                    showToast({type:'ok', title: intl.formatMessage({id:'alert_addcontact_ok_title'}), description: intl.formatMessage({id:'alert_addcontact_ok_description'})});
                }else{
                    showToast({type:'ko', title: intl.formatMessage({id:'alert_addcontact_ko_title'}), description: intl.formatMessage({id:'alert_addcontact_ko_description'})});
                }
            }).finally(()=>{
                closeDialog();
            });
            resolve();
    });

    const handleSubmitReport= (values: any, parametroAdicional: any): Promise<void> =>
        new Promise((resolve) => {
            setLoading(true);
            parametroAdicional = parametroAdicional.split('&');

            values.formType = parametroAdicional[0];
            values.comentary = '';
            setLoading(false);
            addContact(values).then(response=>{
                if(response && response.result === true){
                    downloadFile(parametroAdicional[0]+'.pdf', parametroAdicional[1], {}, false).then(()=>{
                        showToast({type:'ok', title: intl.formatMessage({id:'alert_addcontact_ok_title'}), description: intl.formatMessage({id:'alert_addcontact_ok_description'})});
                        trackStadistics('DOWNLOAD_DOCUMENT', '', parametroAdicional[0]);
                        closeDialog();
                        resolve();
                    })
                }else{
                    showToast({type:'ko', title: intl.formatMessage({id:'alert_addcontact_ko_title'}), description: intl.formatMessage({id:'alert_addcontact_ko_description'})});
                    closeDialog();
                    resolve();
                }
            });
    });

    const handleChangeField= (evt:any) => {
        const { target } = evt;
        const { name, value } = target;
        const newValues = {
            ...formDataFormalize,
            [name]: value,
        };
        setFormDataFormalize(newValues);
    };

    const handleChangeFieldCalculator= (evt:any) => {
        const { target } = evt;
        const { name, value } = target;
        const newValues = {
            ...formDataCalculator,
            [name]: value,
        };
        setFormDataCalculator(newValues);
    };

    const handleSubmitOperationKey= (values: FormValues): Promise<void> =>
        new Promise((resolve) => {

            if(values.new_operationKey !== values.confirm_new_operationKey){
                closeDialog().then(()=>{
                    dialog({
                      icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                      title: intl.formatMessage({id:'page_change_operations_key_operations_key_not_match'}),
                      message: '',
                      onAccept: ()=>{showAlert('_change_operation_key')},
                      acceptText: intl.formatMessage({id:'sign_operations_key_error_action_continue'})});

                    resolve();
                });
            }else{
                generateRecoveryOperationsKey({operationKey: values.new_operationKey}).then((result)=>{
                    let title = intl.formatMessage({id:'sign_operations_key_error_incorrect_title'});
                    if(result.result === 'ok'){
                        closeDialog().then(() => {
                            dialog({
                                icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                                title: intl.formatMessage({id:'alert_change_operation_key_title'}),
                                message: intl.formatMessage({id:'alert_change_operation_key_description'}),
                                onAccept: ()=>{},
                                acceptText: intl.formatMessage({id:'sign_operations_key_error_action_continue'})});
                              resolve();
                        });

                        resolve();
                        return;

                    }else if(result.errors){
                        const errors = result.errors as any;
                        if(errors['newOperationsKey']){
                            switch(errors['newOperationsKey']){
                                case 'operations key too short':
                                    title = intl.formatMessage({id:'page_change_operations_key_password_too_short'});
                                    break;
                                case 'operations key too long':
                                    title = intl.formatMessage({id:'page_change_operations_key_password_too_long'});
                                    break;
                                case 'operations key too weak':
                                    title = intl.formatMessage({id:'page_change_operations_key_password_too_weak'});
                                    break;
                            }
                        }
                    }
                    closeDialog().then(()=>{
                        dialog({
                          icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                          title: title,
                          message: '',
                          onAccept: ()=>{showAlert('_change_operation_key')},
                          acceptText: intl.formatMessage({id:'sign_operations_key_error_action_continue'})});

                        resolve();
                    });
                }).finally(()=>{
                });
            }
        });

    const showAlert = (alertKey:string, alertData?: any, onAccept?: () => void, onBefore?: () => void) : Promise<void> =>
        new Promise((resolve) => {
            if (alertKey) {
                if(onBefore){
                    onBefore();
                }

                let title: string='';
                let message: string='';
                let acceptText: string=intl.formatMessage({id:'alert_info_action_continue'});
                switch(alertKey){
                    case '_operative_web_':
                        closeDialog().then(()=>{
                            dialog({
                                title: intl.formatMessage({id:'page_operational_web_title'}),
                                message: '',
                                extra: <div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_operational_web_text'})}}></div>,
                                showClose: true,
                                className:'dialog-without-buttons'
                            })
                        });
                        resolve();
                        return;
                    case '_privacy_policy_':
                        closeDialog().then(()=>{
                            dialog({
                                title: intl.formatMessage({id:'page_data_protection_title'}),
                                message: '',
                                extra: <div className='html-content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_data_protection_text'})}}></div>,
                                showClose: true,
                                className:'dialog-without-buttons'
                            })
                        });
                        resolve();
                        return;
                        break;
                    case '_expired_session_':
                        message=intl.formatMessage({id:'alert_info_expired_session'})
                        break;
                    case '_inactive_own_without_request_':
                        closeDialog().then(()=>{
                            dialog({
                                title: intl.formatMessage({id:'alert_inactive_own_without_request_signature_title'}),
                                message: intl.formatMessage({id:'alert_inactive_own_without_request_signature_description'}),
                                onAccept: ()=>{
                                    closeDialog().then(()=>{

                                        const controlCheck=(nameCheck: string, value: boolean)=>{
                                            const contractformalization_informationTrueVerifiable = nameCheck === 'contractformalization_informationTrueVerifiable' ? value : document.getElementById('contractformalization_informationTrueVerifiable')?.getAttribute('aria-checked') === 'true';
                                            const contractformalization_legalOriginIncome = nameCheck === 'contractformalization_legalOriginIncome' ? value : document.getElementById('contractformalization_legalOriginIncome')?.getAttribute('aria-checked') === 'true';
                                            const contractformalization_checkTerms = nameCheck === 'contractformalization_checkTerms' ? value : document.getElementById('contractformalization_checkTerms')?.getAttribute('aria-checked') === 'true';

                                            if (contractformalization_informationTrueVerifiable && contractformalization_legalOriginIncome && contractformalization_checkTerms) {
                                                const elements = document.querySelectorAll('.contractformalization_buttonSend');
                                                elements.forEach(element => {
                                                    element.removeAttribute('disabled');
                                                });
                                            } else {
                                                const elements = document.querySelectorAll('.contractformalization_buttonSend');
                                                elements.forEach(element => {
                                                    element.setAttribute('disabled', 'disabled');
                                                });
                                            }
                                        }

                                        dialog({
                                            title: intl.formatMessage({id:'alert_inactive_own_without_request_signature_title'}),
                                            message: intl.formatMessage({id:'alert_inactive_own_without_request_signature_description'}),
                                            extra: <Form initialValues={formDataFormalize} onSubmit={handleSubmitFormalize} className={loading ? 'loading' : ''}>
                                                    <Stack space={16}>
                                                        <GridLayout template="6+6" verticalSpace={16}
                                                            left={<TextField fullWidth name="phone" label={intl.formatMessage({id:'alert_inactive_own_without_request_signature_phone'})} onChange={handleChangeField}></TextField>}
                                                            right={<TextField fullWidth name="mobile" label={intl.formatMessage({id:'alert_inactive_own_without_request_signature_mobilephone'})} onChange={handleChangeField}></TextField>}
                                                        />
                                                        <GridLayout template="6+6" verticalSpace={16}
                                                            left={<EmailField fullWidth name="email" label={intl.formatMessage({id:'alert_inactive_own_without_request_signature_email'})} onChange={handleChangeField}></EmailField>}
                                                            right={<></>}
                                                        />
                                                        <Stack space={16}>
                                                            <Checkbox id="contractformalization_informationTrueVerifiable" name="informationTrueVerifiable" onChange={(val) => controlCheck('contractformalization_informationTrueVerifiable', val)} render={({controlElement, labelId}:any) => (
                                                                <Inline alignItems="center" space={16}>
                                                                {controlElement}
                                                                <Text3 regular id={labelId}>
                                                                    {intl.formatMessage({id:'page_register_information_true_verifiable'})}
                                                                </Text3>
                                                                </Inline>
                                                            )}></Checkbox>

                                                            <Checkbox id="contractformalization_legalOriginIncome" name="legalOriginIncome" onChange={(val) => controlCheck('contractformalization_legalOriginIncome', val)} render={({controlElement, labelId}:any) => (
                                                                <Inline alignItems="center" space={16}>
                                                                {controlElement}
                                                                <Text3 regular id={labelId}>
                                                                    {intl.formatMessage({id:'page_register_legal_origin_income'})}
                                                                </Text3>
                                                                </Inline>
                                                            )}></Checkbox>

                                                            <Checkbox id="contractformalization_checkTerms" name="checkTerms" onChange={(val) => controlCheck('contractformalization_checkTerms', val)} render={({controlElement, labelId}:any) => (
                                                                <Inline alignItems="center" space={16}>
                                                                {controlElement}
                                                                <Text3 regular id={labelId}>
                                                                    {intl.formatMessage({id:'page_contact_form_writeus_check_terms'},{privacy_policy: isNativeApp ? <TextLink onPress={()=>{closeDialog().then(()=>{router.push(getUrlNavigation(UserDataProtectionPage))})}}>{intl.formatMessage({id:'page_contact_form_writeus_check_terms_policy_text'})}</TextLink> : <TextLink newTab href={getUrlNavigation(DataProtectionPage)}>{intl.formatMessage({id:'page_contact_form_writeus_check_terms_policy_text'})}</TextLink>})}
                                                                </Text3>
                                                                </Inline>
                                                            )}></Checkbox>
                                                        </Stack>
                                                        <ButtonLayout align='full-width'>
                                                            <ButtonSecondary onPress={()=>{closeDialog()}}>
                                                                {intl.formatMessage({id:'alert_inactive_own_without_request_signature_action_skip'})}
                                                            </ButtonSecondary>
                                                            <ButtonPrimary className="contractformalization_buttonSend" disabled submit>
                                                                {intl.formatMessage({id:'alert_inactive_own_without_request_signature_action_save'})}
                                                            </ButtonPrimary>
                                                        </ButtonLayout>
                                                    </Stack>
                                                </Form>,
                                            className:'dialog-without-buttons'});
                                    });
                                },
                                showCancel: true,
                                cancelText: intl.formatMessage({id:'alert_inactive_own_without_request_signature_action_skip'}),
                                acceptText: intl.formatMessage({id:'alert_inactive_own_without_request_signature_action_formalize'})})
                        });
                        resolve();
                        return;
                        break;
                    case '_inactive_own_signature_':
                        title=intl.formatMessage({id:'alert_inactive_own_signature_title'})
                        message=intl.formatMessage({id:'alert_inactive_own_signature_description'})
                        break;
                    case '_pending_validation_own_signature_':
                        title=intl.formatMessage({id:'alert_pending_validation_own_signature_title'})
                        message=intl.formatMessage({id:'alert_pending_validation_own_signature_description'})
                        break;
                    case '_rejected_own_signature_':
                        title=intl.formatMessage({id:'alert_rejected_own_signature_title'})
                        message=intl.formatMessage({id:'alert_rejected_own_signature_description'})
                        break;
                    case '_pending_own_signature_':
                        title=intl.formatMessage({id:'alert_pending_own_signature_title'})
                        message=intl.formatMessage({id:'alert_pending_own_signature_description'})
                        break;
                    case '_inactive_other_signature_':
                        if(alertData && alertData.ownership && alertData.name){
                            title=intl.formatMessage({id:'alert_inactive_other_signature_title'},{position: alertData.ownership, user: alertData.name})
                        }else{
                            title=intl.formatMessage({id:'alert_inactive_other_signature_title', })
                        }
                        message=intl.formatMessage({id:'alert_inactive_other_signature_description'})
                        break;
                    case '_rejected_other_signature_':
                        if(alertData && alertData.ownership && alertData.name){
                            title=intl.formatMessage({id:'alert_rejected_other_signature_title'},{position: alertData.ownership, user: alertData.name})
                        }else{
                            title=intl.formatMessage({id:'alert_rejected_other_signature_title', })
                        }
                        message=intl.formatMessage({id:'alert_rejected_other_signature_description'})
                        break;
                    case '_pending_other_signature_':
                        if(alertData && alertData.ownership && alertData.name){
                            title=intl.formatMessage({id:'alert_pending_other_signature_title'},{position: alertData.ownership, user: alertData.name})
                        }else{
                            title=intl.formatMessage({id:'alert_pending_other_signature_title', })
                        }
                        message=intl.formatMessage({id:'alert_pending_other_signature_description'})
                        break;
                    case '_inactive_other_without_request_':
                        if(alertData && alertData.ownership && alertData.name){
                            title=intl.formatMessage({id:'alert_inactive_other_without_request_title'},{position: alertData.ownership, user: alertData.name})
                        }else{
                            title=intl.formatMessage({id:'alert_inactive_other_without_request_title', })
                        }
                        message=intl.formatMessage({id:'alert_inactive_other_without_request_description'})
                        break;
                    case '_closing_date_of_operations_':
                        title=intl.formatMessage({id:'alert_closing_date_of_operations_title'})
                        message=intl.formatMessage({id:'alert_closing_date_of_operations_description'})
                        break;
                    case '_employment_plan_cannot_contribution_':
                        title=intl.formatMessage({id:'alert_employment_plan_cannot_contribution_title'})
                        message=intl.formatMessage({id:'alert_employment_plan_cannot_contribution_description'})
                        break;
                    case '_employment_plan_cannot_mobilize_':
                        title=intl.formatMessage({id:'alert_employment_plan_cannot_mobilize_title'})
                        message=intl.formatMessage({id:'alert_employment_plan_cannot_mobilize_description'})
                        break;
                    case '_expired_idcard_':
                        message=intl.formatMessage({id:'alert_expired_idcard_description'})
                        break;
                    case '_expired_resident_card_':
                        title=intl.formatMessage({id:'alert_expired_resident_card_title'})
                        break;
                    case '_exists_operation_pending_total_output_':
                        title=intl.formatMessage({id:'alert_exists_operation_pending_total_output_title'})
                        break;
                    case '_operations_key_is_blocked_':
                        title=intl.formatMessage({id:'alert_operations_key_is_blocked_title'})
                        message=intl.formatMessage({id:'alert_operations_key_is_blocked_description'})
                        break;
                    case '_no_associated_bank_accounts_':
                        title=intl.formatMessage({id:'alert_no_associated_bank_accounts_title'})
                        message=intl.formatMessage({id:'alert_no_associated_bank_accounts_description'})
                        break;
                    case '_subscription_charge_fees_':
                        title=intl.formatMessage({id:'alert_refund_charge_fees_title'})
                        message=intl.formatMessage({id:'alert_refund_charge_fees_description'})
                        break;
                    case '_subscription_charge_fees_with_dates':
                        title=intl.formatMessage({id:'alert_subscription_charge_fees_with_dates_title'})
                        message=intl.formatMessage({id:'alert_subscription_charge_fees_with_dates_description'}, {start_date: (alertData && alertData.startDate ? alertData && alertData.startDate : ''), end_date: (alertData && alertData.endDate ? alertData && alertData.endDate : '')});
                        break;
                    case '_refund_charge_fees_':
                        title=intl.formatMessage({id:'alert_subscription_charge_fees_title'})
                        message=intl.formatMessage({id:'alert_subscription_charge_fees_description'})
                        break;
                    case '_refund_charge_fees_with_dates_':
                        title=intl.formatMessage({id:'alert_refund_charge_fees_with_dates_title'})
                        message=intl.formatMessage({id:'alert_refund_charge_fees_with_dates_description'}, {start_date: (alertData && alertData.startDate ? alertData && alertData.startDate : ''), end_date: (alertData && alertData.endDate ? alertData && alertData.endDate : '')})
                        break;
                    case '_alert_ppes_':
                        title=intl.formatMessage({id:'alert_ppes_title'})
                        break;
                    case '_pension_plan_not_contractable_':
                        title=intl.formatMessage({id:'alert_pension_plan_not_contractable_title'});
                        break;
                    case '_investment_fund_not_contractable_':
                        title=intl.formatMessage({id:'alert_investment_fund_not_contractable_title'});
                        break;
                    case '_final_capital_calculator_form_':
                    case '_comimission_savings_calculator_form_':
                        const controlCheck=(nameCheck: string, value: boolean)=>{
                            const contractformalization_checkTerms = nameCheck === 'contractformalization_checkTerms' ? value : document.getElementById('contractformalization_checkTerms')?.getAttribute('aria-checked') === 'true';

                            if (contractformalization_checkTerms) {
                                const elements = document.querySelectorAll('.contractformalization_buttonSend');
                                elements.forEach(element => {
                                    element.removeAttribute('disabled');
                                });
                            } else {
                                const elements = document.querySelectorAll('.contractformalization_buttonSend');
                                elements.forEach(element => {
                                    element.setAttribute('disabled', 'disabled');
                                });
                            }
                        }

                        closeDialog().then(()=>{
                            dialog({
                                title: intl.formatMessage({id:'alert_calculator_final_capital_title'}),
                                message: '',
                                extra: <Form  onSubmit={(values) => handleSubmitCalculator(values, alertData)} className={loading ? 'loading' : ''}>
                                            <Stack space={16}>
                                                <GridLayout template="6+6" verticalSpace={16}
                                                    left={<TextField fullWidth name="name" label={intl.formatMessage({id:'alert_calculator_final_capital_name'})} onChange={handleChangeFieldCalculator}></TextField>}
                                                    right={<TextField fullWidth name="lastName" label={intl.formatMessage({id:'alert_calculator_final_capital_lastname'})} onChange={handleChangeFieldCalculator}></TextField>}
                                                />
                                                <GridLayout template="6+6" verticalSpace={16}
                                                    left={<EmailField fullWidth name="email" label={intl.formatMessage({id:'alert_calculator_final_capital_email'})} onChange={handleChangeFieldCalculator}></EmailField>}
                                                    right={<PhoneNumberField fullWidth name="phone" label={intl.formatMessage({id:'alert_calculator_final_capital_phone'})} onChange={handleChangeFieldCalculator} />}
                                                />
                                                <Stack space={16}>
                                                    <Checkbox id="contractformalization_checkTerms" name="checkTerms" onChange={(val) => controlCheck('contractformalization_checkTerms', val)} render={({controlElement, labelId}:any) => (
                                                        <Inline alignItems="center" space={16}>
                                                        {controlElement}
                                                        <Text3 regular id={labelId}>
                                                            {intl.formatMessage({id:'page_contact_form_writeus_check_terms'},{privacy_policy: isNativeApp ? <TextLink onPress={()=>{closeDialog().then(()=>{router.push(getUrlNavigation(UserDataProtectionPage))})}}>{intl.formatMessage({id:'page_contact_form_writeus_check_terms_policy_text'})}</TextLink> : <TextLink newTab href={getUrlNavigation(DataProtectionPage)}>{intl.formatMessage({id:'page_contact_form_writeus_check_terms_policy_text'})}</TextLink>})}
                                                        </Text3>
                                                        </Inline>
                                                    )}></Checkbox>
                                                </Stack>
                                                <ButtonLayout align='full-width'>
                                                    <ButtonPrimary className="contractformalization_buttonSend" disabled submit>
                                                        {intl.formatMessage({id:'alert_inactive_own_without_request_signature_action_save'})}
                                                    </ButtonPrimary>
                                                </ButtonLayout>
                                            </Stack>
                                        </Form>,
                                className:'dialog-without-buttons'});
                        });
                        resolve();
                        return;
                        break;
                    case '_reports_':
                        const controlCheckReport=(nameCheck: string, value: boolean)=>{
                            const contractformalization_checkTerms = nameCheck === 'contractformalization_checkTerms' ? value : document.getElementById('contractformalization_checkTerms')?.getAttribute('aria-checked') === 'true';

                            if (contractformalization_checkTerms) {
                                const elements = document.querySelectorAll('.contractformalization_buttonSend');
                                elements.forEach(element => {
                                    element.removeAttribute('disabled');
                                });
                            } else {
                                const elements = document.querySelectorAll('.contractformalization_buttonSend');
                                elements.forEach(element => {
                                    element.setAttribute('disabled', 'disabled');
                                });
                            }
                        }
                        closeDialog().then(()=>{

                            dialog({
                                title: intl.formatMessage({id:'alert_reports_title'}),
                                message: '',
                                extra: <Form initialValues={formDataCalculator}  onSubmit={(values) => handleSubmitReport(values, alertData)} className={loading ? 'loading' : ''}>
                                            <Stack space={16}>
                                                <GridLayout template="6+6" verticalSpace={16}
                                                    left={<TextField fullWidth name="name" label={intl.formatMessage({id:'alert_reports_name'})} onChange={handleChangeFieldCalculator}></TextField>}
                                                    right={<TextField fullWidth name="lastName" label={intl.formatMessage({id:'alert_reports_lastname'})} onChange={handleChangeFieldCalculator}></TextField>}
                                                />
                                                <GridLayout template="6+6" verticalSpace={16}
                                                    left={<EmailField fullWidth name="email" label={intl.formatMessage({id:'aler_reports_email'})} onChange={handleChangeFieldCalculator}></EmailField>}
                                                    right={<PhoneNumberField fullWidth name="phone" label={intl.formatMessage({id:'alert_reports_phone'})} onChange={handleChangeFieldCalculator} />}
                                                />
                                                <Stack space={16}>
                                                    <Checkbox id="contractformalization_checkTerms" name="checkTerms" onChange={(val) => controlCheckReport('contractformalization_checkTerms', val)} render={({controlElement, labelId}:any) => (
                                                        <Inline alignItems="center" space={16}>
                                                        {controlElement}
                                                        <Text3 regular id={labelId}>
                                                            {intl.formatMessage({id:'page_contact_form_writeus_check_terms'},{privacy_policy:<TextLink newTab href={getUrlNavigation(DataProtectionPage)}>{intl.formatMessage({id:'page_contact_form_writeus_check_terms_policy_text'})}</TextLink>})}
                                                        </Text3>
                                                        </Inline>
                                                    )}></Checkbox>
                                                </Stack>
                                                <ButtonLayout align='full-width'>
                                                    <ButtonPrimary className="contractformalization_buttonSend" disabled submit>
                                                        {intl.formatMessage({id:'alert_inactive_own_without_request_signature_action_save'})}
                                                    </ButtonPrimary>
                                                </ButtonLayout>
                                            </Stack>
                                        </Form>,
                                className:'dialog-without-buttons'});
                        });
                        resolve();
                        return;
                        break;
                    case '_contract_in_proccess_':
                        closeDialog().then(()=>{

                            dialog({
                                title: intl.formatMessage({id:'alert_contract_in_proccess_title'}),
                                message: '',
                                extra:
                                <Form onSubmit={() =>closeDialog()}>
                                    <Stack space={16}>
                                        <Text3 regular>
                                            {intl.formatMessage({id:'alert_contract_in_proccess_description'},{contacto:isNativeApp ? <TextLink onPress={()=>{closeDialog().then(()=>{router.push(getUrlNavigation(UserContactPage))})}}>{intl.formatMessage({id:'alert_contract_in_proccess_contact'})}</TextLink> : <TextLink newTab href={getUrlNavigation(ContactPage)}>{intl.formatMessage({id:'alert_contract_in_proccess_contact'})}</TextLink>})}
                                        </Text3>
                                        <ButtonLayout align='full-width'>
                                            <ButtonPrimary className="contractformalization_buttonSend" submit>
                                                {intl.formatMessage({id:'alert_info_action_continue'})}
                                            </ButtonPrimary>
                                        </ButtonLayout>
                                    </Stack>
                                </Form>,
                                className:'dialog-without-buttons'});
                        });
                        resolve();
                        return;
                        break;
                    case '_change_operation_key':
                        closeDialog().then(()=>{

                            const showLoading=()=>{
                                const buttonSubmit = document.querySelector('.button-sign-operations-key-submit') as any;
                                const buttonLoading = document.querySelector('.button-sign-operations-key-loading') as any;
                                document.getElementById('form-sign-operations-key')?.classList.add('loading');
                                if(buttonSubmit){
                                    buttonSubmit.style.display = 'none';
                                }
                                if(buttonLoading){
                                    buttonLoading.style.display = 'block';
                                }
                            }

                            dialog({
                                title: 'Cambiar clave de operaciones',
                                message: '',
                                extra:
                                <Form onSubmit={(data)=>{showLoading();handleSubmitOperationKey(data)}} className="test">
                                    <Stack space={32}>
                                        <PasswordField
                                            name="new_operationKey"
                                            label={intl.formatMessage({id:'page_change_operations_key_customer_confirm_new_key_title'})} />
                                    
                                        <PasswordField
                                            name="confirm_new_operationKey" helperText={intl.formatMessage({id:'page_change_operations_key_customer_confirm_new_key_helper_text'})}
                                            label={intl.formatMessage({id:'page_change_operations_key_customer_confirm_new_key_label'})} />
                                            
                                            <ButtonLayout align='full-width'>
                                                <ButtonPrimary className="button-sign-operations-key-submit"  submit>
                                                    Aceptar
                                                </ButtonPrimary>
                                                <ButtonPrimary className="button-sign-operations-key-loading" onPress={()=>{}} showSpinner={true} style={{display:'none'}}>
                                                    Aceptar
                                                </ButtonPrimary>
                                            </ButtonLayout>
                                    </Stack>
                                </Form>,
                                className:'dialog-without-buttons'});
                        });
                        resolve();
                        return;
                        break;
                    default:
                        resolve();
                        return;
                        break;
                }
                closeDialog().then(()=>{
                    alert({
                        title: title.length===0 ? intl.formatMessage({id:'alert_info_title'}) : title,
                        message: message??'',
                        acceptText: acceptText,
                        onAccept: onAccept
                    });
                });
                resolve();

                return (<></>);
            }
            return (<></>);
        });
	return { showAlert };
}