import { Box, Divider, SkeletonRectangle, skinVars, Stack, Text3, Text4, Text5 } from '@telefonica/mistica';

import './GeneralInformation.scss';

import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { GeneralInformation as GI } from '../../entities/products/general-information';
import { Product } from '../../entities/products/product';

interface GeneralInformationProps {
    full?:boolean,
    product: Product | null
    loading?: boolean
}

const GeneralInformation: React.FC<GeneralInformationProps> = (prop) => {
    const intl = useIntl()
    const [product, setProduct] = useState(null as null|Product);
    const [generalInfo, setGeneralInfo] = useState(null as null|GI);


    useEffect(()=>{
        if(prop.product){
            setProduct(prop.product);
            if(prop.product.informacionGeneral){
                setGeneralInfo(prop.product.informacionGeneral);
            }
        }
      },[prop.product]);

    return (<>{prop.loading ?
                <Box paddingY={12}>
                    <SkeletonRectangle height={500} />
                </Box>
                :
                product ?
                <>
                    <style>{
                        '.generalInformation--content .html-content *{color:'+skinVars.colors.neutralHigh+';}'
                    }</style>

                    <Box className='generalInformation--content'>
                        <Box paddingBottom={40}>
                            <Text5>{intl.formatMessage({id:'page_investment_funds_customer_general_information_title'})}</Text5>
                        </Box>

                        {product.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_OTROSPRODUCTOS &&
                            <Stack space={40}>
                                <Stack space={24}>
                                    <Stack space={4}>
                                        <Text4 light>{product.datos}</Text4>
                                    </Stack>
                                </Stack>
                            </Stack>
                        }

                        {product.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES && generalInfo &&
                            <Stack space={40}>
                                <Stack space={24}>

                                    {generalInfo.promotor &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_promoter'})}</Text3>

                                                <Text4 light>{generalInfo.promotor}</Text4>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.fondoAdscrito &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_seconded_fund'})} {product.descripcion}</Text3>

                                                <Text4 light>{generalInfo.fondoAdscrito}</Text4>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.gestora &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_plan_manager'})}</Text3>

                                                <Text4 light>{generalInfo.gestora}</Text4>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.depositaria &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_plan_depository'})}</Text3>

                                                <Text4 light>{generalInfo.depositaria}</Text4>
                                            </Stack>
                                        </Stack>
                                    }
                                    <div className='html-content'>
                                        <hr style={{backgroundColor:skinVars.colors.backgroundBrandSecondary}} />
                                    </div>
                                </Stack>

                                <Stack space={24}>

                                    {generalInfo.aportaciones &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_aportartion_to'})} {product.descripcion}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.aportaciones}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.prestaciones &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_benefits_of'})} {product.descripcion}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.prestaciones}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.comisionGestionDeposito &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_management_fee_and_deposit'})} {product.descripcion}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.comisionGestionDeposito}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.comisionControl &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_control_commision'})}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.comisionControl}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.otrosDatos &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_other_data'})}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.otrosDatos}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.movilizacionDerechos &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_rights_mobilization'})}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.movilizacionDerechos}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.efectividadDerechos &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_rights_effectiveness'})}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.efectividadDerechos}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.tipoFondo &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_fund_type'})}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.tipoFondo}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.modalidad &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_modality'})}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.modalidad}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }

                                    {generalInfo.contingenciasCubiertas &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_covered_contingencies'})}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.contingenciasCubiertas}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }
                                </Stack>
                            </Stack>
                        }

                        {product.cdCompania?.toString()===process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS && generalInfo &&
                            <Stack space={40}>
                                <Stack space={24}>


                                    {generalInfo.tipoFondo &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_investment_fund_type'})}</Text3>

                                                <Text4 light>{generalInfo.tipoFondo}</Text4>
                                            </Stack>
                                        </Stack>
                                    }


                                    {generalInfo.gestora &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_investment_fund_manager'})}</Text3>

                                                <Text4 light>{generalInfo.gestora}</Text4>
                                            </Stack>
                                        </Stack>
                                    }


                                    {generalInfo.depositaria &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_investment_fund_depositary'})}</Text3>

                                                <Text4 light>{generalInfo.depositaria}</Text4>
                                            </Stack>
                                        </Stack>
                                    }

                                    <div className='html-content'>
                                        <hr style={{backgroundColor:skinVars.colors.backgroundBrandSecondary}} />
                                    </div>
                                </Stack>

                                <Stack space={24}>


                                    {generalInfo.traspasosFondos &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_investment_fund_transfer'})}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.traspasosFondos}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }



                                    {generalInfo.comisionGestionDeposito &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_investment_fund_commission_and_deposit'})}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.comisionGestionDeposito}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }


                                    {generalInfo.comisionResultadosP &&
                                       <Stack space={24}>
                                            <Stack space={4}>
                                                <Text3 regular color={skinVars.colors.brand}>{intl.formatMessage({id:'page_investment_funds_customer_general_information_investment_fund_commission_on_results'})}</Text3>

                                                <div className='html-content' dangerouslySetInnerHTML={{__html: generalInfo.comisionResultadosP}}></div>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    }
                                </Stack>
                            </Stack>
                        }
                    </Box>
                </>
            :
            <></>
        }</>);
};

export default GeneralInformation;
