

import { Box, ButtonSecondary, Circle, DataCard, Grid, Hero, IconApartmentBuildingRegular, IconControlsLight, IconEmailRegular, IconGroupRegular, IconHeartBubbleLight, IconIntranetLight, IconMoneyDropLight, IconPeopleNetworkLight, IconPlantLight, IconPresentationLight, IconWalletLight, Image, Inline, ResponsiveLayout, skinVars, Stack, Text3, Text6, useScreenSize } from '@telefonica/mistica';
import { useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ChipTabs from '../../../components/chip-tabs/ChipTabs';
import HeroImage from '../../../components/hero/HeroImage';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import OurAppContainer from '../../../components/staticPages/ourApp/OurApp';
import { getUrlNavigation } from '../../../utils/navigation';
import { isBot } from '../../../utils/utils';
import ContactPage from '../contact/ContactPage';
import EmploymentSystemPensionPlansPage from './EmploymentSystemPensionPlansPage';
import './SolutionPage.scss';
import { useIonRouter } from '@ionic/react';
import { Content } from '../../../entities/contents/content.model';
import { getContent } from '../../../utils/apiclient';

const SolutionForCompaniesPage: React.FC = () => {
  const router = useIonRouter();
  const intl = useIntl()
  const size = useScreenSize();
  const screenSize = useScreenSize();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [content, setContent] = useState(undefined as Content|undefined);

  useLayoutEffect(()=>{
    getContent('ppes').then((result)=>{
      setContent(result);
    });
  }, []);

  return (
    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_solutions_for_companies_seo_title'}), metaDescription:intl.formatMessage({id:'page_solutions_for_companies_seo_description'})}}
      header={{title:intl.formatMessage({id:'page_solutions_for_companies_page_title'}), parents:[{title:intl.formatMessage({id:'page_employment_system_pension_plans_page_title'}), item:EmploymentSystemPensionPlansPage}]}}
    >
        <HeroImage
            image={content?.imageBloque1 ?? '../assets/img/soluciones_para_empresas.jpg'}
            title={intl.formatMessage({id:'page_solutions_for_companies_hero_title'})}
            description={intl.formatMessage({id:'page_solutions_for_companies_hero_description'})}
        ></HeroImage>

        <ResponsiveLayout className='content'>
            <Box paddingTop={64}>
                <Stack space={56}>
                    <Box className='boxAlignCenter'>
                        <Text6>{intl.formatMessage({id:'page_solutions_for_companies_responsive_layout_title'})}</Text6>
                    </Box>

                    <Grid columns={screenSize.isTabletOrBigger ? 2 : 1} gap={screenSize.isTabletOrBigger ? 24 : 16}>
                        <DataCard
                            icon={
                                <Circle size={40} backgroundColor={skinVars.colors.brandLow}>
                                    <IconGroupRegular color={skinVars.colors.brand} />
                                </Circle>
                            }
                            title={intl.formatMessage({id:'page_solutions_for_companies_data_card1_title'})}/>
                        <DataCard
                            icon={
                                <Circle size={40} backgroundColor={skinVars.colors.brandLow}>
                                    <IconApartmentBuildingRegular color={skinVars.colors.brand} />
                                </Circle>
                            }
                            title={intl.formatMessage({id:'page_solutions_for_companies_data_card2_title'})}/>
                    </Grid>
                </Stack>
            </Box>
        </ResponsiveLayout>

        <Box paddingY={screenSize.isTabletOrBigger ? 56 : 40}>
            <ChipTabs
                noPadding={true}
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
                tabs={[intl.formatMessage({id:'page_solutions_for_companies_chip_tab1_name'}), intl.formatMessage({id:'page_solutions_for_companies_chip_tab2_name'})].map((text) => ({
                    text,
                }))}
            />
        </Box>


        <div id="promotor">
            <ResponsiveLayout className={(selectedIndex===0 ? 'd-block' : 'd-none')}>
                <Stack space={0}>
                    <Inline fullWidth={true} space={'evenly'} wrap={screenSize.isMobile}>
                        <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                            <Stack space={16}>
                                <Inline space={'evenly'}>
                                    <IconPeopleNetworkLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                </Inline>

                                <Stack space={8}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_solutions_for_companies_social_benefit_title'})}</Text6>
                                    </Box>

                                    <Box className='boxAlignCenter'>
                                        <Text3 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_solutions_for_companies_social_benefit_description'})}</Text3>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>

                        <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                            <Stack space={16}>
                                <Inline space={'evenly'}>
                                    <IconIntranetLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                </Inline>

                                <Stack space={8}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_solutions_for_companies_innovation_title'})}</Text6>
                                    </Box>

                                    <Box className='boxAlignCenter'>
                                        <Text3 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_solutions_for_companies_innovation_description'})}</Text3>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>

                        <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                            <Stack space={16}>
                                <Inline space={'evenly'}>
                                    <IconControlsLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                </Inline>

                                <Stack space={8}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_solutions_for_companies_personalization_title'})}</Text6>
                                    </Box>

                                    <Box className='boxAlignCenter'>
                                        <Text3 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_solutions_for_companies_personalization_description'})}</Text3>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>
                    </Inline>

                    <Inline fullWidth={true} space={'evenly'} wrap={screenSize.isMobile}>
                        <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                            <Stack space={16}>
                                <Inline space={'evenly'}>
                                    <IconWalletLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                </Inline>

                                <Stack space={8}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_solutions_for_companies_tax_advantages_title'})}</Text6>
                                    </Box>

                                    <Box className='boxAlignCenter'>
                                        <Text3 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_solutions_for_companies_tax_advantages_description'})}</Text3>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>

                        <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                            <Stack space={16}>
                                <Inline space={'evenly'}>
                                    <IconPlantLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                </Inline>

                                <Stack space={8}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_solutions_for_companies_responsability_title'})}</Text6>
                                    </Box>

                                    <Box className='boxAlignCenter'>
                                        <Text3 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_solutions_for_companies_responsability_description'})}</Text3>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>
                    </Inline>
                </Stack>
            </ResponsiveLayout>
        </div>

        <div id="empleado">
            <ResponsiveLayout className={(selectedIndex===1 ? 'd-block' : 'd-none')}>
                <Stack space={0}>
                    <Inline fullWidth={true} space={'evenly'} wrap={screenSize.isMobile}>
                        <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                            <Stack space={16}>
                                <Inline space={'evenly'}>
                                    <IconPresentationLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                </Inline>

                                <Stack space={8}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_solutions_for_companies_patrimonialist_efficient_management_title'})}</Text6>
                                    </Box>

                                    <Box className='boxAlignCenter'>
                                        <Text3 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_solutions_for_companies_patrimonialist_efficient_management_description'})}</Text3>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>

                        <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                            <Stack space={16}>
                                <Inline space={'evenly'}>
                                    <IconHeartBubbleLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                </Inline>

                                <Stack space={8}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_solutions_for_companies_independence_security_title'})}</Text6>
                                    </Box>

                                    <Box className='boxAlignCenter'>
                                        <Text3 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_solutions_for_companies_independence_security_description'})}</Text3>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>

                        <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                            <Stack space={16}>
                                <Inline space={'evenly'}>
                                    <IconPlantLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                </Inline>

                                <Stack space={8}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_solutions_for_companies_responsability_title'})}</Text6>
                                    </Box>

                                    <Box className='boxAlignCenter'>
                                        <Text3 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_solutions_for_companies_responsability_description'})}</Text3>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>
                    </Inline>

                    <Inline fullWidth={true} space={'evenly'} wrap={screenSize.isMobile}>
                        <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                            <Stack space={16}>
                                <Inline space={'evenly'}>
                                    <IconMoneyDropLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                </Inline>

                                <Stack space={8}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_solutions_for_companies_low_commissions_title'})}</Text6>
                                    </Box>

                                    <Box className='boxAlignCenter'>
                                        <Text3 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_solutions_for_companies_low_commissions_description'})}</Text3>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>

                        <Box className='BoxIcon' paddingX={8} paddingBottom={40}>
                            <Stack space={16}>
                                <Inline space={'evenly'}>
                                    <IconWalletLight size={screenSize.isDesktopOrBigger ? 64 : 56} color={skinVars.colors.brand} />
                                </Inline>

                                <Stack space={8}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_solutions_for_companies_tax_advantages_title'})}</Text6>
                                    </Box>

                                    <Box className='boxAlignCenter'>
                                        <Text3 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_solutions_for_companies_tax_advantages_description2'})}</Text3>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>
                    </Inline>
                </Stack>
            </ResponsiveLayout>
        </div>

        <ResponsiveLayout className='content' fullWidth={true}>

            <Hero
                background='brand'
                media={<Image src="../assets/img/mejor_plan.jpg" width="100%" height='337px'/>}
                title={intl.formatMessage({id:'page_solutions_for_companies_blue_hero_title'})}
                description={intl.formatMessage({id:'page_solutions_for_companies_blue_hero_description'})}
                desktopMediaPosition='left'
                button={isBot ?
                    <ButtonSecondary href={getUrlNavigation(ContactPage)}><IconEmailRegular color={"currentColor"} />{intl.formatMessage({id:'page_solutions_for_companies_blue_hero_action'})}</ButtonSecondary>
                :
                    <ButtonSecondary onPress={()=>router.push(getUrlNavigation(ContactPage))}><IconEmailRegular color={"currentColor"} />{intl.formatMessage({id:'page_solutions_for_companies_blue_hero_action'})}</ButtonSecondary>
                }
            />

        </ResponsiveLayout>

        <ResponsiveLayout className='content' fullWidth={true}>

            <OurAppContainer/>

        </ResponsiveLayout>
    </LayoutPublic>
  );
};

export default SolutionForCompaniesPage;
