import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';

import { numberAttempsOperationsKey, setOperationsKey } from '../../../../../utils/apiclient';

import { Box, ButtonFixedFooterLayout, ButtonPrimary, DisplayDataCard, Form, GridLayout, Inline, LoadingBar, PasswordField, ResponsiveLayout, SkeletonLine, SkeletonRectangle, SnapCard, Stack, Text4, Text5, TextLink, useScreenSize } from '@telefonica/mistica';
import { removeCurrentUserProduct } from '../../../../../utils/storage';
import { useToast } from '../../../../../utils/toast';
import { trackEvent } from '../../../../../utils/utils';
import { getCurrentLang } from '../../../../../utils/locate';
import { useAlert } from '../../../../../components/alerts/Alert';

const ChangeOperationsKeyCustomerPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();

  const { showToast } = useToast();
  const { showAlert } = useAlert();

  const [numberAttemps, setNumberAttemps] = useState(null as number | null);
  const [formData, setFormData] = useState({operationKey: "", new_operationKey: "", confirm_new_operationKey: ""});
  const [formErrors, setFormErrors] = useState([] as any);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true);
    numberAttempsOperationsKey().then(response=>{
      setNumberAttemps(response.result);
      setLoading(false);
    });
    removeCurrentUserProduct();
  }, []);

  const handleSubmit = (data: any): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('perfil-editar-clave_operaciones-guardar');
      if(data.new_operationKey !== data.confirm_new_operationKey){
        setFormErrors( { 'new_operationKey': intl.formatMessage({id:'operations key not match'}) });
        resolve();
      }else{
        setOperationsKey({oldKey: data.operationKey, newKey: data.confirm_new_operationKey, lang: getCurrentLang()}).then(response => {
          if(response.result.result === 'ko'){
            setFormErrors(response.result.errors);
          }else{
            showToast({type:'ok', title: intl.formatMessage({id:'page_change_operations_key_changed_operations_key_dialog'}), description: intl.formatMessage({id:'page_change_operations_key_changed_operations_key_dialog_description'})});
          }
        }).finally(()=>{
          resolve();
        });
      }
  });

  const handleChangeField= (evt:any) => {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
        ...formData,
        [name]: value,
    };
    setFormData(newValues);
    if(handleError(name)){
        delete formErrors[name];
    }
  };

  const handleError=(name:string) =>{
    if(formErrors && formErrors[name]){
      const error = formErrors[name];

      if(error){
          return intl.formatMessage({id: 'page_change_operations_key_'+error.toLowerCase().replaceAll(' ', '_')});
      }
    }
    return null;
  };

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_change_operations_key_customer_full_title'})}}
      title={intl.formatMessage({id:'page_change_operations_key_customer_title'})}
      pageInfo={{option: process.env.REACT_APP_MENU_OPERATIVA_WEB_CAMBIAR_CLAVE_OPERACIONES, role:'client'}}
      showBack
    >
      <LoadingBar visible={loading} />
      <ResponsiveLayout className='inner first'>
        {screenSize.isDesktopOrBigger &&
          <Box paddingBottom={40}>
            <Inline space={'between'} alignItems='center'>
              <Text5>{intl.formatMessage({id:'page_change_operations_key_customer_full_title'})}</Text5>
            </Inline>
          </Box>
        }

        <Form initialValues={formData} onSubmit={handleSubmit} className='form--srcollY'>
          <Stack space={40}>
            {numberAttemps == null ? 
            <Stack space={32}>
              <Stack space={16}>
                <SkeletonLine width={400}></SkeletonLine>
                <SkeletonRectangle width={400} height={45}></SkeletonRectangle>
              </Stack>
              <Stack space={16}>
                <SkeletonLine width={400}></SkeletonLine>
                <SkeletonRectangle width={400} height={45}></SkeletonRectangle>
                <SkeletonRectangle width={400} height={45}></SkeletonRectangle>
              </Stack>
              <Box>
                <SkeletonLine width={300}></SkeletonLine>
              </Box>
              <SkeletonRectangle width={200} height={45}></SkeletonRectangle>
            </Stack>
            : (numberAttemps < 3 ?(
              <Stack space={32}>
                <Stack space={16}>
                  <Text4 medium>{intl.formatMessage({id:'page_change_operations_key_customer_key_title'})}</Text4>
                  <PasswordField
                    name="operationKey"
                    onChange={handleChangeField}
                    error={handleError('operationKey')!==null} helperText={handleError('operationKey')??undefined}
                    label={intl.formatMessage({id:'page_change_operations_key_customer_key_label'})} />
                </Stack>

                <Stack space={16}>
                  <Text4 medium>{intl.formatMessage({id:'page_change_operations_key_customer_new_key_title'})}</Text4>
                  <PasswordField
                    name="new_operationKey"
                    onChange={handleChangeField}
                    error={handleError('new_operationKey')!==null} helperText={handleError('new_operationKey')??undefined}
                    label={intl.formatMessage({id:'page_change_operations_key_customer_confirm_new_key_title'})} />
                  <PasswordField
                    name="confirm_new_operationKey"
                    onChange={handleChangeField}
                    error={handleError('new_operationKey')!==null} helperText={handleError('new_operationKey') ?? intl.formatMessage({id:'page_change_operations_key_customer_confirm_new_key_helper_text'})}
                    label={intl.formatMessage({id:'page_change_operations_key_customer_confirm_new_key_label'})} />
                </Stack>
              </Stack>
            ) : (
              <GridLayout template='8+4' 
              left = {
                <DisplayDataCard
                  title={intl.formatMessage({id:'page_change_operations_key_customer_block_operation_key_title'})}
                  description={intl.formatMessage({id:'page_change_operations_key_customer_block_operation_key_description'})}
                />
              }
              right={
                <></>
              }/>
              
            ))}
            
            {numberAttemps!= null &&
              <Box>
                <TextLink onPress={()=>{showAlert('_change_operation_key'); trackEvent('cambiar_clave_operaciones');}}>
                  {intl.formatMessage({id:'page_change_operations_key_customer_forget_password'})} 
                </TextLink>
              </Box>
            }

            {numberAttemps!= null && numberAttemps < 3 &&
              <ButtonFixedFooterLayout
                button={<ButtonPrimary submit>{intl.formatMessage({id:'page_change_operations_key_customer_save'})}</ButtonPrimary>}>
              </ButtonFixedFooterLayout>
            }
          </Stack>
        </Form>
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default ChangeOperationsKeyCustomerPage;
