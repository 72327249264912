import { Box, Circle, Inline, Stack, Text1, Text3, skinVars } from '@telefonica/mistica';
import React from 'react';

import { useIntl } from 'react-intl';
import './EmptyCard.scss';

interface EmptyCardProps {
    icon: React.ReactNode,
    title: string,
    subtitle?: string,
    button?: React.ReactNode
}

const EmptyCard: React.FC<EmptyCardProps> = (prop) => {
    const intl = useIntl()


return (<>
          <Box className='empty-card'>
            <Stack space={16}>
              <Circle size={128} backgroundColor={skinVars.colors.brandLow}>
                <>
                  {prop.icon}
                </>
              </Circle>
              <Inline space="evenly">
                <Box className='boxContent'>
                  <Stack space={8}>
                    <Text3 regular>{prop.title}</Text3>
                    {prop.subtitle &&
                      <Text1 regular color={skinVars.colors.textSecondary}>{prop.subtitle}</Text1>
                    }
                  </Stack>
                </Box>
              </Inline>
              {prop.button}
            </Stack>
          </Box>
        </>);
};

export default EmptyCard;
